import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import i18n from "i18n"
import { PoppinsNormal } from "common/typographies"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

const DropdownSetStatus = props => {
  const [menu, setMenu] = useState(false)

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{ width: "100%", height: "0px", marginLeft: "20px" }}
        >
          <DropdownToggle
            style={{
              marginTop: "-10px",
              height: "40px",
              marginLeft: "0px",
              borderRadius: "6px",
              border: "1px solid #c4c4c4",
              width: "98%",
              paddingLeft: "10px",
            }}
          >
            <div className="inline" style={{ position: "relative" }}>
              <PoppinsNormal
                style={{
                  fontSize: "14px",
                  color: "#414141",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
              >
                {props.status === "PAID"
                  ? i18n.t("Pagada")
                  : i18n.t("No pagada")}
              </PoppinsNormal>
              <KeyboardArrowDown
                style={{
                  color: "#414141",
                  width: "15px",
                  position: "absolute",
                  right: "0px",
                  marginTop: "-2px",
                }}
              />
            </div>
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-end"
            style={{ width: "100%", marginLeft: "20px" }}
          >
            <DropdownItem
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
              onClick={e => {
                props.setStatus("PAID")
              }}
            >
              {" "}
              <div className="inline">
                <CheckCircleOutlineIcon
                  style={{
                    color: "#3BA452",
                    fontSize: "20px",
                    marginTop: "1px",
                    marginLeft: "-7px",
                    marginRight: "5px",
                  }}
                />
                {i18n.t("Pagada")}
              </div>
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
              onClick={e => {
                props.setStatus("UNPAID")
              }}
            >
              {" "}
              <div className="inline">
                <HighlightOffIcon
                  style={{
                    color: "#DB7600",
                    fontSize: "20px",
                    marginTop: "1px",
                    marginLeft: "-7px",
                    marginRight: "5px",
                  }}
                />
                {i18n.t("No pagada")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </>
  )
}

DropdownSetStatus.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  status: PropTypes.string,
  setStatus: PropTypes.func,
}

export default DropdownSetStatus
