import React from "react"
import { Redirect } from "react-router-dom"
// Profile

//GeneralProfile
import GeneralProfile from "../pages/Authentication/general-profile"

// Authentication related pages
//import Login from "../pages/Authentication/Login"
import Login from "../pages/Authentication 2.0/Login"
import Register from "pages/Authentication 2.0/Register"
//import Register from "../pages/Authentication/Register"

// Dashboard

import Budgeting from "../pages/Budgeting/index"

import BudgetVersion from "../pages/Budgeting/BudgetVersion"
import BudgetVersionFinal from "../pages/Budgeting/BudgetVersionFinal"
// Calendar
import Contacts from "../pages/Contacts/index"
import Document from "pages/Document/index"
import BudgetingProviders from "pages/Budgeting/BudgetingProviders"
import Teams from "pages/Teams"
import SurveyTypeForm from "pages/Surveys/SurveyTypeForm"
import BancoDePreciosReferenciaC from "pages/BancoPrecios/BancoDePreciosReferenciaC"
import TemplatePage from "pages/BancoPrecios/templatePage"
import SelectReferencia from "pages/BancoPrecios/selectReferencia"

import ModalPartidas from "pages/ModalPartidas/index"

import {
  SummaryNav,
  BudgetingNav,
  DocumentsNav,
  ProfileNav,
  ProfileNav2,
  PlanningNav,
  ProvidersNav,
  ProfileNav6,
  BancoDePreciosNav,
  BancoDePreciosNavCype,
  BancoDePreciosNavTemplate,
  ProfileNav3,
  BancoDePreciosNavFavorites,
  ProfileNav4,
  FacturasNav,
  ProfileNav5,
  ProfileNavTeamManagementSection,
  ActasNav,
  BancoDePreciosNavTemplateUnique,
  CertificacionesNav,
  CertHistoryNav,
  ExtrasAndContradictionsNav,
  BancoDePreciosDynamicElements,
} from "./NavContent"

import PlanningKanbanV1 from "pages/Planning/PlanningKanban"
import BancoPreciosIndex from "pages/BancoPrecios/BancoPreciosIndex"
import Template from "pages/BancoPrecios/Template"
import SubscriptionPage from "pages/GestionEmpresa/subscriptionPage"
import SubscriptionPageAnual from "pages/GestionEmpresa/subscriptionPageAnual"
import SubscriptionPayment from "components/GestionEmpresa/subscriptionPayment"
import FavoritesPage from "pages/BancoPrecios/favoritesPage"
import Profile from "pages/ProfileRefinament/Profile"
import PasswordRecoveryFlow from "pages/Authentication 2.0/PasswordFlow"
import newCreateAct from "pages/NewActs/indexCreateAct"
import NewEditAct from "pages/NewActs/indexEditAct"
import PartGenerator from "pages/Demo/PartGenerator"
import InvoicesDashboard from "pages/Invoice/dashboard"
import InvoicePage from "pages/Invoice/invoicePage"
import IndexActas from "pages/Actas/indexActas"
import TemplateManagement from "pages/TemplatesManagement"
import BudgetingIndex from "pages/BudgetingPage2023/BudgetingIndex"
import IndexProjectPage from "pages/Document/insideProjectPage"
import NewDashboard from "pages/Dashboard/newDashboard"
import NewPlantillaPdf from "pages/NewPlantillaPdf/newPlantillaPdf"
import TemplatePageV2 from "pages/BancoPrecios/templatePageV2"
import CertificacionesIndex from "pages/Certificaciones/CertificacionesIndex"
import CertificationsHistoryIndex from "pages/CertificationsHistory/CertificationsHistoryIndex"
import ExtrasAndContradictionsIndex from "pages/ExtrasAndContradictions/ExtrasAndContradictionsIndex"
import DynamicElementsIndex from "pages/DynamicElements/DynamicElementsIndex"
const authProtectedRoutes = [
  //demo

  { path: "/dashboard", component: NewDashboard },
  { path: "/contacts", component: Contacts },

  // //profile
  { path: "/profile", component: Profile, NavContent: ProfileNav2 },
  {
    path: "/subscription",
    component: SubscriptionPage,
    NavContent: ProfileNav3,
  },
  {
    path: "/subscriptionAnual",
    component: SubscriptionPageAnual,
    NavContent: ProfileNav3,
  },
  {
    path: "/subscription/:planName",
    component: SubscriptionPayment,
    NavContent: ProfileNav3,
  },
  {
    path: "/gestionEquipo",
    component: Teams,
    NavContent: ProfileNavTeamManagementSection,
  },
  { path: "/budgetingCalc/:projectId", component: Budgeting },
  { path: "/invoices/", component: InvoicesDashboard },
  {
    path: "/invoices/:projectId",
    component: InvoicesDashboard,
    NavContent: FacturasNav,
  },
  {
    path: "/invoice/:invoiceId",
    component: InvoicePage,
    NavContent: FacturasNav,
  },
  {
    path: "/templateManagement",
    component: TemplateManagement,
    NavContent: ProfileNav4,
  },
  {
    path: "/budgeting/:projectId",
    component: BudgetingIndex,
    NavContent: BudgetingNav,
  },
  {
    path: "/certificaciones/:projectId",
    component: CertificacionesIndex,
    NavContent: CertificacionesNav,
  },
  {
    path: "/certhistory/:projectId",
    component: CertificationsHistoryIndex,
    NavContent: CertHistoryNav,
  },
  // { path: "/extras/:projectId", component: BudgetingIndex, NavContent: ExtrasAndContradictionsNav },
  // utilizo el BudgetingIndex pero el problema es que luego al moverse de una tab al otra no se actualiza el arbol y queda con los caps de extras y contradict (posiblemente habria que hacer algo parecido como lo hace en certificaciones, revisar el CertificacionesIndex)
  {
    path: "/extras/:projectId",
    component: ExtrasAndContradictionsIndex,
    NavContent: ExtrasAndContradictionsNav,
  },

  {
    path: "/actas/:projectId",
    component: IndexActas,
    NavContent: ActasNav,
  },

  { path: "/equipos/:projectId", component: Teams, NavContent: SummaryNav },

  {
    path: "/budgeting/providers/:projectId",
    component: BudgetingProviders,
    NavContent: ProvidersNav,
  },
  {
    path: "/versions/:projectId/:index",
    component: BudgetVersion,
    NavContent: BudgetingNav,
  },
  {
    path: "/versions/final/:projectId/:index",
    component: BudgetVersionFinal,
    NavContent: BudgetingNav,
  },
  {
    path: "/generalProfile",
    component: GeneralProfile,
    NavContent: ProfileNav,
  },
  {
    path: "/planning/kanban/:projectId",
    component: PlanningKanbanV1,
    NavContent: PlanningNav,
  },
  {
    path: "/bancodeprecios",
    component: BancoPreciosIndex,
    NavContent: BancoDePreciosNav,
  },
  {
    path: "/bancodeprecios/referencia/:referencia",
    component: BancoDePreciosReferenciaC,
    NavContent: BancoDePreciosNavCype,
  },
  {
    path: "/bancodeprecios/referencia/:referencia/:tipo",
    component: BancoDePreciosReferenciaC,
    NavContent: BancoDePreciosNavCype,
  },
  {
    path: "/bancodeprecios/templates",
    component: Template,
    NavContent: BancoDePreciosNavTemplate,
  },
  {
    path: "/bancodeprecios/favorites/myBP",
    component: FavoritesPage,
    NavContent: BancoDePreciosNavFavorites,
  },
  {
    path: "/bancodeprecios/favorites/referenciaC",
    component: FavoritesPage,
    NavContent: BancoDePreciosNavFavorites,
  },
  {
    path: "/bancodeprecios/favorites/referenciaB",
    component: FavoritesPage,
    NavContent: BancoDePreciosNavFavorites,
  },
  {
    path: "/bancodeprecios/favorites/referenciaC",
    component: FavoritesPage,
    NavContent: BancoDePreciosNavFavorites,
  },
  {
    path: "/bancodeprecios/favorites/templates",
    component: FavoritesPage,
    NavContent: BancoDePreciosNavFavorites,
  },
  {
    path: "/bancodeprecios/template/:templateId",
    component: TemplatePage,
    NavContent: BancoDePreciosNavTemplate,
  },
  {
    path: "/bancodeprecios/templateV2/:projectId",
    component: TemplatePageV2,
    NavContent: BancoDePreciosNavTemplateUnique,
  },
  {
    path: "/createAct/:projectId/",
    component: newCreateAct,
    NavContent: SummaryNav,
  },
  {
    path: "/editAct/:projectId/:actId",
    component: NewEditAct,
    NavContent: SummaryNav,
  },
  {
    path: "/bancodeprecios/select",
    exact: true,
    component: SelectReferencia,
    NavContent: BancoDePreciosNavCype,
  },
  {
    path: "/bancodeprecios/dynamic-element",
    exact: true,
    component: DynamicElementsIndex,
    NavContent: BancoDePreciosDynamicElements,
  },

  { path: "/documents", component: Document, NavContent: DocumentsNav },
  {
    path: "/documents/:projectId",
    component: IndexProjectPage,
    NavContent: DocumentsNav,
  },
  { path: "/onboarding", component: SurveyTypeForm },

  //ruta especifica para mostrar modal de partidas
  { path: "/modalpartidas", component: ModalPartidas },
  { path: "/general-pdf", component: NewPlantillaPdf, NavContent: ProfileNav6 },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/forgot-password", component: PasswordRecoveryFlow },
  { path: "/demo/generador-partida", component: PartGenerator },
]

export { publicRoutes, authProtectedRoutes }
