import styled from "styled-components"
import { Grid, Button, backdropClasses } from "@mui/material"

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "10px",
}
export const ModalInfo = {
  position: "absolute",
  top: "0%",
  right: "0%",
  height: "100%",
  width: "400px",
  backgroundColor: "background.paper",
  borderRadius: "16px 0px 0px 16px",
  overflowY: "scroll",
}

export const ActionModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "background.paper",
  padding: "10px",
  borderRadius: "16px",
  width: "393px",
}

export const TagsInput = styled(Grid)`
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 409px;
  padding: 0.5rem;
`
export const CustomButton = styled(Button)`
  width: 140px;
  height: 48px;
  background: #ffd52b;
  border-radius: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #414141;
`
