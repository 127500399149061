import React, { useContext } from "react"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { useState } from "react"
import i18n from "i18n"
import CloseIcon from "@mui/icons-material/Close"
import Budget2023Context from "context/budget2023/Budget2023Context"
import { VersionsTable } from "common/tables"
import ProjectContext from "context/projects/projectContext"
import { useHistory, useParams } from "react-router-dom"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "850px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalVersiones = ({ open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle)
  const { projectId } = useParams()
  const classes = useStyles()
  const { versions } = useContext(ProjectContext)
  const { changeVersion } = useContext(Budget2023Context)
  const history = useHistory()

  const handleVersion = async (version, index) => {
    await changeVersion(version)
    history.push(`/versions/${projectId}/${index}`)
  }

  return (
    <Modal open={open} onClose={() => null}>
      <div style={modalStyle} className={classes.paper}>
        <div
          id="principalOptions"
          style={{ marginTop: "20px", marginBottom: "15px", textAlign: "left" }}
        >
          <CloseIcon
            id="cerrar"
            style={{ marginTop: "-15px", right: "-10px" }}
            onClick={() => {
              setOpen(false)
            }}
          ></CloseIcon>
          <p
            id="titles"
            style={{
              textAlign: "left",
              fontSize: "20px",
              marginBottom: "20px",
            }}
          >
            {i18n.t("Versiones")}
          </p>
          <VersionsTable>
            <tr>
              <th style={{ width: "310px" }}>{i18n.t("Nombre Archivo")}</th>
              <th style={{ width: "330px" }}>{i18n.t("Accesos")}</th>
              <th style={{ width: "140px" }}>{i18n.t("Fecha Cerrada")}</th>
            </tr>
            {versions.length > 0
              ? versions.map((version, index) =>
                  version.name ? (
                    <tr
                      key={index}
                      onClick={() => {
                        handleVersion(version, index)
                      }}
                    >
                      <td>{version.name}</td>
                      <td>
                        <div className="inline">
                          <div
                            style={{
                              borderRadius: "100px",
                              width: "20px",
                              height: "20px",
                              backgroundColor: "#C4C4C4",
                              marginRight: "5px",
                            }}
                          ></div>
                          <div
                            style={{
                              borderRadius: "100px",
                              width: "20px",
                              height: "20px",
                              backgroundColor: "#FFD52B",
                            }}
                          ></div>
                        </div>
                      </td>
                      <td>{new Date(version.closedAt).toLocaleDateString()}</td>
                    </tr>
                  ) : null,
                )
              : null}
          </VersionsTable>
        </div>
      </div>
    </Modal>
  )
}

ModalVersiones.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}
export default ModalVersiones
