import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Modal } from "@mui/material"
import ProjectContext from "context/projects/projectContext"
import ProjectDropzone from "components/Dropzone/ProjectDropzone"
import ModalBC3 from "../../pages/Dashboard/ModalBC3"
import i18n from "i18n"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))
const UploadModal2 = ({ openModal, setOpenModal, proyecto, setWaitModal }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const projectContext = useContext(ProjectContext)
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [notBC3, setNotBC3] = useState(false)
  const { UploadProject } = projectContext
  const [open, setOpen] = useState(false)

  const onUpload = async () => {
    for (let i = 0; i < selectedFiles.length; i++) {
      let extension = selectedFiles[i].path.substr(
        selectedFiles[i].path.length - 3,
      )
      if (extension == "bc3" || extension == "BC3") {
        await UploadProject({ file: selectedFiles[i], infoProject: proyecto })
      }
    }
    setWaitModal(true)
    setOpenModal(false)
  }

  useEffect(() => {
    if (notBC3 == true) {
      setOpen(true)
    }
  }, [notBC3])

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          null
        }}
      >
        <>
          <div style={modalStyle} className={classes.paper}>
            <div className={"py-4"}>
              <p
                className="text-center my-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "23px",
                  color: "#414141",
                }}
              >
                {i18n.t("Sube tus mediciones para obtener tu presupuesto")}
              </p>
              <button
                type="button"
                onClick={() => {
                  setOpenModal(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "0 5rem" }}>
              <ProjectDropzone
                proyecto={proyecto}
                selectedFiles={selectedFiles}
                setselectedFiles={setSelectedFiles}
                setNotBC3={setNotBC3}
              />
            </div>

            <div id="centerButton">
              {selectedFiles.length == 0 ? (
                <button
                  type="button"
                  className="AddProjectDisabled"
                  style={{
                    marginTop: "10px",
                    marginBottom: "20px",
                    position: "relative",
                    zIndex: "1",
                  }}
                >
                  {i18n.t("Subir")}
                </button>
              ) : (
                <button
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="Bc3Uploaded"
                  data-amplify-analytics-attrs={selectedFiles}
                  type="button"
                  className="AddContact"
                  onClick={onUpload}
                  style={{
                    marginTop: "-25px",
                    marginBottom: "20px",
                    position: "relative",
                    zIndex: "1",
                  }}
                >
                  {i18n.t("Subir")}
                </button>
              )}
            </div>
          </div>
        </>
      </Modal>
      <ModalBC3 open={open} setOpen={setOpen} setNotBC3={setNotBC3} />
    </>
  )
}

UploadModal2.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  proyecto: PropTypes.object,
  setWaitModal: PropTypes.func.isRequired,
}

export default UploadModal2
