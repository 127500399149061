import { Auth, API } from "aws-amplify"
import { useState, useMemo } from "react"
export const useSearch = () => {
  const API_NAME = "prod-Planhopper-Search"
  const [items, setItems] = useState([])
  const [detailedInfo, setDetailedInfo] = useState(null)
  const itemsMemo = useMemo(() => items, [items])

  const queryPartida = async term => {
    try {
      const payload = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        queryStringParameters: {
          q: term,
        },
      }
      const { resultados } = await API.get(API_NAME, "/search", payload)
      setItems(
        resultados.map(item => {
          return {
            id: item._id,
            price: item._source.price.M.E.N,
            resumen: item._source.resumen_ext.S,
            name: item._source.resumen.S,
            unity: item._source.unity.S,
            accountId: item._source.accountId ? item._source.accountId.S : null,
            comeFrom: item._source.bucket ? "Tribboo" : "Custom",
          }
        }),
      )
    } catch (error) {
      console.log(error)
    }
  }
  const getDetailedInfo = async partidaId => {
    const payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    const res = await API.get(
      "dev-PLANHOPPER-API",
      `/api/budget/partida/${partidaId}`,
      payload,
    )
    setDetailedInfo(res)
  }

  return {
    items,
    setItems,
    queryPartida,
    getDetailedInfo,
    detailedInfo,
    setDetailedInfo,
    itemsMemo,
  }
}
