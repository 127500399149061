export const getChapterParentId = async (
  chapterId,
  capitulos,
  trobat = false,
  i = 0,
  parentId = "",
) => {
  while (!trobat && i < capitulos.length) {
    if (capitulos[i].chapterId == chapterId) {
      parentId = capitulos[i].parentId
      trobat = true
    } else {
      if (capitulos[i].subcapitulos.length > 0) {
        parentId = await getChapterParentId(
          chapterId,
          capitulos[i].subcapitulos,
          trobat,
          0,
          parentId,
        )
      }
      i++
    }
  }
  return parentId
}
export const getChapterParentId2 = async (
  chapterId,
  capitulos,
  trobat = false,
  i = 0,
  parentId = {},
) => {
  while (!trobat && i < capitulos.length) {
    if (capitulos[i].chapterId == chapterId) {
      parentId = capitulos[i]
      trobat = true
    } else {
      if (capitulos[i].subcapitulos.length > 0) {
        parentId = await getChapterParentId2(
          chapterId,
          capitulos[i].subcapitulos,
          trobat,
          0,
          parentId,
        )
      }
      i++
    }
  }
  return parentId
}
export const getChapterById = async (
  chapterId,
  capitulos,
  trobat = false,
  i = 0,
  chapter = {},
) => {
  while (!trobat && i < capitulos.length) {
    if (capitulos[i].chapterId == chapterId) {
      chapter = capitulos[i]
      trobat = true
    } else {
      if (capitulos[i].subcapitulos.length > 0) {
        chapter = await getChapterById(
          chapterId,
          capitulos[i].subcapitulos,
          trobat,
          0,
          chapter,
        )
      }
      i++
    }
  }

  return chapter
}

export const getChapterByParentAndPosition = async (
  parentId,
  capitulos,
  position,
  trobat = false,
  i = 0,
  chapter = {},
) => {
  while (!trobat && i < capitulos.length) {
    if (capitulos[i].parentId == parentId) {
      if (position == capitulos[i].capituloInfo.position) {
        chapter = capitulos[i]
        trobat = true
      } else {
        i++
      }
    } else {
      if (capitulos[i].subcapitulos.length > 0) {
        chapter = await getChapterByParentAndPosition(
          parentId,
          capitulos[i].subcapitulos,
          position,
          trobat,
          0,
          chapter,
        )
      }
      i++
    }
  }

  return chapter
}

export const swapPartidasPosition = (partida1, partida2) => {
  // Swap the parts
  let temp = partida1.position
  partida1.position = partida2.position
  partida2.position = temp
  return { partida1: partida1, partida2: partida2 }
}

export const getPartByParentAndPosition = async (
  parentId,
  capitulos,
  position,
  trobat = false,
  i = 0,
  chapter = {},
) => {
  while (!trobat && i < capitulos.length) {
    if (capitulos[i].chapterId == parentId) {
      let j = 0
      let trobat2 = false
      while (!trobat2 && j < capitulos[i].partidas.length) {
        if (position == capitulos[i].partidas[j].position) {
          chapter = capitulos[i].partidas[j]
          trobat = true
          trobat2 = true
        } else {
          j++
        }
      }
      i++
    } else {
      if (capitulos[i].subcapitulos.length > 0) {
        chapter = await getPartByParentAndPosition(
          parentId,
          capitulos[i].subcapitulos,
          position,
          trobat,
          0,
          chapter,
        )
      }
      i++
    }
  }

  return chapter
}

export const changesPartidasById = async (
  chapterId,
  capitulos,
  trobat = false,
  i = 0,
  partidas,
) => {
  while (!trobat && i < capitulos.length) {
    if (capitulos[i].chapterId == chapterId) {
      capitulos[i].partidas = partidas
      trobat = true
    } else {
      if (capitulos[i].subcapitulos.length > 0) {
        partidas = await changesPartidasById(
          chapterId,
          capitulos[i].subcapitulos,
          trobat,
          0,
          partidas,
        )
      }
      i++
    }
  }
}

export const getPartIndex = async (chapter, partId) => {
  let i = 0
  let trobat = false
  while (!trobat && i < chapter.partidas.length) {
    if (chapter.partidas[i].partidaId == partId) {
      trobat = true
    } else {
      i++
    }
  }
  return i
}

export const getChapterIndex = async (parentChapter, chapterId) => {
  let i = 0
  let trobat = false
  while (!trobat && i < parentChapter.subcapitulos.length) {
    if (parentChapter.subcapitulos[i].chapterId == chapterId) {
      trobat = true
    } else {
      i++
    }
  }
  return i
}

export const parseDecomposition = parts => {
  if (parts[i].desglose) {
    Object.keys(parts[i].desglose).forEach(key => {
      decomposition.push({
        description: parts[i].desglose[key]["RESUMEN"],
        quantity: parseFloat(
          parseFloat(parts[i].desglose[key]["RENDIMIENTO"]).toFixed(2),
        ),
        price: parseFloat(
          parseFloat(parts[i].desglose[key]["PRECIO"]["E"]).toFixed(2),
        ),
        type: parseInt(parts[i].desglose[key]["TIPO"]) > 1 ? 0 : 1,
        unity: parts[i].desglose[key]["UNIDAD"],
      })
    })
  }
}

export const getPreviousPartByParentAndPosition = async (
  parentId,
  capitulos,
  position,
  trobat = false,
  i = 0,
  chapter = {},
) => {
  while (!trobat && i < capitulos.length) {
    if (capitulos[i].chapterId == parentId) {
      let j = 0
      let trobat2 = false
      while (!trobat2 && j < capitulos[i].partidas.length) {
        if (position == capitulos[i].partidas[j].position) {
          chapter = capitulos[i].partidas[j - 1]
          trobat = true
          trobat2 = true
        } else {
          j++
        }
      }
      i++
    } else {
      if (capitulos[i].subcapitulos.length > 0) {
        chapter = await getPreviousPartByParentAndPosition(
          parentId,
          capitulos[i].subcapitulos,
          position,
          trobat,
          0,
          chapter,
        )
      }
      i++
    }
  }

  return chapter
}

export const getNextPartByParentAndPosition = async (
  parentId,
  capitulos,
  position,
  trobat = false,
  i = 0,
  chapter = {},
) => {
  while (!trobat && i < capitulos.length) {
    if (capitulos[i].chapterId == parentId) {
      let j = 0
      let trobat2 = false
      while (!trobat2 && j < capitulos[i].partidas.length) {
        if (position == capitulos[i].partidas[j].position) {
          chapter = capitulos[i].partidas[j + 1]
          trobat = true
          trobat2 = true
        } else {
          j++
        }
      }
      i++
    } else {
      if (capitulos[i].subcapitulos.length > 0) {
        chapter = await getNextPartByParentAndPosition(
          parentId,
          capitulos[i].subcapitulos,
          position,
          trobat,
          0,
          chapter,
        )
      }
      i++
    }
  }

  return chapter
}
