import { CapituloMini } from "common/containers"
import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import AuthContext from "context/auth/authContext"
import { getChapterById } from "utils/budgetUtils"
import { KeyboardArrowLeftRounded } from "@mui/icons-material"
import PartTableAuto from "../selectParts/indexAuto"
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext"

const CapituloModal = ({
  capitulo,
  estoyEnBusqueda,
  setChaptersToShow,
  chaptersToShow,
  width,
  ml,
  numeroArray,
  bankSelected,
  tipo,
  disabled,
  setDisabled,
  loadingButton,
  setLoadingButton,
  doTheAction,
  setDoTheAction,
  setOpen,
}) => {
  const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
  const [subCapsLoaded, setSubCapsLoaded] = useState(false)
  const { getSubCaps, myPricesBank } = useContext(
    bankSelected == "User database"
      ? BankPricesContext
      : BankPricesReferenceContext,
  )

  const { user } = useContext(AuthContext)
  const handleOpen = async e => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    setOpenNotSendCapitulo(prev => !prev)

    const copia2 = JSON.parse(JSON.stringify(chaptersToShow))
    if (bankSelected == "User database") {
      await getSubCaps(capitulo.chapterId, user.sub)
    } else {
      await getSubCaps(
        capitulo.chapterId,
        tipo,
        bankSelected == "Cype database"
          ? "cype"
          : bankSelected == "Bedec database"
            ? "bedec"
            : "tribboo",
      )
    }
    let subcapitulosGenerados = await getChapterById(
      capitulo.chapterId,
      myPricesBank.subcapitulos,
      false,
      0,
      {},
    )
    let auxiliar = chaptersToShow[chaptersToShow.length - 1]
    await getPath(auxiliar, capitulo.chapterId, subcapitulosGenerados)
    setChaptersToShow([...copia2, auxiliar])
    setSubCapsLoaded(true)
  }
  async function getPath(auxiliar, capituloId, subcapitulosGenerados) {
    if (auxiliar.subcapitulos.length == 1) {
      getPath(auxiliar.subcapitulos[0], capituloId, subcapitulosGenerados)
    } else {
      let i = 0
      let trobat = false
      let aux2 = []
      while (i < auxiliar.subcapitulos.length && !trobat) {
        if (auxiliar.subcapitulos[i].chapterId === capituloId) {
          aux2 = auxiliar.subcapitulos[i]
          aux2.subcapitulos = subcapitulosGenerados.subcapitulos
          aux2.partidas = subcapitulosGenerados.partidas
          auxiliar.subcapitulos = [aux2]
          trobat = true
        } else {
          i++
        }
      }
    }
  }

  const handleSearchPop = e => {
    let i = chaptersToShow.length
    let trobat = false
    if (chaptersToShow.length != 1) {
      if (i == numeroArray + 1) {
        chaptersToShow.pop()
      }
      while (!trobat && i != numeroArray + 1) {
        chaptersToShow.pop()
        i = chaptersToShow.length
      }
      setChaptersToShow([...chaptersToShow])
    }
  }
  useEffect(() => {
    if (capitulo.subcapitulos.length == 0) {
      setOpenNotSendCapitulo(false)
      setSubCapsLoaded(false)
    }
  }, [chaptersToShow.length])

  return (
    <>
      <CapituloMini
        style={{
          background:
            capitulo.subcapitulos.length > 1 || capitulo.partidas.length > 0
              ? "white"
              : openNotSendCapitulo
                ? "white"
                : "#FDEFC6",
          width: width + "%",
          marginLeft: ml + "px",
          animation: "fadeInAnimation ease 2s",
          animationIterationCount: "1",
          animationFillMode: "forwards",
          border:
            capitulo.subcapitulos.length > 1 || capitulo.partidas.length > 0
              ? "1px solid #FFD52B"
              : openNotSendCapitulo
                ? "1px solid #AFAFB0"
                : "0px",
          borderLeft:
            capitulo.subcapitulos.length > 1 || capitulo.partidas.length > 0
              ? "5px solid #FFD52B"
              : openNotSendCapitulo
                ? "5px solid #AFAFB0"
                : "0px",
        }}
      >
        {!estoyEnBusqueda ? (
          capitulo.subcapitulos.length > 0 || capitulo.partidas.length > 0 ? (
            <div>
              <div
                style={{
                  borderRadius: "100px",
                  border: "2px solid #FDEFC6",
                  cursor: "pointer",
                  marginLeft: "10px",
                  marginTop: "8px",
                  width: "20px",
                  height: "20px",
                  backgroundColor: "white",
                }}
              >
                <KeyboardArrowLeftRounded
                  style={{
                    color: "#FDEFC6",
                    paddingLeft: "2px",
                    marginTop: "-6px",
                  }}
                  className="dropdownArrowB"
                  onClick={e => handleSearchPop(e)}
                />
              </div>
            </div>
          ) : (
            <KeyboardArrowRightIcon
              style={{ cursor: "pointer", marginLeft: "5px", marginTop: "5px" }}
              className="dropdownArrowB"
              onClick={e => handleOpen(e)}
            />
          )
        ) : null}
        <p>{capitulo.capituloInfo.name}</p>
      </CapituloMini>

      {capitulo.subcapitulos.length > 0
        ? capitulo.subcapitulos.map((subcapitulo, index) => (
            <CapituloModal
              estoyEnBusqueda={estoyEnBusqueda}
              key={index}
              capitulo={subcapitulo}
              setChaptersToShow={setChaptersToShow}
              chaptersToShow={chaptersToShow}
              width={width - 1}
              ml={ml + 10}
              numeroArray={numeroArray + 1}
              bankSelected={bankSelected}
              tipo={tipo}
              disabled={disabled}
              setDisabled={setDisabled}
              loadingButton={loadingButton}
              setLoadingButton={setLoadingButton}
              doTheAction={doTheAction}
              setDoTheAction={setDoTheAction}
              setOpen={setOpen}
            />
          ))
        : null}

      {capitulo.partidas.length > 0 ? (
        <PartTableAuto
          parts={capitulo.partidas}
          capitulo={capitulo}
          width={width}
          ml={ml}
          bankSelected={bankSelected}
          tipo={tipo}
          disabled={disabled}
          setDisabled={setDisabled}
          loadingButton={loadingButton}
          setLoadingButton={setLoadingButton}
          doTheAction={doTheAction}
          setDoTheAction={setDoTheAction}
          setOpen={setOpen}
        />
      ) : null}
    </>
  )
}

CapituloModal.propTypes = {
  capitulo: PropTypes.object.isRequired,
  setChaptersToShow: PropTypes.func.isRequired,
  chaptersToShow: PropTypes.array.isRequired,
  width: PropTypes.number,
  ml: PropTypes.number,
  numeroArray: PropTypes.number,
  bankSelected: PropTypes.string,
  tipo: PropTypes.string,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  loadingButton: PropTypes.bool,
  setLoadingButton: PropTypes.func,
  doTheAction: PropTypes.bool,
  setDoTheAction: PropTypes.func,

  setOpen: PropTypes.func,
  estoyEnBusqueda: PropTypes.bool,
}

export default CapituloModal
