import React from "react"
import PropTypes from "prop-types"
import { Form, Card, Row, Col } from "reactstrap"
import CloseIcon from "@mui/icons-material/Close"
import UploadRoundedIcon from "@mui/icons-material/UploadRounded"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import i18n from "i18n"

const ImportTemplateDropzone = ({ selectedFiles, setselectedFiles }) => {
  function handleAcceptedFiles(files) {
    let aux = files.map(file => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setselectedFiles([...selectedFiles, ...aux])
  }

  const removeFile = f => {
    let filteredArray = selectedFiles.filter(item => item !== f)
    setselectedFiles(filteredArray)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  return (
    <Form>
      <Dropzone
        className="dropzone"
        accept={[".bc3", ".BC3"]}
        maxFiles={1}
        onDrop={acceptedFiles => {
          handleAcceptedFiles(acceptedFiles)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone2">
            <div className="dz-message needsclick mt-2" {...getRootProps()}>
              <input {...getInputProps()} />
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                {i18n.t("Arrastra archivos o explora tu ordenador")}
              </p>
              <div className="mb-3">
                <UploadRoundedIcon style={{ height: "60px", width: "60px" }} />
              </div>
              <p
                style={{
                  fontWeight: "300",
                  fontSize: "14px",
                  lineHeight: "16px",
                  textAlign: "center",
                }}
              >
                {i18n.t(
                  "El formato en los que deberás cargar los archivos debe ser",
                )}
                {" BC3"}
              </p>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f, i) => {
          return (
            <Card
              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              key={i + "-file"}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <i className="bx bx-file" />
                  </Col>
                  <Col>
                    <Link to="#" className="text-muted font-weight-bold">
                      {f.name}
                    </Link>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                  <CloseIcon
                    style={{
                      marginRight: "1px",
                      width: "45px",
                      marginTop: "-20px",
                    }}
                    onClick={() => {
                      removeFile(f)
                    }}
                  />
                </Row>
              </div>
            </Card>
          )
        })}
      </div>
    </Form>
  )
}
ImportTemplateDropzone.propTypes = {
  selectedFiles: PropTypes.array,
  setselectedFiles: PropTypes.func,
}
export default ImportTemplateDropzone
