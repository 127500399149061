import i18n from "i18n"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import Budget2023Context from "context/budget2023/Budget2023Context"
function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "500px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const ModalSuccesPaste = ({
  setSuccessModal,
  successModal,
  templateId,
  setOpenPrincipal,
  setOpenSegundo,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const history = useHistory()
  const { cleanChapters, cleanParts } = useContext(Budget2023Context)
  const handleModals = () => {
    cleanChapters()
    cleanParts()
    setSuccessModal(false)
    setOpenPrincipal(false)
    setOpenSegundo(false)
  }

  return (
    <>
      <Modal
        open={successModal}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <div id="centerPaco">
            <img
              src={"https://media.tribboo.co/images/paquitoListo.png"}
              style={{ width: "150px", height: "150px", marginBottom: "10px" }}
            ></img>
          </div>
          <button
            type="button"
            onClick={() => {
              setSuccessModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-body">
            <div>
              <div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                    fontFamily: "Roboto",
                  }}
                >
                  {i18n.t("Selección añadida con éxito!")}
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    marginLeft: "40px",
                    marginRight: "40px",
                    color: "#414141",
                  }}
                >
                  {i18n.t(
                    " Ahora puedes seguir añadiendo capítulos y partidas a tus plantillas desde tu presupuesto, o bien ir a gestionar tu plantilla",
                  )}
                </p>
                <div
                  id=""
                  style={{
                    width: "100%",
                    marginBottom: "35px",
                    marginTop: "30px",
                  }}
                >
                  <button
                    onClick={() => {
                      handleModals()
                    }}
                    className="AddProject"
                    style={{
                      marginRight: "0%",
                      marginLeft: "30px",
                      paddingRight: "0px",
                      width: "180px",
                      position: "absolute",
                      border: "2px solid #FFD52B",
                      background: "white",
                    }}
                  >
                    {i18n.t("Volver")}
                  </button>
                  <button
                    onClick={() => {
                      history.push("/bancodeprecios/template/" + templateId)
                    }}
                    className="AddProject"
                    style={{
                      paddingRight: "10px",
                      marginLeft: "220px",
                      cursor: "pointer",
                      position: "relative",
                      bottom: "-30px",
                      width: "180px",
                      height: "50px",
                      border: "2px solid #FFD52B",
                      background: "white",
                    }}
                  >
                    {i18n.t("Ir a plantilla")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
ModalSuccesPaste.propTypes = {
  setSuccessModal: PropTypes.func.isRequired,
  successModal: PropTypes.bool.isRequired,
  templateId: PropTypes.object.isRequired,
  setOpenPrincipal: PropTypes.func.isRequired,
  setOpenSegundo: PropTypes.func.isRequired,
}
export default ModalSuccesPaste
