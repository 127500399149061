import React, { useState, useEffect, useContext } from "react"
import { HelveticaBold } from "common/typographies"
import i18n from "i18n"
import circlePlus from "assets/images/icons/circlePlus.svg"
import ModalSelectContact from "components/Facturas/modals/modalSelectContact"
import PropTypes from "prop-types"
import { EmptyHelveticaInput } from "common/inputs"
import AuthContext from "context/auth/authContext"
import DropdownSubjectStatus from "./DropdownSubjectStatus"
import AddFilesModal from "../modals/addFilesModal"
import pdfIcon from "assets/images/icons/pdf-big.png"
import jpg from "assets/images/icons/jpg.png"
import { DeleteOutline } from "@mui/icons-material"

const AsuntosComponent = ({
  actaValues,
  deleteAsunto,
  setActaValues,
  index,
  actNum,
  actasRefs,
}) => {
  const { user } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const [selectedContact, setSelectedContact] = useState({})
  useEffect(() => {
    const newActaValues = { ...actaValues }
    newActaValues.subjects[index].responsible = selectedContact
    actasRefs.subjectsRefs[index].responsible.current.style.border = "none"
    actasRefs.subjectsRefs[index].responsible.current.style.borderLeft =
      "1px solid #c4c4c4"
    setActaValues(newActaValues)
  }, [selectedContact])

  useEffect(() => {
    if (actaValues.subjects[index].responsible2) {
      setSelectedContact(actaValues.subjects[index].responsible2)
    }
    if (actaValues.subjects[index].status) {
      setStatus(actaValues.subjects[index].status)
    }
    if (actaValues.subjects[index].files.length > 0) {
      setSelectedFiles([
        {
          name: actaValues.subjects[index].files[0].url.substring(
            actaValues.subjects[index].files[0].url.lastIndexOf("/") + 1,
          ),
          type: actaValues.subjects[index].files[0].url.substring(
            actaValues.subjects[index].files[0].url.lastIndexOf(".") + 1,
          ),
        },
      ])
    }
  }, [actaValues.subjects[index]])

  const getAsuntoNumber = () => {
    let asuntoNumber = actNum + "."
    if (index < 10) {
      asuntoNumber = asuntoNumber + "0" + (index + 1).toString()
    } else {
      asuntoNumber = asuntoNumber + (index + 1).toString()
    }
    return asuntoNumber
  }

  const changeDescription = e => {
    const newActaValues = { ...actaValues }
    newActaValues.subjects[index].subject = e.target.value
    newActaValues.subjects[index].code = getAsuntoNumber()
    actasRefs.subjectsRefs[index].subject.current.style.border = "none"
    actasRefs.subjectsRefs[index].subject.current.style.borderLeft =
      "1px solid #c4c4c4"
    setActaValues(newActaValues)
  }
  const [status, setStatus] = useState("Pendiente")

  useEffect(() => {
    const newActaValues = { ...actaValues }
    newActaValues.subjects[index].status = status
    setActaValues(newActaValues)
  }, [status])

  const [openFiles, setOpenFiles] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])

  return (
    <>
      <tr className="hoverRow">
        <td>{getAsuntoNumber()}</td>
        <td ref={actasRefs.subjectsRefs[index].subject}>
          <EmptyHelveticaInput
            style={{ width: "100%" }}
            placeholder={i18n.t("Introducir asunto...")}
            onChange={changeDescription}
            value={actaValues.subjects[index].subject}
          />
        </td>
        <td>
          <DropdownSubjectStatus
            status={status}
            handleChangeStatus={setStatus}
          />
        </td>
        <td
          ref={actasRefs.subjectsRefs[index].responsible}
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(true)}
        >
          {Object.keys(selectedContact).length > 0 ? (
            <div className="inline">
              <img
                style={{ marginLeft: "-8px" }}
                src={
                  user
                    ? user["custom:avatar"]
                      ? user["custom:avatar"]
                      : ""
                    : ""
                }
                width={35}
                height={35}
              />
              <HelveticaBold
                style={{
                  fontWeight: "300",
                  color: "#414141",
                  fontSize: "14px",
                  marginLeft: "5px",
                  marginTop: "7px",
                  marginBottom: "0px",
                }}
              >
                {selectedContact.completeName}
              </HelveticaBold>
            </div>
          ) : (
            <div className="inline">
              <img src={circlePlus} width={20} />
              <HelveticaBold
                style={{
                  fontWeight: "300",
                  fontSize: "14px",
                  marginLeft: "5px",
                  lineHeight: "10px",
                  marginTop: "4px",
                  marginBottom: "0px",
                }}
              >
                {i18n.t("Buscar contactos")}
              </HelveticaBold>
            </div>
          )}
        </td>
        <td style={{ cursor: "pointer", position: "relative" }}>
          {selectedFiles.length == 0 ? (
            <div
              className="inline"
              onClick={() => {
                setOpenFiles(true)
              }}
            >
              <img src={circlePlus} width={20} />
              <HelveticaBold
                style={{
                  fontWeight: "300",
                  fontSize: "14px",
                  marginLeft: "5px",
                  lineHeight: "10px",
                  marginTop: "4px",
                  marginBottom: "0px",
                }}
              >
                {i18n.t("Buscar archivos")}
              </HelveticaBold>
            </div>
          ) : (
            <div className="inline">
              {selectedFiles[0].name &&
              selectedFiles[0].name.substring(
                selectedFiles[0].name.length - 3,
              ) == "pdf" ? (
                <img src={pdfIcon} width={18} />
              ) : (
                <img src={jpg} width={24} style={{ marginLeft: "-3px" }} />
              )}
              <HelveticaBold
                style={{
                  fontWeight: "300",
                  paddingTop: "3px",
                  fontSize: "14px",
                  marginLeft: "5px",
                  lineHeight: "10px",
                  marginTop: "4px",
                  marginBottom: "0px",
                  color: "#414141",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "block",
                  overflow: "hidden",
                  paddingRight: "40px",
                }}
              >
                {selectedFiles[0].name
                  ? selectedFiles[0].name
                  : selectedFiles[0].filename}
              </HelveticaBold>
            </div>
          )}
          {actaValues?.subjects.length != 1 && (
            <DeleteOutline
              className="hoverIcon"
              style={{
                cursor: "pointer",
                display: "none",
                position: "absolute",
                fontSize: "20px",
                right: "10px",
                top: "8px",
              }}
              onClick={() => {
                deleteAsunto(index)
                setSelectedFiles([])
              }}
            />
          )}
        </td>
      </tr>
      {open && (
        <ModalSelectContact
          open={open}
          setOpen={setOpen}
          setContactSelected={setSelectedContact}
        />
      )}
      {openFiles && (
        <AddFilesModal
          index={index}
          actaValues={actaValues}
          setActaValues={setActaValues}
          open={openFiles}
          setOpen={setOpenFiles}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      )}
    </>
  )
}

AsuntosComponent.propTypes = {
  actaValues: PropTypes.object.isRequired,
  setActaValues: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  actNum: PropTypes.string.isRequired,
  actasRefs: PropTypes.array.isRequired,
  deleteAsunto: PropTypes.func.isRequired,
}

export default AsuntosComponent
