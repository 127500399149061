import React, { useContext, useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { makeStyles } from "@material-ui/core/styles"
import { Modal } from "@mui/material"
import PropTypes from "prop-types"
import i18n from "i18n"
import {
  PoppinsSemiBold,
  PoppinsNormal,
  HelveticaLight,
} from "common/typographies"
import AuthContext from "context/auth/authContext"
import { VersionsTable } from "common/tables"
import { YellowButton } from "common/buttons"
import actsContext from "context/acts/actsContext"
import { useParams } from "react-router-dom"
import sendSuccess from "assets/images/paco/paquitoListo.png"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "auto",
    minWidth: "500px",
    height: "500px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalSelectAsistentesToSend = ({ open, setOpen, asistentes, idActa }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const [asistentesCopy, setAsistentesCopy] = useState([])
  const { sendAct, sendingAct } = useContext(actsContext)
  const [sended, setSended] = useState(false)
  const { actId } = useParams()
  useEffect(() => {
    if (asistentes.length > 0) {
      let auxAsistentes = []
      asistentes.forEach(asistente => {
        auxAsistentes.push({ ...asistente, checked: false })
      })
      setAsistentesCopy(auxAsistentes)
    }
  }, [asistentes])

  const changeCheck = (e, index) => {
    let auxAsistentes = [...asistentesCopy]
    auxAsistentes[index] = {
      ...asistentesCopy[index],
      checked: !asistentesCopy[index].checked,
    }
    setAsistentesCopy(auxAsistentes)
  }

  const handleSend = async e => {
    e.preventDefault()
    let auxAsistentes = [...asistentesCopy]
    let asistentesToSend = auxAsistentes.filter(asistente => asistente.checked)
    await sendAct(actId ? actId : idActa, user.sub, asistentesToSend)
    setSended(true)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setOpen(false)
            }}
          ></CloseIcon>
          {sendingAct ? null : sended ? null : (
            <PoppinsSemiBold style={{ marginTop: "20px", fontSize: "18px" }}>
              {i18n.t("Asistentes")}
            </PoppinsSemiBold>
          )}
          <div style={{ width: "100%", position: "relative" }}>
            {sended ? (
              <div
                style={{
                  position: "relative",
                  display: "block",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "80px",
                  width: "450px",
                }}
              >
                <img
                  src={sendSuccess}
                  style={{
                    width: "110px",
                    height: "110px",
                    marginBottom: "30px",
                  }}
                ></img>
                <HelveticaLight
                  style={{ fontSize: "16px", padding: "0px 50px 0px 50px" }}
                >
                  {i18n.t(
                    "¡Enhorabuena, has enviado con éxito el acta a los asistentes!",
                  )}
                </HelveticaLight>
                <YellowButton
                  onClick={() => {
                    setOpen(false)
                  }}
                  style={{
                    width: "190px",
                    height: "40px",
                    marginTop: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <PoppinsSemiBold style={{ fontSize: "14px" }}>
                    {i18n.t("Aceptar")}
                  </PoppinsSemiBold>
                </YellowButton>
              </div>
            ) : sendingAct ? (
              <>
                <div
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    position: "relative",
                    display: "flex",
                    marginLeft: "-80px",
                    marginTop: "60px",
                  }}
                >
                  <div className="loader">
                    <div className="ball"></div>
                    <div className="ball"></div>
                    <div className="ball"></div>
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    position: "relative",
                    display: "flex",
                    marginLeft: "-10px",
                  }}
                >
                  <PoppinsNormal>{i18n.t("Enviando acta...")}</PoppinsNormal>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    position: "relative",
                    display: "flex",
                    marginLeft: "-10px",
                    marginTop: "-15px",
                  }}
                >
                  <PoppinsNormal style={{ fontWeight: "400" }}>
                    {i18n.t("Esta acción puede tardar unos segundos")}
                  </PoppinsNormal>
                </div>
              </>
            ) : asistentes.length === 0 ? (
              <div
                style={{
                  position: "relative",
                  display: "block",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "80px",
                  width: "450px",
                }}
              >
                <PoppinsSemiBold style={{ fontSize: "18px" }}>
                  {i18n.t("No hay asistentes")}
                </PoppinsSemiBold>
              </div>
            ) : (
              <>
                <VersionsTable style={{ maxHeight: "350px" }}>
                  <tr>
                    <th style={{ minWidth: "20px" }}></th>
                    <th style={{ width: "300px" }} colSpan="2">
                      {i18n.t("Nombre")}
                    </th>
                    <th style={{ width: "300px" }}>{i18n.t("Email")}</th>
                    <th style={{ width: "100px" }}>{i18n.t("Rol")}</th>
                  </tr>
                  {asistentes.map((asistente, index) => (
                    <tr
                      className="hoverYellow"
                      key={index}
                      style={{ cursor: "pointer", background: "white" }}
                      onClick={e => {
                        changeCheck(e, index)
                      }}
                    >
                      <td style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <input
                          style={{ marginTop: "5px", cursor: "pointer" }}
                          type="checkbox"
                          checked={asistentesCopy[index]?.checked}
                          onClick={e => {
                            e.stopPropagation()
                            changeCheck(e, index)
                          }}
                        />
                      </td>
                      <td style={{ width: "50px" }}>
                        <img
                          src={user["custom:avatar"]}
                          style={{
                            width: "45px",
                            height: "45px",
                            marginRight: "0px",
                          }}
                        />
                      </td>
                      <td
                        style={{
                          fontWeight: "500",
                          color: "#414141",
                          paddingLeft: "0PX",
                        }}
                      >
                        {asistente.completeName}
                      </td>
                      <td style={{ color: "#414141" }}> {asistente.email}</td>
                      <td>
                        {asistente.role ? (
                          asistente.role.length > 0 ? (
                            <div
                              style={{
                                width: "fit-content",
                                paddingRight: "10px",
                              }}
                              id="color1"
                              className="tag"
                            >
                              {asistente.role[0]}
                            </div>
                          ) : null
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </VersionsTable>
                <div id="centerPaco">
                  <YellowButton
                    onClick={e => {
                      handleSend(e)
                    }}
                    style={{
                      width: "190px",
                      height: "40px",
                      marginTop: "-10px",
                      paddingTop: "10px",
                    }}
                  >
                    <PoppinsSemiBold style={{ fontSize: "14px" }}>
                      {i18n.t("Enviar")}
                    </PoppinsSemiBold>
                  </YellowButton>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
ModalSelectAsistentesToSend.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  asistentes: PropTypes.array,
  idActa: PropTypes.string,
}

export default ModalSelectAsistentesToSend
