import i18n from "i18n"
import { CircularProgress, Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import image1 from "assets/images/budgeting/1.png"
import AuthContext from "context/auth/authContext"
import ModalSuccesPaste from "./modalSuccesPaste"
import FavoritesBPContext from "context/favoritesBP/favoritesBPContext"
import TemplatesContext from "context/templates/templatesContext"
function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "500px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const ModalConfirmPaste = ({
  setSuccessModal,
  successModal,
  setOpenSegundo,
  tipo,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const {
    importChaptersFromDatabase,
    createPartsOnBudget,
    importChapters2MBP,
    importParts2MBP,
  } = useContext(FavoritesBPContext)
  const { selectedCategories2, categoriesChecked, partsChecked } =
    useContext(TemplatesContext)
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const processChapters = async () => {
    setLoading(true)
    if (categoriesChecked.length > 0) {
      await importChapters2MBP(selectedCategories2, user.sub, "root", tipo)
    } else {
      await importParts2MBP(partsChecked, user.sub, "root", tipo)
    }
    setLoading(false)
    setSuccessModal(false)
    setOpen(true)
  }
  return (
    <>
      <Modal
        open={successModal}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <div id="centerPaco">
            <img
              src={image1}
              style={{ width: "150px", height: "150px", marginBottom: "10px" }}
            ></img>
          </div>
          <button
            type="button"
            onClick={() => {
              setSuccessModal(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-body">
            <div>
              <div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                    fontFamily: "Roboto",
                  }}
                >
                  {i18n.t("¡Estás a punto de copiar tu selección!")}
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    marginLeft: "40px",
                    marginRight: "40px",
                    color: "#414141",
                  }}
                >
                  {i18n.t(
                    "Tu selección se añadirá como capitulo principal en 'Mi banco de precios'",
                  )}
                </p>
                {loading == false ? (
                  <div
                    id=""
                    style={{
                      width: "100%",
                      marginBottom: "35px",
                      marginTop: "30px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setSuccessModal(false)
                      }}
                      className="AddProject"
                      style={{
                        marginRight: "10%",
                        marginLeft: "30px",
                        paddingRight: "15px",
                        width: "180px",
                        position: "absolute",
                        border: "2px solid #FFD52B",
                        background: "white",
                      }}
                    >
                      {i18n.t("Cancelar")}
                    </button>
                    <button
                      onClick={() => {
                        processChapters()
                      }}
                      className="AddProject"
                      style={{
                        paddingRight: "15px",
                        marginLeft: "220px",
                        cursor: "pointer",
                        position: "relative",
                        bottom: "-30px",
                        width: "180px",
                        height: "50px",
                        border: "2px solid #FFD52B",
                        background: "white",
                      }}
                    >
                      {i18n.t("Aceptar")}
                    </button>
                  </div>
                ) : (
                  <div>
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: "40%", marginTop: "30px" }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ModalSuccesPaste
        successModal={open}
        setSuccessModal={setOpen}
        setOpenSegundo={setOpenSegundo}
      />
    </>
  )
}
ModalConfirmPaste.propTypes = {
  setSuccessModal: PropTypes.func.isRequired,
  successModal: PropTypes.bool.isRequired,
  setOpenSegundo: PropTypes.func.isRequired,
  tipo: PropTypes.string.isRequired,
}
export default ModalConfirmPaste
