import { useCallback, useContext } from "react"
import { useSnackbar } from "notistack"
import DynamicElementsContext from "context/dynamicElements/DynamicElementsContext"
import i18n from "i18n"

export const useDeleteElement = organizationId => {
  const { deleteElement, dispatches } = useContext(DynamicElementsContext)
  const { enqueueSnackbar } = useSnackbar()

  const handleCancelDeleteModal = isOpen => {
    dispatches.setConfirmDeleteModal(isOpen)
    dispatches.setElementToDelete(null)
  }

  const handleConfirm = useCallback(async () => {
    try {
      await dispatches.deleteElement(
        deleteElement.elementToDelete,
        organizationId,
      )
      dispatches.setConfirmDeleteModal(false)
    } catch (_error) {
      enqueueSnackbar(i18n.t("snackbar.error.deleteElement"), {
        variant: "error",
      })
    }
  }, [dispatches, deleteElement.elementToDelete, organizationId])

  const handleCloseCanNotDeleteModal = () => {
    dispatches.setElementToDelete(null)
    dispatches.setPartsRelatedToElementToDelete(null)
  }

  return {
    handleCancelDeleteModal,
    handleConfirm,
    deleteElement,
    handleCloseCanNotDeleteModal,
  }
}
