import React, { useContext, useRef, useEffect, useState } from "react"
import propTypes from "prop-types"
import { Modal, Box, IconButton, Grid, CircularProgress } from "@mui/material"
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded"
import AddContact from "components/Contacts/ContactTable/addContact"
import { FakeInput } from "common/containers"
import { KeyboardArrowDownOutlined } from "@mui/icons-material"
import { HelveticaLight } from "common/typographies"
import AuthContext from "context/auth/authContext"
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp"
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp"
import TeamsContext from "context/teams/teamsContext"
import { useParams } from "react-router-dom"
import i18n from "i18n"

const SendModal = {
  position: "absolute",
  top: "0%",
  right: "0%",
  height: "100%",
  maxWidth: "425px",
  with: "425px",
  backgroundColor: "#F8F9FA",
  paddingRight: "0px",
  borderRadius: "16px 0px 0px 16px",
  overflowY: "scroll",
  overflowX: "hidden",
}

const UserInvitationModal = ({ open, setOpen, worker }) => {
  const { projectId } = useParams()
  const [openAdd, setOpenAdd] = useState(false)
  const [cambiado, setCambiado] = useState(false)
  const [selectedRol, setSelectedRol] = useState({})
  const [selectingRol, setSelectingRol] = useState(false)
  const { user, freeTrialExpired } = useContext(AuthContext)
  const { updateWorkerCampos, getWorkersByProject, deleteWorker } =
    useContext(TeamsContext)
  useEffect(() => {
    if (cambiado == true) {
      setOpen(false)
    }
  }, [cambiado])

  useEffect(() => {
    if (worker != null) {
      setSelectedRol(worker.customRole)
      setMail(worker.email)
    }
  }, [worker])

  const [mail, setMail] = useState("")

  const handleChangeMail = e => {
    setMail(e.target.value)
  }
  const [validaMail, setValidaMail] = useState(false)
  const refEmail = useRef(null)
  const refEmailConfirm = useRef(null)
  const refEmailError = useRef(null)

  const valida = e => {
    if (e.target.name == "mail") {
      if (emailIsValid(mail)) {
        setValidaMail(true)
        refEmail.current.id = " "
        refEmailConfirm.current.id = "confirmVisible"
        refEmailError.current.id = "errorNotVisible"
      } else {
        refEmail.current.id = "errorValidation"
        setValidaMail(false)
        refEmailError.current.id = "errorVisible"
        refEmailConfirm.current.id = "confirmNotVisible"
      }
    }
  }
  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const [loading, setLoading] = useState(false)
  const handleUpdate = async e => {
    e.preventDefault()
    if (mail != "" && emailIsValid(mail)) {
      let aux = {
        ...worker,
        mail: mail,
        rol: selectedRol,
      }
      const campos = {
        empresa: worker.organization ? worker.organization : "",
        movil: worker.mobile ? worker.mobile : "",
        nif: worker.nif ? worker.nif : "",
      }
      setLoading(true)
      await updateWorkerCampos(user.sub, aux, campos, [])
      await getWorkersByProject(user.sub, projectId)
      setLoading(false)
      setOpen(false)
    }
  }

  const handleDelete = async e => {
    e.preventDefault()
    setLoading(true)
    await deleteWorker(user.sub, worker.workerId)
    await getWorkersByProject(user.sub, projectId)
    setLoading(false)
    setOpen(false)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <Box style={SendModal} component="form">
          <Grid
            container
            direction={"column"}
            marginTop={"0.5rem"}
            spacing={3}
            marginLeft={"-6px"}
            paddingRight={"10px"}
          >
            <Grid item marginLeft={"0rem"}>
              <IconButton
                sx={{
                  border: "1px solid #000001",
                  height: "25px",
                  width: "25px",
                  color: "black",
                  marginBottom: "1rem",
                }}
                onClick={() => {
                  setOpen(false)
                }}
              >
                <ArrowForwardIosRoundedIcon style={{ width: "15px" }} />
              </IconButton>
            </Grid>

            <Grid item>
              <img
                style={{ width: "85px", height: "85px" }}
                src={user["custom:avatar"]}
              />
            </Grid>

            <Grid item>
              <p
                style={{
                  fontFamily: "Helvetica",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "#414141",
                  marginLeft: "10px",
                }}
              >
                {worker ? worker.fullName : ""}
              </p>
            </Grid>
            <Grid item marginTop={"0rem"}>
              <p className="Titulos" style={{ marginLeft: "10px" }}>
                {i18n.t("Rol")}
              </p>
              <div style={{ width: "400px", marginLeft: "10px" }}>
                <FakeInput
                  style={{ backgroundColor: "transparent", width: "95%" }}
                  onClick={() => {
                    setSelectingRol(true)
                  }}
                >
                  {!selectedRol.value ? (
                    <div
                      className="inline"
                      style={{ position: "relative", width: "100%" }}
                    >
                      <HelveticaLight
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#414141",
                          width: "100%",
                          paddingTop: "8px",
                          paddingLeft: "20PX",
                        }}
                      >
                        {i18n.t("Selecciona rol")}
                      </HelveticaLight>
                      <KeyboardArrowDownOutlined
                        style={{
                          position: "absolute",
                          right: "20px",
                          marginTop: "8px",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="inline"
                      style={{ position: "relative", width: "100%" }}
                    >
                      <div
                        id="tagColor"
                        className="tag"
                        style={{
                          cursor: "pointer",
                          marginLeft: "20px",
                          width: "80px",
                          background: selectedRol.bgColor,
                          textAlign: "center",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          color: "black",
                          marginTop: "8px",
                        }}
                      >
                        {selectedRol.value}
                      </div>
                      <KeyboardArrowDownOutlined
                        style={{
                          position: "absolute",
                          right: "20px",
                          marginTop: "8px",
                        }}
                      />
                    </div>
                  )}
                </FakeInput>
                {selectingRol ? (
                  <div
                    className="inline"
                    style={{
                      width: "100%",
                      height: "62px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      onClick={() => {
                        setSelectedRol(["ADMIN.", "#78DD8E"])
                        setSelectingRol(false)
                      }}
                      id="tagColor"
                      className="tag"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        width: "80px",
                        background: "#78DD8E",
                        textAlign: "center",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      {i18n.t("ADMIN.")}
                    </div>
                    <div
                      onClick={() => {
                        setSelectedRol(["DIR.OBRA", "#FF9DE4"])
                        setSelectingRol(false)
                      }}
                      id="tagColor"
                      className="tag"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        width: "80px",
                        background: "#FF9DE4",
                        textAlign: "center",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      {i18n.t("DIR.OBRA")}
                    </div>
                    <div
                      onClick={() => {
                        setSelectedRol(["DIR.PROYECYO", "#ECAD80"])
                        setSelectingRol(false)
                      }}
                      id="tagColor"
                      className="tag"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        width: "80px",
                        background: "#ECAD80",
                        textAlign: "center",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      {i18n.t("DIR.PROYECTO")}
                    </div>
                    <div
                      onClick={() => {
                        setSelectedRol(["ARQUITECTO", "#758FFB"])
                        setSelectingRol(false)
                      }}
                      id="tagColor"
                      className="tag"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        width: "80px",
                        background: "#758FFB",
                        textAlign: "center",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      {i18n.t("ARQUITECTO")}
                    </div>
                  </div>
                ) : null}
              </div>
            </Grid>
            <Box display={"flex"} justifyContent="left" ml="10px"></Box>

            <Grid item>
              <p className="Titulos" style={{ marginLeft: "10px" }}>
                {i18n.t("Email")}
              </p>
              <div
                style={{ marginLeft: "0px", width: "90%", marginLeft: "10px" }}
              >
                <input
                  placeholder="Email"
                  name="mail"
                  value={mail}
                  onChange={e => {
                    handleChangeMail(e)
                  }}
                  ref={refEmail}
                  onBlur={e => valida(e)}
                ></input>
                <CheckCircleOutlineSharpIcon
                  style={{ width: "16px", color: "#3BA452" }}
                  ref={refEmailConfirm}
                  id="confirmNotVisible"
                ></CheckCircleOutlineSharpIcon>
                <ErrorOutlineSharpIcon
                  style={{ width: "16px", color: "#BF2929" }}
                  ref={refEmailError}
                  id="errorNotVisible"
                ></ErrorOutlineSharpIcon>
              </div>
            </Grid>
            <Grid item>
              {user ? (
                user.freeTrialExpired ||
                user["custom:role"] === "WORKER" ? null : (
                  <div
                    className="inline"
                    style={{
                      width: "100%",
                      height: "200px",
                      marginLeft: "-20px",
                      position: "relative",
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                        size={24}
                      />
                    ) : (
                      <>
                        <button
                          onClick={e => {
                            handleUpdate(e)
                          }}
                          className="AddProject"
                          style={{
                            marginRight: "10%",
                            marginLeft: "30px",
                            paddingRight: "15px",
                            width: "180px",
                            border: "2px solid #FFD52B",
                            background: "#FFD52B",
                          }}
                        >
                          {i18n.t("Guardar")}
                        </button>
                        <button
                          onClick={e => {
                            handleDelete(e)
                          }}
                          className="AddProject"
                          style={{
                            paddingRight: "15px",
                            marginLeft: "220px",
                            cursor: "pointer",
                            width: "180px",
                            height: "50px",
                            border: "2px solid #BF2929",
                            background: "white",
                            color: "#BF2929",
                          }}
                        >
                          {i18n.t("Eliminar")}
                        </button>
                      </>
                    )}
                  </div>
                )
              ) : null}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <AddContact
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        worker={worker}
        setCambiado={setCambiado}
      />
    </>
  )
}
UserInvitationModal.propTypes = {
  open: propTypes.bool.isRequired,
  setOpen: propTypes.func.isRequired,
  worker: propTypes.object.isRequired,
}
export default UserInvitationModal
