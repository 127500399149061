import { useState, useEffect } from "react"
import { API, Auth } from "aws-amplify"
import i18n from "i18n"
import { nanoid } from "nanoid"
import { formatCurrency } from "components/Invoice/invoicesTable"
import { formatValue } from "react-currency-input-field"
import { useSnackbar } from "notistack"
import { useHistory } from "react-router-dom"
import axios from "axios"
const FILTERS = [
  {
    name: i18n.t("Todos"),
    value: "ALL",
    field: null,
  },
  {
    name: i18n.t("Pendientes"),
    value: "UNPAID",
    field: "invoiceStatus",
  },
  {
    name: i18n.t("Pagadas"),
    value: "PAID",
    field: "invoiceStatus",
  },
  {
    name: i18n.t("Vencidas"),
    value: "OVERDUE",
    field: "invoiceDueDate",
  },
]
function convert_datetime_to_date(datetime_string) {
  const datetime = new Date(datetime_string)
  const day = datetime.getUTCDate().toString().padStart(2, "0")
  const month = (datetime.getUTCMonth() + 1).toString().padStart(2, "0")
  const year = datetime.getUTCFullYear()
  const date_string = `${year}-${month}-${day}`
  return date_string
}

export const useInvoice = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [filters, setFilters] = useState(FILTERS)
  const [filter, setFilter] = useState(FILTERS[0])
  const INVOICE_SERVICE = "invoiceAPI"
  const PROJECT_SERVICE = "dev-PLANHOPPER-API"
  const [invoice, setInvoice] = useState({})
  const [loading, setLoading] = useState(true) // by default is true
  const [invoices, setInvoices] = useState([])
  const [invoicesDB, setInvoicesDB] = useState([])
  const [localInvoice, setLocalInvoice] = useState(false)
  const [batchAction, setBatchAction] = useState(false)
  const [savingInvoice, setSavingInvoice] = useState(false)
  const [invoicesPdf, setInvoicesPdf] = useState([])
  const [projectError, setProjectError] = useState(false)
  const [contactError, setContactError] = useState(false)
  const [processing, setProcessing] = useState(false) // ANALYZE EXPENSES
  const [summary, setSummary] = useState({
    // handle info for global metrics
    total: 0,
    subtotal: 0,
    retention: 0,
    iva: 0,
    recargo: 0,
  })
  const [loadingPDF, setLoadingPDF] = useState(false)
  const commonHeaders = async () => {
    return {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      "Access-Control-Allow-Origin": "*",
    }
  }
  const filterBy = (field, value, listInvoices) => {
    if (field === null) {
      return listInvoices
    }
    switch (field) {
      case "invoiceStatus":
        return listInvoices.filter(inv => inv.invoiceStatus === value)
      case "invoiceDueDate":
        return listInvoices.filter(
          inv => Date.parse(inv.invoiceDueDate) < new Date(),
        )
    }
  }

  useEffect(() => {
    let filtered = []
    if (filter.field === null) {
      filtered = invoicesDB
    } else {
      filtered = filterBy(filter.field, filter.value, invoicesDB)
    }

    let total = 0,
      subtotal = 0,
      retention = 0,
      recargo = 0,
      iva = 0
    for (let inv of filtered) {
      total += inv.total
      subtotal += inv.subtotal
      retention += inv.retention
      recargo += inv.recargo
      iva += inv.iva
    }
    setInvoices(filtered)
    setSummary({ total, subtotal, retention, recargo, iva })
  }, [filter])

  const getByProject = async projectId => {
    setLoading(true)
    const response = await API.get(INVOICE_SERVICE, `/project/${projectId}`, {
      headers: await commonHeaders(),
    })
    let total = 0,
      subtotal = 0,
      retention = 0,
      recargo = 0,
      iva = 0
    for (let inv of response) {
      total += inv.total
      subtotal += inv.subtotal
      retention += inv.retention
      recargo += inv.recargo
      iva += inv.iva
    }
    setSummary({ total, subtotal, retention, recargo, iva })
    setInvoicesDB(response)
    setInvoices(response)
    setLoading(false)
  }

  const getByOrganization = async () => {
    setLoading(true)
    const response = await API.get(INVOICE_SERVICE, "/organization", {
      headers: await commonHeaders(),
    })
    let total = 0,
      subtotal = 0,
      retention = 0,
      recargo = 0,
      iva = 0
    for (let inv of response) {
      total += inv.total
      subtotal += inv.subtotal
      retention += inv.retention
      recargo += inv.recargo
      iva += inv.iva
    }
    setSummary({ total, subtotal, retention, recargo, iva })
    setInvoicesDB(response)
    setInvoices(filterBy(filter.field, filter.value, response))
    setLoading(false)
  }
  const getIdentifierNumber = async ({ projectId }) => {
    let identifierNumber = 0
    try {
      const response = await API.get(INVOICE_SERVICE, `/project/${projectId}`, {
        headers: await commonHeaders(),
      })
      identifierNumber = response.length + 1
      return identifierNumber
    } catch (err) {
      identifierNumber += 1
      return identifierNumber
    }
  }
  const buildEmptyInvoiceConcept = ({ invoice }) => {
    const aux = 0.0
    return {
      invoiceConceptId: nanoid(),
      invoiceId: invoice.invoiceId,
      accountId: invoice.accountId,
      projectId: invoice.projectId ? invoice.projectId : null,
      organizationId: invoice.organizationId,
      description: "",
      total: 0.0,
      retention: {
        name: "Retención",
        value: 0,
        type: null,
      },
      recargo: {
        name: "Recargo de equivalencia",
        value: 0,
        type: null,
      },
      iva: {
        name: "IVA",
        value: 0,
        type: null,
      },
      quantity: {
        float: aux,
        formatted: formatValue({
          value: aux.toFixed(2),
          locale: "es-ES",
          decimalSeparator: ",",
          groupSeparator: ".",
        }),
        value: aux.toFixed(2),
      },
      price: {
        float: aux,
        formatted: formatValue({
          value: aux.toFixed(2),
          locale: "es-ES",
          decimalSeparator: ",",
          groupSeparator: ".",
        }),
        value: aux.toFixed(2),
      },
      total: aux,
      subtotal: aux,
    }
  }
  const getInvoice = async ({ invoiceId, projectId }) => {
    try {
      setLoading(true)
      const response = await API.get(INVOICE_SERVICE, `/invoice/${invoiceId}`, {
        headers: await commonHeaders(),
      })
      response.concepts.map(concept => {
        concept.price = {
          float: concept.price,
          formatted: formatValue({
            value: concept.price.toFixed(2),
            locale: "es-ES",
            decimalSeparator: ",",
            groupSeparator: ".",
          }),
          value: concept.price.toFixed(2),
        }
        concept.quantity = {
          float: concept.quantity,
          formatted: formatValue({
            value: concept.quantity.toFixed(2),
            locale: "es-ES",
            decimalSeparator: ",",
            groupSeparator: ".",
          }),
          value: concept.quantity.toFixed(2),
        }
      })
      if (response.invoiceDueDate) {
        response.invoiceDueDate = convert_datetime_to_date(
          response.invoiceDueDate,
        )
      }
      if (response.invoiceDate) {
        response.invoiceDate = convert_datetime_to_date(response.invoiceDate)
      }
      setInvoice(response)
      setLoading(false)
    } catch (err) {
      const userAttributes = await Auth.currentAuthenticatedUser()
      let projectInfo = {}
      let identifierNumber = new Date().getFullYear()
      if (projectId !== null && projectId !== undefined) {
        const project = await API.get(
          PROJECT_SERVICE,
          `/api/project/${projectId}`,
          { headers: await commonHeaders() },
        )
        projectInfo = project.project
        identifierNumber += `${projectInfo.name.substring(
          0 - 3,
        )}-${await getIdentifierNumber({ projectId: projectInfo.projectId })}`
      }

      let auxinvoice = {
        invoiceId: invoiceId,
        accountId: userAttributes.attributes.sub,
        organizationId: userAttributes.attributes["custom:organizationId"],
        projectId: projectId ? projectId : null,
        invoiceNumber: identifierNumber,
        projectInfo: projectInfo,
        concepts: [],
        invoiceStatus: "UNPAID",
        total: 0,
        subtotal: 0,
        retention: 0,
        recargo: 0,
        iva: 0,
      }
      auxinvoice.concepts[0] = buildEmptyInvoiceConcept({ invoice: auxinvoice })
      setInvoice({
        ...auxinvoice,
      })
      setLocalInvoice(true)
      setLoading(false)
    }
  }

  const deleteInvoice = async invoiceId => {
    await API.del(INVOICE_SERVICE, `/invoice/${invoiceId}`, {
      headers: await commonHeaders(),
    })
    const filtered = invoicesDB.filter(inv => inv.invoiceId !== invoiceId)
    const filteredInvoices = invoices.filter(inv => inv.invoiceId !== invoiceId)
    setInvoicesDB(filtered)
    setInvoices(filteredInvoices)
    let total = 0,
      subtotal = 0,
      retention = 0,
      recargo = 0,
      iva = 0
    for (let inv of filteredInvoices) {
      total += inv.total
      subtotal += inv.subtotal
      retention += inv.retention
      recargo += inv.recargo
      iva += inv.iva
    }
    setSummary({ total, subtotal, retention, recargo, iva })
  }

  const createWithSteps = async ({ steps, projectInfo, extraInfo }) => {
    try {
      setBatchAction(true)
      let number = await getIdentifierNumber({
        projectId: projectInfo.projectId,
      })
      const authUser = await Auth.currentAuthenticatedUser()
      const attributes = authUser.attributes
      let tmpInvoices = []
      for (let step of steps) {
        const invoiceId = nanoid()
        let price = 0.0
        try {
          price = parseFloat(step.cantidad)
        } catch (err) {
          price = 0.0
        }
        let invoice = {
          invoiceId: invoiceId,
          accountId: attributes.sub,
          projectId: projectInfo.projectId,
          organizationId: attributes["custom:organizationId"]
            ? attributes["custom:organizationId"]
            : attributes.sub,
          invoiceNumber: `${new Date().getFullYear()}-${projectInfo.name
            .substring(0, 3)
            .toUpperCase()}-${number}`,
          invoiceStatus: "UNPAID",
          invoiceType: "SELFMANAGED",
          projectInfo: projectInfo,
          total: price,
          contact: extraInfo?.client ? extraInfo.client : undefined,
          subtotal: price,
          concepts: [],
        }
        let auxConcept = buildEmptyInvoiceConcept({ invoice: invoice })
        let auxTotal = price
        if (extraInfo) {
          if (extraInfo.impuestos.iva?.id) {
            auxConcept.iva = extraInfo.impuestos.iva
            auxTotal = auxTotal + (auxTotal * auxConcept.iva.value) / 100
          }
          if (extraInfo.impuestos.retencion?.id) {
            auxConcept.retention = extraInfo.impuestos.retencion
            auxTotal = auxTotal - (auxTotal * auxConcept.retention.value) / 100
          }
          if (extraInfo.impuestos.recargo?.id) {
            auxConcept.recargo = extraInfo.impuestos.recargo
            auxTotal = auxTotal + (auxTotal * auxConcept.recargo.value) / 100
          }
        }
        auxConcept.price = price
        auxConcept.quantity = 1
        auxConcept.subtotal = price
        auxConcept.description = step.nombre ? step.nombre : ""
        auxConcept.total = auxTotal
        invoice.concepts.push(auxConcept)
        const response = await API.post(INVOICE_SERVICE, "/invoice", {
          body: invoice,
          headers: await commonHeaders(),
        })
        number += 1
        tmpInvoices.push(response)
      }
      setInvoicesDB([...invoicesDB, ...tmpInvoices])
      setInvoices([...invoices, ...tmpInvoices])
      setBatchAction(false)
    } catch (err) {
      console.log("ERROR CREATE WITH STEPS", err)
    }
  }
  const computeTaxes = ({ concept }) => {
    const { quantity, price, iva, recargo, retention } = concept
    const st = quantity.float * price.float
    const rec = st * recargo.value * 0.01
    const ret = -(st * retention.value * 0.01)
    const iv = st * iva.value * 0.01
    const tt = st + rec + iv + ret
    return {
      subtotal: st,
      recargo: rec,
      retention: ret,
      iva: iv,
      total: tt,
    }
  }
  const deleteConcept = ({ conceptId }) => {
    const concept = invoice.concepts.find(
      concept => concept.invoiceConceptId === conceptId,
    )
    const { subtotal, recargo, retention, iva, total } = computeTaxes(concept)
    setInvoice({
      ...invoice,
      subtotal: invoice.subtotal - subtotal,
      recargo: invoice.recargo - recargo,
      retention: invoice.retention - retention,
      iva: invoice.iva - iva,
      total: invoice.total - total,
      concepts: invoice.concepts.filter(
        concept => concept.invoiceConceptId !== conceptId,
      ),
    })
  }

  const createConcept = ({ concept }) => {
    const { subtotal, recargo, retention, iva, total } = computeTaxes({
      concept: concept,
    })
    setInvoice({
      ...invoice,
      subtotal: invoice.subtotal + subtotal,
      recargo: invoice.recargo + recargo,
      retention: invoice.retention + retention,
      iva: invoice.iva + iva,
      total: invoice.total + total,
      concepts: [...invoice.concepts, concept],
    })
  }

  const handleSaveFactura = async () => {
    try {
      setSavingInvoice(true)
      if (invoice.projectId === null || invoice.contact === undefined) {
        setSavingInvoice(false)
        enqueueSnackbar("No se puede guardar la factura, falta información", {
          variant: "error",
        })
        if (invoice.projectId === null) {
          setProjectError(true)
        }
        if (invoice.contact === undefined) {
          setContactError(true)
        }
        return
      }
      let invoiceToSave = { ...invoice }
      if (invoiceToSave.invoiceDueDate === undefined) {
        invoiceToSave.invoiceDueDate = new Date().toISOString()
      } else {
        invoiceToSave.invoiceDueDate = new Date(
          invoiceToSave.invoiceDueDate,
        ).toISOString()
      }
      if (invoiceToSave.invoiceDate === undefined) {
        invoiceToSave.invoiceDate = new Date().toISOString()
      } else {
        invoiceToSave.invoiceDate = new Date(
          invoiceToSave.invoiceDate,
        ).toISOString()
      }
      invoiceToSave.concepts = invoice.concepts.map(concept => {
        return {
          ...concept,
          quantity: concept.quantity.float,
          price: concept.price.float,
          projectId: invoice.projectId,
        }
      })
      let response = {}
      if (localInvoice === true) {
        response = await API.post(INVOICE_SERVICE, "/invoice", {
          body: invoiceToSave,
          headers: await commonHeaders(),
        })
        setLocalInvoice(false)
      } else {
        invoiceToSave.updateChilds = true
        response = await API.put(INVOICE_SERVICE, "/invoice", {
          body: invoiceToSave,
          headers: await commonHeaders(),
        })
      }
      response.concepts.map(concept => {
        concept.price = {
          float: concept.price,
          formatted: formatValue({
            value: concept.price.toFixed(2),
            locale: "es-ES",
            decimalSeparator: ",",
            groupSeparator: ".",
          }),
          value: concept.price.toFixed(2),
        }
        concept.quantity = {
          float: concept.quantity,
          formatted: formatValue({
            value: concept.quantity.toFixed(2),
            locale: "es-ES",
            decimalSeparator: ",",
            groupSeparator: ".",
          }),
          value: concept.quantity.toFixed(2),
        }
      })
      if (response.invoiceDueDate) {
        response.invoiceDueDate = convert_datetime_to_date(
          response.invoiceDueDate,
        )
      }
      if (response.invoiceDate) {
        response.invoiceDate = convert_datetime_to_date(response.invoiceDate)
      }
      setInvoice(response)
      setSavingInvoice(false)
      setContactError(false)
      setProjectError(false)
      history.push(`/invoices/${response.projectId}`)
    } catch (err) {
      console.log("ERROR SAVE INVOICE", err)
    }
  }

  const getPdf = async ({ invoiceId }) => {
    try {
      setLoadingPDF(true)
      const alreadyDownloaded = invoicesPdf.find(
        invoice => invoice.invoiceId === invoiceId,
      )
      if (alreadyDownloaded) {
        setLoadingPDF(false)
      } else {
        const response = await API.get(INVOICE_SERVICE, `/pdf/${invoiceId}`, {
          headers: await commonHeaders(),
        })
        const url = response.pdf
        setInvoicesPdf([...invoicesPdf, { invoiceId: invoiceId, url: url }])
        setLoadingPDF(false)
      }
    } catch (err) {
      console.log("ERROR GET PDF", err)
    }
  }
  function downloadFile(url, invoice) {
    fetch(url)
      .then(response => {
        return response.blob()
      })
      .then(blob => {
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url
        link.download = invoice.invoiceNumber + ".pdf"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      })
      .catch(error => {
        console.error("Error downloading file:", error)
      })
  }

  const handleDownload = async ({ invoiceId }) => {
    try {
      const alreadyDownloaded = invoicesPdf.find(
        invoice => invoice.invoiceId === invoiceId,
      )
      if (!alreadyDownloaded) {
        const invoiceSelected = invoices.find(
          invoice => invoice.invoiceId === invoiceId,
        )
        enqueueSnackbar("Descargando factura", {
          variant: "info",
          autoHideDuration: 2000,
        })
        const response = await API.get(INVOICE_SERVICE, `/pdf/${invoiceId}`, {
          headers: await commonHeaders(),
        })
        setLoadingPDF(true)
        const url = response.pdf
        setInvoicesPdf([
          ...invoicesPdf,
          {
            url: url,
            ...invoiceSelected,
          },
        ])
        downloadFile(url, invoiceSelected)
        setLoadingPDF(false)
        enqueueSnackbar("Factura descargada", {
          variant: "success",
          autoHideDuration: 2000,
        })
      } else {
        const invoice = invoices.find(
          invoice => invoice.invoiceId === invoiceId,
        )
        enqueueSnackbar("Factura ya descargada", {
          variant: "info",
          autoHideDuration: 2000,
        })
        downloadFile(alreadyDownloaded.url, invoice)
      }
    } catch (err) {
      console.log("ERROR DOWNLOAD", err)
    }
  }

  const backgroundDownload = async ({ invoiceId }) => {
    try {
      const alreadyDownloaded = invoicesPdf.find(
        invoice => invoice.invoiceId === invoiceId,
      )
      if (!alreadyDownloaded) {
        const invoiceSelected = invoices.find(
          invoice => invoice.invoiceId === invoiceId,
        )
        const response = await API.get(INVOICE_SERVICE, `/pdf/${invoiceId}`, {
          headers: await commonHeaders(),
        })
        const url = response.pdf
        setInvoicesPdf([
          ...invoicesPdf,
          {
            url: url,
            ...invoiceSelected,
          },
        ])
      }
    } catch (err) {
      console.log("ERROR DOWNLOAD", err)
    }
  }
  const uploadFileToStorage = async ({ file, presignedPostData }) => {
    try {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key])
      })
      formData.append("file", file)
      const res = await axios.post(presignedPostData.url, formData)
      return res
    } catch (error) {
      console.log(error)
    }
  }
  const handleSend = async ({ invoices, sendData }) => {
    try {
      let invoicesIds = []
      invoices.map(i => {
        invoicesIds.push(i.invoiceId)
      })
      const headers = await commonHeaders()
      enqueueSnackbar("Envio en progreso", {
        variant: "info",
        autoHideDuration: 2000,
      })
      let files = []
      for (let file of sendData.selectedFiles) {
        const response = await API.post(
          "dev-PLANHOPPER-API",
          "/api/file/upload",
          {
            body: {
              filename: file.name,
              extension: file.name.split(".").slice(-1)[0],
              size: file.size,
              contentType: file.type,
              customDB: false,
              importedProject: false,
              tags: [],
              description: "",
              orgId: "",
            },
            headers: headers,
          },
        )
        await uploadFileToStorage({
          file: file,
          presignedPostData: response.uploadInfo,
        })
        files.push({
          bucket: response.file.bucket,
          key: response.uploadInfo.fields.key,
          filename: file.name,
          url: response.file.url,
        })
      }
      const response = await API.post(INVOICE_SERVICE, "/send", {
        body: {
          invoiceIds: invoicesIds,
          attachedFiles: files,
          contact: sendData.selectedContacts,
          subject: sendData.asunto,
          message: sendData.mensaje,
          invoiceFile: {
            filename: sendData.title,
          },
          projectId: invoices[0].projectId ? invoices[0].projectId : null,
          organizationId: invoices[0].organizationId
            ? invoices[0].organizationId
            : null,
        },
        headers: headers,
      })
      enqueueSnackbar("Envio realizado!", {
        variant: "success",
        autoHideDuration: 2000,
      })
    } catch (err) {
      console.log("ERROR SEND", err)
    }
  }
  const handleDuplicate = async ({ invoiceId }) => {
    try {
      enqueueSnackbar("Acción en curso", {
        variant: "info",
        autoHideDuration: 2000,
      })
      const response = await API.post(INVOICE_SERVICE, `/duplicate`, {
        body: {
          invoiceId: invoiceId,
        },
        headers: await commonHeaders(),
      })
      setInvoice(response)
      let invoicesTMP = [...invoices, response]
      let total = 0,
        subtotal = 0,
        retention = 0,
        recargo = 0,
        iva = 0
      for (let inv of invoicesTMP) {
        total += inv.total
        subtotal += inv.subtotal
        retention += inv.retention
        recargo += inv.recargo
        iva += inv.iva
      }
      setSummary({ total, subtotal, retention, recargo, iva })
      setInvoices([...invoices, response])
      setInvoicesDB([...invoicesDB, response])
      enqueueSnackbar("Factura duplicada", {
        variant: "success",
        autoHideDuration: 2000,
      })
    } catch (err) {
      console.log("ERROR DUPLICATE", err)
    }
  }

  const analyzeExpense = async ({ url }) => {
    try {
      if (invoice.projectId && invoice.projectInfo) {
        setProcessing(true)
        const response = await API.post(INVOICE_SERVICE, `/analyze`, {
          body: {
            url: url,
            projectId: invoice.projectId,
            projectInfo: invoice.projectInfo,
            invoiceNumber: invoice.invoiceNumber,
          },
          headers: await commonHeaders(),
        })
        setInvoice(response.invoice)
        history.push(
          `/invoice/${response.invoice.invoiceId}?projectId=${response.invoice.projectId}`,
        )
        setProcessing(false)
      } else {
        enqueueSnackbar("Seleccione un proyecto", {
          variant: "error",
          autoHideDuration: 2000,
        })
      }
    } catch (err) {
      setProcessing(false)
      enqueueSnackbar("Error al analizar el gasto", {
        variant: "error",
        autoHideDuration: 2000,
      })
    }
  }
  return {
    invoice,
    setInvoice,
    loading,
    setLoading,
    invoices,
    invoicesDB,
    summary,
    getByProject,
    getByOrganization,
    filters,
    filterBy,
    filter,
    setFilter,
    getInvoice,
    createWithSteps,
    getIdentifierNumber,
    deleteInvoice,
    batchAction,
    localInvoice,
    createConcept,
    deleteConcept,
    buildEmptyInvoiceConcept,
    computeTaxes,
    handleSaveFactura,
    savingInvoice,
    getPdf,
    loadingPDF,
    handleDownload,
    backgroundDownload,
    invoicesPdf,
    handleSend,
    contactError,
    projectError,
    handleDuplicate,
    analyzeExpense,
    processing,
  }
}
