import React, { useContext, useEffect, useRef, useState } from "react"
import { NavLink } from "react-router-dom"
import { Nav, Collapse } from "reactstrap"
// javascript plugin used to create scrollbars on windows
import "react-perfect-scrollbar/dist/css/styles.css"
import PerfectScrollbar from "perfect-scrollbar"
import PropTypes from "prop-types"
import AuthContext from "context/auth/authContext"
import SubscriptionContext from "context/subscription/subscriptionContext"

import logoLarge from "assets/customIcons/logos/planhopper_logo_large.svg"
import ModalClosePassword from "../CommonForBoth/TopbarDropdown/ModalClosePassword.js"
import logoMini from "assets/customIcons/logos/planhopper_logo_mini.svg"
import i18n from "i18n.js"
import { useHistory } from "react-router-dom"
import ProfileDropdown from "components/Navbars/ProfileDropdown.js"
import { UnsetButton } from "common/buttons.js"
var ps

const iconStyle = {
  width: "18px !important",
  minWidth: "18px",
  height: "18px !important",
  size: "20px",
}
function Sidebar(props) {
  const authContext = useContext(AuthContext)
  const { user, getUser, showSnackbar } = authContext

  const {
    subscriptionState,
    isTrial,
    noSubscription,
    daysLeft,
    showTopAdviceBar,
    setShowTopAdviceBar,
  } = useContext(SubscriptionContext)

  //useEffect( ()=> {getUser()} , [])
  const [openPassword, setOpenPassword] = useState(false)
  const [openAvatar, setOpenAvatar] = React.useState(false)
  const [collapseStates, setCollapseStates] = React.useState({})
  const sidebar = React.useRef()
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = routes => {
    let initialState = {}
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        }
      }
      return null
    })
    return initialState
  }

  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])

  function isSelected(allPaths) {
    var found = false
    allPaths.map(path => {
      if (window.location.pathname.includes(path)) {
        found = true
      }
    })
    return found
  }
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = routes => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true
      }
    }
    return false
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null
      }
      if (prop.collapse) {
        var st = {}
        st[prop["state"]] = !collapseStates[prop.state]
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#user"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={e => {
                e.preventDefault()
                setCollapseStates(st)
              }}
            >
              {prop.icon !== undefined ? (
                <div className="inline" style={{ paddingLeft: "8px" }}>
                  <img
                    src={prop.icon}
                    style={{ width: "20px", height: "20px", color: "000000" }}
                  />
                  <p style={{ marginLeft: "8px", marginTop: "-4.5px" }}>
                    {i18n.t(prop.name)}
                    <b className="caret" />
                  </p>
                </div>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {i18n.t(prop.name)}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        )
      }
      return (
        <li
          className={isSelected(prop.allPaths) ? "active" : ""}
          key={key}
          style={{ height: "50px" }}
        >
          {prop.link ? (
            <a href={prop.path} target="_blank" rel="noopener noreferrer">
              {prop.icon !== undefined ? (
                /*<div style={{backgroundColor:'#F5F5F5', marginLeft:'-6px', paddingLeft:'9px', paddingTop:'9px', paddingBottom:'9px', borderRadius:'6PX', marginTop:'-5px'}}> */

                <div className="inline" id="sidebarMuyPronto">
                  <div
                    style={{
                      backgroundColor:
                        window.location.pathname == prop.layout ||
                        isSelected(prop.allPaths)
                          ? prop.layout == "/referal"
                            ? "#4461D770"
                            : "#FFD52B"
                          : "transparent",
                      marginLeft: "0px",
                      paddingLeft: "31px",
                      paddingTop: "8px",
                      paddingBottom: "6px",
                      borderRadius: "6px",
                      height: "30px",
                      marginTop: "5px",
                    }}
                  >
                    <img
                      src={prop.icon}
                      className="iconNewSide"
                      alt={prop.icon}
                    />
                  </div>
                  {prop.muypronto ? (
                    <div
                      style={{
                        right: "10px",
                        lineHeight: "13px",
                        textTransform: "initial",
                        marginTop: "5px",
                      }}
                      id="muypronto2"
                    >
                      muy pronto
                    </div>
                  ) : null}

                  <p
                    style={{
                      marginLeft: "13px",
                      marginTop: "5px",
                      fontFamily: "Helvetica",
                      fontWeight:
                        window.location.pathname == prop.layout ||
                        isSelected(prop.allPaths)
                          ? "700"
                          : "400",
                      fontSize: "14px",
                      textTransform: "none",
                    }}
                  >
                    {i18n.t(prop.name)}
                  </p>
                </div>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{i18n.t(prop.name)}</span>
                </>
              )}
            </a>
          ) : prop.isExternal ? (
            <div
              className="d-flex align-items-center"
              style={{
                marginLeft: "0px",
                paddingLeft: "20px",
                paddingTop: "8px",
                paddingBottom: "6px",
                borderRadius: "6px",
                height: "30px",
                marginTop: "5px",
              }}
            >
              <UnsetButton {...{ [prop.dataAttribute]: true }}>
                <prop.icon style={iconStyle} />
              </UnsetButton>
              <p
                className="ps-3"
                style={{
                  fontFamily: "Helvetica",
                  fontWeight:
                    window.location.pathname == prop.layout ||
                    isSelected(prop.allPaths)
                      ? "700"
                      : "400",
                  fontSize: "14px",
                  textTransform: "none",
                }}
              >
                <UnsetButton {...{ [prop.dataAttribute]: true }}>
                  {prop.name}
                </UnsetButton>
              </p>
            </div>
          ) : (
            <NavLink to={prop.layout + prop.path}>
              {prop.icon !== undefined ? (
                /*<div style={{backgroundColor:'#F5F5F5', marginLeft:'-6px', paddingLeft:'9px', paddingTop:'9px', paddingBottom:'9px', borderRadius:'6PX', marginTop:'-5px'}}> */

                <div className="inline" id="sidebarMuyPronto">
                  <div
                    style={{
                      backgroundColor:
                        window.location.pathname == prop.layout ||
                        isSelected(prop.allPaths)
                          ? prop.layout == "/referal"
                            ? "#4461D770"
                            : "#FFD52B"
                          : "transparent",
                      marginLeft: "0px",
                      paddingLeft: "31px",
                      paddingTop: "8px",
                      paddingBottom: "6px",
                      borderRadius: "6px",
                      height: "30px",
                      marginTop: "5px",
                    }}
                  >
                    <img src={prop.icon} className="iconNewSide" />
                  </div>
                  {prop.muypronto ? (
                    <div
                      style={{
                        right: "10px",
                        lineHeight: "13px",
                        textTransform: "initial",
                        marginTop: "5px",
                      }}
                      id="muypronto2"
                    >
                      muy pronto
                    </div>
                  ) : null}

                  <p
                    style={{
                      marginLeft: "13px",
                      marginTop: "5px",
                      fontFamily: "Helvetica",
                      fontWeight:
                        window.location.pathname == prop.layout ||
                        isSelected(prop.allPaths)
                          ? "700"
                          : "400",
                      fontSize: "14px",
                      textTransform: "none",
                    }}
                  >
                    {i18n.t(prop.name)}
                  </p>
                </div>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{i18n.t(prop.name)}</span>
                </>
              )}
            </NavLink>
          )}
        </li>
      )
    })
  }
  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      if (sidebar.current != null) {
        ps = new PerfectScrollbar(sidebar.current, {
          suppressScrollX: true,
          suppressScrollY: false,
        })
      }
    }

    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy()
      }
    }
  })
  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes))
  }, [])
  const referenciaMini = useRef(null)
  const history = useHistory()

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
      style={{
        top: user
          ? (user["custom:subscription_plan"] == "FREE_TRIAL" ||
              user["custom:subscription_status"] == "cancelled") &&
            showTopAdviceBar
            ? "47px"
            : "0px"
          : "0px",
      }}
    >
      <a href="/dashboard">
        <div
          className="logo"
          style={{
            backgroundColor: "#EFEFEF",
            cursor: "POINTER",
            height: "60px",
          }}
        >
          <a
            ref={referenciaMini}
            //href="/dashboard"
            style={{ opactiy: "1 !important" }}
            className="simple-text logo-mini"
          >
            <div style={{ display: "flex" }}>
              <img
                src={logoMini}
                alt="react-logo"
                style={{
                  width: "80%",
                  height: "80%",
                  borderRadius: "0px",
                  color: "#414141",
                  marginTop: "3px",
                  marginLeft: "2px",
                }}
              />
            </div>
          </a>
          <a //href="/dashboard"
            className="simple-text logo-normal"
          >
            <div>
              <img
                src={logoLarge}
                style={{
                  height: "52px",
                  marginTop: "-16px",
                  paddingTop: "13px",
                  marginLeft: "-35px",
                }}
              />
            </div>
          </a>
        </div>
      </a>

      <div
        className="sidebar-wrapper"
        ref={sidebar}
        style={{
          backgroundColor: "#EFEFEF",
          borderRight: "0.75px solid rgba(65, 65, 65, 0.5)",
          overflowX: "hidden",
        }}
      >
        <Nav>{createLinks(props.routes)}</Nav>
        <div style={{ marginLeft: "15px", bottom: "15px", position: "fixed" }}>
          {/* <Nav>{createLinks(props.routes)}</Nav> */}
          <ProfileDropdown />
        </div>
      </div>
      <ModalClosePassword
        openPassword={openPassword}
        setOpenPassword={setOpenPassword}
      />
    </div>
  )
}

Sidebar.propTypes = {
  location: PropTypes.object,
  routes: PropTypes.array,
  bgColor: PropTypes.string,
  activeColor: PropTypes.string,
  handleMiniClick: PropTypes.any,
  sidebarMini: PropTypes.bool,
}

export default Sidebar
