import React, { useState, useContext, useEffect, useRef } from "react"
import { Row } from "reactstrap"
import Skeleton from "@mui/material/Skeleton"
import ModalFirstPage from "./modalFirstPage"
import * as amplitude from "@amplitude/analytics-browser"
import QuickActionsBottomBar from "./QuickActionsBottomBar"
import Budget2023Context from "context/budget2023/Budget2023Context"
import ModalCallError from "common/modalCallError"
import { useHistory } from "react-router-dom"
import AuthContext from "../../context/auth/authContext"
import ProjectContext from "context/projects/projectContext"
import ContactContext from "../../context/contact/contactContext"
import PersonalModal from "./PersonalModal"
import UploadModal from "./UploadModal"
import WaitingModal from "./WaitingModal"
import SuccessModal from "./SuccessModal"
import CardProject from "./CardProject"
import NoProjects from "components/Dashboard/NoProjects"
import GridViewIcon from "@mui/icons-material/GridView"
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined"
import ModalFirstProject from "./ModalFirstProject"
import MobileModalNew from "./mobileModalAtt"
import i18n from "i18n"
import ModalSelectTypeProject from "./ModalSelectTypeProject"
import ModalWaitingPendingActions from "./ModalWaitingPendingActions"
import { HelveticaInput } from "common/inputs"
import ReorderIcon from "@mui/icons-material/Reorder"

const DashboardComponent = () => {
  const [modal_standard, setModal_standard] = useState(false)
  const [modal_upload, setModal_upload] = useState(false)
  const [waitModal, setWaitModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const onClickPersonalModal = () => setModal_standard(!modal_standard)
  const authContext = useContext(AuthContext)
  const projectContext = useContext(ProjectContext)
  const contactContext = useContext(ContactContext)

  const {
    projects,
    getProjects,
    loadingProjects,
    deleteProject,
    createProcess,
    loadingImportPlantilla,
    uploadProcess,
    test,
  } = projectContext
  const { Contacts, getContacts } = contactContext
  const {
    getUser,
    user,
    logOut,
    pendingActions,
    getPendingActions,
    executePendingActions,
    loadingPendingActions,
  } = authContext
  const [vista, setVista] = useState(0)
  const [uploadDB, setUploadDB] = useState(false)
  const [projectSeleccionado, setSeleccionado] = useState(null)

  const [modal_first, setModal_first] = useState(false)
  const history = useHistory()
  const [esPrimerProyecto, setFirstProject] = useState(false)

  const { downloadBudget, urlDownload } = useContext(Budget2023Context)
  const { sendBudget, loadingSend, sendOk } = useContext(Budget2023Context)

  useEffect(() => {
    initAmplitude()
    getContacts()
  }, [])

  const initAmplitude = async () => {
    amplitude.track("Dashboard Visited")
  }

  useEffect(() => {
    getProjects()
  }, [projects.length])

  const [openSelectFirstPage, setOpenSelectFirstPage] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [errorToSend, setErrorToSend] = useState("")
  const [openChangeToPlanhopper, setOpenChangeToPlanhopper] = useState(false)

  useEffect(async () => {
    if (user) {
      // if(user['custom:subscription_plan'] != 'FREE_TRIAL'){
      //   if(Object.keys(pendingActions).length > 0){
      //     if(pendingActions['type'] == 'downloadBudget'){
      //       executePendingActions()
      //       let result = await downloadBudget(pendingActions.data.projectId, pendingActions.data.name, pendingActions.data.tipo, pendingActions.data.typeBudget, pendingActions.data.options)
      //       if(result?.error){
      //         setOpenError(true)
      //         setErrorToSend(result)
      //       }
      //     }else{
      //       executePendingActions()
      //       let result = await sendBudget(pendingActions.data)
      //       if(result?.error){
      //         setOpenError(true)
      //         setErrorToSend(result)
      //       }
      //     }
      //   }
      // }
      if (!user["custom:firstPageSelected"]) {
        setOpenSelectFirstPage(true)
      }
    }
  }, [user, pendingActions])

  const [openModalPendingActions, setOpenModalPendingActions] = useState(false)

  useEffect(() => {
    if (loadingPendingActions) {
      setOpenModalPendingActions(true)
    }
  }, [loadingPendingActions])

  useEffect(() => {
    if (user) {
      // if(user['custom:subscription_plan'] != 'FREE_TRIAL'){
      //   getPendingActions(user.email)
      // }
      if (window.smartlook) {
        window.smartlook("identify", user.sub, {
          name: user["custom:nombre_apellido"],
          email: user.email,
          phone: user["custom:mobile"],
        })
        window.smartlook("record", { forms: true })
        window.smartlook("record", { numbers: true })
      }
      if (window.heap) {
        window.heap.identify(user.sub)
        window.heap.addUserProperties({ email: user.email })
      }
    }
  }, [])

  const getLastProject = () => {
    let ultimaFecha = ""
    let iteracion = 0
    for (let i = 0; i < projects.length; i++) {
      if (i == 0) {
        ultimaFecha = projects[i].updatedAt
        iteracion = i
      } else {
        if (projects[i].createdAt > ultimaFecha) {
          ultimaFecha = projects[i].createdAt
          iteracion = i
        }
      }
    }
    setSeleccionado(projects[iteracion])
  }
  const [mobileModal, setMobileModal] = useState(false)
  const handleOpenNew = () => {
    setModalNew(true)
  }
  const [modalNew, setModalNew] = useState(false)
  useEffect(() => {
    if (window.innerWidth < 750) {
      setMobileModal(true)
    }
  }, [window.innerWidth])

  const refCuad = useRef(null)
  const refList = useRef(null)
  // const refDataGrid = useRef(null)

  const handleVista0 = e => {
    refCuad.current["id"] = "vistaCuad"
    refList.current["id"] = "vistaList"
    // refDataGrid.current["id"] = "vistaDataGrid"
    setVista(0)
  }
  const handleVista1 = e => {
    refCuad.current["id"] = "vistaCuad2"
    refList.current["id"] = "vistaList2"
    // refDataGrid.current["id"] = "vistaDataGrid"
    setVista(1)
  }
  // const handleVista2 = e => {
  //   refCuad.current["id"] = "vistaCuad2"
  //   refList.current["id"] = "vistaList2"
  //   refDataGrid.current["id"] = "vistaDataGrid"

  //   setVista(2)
  // }

  const handleUploadDB = () => {
    setUploadDB(true)
  }

  const [palabrasFiltered, setPalabrasFiltered] = useState("")

  return (
    <>
      <div
        id="centerButton"
        style={{
          justifyContent: "right",
          marginTop: "0px",
          marginBottom: "10px",
        }}
      >
        {vista === 0 && (
          <HelveticaInput
            value={palabrasFiltered}
            onChange={e => setPalabrasFiltered(e.target.value)}
            style={{
              width: "300px",
              height: "40px",
              marginRight: "20px",
              marginTop: "1px",
              marginBottom: "0px",
            }}
            placeholder={i18n.t("Buscar presupuesto")}
          ></HelveticaInput>
        )}
        <div
          id="SwitchVista"
          style={{
            width: "100px",
            marginRight: "10px",
            marginTop: "1px",
            height: "40px",
          }}
        >
          {/* <div
             ref={refDataGrid}
             id="vistaDataGrid"
             className="Cuad"
             style={{display: "flex", width:'50px', borderRadius:'30px', marginLeft:'0px', height:'40px', paddingTop:'7px'}}
             onClick={handleVista2}
           >
             <ReorderIcon style={{width:'25px', height:'25px',  marginTop:'1PX', marginRight:'2px'}}></ReorderIcon>
           </div> */}

          <div
            ref={refCuad}
            id="vistaCuad"
            className="Cuad"
            style={{
              display: "flex",
              width: "50px",
              borderRadius: "30px",
              marginLeft: "0px",
              height: "40px",
              paddingTop: "7px",
            }}
            onClick={handleVista0}
          >
            <GridViewIcon
              style={{
                width: "25px",
                height: "25px",
                marginTop: "1PX",
                marginRight: "2px",
              }}
            ></GridViewIcon>
          </div>
          <div
            ref={refList}
            id="vistaList"
            className="ListV"
            style={{
              display: "flex",
              width: "50px",
              borderRadius: "30px",
              marginLeft: "0px",
              height: "40px",
              paddingTop: "7px",
            }}
            onClick={handleVista1}
          >
            <FormatListBulletedOutlinedIcon
              style={{
                width: "25px",
                height: "25px",
                marginTop: "1PX",
                marginRight: "2px",
              }}
            ></FormatListBulletedOutlinedIcon>
          </div>
        </div>
        {user ? (
          user.freeTrialExpired ||
          user["custom:role"] === "WORKER" ? null : projects.length > 0 ? (
            <button
              type="button"
              onClick={handleOpenNew}
              className="AddContact"
              style={{ height: "40px", width: "200px", marginRight: "20px" }}
            >
              {i18n.t("Nuevo Presupuesto")}
            </button>
          ) : null
        ) : null}
      </div>

      <div style={{ marginLeft: "30px", marginTop: "-40px" }}>
        <p id="Configuration">{i18n.t("Proyectos")}</p>
      </div>

      {!loadingProjects && !loadingImportPlantilla ? (
        projects.length > 0 ? (
          uploadDB === false ? (
            <>
              <Row
                style={{
                  marginBottom: "160px",
                  marginLeft: "0px",
                  marginLeft: "-32px",
                }}
              >
                <CardProject
                  projects={projects}
                  onClickPersonalModal={setModal_standard}
                  vista={vista}
                  contactos={Contacts}
                  setUploadDB={setUploadDB}
                  deleteProject={deleteProject}
                  palabrasFiltered={palabrasFiltered}
                  setmodal_first={setModal_first}
                />
              </Row>
              {/* <QuickActionsBottomBar setOpenModal={handleOpenNew}/> */}
            </>
          ) : (
            history.push("/dashboard/DB")
          )
        ) : uploadDB === false ? (
          <NoProjects
            setUploadDB={setUploadDB}
            setModal_standard={setModal_standard}
            modal_first={modal_first}
            setModal_first={setModal_first}
          />
        ) : (
          history.push("/dashboard/DB")
        )
      ) : (
        <>
          <div className="row" style={{ marginLeft: "-32px" }}>
            {[...Array(9)].map((e, i) => (
              <div
                id="projectOverflow"
                style={{ position: "relative" }}
                key={i}
              >
                <div
                  id="CardContent"
                  style={{
                    display: "flex",
                    maxWidth: "380px",
                    width: "380px",
                    backgroundColor: "white",
                    borderRadius: "6px",
                    marginBottom: "24px",
                    marginTop: "1.25rem",
                    marginLeft: "5%",
                  }}
                >
                  <Skeleton
                    variant="circular"
                    width={90}
                    height={90}
                    animation="wave"
                  />
                  <div style={{ width: "200px", marginLeft: "5px" }}>
                    <Skeleton
                      variant="text"
                      width={200}
                      animation="wave"
                      sx={{ fontSize: "14px" }}
                    />
                    <Skeleton
                      variant="text"
                      width={100}
                      animation="wave"
                      sx={{ fontSize: "14px" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {modal_standard && (
        <PersonalModal
          modal_standard={modal_standard}
          setmodal_standard={setModal_standard}
          setModalUpload={setModal_upload}
          contactos={Contacts}
          setSeleccionado={setSeleccionado}
          modal_first={modal_first}
          setmodal_first={setModal_first}
        />
      )}

      {modal_first && (
        <ModalFirstProject
          modal_first={modal_first}
          setmodal_first={setModal_first}
          setModalUpload={setModal_upload}
          contactos={Contacts}
          setSeleccionado={setSeleccionado}
          setFirstProject={setFirstProject}
        />
      )}

      {modal_upload && (
        <UploadModal
          openModal={modal_upload}
          setOpenModal={setModal_upload}
          setWaitModal={setWaitModal}
          proyecto={projectSeleccionado}
          setSuccessModal={setSuccessModal}
        />
      )}

      {waitModal ? (
        <WaitingModal
          openModal={waitModal}
          setOpenModal={setWaitModal}
          setSuccessModal={setSuccessModal}
          setSeleccionado={setSeleccionado}
          project={projectSeleccionado}
        />
      ) : null}
      {successModal && (
        <SuccessModal
          setSuccessModal={setSuccessModal}
          successModal={successModal}
          project={projectSeleccionado}
        />
      )}
      {mobileModal && (
        <MobileModalNew open={mobileModal} setOpen={setMobileModal} />
      )}

      <ModalSelectTypeProject
        modalNew={modalNew}
        setModalNew={setModalNew}
        setmodal_first={setModal_first}
        onClickPersonalModal={onClickPersonalModal}
      />
      {loadingPendingActions && (
        <ModalWaitingPendingActions
          open={openModalPendingActions}
          setOpen={setOpenModalPendingActions}
        />
      )}

      {openError && (
        <ModalCallError
          open={openError}
          setOpen={setOpenError}
          error={errorToSend}
          ubicacion={"Modal pending Actions"}
        />
      )}
      {openSelectFirstPage && (
        <ModalFirstPage
          open={openSelectFirstPage}
          setOpen={setOpenSelectFirstPage}
        />
      )}
    </>
  )
}

export default DashboardComponent
