import {
  CALCULATE_ALL_TOTAL_CERT_PRICES,
  SET_CERTIFICATION_HISTORY_TREE,
  SET_LOADING,
  SET_DOWNLOAD_BUDGET_LOADING,
  SET_OPEN_EXPORT_SIDEBAR,
  SET_SELECTED_CERTIFICATION_ID,
  ON_UPDATE,
  SET_OPEN_CONFIRM_DELETE_MODAL,
  SET_CERT_TO_DELETE,
  DELETE_CERTIFICATION_BY_ID,
} from "context/types"

const CertHistoryReducer = (state, action) => {
  switch (action.type) {
    case ON_UPDATE:
      return {
        ...state,
        updating: true,
      }

    case SET_CERTIFICATION_HISTORY_TREE:
      return {
        ...state,
        loading: false,
        certHistoryTree: action.payload.data,
      }

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }

    case SET_DOWNLOAD_BUDGET_LOADING:
      return {
        ...state,
        exportSideBar: {
          ...state.exportSideBar,
          isLoading: action.payload,
        },
      }

    case SET_OPEN_EXPORT_SIDEBAR:
      return {
        ...state,
        exportSideBar: {
          ...state.exportSideBar,
          isOpen: action.payload,
        },
      }

    case CALCULATE_ALL_TOTAL_CERT_PRICES:
      state.certHistoryTree.calculateAllTotalCertPrice()
      return {
        ...state,
        updating: false,
      }

    case SET_SELECTED_CERTIFICATION_ID:
      return {
        ...state,
        exportSideBar: {
          ...state.exportSideBar,
          selectedCertificationId: action.payload.certId,
        },
      }

    case SET_OPEN_CONFIRM_DELETE_MODAL: {
      return {
        ...state,
        openConfirmDelete: action.payload.open,
      }
    }

    case SET_CERT_TO_DELETE: {
      return {
        ...state,
        certToDelete: action.payload.certToDelete,
      }
    }

    case DELETE_CERTIFICATION_BY_ID: {
      const newCertificationRoot = state.certHistoryTree.deleteCertById(
        action.payload.certId,
      )

      return {
        ...state,
        certHistoryTree: newCertificationRoot,
      }
    }

    default:
      return state
  }
}

export default CertHistoryReducer
