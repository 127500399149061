//AUTH TYPES
export const REGISTRO_EXITOSO = "REGISTRO_EXITOSO"
export const REGISTRO_ERROR = "REGISTRO_ERROR"
export const LOGIN_EXITOSO = "LOGIN_EXITOSO"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const LOGOUT = "LOGOUT"
export const OBTENER_USUARIO = "OBTENER_USUARIO"
export const MODIFICAR_USUARIO = "MODIFICAR_USUARIO"
export const OBTENER_USUARIO_ERROR = "OBTENER_USUARIO_ERROR"
export const VALIDAR_CUENTA_EXITOSA = "VALIDAR_CUENTA_EXITOSA"
export const VALIDAR_CUENTA_ERROR = "VALIDAR_CUENTA_ERROR"
export const GET_PROYECTOS = "GET_PROYECTOS"
export const ERROR_USUARIO = "ERROR_USUARIO"
export const ADD_TO_FAVORITES_SUCCESS = "ADD_TO_FAVORITES_SUCCESS"
export const ON_UPLOAD_PROJECT = "ON_UPLOAD_PROJECT"
export const UPLOAD_PROJECT_SUCCESS = "UPLOAD_PROJECT_SUCCESS"
export const UPLOAD_PROJECT_ERROR = "UPLOAD_PROJECT_ERROR"
export const ON_CREATE_PROJECT = "ON_CREATE_PROJECT"
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS"
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR"
export const ON_GET_PROJECTS = "ON_GET_PROJECTS"
export const ON_GET_CHAPTERS = "ON_GET_CHAPTERS"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_CHAPTERS_SUCCESS = "GET_CHAPTERS_SUCCESS"
export const GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR"
export const ON_GET_PROJECT = "ON_GET_PROJECT"
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS"
export const GET_PROJECT_ERROR = "GET_PROJECT_ERROR"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const DELETE_CHAPTERS_SUCCESS = "DELETE_CHAPTERS_SUCCESS"
export const DELETE_PARTIDA_SUCCESS = "DELETE_PARTIDA_SUCCESS"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const CLEAR_UPLOAD_PROCESS = "CLEAR_UPLOAD_PROCESS"
export const GET_CHAPTERS_BUDGET_SUCCESS = "GET_CHAPTERS_BUDGET_SUCCESS"
export const CHANGE_SEND_BUDGET = "CHANGE_SEND_BUDGET"
export const SENDING_MESSAGE = "SENDING_MESSAGE"
export const SEND_COMPLETE = "SEND_COMPLETE"
//FILE ACTIONS
export const GET_URL_POST = "GET_URL_POST"
export const GET_URL_POST_SUCCESS = "GET_URL_POST_SUCCESS"
export const GET_URL_POST_ERROR = "GET_URL_POST_ERROR"
export const ON_UPLOADING_FILE = "ON_UPLOADING_FILE"
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR"
export const GET_MY_FILES = "GET_MY_FILES"
export const GET_MY_FILES_SUCCESS = "GET_MY_FILES_SUCCESS"
export const GET_MY_FILES_ERROR = "GET_MY_FILES_ERROR"
export const UPLOADING_FILE = "UPLOADING_FILE"

export const ON_GET_PROPOSAL = "ON_GET_PROPOSAL"
export const GET_PROPOSAL_SUCCESS = "GET_PROPOSAL_SUCCESS"
export const GET_PROPOSAL_ERROR = "GET_PROPOSAL_ERROR"
export const UPDATE_PART = "UPDATE_PART"
export const GET_GROUP_PROPOSAL_SUCCESS = "GET_GROUP_PROPOSAL_SUCCESS"
export const LOADING_PDF = "LOADING_PDF"
export const GET_PDF_SUCCESS = "GET_PDF_SUCCESS"
export const LOADING_TABLA_COMPARATIVA = "LOADING_TABLA_COMPARATIVA"
export const GET_TABLA_COMPARATIVA_SUCCESS = "GET_TABLA_COMPARATIVA_SUCCESS"

export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS"

export const RESET_SEND = "RESET_SEND"
export const GET_VERSIONS_SUCCESS = "GET_VERSIONS_SUCCESS"

export const WORKER_CREATED = "WORKER_CREATED"
export const GET_WORKERS_BY_PROJECT = "GET_WORKERS_BY_PROJECT"
export const WORKER_UPDATED = "WORKER_UPDATED"

export const GET_TASKS_BY_PROJECT = "GET_TASKS_BY_PROJECT"
export const ADD_COLUMN = "ADD_COLUMN"
export const DELETE_COLUMN = "DELETE_COLUMN"
export const UPDATE_COLUMN = "UPDATE_COLUMN"
export const UPDATE_TASK = "UPDATE_TASK"
export const CREATE_GANTT = "CREATE_GANTT"
export const UPDATE_FILTER = "UPDATE_FILTER"

// acts
export const SET_SELECTED_ACTA = "SET_SELECTED_ACTA"
export const GET_ACTS = "GET_ACTS"
export const GET_ACTA = "GET_ACTA"
export const ADD_ACT = "ADD_ACT"
export const UPDATE_ACT = "UPDATE_ACT"
export const DELETE_ACT = "DELETE_ACT"
export const UPDATE_PENDING_SUBJECT = "UPDATE_PENDING_SUBJECT"

//bankPrices
export const GET_BANK_PRICES = "GET_BANK_PRICES"
export const LOADING_BANKPRICES = "LOADING_BANKPRICES"
export const LOADING_SUBCAPS = "LOADING_SUBCAPS"

export const SET_FOOTER_VALUES = "SET_FOOTER_VALUES"

export const DEL_CAPITULO_SET_POSITION = "DEL_CAPITULO_SET_POSITION"

export const SET_BC3_LINK_DATA = "SET_BC3_LINK_DATA"
export const TOGGLE_MODAL_IMPORT_BC3_LINK = "TOGGLE_MODAL_IMPORT_BC3_LINK"
export const IS_IMPORTING_PART_FROM_EDIT_MODAL =
  "IS_IMPORTING_PART_FROM_EDIT_MODAL"
export const SET_MODAL_BUDGETING_ERROR = "SET_MODAL_BUDGETING_ERROR"
export const REMOVE_ALL_PARTS_FROM_CHAPTER = "REMOVE_ALL_PARTS_FROM_CHAPTER"
export const SET_CHAPTER_ID_SELECTED_FROM_BC3LINK =
  "SET_CHAPTER_ID_SELECTED_FROM_BC3LINK"

// Certification history
export const SET_CERTIFICATION_HISTORY_TREE = "SET_CERTIFICATION_HISTORY_TREE"
export const SET_LOADING = "SET_LOADING"
export const SET_DOWNLOAD_BUDGET_LOADING = "SET_DOWNLOAD_BUDGET_LOADING"
export const SET_OPEN_EXPORT_SIDEBAR = "SET_OPEN_EXPORT_SIDEBAR"
export const CALCULATE_ALL_TOTAL_CERT_PRICES = "CALCULATE_ALL_TOTAL_CERT_PRICES"
export const SET_SELECTED_CERTIFICATION_ID = "SET_SELECTED_CERTIFICATION_ID"
export const ON_UPDATE = "ON_UPDATE"
export const SET_CERT_TO_DELETE = "SET_CERT_TO_DELETE"
export const DELETE_CERTIFICATION_BY_ID = "DELETE_CERTIFICATION_BY_ID"

export const IS_CONTRADICTING_PART = "IS_CONTRADICTING_PART"
export const SET_EXTRA_AND_CONTRADITIONS = "SET_EXTRA_AND_CONTRADITIONS"
export const LOADING_EXTRA_AND_CONTRADITIONS = "LOADING_EXTRA_AND_CONTRADITIONS"
export const SET_PART_ID = "SET_PART_ID"
export const EXTRA_AND_CONTRADITIONS_PART_ID_IS_CHECKED =
  "EXTRA_AND_CONTRADITIONS_PART_ID_IS_CHECKED"
export const SET_TREE = "SET_TREE"
export const SET_FOOTER_VALUES_FOR_EXTRA_AND_CONTRADITIONS =
  "SET_FOOTER_VALUES_FOR_EXTRA_AND_CONTRADITIONS"
export const SET_ERROR = "SET_ERROR"
export const TOGGLE_DELETE_PART_MODAL = "TOGGLE_DELETE_PART_MODAL"
export const REMOVE_PART = "REMOVE_PART"
export const MOVE_PART = "MOVE_PART"
export const REASSIGN_PART_POSITIONS = "REASSIGN_PART_POSITIONS"
export const SET_OPEN_NEW_MODAL_PART = "SET_OPEN_NEW_MODAL_PART"
export const SET_BTN_LOADING = "SET_BTN_LOADING"
export const SET_LOADING_ICON = "SET_LOADING_ICON"
export const IS_BUDGET_CLOSED = "IS_BUDGET_CLOSED"
export const MOVE_PARTS_BY_CHAPTERS_ID = "MOVE_PARTS_BY_CHAPTERS_ID"

export const SET_ALL_DYNAMIC_ELEMENTS = "SET_ALL_DYNAMIC_ELEMENTS"
export const SET_ALL_DYNAMIC_ELEMENTS_TO_INITIAL_STATE =
  "SET_ALL_DYNAMIC_ELEMENTS_TO_INITIAL_STATE"
export const ADD_DYNAMIC_ELEMENT = "ADD_DYNAMIC_ELEMENT"
export const DELETE_DYNAMIC_ELEMENT = "DELETE_DYNAMIC_ELEMENT"
export const EDIT_DYNAMIC_ELEMENT = "EDIT_DYNAMIC_ELEMENT"
export const SET_OPEN_CONFIRM_DELETE_MODAL = "SET_OPEN_CONFIRM_DELETE_MODAL"
export const SET_ELEMENT_TO_DELETE = "SET_ELEMENT_TO_DELETE"
export const SET_IS_HOW_TO_ADD_MODAL_OPEN = "SET_IS_HOW_TO_ADD_MODAL_OPEN"
export const SET_DYNAMIC_ELEMENT_MODAL_IS_OPEN =
  "SET_DYNAMIC_ELEMENT_MODAL_IS_OPEN"
export const SET_DYNAMIC_ELEMENT_SELECTED = "SET_DYNAMIC_ELEMENT_SELECTED"
export const SET_DYNAMIC_ELEMENT_PROJECT_ID = "SET_DYNAMIC_ELEMENT_PROJECT_ID"
export const SET_PARTS_RELATED_TO_ELEMENT_TO_DELETE =
  "SET_PARTS_RELATED_TO_ELEMENT_TO_DELETE"
