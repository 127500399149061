import React from "react"
import { YellowButton } from "common/buttons"
import { HelveticaBold } from "common/typographies"
import { HelveticaInput } from "common/inputs"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { CircularProgress } from "@mui/material"
import PropTypes from "prop-types"
import i18n from "i18n"

const InputPasswords = ({
  codeError,
  codeMessage,
  loading,
  passwordError,
  passwordMessage,
  handleChange,
  handleReset,
  handleConfirmEmail,
}) => {
  const [sendedAgain, setSendedAgain] = React.useState(false)

  return (
    <div className="centered-container" style={{ height: "480px" }}>
      <HelveticaBold
        className="tituloGrandeResponsive"
        style={{
          color: "#414141",
          fontSize: "24px",
          paddingTop: "20px",
          fontWeight: "700",
          marginBottom: "5px",
        }}
      >
        {i18n.t("Reestablecer contraseña")}
      </HelveticaBold>
      <HelveticaBold
        style={{
          color: "#41414199",
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "30px",
        }}
      >
        {i18n.t("Introduce el código que has recibido y tu nueva contraseña")}
      </HelveticaBold>

      <HelveticaBold
        style={{ fontSize: "14px", marginBottom: "5px", color: "#414141" }}
      >
        {i18n.t("Codigo de verificacion")}
      </HelveticaBold>
      <HelveticaInput
        style={{
          width: "100%",
          marginBottom: !codeError && "30px",
          border: codeError && "1px solid #BF2929",
        }}
        placeholder={i18n.t("Introduce tu codigo de verificacion")}
        autoComplete="off"
        name="code"
        onChange={e => handleChange(e)}
      ></HelveticaInput>
      {codeError && (
        <div style={{ position: "relative" }}>
          <HelveticaBold
            className="errorMessageHelvetica"
            style={{ marginBottom: "6px" }}
          >
            {codeMessage}
          </HelveticaBold>
          <HighlightOffIcon className="errorPasswordIcon" />
        </div>
      )}

      <HelveticaBold
        style={{ fontSize: "14px", marginBottom: "5px", color: "#414141" }}
      >
        {i18n.t("Nueva contraseña")}
      </HelveticaBold>
      <HelveticaInput
        style={{
          width: "100%",
          marginBottom: !passwordError && "38px",
          border: passwordError && "1px solid #BF2929",
        }}
        placeholder={i18n.t("Introduce tu nueva contraseña")}
        name="password"
        autoComplete="off"
        type={"password"}
        onChange={e => handleChange(e)}
      ></HelveticaInput>

      {passwordError && (
        <div style={{ position: "relative" }}>
          <HelveticaBold
            className="errorMessageHelvetica"
            style={{ marginBottom: "12px" }}
          >
            {passwordMessage}
          </HelveticaBold>
          <HighlightOffIcon className="errorPasswordIcon" />
        </div>
      )}
      {loading ? (
        <div
          style={{
            width: "100%",
            position: "relative",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress size={24} style={{ marginBottom: "30px" }} />
        </div>
      ) : (
        <YellowButton
          style={{
            width: "100%",
            marginBottom: "30px",
            height: "35px",
            rotate: "-0.5deg",
          }}
          onClick={e => {
            handleReset(e)
          }}
        >
          {i18n.t("Reestablecer")}
        </YellowButton>
      )}

      {!sendedAgain ? (
        <div
          style={{
            marginTop: "-10px",
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            position: "relative",
          }}
        >
          <HelveticaBold
            className="littlePresponsive"
            style={{ color: "#414141", fontSize: "12px", fontWeight: "400" }}
          >
            {i18n.t("No has recibido ningún correo?")}
          </HelveticaBold>
          <a>
            <HelveticaBold
              className="littlePresponsive"
              onClick={e => {
                handleConfirmEmail(e)
                setSendedAgain(true)
              }}
              style={{
                color: "#4461D7",
                fontSize: "12px",
                fontWeight: "400",
                textDecoration: "underline",
                fontStyle: "italic",
                marginLeft: "5px",
              }}
            >
              {i18n.t("Clica aqui para volver a enviar")}
            </HelveticaBold>
          </a>
        </div>
      ) : (
        <div
          style={{
            marginTop: "-10px",
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            position: "relative",
          }}
        >
          <HelveticaBold
            className="littlePresponsive"
            style={{ color: "#414141", fontSize: "12px", fontWeight: "400" }}
          >
            {i18n.t(
              "Se ha enviado de nuevo un correo con el código de verificación",
            )}
          </HelveticaBold>
        </div>
      )}
    </div>
  )
}

InputPasswords.propTypes = {
  codeError: PropTypes.bool,
  loading: PropTypes.bool,
  codeMessage: PropTypes.string,
  handleChange: PropTypes.func,
  handleReset: PropTypes.func,
  passwordError: PropTypes.bool,
  passwordMessage: PropTypes.string,
  handleConfirmEmail: PropTypes.func,
}

export default InputPasswords
