import React, { useState } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import i18n from "i18n"
import { YellowButton } from "common/buttons"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "480px",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    height: "390px",
    maxHeight: "calc(100vh - 20px)",
    borderLeft: "5px solid #FFD52B",
    overflowY: "hidden",
  },
}))

const ModalVerFirma = ({ open, setOpen, signature }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setOpen(false)
            }}
            style={{
              marginTop: "0px",
              paddingLeft: "6px",
              marginRight: "-10px",
              size: "7px",
            }}
          ></CloseIcon>
          <img
            src={signature}
            alt="Firma"
            style={{ width: "400px", height: "300px" }}
          />
          <YellowButton
            onClick={() => {
              setOpen(false)
            }}
            style={{ marginLeft: "110px", marginTop: "-15px" }}
          >
            {i18n.t("Aceptar")}
          </YellowButton>
        </div>
      </Modal>
    </>
  )
}
ModalVerFirma.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  signature: PropTypes.string.isRequired,
}

export default ModalVerFirma
