import React, { useContext, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress, Modal } from "@mui/material"
import PropTypes from "prop-types"

import i18n from "i18n"
import { HelveticaBold } from "common/typographies"
import AuthContext from "context/auth/authContext"
import { YellowButton } from "common/buttons"
import sendWithColor from "assets/customIcons/sendWithColor.png"
import { useParams, useHistory } from "react-router-dom"
import Budget2023Context from "context/budget2023/Budget2023Context"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "420px",
    height: "300px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],

    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const BlockSendModal = ({
  open,
  setOpen,
  proposalTree,
  capitulo,
  chaptersChecked,
  message,
  tipo,
  ocultaM,
  ocultaP,
  selectedContacts,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { user, setPendingActions } = useContext(AuthContext)
  const history = useHistory()
  const { projectId } = useParams()
  const { selectedBudget2 } = useContext(Budget2023Context)

  const setPendingAction = async e => {
    e.preventDefault()
    setLoading(true)
    await setPendingActions({
      pendingActions: {
        type: "sendBudget",
        data: {
          projectId: projectId,
          budgetTree: chaptersChecked
            ? selectedBudget2
            : capitulo
              ? [capitulo]
              : proposalTree
                ? proposalTree
                : selectedBudget2,
          contact: selectedContacts[0],
          message: message,
          capitulo: capitulo ? true : false,
          tipo: tipo,
          mediciones: ocultaM,
          precioObjetivo: ocultaP,
        },
      },
      email: user.email,
    })
    setLoading(false)
    history.push("/subscription")
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <div
            style={{
              justifyContent: "center",
              position: "relative",
              textAlign: "center",
              display: "block",
              marginTop: "35px",
              margin: "30px",
            }}
          >
            <img
              src={sendWithColor}
              style={{ width: "150px", height: "55px" }}
            />
            <HelveticaBold
              style={{ fontSize: "16px", marginTop: "10px", color: "#414141" }}
            >
              {i18n.t("¡Estás a un paso de enviar tu presupuesto!")}
            </HelveticaBold>
            <HelveticaBold
              style={{
                fontSize: "12px",
                lineHeight: "13px",
                marginTop: "10px",
                color: "#414141",
                fontWeight: "400",
              }}
            >
              {i18n.t(
                "Te invitamos a formar parte de la familia Planhopper y ",
              )}{" "}
              <HelveticaBold style={{ fontSize: "12px", color: "#414141" }}>
                {i18n.t("digitalizar tu negocio y explota tu potencial.")}
              </HelveticaBold>
            </HelveticaBold>
            <HelveticaBold
              style={{ fontSize: "12px", marginTop: "10px", color: "#414141" }}
            >
              {i18n.t("El presupuesto se enviará después de")}:
            </HelveticaBold>
            {loading ? (
              <CircularProgress size={24} style={{ marginTop: "5px" }} />
            ) : (
              <YellowButton
                onClick={e => {
                  setPendingAction(e)
                }}
                style={{
                  marginTop: "5px",
                  width: "160px",
                  height: "40px",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {i18n.t("Elegir tu plan")}
              </YellowButton>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
BlockSendModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  chaptersChecked: PropTypes.any,
  capitulo: PropTypes.any,
  proposalTree: PropTypes.any,
  selectedContacts: PropTypes.array,
  message: PropTypes.string,
  tipo: PropTypes.string,
  ocultaM: PropTypes.bool,
  ocultaP: PropTypes.bool,
}

export default BlockSendModal
