import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import BancoPreciosContent from "components/BancoPrecios/bancoPreciosContent"

import { Container } from "reactstrap"

import { RecordPageView } from "App"
import * as amplitude from "@amplitude/analytics-browser"

const BancoPreciosIndex = () => {
  useEffect(() => {
    amplitude.track("Mi Banco de Precios Visited")
  }, [])

  RecordPageView()
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper | Banco de precios </title>
        </MetaTags>
        <Container fluid>
          <BancoPreciosContent />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BancoPreciosIndex
