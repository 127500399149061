import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { PoppinsGrey } from "common/typographies"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext"

const Part = ({ part, bankSelected }) => {
  const [total, setTotal] = useState(0)
  const { checkPartida, updatePartsList, checkMultipleParts } = useContext(
    bankSelected == "User database"
      ? BankPricesContext
      : BankPricesReferenceContext,
  )

  useEffect(() => {
    if (part) {
      setTotal(
        parseFloat(
          parseFloat(part.quantity ? part.quantity : 0) * part.userPrice
            ? part.userPrice
            : part.price,
        ).toFixed(2),
      )
    }
  }, [])

  const handleCheckBox = e => {
    if (e.nativeEvent.shiftKey) {
      if (part.checked) {
        checkPartida(part.chapterId, part.partidaId)
        updatePartsList(part)
      } else {
        checkMultipleParts(part.chapterId, part)
      }
    } else {
      checkPartida(part.chapterId, part.partidaId)
      updatePartsList(part)
    }
    setChecked(!checked)
  }
  const [checked, setChecked] = useState(false)

  return (
    <tr>
      <td style={{ fontFamily: "Helvetica" }}>
        <div
          className="inline"
          style={{ marginLeft: "-10px", marginBottom: "-3px" }}
        >
          <input
            type={"checkbox"}
            onChange={e => handleCheckBox(e)}
            checked={(bankSelected = "User database" ? checked : part.checked)}
            style={{
              width: "13px",
              marginRight: "10px",
              cursor: "pointer",
              marginTop: "0px",
            }}
          ></input>
          <PoppinsGrey
            style={{ fontWeight: "300", paddingTop: "10px", fontSize: "13px" }}
          >
            {part.unity}
          </PoppinsGrey>
        </div>
      </td>
      <td style={{ fontWeight: "300" }}>{part.name}</td>
      <td style={{ paddingLeft: "23px", fontFamily: "Helvetica" }}>
        {part.quantity ? part.quantity : 0}
      </td>
      <td style={{ fontFamily: "Helvetica" }}>
        {part.userPrice ? part.userPrice : part.price}
      </td>
      <td style={{ fontFamily: "Helvetica" }}>{total}</td>
    </tr>
  )
}
Part.propTypes = {
  part: PropTypes.object.isRequired,
  bankSelected: PropTypes.string.isRequired,
}
export default Part
