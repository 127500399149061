import React, { useContext, useRef, useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { CircularProgress, Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import i18n from "i18n"
import {
  BorderLeft,
  DownloadDoneTwoTone,
  PrintOutlined,
} from "@mui/icons-material"
import {
  PoppinsGrey,
  PoppinsSemiBold,
  PoppinsNormal,
  HelveticaBold,
} from "common/typographies"
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined"
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined"
import { BlueButton } from "common/containers"
import { PoppinsWhite } from "common/typographies"
import { Document, Page, pdfjs } from "react-pdf"
import FacturasContext from "context/facturas/facturasContext"
import { useReactToPrint } from "react-to-print"
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined"
import AuthContext from "context/auth/authContext"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "640px",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    height: "700px",
    maxHeight: "calc(100vh - 20px)",
    borderLeft: "5px solid #FFD52B",
  },
}))

const ModalPrevisualizarPDF = ({
  open,
  setOpen,
  invoiceService,
  invoiceId,
}) => {
  const getInvoiceUrl = invoiceId => {
    let inv = invoiceService.invoicesPdf.find(
      inv => inv.invoiceId === invoiceId,
    )
    return inv ? inv.url : null
  }
  const [invoicePdf, setInvoicePdf] = useState(getInvoiceUrl(invoiceId))
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [numPages, setNumPages] = useState(null)
  const { freeTrialExpired, openFreeTrialExpire } = useContext(AuthContext)
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    paperSize: "A5",
    scale: 2,
  })

  useEffect(() => {
    if (open) {
      invoiceService.getPdf({
        invoiceId: invoiceId,
      })
    }
  }, [open])

  const [imprimir, setImprimir] = useState(false)
  const [download, setDownload] = useState(false)
  const handleImprimir = () => {
    setImprimir(true)
  }

  useEffect(() => {
    if (imprimir === true) {
      handlePrint()
      setImprimir(false)
    }
  }, [imprimir])

  const handleDownload = () => {
    setDownload(true)
  }

  useEffect(() => {
    if (invoicePdf && download) {
      window.open(invoicePdf)
      setDownload(false)
    }
  }, [invoicePdf, download])

  useEffect(() => {
    setInvoicePdf(getInvoiceUrl(invoiceId))
  }, [invoiceService.invoicesPdf])
  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setOpen(false)
            }}
            style={{
              marginTop: "0px",
              paddingLeft: "6px",
              marginRight: "-10px",
              size: "7px",
            }}
          ></CloseIcon>
          <div
            className="inline"
            style={{ position: "relative", marginBottom: "-25px" }}
          >
            <div
              style={{
                width: "50%",
                display: "grid",
                marginTop: "3px",
                marginRight: "40PX",
              }}
            >
              <PoppinsSemiBold style={{ fontSize: "18px", fontWeight: "500" }}>
                Factura {invoiceService.invoices[0].name}
              </PoppinsSemiBold>
              <PoppinsGrey style={{ marginTop: "-5px" }}>
                {invoiceService.invoices[0].name}
              </PoppinsGrey>
            </div>
            <BlueButton
              onClick={() => {
                freeTrialExpired ? openFreeTrialExpire() : handleImprimir()
              }}
              className="inline"
              style={{
                marginRight: "0px",
                width: "100px",
                marginTop: "-2px",
                height: "30px",
              }}
            >
              <PrintOutlined
                style={{
                  color: "white",
                  width: "16px",
                  marginTop: "1px",
                  marginLeft: "7px",
                  marginRight: "5px",
                }}
              />
              <PoppinsWhite style={{ marginTop: "2px", fontWeight: "400" }}>
                {i18n.t("Imprimir")}
              </PoppinsWhite>
            </BlueButton>
            <BlueButton
              onClick={() => {
                freeTrialExpired ? openFreeTrialExpire() : handleDownload()
              }}
              className="inline"
              style={{
                width: "114px",
                marginTop: "-2px",
                float: "right",
                position: "absolute",
                right: "0px",
                marginRight: "10px",
                border: "none",
                borderRadius: "5px",
                height: "30px",
              }}
            >
              <DownloadForOfflineOutlinedIcon
                style={{
                  color: "white",
                  width: "16px",
                  marginTop: "1px",
                  marginLeft: "7px",
                  marginRight: "7px",
                }}
              />
              <PoppinsWhite style={{ marginTop: "2px", fontWeight: "400" }}>
                {i18n.t("Descargar")}{" "}
              </PoppinsWhite>
            </BlueButton>
          </div>
          {invoiceService.loadingPDF === true ? (
            <>
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  transform: "translateY(-50%, -50%)",
                  top: "38%",
                  left: "38%",
                }}
              >
                <CircularProgress
                  style={{
                    width: 150,
                    height: 150,
                    marginLeft: "0px",
                    color: "#414141",
                    marginBottom: "20px",
                  }}
                />
                <HelveticaBold
                  style={{
                    color: "#414141",
                    fontSize: "24px",
                    marginLeft: "-10px",
                    marginTop: "0px",
                  }}
                >
                  {i18n.t("Cargando PDF...")}
                </HelveticaBold>
              </div>
            </>
          ) : (
            <Fragment>
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "91%",
                  marginLeft: "-13px",
                  marginRight: "-32px",
                }}
              >
                <Document
                  file={invoicePdf}
                  onLoadError={console.error}
                  style={{ overflowY: "auto" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {[...Array(numPages)].map((elementInArray, index) => (
                    <Page key={index} pageNumber={index + 1} scale={1} />
                  ))}
                </Document>
              </div>
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "91%",
                  marginLeft: "-13px",
                  marginRight: "-32px",
                  display: "none",
                }}
              >
                <Document
                  ref={componentRef}
                  file={invoicePdf}
                  onLoadError={console.error}
                  style={{ overflowY: "auto" }}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {[...Array(numPages)].map((elementInArray, index) => (
                    <Page key={index} pageNumber={index + 1} scale={2} />
                  ))}
                </Document>
              </div>
            </Fragment>
          )}
        </div>
      </Modal>
    </>
  )
}
ModalPrevisualizarPDF.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  invoiceService: PropTypes.any,
  invoiceId: PropTypes.any,
}

export default ModalPrevisualizarPDF
