import React, { useState, useMemo, useEffect, useContext } from "react"
import { useParams } from "react-router-dom"
import data from "./pricing.json"
import { Grid, CircularProgress } from "@material-ui/core"
import { CardPricing, PagoSeguro } from "common/containers"
import {
  HelveticaBold,
  HelveticaLight,
  PoppinsSemiBold,
} from "common/typographies"
import i18n from "i18n"
import { BillingInput } from "common/inputs"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { YellowButton } from "common/buttons"
import amex from "assets/images/creditCards/amex.png"
import mastercard from "assets/images/creditCards/mastercard.png"
import visa from "assets/images/creditCards/visa.png"
import dankort from "assets/images/creditCards/dankort.png"
import diners from "assets/images/creditCards/diners.png"
import discover from "assets/images/creditCards/discover.png"
import jcb from "assets/images/creditCards/jcb.png"
import maestro from "assets/images/creditCards/maestro.png"
import unionpay from "assets/images/creditCards/unionpay.png"
import electron from "assets/images/creditCards/electron.png"
import interpayment from "assets/images/creditCards/interpayment.png"
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined"
import Select from "react-select"
import countryList from "react-select-country-list"
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined"
import AuthContext from "context/auth/authContext"
import { DeleteOutline } from "@mui/icons-material"
import axios from "axios"
import SubscriptionTotal from "./subscriptionTotal"
import { RecordPageViewWithoutUserId } from "App"
import moment from "moment"
import { isLocalhost } from "utils/functions"

const SubscriptionPayment = () => {
  RecordPageViewWithoutUserId()
  const cbInstance = window.Chargebee.init({
    site: isLocalhost ? "planhopper-test" : "planhopper",
  })
  const baseUrl = isLocalhost
    ? "https://api.dev.planhopper.com/integration/"
    : "https://api.prod.planhopper.com/integration/"
  const {
    card,
    deleteCard,
    createCard,
    getCustomerInfo,
    updateBillingAddress,
    billingAddress,
    validateCoupon,
    coupon,
    createPaymentIntent,
    paymentIntent,
    subscription,
  } = useContext(AuthContext)
  const { planName } = useParams()
  const [creditCard, setCredidCard] = useState({
    creditCardNumber: {
      value: "",
      valid: true,
    },
    expireDate: {
      value: `${new Date().getFullYear()}-${new Date().getMonth()}`,
      valid: true,
    },
    fullName: {
      value: "",
      valid: true,
    },
    cvv: {
      value: "",
      valid: true,
    },
  })
  const [facturationAddress, setFacturationAddress] = useState({
    company: { value: "", valid: true },
    vatNumber: { value: "", valid: true },
    address: { value: "", valid: true },
    city: { value: "", valid: true },
    zip: { value: "", valid: true },
    state: { value: "", valid: true },
    country: { value: "ES", valid: true, label: "Spain" },
  })
  useEffect(() => {
    getCustomerInfo()
  }, [])

  useEffect(() => {
    let aux = { ...facturationAddress }
    for (const [key, value] of Object.entries(billingAddress)) {
      if (key !== "loading" && key !== "error") {
        aux[key].value = value
      }
    }
    setFacturationAddress(aux)
    if (aux.address.value !== "") {
      setFacturationAddressSaved(true)
    }
  }, [billingAddress.loading])

  useEffect(() => {
    if (card.loading === false && card.error === false) {
      if (card.cardId) {
        let aux = {}
        for (const [key, value] of Object.entries(card)) {
          aux[key] = {
            value: value,
            valid: true,
          }
        }
        setCredidCard(aux)
        setCreditCardSaved(true)
      } else {
        setCredidCard({
          creditCardNumber: {
            value: "",
            valid: true,
          },
          expireDate: {
            value: `${new Date().getFullYear()}-${new Date().getMonth()}`,
            valid: true,
          },
          fullName: {
            value: "",
            valid: true,
          },
          cvv: {
            value: "",
            valid: true,
          },
        })
        setCreditCardSaved(false)
      }
    }
  }, [card.loading])

  useEffect(() => {
    if (coupon.loading === false && coupon.error === false) {
      setDiscountCode({
        ...discountCode,
        valid: true,
      })
    } else if (coupon.loading === false && coupon.error === true) {
      setDiscountCode({
        ...discountCode,
        valid: false,
      })
    }
  }, [coupon.loading])

  const validateExpireDate = value => {
    if (value === "") {
      return false
    }
    var month = value.split("-")[1]
    var year = value.split("-")[0]
    var currentDate = new Date()
    var currentMonth = currentDate.getMonth() + 1
    var currentYear = currentDate.getFullYear()
    if (year > currentYear) {
      return true
    } else if (year == currentYear && month >= currentMonth) {
      return true
    } else {
      return false
    }
  }

  const options = useMemo(() => countryList().getData(), [])

  const [discountCode, setDiscountCode] = useState({
    value: "",
    valid: true,
  })

  const [typeOfCreditCard, setTypeOfCreditCard] = useState("")
  const [billingPeriod, setBillingPeriod] = useState("MONTHLY")
  const [creditCardSaved, setCreditCardSaved] = useState(false)
  const [facturationAddressSaved, setFacturationAddressSaved] = useState(false)

  const handleCardChange = e => {
    setCredidCard({
      ...creditCard,
      [e.target.name]: {
        ...creditCard[e.target.name],
        value: e.target.value,
      },
    })
  }

  const handleAddressChange = e => {
    if (!e.target) {
      setFacturationAddress({
        ...facturationAddress,
        country: {
          ...facturationAddress.country,
          value: e.value,
          label: e.label,
        },
      })
    } else {
      setFacturationAddress({
        ...facturationAddress,
        [e.target.name]: {
          ...facturationAddress[e.target.name],
          value: e.target.value,
        },
      })
    }
  }

  const [precio, setPrecio] = useState(0)
  const [iva, setIva] = useState(0)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    let auxTotal = 0
    let auxIva = 0
    let auxPrecio = 0
    if (planName == "Basic") {
      if (billingPeriod == "MONTHLY") {
        auxTotal = data[0].monthlyYearlyPrice * 1.21
        auxIva = data[0].monthlyYearlyPrice * 0.21
        auxPrecio = data[0].monthlyYearlyPrice
      } else if (billingPeriod == "YEARLY") {
        auxTotal = data[0].monthlyYearlyPrice * 12 * 1.21
        auxIva = data[0].monthlyYearlyPrice * 12 * 0.21
        auxPrecio = data[0].monthlyYearlyPrice * 12
      }
    } else if (planName == "Business") {
      if (billingPeriod == "MONTHLY") {
        auxTotal = data[1].monthlyPrice * 1.21
        auxIva = data[1].monthlyPrice * 0.21
        auxPrecio = data[1].monthlyPrice
      } else if (billingPeriod == "YEARLY") {
        auxTotal = data[1].monthlyYearlyPrice * 12 * 1.21
        auxIva = data[1].monthlyYearlyPrice * 12 * 0.21
        auxPrecio = data[1].monthlyYearlyPrice * 12
      }
    }
    setPrecio(auxPrecio)
    setIva(auxIva)
    setTotal(auxTotal)
  }, [billingPeriod, planName])

  const changeBillingPeriod = e => {
    setBillingPeriod(e.target.name)
  }
  const validateCardNumber = number => {
    let regex = new RegExp("^[0-9]{16}$")
    if (!regex.test(number)) return false
    return true
  }
  const validateCvv = cvv => {
    let regex = new RegExp("^[0-9]{3}$")
    if (!regex.test(cvv)) return false
    return true
  }
  const validateFullName = fullName => {
    let regex = new RegExp("^[a-zA-Z ]{2,30}$")
    if (!regex.test(fullName)) return false
    return true
  }
  const handleSaveCard = e => {
    e.preventDefault()
    const auxCreditCard = { ...creditCard }

    if (validateExpireDate(creditCard.expireDate.value) === true) {
      auxCreditCard.expireDate = {
        ...creditCard.expireDate,
        valid: true,
      }
    } else {
      auxCreditCard.expireDate = {
        ...creditCard.expireDate,
        valid: false,
      }
    }

    if (validateCardNumber(creditCard.creditCardNumber.value)) {
      auxCreditCard.creditCardNumber = {
        ...creditCard.creditCardNumber,
        valid: true,
      }
    } else {
      auxCreditCard.creditCardNumber = {
        ...creditCard.creditCardNumber,
        valid: false,
      }
    }

    if (validateCvv(creditCard.cvv.value)) {
      auxCreditCard.cvv = {
        ...creditCard.cvv,
        valid: true,
      }
    } else {
      auxCreditCard.cvv = {
        ...creditCard.cvv,
        valid: false,
      }
    }

    if (validateFullName(creditCard.fullName.value)) {
      auxCreditCard.fullName = {
        ...creditCard.fullName,
        valid: true,
      }
    } else {
      auxCreditCard.fullName = {
        ...creditCard.fullName,
        valid: false,
      }
    }
    setCredidCard(auxCreditCard)

    if (
      auxCreditCard.expireDate.valid &&
      auxCreditCard.creditCardNumber.valid &&
      auxCreditCard.cvv.valid &&
      auxCreditCard.fullName.valid
    ) {
      createCard({
        cardNumber: auxCreditCard.creditCardNumber.value,
        cvv: auxCreditCard.cvv.value,
        expiryMonth: auxCreditCard.expireDate.value.split("-")[1],
        expiryYear: auxCreditCard.expireDate.value.split("-")[0],
        firstName: auxCreditCard.fullName.value,
      })
      setTypeOfCreditCard(
        detectCardType(auxCreditCard.creditCardNumber.value).toUpperCase(),
      )
    }
  }
  const handleSaveBillingAddress = e => {
    e.preventDefault()
    const auxBillingAddress = { ...facturationAddress }
    for (const [key, value] of Object.entries(auxBillingAddress)) {
      if (auxBillingAddress[key].value.length === 0) {
        auxBillingAddress[key] = {
          ...value,
          valid: false,
        }
      } else {
        auxBillingAddress[key] = {
          ...value,
          valid: true,
        }
      }
    }
    setFacturationAddress(auxBillingAddress)
    if (
      auxBillingAddress.company.valid &&
      auxBillingAddress.vatNumber.valid &&
      auxBillingAddress.address.valid &&
      auxBillingAddress.city.valid &&
      auxBillingAddress.zip.valid &&
      auxBillingAddress.country.valid &&
      auxBillingAddress.state.valid
    ) {
      updateBillingAddress({
        company: auxBillingAddress.company.value,
        zip: auxBillingAddress.zip.value,
        country: auxBillingAddress.country.value,
        city: auxBillingAddress.city.value,
        address: auxBillingAddress.address.value,
        state: auxBillingAddress.state.value,
        vatNumber: auxBillingAddress.vatNumber.value,
      })
    }
  }
  const handleValidateCoupon = e => {
    e.preventDefault()
    if (discountCode.value.length > 0) {
      validateCoupon(discountCode.value)
    } else {
      setDiscountCode({ ...discountCode, valid: false })
    }
  }

  const handleDeleteCard = e => {
    e.preventDefault()
    deleteCard(creditCard.cardId.value)
    setCreditCardSaved(false)
  }

  const handlePayment = e => {
    e.preventDefault()
    cbInstance.openCheckout({
      hostedPage() {
        return axios
          .post(baseUrl + "chargebee/checkout", {
            subscriptionId: subscription.id,
            plan: planName.toUpperCase(),
            billing_period: billingPeriod,
            coupon: coupon.id ? [coupon.id] : [],
          })
          .then(response => response.data.checkout)
      },
      success(hostedPageId) {
        console.log("")
      },
      close() {
        console.log("checkout new closed")
      },
      step(step) {
        console.log("checkout")
      },
    })
  }
  function detectCardType(number) {
    var re = {
      electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      maestro:
        /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      dankort: /^(5019)\d+$/,
      interpayment: /^(636)\d+$/,
      unionpay: /^(62|88)\d+$/,
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    }

    for (var key in re) {
      if (re[key].test(number)) {
        return key
      }
    }
  }

  return (
    <div className="page-content">
      <PoppinsSemiBold
        style={{ fontSize: "20px", marginLeft: "20px", marginBottom: "20px" }}
      >
        {i18n.t("Pago de subscripción")}
      </PoppinsSemiBold>
      <div className="inline">
        <Grid
          container
          style={{
            backgroundColor: "transparent",
            height: "auto",
            marginLeft: "20px",
            marginRight: "20px",
            marginBottom: "50px",
          }}
          direction="row"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "12px",
              cursor: "pointer",
              width: "100%",
              position: "relative",
            }}
          >
            {creditCardSaved == false ? (
              <CardPricing
                style={{
                  width: "100%",
                  position: "relative",
                  height: "220px",
                  maxWidth: "908px",
                  minWidth: "520px",
                  paddingLeft: "16px",
                  paddingTop: "9px",
                  cursor: "default",
                }}
              >
                <HelveticaBold style={{ color: "#414141", fontSize: "20px" }}>
                  {i18n.t("Método de pago")}
                </HelveticaBold>
                <PagoSeguro
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "20px",
                    cursor: "default",
                  }}
                >
                  <LockOutlinedIcon
                    style={{
                      color: "#008653",
                      fontSize: "18px",
                      marginTop: "-2px",
                      marginLeft: "-2px",
                    }}
                  />
                  {i18n.t("Pago seguro")}
                </PagoSeguro>

                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Nº de tarjeta")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "100%" }}
                      placeholder={i18n.t("Nº de tarjeta")}
                      name="creditCardNumber"
                      value={creditCard.creditCardNumber.value}
                      onChange={handleCardChange}
                      valid={creditCard.creditCardNumber.valid}
                      type="number"
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Caducidad")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "65%" }}
                      placeholder={i18n.t("Caducidad")}
                      min={moment().format("YYYY-MM")}
                      name="expireDate"
                      value={creditCard.expireDate.value}
                      type="month"
                      valid={creditCard.expireDate.valid}
                      onChange={handleCardChange}
                    />
                  </div>
                </div>
                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Titular de la tarjeta")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "100%" }}
                      placeholder={i18n.t("Titular de la tarjeta")}
                      name="fullName"
                      value={creditCard.fullName.value}
                      valid={creditCard.fullName.valid}
                      onChange={handleCardChange}
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("CVV")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "65%" }}
                      valid={creditCard.cvv.valid}
                      placeholder={i18n.t("CVV")}
                      name="cvv"
                      type="number"
                      value={creditCard.cvv.value}
                      onChange={handleCardChange}
                    />
                  </div>
                </div>
                <YellowButton
                  style={{
                    position: "absolute",
                    right: "20px",
                    width: "130px",
                    bottom: "20px",
                    height: "35px",
                  }}
                  onClick={e => {
                    handleSaveCard(e)
                  }}
                >
                  {card.loading === true ? (
                    <CircularProgress size={24} />
                  ) : (
                    i18n.t("Guardar tarjeta")
                  )}
                </YellowButton>
              </CardPricing>
            ) : (
              <CardPricing
                style={{
                  width: "100%",
                  height: "220px",
                  maxWidth: "908px",
                  minWidth: "520px",
                  paddingLeft: "16px",
                  paddingTop: "9px",
                  cursor: "default",
                }}
              >
                <HelveticaBold style={{ color: "#414141", fontSize: "20px" }}>
                  {i18n.t("Método de pago")}
                </HelveticaBold>
                <PagoSeguro
                  style={{
                    position: "absolute",
                    right: "20px",
                    top: "20px",
                    cursor: "default",
                  }}
                >
                  <LockOutlinedIcon
                    style={{
                      color: "#008653",
                      fontSize: "18px",
                      marginTop: "-2px",
                      marginLeft: "-2px",
                    }}
                  />
                  {i18n.t("Pago seguro")}
                </PagoSeguro>

                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Nº de tarjeta")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <img
                        src={
                          typeOfCreditCard == "MASTERCARD"
                            ? mastercard
                            : typeOfCreditCard == "VISA"
                              ? visa
                              : typeOfCreditCard == "ELECTRON"
                                ? electron
                                : typeOfCreditCard == "AMEX"
                                  ? amex
                                  : typeOfCreditCard == "DINERS"
                                    ? diners
                                    : typeOfCreditCard == "DISCOVER"
                                      ? discover
                                      : typeOfCreditCard == "JCB"
                                        ? jcb
                                        : typeOfCreditCard == "UNIONPAY"
                                          ? unionpay
                                          : typeOfCreditCard == "MAESTRO"
                                            ? maestro
                                            : typeOfCreditCard == "INTERPAYMENT"
                                              ? interpayment
                                              : typeOfCreditCard == "DANKORT"
                                                ? dankort
                                                : visa
                        }
                        style={{
                          width: "40px",
                          height: "25px",
                          marginRight: "10px",
                        }}
                      />
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {typeOfCreditCard + " " + i18n.t("terminada en")}{" "}
                        {creditCard.creditCardNumber.value.substring(-4)}
                      </HelveticaLight>
                    </div>
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Caducidad")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {creditCard.expireDate.value}
                      </HelveticaLight>
                    </div>
                  </div>
                </div>
                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Titular de la tarjeta")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {creditCard.fullName.value}
                      </HelveticaLight>
                    </div>
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("CVV")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {creditCard.cvv.value}
                      </HelveticaLight>
                    </div>
                  </div>
                  <DeleteOutline
                    onClick={handleDeleteCard}
                    style={{
                      position: "absolute",
                      right: "20px",
                      bottom: "20px",
                      cursor: "pointer",
                      color: "red",
                    }}
                  />
                </div>
              </CardPricing>
            )}
          </Grid>
          {
            //SEGUNDO COMPONENTE
          }

          <Grid
            item
            xs={12}
            spacing={3}
            style={{
              paddingLeft: "12px",
              cursor: "pointer",
              height: "370px",
              marginTop: "20px",
            }}
          >
            {facturationAddressSaved == false ? (
              <CardPricing
                style={{
                  width: "auto",
                  height: "370px",
                  minWidth: "520px",
                  maxWidth: "908px",
                  paddingLeft: "16px",
                  paddingTop: "9px",
                  position: "relative",
                  cursor: "default",
                }}
              >
                <HelveticaBold style={{ color: "#414141", fontSize: "20px" }}>
                  {i18n.t("Dirección de facturación")}
                </HelveticaBold>

                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Nombre de la empresa")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "100%" }}
                      placeholder={i18n.t("Nombre de la empresa")}
                      name="company"
                      value={facturationAddress.company.value}
                      onChange={handleAddressChange}
                      valid={facturationAddress.company.valid}
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("CIF de la empresa")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "100%" }}
                      placeholder={i18n.t("CIF de la empresa")}
                      name="vatNumber"
                      valid={facturationAddress.vatNumber.valid}
                      value={facturationAddress.vatNumber.value}
                      onChange={handleAddressChange}
                    />
                  </div>
                </div>
                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Dirección")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "100%" }}
                      placeholder={i18n.t("Dirección de la empresa")}
                      name="address"
                      value={facturationAddress.address.value}
                      onChange={handleAddressChange}
                      valid={facturationAddress.address.valid}
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Ciudad")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "100%" }}
                      placeholder={i18n.t("Ciudad")}
                      name="city"
                      value={facturationAddress.city.value}
                      onChange={handleAddressChange}
                      valid={facturationAddress.city.valid}
                    />
                  </div>
                </div>
                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Codigo postal")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "100%" }}
                      placeholder={i18n.t("Codigo postal")}
                      name="zip"
                      type="number"
                      value={facturationAddress.zip.value}
                      onChange={handleAddressChange}
                      valid={facturationAddress.zip.valid}
                    />
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Provincia")}
                    </HelveticaBold>
                    <BillingInput
                      style={{ width: "100%" }}
                      placeholder={i18n.t("Provincia")}
                      name="state"
                      value={facturationAddress.state.value}
                      onChange={handleAddressChange}
                      valid={facturationAddress.state.valid}
                    />
                  </div>
                </div>
                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Pais")}
                    </HelveticaBold>
                    <Select
                      style={{ height: "45px", border: "4px solid black" }}
                      options={options}
                      value={facturationAddress.country}
                      onChange={handleAddressChange}
                      name="country"
                    />
                  </div>
                  <ReportGmailerrorredOutlinedIcon
                    style={{
                      display: `${
                        facturationAddress.country.valid ? "none" : "inline"
                      }`,
                      color: "red",
                      marginTop: "35px",
                    }}
                  />
                </div>
                <YellowButton
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="SavePaymentMethod"
                  data-amplify-analytics-attrs=""
                  style={{
                    position: "absolute",
                    right: "20px",
                    width: "130px",
                    bottom: "20px",
                    height: "35px",
                  }}
                  onClick={e => {
                    handleSaveBillingAddress(e)
                  }}
                >
                  {billingAddress.loading === true ? (
                    <CircularProgress size={24} />
                  ) : (
                    i18n.t("Guardar")
                  )}
                </YellowButton>
              </CardPricing>
            ) : (
              <CardPricing
                style={{
                  width: "auto",
                  height: "370px",
                  minWidth: "520px",
                  maxWidth: "908px",
                  paddingLeft: "16px",
                  paddingTop: "9px",
                  position: "relative",
                  cursor: "default",
                }}
              >
                <HelveticaBold style={{ color: "#414141", fontSize: "20px" }}>
                  {i18n.t("Dirección de facturación")}
                </HelveticaBold>

                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Nombre de la empresa")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {facturationAddress.company.value}
                      </HelveticaLight>
                    </div>
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("CIF de la empresa")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {facturationAddress.vatNumber.value}
                      </HelveticaLight>
                    </div>
                  </div>
                </div>
                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Dirección")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {facturationAddress.address.value}
                      </HelveticaLight>
                    </div>
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Ciudad")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {facturationAddress.city.value}
                      </HelveticaLight>
                    </div>
                  </div>
                </div>
                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Codigo postal")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {facturationAddress.zip.value}
                      </HelveticaLight>
                    </div>
                  </div>
                  <div style={{ width: "45%" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Provincia")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {facturationAddress.state.value}
                      </HelveticaLight>
                    </div>
                  </div>
                </div>
                <div className="inline" style={{ width: "85%" }}>
                  <div style={{ width: "45%", marginRight: "20px" }}>
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#707070",
                      }}
                    >
                      {i18n.t("Pais")}
                    </HelveticaBold>
                    <div
                      style={{ width: "100%", paddingTop: "5px" }}
                      className="inline"
                    >
                      <HelveticaLight
                        style={{
                          fontWeigth: "400",
                          color: "#8E8E8E",
                          fontSize: "14px",
                          paddingTop: "2px",
                        }}
                      >
                        {facturationAddress.country.label}
                      </HelveticaLight>
                    </div>
                  </div>
                </div>
                <ModeEditOutlineOutlinedIcon
                  style={{
                    position: "absolute",
                    right: "20px",
                    bottom: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setFacturationAddressSaved(false)
                  }}
                />
              </CardPricing>
            )}
          </Grid>
        </Grid>
        <Grid
          spacing={3}
          container
          style={{ backgroundColor: "transparent", height: "600px" }}
        >
          <Grid item xs={12} style={{ paddingLeft: "12px", cursor: "pointer" }}>
            <CardPricing
              style={{
                width: "auto",
                height: "620px",
                minWidth: "480px",
                maxWidth: "506px",
                paddingLeft: "16px",
                paddingTop: "9px",
                position: "relative",
                cursor: "default",
              }}
            >
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "20px",
                  cursor: "default",
                }}
              >
                {i18n.t("Un plan a tu medida")}
              </HelveticaBold>
              <HelveticaBold
                style={{
                  color: "#707070",
                  fontSize: "14px",
                  cursor: "default",
                }}
              >
                {i18n.t("Tipo de facturación")}
              </HelveticaBold>
              <div
                style={{ width: "100%", paddingTop: "5px" }}
                className="inline"
              >
                <td
                  className="tdcheckColumn"
                  style={{ marginRight: "0px", cursor: "default" }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={billingPeriod === "MONTHLY" ? true : false}
                    name="MONTHLY"
                    id="flexCheckDefault"
                    onChange={changeBillingPeriod}
                    style={{
                      width: "13px",
                      height: "13px",
                      cursor: "pointer",
                      zIndex: "5",
                      borderColor: "#414141",
                    }}
                  ></input>
                </td>
                <HelveticaLight
                  style={{
                    fontWeigth: "400",
                    cursor: "default",
                    color: "#B7B7B7",
                    fontSize: "14px",
                    paddingTop: "0px",
                    marginLeft: "-5px",
                    marginRight: "20px",
                    borderColor: "#414141",
                  }}
                >
                  {i18n.t("Mensual")}
                </HelveticaLight>
                <td className="tdcheckColumn" style={{ marginRight: "0px" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="YEARLY"
                    checked={billingPeriod === "YEARLY" ? true : false}
                    id="flexCheckDefault"
                    onChange={changeBillingPeriod}
                    style={{
                      width: "13px",
                      height: "13px",
                      cursor: "pointer",
                      zIndex: "5",
                      borderColor: "#414141",
                    }}
                  ></input>
                </td>
                <HelveticaLight
                  style={{
                    fontWeigth: "400",
                    color: "#B7B7B7",
                    fontSize: "14px",
                    paddingTop: "1px",
                    marginLeft: "-5px",
                    cursor: "default",
                  }}
                >
                  {i18n.t("Anual")}
                </HelveticaLight>
              </div>
              <div
                style={{
                  width: "95%",
                  height: "1px",
                  backgroundColor: "#7c7c7c",
                  marginBottom: "18px",
                  marginTop: "-4px",
                }}
              ></div>
              <div
                style={{ width: "95%", paddingTop: "5px" }}
                className="inline"
              >
                <div style={{ width: "80%", marginRight: "20px" }}>
                  <HelveticaBold
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      cursor: "default",
                    }}
                  >
                    {i18n.t("Subscripción " + planName)}
                  </HelveticaBold>
                </div>
                <div style={{ width: "20%" }}>
                  <HelveticaBold
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      cursor: "default",
                    }}
                  >
                    {parseFloat(precio).toFixed(2) +
                      "€/" +
                      (billingPeriod == "YEARLY"
                        ? i18n.t("año")
                        : i18n.t("mes"))}
                  </HelveticaBold>
                </div>
              </div>
              <div
                style={{ width: "95%", paddingTop: "5px" }}
                className="inline"
              >
                <div style={{ width: "80%", marginRight: "20px" }}>
                  <HelveticaBold
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      cursor: "default",
                    }}
                  >
                    {i18n.t("IVA ")}
                  </HelveticaBold>
                </div>
                <div style={{ width: "20%" }}>
                  <HelveticaBold
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      cursor: "default",
                    }}
                  >
                    {parseFloat(iva.toFixed(2)) + "€"}
                  </HelveticaBold>
                </div>
              </div>
              <div
                style={{
                  width: "95%",
                  height: "1px",
                  backgroundColor: "#7c7c7c",
                  marginBottom: "18px",
                  marginTop: "4px",
                }}
              ></div>
              <div
                style={{ width: "95%", paddingTop: "5px" }}
                className="inline"
              >
                <div
                  style={{
                    width: "50%",
                    marginRight: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <HelveticaBold
                    style={{
                      color: "#707070",
                      fontSize: "14px",
                      cursor: "default",
                    }}
                  >
                    {i18n.t("Codigo descuento")}
                  </HelveticaBold>
                </div>
                <div style={{ width: "50%" }}>
                  <BillingInput
                    style={{ width: "100%" }}
                    placeholder={i18n.t("Codigo de descuento")}
                    name="discount"
                    valid={discountCode.valid}
                    value={discountCode.value}
                    onChange={e => {
                      setDiscountCode({
                        ...discountCode,
                        value: e.target.value,
                      })
                    }}
                  />
                  <YellowButton
                    style={{ height: "30px" }}
                    onClick={handleValidateCoupon}
                  >
                    {coupon.loading === true ? (
                      <CircularProgress size={20} />
                    ) : (
                      "Añadir descuento"
                    )}
                  </YellowButton>
                </div>
              </div>
              <div
                style={{
                  width: "95%",
                  height: "1px",
                  backgroundColor: "#7c7c7c",
                  marginBottom: "18px",
                  marginTop: "12px",
                }}
              ></div>
              <SubscriptionTotal precio={precio} discount={coupon} />
              <div
                style={{
                  width: "95%",
                  height: "1px",
                  backgroundColor: "#7c7c7c",
                  marginBottom: "18px",
                  marginTop: "10px",
                }}
              ></div>
              <div
                style={{ width: "95%", paddingTop: "5px" }}
                className="inline"
              >
                <HelveticaLight
                  style={{
                    color: "#8E8E8E",
                    fontWeigth: "400",
                    fontSize: "12px",
                    cursor: "default",
                  }}
                >
                  {i18n.t(
                    "Tu suscripción se renovara automáticamente a la nueva suscripción seleccionada y se te cargará en tu tarjeta la cantidad del plan seleccionado. Puedes cancelar en cualquier momento antes del siguiente pago.",
                  )}
                </HelveticaLight>
              </div>
              {creditCardSaved && facturationAddressSaved ? (
                <YellowButton
                  style={{
                    position: "absolute",
                    right: "21px",
                    width: "91%",
                    bottom: "20px",
                    height: "35px",
                  }}
                  onClick={e => {
                    handlePayment(e)
                  }}
                >
                  {i18n.t("Confirmar Pago")}
                </YellowButton>
              ) : (
                <YellowButton
                  style={{
                    position: "absolute",
                    right: "21px",
                    width: "91%",
                    bottom: "20px",
                    height: "35px",
                    cursor: "default",
                    opacity: "0.5",
                    backgroundColor: "#AFAFB0",
                  }}
                >
                  {i18n.t("Confirmar Pago")}
                </YellowButton>
              )}
            </CardPricing>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default SubscriptionPayment
