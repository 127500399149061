import React, { useContext, useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { BlackHoverButton4CreateDynamically } from "common/containers"
import AddIcon from "@mui/icons-material/Add"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress, Modal } from "@mui/material"
import PropTypes from "prop-types"
import i18n from "i18n"
import {
  PoppinsSemiBold,
  PoppinsNormal,
  HelveticaBold,
} from "common/typographies"
import ContactContext from "context/contact/contactContext"
import AuthContext from "context/auth/authContext"
import { VersionsTable } from "common/tables"
import { YellowButton } from "common/buttons"
import AddContact from "components/Contacts/ContactTable/addContact"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "auto",
    minWidth: "500px",
    height: "500px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalSelectContact = ({
  open,
  setOpen,
  setSelectedContacts,
  selectedContacts,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { Contacts, getContacts, loadingContacts } = useContext(ContactContext)
  const { user } = useContext(AuthContext)
  const [checkboxArray, setCheckboxArray] = useState([])
  const changeCheckbox = (e, index) => {
    let aux = [...checkboxArray]
    aux[index] = aux[index] ? false : true
    setCheckboxArray(aux)
  }

  useEffect(() => {
    if (open) {
      getContacts()
    }
  }, [open])

  useEffect(() => {
    if (Contacts) {
      setCheckboxArray(Contacts.map(contact => false))
    }
  }, [Contacts])

  const [loading, setLoading] = useState(false)

  const handleAddContacts = async e => {
    e.preventDefault()
    let i = 0
    let trobat = false
    while (i < checkboxArray.length && !trobat) {
      if (checkboxArray[i]) {
        trobat = true
      } else {
        i++
      }
    }
    if (trobat) {
      setLoading(true)
      for (let i = 0; i < checkboxArray.length; i++) {
        if (checkboxArray[i]) {
          selectedContacts.push(Contacts[i])
        }
      }
      setSelectedContacts(selectedContacts)
      setLoading(false)
      setOpen(false)
    }
  }
  const [openAddContact, setOpenAddContact] = useState(false)

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setOpen(false)
            }}
          ></CloseIcon>
          <PoppinsSemiBold style={{ marginTop: "20px", fontSize: "18px" }}>
            {i18n.t("Contactos")}
          </PoppinsSemiBold>
          <div style={{ width: "100%", position: "relative" }}>
            {loadingContacts ? (
              <div style={{ marginLeft: "60px" }}>
                <div
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    position: "relative",
                    display: "flex",
                    marginLeft: "-100px",
                    marginTop: "60px",
                  }}
                >
                  <div className="loader">
                    <div className="ball"></div>
                    <div className="ball"></div>
                    <div className="ball"></div>
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    position: "relative",
                    display: "flex",
                    marginLeft: "-30px",
                  }}
                >
                  <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    width: "100%",
                    textAlign: "center",
                    position: "relative",
                    display: "flex",
                    marginLeft: "-30px",
                    marginTop: "-15px",
                  }}
                >
                  <PoppinsNormal style={{ fontWeight: "400" }}>
                    {i18n.t("Esta acción puede tardar unos segundos")}
                  </PoppinsNormal>
                </div>
              </div>
            ) : Contacts.length === 0 ? (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "80px",
                }}
              >
                <PoppinsSemiBold style={{ fontSize: "18px" }}>
                  {i18n.t("No tienes contactos")}
                </PoppinsSemiBold>
              </div>
            ) : (
              <>
                <VersionsTable style={{ maxHeight: "350px" }}>
                  <tr>
                    <th style={{ minWidth: "60px" }}></th>
                    <th style={{ width: "300px" }} colSpan="2">
                      {i18n.t("Nombre")}
                    </th>
                    <th style={{ width: "300px" }}>{i18n.t("Email")}</th>
                    <th style={{ width: "100px" }}>{i18n.t("Rol")}</th>
                  </tr>
                  {Contacts.map((contact, index) => (
                    <tr
                      onClick={e => {
                        if (!e) var e = window.event
                        e.cancelBubble = true
                        if (e.stopPropagation) e.stopPropagation()
                        changeCheckbox(e, index)
                      }}
                      key={index}
                      style={{ cursor: "pointer", background: "white" }}
                    >
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={checkboxArray[index]}
                          id="flexCheckDefault"
                          onClick={e => {
                            if (!e) var e = window.event
                            e.cancelBubble = true
                            if (e.stopPropagation) e.stopPropagation()
                          }}
                          onChange={e => {
                            changeCheckbox(e, index)
                          }}
                          style={{
                            width: "13px",
                            height: "13px",
                            cursor: "pointer",
                            zIndex: "5",
                          }}
                        ></input>
                      </td>
                      <td style={{ width: "50px" }}>
                        <img
                          src={user["custom:avatar"]}
                          style={{
                            width: "45px",
                            height: "45px",
                            marginRight: "0px",
                          }}
                        />
                      </td>
                      <td
                        style={{
                          fontWeight: "500",
                          color: "#414141",
                          paddingLeft: "0PX",
                        }}
                      >
                        {contact.completeName}
                      </td>
                      <td style={{ color: "#414141" }}> {contact.email}</td>
                      <td>
                        {contact.role ? (
                          contact.role.length > 0 ? (
                            <div
                              style={{
                                width: "fit-content",
                                paddingRight: "10px",
                              }}
                              id="color1"
                              className="tag"
                            >
                              {contact.role[0]}
                            </div>
                          ) : null
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </VersionsTable>
                <BlackHoverButton4CreateDynamically
                  onClick={() => {
                    setOpenAddContact(true)
                  }}
                  style={{
                    marginTop: "5px",
                    marginBottom: "10px",
                    marginLeft: "20px",
                  }}
                >
                  <AddIcon
                    style={{
                      fontSize: "18px",
                      marginRight: "5px",
                      color: "#414141",
                      marginLeft: "-5px",
                      paddingTop: "1px",
                    }}
                  />
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      fontWeight: "600",
                      paddingTop: "1px",
                      fontSize: "12px",
                    }}
                  >
                    {i18n.t("Crear contacto")}
                  </HelveticaBold>
                </BlackHoverButton4CreateDynamically>
              </>
            )}
            <div id="centerPaco">
              {loading ? (
                <CircularProgress size={24} />
              ) : Contacts.length > 0 ? (
                <YellowButton
                  onClick={e => {
                    handleAddContacts(e)
                  }}
                >
                  {i18n.t("Añadir")}
                </YellowButton>
              ) : (
                <YellowButton
                  onClick={() => {
                    setOpenAddContact(true)
                  }}
                >
                  {i18n.t("Agregar un nuevo contacto")}
                </YellowButton>
              )}
            </div>
          </div>
        </div>
      </Modal>
      {openAddContact && (
        <AddContact openAdd={openAddContact} setOpenAdd={setOpenAddContact} />
      )}
    </>
  )
}
ModalSelectContact.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setSelectedContacts: PropTypes.func,
  selectedContacts: PropTypes.array,
}

export default ModalSelectContact
