//Pages
import Contacts from "pages/Contacts"
import Document from "pages/Document/index"
import generalProfile from "pages/Authentication/general-profile"
//Icons
import home from "../../assets/images/icons/icono-bancoprecios.svg"
import archivos from "../../assets/images/icons/icono-archivos.svg"
import contactos from "assets/customIcons/contactos.svg"
import empresa from "assets/images/icons/icono-gestion.svg"
import whatsappIcon from "assets/customIcons/whatsapp_icon.svg"
import main from "assets/images/icons/icono-proyecto.svg"
import invoice from "assets/customIcons/invoices.svg"
import i18n from "i18n"
import SelectBankPage from "components/BancoPreciosReferencia/selectBankPrice"
import InvoicesDashboard from "pages/Invoice/dashboard"
import NewDashboard from "pages/Dashboard/newDashboard"
import headsetIcon from "assets/customIcons/Headset.svg"
import CampaignIcon from "@mui/icons-material/Campaign"
import FeedbackIcon from "@mui/icons-material/Feedback"

export const WHATSAPP_NUMBER = 34622235597
const routes = [
  {
    path: "",
    name: i18n.t("Proyectos"),
    allPaths: [
      "/dashboard",
      "/project",
      "/budgeting/",
      "/facturas/",
      "/facturas/create",
      "/equipos",
    ],
    icon: main,
    component: NewDashboard,
    layout: "/dashboard",
  },
  {
    path: "",
    name: i18n.t("Banco de precios"),
    allPaths: [
      "/bancodeprecios",
      "/bancodeprecios/templates",
      "/bancodeprecios/favorites/myBP",
    ],
    icon: home,
    component: SelectBankPage,
    layout: "/bancodeprecios/select",
  },
  {
    path: "",
    name: i18n.t("Facturas"),
    allPaths: ["/invoices", "facturas-general/create/"],
    icon: invoice,
    component: InvoicesDashboard,
    layout: "/invoices",
  },
  {
    path: "",
    name: i18n.t("Archivos"),
    allPaths: ["/documents", "/fotos"],
    icon: archivos,
    component: Document,
    layout: "/documents",
  },
  {
    path: "",
    name: i18n.t("Contactos"),
    allPaths: ["/contacts"],
    icon: contactos,
    component: Contacts,
    layout: "/contacts",
  },
  {
    path: "",
    name: i18n.t("Gestión de empresa"),
    allPaths: [
      "/generalProfile",
      "/subscription",
      "/budgetingtemplate",
      "/billingtemplate",
    ],
    icon: empresa,
    component: generalProfile,
    layout: "/generalProfile",
  },
  {
    path: `https://api.whatsapp.com/send/?phone=${WHATSAPP_NUMBER}&text=Hola%2C+%C2%BFen+que+puedo+ayudarte%3F&type=phone_number&app_absent=0`,
    name: i18n.t("Whatsapp contacto"),
    allPaths: ["https://chatwith.io/es/s/planhopper"],
    icon: whatsappIcon,
    link: true,
    layout: "/Whatsapp",
  },
  {
    path: "https://help.planhopper.com/es",
    name: i18n.t("Centro de ayuda"),
    allPaths: [
      "https://www.notion.so/planhopper/Planhopper-Centro-de-Ayuda-En-Construcci-n-c2fe8cbd570e4b2585eecaa1918d5c72",
    ],
    icon: headsetIcon,
    link: true,
    layout: "/CentroAyuda",
  },
  {
    path: "",
    name: i18n.t("sidebar.lastChanges"),
    allPaths: [],
    icon: CampaignIcon,
    link: false,
    isExternal: true,
    dataAttribute: "data-featurebase-changelog",
  },
  {
    path: "",
    name: i18n.t("sidebar.feedback"),
    allPaths: [],
    icon: FeedbackIcon,
    link: false,
    isExternal: true,
    dataAttribute: "data-featurebase-feedback",
  },
  /*
  {
    path: "",
    name: i18n.t("¡Gana 89€!"),
    allPaths : ["/referal"],
    icon: gift,
    component: ReferalPage,
    layout: "/referal"
  },
  */
]

export default routes
