import React, { useState } from "react"
import PropTypes from "prop-types"
import CertHistoryRowHeader from "./CertHistoryRowHeader"
import { Col, Row } from "reactstrap"
import CertHistoryRowContent from "./CertHistoryRowContent"
import classnames from "classnames"

const CertHistoryRow = ({
  title,
  data,
  isSubItem = false,
  showDeleteIcon = false,
}) => {
  const [showRow, setShowRow] = useState(false)
  return (
    <Row
      xs={12}
      style={{ margin: isSubItem ? "10px 0px" : "0px 30px 10px 30px" }}
      aria-label="general-row"
    >
      <Col
        xs={12}
        className=""
        onClick={() => {
          setShowRow(!showRow)
        }}
      >
        <CertHistoryRowHeader
          title={title}
          showDownloadIcon={!isSubItem}
          totalCertPrice={data.totalCertPrice}
          certId={data.id}
          showDeleteIcon={showDeleteIcon}
        />
      </Col>

      <Col
        xs={12}
        className={classnames("mt-1 rounded-2", {
          hide: !showRow,
          backgroundWhite: data.chapters.length === 0,
          backgroundLightBlue: data.chapters.length > 0,
        })}
        aria-label="general-Content"
      >
        {data.chapters && data.chapters.length > 0 ? (
          data.chapters.map(c => (
            <CertHistoryRow
              key={c.chapterId}
              data={c}
              title={c.name}
              isSubItem
            />
          ))
        ) : (
          <CertHistoryRowContent data={data.parts} />
        )}
      </Col>
    </Row>
  )
}

CertHistoryRow.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any,
  isSubItem: PropTypes.bool,
  showDeleteIcon: PropTypes.bool,
}

export default CertHistoryRow
