import React, { useContext, useEffect, useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter, Link, useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import ModalClosePassword from "components/CommonForBoth/TopbarDropdown/ModalClosePassword"
import usuarioIcon from "assets/customIcons/newUser.svg"

const ProfileDropdown = props => {
  const [menu, setMenu] = useState(false)
  const { user, getUser } = useContext(AuthContext)
  const history = useHistory()
  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])
  const [open, setOpen] = useState(false)
  return (
    <>
      <React.Fragment>
        <Dropdown isOpen={menu} toggle={() => setMenu(!menu)}>
          <div
            className="inline-user"
            id="sidebarMuyPronto"
            style={{ overflow: "hidden" }}
          >
            <DropdownToggle
              className=""
              id="settingb"
              style={{
                marginTop: "-10px",
                color: "black",
                margin: "0px",
                padding: "0px",
              }}
            >
              <img
                src={usuarioIcon}
                width="30px"
                height="30px"
                style={{ minWidth: "30px", minHeight: "30px" }}
              />
              {/*
           <div
                style={{
                  backgroundColor: '#FFD52B',
                  marginLeft: "0px",
                  paddingLeft: "30px",
                  paddingTop: "2px",
                  paddingBottom: "6px",
                  borderRadius: "6px",
                  height:'30px',
                  
                }}
              >
                <HelveticaLight className="notAffect" style={{fontWeight:'400', fontSize:'14px', paddingTop:'0px', color:'#414141', position:'fixed', marginTop:'2px', marginLeft:'-24px', opacity:'1'}}>
              {user ? user['custom:nombre_apellido']? (user['custom:nombre_apellido'].split(' ').shift().charAt(0) + user['custom:nombre_apellido'].split(' ').pop().charAt(0)).toUpperCase() : 'U' : 'U'}
            </HelveticaLight>
              </div>
               */}
            </DropdownToggle>
            <p
              style={{
                maxWidth: "180px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                margin: "0",
                whiteSpace: "nowrap",
                marginLeft: "13px",
                marginTop: "5px",
                fontFamily: "Helvetica",
                fontWeight: 400,
                fontSize: "14px",
                textTransform: "none",
              }}
            >
              {user ? user["custom:nombre_apellido"] : null}
            </p>
          </div>
          <DropdownMenu
            id="menuProfile"
            className="dropdown-menu-end"
            style={{ width: "170px" }}
          >
            {/*<div style={{borderBottom: '0.5px solid #c4c4c4'}}>
                <DropdownItem header>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div
                                style={{
                                    backgroundColor: '#FFD52B',
                                    marginLeft: "0px",
                                    paddingLeft: "30px",
                                    paddingTop: "1px",
                                    paddingBottom: "6px",
                                    borderRadius: "6px",
                                    height:'30px'
                                    
                                }}
                            >
                                <HelveticaLight className="notAffect" style={{fontWeight:'400', fontSize:'14px', paddingTop:'0px', color:'#414141', position:'fixed', marginTop:'2px', marginLeft:'-25px', opacity:'1'}}>
                                {user ? user['custom:nombre_apellido']? (user['custom:nombre_apellido'].split(' ').shift().charAt(0) + user['custom:nombre_apellido'].split(' ').pop().charAt(0)).toUpperCase() : 'U' : 'U'}
                                </HelveticaLight>
                            </div>
                            <div style={{marginLeft:'10px', width:'108px', height:'30px'}}>
                                <HelveticaLight className="notAffect" style={{fontWeight:'400', textTransform:'none', fontSize:'12px', whiteSpace:'normal', marginTop:'2px', lineHeight:'13px', paddingTop:'0px', color:'#414141', position:'fixed', overflow:'auto', opacity:'1'}}>
                                {user ? user['custom:nombre_apellido'] : 'Usuario'}
                                </HelveticaLight>
                            </div>
                        </div>
                    </div>
                </DropdownItem>
                
                              </div>*/}
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer", backgroundColor: "#ffffff" }}
              onClick={() => {
                history.push("/profile")
              }}
            >
              {" "}
              {i18n.t("Mi Perfil")}
            </DropdownItem>
            <DropdownItem
              tag="d"
              style={{ cursor: "pointer", backgroundColor: "#ffffff" }}
              onClick={() => {
                history.push("/subscription")
                history.go(0)
              }}
            >
              {" "}
              {i18n.t("Suscripcion")}
            </DropdownItem>
            <DropdownItem
              tag="g"
              style={{ cursor: "pointer", backgroundColor: "#ffffff" }}
              onClick={() => {
                setOpen(true)
              }}
            >
              {" "}
              {i18n.t("Cerrar sesión")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>

      <ModalClosePassword openPassword={open} setOpenPassword={setOpen} />
    </>
  )
}

ProfileDropdown.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

export default withTranslation()(withRouter(ProfileDropdown))
