import React, { Fragment, useState } from "react"
import i18n from "i18n"
import PropTypes from "prop-types"

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { HelveticaLight } from "common/typographies"
import AddIcon from "@mui/icons-material/Add"
const InvoicesColumnManager = ({ columns, setColumns }) => {
  const toggleColumn = column => {
    const newColumns = columns.map(col => {
      if (col.id === column.id) {
        return {
          ...col,
          show: !col.show,
        }
      }
      return col
    })
    setColumns(newColumns)
  }
  const [menu, setMenu] = useState(false)
  return (
    <Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
        style={{ position: "absolute", marginTop: "-2px", marginLeft: "5px" }}
      >
        <DropdownToggle
          id="settingb"
          style={{
            marginTop: "-10px",
            color: "black",
            margin: "0px",
            padding: "0px",
          }}
        >
          <div className="inline" style={{ marginRight: "10px" }}>
            <AddIcon
              style={{ color: "black", fontSize: "18px", marginRight: "5px" }}
            />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {columns.map((column, index, arr) => {
            const key = index * Math.random()
            return (
              <DropdownItem
                key={key}
                tag="b"
                style={{ lineHeight: "20px", cursor: "pointer" }}
                onClick={() => toggleColumn(column)}
              >
                {" "}
                <div className="inline">
                  <input
                    key={key}
                    onChange={() => toggleColumn(column)}
                    className="form-check-input"
                    type="checkbox"
                    checked={column.show}
                    id="flexCheckDefault"
                    style={{
                      width: "13px",
                      height: "13px",
                      cursor: "pointer",
                      zIndex: "5",
                      marginTop: "5px",
                    }}
                  />
                  <HelveticaLight
                    style={{
                      color: "#414141",
                      fontWeight: "400",
                      fontSize: "14px",
                      paddingTop: "2px",
                      paddingLeft: "10px",
                      marginBottom: "-10px",
                    }}
                  >
                    {i18n.t(`${column.label}`)}
                  </HelveticaLight>
                </div>
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>
    </Fragment>
  )
}

InvoicesColumnManager.propTypes = {
  columns: PropTypes.array.isRequired,
  setColumns: PropTypes.func.isRequired,
}

export default InvoicesColumnManager
