import React from "react"
import { YellowButton } from "common/buttons"
import { HelveticaBold } from "common/typographies"
import { useHistory } from "react-router-dom"
import i18n from "i18n"

const PasswordCompleted = () => {
  const history = useHistory()

  return (
    <div className="centered-container" style={{ height: "480px" }}>
      <HelveticaBold
        style={{
          color: "#414141",
          fontSize: "24px",
          fontWeight: "700",
          paddingTop: "20px",
          marginBottom: "5px",
        }}
      >
        {i18n.t("¡Contraseña restablecida!")}
      </HelveticaBold>
      <HelveticaBold
        style={{
          color: "#41414199",
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "230px",
        }}
      >
        {i18n.t(
          "Contraseña restablecida con éxito, ahora puedes iniciar sesión con tus nuevos creedenciales",
        )}
      </HelveticaBold>

      <div
        style={{
          width: "100%",
          position: "relative",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <YellowButton
          style={{
            width: "100%",
            marginBottom: "30px",
            height: "35px",
            rotate: "-0.5deg",
          }}
          onClick={e => {
            history.push("/login")
          }}
        >
          {i18n.t("Ingresar")}
        </YellowButton>
      </div>
    </div>
  )
}

export default PasswordCompleted
