import i18n from "i18n"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useContext } from "react"
import PropTypes from "prop-types"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "450px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "400px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const ImportDatabaseSucces = ({ open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const handleClose = () => {
    window.location.reload(false)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <div id="centerPaco">
            <img
              src={"https://media.tribboo.co/images/paquitoListo.png"}
              style={{ width: "130px", height: "130px", marginBottom: "10px" }}
            ></img>
          </div>
          <button
            type="button"
            onClick={() => {
              handleClose()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-body">
            <div>
              <div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                    fontFamily: "Roboto",
                  }}
                >
                  {i18n.t("¡Listo!")}
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    marginLeft: "40px",
                    marginRight: "40px",
                    color: "#414141",
                  }}
                >
                  {i18n.t("Tu archivo ha sido importado exitosamente")}
                </p>
                <div
                  className="inline"
                  style={{
                    width: "100%",
                    marginBottom: "35px",
                    marginTop: "-20px",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={handleClose}
                    className="AddProject"
                    style={{
                      paddingRight: "10px",
                      cursor: "pointer",
                      position: "relative",
                      bottom: "-30px",
                      width: "230px",
                      height: "50px",
                      border: "2px solid #FFD52B",
                      background: "#FFD52B",
                    }}
                  >
                    {i18n.t("Ver mi banco de precios")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
ImportDatabaseSucces.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}
export default ImportDatabaseSucces
