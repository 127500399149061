import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import i18n from "i18n"
import { HelveticaBold, PoppinsNormal } from "common/typographies"
import { Taxes } from "./constants"
import { Chip } from "@mui/material"
import styled from "styled-components"
const CustomDropdownToggle = styled(DropdownToggle)`
  width: 385.13px;
  height: 35px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  color: #414141;
  margin-bottom: 9px;
  padding-left: 8px;

  &::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #b7b7b7;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #ffd52b !important;
  }

  &:hover {
    &::placeholder {
      color: #414141;
    }
    border: 1px solid #414141;
  }
`
const DropdownSelectTax = props => {
  const [menu, setMenu] = useState(false)
  const ivaTaxes = Taxes.iva.types
  const recargo = Taxes.recargo.types
  const retention = Taxes.retention.types
  const initTax = (concept, taxType) => {
    let tax = {
      id: `${taxType}.${concept[taxType].value}`,
      name: concept[taxType].name,
      value: concept[taxType].value,
      type: concept[taxType].type,
      label: concept[taxType].name,
    }
    return tax
  }
  const [selectedIva, setSelectedIva] = useState(initTax(props.concept, "iva"))
  const [selectedRecargo, setSelectedRecargo] = useState(
    props.concept,
    "recargo",
  )
  const [selectedRetention, setSelectedRetention] = useState(
    props.concept,
    "retention",
  )

  const handleTaxSelect = tax => {
    let tmpTax = {}
    if (tax.id.includes("iva")) {
      tmpTax =
        selectedIva.name === tax.name
          ? { name: "IVA", type: null, value: 0 }
          : tax
      props.handleTaxChange(tmpTax, "iva", props.concept)
    }
    if (tax.id.includes("recargo")) {
      tmpTax =
        selectedRecargo.name === tax.name
          ? { name: "IVA", type: null, value: 0 }
          : tax
      props.handleTaxChange(tmpTax, "recargo", props.concept)
    }
    if (tax.id.includes("retention")) {
      tmpTax =
        selectedRetention.name === tax.name
          ? { name: "IVA", type: null, value: 0 }
          : tax
      props.handleTaxChange(tmpTax, "retention", props.concept)
    }
  }

  useEffect(() => {
    setSelectedIva(initTax(props.concept, "iva"))
    setSelectedRecargo(initTax(props.concept, "recargo"))
    setSelectedRetention(initTax(props.concept, "retention"))
  }, [props.concept.iva, props.concept.recargo, props.concept.retention])

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{
            width: "11%",
            height: `${props.size.height + 21.5} px`,
            marginLeft: "0px",
            marginRight: "0px",
          }}
        >
          <CustomDropdownToggle
            style={{
              borderLeft: "0px",
              height:
                props.size.height < 38
                  ? `${props.size.height + 2 + 19.5}px`
                  : `${props.size.height + 19.5}px`,
              minHeight: "33px",
              marginTop: "-7.5px",
              marginBottom: "-2px",
              marginLeft: "20px",
              width: "100%",
              paddingLeft: "10px",
              minWidth: "85px",
              paddingRight: "12px",
              borderRadius: "0px",
            }}
          >
            <div
              className="inline"
              style={{
                position: "relative",
                overflowX: "auto",
                overflowY: "hidden",
                whiteSpace: "nowrap",
                height: `${props.size.height + 21.5} px`,
                padding: "0px 0px 0px 0px",
              }}
            >
              {selectedIva.type !== undefined && selectedIva.type !== null && (
                <PoppinsNormal
                  style={{
                    textTransform: "none",
                    fontSize: "14px",
                    color: "#414141",
                    lineHeight: "16px",
                    cursor: "pointer",
                  }}
                >
                  <Chip
                    label={selectedIva.name}
                    sx={{
                      marginX: "2px",
                      fontSize: "14px",
                      borderRadius: "6px",
                      height: "20px",
                      border: "2px solid #FFD52B",
                      ".MuiChip-deleteIcon": {
                        fontSize: "14px",
                      },
                    }}
                    onDelete={() => {
                      handleTaxSelect({ ...selectedIva })
                    }}
                  />
                </PoppinsNormal>
              )}
              {selectedRetention.type !== undefined &&
                selectedRetention.type !== null && (
                  <PoppinsNormal
                    style={{
                      textTransform: "none",
                      fontSize: "14px",
                      color: "#414141",
                      lineHeight: "16px",
                      cursor: "pointer",
                    }}
                  >
                    <Chip
                      label={selectedRetention.name}
                      fontSize="12px"
                      sx={{
                        marginX: "2px",
                        fontSize: "14px",
                        borderRadius: "6px",
                        height: "20px",
                        border: "2px solid #FFD52B",
                        ".MuiChip-deleteIcon": {
                          fontSize: "14px",
                        },
                      }}
                      onDelete={() => {
                        handleTaxSelect({ ...selectedRetention })
                      }}
                    />
                  </PoppinsNormal>
                )}
              {selectedRecargo.type !== undefined &&
                selectedRecargo.type !== null && (
                  <PoppinsNormal
                    style={{
                      textTransform: "none",
                      fontSize: "14px",
                      color: "#414141",
                      lineHeight: "16px",
                      cursor: "pointer",
                    }}
                  >
                    <Chip
                      label={selectedRecargo.name}
                      sx={{
                        marginX: "2px",
                        fontSize: "14px",
                        borderRadius: "6px",
                        height: "20px",
                        border: "2px solid #FFD52B",
                        ".MuiChip-deleteIcon": {
                          fontSize: "14px",
                        },
                      }}
                      onDelete={() => {
                        handleTaxSelect({ ...selectedRecargo })
                      }}
                    />
                  </PoppinsNormal>
                )}
            </div>
          </CustomDropdownToggle>
          <DropdownMenu
            className="dropdown-menu-end"
            style={{ width: "130%", right: "0px", marginTop: "-32px" }}
          >
            <DropdownItem disabled={true}>
              <HelveticaBold
                style={{
                  fontSize: "14px",
                  color: "#414141",
                  margin: "0px",
                }}
              >
                {i18n.t("IVA")}
              </HelveticaBold>
            </DropdownItem>
            {ivaTaxes.map((tax, index) => {
              return (
                <DropdownItem
                  onClick={() => {
                    handleTaxSelect(tax)
                  }}
                  key={index}
                  tag="b"
                  style={{
                    lineHeight: "20px",
                    cursor: "pointer",
                    backgroundColor:
                      tax.name === selectedIva.name ? "#f2f2f2" : "white",
                  }}
                >
                  {" "}
                  <div
                    style={{ alignItems: "center", overflow: "hidden" }}
                    className="inline"
                  >
                    {tax.name}
                  </div>
                </DropdownItem>
              )
            })}
            <DropdownItem disabled={true}>
              <HelveticaBold
                style={{
                  fontSize: "14px",
                  color: "#414141",
                  margin: "0px",
                }}
              >
                {i18n.t("Retención")}
              </HelveticaBold>
            </DropdownItem>
            {retention.map((tax, index) => {
              return (
                <DropdownItem
                  onClick={() => {
                    handleTaxSelect(tax)
                  }}
                  key={index}
                  tag="b"
                  style={{
                    lineHeight: "20px",
                    cursor: "pointer",
                    backgroundColor:
                      tax.name === selectedRetention.name ? "#f2f2f2" : "white",
                  }}
                >
                  {" "}
                  <div
                    style={{ alignItems: "center", overflow: "hidden" }}
                    className="inline"
                  >
                    {tax.name}
                  </div>
                </DropdownItem>
              )
            })}
            <DropdownItem disabled={true}>
              <HelveticaBold
                style={{
                  fontSize: "14px",
                  color: "#414141",
                  margin: "0px",
                }}
              >
                {i18n.t("Recargo de equivalencia")}
              </HelveticaBold>
            </DropdownItem>
            {recargo.map((tax, index) => {
              return (
                <DropdownItem
                  onClick={() => {
                    handleTaxSelect(tax)
                  }}
                  key={index}
                  tag="b"
                  style={{
                    lineHeight: "20px",
                    cursor: "pointer",
                    backgroundColor:
                      tax.name === selectedRecargo.name ? "#f2f2f2" : "white",
                  }}
                >
                  {" "}
                  <div
                    style={{ alignItems: "center", overflow: "hidden" }}
                    className="inline"
                  >
                    {tax.name}
                  </div>
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </>
  )
}

DropdownSelectTax.propTypes = {
  concept: PropTypes.any,
  handleTaxChange: PropTypes.func,
  size: PropTypes.any,
}

export default DropdownSelectTax
