import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import BancoPreciosRefContent from "components/BancoPreciosReferencia/BancoPreciosRefContent"
import { useParams } from "react-router-dom"
import * as amplitude from "@amplitude/analytics-browser"

import { Container } from "reactstrap"
import { RecordPageView } from "App"
import { ListBank } from "./ListBank"
const getName = (uri, referencia) => {
  for (let cat of referencia.categorias) {
    if (cat.uri == uri) {
      return cat.name
    }
  }
}

const BancoDePreciosReferenciaC = () => {
  RecordPageView()
  const params = useParams()
  const referencia = params.referencia
  const tipo = params.tipo
    ? getName(params.tipo, ListBank[referencia])
    : ListBank[referencia].categorias[0].name

  useEffect(() => {
    amplitude.track({
      event_type: "Banco de precios referencia visited",
      event_properties: { referencia: referencia, tipo: tipo },
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper | Banco de precios </title>
        </MetaTags>
        <Container fluid>
          {tipo.length > 0 ? (
            <BancoPreciosRefContent tipo={tipo} referencia={referencia} />
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BancoDePreciosReferenciaC
