import React, { useContext, useState } from "react"
import i18n from "i18n"
import imagen1 from "assets/customIcons/invoices/crear-factura.svg"
import imagen2 from "assets/customIcons/invoices/plan-de-pago.svg"
import imagen3 from "assets/customIcons/invoices/certificacion.svg"
import ModalDefinirFases from "./modals/modalDefinirFases"
import ModalSelectProject from "./modals/modalSelectProject"
import AuthContext from "context/auth/authContext"
import PropTypes from "prop-types"
import ModalAntesDeEmpezar from "./modals/modalAntesDeEmpezar"
import { useHistory, useParams } from "react-router-dom"
import { nanoid } from "nanoid"
const SelectNewFactura = ({ invoiceService }) => {
  const { billingTemplate, freeTrialExpired, openFreeTrialExpire } =
    useContext(AuthContext)
  const [openModalTemplate, setOpenModalTemplate] = useState(false)
  const [num, setNum] = useState(0)
  const history = useHistory()
  const { projectId } = useParams()
  const imGeneral = projectId ? false : true
  const [openDefinirFases, setOpenDefinirFases] = useState(false)
  const [openProject, setOpenProject] = useState(false)
  const [selectedProject, setSelectedProject] = useState(null)
  const handleCreateFromPresupuesto = () => {
    if (imGeneral) {
      setOpenProject(true)
    } else {
      setOpenDefinirFases(true)
    }
  }
  const handleGoToFactura = numero => {
    // if(Object.keys(billingTemplate).length === 0 || billingTemplate.theme == "#ffffff"){
    //     if(numero==1){
    //         setNum(1)
    //     }else{
    //         setNum(2)
    //     }
    //     setOpenModalTemplate(true)
    // }else{
    if (numero == 1) {
      handleCreateFromPresupuesto()
    } else {
      history.replace(
        `/invoice/${nanoid()}${projectId ? `?projectId=${projectId}` : ""}`,
      )
    }
    //}
  }

  return (
    <>
      <div
        className="CardProjects"
        onClick={() => {
          freeTrialExpired ? openFreeTrialExpire() : handleGoToFactura(2)
        }}
        style={{
          marginTop: "40px",
          marginLeft: "40px",
          cursor: "pointer",
          width: "85%",
          border: "1px solid #C4C4C4",
        }}
      >
        <div id="CardContent">
          <div
            className="ImgProyect"
            style={{ marginLeft: "0px", marginRight: "20px" }}
          >
            <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
              <img src={imagen1} height="74px" width="74px" />
            </span>
          </div>
          <div
            id="CenterContentProyectCard"
            style={{
              marginLeft: "105px",
              marginRight: "20px",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            <div
              className="flex-grow-1 overflow-hidden"
              style={{ marginRight: "-10px" }}
            >
              <div
                id="AddProyecto"
                style={{ fontSize: "18px", marginTop: "-5px" }}
              >
                {i18n.t("Comienza desde cero")}
              </div>
              <p
                className="DescriptionText"
                style={{ fontSize: "14px", marginRight: "0px" }}
              >
                <b style={{ fontSize: "14px", fontFamily: "Helvetica" }}>
                  Crearás tu factura manualmente desde cero
                </b>
                {i18n.t(" en base a tus precios de referencia.")}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="CardProjects"
        onClick={() => {
          freeTrialExpired ? openFreeTrialExpire() : handleGoToFactura(1)
        }}
        style={{
          marginLeft: "40px",
          cursor: "pointer",
          width: "85%",
          border: "1px solid #C4C4C4",
        }}
      >
        <div id="CardContent">
          <div
            className="ImgProyect"
            style={{ marginLeft: "0px", marginRight: "20px" }}
          >
            <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
              <img src={imagen2} height="75px" width="54px" />
            </span>
          </div>
          <div
            id="CenterContentProyectCard"
            style={{
              marginLeft: "105px",
              marginRight: "20px",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            <div
              className="flex-grow-1 overflow-hidden"
              style={{ marginRight: "-10px" }}
            >
              <div
                id="AddProyecto"
                style={{ fontSize: "18px", marginTop: "-5px" }}
              >
                {i18n.t("Factura desde presupuesto")}
              </div>
              <p className="DescriptionText" style={{ fontSize: "14px" }}>
                Crea tu factura a partir de tu{" "}
                <b style={{ fontSize: "14px", fontFamily: "Helvetica" }}>
                  presupuesto del proyecto.
                </b>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="CardProjects"
        style={{
          cursor: "default",
          backgroundColor: "#e9e9e9",
          marginLeft: "40px",
          cursor: "pointer",
          width: "85%",
        }}
      >
        <div id="CardContent">
          <div
            className="ImgProyect"
            style={{ marginLeft: "0px", marginRight: "20px" }}
          >
            <span className="avatar-title rounded-circle bg-light text-danger font-size-16">
              <img src={imagen3} height="62px" width="75px" />
            </span>
          </div>
          <div
            id="CenterContentProyectCard"
            style={{
              marginLeft: "105px",
              marginRight: "20px",
              textAlign: "left",
              fontSize: "14px",
            }}
          >
            <div
              className="flex-grow-1 overflow-hidden"
              style={{ marginRight: "-10px" }}
            >
              <div
                id="AddProyecto"
                style={{ fontSize: "18px", marginTop: "-5px" }}
              >
                {i18n.t("Hacer una certificación")}
              </div>
              <p className="DescriptionText" style={{ fontSize: "14px" }}>
                Realiza una certificación a partir de tu{" "}
                <b style={{ fontSize: "14px", fontFamily: "Helvetica" }}>
                  presupuesto del proyecto.
                </b>
              </p>
            </div>
          </div>
          <div style={{ top: "10px", right: "10px" }} id="muypronto2">
            {i18n.t("muy pronto")}
          </div>
        </div>
      </div>
      {/* {num==1 ? 
            <ModalAntesDeEmpezar open={openModalTemplate} setOpen={setOpenModalTemplate} setOpenNextStep={handleCreateFromPresupuesto} setNum={setNum} num={num}/>
            :
            <ModalAntesDeEmpezar open={openModalTemplate} setOpen={setOpenModalTemplate} setNum={setNum} num={num}/>
            } */}
      {openDefinirFases && (
        <ModalDefinirFases
          open={openDefinirFases}
          setOpen={setOpenDefinirFases}
          imGeneral={imGeneral}
          invoiceService={invoiceService}
          selectedProject={selectedProject}
        />
      )}
      {openProject && (
        <ModalSelectProject
          open={openProject}
          setOpen={setOpenProject}
          imGeneral={imGeneral}
          openDefinirFases={openDefinirFases}
          setOpenDefinirFases={setOpenDefinirFases}
          setProjectSelected={setSelectedProject}
        />
      )}
    </>
  )
}
SelectNewFactura.propTypes = {
  invoiceService: PropTypes.any,
}
export default SelectNewFactura
