import MetaTags from "react-meta-tags"
import React from "react"
import { useRegister } from "hooks/useRegister"
import { Auth } from "aws-amplify"
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth"
import { Container } from "reactstrap"
import i18n from "i18n"
import logoLarge from "assets/customIcons/logos/planhopper_logo_large.svg"
import { YellowButton } from "common/buttons"
import { HelveticaBold } from "common/typographies"
import { HelveticaInput } from "common/inputs"
import { InlineEmptyButton } from "common/containers"
import googleOutlined from "assets/images/googleOutlined.png"
import facebookOutlined from "assets/images/facebookOutlined.png"
import microsoftOultined from "assets/images/microsoftOutlined.png"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { CircularProgress } from "@mui/material"
import VisibilityOff from "assets/customIcons/VisibilityOff.svg"
import VisibilityOn from "assets/customIcons/VisibilityOn.svg"
import { RecordPageView } from "App"
import tribboLogo from "assets/customIcons/logos/Logo Tribboo.svg"
import arrow from "assets/customIcons/logos/Arrow 1.svg"
import planhopperLogo from "assets/customIcons/logos/planhopper_logo_large.svg"
const Register = () => {
  RecordPageView()
  const {
    isLocalhost,
    email,
    mailError,
    passwordError,
    errorMessage,
    passwordMessage,
    handleRegister,
    handleChange,
    loading,
  } = useRegister()
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Planhopper</title>
      </MetaTags>
      <Container fluid style={{ backgroundColor: "#EFEFEF", height: "100vh" }}>
        <div className="inline">
          <a
            href="https://www.planhopper.com"
            target="_blank"
            rel="noreferrer"
            style={{ zIndex: "99" }}
          >
            <img
              className="logoPositioning"
              src={planhopperLogo}
              width="147px"
              height="45px"
            />
          </a>
        </div>
        <div className="centered-container">
          <HelveticaBold
            className="tituloGrandeResponsive"
            style={{
              color: "#414141",
              fontSize: "24px",
              fontWeight: "700",
              marginBottom: "5px",
            }}
          >
            {i18n.t("Prueba Planhopper 30 dias gratis")}
          </HelveticaBold>
          <HelveticaBold
            className="subTitleGrey"
            style={{
              color: "#41414199",
              fontSize: "14px",
              fontWeight: "400",
              marginBottom: "25px",
            }}
          >
            {i18n.t(
              "Sin compromiso, sin límites y sin necesidad de tarjeta de credito",
            )}
          </HelveticaBold>

          <HelveticaBold
            style={{
              fontSize: "14px",
              marginBottom: "5px",
              color: "#41414199",
            }}
          >
            {i18n.t("Email")}
          </HelveticaBold>
          <HelveticaInput
            style={{
              width: "100%",
              marginBottom: !mailError && "30px",
              border: mailError && "1px solid #BF2929",
            }}
            placeholder={i18n.t("Introduce tu correo electrónico")}
            name="email"
            value={email}
            autoComplete="off"
            onChange={e => handleChange(e)}
          ></HelveticaInput>
          {mailError && (
            <div style={{ position: "relative" }}>
              <HelveticaBold
                className="errorMessageHelvetica"
                style={{ marginBottom: "6px" }}
              >
                {errorMessage}
              </HelveticaBold>
              <HighlightOffIcon className="errorPasswordIcon" />
            </div>
          )}
          <div style={{ display: "flex", position: "relative", width: "100%" }}>
            <HelveticaBold
              style={{
                fontSize: "14px",
                marginBottom: "5px",
                color: "#41414199",
              }}
            >
              {i18n.t("Contraseña")}
            </HelveticaBold>
            <div
              style={{
                position: "absolute",
                right: "0px",
                top: "0px",
                display: "flex",
              }}
            >
              {showPassword ? (
                <>
                  <img
                    src={VisibilityOff}
                    style={{
                      width: "17px",
                      height: "14px",
                      marginTop: "3px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowPassword(false)}
                  />
                  <HelveticaBold
                    style={{
                      fontSize: "12px",
                      marginBottom: "5px",
                      marginTop: "1px",
                      cursor: "pointer",
                      color: "#414141",
                      fontWeight: "400",
                      marginLeft: "5px",
                    }}
                    onClick={() => setShowPassword(false)}
                  >
                    {i18n.t("Ocultar")}
                  </HelveticaBold>
                </>
              ) : (
                <>
                  <img
                    src={VisibilityOn}
                    style={{
                      width: "15px",
                      height: "12px",
                      cursor: "pointer",
                      marginTop: "4px",
                    }}
                    onClick={() => setShowPassword(true)}
                  />
                  <HelveticaBold
                    style={{
                      fontSize: "12px",
                      marginBottom: "5px",
                      cursor: "pointer",
                      marginTop: "1px",
                      color: "#414141",
                      fontWeight: "400",
                      marginLeft: "5px",
                    }}
                    onClick={() => setShowPassword(true)}
                  >
                    {i18n.t("Mostrar")}
                  </HelveticaBold>
                </>
              )}
            </div>
          </div>

          <div>
            <HelveticaInput
              style={{
                width: "100%",
                marginBottom: !passwordError && "38px",
                border: passwordError && "1px solid #BF2929",
              }}
              placeholder={i18n.t("Introduir contraseña")}
              name="password"
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              onChange={e => handleChange(e)}
            ></HelveticaInput>
          </div>

          {passwordError && (
            <div style={{ position: "relative" }}>
              <HelveticaBold
                className="errorMessageHelvetica"
                style={{ marginBottom: "12px" }}
              >
                {passwordMessage}
              </HelveticaBold>
              <HighlightOffIcon className="errorPasswordIcon" />
            </div>
          )}
          {loading ? (
            <div
              style={{
                width: "100%",
                position: "relative",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <CircularProgress size={24} style={{ marginBottom: "30px" }} />
            </div>
          ) : (
            <YellowButton
              style={{
                width: "100%",
                marginBottom: "23px",
                height: "35px",
                rotate: "-0.5deg",
              }}
              onClick={() => {
                handleRegister()
              }}
            >
              {i18n.t("Empieza tu prueba de 30 dias")}
            </YellowButton>
          )}
          {isLocalhost ? null : (
            <>
              <div
                style={{
                  marginBottom: "10px",
                  display: "flex",
                  position: "relative",
                }}
              >
                <div className="greySeparator" />
                <div
                  className="separatorContent"
                  style={{ margin: "-6px 19px" }}
                >
                  <HelveticaBold
                    style={{
                      color: "#41414199",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {i18n.t("o registrate con")}
                  </HelveticaBold>
                </div>
                <div className="greySeparator" />
              </div>

              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <InlineEmptyButton
                  className="federatedButtons"
                  style={{ marginRight: "20px" }}
                  onClick={() =>
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Google,
                    })
                  }
                >
                  <img src={googleOutlined} width="18px" height="17px" />
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "700",
                      marginLeft: "10px",
                      marginTop: "16px",
                    }}
                  >
                    {i18n.t("Google")}
                  </HelveticaBold>
                </InlineEmptyButton>

                <InlineEmptyButton
                  className="federatedButtons"
                  style={{ marginRight: "20px" }}
                >
                  <img src={facebookOutlined} width="11px" height="18px" />
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      fontSize: "14px",
                      fontWeight: "700",
                      marginLeft: "10px",
                      marginTop: "16px",
                    }}
                    onClick={() =>
                      Auth.federatedSignIn({
                        provider: CognitoHostedUIIdentityProvider.Facebook,
                      })
                    }
                  >
                    {i18n.t("Facebook")}
                  </HelveticaBold>
                </InlineEmptyButton>

                {/* <InlineEmptyButton className="federatedButtons">
              <img src={microsoftOultined} width='18px' height='17px'/>
              <HelveticaBold style={{color:'#414141', fontSize:'14px', fontWeight:'700', marginLeft:'10px', marginTop:'16px'}}>
                {i18n.t("Microsoft")}
              </HelveticaBold>
            </InlineEmptyButton> */}
              </div>
            </>
          )}

          <div
            style={{
              marginTop: "13px",
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              position: "relative",
            }}
          >
            <HelveticaBold
              className="littlePresponsive"
              style={{
                color: "#41414199",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {i18n.t("¿Ya tienes cuenta?")}
            </HelveticaBold>
            <a href="/login">
              <HelveticaBold
                className="littlePresponsive"
                style={{
                  color: "#4461D7",
                  fontSize: "14px",
                  fontWeight: "400",
                  textDecoration: "underline",
                  marginLeft: "5px",
                }}
              >
                {i18n.t("Inicia sesion")}
              </HelveticaBold>
            </a>
          </div>

          <div
            style={{
              marginTop: "-10px",
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              position: "relative",
            }}
          >
            <HelveticaBold
              className="littlePresponsive"
              style={{
                color: "#41414199",
                fontSize: "12px",
                fontWeight: "400",
              }}
            >
              {i18n.t("Al continuar estás aceptando")}
            </HelveticaBold>
            <a
              href="https://www.planhopper.com/terms-of-services"
              rel="noreferrer"
              target="_blank"
            >
              <HelveticaBold
                className="littlePresponsive"
                style={{
                  color: "#4461D7",
                  fontSize: "12px",
                  fontWeight: "400",
                  marginLeft: "5px",
                }}
              >
                {i18n.t("nuestros terminos y condiciones")}
              </HelveticaBold>
            </a>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default Register
