import React, { useContext, useEffect, useState } from "react"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { PoppinsNormal, PoppinsSemiBold } from "common/typographies"
import i18n from "i18n"
import CloseIcon from "@mui/icons-material/Close"
import BankSelector from "./bankselector"
import LayoutMiBancoDePrecios from "./selectPartType/layoutMiBancoDePrecios"
import LayoutBancoRef from "./selectPartType/layoutBancoRef"
import PrincipalChapters from "./myBP"
import AuthContext from "context/auth/authContext"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import { YellowButton } from "common/buttons"
import { CircularProgress } from "@mui/material"
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext"
import CreateManualPart from "./createManualPart"
import useNewSearch from "hooks/newSearch"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "1000px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    height: "calc(100vh - 100px)",
    overflowY: "hidden",
    overflowX: "hidden",
  },
}))

const ModalSearchParts = ({ open, setOpen, capitulo }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const banks = [
    "User database",
    "Tribboo database",
    "Cype database",
    "Bedec database",
    "Manual part",
  ]
  const [bankSelected, setBankSelected] = useState("User database")
  const [referencia, setReferencia] = useState("")
  const [tipo, setTipo] = useState("")
  const [chaptersToShow, setChaptersToShow] = useState([])
  const [disabled, setDisabled] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const [doTheAction, setDoTheAction] = useState(false)
  const { user, getUser } = useContext(AuthContext)
  const {
    getMyBankPrices,
    getMyBankPricesWithoutClassify,
    myPricesBank,
    loading,
    getSearchChapters,
  } = useContext(
    bankSelected == "User database"
      ? BankPricesContext
      : BankPricesReferenceContext,
  )
  const [cont, setCont] = useState(0)
  const [estoyEnBusqueda, setEstoyEnBusqueda] = useState(false)
  useEffect(() => {
    if (!user) {
      getUser
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (estoyEnBusqueda) {
        if (bankSelected == "User database") {
          handleSearch2()
        } else {
          if (tipo.length > 0) {
            handleSearch2()
          }
        }
      } else {
        if (bankSelected == "User database") {
          getMyBankPrices(user.sub)
          getMyBankPricesWithoutClassify(user.sub)
          setCont(1)
        } else {
          if (tipo.length > 0) {
            if (bankSelected == "Cype database") {
              getMyBankPrices(tipo, "cype")
              setCont(1)
            } else if (bankSelected == "Bedec database") {
              getMyBankPrices(tipo, "bedec")
              setCont(1)
            }
          }
        }
      }
    }
  }, [bankSelected, tipo])
  useEffect(() => {
    if (
      myPricesBank.subcapitulos.length > 0 &&
      bankSelected == "User database"
    ) {
      if (estoyEnBusqueda) {
        if (results.length > 0) {
          setChaptersToShow([myPricesBank])
        } else {
          setChaptersToShow([])
        }
      } else {
        setChaptersToShow(JSON.parse(JSON.stringify([myPricesBank])))
      }
    }
    if (
      myPricesBank.subcapitulos.length > 0 &&
      bankSelected == "Cype database"
    ) {
      if (estoyEnBusqueda) {
        if (results.length > 0) {
          setChaptersToShow([myPricesBank])
        } else {
          setChaptersToShow([])
        }
      } else {
        setChaptersToShow(JSON.parse(JSON.stringify([myPricesBank])))
      }
    }
    if (
      myPricesBank.subcapitulos.length > 0 &&
      bankSelected == "Bedec database"
    ) {
      if (estoyEnBusqueda) {
        if (results.length > 0) {
          setChaptersToShow([myPricesBank])
        } else {
          setChaptersToShow([])
        }
      } else {
        setChaptersToShow(JSON.parse(JSON.stringify([myPricesBank])))
      }
    }
  }, [myPricesBank.subcapitulos.length, bankSelected, tipo, estoyEnBusqueda])

  const { results, loadingSearch, getResults } = useNewSearch()
  const [word, setWord] = useState("")

  const handleSearch = async e => {
    if (e.target.value == "") {
      setEstoyEnBusqueda(false)
      setWord("")
      if (bankSelected == "User database") {
        getMyBankPrices(user.sub)
        setCont(1)
      } else {
        if (tipo.length > 0) {
          if (bankSelected == "Cype database") {
            getMyBankPrices(tipo, "cype")
            setCont(1)
          } else if (bankSelected == "Bedec database") {
            getMyBankPrices(tipo, "bedec")
            setCont(1)
          }
        }
      }
    } else {
      if (e.target.value.length >= 3) {
        setWord(e.target.value)
        if (bankSelected == "User database") {
          getSearchChapters(await getResults(e.target.value, "user-database"))
          setEstoyEnBusqueda(true)
          if (results.length == 0) {
            setChaptersToShow([])
          }
        } else if (bankSelected == "Cype database") {
          getSearchChapters(await getResults(e.target.value, "cype", tipo))
          setEstoyEnBusqueda(true)
          if (results.length == 0) {
            setChaptersToShow([])
          }
        } else if (bankSelected == "Bedec database") {
          getSearchChapters(await getResults(e.target.value, "bedec", tipo))
          setEstoyEnBusqueda(true)
          if (results.length == 0) {
            setChaptersToShow([])
          }
        }
      } else {
        setWord(e.target.value)
      }
    }
  }

  const handleSearch2 = async () => {
    if (word.length >= 3) {
      if (bankSelected == "User database") {
        getSearchChapters(await getResults(word, "user-database"))
        if (results.length == 0) {
          setChaptersToShow([])
        }
      } else if (bankSelected == "Cype database") {
        getSearchChapters(await getResults(word, "cype", tipo))
        if (results.length == 0) {
          setChaptersToShow([])
        }
      } else if (bankSelected == "Bedec database") {
        getSearchChapters(await getResults(word, "bedec", tipo))
        if (results.length == 0) {
          setChaptersToShow([])
        }
        setEstoyEnBusqueda(true)
      }
    }
  }

  return (
    <>
      <Modal open={open} onClose={() => null}>
        <div style={modalStyle} className={classes.paper}>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              position: "relative",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <PoppinsSemiBold style={{ fontSize: "18px" }}>
              {i18n.t(
                "Selecciona la base de datos donde deseas buscar la partida",
              )}
              :
            </PoppinsSemiBold>
            <CloseIcon
              id="cerrar"
              style={{ marginTop: "-40px", right: "20px" }}
              onClick={() => {
                setOpen(false)
              }}
            ></CloseIcon>
          </div>

          <BankSelector
            banks={banks}
            bankSelected={bankSelected}
            setBankSelected={setBankSelected}
            setReferencia={setReferencia}
            setTipo={setTipo}
          />
          {/*
            <div style={{width:'100%', justifyContent:'center', position:'relative', textAlign:'center', marginTop:'25px'}}>
                <Input4Search onChange={(e)=>{handleSearch(e)}} value={word} placeholder={i18n.t("Introduce palabras clave para buscar partidas...")}/>
                <SearchOutlined style={{position:'absolute', left:'168px', top:'10px'}}/>
            </div>
             */}
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              position: "relative",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            {bankSelected === "User database" ? (
              <LayoutMiBancoDePrecios />
            ) : bankSelected === "Cype database" ||
              bankSelected == "Bedec database" ? (
              <LayoutBancoRef
                referencia={referencia}
                tipo={tipo}
                setTipo={setTipo}
              />
            ) : null}
          </div>

          {bankSelected === "Tribboo database" ? (
            <PoppinsNormal style={{ marginLeft: "160px", marginTop: "30px" }}>
              {i18n.t(
                "No hay ninguna base de datos de partidas de Planhopper disponible en este momento.",
              )}
            </PoppinsNormal>
          ) : bankSelected === "Manual part" ? (
            <CreateManualPart setOpen={setOpen} capitulo={capitulo} />
          ) : (
            <>
              <PrincipalChapters
                estoyEnBusqueda={estoyEnBusqueda}
                chaptersToShow={chaptersToShow}
                setChaptersToShow={setChaptersToShow}
                loadingTree={loading}
                bankSelected={bankSelected}
                tipo={tipo}
                disabled={disabled}
                setDisabled={setDisabled}
                loadingButton={loadingButton}
                setLoadingButton={setLoadingButton}
                doTheAction={doTheAction}
                setDoTheAction={setDoTheAction}
                setOpen={setOpen}
                loadingSearch={loadingSearch}
                capitulo={capitulo}
              />
              <div
                style={{
                  width: "60%",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {loadingButton ? (
                  <CircularProgress
                    size={24}
                    style={{
                      position: "fixed",
                      bottom: "25px",
                      marginLeft: "180px",
                    }}
                  />
                ) : (
                  <YellowButton
                    onClick={() => {
                      if (!disabled) {
                        setDoTheAction(true)
                      }
                    }}
                    style={{
                      position: "fixed",
                      bottom: "25px",
                      width: "360px",
                      height: "34px",
                      cursor: disabled ? "default" : "pointer",
                      backgroundColor: disabled ? "#AFAFB0" : "#F1CA2C",
                    }}
                  >
                    {i18n.t("Añadir partida al presupuesto")}
                  </YellowButton>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  )
}

ModalSearchParts.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  capitulo: PropTypes.object.isRequired,
}
export default ModalSearchParts
