import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

const PartComparison = ({ part, tabla }) => {
  const [total, setTotal] = useState(0)
  const [higher, setHigher] = useState(0)
  const [lower, setLower] = useState(0)
  useEffect(() => {
    if (part.preciosProveedores) {
      let higherAux = [0, part.preciosProveedores[0].precio]
      let lowerAux = [0, part.preciosProveedores[0].precio]
      if (part.preciosProveedores.length > 1) {
        for (let i = 0; i < part.preciosProveedores.length; i++) {
          if (part.preciosProveedores[i].precio > higherAux[1]) {
            higherAux = [i, part.preciosProveedores[i].precio]
          }
          if (part.preciosProveedores[i].precio < lowerAux[1]) {
            lowerAux = [i, part.preciosProveedores[i].precio]
          }
        }
        setHigher(higherAux[0])
        setLower(lowerAux[0])
      } else {
        setHigher(999)
        setLower(0)
      }
    }
  }, [part])
  useEffect(() => {
    if (part) {
      setTotal(
        parseFloat(parseFloat(part.quantity) * part.userPrice).toFixed(2),
      )
    }
  }, [])
  return (
    <tr>
      <td
        style={{
          fontFamily: "Helvetica",
          color: part.chapterId ?? "#414141",
          fontWeight: part.chapterId ?? "600",
        }}
      >
        {part.unity}
      </td>
      <td
        style={{
          fontWeight: "300",
          color: part.chapterId ?? "#414141",
          fontWeight: part.chapterId ?? "600",
        }}
      >
        {part.name}
      </td>
      <td style={{ paddingLeft: "23px", fontFamily: "Helvetica" }}>
        {part.medicion}
      </td>
      <td style={{ fontFamily: "Helvetica" }}>
        {part.chapterId ? parseFloat(part["precio objetivo"]).toFixed(2) : ""}
      </td>
      <td style={{ fontFamily: "Helvetica", borderLeft: "1px solid #EBEBEB" }}>
        {part.chapterId ? parseFloat(part["precio total"]).toFixed(2) : ""}
      </td>
      {part.preciosProveedores
        ? part.preciosProveedores.map((item, index) => {
            return (
              <>
                <td
                  style={{
                    fontFamily: "Helvetica",
                    opacity:
                      tabla[tabla.length - 1][index].proposalInfo.statusUser ==
                      "LOCKED"
                        ? "0.44"
                        : "1",
                    backgroundColor:
                      tabla[tabla.length - 1][index].proposalInfo.statusUser ==
                      "LOCKED"
                        ? "rgba(217, 217, 217, 0.44)"
                        : higher === index
                          ? "#FBD7D7"
                          : lower === index
                            ? "#E4F4E8"
                            : "#FFF8DA",
                  }}
                >
                  {item.precio}
                </td>
                <td
                  style={{
                    opacity:
                      tabla[tabla.length - 1][index].proposalInfo.statusUser ==
                      "LOCKED"
                        ? "0.44"
                        : "1",
                    fontFamily: "Helvetica",
                    borderLeft: "1px solid #EBEBEB",
                    backgroundColor:
                      tabla[tabla.length - 1][index].proposalInfo.statusUser ==
                      "LOCKED"
                        ? "rgba(217, 217, 217, 0.44)"
                        : higher === index
                          ? "#FBD7D7"
                          : lower === index
                            ? "#E4F4E8"
                            : "#FFF8DA",
                  }}
                >
                  {parseFloat(item.total).toFixed(2)}
                </td>
              </>
            )
          })
        : tabla[0].map((item, index) => {
            return (
              <>
                <td
                  style={{
                    fontFamily: "Helvetica",
                    opacity:
                      tabla[tabla.length - 1][index].proposalInfo.statusUser ==
                      "LOCKED"
                        ? "0.44"
                        : "1",
                    backgroundColor:
                      tabla[tabla.length - 1][index].proposalInfo.statusUser ==
                      "LOCKED"
                        ? "rgba(217, 217, 217, 0.44)"
                        : "white",
                  }}
                ></td>
                <td
                  style={{
                    fontFamily: "Helvetica",
                    borderLeft: "1px solid #EBEBEB",
                    opacity:
                      tabla[tabla.length - 1][index].proposalInfo.statusUser ==
                      "LOCKED"
                        ? "0.44"
                        : "1",
                    backgroundColor:
                      tabla[tabla.length - 1][index].proposalInfo.statusUser ==
                      "LOCKED"
                        ? "rgba(217, 217, 217, 0.44)"
                        : "white",
                  }}
                ></td>
              </>
            )
          })}
    </tr>
  )
}
PartComparison.propTypes = {
  part: PropTypes.object.isRequired,
  tabla: PropTypes.array.isRequired,
}
export default PartComparison
