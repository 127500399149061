import React, { useEffect, useState, useContext } from "react"
import { Modal, Box, Typography, Grid, IconButton } from "@mui/material"
import PropTypes from "prop-types"
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined"
import {
  CustomButton,
  ActionModal,
} from "components/Documents/StylesAndUtils/CustomStyles"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import i18n from "i18n"
import Budget2023Context from "context/budget2023/Budget2023Context"
const ModalDeleteImages = ({
  openEliminate,
  setOpenEliminate,
  image,
  partidaInfo,
}) => {
  const [url, setUrl] = useState()
  useEffect(() => {
    if (image) {
      setUrl(image.fileId)
    }
  }, [image])
  const { deleteImageFromPart } = useContext(Budget2023Context)

  const handleEliminar = async () => {
    await deleteImageFromPart(
      partidaInfo.accountId,
      partidaInfo.partidaId,
      partidaInfo.chapterId,
      image.fileId,
      partidaInfo.elementInfo,
    )
    setOpenEliminate(false)
  }
  const getLastItem = thePath => thePath.substring(thePath.lastIndexOf("/") + 1)
  return (
    <Modal open={openEliminate} onClose={() => null}>
      <Box component="form" sx={ActionModal}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            sx={{
              alignSelf: "flex-end",
            }}
          >
            <IconButton onClick={() => setOpenEliminate(false)}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <WarningOutlinedIcon fontSize="large" sx={{ fill: "#FFD52B" }} />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center">
              {i18n.t("¿Está seguro que desea eliminar el archivo")}{" "}
              {image ? getLastItem(image.url) : ""}?
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              {i18n.t("Esta acción no se puede deshacer.")}
            </Typography>
          </Grid>
          <Grid item sx={{ marginY: "1.5rem" }}>
            <CustomButton
              style={{ color: "white", backgroundColor: "#BF2929" }}
              onClick={() => {
                handleEliminar()
              }}
            >
              {i18n.t("Eliminar")}
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

ModalDeleteImages.propTypes = {
  openEliminate: PropTypes.bool.isRequired,
  setOpenEliminate: PropTypes.func.isRequired,
  image: PropTypes.object.isRequired,
  partidaInfo: PropTypes.object.isRequired,
}
export default ModalDeleteImages
