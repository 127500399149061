import React, { Fragment } from "react"
import { useState } from "react"
import { Card, CircularProgress } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import i18n from "i18n"
import PrompInput from "./prompInput"
import PartHeader from "./partHeader"
import { API, Auth } from "aws-amplify"
import DecompositionTable from "./decompositionTable"
import PartTotal from "./partTotal"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import "assets/css/partGenerator.css"
import { useOpenAI } from "hooks/useOpenAI"
import { YellowButton } from "common/buttons"
import RefreshIcon from "@mui/icons-material/Refresh"

const useStyles = makeStyles(theme => ({
  paper: {
    position: "relative",
    width: "100%",
    backgroundColor: "transparent !important",
    boxShadow: "none !important",
    paddingTop: "0px !important",
    padding: 20,
    borderRadius: 6,
    height: "auto",
    overflowY: "hidden",
    overflowX: "hidden",
  },
}))
const PartGeneratorContainer = () => {
  const openai = useOpenAI()
  const SERVICE_INTEGRATION = "service-integration"
  const classes = useStyles()
  const [model, setModel] = useState("CURIE")
  const handleSubmit = async prompt => {
    try {
      openai.handleSubmit({ text: prompt, model: model })
    } catch (error) {
      console.log(error)
    }
  }
  const [isThumbedUp, setIsThumbedUp] = useState(false)
  const [isThumbedDown, setIsThumbedDown] = useState(false)
  const [prompt, setPrompt] = React.useState({
    value: "",
    error: false,
  })

  const handleVote = async vote => {
    if (vote === "up") {
      if (!isThumbedUp) {
        await votePart("up")
        setIsThumbedUp(true)
        setIsThumbedDown(false)
      }
    } else {
      if (!isThumbedDown) {
        await votePart("down")
        setIsThumbedDown(true)
        setIsThumbedUp(false)
      }
    }
  }
  const [loadingVote, setLoadingVote] = useState(false)
  const votePart = async vote => {
    try {
      setLoadingVote(true)
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }

      const response = await API.post(
        SERVICE_INTEGRATION,
        `/openai/feedback-part`,
        {
          headers: headers,
          body: {
            part: openai.currentPart,
            model: model,
            feedback: vote == "up" ? "ACCEPTED" : "DECLINED",
          },
        },
      )
      setLoadingVote(false)
    } catch (error) {
      setLoadingVote(false)
    }
  }
  const validate = () => {
    const words = prompt.value.split(" ")
    if (words.length < 3) {
      return false
    }
    return true
  }

  const handleSearch = e => {
    e.stopPropagation()
    e.preventDefault()
    if (validate()) {
      setPrompt({ value: prompt.value, error: false })
      handleSubmit(prompt.value)
    } else {
      setPrompt({ value: prompt.value, error: true })
    }
  }
  const onChangeQuantity = (value, _, values) => {
    openai.setQuantity({
      float: values.float ?? 1,
      formatted: values.formattedValue,
      value: values.value,
    })
    let newPart = { ...openai.currentPart }
    newPart.quantity = values.float ?? 1
    newPart.decomposition = openai.currentPart.decomposition.map(item => {
      return { ...item, quantityComputed: values.float * item.quantity }
    })
    openai.setCurrentPart(newPart)
  }

  return (
    <Card className={classes.paper}>
      <div
        className="inline"
        style={{ marginTop: "0px", marginBottom: "15px", position: "relative" }}
      >
        <YellowButton
          onClick={() => setModel("CURIE")}
          style={{
            width: "113px",
            boxShadow: "none",
            fontSize: "14px",
            zIndex: "2",
            backgroundColor: `${model === "CURIE" ? "#ffd52b" : "#F4F4F4"}`,
            border: `${model === "CURIE" ? "none" : "1px solid #ffd52b"}`,
          }}
        >
          {i18n.t("Básico")}
        </YellowButton>
        <YellowButton
          onClick={() => setModel("DAVINCI")}
          style={{
            width: "113px",
            boxShadow: "none",
            marginLeft: "10px",
            marginTop: "1px",
            fontSize: "14px",
            backgroundColor: `${model === "DAVINCI" ? "#ffd52b" : "#F4F4F4"}`,
            border: `${model === "DAVINCI" ? "none" : "1px solid #ffd52b"}`,
          }}
        >
          {i18n.t("Avanzado")}
        </YellowButton>
        {openai.currentPart?.status === "COMPLETED" ? (
          !loadingVote ? (
            <>
              <ThumbUpIcon
                onClick={() => {
                  handleVote("up")
                }}
                className="thumbUp"
                style={{
                  marginTop: "13px",
                  marginLeft: "20px",
                  cursor: isThumbedUp ? "default" : "pointer",
                  width: "20px",
                  opacity: isThumbedUp && "1",
                  color: isThumbedUp && "#00CC00",
                }}
              />
              <ThumbDownIcon
                onClick={() => {
                  handleVote("down")
                }}
                className="thumbDown"
                style={{
                  marginTop: "13px",
                  marginLeft: "10px",
                  cursor: isThumbedDown ? "default" : "pointer",
                  width: "20px",
                  opacity: isThumbedDown && "1",
                  color: isThumbedDown && "#CC0000",
                }}
              />
              <RefreshIcon
                onClick={e => {
                  handleSearch(e)
                }}
                className="refresh"
                style={{
                  marginTop: "13px",
                  marginLeft: "8px",
                  cursor: "pointer",
                  width: "23px",
                }}
              />
            </>
          ) : (
            <CircularProgress
              size={20}
              style={{ marginTop: "16px", marginLeft: "20px" }}
            />
          )
        ) : null}
      </div>
      <div>
        <PrompInput
          handleSubmit={handleSubmit}
          generating={openai.generating}
          prompt={prompt}
          setPrompt={setPrompt}
        />
      </div>
      {openai.currentPart?.id ? (
        <Fragment>
          <PartHeader part={openai.currentPart} />
          <DecompositionTable part={openai.currentPart} />
          <PartTotal part={openai.currentPart} />
        </Fragment>
      ) : null}
    </Card>
  )
}
export default PartGeneratorContainer
