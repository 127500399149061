import React, { useContext, useState, useEffect } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import PropTypes from "prop-types"
import i18n from "i18n"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { Document, Page } from "react-pdf"
import { useHistory } from "react-router-dom"
import SendModal from "components/Facturas/sendFactura/sendModal"
import { useReactToPrint } from "react-to-print"
import ModalDeleteFactura from "components/Facturas/modals/modalDeleteFactura"
import AuthContext from "context/auth/authContext"
import DownloadIcon from "@mui/icons-material/Download"
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
const InvoiceActions = ({ invoice, invoiceService }) => {
  const getPDFURL = invoiceId => {
    const download = invoiceService.invoicesPdf.find(
      invoicePdf => invoicePdf.invoiceId === invoiceId,
    )
    return download ? download.url : null
  }
  const [openEliminate, setOpenEliminate] = useState(false)
  const [menu, setMenu] = useState(false)
  const history = useHistory()
  const [invoicePdf, setInvoicePdf] = useState(getPDFURL(invoice.invoiceId))
  const [open, setOpen] = useState(false)
  const { user, freeTrialExpired, openFreeTrialExpire } =
    useContext(AuthContext)
  const [numPages, setNumPages] = useState(null)
  const refToPrint = React.useRef()
  const [download, setDownload] = useState(false)
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const handlePrint = useReactToPrint({
    content: () => refToPrint.current,
  })
  useEffect(() => {
    setInvoicePdf(getPDFURL(invoice.invoiceId))
  }, [invoiceService.invoicesPdf])
  useEffect(() => {
    if (menu === true) {
      invoiceService.backgroundDownload({
        invoiceId: invoice.invoiceId,
      })
    }
  }, [menu])

  //EDIT
  const handleEdit = () => {
    if (freeTrialExpired) {
      openFreeTrialExpire()
    } else {
      history.push(
        `/invoice/${invoice.invoiceId}?projectId=${invoice.projectId}`,
      )
    }
  }

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{ position: "absolute", top: "-20px" }}
        >
          <DropdownToggle
            id="settingb"
            style={{
              color: "black",
              marginTop: "2.5px",
              padding: "0px",
              width: "100%",
              background: "transparent",
            }}
          >
            <MoreVertIcon
              id="settingsBud"
              style={{ color: "#414141", marginTop: "6px" }}
            />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-end"
            style={{ minWidth: "125px" }}
          >
            <DropdownItem
              tag="b"
              onClick={() => {
                freeTrialExpired
                  ? openFreeTrialExpire()
                  : setOpenEliminate(true)
              }}
              style={{ lineHeight: "20px", cursor: "pointer" }}
            >
              {" "}
              <div className="inline">
                <DeleteOutlineIcon
                  style={{
                    color: "#414141",
                    fontSize: "20px",
                    marginTop: "1px",
                    marginLeft: "3px",
                    marginRight: "7px",
                  }}
                />
                {i18n.t("Eliminar")}
              </div>
            </DropdownItem>
            <DropdownItem
              tag="b"
              onClick={handleEdit}
              style={{ lineHeight: "20px", cursor: "pointer" }}
            >
              {" "}
              <div className="inline">
                <EditOutlinedIcon
                  style={{
                    color: "#414141",
                    fontSize: "20px",
                    marginTop: "1px",
                    marginLeft: "3px",
                    marginRight: "7px",
                  }}
                />
                {i18n.t("Editar")}
              </div>
            </DropdownItem>
            <DropdownItem
              tag="b"
              onClick={() => {
                freeTrialExpired ? openFreeTrialExpire() : setOpen(true)
              }}
              style={{ lineHeight: "20px", cursor: "pointer" }}
            >
              {" "}
              <div className="inline">
                <i
                  className="bx bx-send"
                  style={{
                    color: "#414141",
                    fontSize: "20px",
                    marginTop: "1px",
                    marginLeft: "3px",
                    marginRight: "7px",
                  }}
                ></i>
                {i18n.t("Enviar")}
              </div>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                freeTrialExpired ? openFreeTrialExpire() : handlePrint()
              }}
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
            >
              {" "}
              <div className="inline">
                <PrintOutlinedIcon
                  style={{
                    color: "#414141",
                    fontSize: "20px",
                    marginTop: "1px",
                    marginLeft: "3px",
                    marginRight: "7px",
                  }}
                />
                {i18n.t("Imprimir")}
              </div>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                freeTrialExpired
                  ? openFreeTrialExpire()
                  : invoiceService.handleDownload({
                      invoiceId: invoice.invoiceId,
                    })
              }}
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
            >
              {" "}
              <div className="inline">
                <DownloadIcon
                  style={{
                    color: "#414141",
                    fontSize: "20px",
                    marginTop: "1px",
                    marginLeft: "3px",
                    marginRight: "7px",
                  }}
                />
                {i18n.t("Descargar")}
              </div>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                freeTrialExpired
                  ? openFreeTrialExpire()
                  : invoiceService.handleDuplicate({
                      invoiceId: invoice.invoiceId,
                    })
              }}
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
            >
              {" "}
              <div className="inline">
                <ContentCopyIcon
                  style={{
                    color: "#414141",
                    fontSize: "20px",
                    marginTop: "1px",
                    marginLeft: "3px",
                    marginRight: "7px",
                  }}
                />
                {i18n.t("Duplicar")}
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>

      {open === true && (
        <SendModal
          open={open}
          setOpen={setOpen}
          facturas={[invoice]}
          invoiceService={invoiceService}
        />
      )}
      {invoicePdf && (
        <div style={{ display: "none" }}>
          <Document
            ref={refToPrint}
            file={invoicePdf}
            onLoadError={console.error}
            style={{ overflowY: "auto" }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {[...Array(numPages)].map((elementInArray, index) => (
              <Page key={index} pageNumber={index + 1} scale={2} />
            ))}
          </Document>
        </div>
      )}
      <ModalDeleteFactura
        factura={invoice}
        openEliminate={openEliminate}
        setOpenEliminate={setOpenEliminate}
        invoiceService={invoiceService}
      />
    </>
  )
}

InvoiceActions.propTypes = {
  invoice: PropTypes.object.isRequired,
  invoiceService: PropTypes.any,
}

export default InvoiceActions
