import React, { Fragment, useContext, useState } from "react"
import i18n from "i18n"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import PropTypes from "prop-types"
import { TableFooter } from "@mui/material"
import InvoicesColumnManager from "./invoicesColumnManager"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { PoppinsNormal } from "common/typographies"
import InvoiceActions from "./invoiceActions"
import ModalPrevisualizarPDF from "components/Facturas/modals/modalPreviewPDF"
import { useHistory } from "react-router-dom"
import { CheckedInput, CustomCheckedInput } from "common/inputs"
import TopQuickInvoiceActions from "./TopQuickInvoiceActions"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext"

const COLUMNS = [
  {
    id: "createdAt",
    label: i18n.t("Fecha"),
    show: true,
    type: "date",
  },
  {
    id: "invoiceDueDate",
    label: i18n.t("Vencimiento"),
    show: true,
    type: "date",
  },
  {
    id: "invoiceNumber",
    label: i18n.t("No. Factura"),
    show: true,
    type: "string",
  },
  {
    id: "projectInfo.name",
    label: i18n.t("Proyecto"),
    show: true,
    type: "string",
  },
  {
    id: "contact.completeName",
    label: i18n.t("Cliente"),
    show: true,
    type: "string",
  },
  {
    id: "subtotal",
    label: i18n.t("Subtotal"),
    show: true,
    type: "currency",
  },
  {
    id: "iva",
    label: i18n.t("IVA"),
    show: false,
    type: "currency",
  },
  {
    id: "retention",
    label: i18n.t("Retención"),
    show: false,
    type: "currency",
  },
  {
    id: "recargo",
    label: i18n.t("Recargo"),
    show: false,
    type: "currency",
  },
  {
    id: "total",
    label: i18n.t("Total"),
    show: true,
    type: "currency",
  },
  {
    id: "invoiceStatus",
    label: i18n.t("Estado de factura"),
    show: true,
    type: "status",
  },
]

const InvoiceStatus = ({ status }) => {
  const bgColor = status === "UNPAID" ? "#E8CDAD" : "#C9DBCD"
  const color = status === "UNPAID" ? "#DB7600" : "#3BA452"
  const text = status === "UNPAID" ? "No Pagada" : "Pagada"
  return (
    <div
      className="inline"
      style={{
        marginTop: "0px",
        backgroundColor: bgColor,
        height: "22px",
        borderRadius: "6px",
        width: "103px",
        padding: "0px",
      }}
    >
      {status === "UNPAID" ? (
        <HighlightOffIcon
          style={{
            color: color,
            fontSize: "19px",
            marginTop: "1.5px",
            marginLeft: "6px",
          }}
        />
      ) : (
        <CheckCircleOutlineIcon
          style={{
            color: color,
            fontSize: "19px",
            marginTop: "1.5px",
            marginLeft: "6px",
          }}
        />
      )}
      <PoppinsNormal
        style={{
          fontSize: "10px",
          color: color,
          lineHeight: "20px",
          cursor: "pointer",
          paddingTop: "1.5px",
          paddingLeft: status == "UNPAID" ? "5px" : "12px",
          textTransform: "uppercase",
        }}
      >
        {text}
      </PoppinsNormal>
    </div>
  )
}
InvoiceStatus.propTypes = {
  status: PropTypes.string.isRequired,
}
export function formatCurrency(number) {
  const numString = number.toFixed(2) // Round to 2 decimal places
  const numArr = numString.split(".") // Split into integer and decimal parts
  const integerPart = numArr[0]
  const decimalPart = numArr[1]
  const len = integerPart.length

  let result = ""
  let count = 0
  for (let i = len - 1; i >= 0; i--) {
    result = integerPart.charAt(i) + result
    count++
    if (count % 3 === 0 && i !== 0) {
      result = "." + result
    }
  }
  return result + "," + decimalPart // Add decimal part and return result
}

const InvoicesTable = ({ invoiceService }) => {
  const { user } = useContext(AuthContext)
  const [openPDF, setOpenPDF] = useState(false)
  const [columns, setColumns] = useState(COLUMNS)
  const history = useHistory()
  const goToEdit = (e, invoice) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(`/invoice/${invoice.invoiceId}`)
  }

  const [invoicesChecked, setInvoicesChecked] = useState(
    invoiceService.invoices.map(invoice => false),
  )

  const [invoicesToMakeActions, setInvoicesToMakeActions] = useState([])

  const changeCheck = (e, i) => {
    e.preventDefault()
    e.stopPropagation()
    let aux = invoicesChecked
    setInvoicesChecked(
      aux.map((check, index) => {
        if (index === i) {
          invoicesToMakeActions.find(
            invoice =>
              invoice.invoiceId === invoiceService.invoices[i].invoiceId,
          )
            ? setInvoicesToMakeActions(
                invoicesToMakeActions.filter(
                  invoice =>
                    invoice.invoiceId !== invoiceService.invoices[i].invoiceId,
                ),
              )
            : setInvoicesToMakeActions([
                ...invoicesToMakeActions,
                invoiceService.invoices[i],
              ])
          return !check
        }
        return check
      }),
    )
  }

  const resolveValue = (invoice, column) => {
    switch (column.type) {
      case "date":
        try {
          const dt = new Date(invoice[column.id]).toLocaleDateString()
          if (dt === "Invalid Date") return "-"
          return dt
        } catch (e) {
          return "-"
        }
      case "currency":
        try {
          return (
            formatCurrency(invoice[column.id]) +
            getCurrency(user["custom:currency"])
          )
        } catch (e) {
          return "0.00" + getCurrency(user["custom:currency"])
        }
      case "string":
        try {
          let ids = column.id.split(".")
          if (ids.length > 1) {
            return invoice[ids[0]][ids[1]]
          }
          return invoice[column.id]
        } catch (e) {
          return "-"
        }
      case "status":
        return <InvoiceStatus status={invoice[column.id]} />
    }
  }
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const resolveGlobals = column => {
    switch (column.id) {
      case "subtotal":
        try {
          return (
            formatCurrency(invoiceService.summary.subtotal) +
            getCurrency(user["custom:currency"])
          )
        } catch (e) {
          return "0.00" + getCurrency(user["custom:currency"])
        }
      case "iva":
        try {
          return (
            formatCurrency(invoiceService.summary.iva) +
            getCurrency(user["custom:currency"])
          )
        } catch (e) {
          return "0.00" + getCurrency(user["custom:currency"])
        }
      case "retention":
        try {
          return (
            formatCurrency(invoiceService.summary.retention) +
            getCurrency(user["custom:currency"])
          )
        } catch (e) {
          return "0.00" + getCurrency(user["custom:currency"])
        }
      case "recargo":
        try {
          return (
            formatCurrency(invoiceService.summary.recargo) +
            getCurrency(user["custom:currency"])
          )
        } catch (e) {
          return "0.00" + getCurrency(user["custom:currency"])
        }
      case "total":
        try {
          return (
            formatCurrency(invoiceService.summary.total) +
            getCurrency(user["custom:currency"])
          )
        } catch (e) {
          return "0.00" + getCurrency(user["custom:currency"])
        }
      default:
        return ""
    }
  }
  const handlePreviewPDF = invoiceId => {
    setSelectedInvoice(invoiceId)
    setOpenPDF(true)
  }

  const [allChecked, setAllChecked] = useState(false)
  return (
    <Fragment>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "95vw",
          overflow: "hidden",
          height: "auto",
        }}
        style={{ overflow: "inherit" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <div
              id="rowHoverSpecial2"
              style={{
                position: "absolute",
                height: "49px",
                marginLeft: "-20px",
                width: "90%",
                cursor: "pointer",
              }}
            >
              <div
                id="specialInputHover"
                style={{
                  display: allChecked && "block",
                  backgroundColor: "transparent",
                  width: "40px",
                  height: "49px",
                  marginTop: "0px",
                  position: "absolute",
                  zIndex: "2",
                  left: "-5px",
                }}
                onClick={e => {
                  e.stopPropagation()
                }}
              >
                <CustomCheckedInput
                  onClick={e => {
                    if (allChecked) {
                      setInvoicesToMakeActions([])
                    } else {
                      setInvoicesToMakeActions(invoiceService.invoices)
                    }
                    setAllChecked(!allChecked)
                    setInvoicesChecked(
                      invoicesChecked.map(invoice => !allChecked),
                    )
                  }}
                  style={{ marginTop: "17px", marginLeft: "3px" }}
                >
                  {allChecked && <CheckedInput />}
                </CustomCheckedInput>
              </div>
            </div>
            <TableRow id="rowHover">
              {columns.map(
                (column, index) =>
                  column.show && (
                    <TableCell
                      style={{ borderBottom: invoicesChecked[0] && "none" }}
                      key={index}
                      id="helveticaTitle"
                    >
                      {column.label}
                    </TableCell>
                  ),
              )}
              <TableCell>
                <div style={{ height: "20px" }}>
                  <InvoicesColumnManager
                    columns={columns}
                    setColumns={setColumns}
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceService.invoices.map((invoice, i) => (
              <>
                <div
                  id="rowHoverSpecial"
                  onClick={e => handlePreviewPDF(invoice.invoiceId)}
                  style={{
                    position: "absolute",
                    height: "49px",
                    marginLeft: "-20px",
                    width: "90%",
                    cursor: "pointer",
                  }}
                >
                  <div
                    id="specialInputHover"
                    style={{
                      display: invoicesChecked[i] && "block",
                      backgroundColor: "transparent",
                      width: "40px",
                      height: "49px",
                      marginTop: "0px",
                      position: "absolute",
                      zIndex: "2",
                      left: "-5px",
                    }}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  >
                    <CustomCheckedInput
                      onClick={e => {
                        e.stopPropagation()
                        changeCheck(e, i)
                      }}
                      style={{ marginTop: "17px", marginLeft: "3px" }}
                    >
                      {invoicesChecked[i] && <CheckedInput />}
                    </CustomCheckedInput>
                  </div>
                </div>
                <TableRow
                  id="rowHover"
                  key={invoice.invoiceId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                  }}
                  style={{
                    opacity: "1",
                    border: invoicesChecked[i] && "1.5px solid #FFD52B",
                    borderBottom:
                      i !== invoicesChecked.length
                        ? invoicesChecked[i + 1] && "none"
                        : "none",
                  }}
                >
                  {columns.map((column, index) => {
                    return (
                      column.show && (
                        <TableCell
                          style={{ borderBottom: "0px" }}
                          key={index}
                          id="bigCellWithOverflow"
                          onClick={() => handlePreviewPDF(invoice.invoiceId)}
                        >
                          {resolveValue(invoice, column)}
                        </TableCell>
                      )
                    )
                  })}

                  <TableCell
                    id="bigCellWithOverflow"
                    style={{ overflow: "inherit", borderBottom: "none" }}
                  >
                    <div style={{ position: "absolute" }}>
                      <InvoiceActions
                        invoice={invoice}
                        invoiceService={invoiceService}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
          <TableFooter>
            {columns.map((column, index) => {
              return (
                column.show && (
                  <TableCell
                    key={index}
                    id="helveticaTitle"
                    style={{ fontSize: "14px", color: "#414141" }}
                  >
                    {resolveGlobals(column)}
                  </TableCell>
                )
              )
            })}
          </TableFooter>
        </Table>
      </TableContainer>
      {openPDF === true && (
        <ModalPrevisualizarPDF
          open={openPDF}
          setOpen={setOpenPDF}
          invoiceService={invoiceService}
          invoiceId={selectedInvoice}
        />
      )}
      {invoicesToMakeActions.length > 0 && (
        <TopQuickInvoiceActions
          invoices={invoicesToMakeActions}
          setAllChecked={setAllChecked}
          setInvoicesToMakeActions={setInvoicesToMakeActions}
          invoicesChecked={invoicesChecked}
          setInvoicesChecked={setInvoicesChecked}
          invoiceService={invoiceService}
        />
      )}
    </Fragment>
  )
}
InvoicesTable.propTypes = {
  invoiceService: PropTypes.any,
}
export default InvoicesTable
