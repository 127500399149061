import React from "react"
import PropTypes from "prop-types"
const IllustrationsTab = ({ defa, handleonClick }) => {
  return (
    <div id="avatares" style={{ marginLeft: "10%" }}>
      <img
        id="fotoAvatar"
        src="https://d1t181n48swd5d.cloudfront.net/imgProject/00_DEFAULT.png"
        onClick={handleonClick}
        alt={defa}
      />
      <img
        id="fotoAvatar"
        src="https://d1t181n48swd5d.cloudfront.net/imgProject/01_VIVIENDA.png"
        alt="avatar1"
        onClick={handleonClick}
      />
      <img
        id="fotoAvatar"
        src="https://d1t181n48swd5d.cloudfront.net/imgProject/02_REFORMA.png"
        alt="avatar2"
        onClick={handleonClick}
      />
      <img
        id="fotoAvatar"
        src="https://d1t181n48swd5d.cloudfront.net/imgProject/03_REHAB.png"
        alt="avatar3"
        onClick={handleonClick}
      />
      <img
        id="fotoAvatar"
        src="https://d1t181n48swd5d.cloudfront.net/imgProject/04_RESIDENCIAL.png"
        alt="avatar4"
        onClick={handleonClick}
      />
      <img
        id="fotoAvatar"
        src="https://d1t181n48swd5d.cloudfront.net/imgProject/05_OFICINAS.png"
        alt="avatar5"
        onClick={handleonClick}
      />
      <img
        id="fotoAvatar"
        src="https://d1t181n48swd5d.cloudfront.net/imgProject/06_RETAIL.png"
        alt="avatar6"
        onClick={handleonClick}
      />
      <img
        id="fotoAvatar"
        src="https://d1t181n48swd5d.cloudfront.net/imgProject/07_EDIFICIOPUBLIC.png"
        alt="avatar6"
        onClick={handleonClick}
      />
      <img
        id="fotoAvatar"
        src="https://d1t181n48swd5d.cloudfront.net/imgProject/08_ESPACIOPUBLICO.png"
        alt="avatar6"
        onClick={handleonClick}
      />
    </div>
  )
}
IllustrationsTab.propTypes = {
  defa: PropTypes.any,
  handleonClick: PropTypes.any,
}
export default IllustrationsTab
