import React, { useContext, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useParams } from "react-router-dom"
//CONTEXT
import ProjectContext from "context/projects/projectContext"

//COMPONENTS
import { Container } from "reactstrap"
import { RecordPageViewWithoutUserId } from "App"
import BudgetStructure from "components/Budgeting2023/BugdetStructure"
import { CircularProgress } from "@material-ui/core"
import { HelveticaBold } from "common/typographies"
import i18n from "i18n"
import Budget2023Context from "context/budget2023/Budget2023Context"

const CertificacionesIndex = () => {
  RecordPageViewWithoutUserId()
  const { projectId } = useParams()
  const projectContext = useContext(ProjectContext)
  const { getProject, projectInfo } = projectContext
  const { cleanChapters, cleanParts } = useContext(Budget2023Context)
  useEffect(() => {
    cleanChapters()
    cleanParts()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper | Proyecto </title>
        </MetaTags>
        <Container fluid>
          {projectInfo?.projectId == projectId ? (
            <BudgetStructure certificaciones={true} />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  transform: "translateY(-50%, -50%)",
                  top: "40%",
                  left: "45%",
                }}
              >
                <CircularProgress
                  style={{
                    width: 150,
                    height: 150,
                    marginLeft: "0px",
                    color: "#414141",
                    marginBottom: "20px",
                  }}
                />
                <HelveticaBold
                  style={{
                    color: "#414141",
                    fontSize: "24px",
                    marginLeft: "10px",
                    marginTop: "0px",
                  }}
                >
                  {i18n.t("Cargando...")}
                </HelveticaBold>
              </div>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CertificacionesIndex
