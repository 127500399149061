import React from "react"
import PropTypes from "prop-types"
import TableStructureBank from "./tableStructure"
import Part from "./part"

const PartTable = ({ parts, imParent, total, capitulo }) => {
  return (
    <TableStructureBank
      imParent={imParent}
      total={total}
      capitulo={capitulo}
      parts={parts}
    >
      {parts.map(part => (
        <Part key={part.partidaId} part={part} />
      ))}
    </TableStructureBank>
  )
}
PartTable.propTypes = {
  parts: PropTypes.array.isRequired,
  imParent: PropTypes.bool,
  total: PropTypes.float,
  capitulo: PropTypes.object,
}
export default PartTable
