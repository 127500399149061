import i18n from "i18n"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import errorBlue from "assets/images/budgeting/errorBlue.png"
import { DownloadingOutlined } from "@mui/icons-material"
import { HelveticaBold } from "common/typographies"
import notifyToSlackChannel from "react-slack-notification"
import AuthContext from "context/auth/authContext"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "500px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const ModalErrorExcel = ({ setOpen, open, errorMessage, setOpenWaiting }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { user } = useContext(AuthContext)
  const url =
    "https://hooks.slack.com/services/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"
  notifyToSlackChannel(
    url,
    "Ha ocurrido un error en Importar excel \nEntorno: " +
      window.location.hostname +
      "\nError: " +
      errorMessage +
      " \nUsuario: " +
      user?.sub +
      " " +
      user?.email +
      " \nFecha: " +
      new Date().toLocaleString(),
  )

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <button
            type="button"
            onClick={() => {
              window.location.reload()
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-body">
            <div>
              <div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                    fontFamily: "Roboto",
                  }}
                >
                  {i18n.t(`¡Atención! Ha ocurrido un error`)}
                </p>
                {errorMessage == "INVALID FORMAT" && (
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      textAlign: "center",
                      fontFamily: "Roboto",
                    }}
                  >
                    {i18n.t(`El formato del archivo no es correcto`)}
                  </p>
                )}
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginBottom: "20px",
                  }}
                >
                  <img
                    src={errorBlue}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>

                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    marginLeft: "0px",
                    marginRight: "0px",
                    color: "#414141",
                  }}
                >
                  {i18n.t(
                    "Tu archivo será revisado por el equipo de Planhopper y te enviaremos un correo con la información de la revisión en un maximo de 48 horas",
                  )}
                </p>
                <div className="inline" style={{ justifyContent: "center" }}>
                  <a href="https://cdn.tribboo.co/Plantilla%20Presupuesto%20PlanHopper.xlsx">
                    <div className="inline" style={{ cursor: "pointer" }}>
                      <DownloadingOutlined
                        style={{ marginTop: "5px", color: "#4461D7" }}
                      />
                      <HelveticaBold
                        style={{
                          marginLeft: "10px",
                          fontSize: "12px",
                          fontWeight: "500",
                          paddingTop: "7px",
                          color: "#4461D7",
                          textDecoration: "underline",
                          textDecorationLine: "#4461D7",
                        }}
                      >
                        Modelo Proyecto - Presupuesto Simple.xlsx
                      </HelveticaBold>
                    </div>
                  </a>
                </div>

                <div
                  id=""
                  style={{
                    width: "100%",
                    marginBottom: "35px",
                    marginTop: "0px",
                  }}
                >
                  <button
                    onClick={() => {
                      setOpen(false)
                      setOpenWaiting(false)
                    }}
                    className="AddProject"
                    style={{
                      marginRight: "10%",
                      marginLeft: "30px",
                      paddingRight: "5px",
                      width: "180px",
                      position: "absolute",
                      border: "2px solid #FFD52B",
                      background: "white",
                    }}
                  >
                    {i18n.t("Cerrar")}
                  </button>
                  <a
                    href="https://calendly.com/planhopper-xvq/planhopper"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button
                      className="AddProject"
                      style={{
                        paddingRight: "5px",
                        marginLeft: "220px",
                        cursor: "pointer",
                        position: "relative",
                        bottom: "-30px",
                        width: "180px",
                        height: "50px",
                        border: "2px solid #FFD52B",
                        background: "#FFD52B",
                      }}
                    >
                      {i18n.t("Pedir una demo")}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
ModalErrorExcel.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setOpenWaiting: PropTypes.func.isRequired,
}
export default ModalErrorExcel
