import { Auth, Storage } from "aws-amplify"
import { useState } from "react"
import { isLocalhost } from "utils/functions"

export const useUtils = () => {
  const [uploading, setUploading] = useState(false)

  async function generateFileUrl(file, level) {
    try {
      setUploading(true)
      let fileNameParsed = file.name.split(" ").join("_")
      await Storage.put(fileNameParsed, file, {
        level: level ? level : "protected",
        contentType: file.type,
      })
      const currentUserCredentials = await Auth.currentUserCredentials()
      const url = isLocalhost
        ? "https://dev.storage.tribboo.co/" +
          level +
          "/" +
          currentUserCredentials.identityId +
          "/" +
          fileNameParsed
        : "https://storage.tribboo.co/" +
          level +
          "/" +
          currentUserCredentials.identityId +
          "/" +
          fileNameParsed
      setUploading(false)
      return url
    } catch (error) {
      console.log("Error uploading file: ", error)
    }
  }

  return {
    generateFileUrl,
    isLocalhost,
    uploading,
  }
}
