import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import pdfIcon from "assets/images/icons/pdf-big.png"
import excelIcon from "assets/images/icons/excel-big.png"
import bc3Icon from "assets/images/icons/bc3.png"
import logoIcon from "assets/customIcons/logos/planhopper_logo_mini.svg"

import "./StylesAndUtils/document.css"
import { HelveticaBold } from "common/typographies"
import { CheckedInput, CustomCheckedInput } from "common/inputs"

const getImage = extension => {
  switch (extension.toLowerCase()) {
    case "pdf":
      return (
        <img
          src={pdfIcon}
          style={{
            height: "70px",
            width: "55px",
            marginBottom: "5px",
            marginTop: "5px",
          }}
        />
      )
    case "xlsx":
      return (
        <img
          src={excelIcon}
          style={{
            height: "70px",
            width: "65px",
            marginBottom: "5px",
            marginTop: "5px",
          }}
        />
      )
    case "bc3":
      return (
        <img
          src={bc3Icon}
          style={{
            height: "70px",
            width: "65px",
            marginBottom: "5px",
            marginTop: "5px",
          }}
        />
      )
    default:
      return (
        <img
          src={logoIcon}
          style={{ height: "80px", width: "80px", marginBottom: "0px" }}
        />
      )
  }
}

const Document = ({ documents, selectedDocument, setSelectedDocument }) => {
  const handleFile = (i, file) => {
    if (arrayOfSelectedDocuments[i] == true) {
      setSelectedDocument(selectedDocument.filter(item => item !== file))
      setArrayOfSelectedDocuments(
        arrayOfSelectedDocuments.map((item, index) =>
          index == i ? false : item,
        ),
      )
    } else {
      setSelectedDocument([...selectedDocument, file])
      setArrayOfSelectedDocuments(
        arrayOfSelectedDocuments.map((item, index) =>
          index == i ? true : item,
        ),
      )
    }
  }

  useEffect(() => {
    setArrayOfSelectedDocuments(documents.map(file => false))
  }, [documents])

  const [arrayOfSelectedDocuments, setArrayOfSelectedDocuments] = useState(
    documents.map(file => false),
  )

  return (
    <>
      {documents.map((file, i) => {
        return (
          <div
            key={i}
            style={{
              cursor: "pointer",
              marginLeft: "10px",
              marginRight: "10px",
              margin: "0.5rem",
              paddingTop: "10PX",
              display: "flex",
              height: "134px",
              width: "134px",
              flexDirection: "row",
              backgroundColor:
                arrayOfSelectedDocuments[i] == true ? "#fdefc6" : "#F5F5F5",
            }}
            onClick={e => handleFile(i, file)}
          >
            <div
              style={{
                width: "141px",
                height: "84px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <div className="inline" style={{ textAlign: "center" }}>
                <CustomCheckedInput
                  style={{
                    marginTop: "1.5px",
                    marginLeft: "3px",
                    marginRight: "5px",
                  }}
                >
                  {arrayOfSelectedDocuments[i] == true && <CheckedInput />}
                </CustomCheckedInput>
                <HelveticaBold
                  style={{
                    fontWeight: "300",
                    fontSize: "12px",
                    color: "#414141",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "100px",
                    marginBottom: "10px",
                  }}
                >
                  {file.filename}
                </HelveticaBold>
              </div>
              <div
                style={{
                  width: "115px",
                  height: "90px",
                  textAlign: "center",
                  marginBottom: "0.5rem",
                  borderRadius: "6px",
                  border: "0.5px solid #c4c4c4",
                }}
              >
                {getImage(file.extension)}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

Document.propTypes = {
  documents: PropTypes.array,
  contacts: PropTypes.array,
  selectedDocument: PropTypes.array,
  setSelectedDocument: PropTypes.func,
}

export default Document
