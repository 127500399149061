import React, { useState } from "react"
import propTypes from "prop-types"

import {
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  Box,
  Grid,
} from "@mui/material"

import i18n from "i18n"
import { HelveticaBold, PoppinsSemiBold } from "common/typographies"

const FirstStep = ({
  options,
  setOptions,
  handleChangeTipo,
  handleOculta,
  tipo,
  ocultaM,
  ocultaP,
  selectedRoles,
  setSelectedRoles,
  soyFinal,
  groupName,
  setGroupName,
}) => {
  const [profiles, setProfiles] = useState([
    soyFinal
      ? { name: "Cliente", value: false }
      : { name: "Proveedor", value: false },
    { name: "Otro", value: false },
  ])
  const changeCheckbox = (e, newTipo) => {
    if (newTipo !== null) {
      setSelectedRoles(newTipo)
    }
  }

  const handleManageOptions = (e, option) => {
    setOptions({ ...options, [option]: !options[option] })
  }

  return (
    <>
      <div>
        <HelveticaBold
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#414141",
            marginTop: "20px",
          }}
        >
          {i18n.t("A quien deseas enviar el presupuesto")}:
        </HelveticaBold>
        <div style={{ marginTop: "20px" }}>
          <Box
            display={"flex"}
            justifyContent="left"
            sx={{ marginBottom: "0rem" }}
          >
            <ToggleButtonGroup
              value={selectedRoles}
              onChange={changeCheckbox}
              exclusive
              sx={{
                "& .MuiToggleButtonGroup-grouped": {
                  marginX: "0px",
                  borderRadius: "50px",
                  border: "1px solid #FFD52B",
                  paddingX: "0rem",
                  lineHeight: "15px",
                  height: "30px",
                  fontWeight: "300",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontFamily: "Helvetica",
                  textTransform: "none",
                  width: "90px",
                  color: "#414141",
                  marginRight: "25px",
                  marginBottom: "20px",
                },

                "& .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                  {
                    backgroundColor: "#FFD52B",
                    opacity: "100%",
                  },
              }}
            >
              <ToggleButton
                id="PresSend"
                value="provider"
                style={{ opacity: selectedRoles == "client" && "0.5" }}
              >
                Proveedor
              </ToggleButton>
              <ToggleButton
                value="client"
                style={{ opacity: selectedRoles == "provider" && "0.5" }}
              >
                Cliente
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </div>
        {
          // !soyFinal && selectedRoles.provider &&
          // <>
          // <div style={{display:'flex', padding:'10px', paddingLeft:'0px', marginBottom:'-10px'}}>
          //     <PoppinsSemiBold style={{fontSize:'14px'}} >
          //         {i18n.t('Nombre del grupo')}
          //     </PoppinsSemiBold>
          //     <Tooltip placement="right-end" title={i18n.t("Introduciendo un nombre para esta seleccion de presupuesto, podras encontrarla facilmente para reenviarla en la seccion de proveedores")}>
          //             <IconButton style={{marginTop:'-1px',  width:'22px', height:'22px', marginLeft:'4px' }}>
          //                 <InfoOutlined style={{color:'blue', fontSize:'18px'}}/>
          //             </IconButton>
          //         </Tooltip>
          // </div>
          // <HelveticaInput  style={{marginTop:'-20px', width:'100%', paddingLeft:'10px', minWidth:'250px'}}
          //    placeholder={i18n.t('Introduzca un nombre para el grupo')}
          //     value={groupName}
          //     onChange={(e)=>{setGroupName(e.target.value)}}
          // />
          // </>
        }
      </div>

      {soyFinal ? null : (
        <>
          <Grid item style={{ marginTop: "0px" }}>
            <Typography
              variant="body1"
              align="left"
              style={{
                fontFamily: "Helvetica",
                fontWeight: "500",
                fontSize: "14px",
                color: "#414141",
                marginBottom: "1rem",
              }}
            >
              {i18n.t("Formato de envio del presupuesto")}:
            </Typography>
          </Grid>

          <Box
            display={"flex"}
            justifyContent="left"
            sx={{ marginBottom: "0rem" }}
          >
            <ToggleButtonGroup
              value={tipo}
              onChange={handleChangeTipo}
              exclusive={selectedRoles == "client" ? true : false}
              sx={{
                "& .MuiToggleButtonGroup-grouped": {
                  marginX: "0px",
                  borderRadius: "50px",
                  border: "1px solid #FFD52B",
                  paddingX: "0rem",
                  lineHeight: "15px",
                  height: "30px",
                  fontWeight: "300",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontFamily: "Helvetica",
                  textTransform: "none",
                  width: "90px",
                  color: "#414141",
                  marginRight: "25px",
                  marginBottom: "20px",
                },
                "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                  borderRadius: "50px",
                  border: "1px solid #FFD52B",
                },
                "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                  borderRadius: "50px",
                  border: "1px solid #FFD52B",
                },
                "& .css-ueukts-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                  {
                    backgroundColor: "#FFD52B",
                    opacity: "100%",
                  },
              }}
            >
              <ToggleButton
                disabled={selectedRoles == "client" ? true : false}
                id="PresSend"
                value="excel"
                style={{
                  opacity: tipo[0] == "PDF" && tipo.length == 1 && "0.5",
                }}
              >
                Excel
              </ToggleButton>
              <ToggleButton
                value="PDF"
                style={{
                  opacity: tipo[0] == "excel" && tipo.length == 1 && "0.5",
                }}
              >
                PDF
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </>
      )}
      <Grid item style={{ marginTop: soyFinal && "30px" }}>
        <Typography
          variant="body1"
          align="left"
          style={{
            fontFamily: "Helvetica",
            fontWeight: "500",
            fontSize: "14px",
            color: "#414141",
            marginBottom: "1rem",
          }}
        >
          {i18n.t("Selección la información que quieres enviar")}:
        </Typography>

        {Object.keys(options).map(function (key, index) {
          return (
            <div
              key={index}
              style={{ display: "flex", position: "relative", width: "200px" }}
            >
              <div style={{ marginTop: "2px", marginLeft: "-10px" }}>
                <Switch
                  className="aaa"
                  disabled={
                    (key == "cost" && options["client"]) ||
                    (key == "client" && selectedRoles == "provider")
                  }
                  checked={options[key]}
                  onChange={e => {
                    handleManageOptions(e, key)
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                  color="warning"
                />
              </div>
              <div
                id="Poppinsnormal"
                style={{ height: "30px", fontWeight: "400" }}
              >
                {i18n.t(
                  key == "cost"
                    ? "Precio Objetivo"
                    : key == "description"
                      ? "Descripción larga"
                      : key == "showCover"
                        ? "Diseño portada"
                        : "Precio Cliente",
                )}
              </div>
            </div>
          )
        })}
      </Grid>
    </>
  )
}

FirstStep.propTypes = {
  selectedRoles: propTypes.object.isRequired,

  setSelectedRoles: propTypes.func.isRequired,
  soyFinal: propTypes.bool.isRequired,
  groupName: propTypes.string.isRequired,
  setGroupName: propTypes.func.isRequired,
  handleChangeTipo: propTypes.func.isRequired,
  handleOculta: propTypes.func.isRequired,
  tipo: propTypes.string.isRequired,
  ocultaP: propTypes.bool.isRequired,
  ocultaM: propTypes.bool.isRequired,
  options: propTypes.object.isRequired,
  setOptions: propTypes.func.isRequired,
}

export default FirstStep
