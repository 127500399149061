import React, { useContext } from "react"
import PropTypes from "prop-types"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Cancel"
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material"
import { useFormik } from "formik"
import * as Yup from "yup"
import i18n from "i18n"
import { DynamicElementPropType } from "common/CDynamicElement"
import DynamicElementsContext from "context/dynamicElements/DynamicElementsContext"
import { itemTypesWithIcons, UnityEnum } from "constants/unity.js"
import ActionIcon from "./ActionIcon"
import { useSnackbar } from "notistack"

const DynamicElementForm = ({ element, poolId, fnCallback }) => {
  const { dispatches } = useContext(DynamicElementsContext)
  const { enqueueSnackbar } = useSnackbar()

  const validationSchema = Yup.object({
    type: Yup.number().required(i18n.t("validation.required")),
    description: Yup.string().required(i18n.t("validation.required")),
    unity: Yup.string().required(i18n.t("validation.required")),
    price: Yup.number()
      .required(i18n.t("validation.required"))
      .min(0, i18n.t("validation.min")),
  })

  const formik = useFormik({
    initialValues: {
      code: element ? element.code : "",
      type: element ? element.type : "",
      description: element ? element.description : "",
      unity: element?.unity ? UnityEnum[element.unity] : UnityEnum["pa"],
      price: element ? element.price : "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const { code, type, description, unity, price } = values
      let isSuccess = false
      if (!element) {
        isSuccess = await dispatches.addElement(
          { code, type, description, unity, price },
          poolId,
        )
      } else {
        isSuccess = await dispatches.editElement(
          {
            itemId: element.itemId,
            code,
            type,
            description,
            unity,
            price,
          },
          poolId,
        )
      }

      if (isSuccess) {
        enqueueSnackbar(i18n.t("snackbar.successfullySaved"), {
          variant: "success",
        })
        resetForm()
      } else {
        enqueueSnackbar(i18n.t("snackbar.unSuccessfullySaved"), {
          variant: "error",
        })
      }

      if (fnCallback) fnCallback()
    },
  })

  const handleCancel = () => {
    formik.resetForm()
    if (fnCallback) fnCallback()
  }

  const renderValue = selected => {
    const selectedItem = itemTypesWithIcons.find(item => item.id === selected)
    return selectedItem ? selectedItem.icon : null
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            id="code"
            name="code"
            label={i18n.t("dynamicElement.columns.code")}
            value={formik.values.code}
            onChange={formik.handleChange}
            error={formik.touched.code && Boolean(formik.errors.code)}
            helperText={formik.touched.code && formik.errors.code}
            fullWidth
            variant="standard"
            placeholder={i18n.t("dynamicElement.columns.code")}
            size="small"
            sx={{ maxWidth: 70 }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <FormControl
            variant="standard"
            fullWidth
            error={formik.touched.type && Boolean(formik.errors.type)}
          >
            <InputLabel id="type-label">
              {i18n.t("dynamicElement.columns.type")}
            </InputLabel>
            <Select
              labelId="type-label"
              id="type"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              renderValue={renderValue}
              label={i18n.t("dynamicElement.columns.type")}
              size="small"
            >
              {itemTypesWithIcons.map(itemType => (
                <MenuItem key={itemType.id} value={itemType.id}>
                  {itemType.icon}
                  {itemType.type}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.type && Boolean(formik.errors.type) && (
              <div style={{ color: "red", fontSize: "0.75rem" }}>
                {formik.errors.type}
              </div>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            id="description"
            name="description"
            multiline
            size="small"
            rows={1}
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            fullWidth
            variant="standard"
            placeholder={i18n.t("dynamicElement.columns.description")}
            label={i18n.t("dynamicElement.columns.description")}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <FormControl
            variant="standard"
            fullWidth
            error={formik.touched.unity && Boolean(formik.errors.unity)}
          >
            <InputLabel id="unity-label">
              {i18n.t("dynamicElement.columns.unity")}
            </InputLabel>
            <Select
              labelId="unity-label"
              id="unity"
              name="unity"
              value={formik.values.unity}
              onChange={formik.handleChange}
              label={i18n.t("dynamicElement.columns.unity")}
              size="small"
            >
              {Object.keys(UnityEnum).map((item, index) => (
                <MenuItem key={`unity_${index}`} value={UnityEnum[item]}>
                  {UnityEnum[item]}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.unity && Boolean(formik.errors.unity) && (
              <div style={{ color: "red", fontSize: "0.75rem" }}>
                {formik.errors.unity}
              </div>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <TextField
            id="price"
            name="price"
            type="number"
            label={i18n.t("dynamicElement.columns.price")}
            value={formik.values.price}
            onChange={formik.handleChange}
            error={formik.touched.price && Boolean(formik.errors.price)}
            helperText={formik.touched.price && formik.errors.price}
            fullWidth
            variant="standard"
            placeholder={i18n.t("dynamicElement.columns.price")}
            size="small"
            sx={{ maxWidth: 70 }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1}
        >
          <ActionIcon
            success={false}
            loading={false}
            handleSubmit={formik.handleSubmit}
            ActionComponent={SaveIcon}
            iconSize="2rem !important"
            tooltipTitle={i18n.t("buttons.save")}
          />
          <Tooltip title="Cancel" placement="top">
            <IconButton color="secondary" onClick={handleCancel}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  )
}

DynamicElementForm.propTypes = {
  element: DynamicElementPropType,
  poolId: PropTypes.string.isRequired,
  fnCallback: PropTypes.func,
}

export default DynamicElementForm
