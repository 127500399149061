import React, { useState } from "react"
import { Storage } from "aws-amplify"
import { Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Tabs, Tab } from "@mui/material"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import defa from "../../assets/images/icons/00_DEFAULT.png"
import i18n from "i18n"
import IllustrationsTab from "./IlustrationsTab"
import FromComputerTab from "./FromComputerTab"
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}
const TabContainer = props => {
  const { children, value, index } = props
  return <div hidden={value !== index}>{value === index && children}</div>
}
TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "500px",
    height: "775px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalImageProject = ({
  openImage,
  setOpenImage,
  image,
  setImage,
  updateProject,
  campos,
  tagsFinal,
  selectedContact,
  first,
  refBoton,
  imInContacts,
}) => {
  // Configuración del modal para añadir contactos
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles({ defa })

  const onClose = () => {
    setOpenImage(false)
  }
  const [tabValue, setTabValue] = useState(0)
  const [selectedFiles, setselectedFiles] = useState([])
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }
  const handleonClick = e => {
    let varImage = e.currentTarget.src
    if (varImage.charAt(0) == "o") {
      setImage(varImage.slice(1))
    } else {
      setImage(e.currentTarget.src)
    }
    if (!imInContacts && first != true) {
      campos.imagen = e.currentTarget.src
      campos.tags = tagsFinal
      if (selectedContact == null) {
        campos.client = " "
      } else {
        campos.client = selectedContact.id
      }
    }
  }
  async function saveImage() {
    if (imInContacts) {
      setOpenImage(false)
    } else {
      if (first != true) {
        updateProject(campos)
      }
      if (refBoton) {
        refBoton.current["className"] = "AddContact"
      }
      setOpenImage(false)
    }
  }

  return (
    <>
      <Modal open={openImage} onClose={onClose}>
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon id="cerrar" onClick={onClose}></CloseIcon>
          <div id="divFotoAvatar">
            <img id="fotoAvatarPrin" src={image} alt={defa} />
          </div>
          {!imInContacts ? (
            <>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                sx={{ width: "100%", marginY: "1rem", marginX: "2.5rem" }}
              >
                <Tab label="Ilustraciones" />
                <Tab label="Desde tu ordenador" />
              </Tabs>
              <TabContainer value={tabValue} index={0}>
                <IllustrationsTab handleonClick={handleonClick} defa={defa} />
              </TabContainer>
              <TabContainer value={tabValue} index={1}>
                <FromComputerTab setImage={setImage} />
              </TabContainer>
            </>
          ) : (
            <IllustrationsTab handleonClick={handleonClick} defa={defa} />
          )}
          <div id="centerButton">
            <button type="button" onClick={saveImage} className="SaveFoto">
              {i18n.t("Guardar")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

ModalImageProject.propTypes = {
  openImage: PropTypes.bool.isRequired,
  setOpenImage: PropTypes.func.isRequired,
  image: PropTypes.string,
  setImage: PropTypes.func,
  updateProject: PropTypes.func,
  campos: PropTypes.object,
  tagsFinal: PropTypes.array,
  selectedContact: PropTypes.object,
  first: PropTypes.bool,
  refBoton: PropTypes.object,
  imInContacts: PropTypes.bool,
}
export default withRouter(ModalImageProject)
