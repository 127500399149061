import { Auth } from "aws-amplify"
import { useState } from "react"
import { useHistory } from "react-router-dom"

export const usePassword = () => {
  const [mailError, setMailError] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [code, setCode] = useState("")
  const [codeMessage, setCodeMessage] = useState("")
  const [codeError, setCodeError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [passwordMessage, setPasswordMessage] = useState("")
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1)

  const handleConfirmEmail = async e => {
    e.preventDefault()
    setLoading(true)
    if (email === "") {
      setMailError(true)
      setErrorMessage("Por favor, introduce un correo electrónico")
      setLoading(false)
      return
    } else {
      setMailError(false)
      setErrorMessage("")
    }
    if (!email.includes("@") || !email.includes(".")) {
      setMailError(true)
      setErrorMessage("Por favor, introduce un correo electrónico válido")
      setLoading(false)
      return
    } else {
      setMailError(false)
      setErrorMessage("")
    }
    await Auth.forgotPassword(email)
      .then(data => console.log(" ", data))
      .catch(err => console.log(" ", err))

    setStep(2)
    setLoading(false)
  }

  const handleChange = e => {
    if (e.target.name === "password") {
      setPassword(e.target.value)
    } else if (e.target.name === "code") {
      setCode(e.target.value)
    } else {
      setEmail(e.target.value)
    }
  }

  const handleReset = async e => {
    e.preventDefault()
    setLoading(true)
    if (code === "") {
      setCodeError(true)
      setCodeMessage("Por favor, introduce el código de verificación")
      setLoading(false)
      return
    } else {
      setCodeError(false)
      setCodeMessage("")
    }
    if (password === "") {
      setPasswordError(true)
      setPasswordMessage("Por favor, introduce una contraseña")
      setLoading(false)
      return
    } else {
      setPasswordError(false)
      setPasswordMessage("")
    }
    if (password.length < 8) {
      setPasswordError(true)
      setPasswordMessage("La contraseña debe tener al menos 8 caracteres")
      setLoading(false)
      return
    }
    if (!password.match(/\d+/g)) {
      setPasswordError(true)
      setPasswordMessage("La contraseña debe tener al menos un número")
      setLoading(false)
      return
    }
    if (!password.match(/[a-z]/g)) {
      setPasswordError(true)
      setPasswordMessage(
        "La contraseña debe tener al menos una letra minúscula",
      )
      setLoading(false)
      return
    }

    await Auth.forgotPasswordSubmit(email, code, password)
      .then(data => {
        setLoading(false)
        setStep(3)
      })
      .catch(err => {
        if (
          err.message ===
          "Invalid verification code provided, please try again."
        ) {
          setLoading(false)
          setCodeError(true)
          setCodeMessage("El codigo introducido no es válido")
        }
        if (
          err.message === "Attempt limit exceeded, please try after some time."
        ) {
          setLoading(false)
          setCodeError(true)
          setCodeMessage(
            "Ha excedido el numero de intentos, por favor, inténtelo más tarde",
          )
        }
      })
  }

  return {
    history,
    errorMessage,
    mailError,
    passwordError,
    passwordMessage,
    loading,
    email,
    password,
    code,
    handleChange,
    handleConfirmEmail,
    step,
    codeError,
    codeMessage,
    handleReset,
  }
}
