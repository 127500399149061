import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import circlePlus from "assets/images/icons/circlePlus.svg"
import ModalSelectContact from "components/Facturas/modals/modalSelectContact"
import { HelveticaBold } from "common/typographies"
import AuthContext from "context/auth/authContext"
import i18n from "i18n"
import actsContext from "context/acts/actsContext"
import logoImage from "assets/images/icons/jpg.png"
import ModalVerFirma from "../modals/ModalVerFirma"
import { DeleteOutline } from "@mui/icons-material"

const AsistentesComponent = ({
  actaValues,
  setActaValues,
  index,
  actasRefs,
}) => {
  const { user } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const [selectedContact, setContactSelected] = useState(
    actaValues.attendees[index].contact
      ? actaValues.attendees[index].contact
      : {},
  )
  const { selectedActa } = useContext(actsContext)

  useEffect(() => {
    const newActaValues = { ...actaValues }
    newActaValues.attendees[index] = selectedContact
    actasRefs.attendeesRefs[index].name.current.style = "border: 0px"
    actasRefs.attendeesRefs[index].name.current.style =
      "borderLeft:1px solid #c4c4c4"
    setActaValues(newActaValues)
  }, [selectedContact])

  useEffect(() => {
    if (actaValues.attendees[index].contact) {
      setContactSelected(actaValues.attendees[index].contact)
    }
  }, [actaValues.attendees[index]])

  const [imSigned, setImSigned] = useState(false)
  const [signature, setSignature] = useState("")
  useEffect(() => {
    if (selectedActa) {
      if (selectedActa.attendees[index]?.hasSigned) {
        setImSigned(true)
        setSignature(selectedActa.attendees[index].signature)
      }
    }
  }, [selectedActa])

  const [openFirma, setOpenFirma] = useState(false)

  return (
    <>
      <tr className="hoverRow">
        <td>
          {Object.keys(selectedContact).length == 0 ? (
            ""
          ) : (
            <div
              id="color1"
              className="tag"
              style={{
                width: "fit-content",
                paddingRight: "8px",
                fontSize: "12px",
                textTransform: "uppercase",
                marginTop: "5.5px",
                marginBottom: "5.5px",
                marginLeft: "5px",
                marginRight: "0px",
              }}
            >
              {selectedContact.role.length > 0
                ? selectedContact.role[0]
                : "Sin rol asignado"}
            </div>
          )}
        </td>
        <td
          style={{ cursor: "pointer" }}
          onClick={() => setOpen(true)}
          ref={actasRefs.attendeesRefs[index].name}
        >
          {Object.keys(selectedContact).length > 0 ? (
            <div className="inline" style={{ cursor: "pointer" }}>
              <img
                src={
                  user
                    ? user["custom:avatar"]
                      ? user["custom:avatar"]
                      : ""
                    : ""
                }
                width={35}
                height={35}
              />
              <HelveticaBold
                style={{
                  fontWeight: "300",
                  color: "#414141",
                  fontSize: "14px",
                  marginLeft: "5px",
                  marginTop: "7px",
                  marginBottom: "0px",
                }}
              >
                {selectedContact.completeName}
              </HelveticaBold>
            </div>
          ) : (
            <div className="inline" style={{ cursor: "pointer" }}>
              <img src={circlePlus} width={20} />
              <HelveticaBold
                style={{
                  fontWeight: "300",
                  fontSize: "14px",
                  marginLeft: "5px",
                  lineHeight: "10px",
                  marginTop: "4px",
                  marginBottom: "0px",
                }}
              >
                {i18n.t("Buscar contactos")}
              </HelveticaBold>
            </div>
          )}
        </td>
        <td style={{ position: "relative" }}>
          {imSigned ? (
            <div
              className="inline"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenFirma(true)
              }}
            >
              <img src={logoImage} width={25} height={30} />
              <HelveticaBold
                style={{
                  fontWeight: "300",
                  color: "#414141",
                  fontSize: "14px",
                  marginLeft: "5px",
                  marginTop: "5px",
                  marginBottom: "0px",
                }}
              >
                {"firma.png"}
              </HelveticaBold>
            </div>
          ) : null}
          {actaValues?.attendees.length != 1 && (
            <DeleteOutline
              className="hoverIcon"
              style={{
                cursor: "pointer",
                display: "none",
                position: "absolute",
                fontSize: "20px",
                right: "10px",
                top: "8px",
              }}
              onClick={() => {
                const newActaValues = { ...actaValues }
                newActaValues.attendees.splice(index, 1)
                setActaValues(newActaValues)
              }}
            />
          )}
        </td>
      </tr>
      {open && (
        <ModalSelectContact
          open={open}
          setOpen={setOpen}
          setContactSelected={setContactSelected}
        />
      )}
      {openFirma && (
        <ModalVerFirma
          open={openFirma}
          setOpen={setOpenFirma}
          signature={signature}
        />
      )}
    </>
  )
}

AsistentesComponent.propTypes = {
  actaValues: PropTypes.object,
  setActaValues: PropTypes.func,
  index: PropTypes.number,
  actasRefs: PropTypes.array,
}

export default AsistentesComponent
