import React, { useState, useContext, useEffect, useRef } from "react"
import propTypes from "prop-types"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import {
  CapituloContainer,
  SubChapterContainer,
  ShowChildDiv,
  ShowChildDivWithBorder,
} from "common/containers"
import { PoppinsNormal } from "common/typographies"
import { currencyFormatDE } from "lib/transformers"
import i18n from "i18n"
import ContactContext from "context/contact/contactContext"
import PartTable from "components/Templates/parts/index"
import AuthContext from "context/auth/authContext"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import ModalDeleteCap from "../modals/modalDeleteCap"
import { useParams } from "react-router-dom"
import TemplatesContext from "context/templates/templatesContext"
import SendModal from "components/Feed/sendBudgetRefatctor/sendModal"
import ModalSearchParts from "components/PartSearcherTemplates/default"
import Budget2023Context from "context/budget2023/Budget2023Context"
import { CircularProgress } from "@material-ui/core"
import { getCurrency } from "utils/functions"

const Capitulo = props => {
  const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
  const { addToSelfManagement } = useContext(Budget2023Context)
  const [subCapsLoaded, setSubCapsLoaded] = useState(false)
  const {
    getSubCaps,
    loadingCaps,
    myPricesBank,
    addBankChapter,
    updateChapterBank,
    templateInfo,
    checkCapitulos,
    updateChaptersList,
    checkMultipleChapters,
    selectedCategories,
    getSelectedChapters,
    selectedCategories2,
    categoriesChecked,
  } = useContext(TemplatesContext)
  const { user, freeTrialExpired } = useContext(AuthContext)
  const [openEliminate, setOpenEliminate] = useState(false)
  const referenciatag = useRef(null)
  const [input, setInput] = useState("")
  const referenciaextra = useRef(null)
  const [moreTags, setMoreTags] = useState([])
  const refExtraTag = useRef(null)
  const { templateId } = useParams()
  const [loadingContent, setLoadingContent] = useState(false)
  const handleOpen = async () => {
    setOpenNotSendCapitulo(prev => !prev)
    if (subCapsLoaded === false) {
      setLoadingContent(true)
      await getSubCaps(props.capitulo.chapterId, templateId)
      setLoadingContent(false)
      setSubCapsLoaded(true)
    }
  }
  const deleteTag = (e, index) => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    e.preventDefault()
    setTags(prevState => prevState.filter((tag, i) => i !== index))
    let copiaTags = tags
    copiaTags.splice(index)
    let copia = { ...props.capitulo }
    copia.capituloInfo.tags = copiaTags
    const data = {
      name: name,
      description: "",
      chapterTemplateId: props.capitulo.capituloInfo.chapterTemplateId,
      tags: copiaTags,
      accountId: user.sub,
      code: null,
    }
    updateChapterBank(data)
    //updateCapitulov2(copia, false)
  }
  const getPixels = word => {
    let sumPixels = 0
    if (word == null) {
      sumPixels = 450
    } else {
      for (let i = 0; i < word.length; i++) {
        if (word.charAt(i) == word.charAt(i).toUpperCase()) {
          sumPixels += 13
        } else {
          sumPixels += 10
        }
      }
    }
    if (sumPixels > 650) {
      return sumPixels * 1.55
    } else if (sumPixels > 500) {
      return sumPixels * 1.4
    } else if (sumPixels > 300) {
      return sumPixels * 1.3
    } else {
      return sumPixels
    }
  }
  const [openSendBudget, setOpenSendBudget] = useState(false)
  const contactContext = useContext(ContactContext)
  const { Contacts, getContacts } = contactContext
  const [openPart, setOpenPart] = useState(false)
  const addSubChapter = () => {
    const data = {
      name: null,
      description: null,
      parentId: props.capitulo.capituloInfo.chapterTemplateId,
      price: null,
      tags: null,
      accountId: user.sub,
      code: null,
      root: "false",
    }
    addBankChapter(
      data.parentId,
      data.projectId,
      props.indice,
      data.accountId,
      data.root,
      templateId,
    )
  }

  const addSubChapter2 = () => {
    const data = {
      name: null,
      description: null,
      parentId: props.capitulo.capituloInfo.parentId,
      price: null,
      tags: null,
      accountId: user.sub,
      code: null,
      root: "false",
    }
    addBankChapter(
      data.parentId,
      data.projectId,
      props.indice,
      data.accountId,
      data.root,
      templateId,
    )
  }

  const handleUpdateChapter = async () => {
    const data = {
      name: name,
      description: props.capitulo.capituloInfo.description,
      chapterTemplateId: props.capitulo.capituloInfo.chapterTemplateId,
      tags: [],
      accountId: user.sub,
      code: null,
      templateId: templateId,
      quantity: props.capitulo.capituloInfo.quantity,
      price: props.capitulo.capituloInfo.price,
      parentId: props.capitulo.capituloInfo.parentId,
    }
    updateChapterBank(data)
  }

  const [name, setName] = useState(
    props.capitulo.capituloInfo.name !== null
      ? props.capitulo.capituloInfo.name
      : "",
  )
  useEffect(() => {
    setName(props.capitulo.capituloInfo.name)
  }, [props.capitulo])

  const handleChangeName = e => {
    setName(e.target.value)
  }
  const changeCheck = async e => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    if (e.nativeEvent.shiftKey) {
      if (props.capitulo.checked === true) {
        await checkCapitulos(props.capitulo.chapterId, false)
        await updateChaptersList(props.capitulo.capituloInfo)
      } else {
        await checkMultipleChapters(props.capitulo.chapterId)
      }
    } else {
      if (props.capitulo.checked === true) {
        await checkCapitulos(props.capitulo.chapterId, false)
        await updateChaptersList(props.capitulo.capituloInfo)
      } else {
        await checkCapitulos(props.capitulo.chapterId, true)
        await updateChaptersList(props.capitulo.capituloInfo)
      }
    }
  }

  const [tags, setTags] = useState([])
  const [color, setColor] = useState(0)
  const listaColores = [
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
    "color7",
    "color8",
  ]
  const onKeyDown = e => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    const { key } = e
    const trimmedInput = input.trim()
    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
      setInput("")
      let copiaTag = props.capitulo.capituloInfo.tags
      if (copiaTag === null) {
        copiaTag = []
      }
      copiaTag.push([trimmedInput, listaColores[color]])
      let copia = { ...props.capitulo }
      copia.capituloInfo.tags = copiaTag
      referenciatag.current.id = "NOaddtagbudget"
      const data = {
        name: name,
        tags: copiaTag,
        accountId: user.sub,
        description: props.capitulo.capituloInfo.description,
        chapterTemplateId: props.capitulo.capituloInfo.chapterTemplateId,
        templateId: templateId,
        quantity: props.capitulo.capituloInfo.quantity,
        price: props.capitulo.capituloInfo.price,
        parentId: props.capitulo.capituloInfo.parentId,
      }

      updateChapterBank(data)
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }
  }
  useEffect(() => {
    if (props.capitulo.capituloInfo.tags !== null) {
      setTags(props.capitulo.capituloInfo.tags)
    } else {
      setTags([])
    }
  }, [])
  useEffect(() => {
    let nuevo = []
    if (tags.length > 2) {
      for (let i = 2; i < tags.length; i++) {
        nuevo.push(tags[i])
      }
    } else {
      if (refExtraTag.current) {
        refExtraTag.current.className = "NOseeMoretags"
      }
    }
    setMoreTags(nuevo)
  }, [tags])
  const onChange = e => {
    const { value } = e.target
    setInput(value)
  }

  const createPart = () => {
    setOpenPart(true)
  }

  return (
    <div
      style={{
        marginBottom:
          props.capitulo.capituloInfo.name == "PARTIDAS SIN CLASIFICAR"
            ? "40px"
            : "0px",
      }}
    >
      {props.capitulo.capituloInfo.name == "PARTIDAS SIN CLASIFICAR" ? (
        <PoppinsNormal>{i18n.t("Partidas sin clasificar")}:</PoppinsNormal>
      ) : null}
      {props.capitulo.parentId == "root" ||
      props.capitulo.capituloInfo.parentId === "root" ? (
        <div>
          <CapituloContainer
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              handleOpen()
            }}
            className="hoverCapitulo"
            style={{ background: "white" }}
          >
            <div
              className="hoverYellow"
              style={{
                height: "44px",
                background: !openNotSendCapitulo ? "white" : "#FCD56B",
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                borderRadius: "6px",
                paddingTop: "12px",
              }}
            >
              {loadingContent ? (
                <CircularProgress
                  style={{
                    width: "18px",
                    height: "20px",
                    color: "#414141",
                    marginLeft: "3px",
                    marginRight: "4px",
                    marginTop: "-1.5px",
                    display: "block",
                  }}
                />
              ) : openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
                />
              )}
              <div className={props.capitulo.checked ? " " : "detallesCap"}>
                <div className="tdcheckColumn">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={props.capitulo.checked}
                    id="flexCheckDefault"
                    onClick={e => {
                      if (!e) var e = window.event
                      e.cancelBubble = true
                      if (e.stopPropagation) e.stopPropagation()
                    }}
                    onChange={changeCheck}
                    style={{
                      width: "13px",
                      height: "13px",
                      cursor: "pointer",
                      zIndex: "5",
                    }}
                  ></input>
                </div>
              </div>
              <input
                onChange={handleChangeName}
                onClick={e => {
                  if (!e) var e = window.event
                  e.cancelBubble = true
                  if (e.stopPropagation) e.stopPropagation()
                }}
                onBlur={handleUpdateChapter}
                className="NombreAlign"
                style={{
                  marginTop: "-10px",
                  fontWeight: "700",
                  width: getPixels(name) + "px",
                  minWidth: name?.length == 0 ? "240px" : "120px",
                }}
                name={"name"}
                value={name}
                placeholder={i18n.t("Introduce el nombre de la categoria")}
                autoComplete="off"
                disabled={
                  templateInfo.accountId == "tribboo"
                    ? true
                    : props.capitulo.capituloInfo.name ==
                        "PARTIDAS SIN CLASIFICAR"
                      ? true
                      : user
                        ? freeTrialExpired || user["custom:role"] === "WORKER"
                          ? true
                          : false
                        : false
                }
              ></input>

              {templateInfo.accountId == "tribboo" ? (
                props.capitulo.capituloInfo.price ? (
                  <div
                    id="precioTotal"
                    style={{
                      marginTop: "-4px",
                      marginRight: "15px",
                      width: "160px",
                      background: "white",
                      position: "absolute",
                      right: "10px",
                    }}
                  >
                    {currencyFormatDE(
                      props.capitulo.capituloInfo.price,
                      getCurrency(user["custom:currency"]),
                    )}
                  </div>
                ) : (
                  <div
                    id="precioTotal"
                    style={{
                      marginTop: "-4px",
                      marginRight: "15px",
                      width: "160px",
                      background: "white",
                      position: "absolute",
                      right: "10px",
                    }}
                  >
                    {"0,00 " + getCurrency(user["custom:currency"])}
                  </div>
                )
              ) : null}
            </div>
          </CapituloContainer>
          {openNotSendCapitulo ? (
            <ShowChildDiv
              style={{
                width: "99%",
                marginTop: props.capitulo.partidas?.length ? "-8px" : "-14px",
                background: "#FDEFC6",
              }}
            >
              {props.capitulo.partidas?.length ? (
                <PartTable
                  parts={props.capitulo.partidas}
                  total={props.capitulo.total}
                  capitulo={props.capitulo}
                />
              ) : props.capitulo.subcapitulos?.length ? (
                props.capitulo.subcapitulos.map((capitulo, i) => (
                  <Capitulo
                    key={capitulo.chapterId}
                    i={i}
                    capitulo={capitulo}
                    imParent={(props.capitulo.parentId = "root" ? true : false)}
                    imLast={
                      props.capitulo.subcapitulos.length - 1 === i
                        ? true
                        : false
                    }
                    indice={i}
                  />
                ))
              ) : user ? (
                freeTrialExpired || user["custom:role"] === "WORKER" ? null : (
                  <div style={{ display: "grid" }}>
                    {templateInfo.accountId == "tribboo" ? null : (
                      <div className="NombreAlign">
                        <button
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="AddNewCategoryTemplates"
                          data-amplify-analytics-attrs=""
                          onClick={() => {
                            addSubChapter()
                          }}
                          className="nuevoBotonCrear4"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          + {i18n.t("Nueva Subcategoría")}
                        </button>
                      </div>
                    )}
                    {templateInfo.accountId == "tribboo" ? null : (
                      <div className="NombreAlign">
                        <button
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="AddNewPartTemplates"
                          data-amplify-analytics-attrs=""
                          onClick={() => {
                            createPart()
                          }}
                          className="nuevoBotonCrear4"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          + {i18n.t("Buscar o crear nueva partida")}
                        </button>
                      </div>
                    )}
                  </div>
                )
              ) : null}
            </ShowChildDiv>
          ) : null}
        </div>
      ) : (
        //im a subchapter  <SubChapterContainer> --> 'className='NewSubCapi' in budgeting.css
        <div>
          <SubChapterContainer
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              handleOpen()
            }}
            className="hoverCapitulo"
            style={{
              borderLeft: props.imParent ? "6px solid #F1CA2C" : "0px",
              background: "#FCD56B",
            }}
          >
            <div
              className="hoverYellow"
              style={{
                height: "44px",
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                paddingTop: "11px",
                background: "#FCD56B",
                borderRadius: "6px",
              }}
            >
              {loadingContent ? (
                <CircularProgress
                  style={{
                    width: "18px",
                    height: "20px",
                    color: "#414141",
                    marginLeft: "3px",
                    marginRight: "4px",
                    marginTop: "-1.5px",
                    display: "block",
                  }}
                />
              ) : openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
                />
              )}
              <div className={props.capitulo.checked ? " " : "detallesCap"}>
                <div className="tdcheckColumn" style={{ height: "0px" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={props.capitulo.checked}
                    id="flexCheckDefault"
                    onClick={e => {
                      if (!e) var e = window.event
                      e.cancelBubble = true
                      if (e.stopPropagation) e.stopPropagation()
                    }}
                    onChange={changeCheck}
                    style={{
                      width: "13px",
                      height: "13px",
                      cursor: "pointer",
                      zIndex: "5",
                    }}
                  ></input>
                </div>
              </div>
              <input
                onChange={handleChangeName}
                onClick={e => {
                  if (!e) var e = window.event
                  e.cancelBubble = true
                  if (e.stopPropagation) e.stopPropagation()
                }}
                onBlur={handleUpdateChapter}
                className="NombreAlign"
                style={{
                  marginTop: "-10px",
                  fontWeight: "700",
                  width: getPixels(name) + "px",
                  minWidth: name.length == 0 ? "240px" : "120px",
                }}
                name={"name"}
                value={name}
                disabled={
                  templateInfo.accountId == "tribboo"
                    ? true
                    : user
                      ? freeTrialExpired || user["custom:role"] === "WORKER"
                        ? true
                        : false
                      : false
                }
                placeholder={i18n.t("Introduce el nombre de la categoria")}
                autoComplete="off"
              ></input>

              <div
                className="inline"
                style={{
                  marginLeft: "5px",
                  position: "absolute",
                  right: "1%",
                  marginTop: "0px",
                }}
              >
                <div
                  className="detallesCap"
                  style={{
                    backgroundColor: "transparent",
                    width: "40px",
                    height: "0px",
                    justifyContent: "center",
                    marginLeft: "120px",
                  }}
                >
                  {templateInfo.accountId == "tribboo" ? null : user ? (
                    freeTrialExpired ||
                    user["custom:role"] === "WORKER" ? null : (
                      <DeleteOutlineIcon
                        style={{ width: "19px", cursor: "pointer" }}
                        onClick={e => {
                          e.stopPropagation()
                          setOpenEliminate(true)
                        }}
                      />
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </SubChapterContainer>

          {openNotSendCapitulo ? (
            props.imParent ? (
              <ShowChildDivWithBorder
                style={{
                  paddingLeft: props.capitulo.partidas?.length ? "0px" : "8px",
                  borderLeft: "7px solid #F1CA2C",
                }}
              >
                {props.capitulo.partidas?.length ? (
                  <>
                    <PartTable
                      parts={props.capitulo.partidas}
                      imParent={props.imParent ? props.imParent : false}
                      total={props.capitulo.total}
                      capitulo={props.capitulo}
                    />
                    {props.capitulo.subcapitulos?.length ? (
                      <div style={{ marginTop: "20px" }}>
                        {props.capitulo.subcapitulos.map((capitulo, i) => (
                          <Capitulo
                            key={capitulo.chapterId}
                            i={i}
                            capitulo={capitulo}
                            imLast={
                              props.capitulo.subcapitulos.length - 1 === i
                                ? true
                                : false
                            }
                            indice={i}
                          />
                        ))}{" "}
                      </div>
                    ) : null}
                  </>
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <Capitulo
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                      imLast={
                        props.capitulo.subcapitulos.length - 1 === i
                          ? true
                          : false
                      }
                      indice={i}
                    />
                  ))
                ) : user ? (
                  freeTrialExpired ||
                  user["custom:role"] === "WORKER" ? null : (
                    <div style={{ display: "grid" }}>
                      {templateInfo.accountId == "tribboo" ? null : (
                        <div className="NombreAlign">
                          <button
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name="AddNewCategoryBP"
                            data-amplify-analytics-attrs=""
                            onClick={() => {
                              addSubChapter()
                            }}
                            className="nuevoBotonCrear4"
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            + {i18n.t("Nueva Subcategoría")}
                          </button>
                        </div>
                      )}
                      {templateInfo.accountId == "tribboo" ? null : (
                        <div className="NombreAlign">
                          <button
                            data-amplify-analytics-on="click"
                            data-amplify-analytics-name="AddNewPartTemplates"
                            data-amplify-analytics-attrs=""
                            onClick={() => {
                              createPart()
                            }}
                            className="nuevoBotonCrear4"
                            style={{
                              marginTop: "10px",
                              marginBottom: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            + {i18n.t("Buscar o crear nueva partida")}
                          </button>
                        </div>
                      )}
                    </div>
                  )
                ) : null}
              </ShowChildDivWithBorder>
            ) : (
              <ShowChildDiv style={{ backgroundColor: "#FDEFC6" }}>
                {props.capitulo.partidas?.length ? (
                  <>
                    <PartTable
                      parts={props.capitulo.partidas}
                      total={props.capitulo.total}
                      capitulo={props.capitulo}
                    />
                    {props.capitulo.subcapitulos?.length ? (
                      <div style={{ marginTop: "20px" }}>
                        {props.capitulo.subcapitulos.map((capitulo, i) => (
                          <Capitulo
                            key={capitulo.chapterId}
                            i={i}
                            capitulo={capitulo}
                            imLast={
                              props.capitulo.subcapitulos.length - 1 === i
                                ? true
                                : false
                            }
                            indice={i}
                          />
                        ))}
                      </div>
                    ) : null}
                  </>
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <Capitulo
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                      imLast={
                        props.capitulo.subcapitulos.length - 1 === i
                          ? true
                          : false
                      }
                      indice={i}
                    />
                  ))
                ) : templateInfo.accountId == "tribboo" ? null : user ? (
                  freeTrialExpired ||
                  user["custom:role"] === "WORKER" ? null : (
                    <div style={{ display: "grid" }}>
                      <div className="NombreAlign">
                        <button
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="AddNewCategoryTemplate"
                          data-amplify-analytics-attrs=""
                          onClick={() => {
                            addSubChapter()
                          }}
                          className="nuevoBotonCrear4"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          + {i18n.t("Nueva Subcategoría")}
                        </button>
                      </div>
                      <div className="NombreAlign">
                        <button
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="AddNewPartTemplates"
                          data-amplify-analytics-attrs=""
                          onClick={() => {
                            createPart()
                          }}
                          className="nuevoBotonCrear4"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          + {i18n.t("Buscar o crear nueva partida")}
                        </button>
                      </div>
                    </div>
                  )
                ) : null}
              </ShowChildDiv>
            )
          ) : null}
          {templateInfo.accountId == "tribboo" ? null : user ? (
            freeTrialExpired ||
            user["custom:role"] === "WORKER" ? null : props.imLast ? (
              props.imParent ? (
                <div className="NombreAlign">
                  <button
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="AddNewCategoryTemplate"
                    data-amplify-analytics-attrs={props.capitulo}
                    onClick={() => {
                      addSubChapter2()
                    }}
                    className="nuevoBotonCrear4"
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    + {i18n.t("Nueva Subcategoría")}
                  </button>
                </div>
              ) : (
                <div className="NombreAlign">
                  <button
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="AddNewCategoryTemplate"
                    data-amplify-analytics-attrs={props.capitulo}
                    onClick={() => {
                      addSubChapter2()
                    }}
                    className="nuevoBotonCrear2"
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    + {i18n.t("Nueva Subcategoría")}
                  </button>
                </div>
              )
            ) : null
          ) : null}
        </div>
      )}
      <SendModal
        open={openSendBudget}
        setOpen={setOpenSendBudget}
        contactos={Contacts}
        capitulo={props.capitulo}
      />
      <ModalDeleteCap
        openEliminate={openEliminate}
        setOpenEliminate={setOpenEliminate}
        infoCap={props.capitulo}
      />
      {openPart && (
        <ModalSearchParts
          open={openPart}
          setOpen={setOpenPart}
          capitulo={props.capitulo}
        />
      )}

      {
        // <ModalCreateBankPart open={openPart} setOpen={setOpenPart} capitulo={props.capitulo}/>
      }
    </div>
  )
}

Capitulo.propTypes = {
  i: propTypes.number.isRequired,
  capitulo: propTypes.object.isRequired,
  imParent: propTypes.bool,
  imLast: propTypes.bool,
  indice: propTypes.number,
}

export default Capitulo
