import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import SuccessModal from "./SuccessModal"
import { CircularProgress } from "@mui/material"
import { HelveticaBold } from "common/typographies.js"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "500px",
    height: "425px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "500px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const ModalWaiting = ({
  open,
  setOpen,
  setOpenAnt,
  project,
  loading,
  imInBudget,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [open2, setOpen2] = useState(false)
  //const importedProject = useProgress({projectId: project.projectId, accountId: project.createdBy})
  useEffect(() => {
    if (loading === false) {
      setOpen2(true)
    }
  }, [loading])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <div className="modal-body">
            <div>
              <div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    marginTop: "30px",
                  }}
                >
                  {i18n.t("Estamos construyendo tu")}
                  <br /> {i18n.t("presupuesto y planning...")}
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                  }}
                >
                  Esto puede tomar un minuto. Por favor no te
                  <br /> vayas, ni cierres esta pestaña
                </p>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    style={{
                      width: 125,
                      height: 125,
                      marginLeft: "0px",
                      color: "#414141",
                      marginBottom: "20px",
                      marginTop: "20px",
                    }}
                  />
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      fontSize: "20px",
                      marginLeft: "10px",
                      marginTop: "0px",
                    }}
                  >
                    {i18n.t("Cargando...")}
                  </HelveticaBold>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {!loading && imInBudget ? (
        location.reload()
      ) : (
        <SuccessModal
          successModal={open2}
          setSuccessModal={setOpen2}
          project={project}
        />
      )}
    </>
  )
}
ModalWaiting.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  project: PropTypes.object,
  imInBudget: PropTypes.bool,
  loading: PropTypes.bool,
  setOpenAnt: PropTypes.func.isRequired,
}
export default ModalWaiting
