import React, { useContext, useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import i18n from "i18n"
import {
  PoppinsGrey,
  PoppinsSemiBold,
  PoppinsNormal,
} from "common/typographies"
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined"
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined"
import { BlueButton } from "common/containers"
import { PoppinsWhite } from "common/typographies"
import { Document, Page, pdfjs } from "react-pdf"
import ProposalContext from "context/proposal/proposalContext"
import { useReactToPrint } from "react-to-print"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "640px",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    height: "620px",
    borderLeft: "5px solid #ED6335",
  },
}))

const ModalPrevisualizarPDF = ({ open, setOpen, group }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const { getPdf, loadingPdf, pdf } = useContext(ProposalContext)
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })
  useEffect(() => {
    if (open) {
      getPdf(
        group.proposals[0].proposalInfo.proposalId,
        group.proposals[0].proposalInfo.accountId,
      )
    }
  }, [open])
  const [imprimir, setImprimir] = useState(false)
  const [download, setDownload] = useState(false)
  const handleImprimir = () => {
    setImprimir(true)
  }

  useEffect(() => {
    if (imprimir) {
      handlePrint()
      setImprimir(false)
    }
  }, [imprimir])

  const handleDownload = () => {
    setDownload(true)
  }

  useEffect(() => {
    if (pdf.link && download) {
      window.open(pdf.link)
      setDownload(false)
    }
  }, [pdf, download])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setOpen(false)
            }}
            style={{
              marginTop: "0px",
              paddingLeft: "6px",
              marginRight: "-10px",
              size: "7px",
            }}
          ></CloseIcon>
          <div className="inline" style={{ position: "relative" }}>
            <div
              style={{
                width: "50%",
                display: "grid",
                marginTop: "10px",
                marginRight: "40PX",
              }}
            >
              <PoppinsSemiBold style={{ fontSize: "18px", fontWeight: "500" }}>
                {group.groupName}
              </PoppinsSemiBold>
              <PoppinsGrey style={{ marginTop: "-5px" }}>
                {group.proposals ? group.proposals[0].projectInfo.name : ""}
              </PoppinsGrey>
            </div>
            <BlueButton
              onClick={() => {
                handleImprimir()
              }}
              className="inline"
              style={{ marginRight: "7px", width: "100px", marginTop: "25px" }}
            >
              <UploadFileOutlinedIcon
                style={{
                  color: "white",
                  width: "16px",
                  marginTop: "-2px",
                  marginLeft: "7px",
                  marginRight: "5px",
                }}
              />
              <PoppinsWhite>{i18n.t("Imprimir")}</PoppinsWhite>
            </BlueButton>
            <BlueButton
              onClick={() => {
                handleDownload()
              }}
              className="inline"
              style={{ width: "114px", marginTop: "25px" }}
            >
              <NotificationsActiveOutlinedIcon
                style={{
                  color: "white",
                  width: "16px",
                  marginTop: "-2px",
                  marginLeft: "7px",
                  marginRight: "7px",
                }}
              />
              <PoppinsWhite>{i18n.t("Descargar")} </PoppinsWhite>
            </BlueButton>
          </div>
          {loadingPdf ? (
            <>
              <div
                style={{
                  justifyContent: "center",
                  width: "100%",
                  textAlign: "center",
                  position: "relative",
                  display: "flex",
                  marginLeft: "-100px",
                  marginTop: "70px",
                }}
              >
                <div className="loader">
                  <div className="ball"></div>
                  <div className="ball"></div>
                  <div className="ball"></div>
                </div>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  width: "100%",
                  textAlign: "center",
                  position: "relative",
                  display: "flex",
                  marginLeft: "-30px",
                }}
              >
                <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
              </div>
              <div
                style={{
                  justifyContent: "center",
                  width: "100%",
                  textAlign: "center",
                  position: "relative",
                  display: "flex",
                  marginLeft: "-30px",
                  marginTop: "-15px",
                }}
              >
                <PoppinsNormal style={{ fontWeight: "400" }}>
                  {i18n.t("Esta acción puede tardar unos segundos")}
                </PoppinsNormal>
              </div>
            </>
          ) : (
            <div
              style={{
                overflowY: "auto",
                maxHeight: "90.7%",
                marginLeft: "-13px",
                marginRight: "-32px",
              }}
            >
              <Document
                ref={componentRef}
                file={pdf.link}
                onLoadError={console.error}
                style={{ overflowY: "auto" }}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {[...Array(numPages)].map((elementInArray, index) => (
                  <Page key={index} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
ModalPrevisualizarPDF.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
}

export default ModalPrevisualizarPDF
