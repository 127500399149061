import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import CurrencyInput from "react-currency-input-field"
import { TextareaAutosize } from "@mui/base"
import styled from "styled-components"
import TypeDropdown from "./TypeDropdown"
import UnityDropdown from "../UnityDropdown"

const CustomInput = styled.input`
  text-align: start;
  width: 385.13px;
  height: 35px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  color: #414141;
  margin-bottom: 9px;
  padding-left: 8px;

  &::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #b7b7b7;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #ffd52b !important;
  }

  &:hover {
    &::placeholder {
      color: #414141;
    }
    z-index: 10;
    border: 1px solid #414141;
  }
`

const CustomTextarea = styled(TextareaAutosize)`
    padding-top: 5px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    min-height: 25px;
    margin-top: 10px;
    color: #414141;
    border: 0px;
    resize: none;
    justify-content:'left';
    text-align:'left',
    :focus-within {
        border: 1px solid #FFD52B !important;
    }
    :hover:not(:focus-within) {
        z-index: 10;
        border: 1px solid #414141 !important;
    }
    :empty:focus::before, :empty::before {
        content: 'Escribir';
        color: lightgray;
    }
`

const CreateDetailViewMode = ({
  setCreatingDetailInViewMode,
  details,
  setDetails,
  detail,
  bancoDePrecios,
}) => {
  const divRef = useRef()
  const sizeRef = useRef(null)
  const [size, setSize] = useState({ height: 28 })
  const [detailView, setDetailView] = useState(detail)
  const handleFocusOut = e => {
    if (!divRef.current.contains(e.relatedTarget)) {
      if (
        detailView.description !== "" &&
        detailView.price !== 0 &&
        detailView.quantity !== 0 &&
        detailView.unity !== ""
      ) {
        setDetails(prevArray => [...prevArray, detailView])
        setCreatingDetailInViewMode(false)
        setDetailView({
          type: "",
          description: "",
          price: 0,
          quantity: 0,
          total: 0,
        })
      }
    }
  }

  const handleChangeType = type => {
    setDetailView(prevObject => {
      return {
        ...prevObject,
        type: type,
      }
    })
  }
  const handleChangeUnity = unity => {
    setDetailView(prevObject => {
      return {
        ...prevObject,
        unity: unity,
      }
    })
  }

  const parseNumberToFloat = number => {
    return parseFloat(number.replace(",", "."))
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (e.charCode === 13) {
      e.preventDefault()
      setDetails(prevArray => [...prevArray, detailView])
      setCreatingDetailInViewMode(false)
      setDetailView({
        type: "",
        description: "",
        price: 0,
        quantity: 0,
        total: 0,
      })
    }

    setDetailView(prevObject => {
      if (name === "quantity" || name === "price") {
        const total =
          name === "quantity"
            ? prevObject.price * value
            : value * prevObject.quantity
        return {
          ...prevObject,
          [name]: value,
          total: total % 2 === 0 ? total : total.toFixed(2),
        }
      } else {
        return {
          ...prevObject,
          [name]: value,
        }
      }
    })
  }

  useEffect(() => {
    if (sizeRef.current) {
      const charWidth = 8
      const charsLength = detail.description.length * charWidth
      const rows = Math.floor(charsLength / sizeRef.current.clientWidth) + 1
      setSize({
        height: rows * 18,
        width: sizeRef.current.clientWidth,
      })
    }
  }, [sizeRef.current, detail.description])

  return (
    <>
      {
        <div
          className="inline"
          ref={divRef}
          onBlur={handleFocusOut}
          style={{ width: "100%", marginBottom: `-${size.height - 14}px` }}
        >
          <div
            style={{
              width: "7%",
              textAlign: "end",
              marginRight: "9px",
              marginTop: "-3px",
            }}
          >
            <TypeDropdown
              type={detailView.type}
              setType={handleChangeType}
              size={size}
              mode={"view"}
            />
          </div>
          <div style={{ width: "42%", textAlign: "start" }}>
            <CustomTextarea
              placeholder="Escribir descripción"
              value={detailView.description}
              name="description"
              style={{
                height: `${size.height + 14}px`,
                position: "relative",
                border: "1px solid #C4C4C4",
                background: "white",
                marginBottom: "-12.5px",
                marginTop: "-4px",
                width: "100%",
                borderRadius: "0px",
                minWidth: "85px",
                borderBottomLeftRadius: "6px",
                borderTopLeftRadius: "6px",
              }}
              ref={sizeRef}
              onChange={e => {
                handleChange(e)
              }}
            />
          </div>
          <div style={{ width: "10%", textAlign: "start" }}>
            <div
              style={{
                paddingTop: "5px",
                width: "100%",
                height: `${size.height + 14}px`,
                background: "white",
                marginBottom: "-12.5px",
                marginTop: "-4px",
                border: "1px solid #C4C4C4",
              }}
            >
              <UnityDropdown
                imDesglose={true}
                unity={detailView.unity}
                setUnity={handleChangeUnity}
              />
            </div>
          </div>
          <div style={{ width: "12%", textAlign: "start" }}>
            <CustomInput
              onClick={e => {
                e.target.select()
              }}
              value={detailView.quantity}
              decimalsLimit={10}
              type="number"
              name="quantity"
              placeholder={i18n.t("Cantidad")}
              style={{
                height: `${size.height + 14}px`,
                position: "relative",
                minHeight: "25px",
                marginTop: "-4px",
                marginBottom: "-2px",
                width: "100%",
                padding: "3px 10px",
                borderRadius: "0px",
              }}
              onChange={e => {
                handleChange(e)
              }}
            />
          </div>
          <div style={{ width: "12%", textAlign: "start" }}>
            <CustomInput
              onClick={e => {
                e.target.select()
              }}
              value={detailView.price}
              decimalsLimit={10}
              type="number"
              name="price"
              placeholder={i18n.t("Precio")}
              style={{
                height: `${size.height + 14}px`,
                position: "relative",
                minHeight: "25px",
                marginTop: "-4px",
                marginBottom: "-2px",
                width: "100%",
                padding: "3px 10px",
                borderRadius: "0px",
              }}
              onChange={e => {
                handleChange(e)
              }}
            />
          </div>
          <div style={{ width: "11%", textAlign: "start" }}>
            <CustomInput
              disabled
              value={detailView.total}
              decimalsLimit={10}
              type="number"
              name="total"
              placeholder={i18n.t("Importe")}
              style={{
                height: `${size.height + 14}px`,
                position: "relative",
                minHeight: "25px",
                marginTop: "-4px",
                marginBottom: "-2px",
                width: "100%",
                padding: "3px 10px",
                borderRadius: "0px",
                borderBottomRightRadius: "6px",
                borderTopRightRadius: "6px",
              }}
            />
          </div>
        </div>
      }
    </>
  )
}
CreateDetailViewMode.propTypes = {
  setCreatingDetailInViewMode: PropTypes.func.isRequired,
  details: PropTypes.array.isRequired,
  setDetails: PropTypes.func.isRequired,
  detail: PropTypes.object.isRequired,
  bancoDePrecios: PropTypes.string,
}
export default CreateDetailViewMode
