const validateFields = (partidaState, refObject, setErrorMessage) => {
  if (partidaState.name == "") {
    refObject.referenciaName.current.style.border = "1px solid red"
    setErrorMessage("Debe introducir el nombre antes de guardar la partida")
    return false
  } else {
    refObject.referenciaName.current.style.border = "1px solid #C4C4C4"
  }

  return true
}

export default validateFields
