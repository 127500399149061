export const ListaColores = [
  {
    bg: "#C9DBCD",
    color: "#3BA452",
  },
  {
    bg: "#B8DBEA",
    color: "#3090B9",
  },
  {
    bg: "#D6A8A8",
    color: "#BF2929",
  },
  {
    bg: "#FFC188",
    color: "#DB7600",
  },
  {
    bg: "#C4BCDB",
    color: "#633BA4",
  },
  {
    bg: "#FAE1EF",
    color: "#BA4D8E",
  },
  {
    bg: "#F2ECB7",
    color: "#AF9E09",
  },
  {
    bg: "#FFC9F3",
    color: "#D854BB",
  },
]
