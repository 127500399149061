import React, { useContext, useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { v4 as uuidv4 } from "uuid"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import { useParams } from "react-router-dom"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import UnityDropdown from "./UnityDropdown"
import Budget2023Context from "context/budget2023/Budget2023Context"
import { getCurrency } from "utils/functions"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",

    width: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "700px",
    overflowY: "auto",
    overflowX: "hidden",
    height: "auto",
    padding: "0",
  },
}))

const ModalEditarPartida = ({ partida, open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { projectId } = useParams()
  const { updatePartidav2, getSubCapitulosContent2 } =
    useContext(Budget2023Context)
  const [total, setTotal] = useState(partida.price ? partida.price : 0)
  const [user2, setUser] = useState(0)
  const { user } = useContext(AuthContext)
  const [final, setFinal] = useState(0)
  const [medicion, setMedicion] = useState(
    partida.quantity ? partida.quantity : 0,
  )
  const [resumen, setResumen] = useState(
    partida.description ? partida.description : "",
  )
  const [name, setName] = useState(partida.name ? partida.name : "")
  useEffect(() => {
    if (open && partida) {
      setName(partida.name ? partida.name : "")
      setResumen(partida.description ? partida.description : "")
      setTotal(partida.price ? partida.price : 0)
      setMedicion(partida.quantity ? partida.quantity : 0)
      setFinal(
        partida.price
          ? partida.quantity
            ? parseFloat(partida.price * partida.quantity).toFixed(2)
            : 0
          : 0,
      )
      setUnity(partida.unity ? partida.unity : "")
    }
  }, [open])
  const handleChangeMedicion = e => {
    if (e.target.value >= 0) {
      setMedicion(e.target.value)
    } else {
      setMedicion(e.target.value * -1)
    }
  }
  const handleChangeTotal = e => {
    if (e.target.value >= 0) {
      setTotal(parseFloat(e.target.value))
    } else {
      setTotal(parseFloat(e.target.value * -1))
    }
  }
  const handleChange = e => {
    setName(referenciaParti2.current.outerText)
  }
  const referenciaParti = useRef(null)
  const referenciaParti2 = useRef(null)
  const [contadorTest, setContadorTest] = useState(1)

  useEffect(() => {
    if (contadorTest != 0) {
      if (referenciaParti.current) {
        referenciaParti.current.innerText = partida.description
        referenciaParti2.current.innerText = partida.name
        setContadorTest(0)
      } else if (referenciaParti.current == null && open == true) {
        setContadorTest(contadorTest + 1)
        setHeSidoAbierto(true)
      }
    }
    if (heSidoAbierto == true && open == false) {
      setContadorTest(contadorTest + 1)
      setHeSidoAbierto(false)
    }
  }, [referenciaParti, open, contadorTest])
  useEffect(() => {
    if (partida.price != undefined && total == 0) {
      setTotal(partida.price)
      setResumen(partida.description)
      setMedicion(Number(partida.quantity))
    } else if (partida.price != undefined) {
      setResumen(partida.description)
      setUser(parseFloat(partida.quantity * partida.price).toFixed(2))
      setFinal(
        partida.price
          ? partida.quantity
            ? parseFloat(partida.price * partida.quantity).toFixed(2)
            : 0
          : 0,
      )
      setMedicion(Number(partida.quantity))
    }
  }, [partida])

  useEffect(() => {
    setFinal(parseFloat(medicion * total).toFixed(2))
    setUser(parseFloat(medicion * partida.price).toFixed(2))
  }, [total, medicion])

  const handleChangeResumen = e => {
    setResumen(referenciaParti.current.outerText)
  }
  const guardarPres = async e => {
    await updatePartidav2(
      {
        ...partida,
        description: resumen,
        resumen: resumen,
        quantity: medicion,
        unity: unity,
        projectId: projectId,
        price: total,
        code: partida.code ? partida.code : uuidv4(),
        name: name,
      },
      partida.chapterId,
      user.sub,
    )
    await getSubCapitulosContent2(projectId, partida.chapterId)
    setOpen(false)
  }
  const cancelar = () => {
    setOpen(false)
  }
  const [heSidoAbierto, setHeSidoAbierto] = useState(false)
  const [unity, setUnity] = useState(partida.unity ? partida.unity : "")

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <div id="totalDiv">
        <div style={modalStyle} className={classes.paper}>
          <table
            className="tablaPartidas"
            style={{ maxWidth: "348px", minWidth: "348px" }}
          >
            <tr style={{ borderBottom: "0.5px solid #B7B7B7" }}>
              <th
                style={{
                  minWidth: "78px",
                  paddingLeft: "5px",
                  borderLeft: "none",
                  backgroundColor: "#F6F9FC",
                  borderBottom: "0.5px solid #B7B7B7",
                }}
              >
                {i18n.t("Nombre")}
              </th>
              <th
                style={{
                  minWidth: "690px",
                  textAlign: "left",
                  paddingLeft: "57px",
                  backgroundColor: "#F6F9FC",
                  borderBottom: "0.5px solid #B7B7B7",
                }}
              >
                <p
                  style={{
                    display: "block",
                    textAlign: "left",
                    marginBottom: "0px",
                    height: "100%",
                    marginLeft: "-60px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span
                    ref={referenciaParti2}
                    className="textareaExpandEdit"
                    role="textbox"
                    autoComplete="off"
                    name="name"
                    onInput={handleChange}
                    contentEditable
                  ></span>
                </p>
              </th>
              <th
                style={{
                  minWidth: "86px",
                  borderBottom: "0.5px solid #B7B7B7",
                }}
              ></th>
              <th
                colSpan="2"
                style={{
                  minWidth: "253px",
                  borderBottom: "0.5px solid #B7B7B7",
                }}
              >
                {i18n.t("Precio Objetivo")}
                <CloseIcon
                  onClick={() => {
                    setOpen(false)
                  }}
                  style={{ marginLeft: "110px", cursor: "pointer" }}
                ></CloseIcon>
              </th>
            </tr>
            <tr
              id="subCategory"
              style={{ borderBottom: "0.5px solid #B7B7B7" }}
            >
              <td
                style={{ borderLeft: "none", width: "79px", color: "#B7B7B7" }}
              >
                U
              </td>
              {partida.elementInfo != null ? (
                <td
                  style={{
                    textAlign: "left",
                    paddingLeft: "35px",
                    color: "#B7B7B7",
                  }}
                >
                  {i18n.t("Descripción")}
                </td>
              ) : (
                <td
                  style={{
                    textAlign: "left",
                    paddingLeft: "35px",
                    color: "#414141",
                  }}
                >
                  {i18n.t("Descripción")}
                </td>
              )}
              <th style={{ minWidth: "86px", borderBottom: "none" }}>
                {i18n.t("Medición")}
              </th>
              <td style={{ minWidth: "127px", color: "#414141" }}>
                {" "}
                {getCurrency(user["custom:currency"])}/u
              </td>
              <td
                style={{
                  minWidth: "126px",
                  color: "#B7B7B7",
                  borderLeft: "1px solid #F3F1F1",
                }}
              >
                {i18n.t("Total")}
              </td>
            </tr>

            <tr>
              <td style={{ color: "#B7B7B7" }}>
                <div style={{ marginTop: "15px", height: "100%" }}>
                  <UnityDropdown setUnity={setUnity} unity={unity}>
                    &#9660;
                  </UnityDropdown>
                </div>
              </td>
              <td>
                <p
                  style={{
                    display: "block",
                    textAlign: "left",
                    marginBottom: "0px",
                    height: "100%",
                  }}
                >
                  <span
                    style={{ maxHeight: "40vh", overflowY: "auto" }}
                    ref={referenciaParti}
                    className="textareaExpandEdit"
                    role="textbox"
                    autoComplete="off"
                    name="resumen"
                    onInput={handleChangeResumen}
                    contentEditable
                  ></span>
                </p>
              </td>

              <td>
                <div style={{ marginTop: "15px", height: "100%" }}>
                  <input
                    style={{ color: "#414141", fontWeight: "400" }}
                    id="inputUnidades"
                    type="number"
                    min="0"
                    onChange={handleChangeMedicion}
                    name="medicion"
                    value={medicion}
                  />
                </div>
              </td>

              <td className="letraPrecio">
                <div
                  style={{
                    justifyContent: "center",
                    marginTop: "15px",
                    height: "100%",
                  }}
                >
                  <input
                    style={{ color: "#414141", fontWeight: "400" }}
                    id="inputUnidades"
                    min="0"
                    type="number"
                    onChange={handleChangeTotal}
                    name="total"
                    value={total}
                  ></input>
                </div>
              </td>
              <td
                className="letraPrecio"
                style={{ color: "#B7B7B7", borderLeft: "0.5px solid #B7B7B7" }}
              >
                <div
                  style={{
                    justifyContent: "center",
                    marginTop: "20px",
                    height: "100%",
                  }}
                >
                  {final} {getCurrency(user["custom:currency"])}
                </div>
              </td>
            </tr>

            <tr style={{ minHeight: "50px", maxHeight: "50px" }}>
              <td></td>
              <td
                colSpan="4"
                style={{
                  borderLeft: "none",
                  textAlign: "right",
                  paddingRight: "30px",
                }}
              >
                <button
                  style={{
                    position: "absolute",
                    width: "102px",
                    boxShadow: "0px 0px 0px 0px",
                    border: "0px",
                    backgroundColor: "white",
                  }}
                  className="PartidaManual"
                  onClick={() => {
                    cancelar()
                  }}
                >
                  {i18n.t("Cancelar")}
                </button>
                <button
                  style={{
                    marginLeft: "110px",
                    width: "102px",
                    backgroundColor: "#FFD52B",
                  }}
                  className="PartidaManual"
                  onClick={e => {
                    guardarPres()
                  }}
                >
                  {i18n.t("Guardar")}
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </Modal>
  )
}

ModalEditarPartida.propTypes = {
  partida: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  addPartida: PropTypes.func.isRequired,
  handleChangePartida: PropTypes.func.isRequired,
  handleCustom: PropTypes.func.isRequired,
  contador2: PropTypes.number,
  setContador2: PropTypes.func,
}

export default ModalEditarPartida
