import useLocalStorage from "hooks/useLocalStorage"

export const BUDGET_CONFIG_TYPES = Object.freeze({
  PREFERENCES: "preferences",
})

export const PREFERENCES = Object.freeze({
  COST_PRICE: "precioCoste", // Coste Objetivo
  COST_REAL: "costReal", // Coste Real
  DATABASE: "basededatos", // Márgen
  CLIENT_PRICE: "clientPrice", // Precio cliente
  DISCOUNT: "descuento", //Descuentos
  PROCEDENCE: "procedencia", // Procedencia
})

export const BUDGET_CONFIG = "budgetConfig"

/**
 * Custom hook for managing hierarchical budget configurations.
 *
 * This hook provides a way to persistently store and update hierarchical settings using `useLocalStorage`.
 *
 * @returns {[Object, Function]} An array containing the current budget configuration and an updater function.
 * @returns {Object} budgetConfig - The current hierarchical configuration for budget settings.
 * @returns {Function} updateConfig - Updates a specific configuration value in the hierarchy.
 *
 * @example
 * const [budgetConfig, updateConfig] = useBudgetConfig();
 *
 * // Access preferences
 * const databasePreference = budgetConfig.preferences.database;
 *
 * // Update a preference
 * updateConfig("preferences", "database", true);
 *
 * @description
 * - `budgetConfig`: Stores the current hierarchical configuration.
 * - `updateConfig`: Updates a specific key-value pair in the configuration hierarchy.
 *
 * ### Default Configuration
 * #### Preferences
 * - `database`: `false`
 * - `clientPrice`: `true`
 * - `discount`: `false`
 * - `objective`: `true`
 * - `procedence`: `true`
 * - `costPrice`: `true`
 */

const useBudgetConfig = () => {
  const [budgetConfig, setBudgetConfig] = useLocalStorage(BUDGET_CONFIG, {
    [BUDGET_CONFIG_TYPES.PREFERENCES]: {
      [PREFERENCES.DATABASE]: false,
      [PREFERENCES.CLIENT_PRICE]: true,
      [PREFERENCES.DISCOUNT]: false,
      [PREFERENCES.OBJECTIVE]: true,
      [PREFERENCES.PROCEDENCE]: true,
      [PREFERENCES.COST_PRICE]: true,
    },
  })

  const updateConfig = (section, key, value) => {
    setBudgetConfig(prevConfig => ({
      ...prevConfig,
      [section]: {
        ...prevConfig[section],
        [key]: value,
      },
    }))
  }

  return [budgetConfig, updateConfig]
}

export default useBudgetConfig
