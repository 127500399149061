import React, { useState, useMemo, useContext } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import i18n from "i18n"
import useDashboardConfig, {
  DASHBOARD_COLUMN_VISIBILITY,
  DASHBOARD_ORDERED_FIELDS,
  DASHBOARD_SORT,
} from "./useDashboardConfig"
import moment from "moment"
import DropdownSettings from "components/Project/Overview/DropdownSettings"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { currencyFormatDE } from "lib/transformers"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext"

/**
 * Custom hook for managing dashboard column configuration, sorting, and visibility filters.
 *
 * This hook provides tools for managing column order, sort models, and visibility settings.
 * It uses `useDashboardConfig` for persistent configuration management and exposes functions to update each aspect of the dashboard configuration.
 *
 * @returns {Object} Configuration and handler functions for managing dashboard columns and filters.
 * @returns {Array} columns - An array of column definitions for rendering in the dashboard.
 * @returns {Function} updateColumnOrder - Updates the order of columns based on new indices.
 * @returns {Function} handleColumnOrderChange - Handles column drag-and-drop order changes.
 * @returns {Function} handleSortModelChange - Updates the sorting model of the columns.
 * @returns {Function} handleColumnVisibilityModelChange - Updates the visibility model of the columns.
 * @returns {Object} dashboardConfig - The current dashboard configuration state.
 * @returns {Function} updateConfig - Updates the dashboard configuration for a specific key.
 *
 * @example
 * const {
 *   columns,
 *   updateColumnOrder,
 *   handleColumnOrderChange,
 *   handleSortModelChange,
 *   handleColumnVisibilityModelChange,
 *   dashboardConfig,
 * } = useDashboardConfigFilters();
 *
 * @description
 * - `columns`: Array of column definitions, including rendering and sorting logic.
 * - `updateColumnOrder`: Rearranges columns and updates the order in the persistent configuration.
 * - `handleColumnOrderChange`: Event handler for drag-and-drop column reordering.
 * - `handleSortModelChange`: Updates the sort order of columns based on user interaction.
 * - `handleColumnVisibilityModelChange`: Toggles column visibility and saves it to the configuration.
 * - `dashboardConfig`: Stores the state of the dashboard configuration including column order, visibility, and sort models.
 */
const useDashboardConfigFilters = () => {
  const history = useHistory()
  const [dashboardConfig, updateConfig] = useDashboardConfig()
  const { user } = useContext(AuthContext)

  const initialColumns = [
    {
      field: "detalle",
      headerName: "Ir al detalle",
      width: 100,
      sortable: false,
      renderCell: params => (
        <OpenInNewIcon
          style={{ cursor: "pointer", color: "#1976d2" }}
          onClick={() => history.push(`/budgeting/${params.row.id}`)}
        />
      ),
    },
    {
      field: "projectImage",
      headerName: i18n.t("dashboard.image"),
      width: 100,
      sortable: false,
      renderCell: params => (
        <img
          src={params.row.projectImage}
          alt={params.row.name}
          style={{
            width: 50,
            height: 50,
            objectFit: "cover",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={() => history.push(`/budgeting/${params.row.id}`)}
        />
      ),
    },
    {
      field: "identifierNumber",
      headerName: i18n.t("dashboard.identifierNumber"),
      width: 150,
    },
    { field: "name", headerName: i18n.t("dashboard.name"), width: 200 },
    {
      field: "description",
      headerName: i18n.t("dashboard.description"),
      width: 150,
    },
    {
      field: "updatedAt",
      headerName: i18n.t("dashboard.updatedAt"),
      width: 100,
      renderCell: params => moment(params.value).format("DD/MM/YYYY"),
    },
    // { field: "fase", headerName: "Phase", width: 100 },
    {
      field: "progressPercentage",
      headerName: i18n.t("dashboard.progressPercentage"),
      width: 100,
    },
    {
      field: "finalPrice",
      headerName: i18n.t("dashboard.finalPrice"),
      width: 150,
      type: "number",
      valueFormatter: params => {
        const value = params
        if (value !== undefined && value !== null) {
          const data = currencyFormatDE(
            value,
            getCurrency(user["custom:currency"]),
          )
          return data
        }
        return "0"
      },
    },
    {
      field: "price",
      headerName: i18n.t("dashboard.priceCost"),
      width: 150,
      type: "number",
      valueFormatter: params => {
        const value = params
        if (value !== undefined && value !== null) {
          const data = currencyFormatDE(
            value,
            getCurrency(user["custom:currency"]),
          )
          return data
        }
        return "0"
      },
    },
    {
      field: "industrialProfit",
      headerName: i18n.t("dashboard.marginObj"),
      width: 150,
      type: "number",
    },
    // {
    //   field: "realMargin",
    //   headerName: "Margen real",
    //   width: 150,
    //   type: "number",

    //   // valueGetter: (params) => params.row.finalPrice - params.row.price
    //   renderCell: (params) => {
    //     // console.log("🚀 ~ useDashboardConfigFilters ~ params:", params)
    //     const row = params.row || {};
    //     const finalPrice = row.finalPrice || 0;
    //     const price = row.price || 0;
    //     return finalPrice - price;
    //   },
    // },
    {
      field: "actions",
      headerName: "",
      width: 50,
      sortable: false,
      hideable: false,
      filterable: false,
      pinnable: false,
      renderCell: params => {
        return (
          <div style={{ position: "absolute" }}>
            <DropdownSettings
              imCard={true}
              project={params.row} // Asigna la fila actual como proyecto
              imList={true}
            />
          </div>
        )
      },
    },
  ]

  const sortDataByColumnOrder = (data, columnOrder) => {
    if (
      !columnOrder ||
      columnOrder.length === 0 ||
      columnOrder.length !== data.length
    ) {
      return data
    }

    return data.sort((a, b) => {
      return columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field)
    })
  }

  const sortedData = useMemo(
    () => sortDataByColumnOrder(initialColumns, dashboardConfig.orderedFields),
    [initialColumns, dashboardConfig.orderedFields],
  )

  const [columns, setColumns] = useState(sortedData)

  const updateColumnOrder = async (initialIndex, newIndex) => {
    const columnsClone = [...columns]
    const column = columnsClone.splice(initialIndex, 1)[0]
    columnsClone.splice(newIndex, 0, column)
    const newColumnsOrder = columnsClone.map(item => item.field)
    updateConfig(DASHBOARD_ORDERED_FIELDS, newColumnsOrder)
    setColumns(columnsClone)
  }

  const handleColumnOrderChange = async params => {
    await updateColumnOrder(params.oldIndex, params.targetIndex)
  }

  const handleSortModelChange = newSortModel =>
    updateConfig(DASHBOARD_SORT, newSortModel)

  const handleColumnVisibilityModelChange = model =>
    updateConfig(DASHBOARD_COLUMN_VISIBILITY, model)

  return {
    columns,
    updateColumnOrder,
    handleColumnOrderChange,
    handleSortModelChange,
    handleColumnVisibilityModelChange,
    dashboardConfig,
    updateConfig,
  }
}

export default useDashboardConfigFilters
