import React, { Fragment, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Navbar } from "reactstrap"
import { Box, Stack } from "@mui/material"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import AuthContext from "context/auth/authContext"
import SubscriptionContext from "context/subscription/subscriptionContext"
import i18n from "i18n"
import ModalClosePassword from "components/CommonForBoth/TopbarDropdown/ModalClosePassword"
import { HelveticaBold } from "common/typographies"
import { YellowButton } from "common/buttons"
const BoxOptions = styled(Box)`
  border-bottom: ${props =>
    props.isActive == true ? "2px solid #5984FC;" : "none"};
  cursor: pointer;
  &:hover {
    border-bottom: ${props =>
      props.isDisabled == true ? "none" : "2px solid #5984FC;"};
  }
  font-weight: ${props => (props.isActive == true ? "bold" : "normal")};
  color: ${props => (props.isDisabled == true ? "grey" : "black")};
`
const SearchBox = styled(Box)`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 6px;
`

const MainNavbar = props => {
  const [color, setColor] = useState("navbar-transparent")
  const { invoiceId, projectId } = useParams()
  const { user, showSnackbar, getUser, freeTrialExpired } =
    useContext(AuthContext)
  const {
    subscriptionState,
    isTrial,
    trialDays,
    noSubscription,
    daysLeft,
    showTopAdviceBar,
    setShowTopAdviceBar,
  } = useContext(SubscriptionContext)

  const history = useHistory()
  const getProjectId = () => {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get("projectId")
  }
  const [openModal, setOpenModal] = useState(false)

  return (
    <Fragment>
      <Navbar
        className={classnames("navbar-absolute fixed-top", color)}
        expand="lg"
        style={{
          paddingRight: "0",
          backgroundColor: "white !important",
          height: "60px",
          padding: "0",
          top:
            user && subscriptionState
              ? ((isTrial && daysLeft < trialDays) || noSubscription) &&
                showTopAdviceBar == true
                ? "47px"
                : "0px"
              : "0px",
        }}
      >
        <Box
          display="grid"
          width={"100%"}
          padding={"0"}
          paddingLeft={"30px"}
          margin={"0"}
          height={"60px"}
        >
          <Box
            display="flex"
            alignItems="center"
            width="auto"
            gridColumn={"span 2"}
            padding={"0"}
            margin={"0"}
            height={"54px"}
          >
            {props.navContent ? (
              <Stack
                direction={"row"}
                spacing={"30px"}
                padding={"0"}
                margin={"0"}
                height={"60px"}
                alignItems={"center"}
                style={{
                  paddingTop: "12px",
                  fontFamily: "Helvetica",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "50px",
                }}
              >
                {props.navContent.paths.map((item, index) => {
                  return item.imComponent == true ? (
                    props.navContent.options2
                  ) : item.disabled == true ? (
                    <>
                      <BoxOptions
                        key={index + index * 9}
                        style={{ cursor: "default" }}
                        isActive={item.isActive}
                        isDisabled={item.disabled}
                      >
                        {item.muypronto ? (
                          <div
                            style={{
                              marginTop: "0px",
                              background: "#5984FC",
                              borderRadius: "6px",
                              width: "60px",
                              height: "18px",
                              fontSize: "10px",
                              fontWeight: "500",
                              paddingTop: "-15px",
                            }}
                            id="muypronto2"
                          >
                            <p>{i18n.t("Muy pronto")}</p>
                          </div>
                        ) : null}
                        {item.label}
                      </BoxOptions>
                    </>
                  ) : item.imModal ? (
                    <BoxOptions
                      key={index + index * 20}
                      onClick={() => setOpenModal(true)}
                      isActive={item.isActive}
                      isDisabled={item.disabled}
                    >
                      {item.label}
                    </BoxOptions>
                  ) : item.label == i18n.t("Suscripción") ? (
                    <div
                      key={index * 16}
                      onClick={() => {
                        history.push("/subscription")
                        history.go(0)
                      }}
                    >
                      <BoxOptions
                        isActive={item.isActive}
                        isDisabled={item.disabled}
                      >
                        {item.label}
                      </BoxOptions>
                    </div>
                  ) : item.label == i18n.t("Plantillas") ? (
                    <div
                      key={index * 16}
                      onClick={() => {
                        history.push("/bancodeprecios/templates")
                      }}
                    >
                      <BoxOptions
                        isActive={item.isActive}
                        isDisabled={item.disabled}
                      >
                        {item.label}
                      </BoxOptions>
                    </div>
                  ) : item.label == i18n.t("Mi banco de precios") ? (
                    <div
                      key={index * 16}
                      onClick={() => {
                        history.push("/bancodeprecios")
                      }}
                    >
                      <BoxOptions
                        isActive={item.isActive}
                        isDisabled={item.disabled}
                      >
                        {item.label}
                      </BoxOptions>
                    </div>
                  ) : (
                    <Link
                      key={index * 16}
                      to={
                        projectId
                          ? `${item.to}/${projectId}`
                          : invoiceId
                            ? `${item.to}/${getProjectId()}`
                            : `${item.to}`
                      }
                    >
                      <BoxOptions
                        isActive={item.isActive}
                        isDisabled={item.disabled}
                      >
                        {item.label}
                      </BoxOptions>
                    </Link>
                  )
                })}
                {props.navContent.options
                  ? user
                    ? user.freeTrialExpired || user["custom:role"] === "WORKER"
                      ? null
                      : props.navContent.options
                    : null
                  : null}
              </Stack>
            ) : null}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"flex-end"}
            gridColumn={"4/5"}
            style={{ paddingTop: "5px", marginRight: "70px" }}
          >
            {user && subscriptionState && (isTrial || noSubscription) ? (
              <div className="d-flex justify-content-center align-items-center gap-3">
                <div className="inline" style={{ marginTop: "1px" }}>
                  <div
                    style={{
                      width: "23px",
                      height: "23px",
                      borderRadius: "50px",
                      textAlign: "center",
                      position: "relative",
                      marginTop: "2px",
                      border:
                        daysLeft == 0
                          ? "2px solid #BF2929"
                          : daysLeft <= trialDays / 2
                            ? "2px solid #EE950F"
                            : "2px solid #519F62",
                    }}
                  >
                    <HelveticaBold
                      style={{
                        color: "#414141",
                        fontSize: "12px",
                        position: "absolute",
                        right: "2.5px",
                        top: "-1px",
                        minWidth: "13px",
                      }}
                    >
                      {daysLeft}
                    </HelveticaBold>
                  </div>
                  <HelveticaBold
                    onClick={() => {
                      history.push("/subscription")
                    }}
                    style={{
                      color: "#5984FC",
                      fontWeight: "700",
                      fontSize: "14px",
                      marginLeft: "10px",
                      marginRight: "10px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {i18n.t("Elige tu plan ahora")}
                  </HelveticaBold>
                </div>
                <YellowButton
                  onClick={() => {
                    window.open(
                      "https://meetings-eu1.hubspot.com/meetings/tizianopierini/trial-demo-",
                      "_blank",
                    )
                  }}
                  style={{
                    marginTop: "5px",
                    width: "160px",
                    height: "40px",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                >
                  {i18n.t("buttons.demoRequest")}
                </YellowButton>
              </div>
            ) : null}
          </Box>
        </Box>
      </Navbar>
      <ModalClosePassword
        openPassword={openModal}
        setOpenPassword={setOpenModal}
      />
    </Fragment>
  )
}

MainNavbar.propTypes = {
  navContent: PropTypes.any,
  handleMiniClick: PropTypes.any,
  sidebarMini: PropTypes.any,
}
export default MainNavbar
