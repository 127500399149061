import React, { Fragment, useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import InvoiceLoader from "./invoiceLoader"
import ProjectContext from "context/projects/projectContext"
import SelectNewFactura from "components/Facturas/selectNewFactura"
import i18n from "i18n"
import { HelveticaBold } from "common/typographies"
import InvoicesFilter from "./invoicesFilter"
import { BlueButton } from "common/containers"
import AddIcon from "@mui/icons-material/Add"
import AuthContext from "context/auth/authContext"
import InvoicesTable from "./invoicesTable"
import ModalAntesDeEmpezar from "components/Facturas/modals/modalAntesDeEmpezar"
import ModalCreateFactura from "components/Facturas/modals/modalCreateFactura"
import { SquareContent } from "common/containers"
import { useParams } from "react-router-dom"
import { YellowButton } from "common/buttons"
const InvoicesDashboardContainer = ({ invoiceService }) => {
  const { projectId } = useParams()
  const { loading, invoices, invoicesDB } = invoiceService
  const {
    billingTemplate,
    getBillingTemplate,
    freeTrialExpired,
    openFreeTrialExpire,
  } = useContext(AuthContext)
  const [openModalTemplate, setOpenModalTemplate] = useState(false)
  const [openModalCreate, setOpenModalCreate] = useState(false)
  const { projectInfo } = useContext(ProjectContext)
  const [num, setNum] = useState(0)
  useEffect(() => {
    getBillingTemplate()
  }, [])
  const handleCreateNewFactura = () => {
    // if(Object.keys(billingTemplate).length === 0){
    //     setOpenModalTemplate(true)
    // }else if(billingTemplate.theme == "#ffffff"){
    //     setOpenModalTemplate(true)
    // }else{
    setOpenModalCreate(true)
    // }
  }
  return (
    <div>
      {loading && <InvoiceLoader />}
      {!loading && invoicesDB.length === 0 && (
        <div
          style={{
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            position: "relative",
            display: "flex",
            marginLeft: "-30px",
            height: "80vh",
          }}
        >
          <SquareContent style={{ width: "520px", height: "490px" }}>
            <SelectNewFactura invoiceService={invoiceService} />
          </SquareContent>
        </div>
      )}
      {!loading && invoicesDB.length > 0 ? (
        <Fragment>
          <div
            style={{ width: "100%", position: "relative", height: "60px" }}
            className="inline"
          >
            {projectId && projectInfo ? (
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "20px",
                  left: "30px",
                  position: "absolute",
                }}
              >
                {i18n.t("Facturas") + ` de ${projectInfo.name}`}
              </HelveticaBold>
            ) : (
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "20px",
                  left: "30px",
                  position: "absolute",
                }}
              >
                {i18n.t("Facturas")}
              </HelveticaBold>
            )}
            <div
              className="inline"
              style={{ position: "absolute", right: "20px" }}
            >
              <InvoicesFilter invoiceService={invoiceService} />
              {projectId ? (
                <BlueButton
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="NewInvoiceDashboard"
                  data-amplify-analytics-attrs=""
                  onClick={() => {
                    freeTrialExpired
                      ? openFreeTrialExpire()
                      : handleCreateNewFactura()
                  }}
                  className="inline"
                  style={{
                    width: "180px",
                    height: "33px",
                    textAlign: "center",
                    justifyContent: "center",
                    paddingTop: "5px",
                  }}
                >
                  <AddIcon
                    style={{
                      fontSize: "20px",
                      marginRight: "5px",
                      color: "white",
                      marginLeft: "-5px",
                      paddingTop: "1px",
                    }}
                  />
                  <HelveticaBold
                    style={{
                      color: "white",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {i18n.t("Nueva Factura")}
                  </HelveticaBold>
                </BlueButton>
              ) : (
                <YellowButton
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="NewInvoiceDashboard"
                  data-amplify-analytics-attrs=""
                  onClick={() => {
                    freeTrialExpired
                      ? openFreeTrialExpire()
                      : handleCreateNewFactura()
                  }}
                  className="inline"
                  style={{
                    width: "180px",
                    height: "33px",
                    textAlign: "center",
                    justifyContent: "center",
                    paddingTop: "5px",
                  }}
                >
                  <AddIcon
                    style={{
                      fontSize: "20px",
                      marginRight: "5px",
                      color: "#414141",
                      marginLeft: "-5px",
                      paddingTop: "1px",
                    }}
                  />
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      fontWeight: "600",
                      fontSize: "15px",
                    }}
                  >
                    {i18n.t("Nueva Factura")}
                  </HelveticaBold>
                </YellowButton>
              )}
            </div>
          </div>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              marginRight: "20px",
              marginLeft: "30px",
            }}
          >
            <InvoicesTable invoiceService={invoiceService} />
          </div>
        </Fragment>
      ) : null}
      {
        // <ModalAntesDeEmpezar open={openModalTemplate} setOpen={setOpenModalTemplate} setOpenNextStep={setOpenModalCreate} setNum={setNum}/>
      }
      <ModalCreateFactura
        open={openModalCreate}
        setOpen={setOpenModalCreate}
        invoiceService={invoiceService}
      />
    </div>
  )
}
InvoicesDashboardContainer.propTypes = {
  invoiceService: PropTypes.any,
}
export default InvoicesDashboardContainer
