import React from "react"
import { HelveticaBold } from "common/typographies.js"
import i18n from "i18n"
import { useContext, useState, useEffect } from "react"
import Capitulo from "./chapters/Capitulo"
import AuthContext from "context/auth/authContext"
import PropTypes from "prop-types"
import TopQuickMenu4 from "./topQuickMenu4"
import FavoritesBPContext from "context/favoritesBP/favoritesBPContext"
import LayoutFav from "./layoutFav"
import { CircularProgress } from "@mui/material"

const BancoPreciosRefContent = ({ tipo, referencia }) => {
  const {
    getMyBankPrices,
    myPricesBank,
    loading,
    addBankChapter,
    categoriesChecked,
    partsChecked,
    getMyBankPricesWithoutClassify,
    myNonSpecifiedPricesBank,
    cleanChapters,
    cleanParts,
  } = useContext(FavoritesBPContext)
  const { user, getUser, freeTrialExpired, openFreeTrialExpire } =
    useContext(AuthContext)
  const [cont, setCont] = useState(0)

  useEffect(() => {
    if (freeTrialExpired) {
      openFreeTrialExpire()
    }
  }, [freeTrialExpired])

  useEffect(() => {
    if (user) {
      if (cont === 0) {
        cleanChapters()
        cleanParts()
        getMyBankPrices(user.sub, tipo, referencia)
        setCont(1)
      }
    } else {
      getUser()
    }
  }, [user])

  return (
    <>
      <LayoutFav tipo={tipo} referencia={referencia} />
      {loading ? (
        <>
          <div
            style={{
              width: "100%",
              position: "absolute",
              transform: "translateY(-50%, -50%)",
              top: "40%",
              left: "45%",
            }}
          >
            <CircularProgress
              style={{
                width: 150,
                height: 150,
                marginLeft: "0px",
                color: "#414141",
                marginBottom: "20px",
              }}
            />
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "24px",
                marginLeft: "10px",
                marginTop: "0px",
              }}
            >
              {i18n.t("Cargando...")}
            </HelveticaBold>
          </div>
        </>
      ) : myPricesBank.subcapitulos.length > 0 ? (
        <>
          <div style={{ marginLeft: "30px" }}>
            {myPricesBank.subcapitulos.map((capitulo, i) => (
              <Capitulo
                key={capitulo.chapterId}
                i={i}
                capitulo={capitulo}
                indice={i}
                tipo={tipo}
                referencia={referencia}
                soyRoot={true}
              />
            ))}
          </div>
        </>
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <HelveticaBold
            style={{ paddingTop: "100px", color: "#414141", fontSize: "22px" }}
          >
            No tienes capitulos añadidos en favoritos
          </HelveticaBold>
        </div>
      )}
      {categoriesChecked.length > 0 ? (
        <TopQuickMenu4 tipo={tipo} />
      ) : partsChecked.length > 0 ? (
        <TopQuickMenu4 tipo={tipo} />
      ) : null}
    </>
  )
}

BancoPreciosRefContent.propTypes = {
  tipo: PropTypes.string.isRequired,
  referencia: PropTypes.string.isRequired,
}

export default BancoPreciosRefContent
