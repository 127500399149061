import React, { useEffect, useContext } from "react"
import { PoppinsNormal } from "common/typographies"
import CapituloModal from "./CapituloModal"
import PropTypes from "prop-types"
import i18n from "i18n"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext"
import AuthContext from "context/auth/authContext"
import TemplatesContext from "context/templates/templatesContext"
import { useParams } from "react-router-dom"

const PrincipalChapters = ({
  chaptersToShow,
  loadingSearch,
  estoyEnBusqueda,
  setChaptersToShow,
  loadingTree,
  bankSelected,
  tipo,
  disabled,
  setDisabled,
  loadingButton,
  setLoadingButton,
  doTheAction,
  setDoTheAction,
  setOpen,
  capitulo,
}) => {
  const {
    checkPartida,
    updatePartsList,
    checkMultipleParts,
    partsChecked,
    cleanParts,
  } = useContext(
    bankSelected == "User database"
      ? BankPricesContext
      : BankPricesReferenceContext,
  )
  const { createPartsSearcher } = useContext(TemplatesContext)
  const { user } = useContext(AuthContext)
  const { templateId } = useParams()

  useEffect(() => {
    if (doTheAction && !disabled) {
      setLoadingButton(true)
      const createParts = async () => {
        await createPartsSearcher(
          user.sub,
          partsChecked,
          capitulo.chapterId,
          templateId,
          capitulo,
        )
        cleanParts()
        setDisabled(true)
        setDoTheAction(false)
        setLoadingButton(false)
        setOpen(false)
      }
      createParts()
    }
  }, [doTheAction])

  useEffect(() => {
    if (partsChecked.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [partsChecked.length])
  return (
    <div
      style={{
        height: "60vh",
        paddingBottom: "10px",
        overflowY: "auto",
        minHeight: "290px",
        position: "relative",
        justifyContent: "center",
      }}
    >
      {loadingTree || loadingSearch ? (
        <>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-100px",
              marginTop: "0px",
            }}
          >
            <div className="loader">
              <div className="ball"></div>
              <div className="ball"></div>
              <div className="ball"></div>
            </div>
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-30px",
            }}
          >
            <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-30px",
              marginTop: "5px",
            }}
          >
            <PoppinsNormal style={{ fontWeight: "400" }}>
              {i18n.t("Esta acción puede tardar unos segundos")}
            </PoppinsNormal>
          </div>
        </>
      ) : chaptersToShow.length > 0 ? (
        chaptersToShow[chaptersToShow.length - 1].subcapitulos.length > 0 ? (
          chaptersToShow[chaptersToShow.length - 1].subcapitulos.map(
            (subcapitulo, index) => {
              return (
                <CapituloModal
                  estoyEnBusqueda={estoyEnBusqueda}
                  key={index}
                  capitulo={subcapitulo}
                  setChaptersToShow={setChaptersToShow}
                  chaptersToShow={chaptersToShow}
                  width={96}
                  ml={20}
                  numeroArray={0}
                  bankSelected={bankSelected}
                  tipo={tipo}
                  disabled={disabled}
                  setDisabled={setDisabled}
                  loadingButton={loadingButton}
                  setLoadingButton={setLoadingButton}
                  doTheAction={doTheAction}
                  setDoTheAction={setDoTheAction}
                  setOpen={setOpen}
                />
              )
            },
          )
        ) : (
          <PoppinsNormal style={{ marginLeft: "360px", marginTop: "30px" }}>
            {i18n.t("No hay capítulos disponibles")}
          </PoppinsNormal>
        )
      ) : (
        <PoppinsNormal style={{ marginLeft: "360px", marginTop: "30px" }}>
          {i18n.t("No hay capítulos disponibles")}
        </PoppinsNormal>
      )}
    </div>
  )
}

PrincipalChapters.propTypes = {
  chaptersToShow: PropTypes.array.isRequired,
  setChaptersToShow: PropTypes.func.isRequired,
  loadingTree: PropTypes.bool.isRequired,
  bankSelected: PropTypes.string,
  tipo: PropTypes.string,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  loadingButton: PropTypes.bool,
  setLoadingButton: PropTypes.func,
  doTheAction: PropTypes.bool,
  setDoTheAction: PropTypes.func,
  setOpen: PropTypes.func,
  estoyEnBusqueda: PropTypes.bool,
  loadingSearch: PropTypes.bool,
  capitulo: PropTypes.object,
}

export default PrincipalChapters
