import React, { useState } from "react"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import i18n from "i18n"
import { Form, Card, Row, Col } from "reactstrap"
import UploadRoundedIcon from "@mui/icons-material/UploadRounded"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { HelveticaBold } from "common/typographies"
import { YellowButton } from "common/buttons"
import CloseIcon from "@mui/icons-material/Close"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    minHeight: "370px",
    height: "auto",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalSelectFiles = ({
  selectedFiles,
  setSelectedFiles,
  open,
  setOpen,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [selectedFilesAux, setSelectedFilesAux] = useState(selectedFiles)

  function handleAcceptedFiles(files) {
    let aux = files.map(file => {
      let extension = file.path.substr(file.path.length - 3)
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setSelectedFilesAux([...selectedFilesAux, ...aux])
  }

  const removeFile = f => {
    let filteredArray = selectedFilesAux.filter(item => item !== f)
    setSelectedFilesAux(filteredArray)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSave = () => {
    setSelectedFiles(selectedFilesAux)
    setOpen(false)
  }
  return (
    <Modal open={open} onClose={() => null}>
      <div style={modalStyle} className={classes.paper}>
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
            position: "relative",
            marginTop: "10px",
          }}
        >
          <HelveticaBold style={{ color: "#414141", fontSize: "18px" }}>
            {i18n.t("Sube aqui tus archivos")}
          </HelveticaBold>
        </div>

        <Form>
          <Dropzone
            className="dropzone"
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles)
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                className="dropzone2"
                style={{ minHeight: "100px", width: "400px" }}
              >
                <div
                  className="dz-message needsclick mt-2"
                  {...getRootProps()}
                  style={{ padding: "10px" }}
                >
                  <input {...getInputProps()} />
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "9px",
                    }}
                  >
                    {i18n.t("Arrastra archivos o explora tu ordenador")}
                  </p>
                  <div className="mb-3">
                    <UploadRoundedIcon
                      style={{
                        height: "60px",
                        width: "100px",
                        marginTop: "20px",
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontWeight: "300",
                      fontSize: "14px",
                      lineHeight: "16px",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    {i18n.t(
                      "El formato en el que deberás cargar los archivos debe ser",
                    )}
                    .JPG, .PNG o .PDF
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFilesAux.map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <i className="bx bx-file" />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {f.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{f.formattedSize}</strong>
                        </p>
                      </Col>
                      <CloseIcon
                        style={{
                          marginRight: "1px",
                          width: "45px",
                          marginTop: "-20px",
                        }}
                        onClick={() => {
                          removeFile(f)
                        }}
                      />
                    </Row>
                  </div>
                </Card>
              )
            })}
          </div>
          <div
            style={{
              width: "100%",
              justifyContent: "center",
              textAlign: "center",
              position: "relative",
              marginTop: "10px",
            }}
          >
            <YellowButton
              onClick={() => {
                handleSave()
              }}
              style={{ fontSize: "16px", width: "150px", marginTop: "10px" }}
            >
              {i18n.t("Subir")}
            </YellowButton>
          </div>
        </Form>
      </div>
    </Modal>
  )
}
ModalSelectFiles.propTypes = {
  selectedFiles: PropTypes.array,
  setSelectedFiles: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}
export default ModalSelectFiles
