import useLocalStorage from "hooks/useLocalStorage"

export const DASHBOARD_CONFIG = "dashboardConfig"

export const DASHBOARD_FILTER = "filter"
export const DASHBOARD_COLUMN_VISIBILITY = "columnVisibility"
export const DASHBOARD_ORDERED_FIELDS = "orderedFields"
export const DASHBOARD_SEARCH = "search"
export const DASHBOARD_SORT = "sort"

/**
 * Custom hook for managing dashboard configuration using local storage.
 *
 * @constant {string} DASHBOARD_CONFIG - The key used to store dashboard configuration in local storage.
 * @constant {string} DASHBOARD_FILTER - The key for the filter configuration.
 * @constant {string} DASHBOARD_COLUMN_VISIBILITY - The key for the column visibility settings.
 * @constant {string} DASHBOARD_ORDERED_FIELDS - The key for the ordered fields configuration.
 * @constant {string} DASHBOARD_SEARCH - The key for the search query.
 * @constant {string} DASHBOARD_SORT - The key for the sorting configuration.
 *
 * @returns {[Object, Function]} Returns an array containing the dashboard configuration and a function to update it.
 *
 * @property {Object} dashboardConfig - The current configuration of the dashboard.
 * @property {Object} dashboardConfig.filter - The filter settings, containing an array of items.
 * @property {Object} dashboardConfig.columnVisibility - The visibility configuration for dashboard columns.
 * @property {string[]} dashboardConfig.orderedFields - The ordered fields of the dashboard.
 * @property {string} dashboardConfig.search - The current search query.
 * @property {Array} dashboardConfig.sort - The current sorting configuration.
 *
 * @property {Function} updateConfig - A function to update specific parts of the dashboard configuration.
 * @param {string} key - The key of the configuration property to update.
 * @param {any} value - The new value to set for the specified configuration key.
 */
const useDashboardConfig = () => {
  const [dashboardConfig, setDashboardConfig] = useLocalStorage(
    DASHBOARD_CONFIG,
    {
      [DASHBOARD_FILTER]: { items: [] },
      [DASHBOARD_COLUMN_VISIBILITY]: {},
      [DASHBOARD_ORDERED_FIELDS]: [],
      [DASHBOARD_SEARCH]: "",
      [DASHBOARD_SORT]: [],
    },
  )

  const updateConfig = (key, value) => {
    setDashboardConfig(prevConfig => {
      return {
        ...prevConfig,
        [key]: value,
      }
    })
  }

  return [dashboardConfig, updateConfig]
}

export default useDashboardConfig
