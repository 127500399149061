import React, { useState } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { getCurrency } from "utils/functions"

const TableStructureBank = ({ children, imParent, parts, capitulo }) => {
  const [openPart, setOpenPart] = useState(false)
  const createPart = () => {
    setOpenPart(true)
  }
  return (
    <>
      <div id="table-wrapper">
        <div id="table-scroll">
          <table
            className="tablaPartidas"
            style={{
              marginLeft: imParent ? "0px" : "-8px",
              width: imParent ? "100%" : "100.5%",
              marginTop: imParent ? "0px" : "-10px",
              marginBottom: imParent ? "0px" : "-10px",
            }}
          >
            <tr style={{ borderBottom: " solid #E9ECEF" }}>
              <th
                style={{
                  minWidth: "78px",
                  paddingLeft: "15px",
                  borderLeft: "none",
                }}
              ></th>
              <th
                style={{
                  minWidth: "300px",
                  textAlign: "left",
                  paddingLeft: "57px",
                  borderLeft: "none",
                  width: "100%",
                }}
              ></th>
              <th
                colSpan="2"
                style={{
                  minWidth: "171px",
                  backgroundColor: "white",
                  color: "rgb(183, 183, 183)",
                }}
              >
                {i18n.t("Histórico")}
              </th>
              <th
                style={{
                  minWidth: "150px",
                  color: "#c4c4c4",
                  paddingLeft: "15px",
                }}
              >
                {i18n.t("Precio Unitario")}
              </th>
            </tr>

            <tr id="subCategory">
              <td
                style={{ borderLeft: "none", width: "79px", color: "#B7B7B7" }}
              >
                {i18n.t("Unidad")}
              </td>
              <td
                style={{
                  textAlign: "left",
                  width: "100%",
                  paddingLeft: "35px",
                }}
              >
                {i18n.t("Descripción")}
              </td>

              <td
                style={{
                  minWidth: "86px",
                  backgroundColor: "white",
                  color: "rgb(183, 183, 183)",
                }}
              >
                {" "}
                {getCurrency(user["custom:currency"])}/u
              </td>
              <td
                style={{
                  minWidth: "86px",
                  borderLeft: "1px solid #F3F1F1",
                  backgroundColor: "white",
                  color: "rgb(183, 183, 183)",
                }}
              >
                {i18n.t("Total")}
              </td>
              <td
                style={{
                  minWidth: "127px",
                  color: "#c4c4c4",
                  backgroundColor: "white",
                }}
              >
                {" "}
                {getCurrency(user["custom:currency"])}/u
              </td>
              <td
                style={{
                  minWidth: "35px",
                  maxWidth: "35px",
                  color: "#c4c4c4",
                  backgroundColor: "white",
                  borderLeft: "none",
                }}
              ></td>
            </tr>

            {children}
          </table>
        </div>
      </div>
    </>
  )
}

TableStructureBank.propTypes = {
  children: PropTypes.node.isRequired,
  imParent: PropTypes.bool,
  parts: PropTypes.array,
  capitulo: PropTypes.object,
}

export default TableStructureBank
