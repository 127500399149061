import React from "react"
import { ReactComponent as MaterialIcon } from "assets/customIcons/materiales.svg"
import { ReactComponent as ManodeobraIcon } from "assets/customIcons/manodeobra.svg"
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"
import RecyclingIcon from "@mui/icons-material/Recycling"
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined"
import NoAccountsIcon from "@mui/icons-material/NoAccounts"

const ItemTypes = Object.freeze({
  0: "Sin clasificar",
  1: "Mano de obra",
  2: "Maquinaria y medios auxiliares",
  3: "Materiales",
  4: "Componentes adicionales de residuo",
  5: "Clasificación de residuo",
  6: "Otros",
  7: "Otros",
})

const UnityEnum = Object.freeze({
  pa: "pa",
  m: "m",
  ml: "ml",
  "m²": "m²",
  "m³": "m³",
  ud: "ud",
  kg: "kg",
  t: "t",
  l: "l",
  h: "h",
})

const itemTypesWithIcons = [
  {
    id: 0,
    type: "Sin clasificar",
    icon: (
      <NoAccountsIcon
        className="me-2"
        style={{
          width: "20px",
          color: "#c4c4c4",
          height: "20px",
          marginLeft: "1px",
        }}
      />
    ),
  },
  {
    id: 1,
    type: "Mano de obra",
    icon: <ManodeobraIcon className="me-2" />,
  },
  {
    id: 2,
    type: "Maquinaria y medios auxiliares",
    icon: (
      <LocalShippingOutlinedIcon
        className="me-2"
        style={{
          width: "20px",
          height: "20px",
          marginLeft: "1px",
          color: "#c4c4c4",
        }}
      />
    ),
  },
  {
    id: 3,
    type: "Materiales",
    icon: <MaterialIcon className="me-2" />,
  },
  {
    id: 4,
    type: "Componentes adicionales de residuo",
    icon: (
      <RecyclingIcon
        className="me-2"
        style={{
          width: "20px",
          height: "20px",
          marginLeft: "1px",
          color: "#c4c4c4",
        }}
      />
    ),
  },
  {
    id: 5,
    type: "Clasificación de residuo",
    icon: (
      <AutoDeleteOutlinedIcon
        className="me-2"
        style={{
          width: "20px",
          color: "#c4c4c4",
          height: "20px",
          marginLeft: "1px",
        }}
      />
    ),
  },
]

export { itemTypesWithIcons, UnityEnum, ItemTypes }
