import {
  GET_TASKS_BY_PROJECT,
  ADD_COLUMN,
  DELETE_COLUMN,
  UPDATE_COLUMN,
  UPDATE_TASK,
  CREATE_GANTT,
  UPDATE_FILTER,
} from "../types"
const PlanningReducer = (state, action) => {
  switch (action.type) {
    case GET_TASKS_BY_PROJECT:
      return {
        ...state,
        tasks: action.payload,
      }
    case CREATE_GANTT:
      return {
        ...state,
        gantt: action.payload.gantt,
        tree: action.payload.ganttTree,
        dependencias: action.payload.dependencies,
      }
    case ADD_COLUMN:
      var aux =
        action.payload.kanbanStatus[action.payload.kanbanStatus.length - 1]

      aux = { ...aux, cards: [] }
      state.tasks.push(aux)

      return {
        ...state,
      }
    case DELETE_COLUMN:
      let i = 0
      let trobat = false
      while (!trobat && state.tasks.length > i) {
        if (state.tasks[i].id == action.payload) {
          state.tasks.splice(i, 1)
          trobat = true
        } else {
          i = i + 1
        }
      }

      return { ...state }
    case UPDATE_COLUMN:
      let indice = 0
      let trobat2 = false
      while (!trobat2 && state.tasks.length > indice) {
        if (state.tasks[indice].id == action.payload.id) {
          state.tasks[indice].title = action.payload.title
          state.tasks[indice].color = action.payload.color
          state.tasks[indice].bgColor = action.payload.bgColor
          trobat2 = true
        } else {
          indice = indice + 1
        }
      }

      return { ...state }
    case UPDATE_TASK:
      let index = 0
      let find = false
      while (!find && index < state.tasks.length) {
        let j = 0
        let find2 = false
        while (!find2 && j < state.tasks[index].cards.length) {
          if (state.tasks[index].cards[j].taskId == action.payload.res.taskId) {
            state.tasks[index].cards.splice(j, 1)

            find2 = true
          } else {
            j = j + 1
          }
        }
        if (find2 == true) {
          find = true
        } else {
          index = index + 1
        }
      }
      state.tasks[action.payload.destination.toColumnId - 1].cards.splice(
        action.payload.destination.toPosition,
        0,
        action.payload.res,
      )
      return { ...state }
    case UPDATE_FILTER:
      state.filtro = action.payload
      return { ...state, filtro: [...state.filtro, action.payload] }
    default:
      return state
  }
}
export default PlanningReducer
