import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { HelveticaBold, PoppinsNormal } from "common/typographies"
import sadBlueFace from "assets/customIcons/sadBlueFace.svg"
function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "340px",
    height: "300px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const MobileModalNew = ({ setOpen, open }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  return (
    <>
      <Modal open={open}>
        <div style={modalStyle} className={classes.paper}>
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "block",
              marginLeft: "10%",
              marginRight: "10%",
              marginTop: "10%",
            }}
          >
            <img
              src={sadBlueFace}
              style={{ width: "60px", height: "60px", marginBottom: "10px" }}
            />
            <HelveticaBold
              style={{ fontWeight: "700", fontSize: "32px", color: "#414141" }}
            >
              {i18n.t("¡Lo sentimos!")}
            </HelveticaBold>
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#414141",
                lineHeight: "16px",
              }}
            >
              Por el momento Planhopper{" "}
              <b>solo está disponible para ordenador</b>, te invitamos a abrirlo
              en tu navegador y explora todo su potencial.
            </HelveticaBold>
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#414141",
                lineHeight: "16px",
              }}
            >
              Si estas usando una tablet, puedes probar de usarla en modo
              horizontal.
            </HelveticaBold>
          </div>
        </div>
      </Modal>
    </>
  )
}
MobileModalNew.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
export default MobileModalNew
