import React, { useContext, useEffect, useState } from "react"
import { CircularProgress, Modal } from "@mui/material"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import deleteCap from "assets/images/budgeting/deleteCap.png"
import i18n from "i18n"
import Budget2023Context from "context/budget2023/Budget2023Context"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "630px",
    height: "470px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalDeleteCap = ({
  openEliminate,
  setOpenEliminate,
  infoCap,
  soyFinal,
  modificado,
  setClose,
  chapterList,
}) => {
  // Configuración del modal para añadir contactos
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const {
    deleteCapituloAndSetPosition,
    budgetTree,
    getSelectedBudget2,
    selectedChaptersGroup,
    chaptersChecked,
    cleanChapters,
  } = useContext(Budget2023Context)
  const [listaAux, setLista] = useState([])
  const [loading, setLoading] = useState(false)
  const onClose = () => {
    setOpenEliminate(false)
  }
  useEffect(() => {
    if (openEliminate && !chapterList) {
      getSelectedBudget2([], budgetTree.subcapitulos)
    }
  }, [openEliminate])

  useEffect(() => {
    if (selectedChaptersGroup) {
      setLista(selectedChaptersGroup)
    }
  }, [selectedChaptersGroup])
  const deleteChapterFunc = async () => {
    //La  info  del  capitulo se pasa diferente si es no es root por lo que se hace un if
    let projectId = ""
    setLoading(true)
    if (chapterList) {
      if (chaptersChecked.length > 0) {
        for (let index = 0; index < chaptersChecked.length; index++) {
          await deleteCapituloAndSetPosition(
            chaptersChecked[index].chapterId,
            chaptersChecked[index].projectId,
          )
        }
      }
      await cleanChapters()
      setLoading(false)
      setOpenEliminate(false)
    } else {
      if (infoCap.capituloInfo == undefined) {
        projectId = infoCap.projectId
      } else {
        projectId = infoCap.capituloInfo.projectId
      }
      if (listaAux.length == 0) {
        await deleteCapituloAndSetPosition(
          infoCap.chapterId,
          projectId,
          soyFinal,
        )
        setLoading(false)
        if (modificado == 1) {
          setClose(1)
        }
        setOpenEliminate(false)
      } else {
        for (let index = 0; index < listaAux.length; index++) {
          await deleteCapituloAndSetPosition(
            listaAux[index].chapterId,
            projectId,
            soyFinal,
          )
        }
        cleanChapters()
        setLoading(false)
        if (modificado == 1) {
          setClose(1)
        }
        setOpenEliminate(false)
      }
    }
  }

  return (
    <>
      <Modal open={openEliminate} onClose={onClose}>
        <div style={modalStyle} className={classes.paper}>
          <div id="centerPaco">
            <img src={deleteCap} style={{ width: "95px", height: "144px" }} />
          </div>

          <div
            id="DivTitleEliminar2"
            style={{
              width: "100%",
              textAlign: "center",
              display: "block",
              marginLeft: "0PX",
            }}
          >
            <p id="centerTitleEliminar" style={{ marginTop: "40px" }}>
              {i18n.t("Estás a punto de eliminar este Capítulo")}
            </p>
          </div>

          <div
            id="DivTitleEliminar2"
            style={{
              width: "100%",
              textAlign: "center",
              display: "block",
              marginLeft: "0px",
            }}
          >
            <p id="subtitledelete">
              {" "}
              {i18n.t("Eliminarás los Capítulos seleccionados")} <br />{" "}
              {i18n.t("No podrás recuperar este apartado una vez eliminado.")}{" "}
            </p>
          </div>
          <div id="centerButton">
            <div
              id="DivTitleEliminar2"
              style={{
                marginLeft: "70px",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <>
                  <button
                    type="button"
                    style={{
                      width: "190px",
                      marginRight: "15px",
                      borderRadius: "6px",
                    }}
                    className="CancelDel"
                    onClick={onClose}
                  >
                    {i18n.t("Cancelar")}
                  </button>
                  <button
                    type="button"
                    style={{
                      width: "190px",
                      position: "initial",
                      marginTop: "0px",
                      color: "white",
                      backgroundColor: "#BF2929",
                    }}
                    className="AddProject"
                    id="DeleteButton"
                    onClick={deleteChapterFunc}
                  >
                    {i18n.t("Eliminar")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

ModalDeleteCap.propTypes = {
  openEliminate: PropTypes.bool.isRequired,
  setOpenEliminate: PropTypes.func.isRequired,
  infoCap: PropTypes.object.isRequired,
  soyFinal: PropTypes.bool,
  modificado: PropTypes.number,
  setClose: PropTypes.func,
  chapterList: PropTypes.bool,
}

export default withRouter(ModalDeleteCap)
