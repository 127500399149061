import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

const Part = ({ part }) => {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    if (part) {
      setTotal(
        parseFloat(parseFloat(part.quantity) * part.userPrice).toFixed(2),
      )
    }
  }, [])

  return (
    <tr>
      <td style={{ fontFamily: "Helvetica" }}>{part.unity}</td>
      <td style={{ fontWeight: "300" }}>{part.description}</td>
      <td style={{ paddingLeft: "23px", fontFamily: "Helvetica" }}>
        {part.quantity}
      </td>
      <td style={{ fontFamily: "Helvetica" }}>{part.userPrice}</td>
      <td style={{ fontFamily: "Helvetica" }}>{total}</td>
    </tr>
  )
}
Part.propTypes = {
  part: PropTypes.object.isRequired,
}
export default Part
