import React, { useState, useContext, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useParams } from "react-router-dom"
import ProjectContext from "context/projects/projectContext"
import Presupuesto from "../../components/Project/Overview/Presupuesto"
import { Container } from "reactstrap"
import { RecordPageView } from "App"
const Budgeting = () => {
  RecordPageView()
  const { projectId } = useParams()
  const [activeTab, setactiveTab] = useState("1")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }
  const projectContext = useContext(ProjectContext)
  const { getProject, projectInfo, createChapter } = projectContext
  useEffect(() => {
    getProject(projectId)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper | Proyecto </title>
        </MetaTags>
        <Container fluid>
          {projectInfo ? <Presupuesto projectInfo={projectInfo} /> : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Budgeting
