import React from "react"
import PropTypes from "prop-types"
import { Modal, Box, Typography, Button } from "@mui/material"
import i18n from "i18n"

const ConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  title = i18n.t("common.confirmAction"),
  message = i18n.t("common.confirmActionDefaultMessage"),
  confirmButtonText = i18n.t("buttons.confirm"),
  cancelButtonText = i18n.t("buttons.cancel"),
  confirmButtonColor = "inherit",
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-modal-title"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography
          id="confirmation-modal-title"
          variant="h6"
          component="h2"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {message}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button
            variant="contained"
            color={confirmButtonColor}
            onClick={onConfirm}
          >
            {confirmButtonText}
          </Button>
          <Button variant="outlined" onClick={onClose}>
            {cancelButtonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired, // Modal open state
  onClose: PropTypes.func.isRequired, // Function to close the modal
  onConfirm: PropTypes.func.isRequired, // Function to handle confirmation
  title: PropTypes.string, // Modal title
  message: PropTypes.string, // Modal message
  confirmButtonText: PropTypes.string, // Confirm button text
  cancelButtonText: PropTypes.string, // Cancel button text
  confirmButtonColor: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "success",
    "error",
    "info",
    "warning",
  ]), // Confirm button color
}

export default ConfirmationModal
