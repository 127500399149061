import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import ModalEditar from "./modalEditar"
import ModalEliminar from "./modalEliminar"

const DropdownOptionsContact = props => {
  const [menu, setMenu] = useState(false)
  const { user, getUser, freeTrialExpired, openFreeTrialExpire } =
    useContext(AuthContext)
  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])
  const [openEditar, setOpenEditar] = useState(false)
  const [openEliminate, setOpenEliminate] = useState(false)

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
        >
          <DropdownToggle
            id="settingb"
            style={{
              marginTop: "-10px",
              color: "black",
              margin: "0px",
              padding: "0px",
            }}
          >
            <MoreHorizIcon id="settingsBud" style={{ color: "#414141" }} />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {(user && user.freeTrialExpired) ||
            user["custom:role"] === "WORKER" ? null : (
              <>
                <DropdownItem
                  tag="d"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    freeTrialExpired
                      ? openFreeTrialExpire()
                      : setOpenEditar(true)
                  }}
                >
                  {" "}
                  <i
                    style={{ marginTop: "-2px" }}
                    className="bx bx-pencil font-size-16 align-middle me-1"
                  />
                  {i18n.t("Editar")}
                </DropdownItem>
                <DropdownItem
                  tag="d"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenEliminate(true)
                  }}
                >
                  {" "}
                  <i
                    style={{ marginTop: "-2px" }}
                    className="bx bx-trash font-size-16 align-middle me-1"
                  />
                  {i18n.t("Eliminar")}
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
      {openEditar && (
        <ModalEditar
          open={openEditar}
          setOpen={setOpenEditar}
          selectedContact={props.selectedContact}
        />
      )}
      {openEliminate && (
        <ModalEliminar
          openEliminate={openEliminate}
          setOpenEliminate={setOpenEliminate}
          selectedContact={props.selectedContact}
        />
      )}
    </>
  )
}

DropdownOptionsContact.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  selectedContact: PropTypes.object,
}

export default DropdownOptionsContact
