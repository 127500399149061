import MetaTags from "react-meta-tags"
import React, { useLayoutEffect, useContext } from "react"
import AuthContext from "../../context/auth/authContext"
import { useHistory } from "react-router-dom"
import { Container } from "reactstrap"
import { withRouter } from "react-router-dom"
import OnboardingSurvey from "components/Typeform/OnboardingSurvey"
import ilustracion from "assets/customIcons/ilustracion.svg"
import logoLarge from "assets/customIcons/logos/planhopper_logo_large.svg"

const SurveyTypeForm = props => {
  const { getUser } = useContext(AuthContext)
  const history = useHistory()

  useLayoutEffect(() => {
    let userAuth = JSON.parse(localStorage.getItem("userAuth"))
    if (userAuth) {
      userAuth["custom:onboarding"] === "true"
        ? history.push("/dashboard")
        : null
    } else {
      getUser()
    }
  }, [])
  return (
    <React.Fragment>
      <div
        className="LayoutHubspot"
        style={{
          zIndex: "999",
          width: "102%",
          backgroundColor: "#E5E5E5",
          marginLeft: "-80px",
          height: "106vh",
          marginTop: "-40px",
          paddingTop: "120px",
          position: "absolute",
          overflow: "hidden",
        }}
      >
        <MetaTags>
          <title>Planhopper | Bienvenida</title>
        </MetaTags>
        <div className="inline">
          <a
            href="https://www.planhopper.com"
            target="_blank"
            rel="noreferrer"
            style={{ zIndex: "99" }}
          >
            <img
              className="logoPositioning"
              style={{ top: "8%" }}
              src={logoLarge}
              width="147px"
              height="45px"
            />
          </a>
        </div>
        <Container fluid>
          <OnboardingSurvey />
          <img
            className="AnimationHubspot"
            src={ilustracion}
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "360px",
              marginLeft: "-17px",
              bottom: "-12px",
              position: "absolute",
              zIndex: "-1",
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(SurveyTypeForm)
