import React from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import RowPart from "./RowPart"
import { partPropTypes } from "common/CPart"

const PartTable = ({ parts, chapterIndex }) => {
  return (
    <div className="mt-2 mb-2 backgroundWhite">
      <table className="tablaPartidas">
        <thead>
          <tr className="tableBorder">
            <th colSpan="7" className="tableBorderRight"></th>
            <th colSpan="2" className="tableBorderRight">
              {i18n.t("certHistory.objetivePrice")}
            </th>
            <th colSpan="6">{i18n.t("certHistory.clientPrice")}</th>
          </tr>
          <tr className="tableBorder">
            <th colSpan="1" className="tableBorderRight2 w-4-percent">
              {i18n.t("certHistory.ref")}
            </th>
            <th colSpan="4" className="tableBorderRight2 w-38-percent">
              {i18n.t("certHistory.description")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-4-percent">
              {i18n.t("certHistory.unit")}
            </th>
            <th colSpan="1" className="tableBorderRight w-6-percent">
              {i18n.t("certHistory.measures")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.pricePerUnit")}
            </th>
            <th colSpan="1" className="tableBorderRight w-6-percent">
              {i18n.t("certHistory.clientTotalPrice")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.pricePerUnit")}
            </th>
            <th colSpan="2" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.clientTotalPrice")}
            </th>
          </tr>
        </thead>
        <tbody>
          {parts && parts.length > 0
            ? parts.map((part, index) => {
                return (
                  <RowPart
                    key={part.partId}
                    part={part}
                    index={index}
                    chapterIndex={chapterIndex}
                  />
                )
              })
            : null}
        </tbody>
      </table>
    </div>
  )
}

PartTable.propTypes = {
  parts: PropTypes.arrayOf(partPropTypes),
  chapterIndex: PropTypes.number.isRequired,
}

export default PartTable
