import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import SelectBDType from "../selectBDType"
import { CloseOutlined } from "@mui/icons-material"
function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "570px ",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "600px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const ModalImportAll = ({ open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseOutlined
            onClick={() => {
              setOpen(false)
            }}
            style={{ position: "absolute", cursor: "pointer", right: "10px" }}
          />
          <SelectBDType />
        </div>
      </Modal>
    </>
  )
}
ModalImportAll.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}
export default ModalImportAll
