import React, { useContext, useState } from "react"
import { API, Auth } from "aws-amplify"
import { Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import ContactContext from "context/contact/contactContext"
import i18n from "i18n"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "630px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    height: "470px",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalEliminar = ({
  openEliminate,
  setOpenEliminate,
  selectedContact,
}) => {
  // Configuración del modal para añadir contactos
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const contactContext = useContext(ContactContext)
  const { getContacts } = contactContext

  async function deleteContact() {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/contact"
      const myInit = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          id: selectedContact.id,
        },
      }
      const res = await API.del(apiName, path, myInit)
      getContacts()
      setOpenEliminate(false)
      //location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  const onClose = () => {
    setOpenEliminate(false)
  }

  return (
    <>
      <Modal open={openEliminate} onClose={onClose}>
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon id="cerrar" onClick={onClose}></CloseIcon>
          <div id="DivTitleEliminar">
            <p id="centerTitle2">
              {i18n.t(
                "¿Estás seguro de que deseas eliminar a este contacto de tu lista?",
              )}
            </p>
          </div>
          <div id="divFotoEliminar">
            <img
              id="fotoEliminar"
              className="rounded-circle header-profile-user"
              src="/static/media/builder.6fffb110.png"
              alt="Header Avatar"
            />
          </div>
          <div id="confirmDelete">{selectedContact["completeName"]}</div>
          <div
            id="DivTitleEliminar2"
            style={{
              width: "100%",
              textAlign: "center",
              display: "block",
              marginLeft: "0px",
            }}
          >
            <p>
              {i18n.t(
                "Este contacto será removido de tu lista de contactos y no podás rehacer esta acción.",
              )}
            </p>
          </div>
          <div id="centerButton">
            <button
              type="button"
              style={{ color: "white", backgroundColor: "#BF2929" }}
              onClick={async () => {
                await deleteContact()
              }}
              className="AddContact"
            >
              {i18n.t("Eliminar")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

ModalEliminar.propTypes = {
  openEliminate: PropTypes.bool.isRequired,
  setOpenEliminate: PropTypes.func.isRequired,
  selectedContact: PropTypes.object.isRequired,
}
export default withRouter(ModalEliminar)
