import { Auth, API } from "aws-amplify"
import { useState, useLayoutEffect, useContext, useEffect, useRef } from "react"
import AuthContext from "context/auth/authContext"
import { useHistory } from "react-router-dom"
import { isLocalhost } from "utils/functions"

export const useLogin = () => {
  const { user, getUser, Login, errorLogin } = useContext(AuthContext)
  const [mailError, setMailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [passwordMessage, setPasswordMessage] = useState("")
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [cont, setCont] = useState(0) //Es un contador para recargar el useEffect por si sale el mismo error mas de una vez

  useLayoutEffect(() => {
    let userAuth = localStorage.getItem("userAuth")
    if (userAuth) {
      history.push("/dashboard")
    } else {
      getUser()
    }
  }, [])

  useEffect(() => {
    if (user) {
      user["custom:onboarding"] === "true"
        ? history.push("/dashboard")
        : history.push("/onboarding")
    }
  }, [user])

  useEffect(() => {
    if (errorLogin.estado) {
      if (errorLogin.mensaje === "Incorrect username or password.") {
        setPasswordMessage("La contraseña es incorrecta")
        setPasswordError(true)
      } else if (
        errorLogin.mensaje ===
        "Error - Account already exists with external provider"
      ) {
        setErrorMessage("Ya existe una cuenta vinculada a un proveedor externo")
        setMailError(true)
      } else if (
        errorLogin.mensaje === "Error - Account already exists with Planhopper"
      ) {
        setErrorMessage("Ya existe una cuenta con este correo asociado")
        setMailError(true)
      } else if (
        errorLogin.mensaje ==
        "PreAuthentication failed with error User not found."
      ) {
        setErrorMessage(
          "No se ha encontrado este usuario en nuestra base de datos",
        )
        setMailError(true)
      } else if ((errorLogin.mensaje = "Password attempts exceeded")) {
        setPasswordMessage(
          "Ha superado el número de intentos de inicio de sesión, por favor intentelo de nuevo mas tarde",
        )
        setPasswordError(true)
      } else if ((errorLogin.mensaje = "User is disabled.")) {
        setErrorMessage(
          "Tu acceso ha sido solicitado, nos pondremos en contacto contigo en breve",
        )
        setMailError(true)
      }
    }
  }, [errorLogin.estado, errorLogin.mensaje, cont])

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email)
  }

  const handleChange = e => {
    if (e.target.name === "email") {
      setEmail(e.target.value)
      setErrorMessage("")
      setMailError(false)
    }
    if (e.target.name === "password") {
      setPassword(e.target.value)
      setPasswordMessage("")
      setPasswordError(false)
    }
  }

  const handleLogin = async () => {
    setLoading(true)
    if (email === "" || password === "") {
      if (email === "") {
        setMailError(true)
        setErrorMessage("El correo no puede estar vacío")
      } else {
        setMailError(false)
        setErrorMessage("")
      }
      if (password === "") {
        setPasswordError(true)
        setPasswordMessage("La contraseña no puede estar vacía")
      } else {
        setPasswordError(false)
        setPasswordMessage("")
      }
      setLoading(false)
    } else if (!isValidEmail(email)) {
      setMailError(true)
      setErrorMessage("El correo no es válido")
      setLoading(false)
    } else {
      setCont(cont + 1)
      await Login({ email: email, password: password })
      setLoading(false)
    }
  }

  return {
    isLocalhost,
    history,
    errorMessage,
    mailError,
    passwordError,
    passwordMessage,
    email,
    password,
    loading,
    handleLogin,
    handleChange,
  }
}
