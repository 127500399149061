import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { PoppinsNormal } from "common/typographies"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"
import addProject from "assets/images/budgeting/addProject.png"
import CreateProject4Invoice from "../modals/createProjectModal4Invoice"

const DropdownSetProject = props => {
  const [menu, setMenu] = useState(false)
  const [openProject, setOpenProject] = useState(false)
  const handleSetProject = async project => {
    const identifierNumber = await props.getIdentifierNumber(project.projectId)
    const now = new Date()
    props.setInvoice({
      ...props.invoice,
      projectId: project.projectId,
      projectInfo: project,
      invoiceNumber: `${now.getFullYear()}-${project.name.substring(
        0,
        3,
      )}-${identifierNumber}`,
    })
    setMenu(!menu)
  }

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{
            width: "100%",
            height: "0px",
            marginLeft: "20px",
            marginRight: "20px",
          }}
        >
          <DropdownToggle
            style={{
              marginTop: "-10px",
              height: "40px",
              marginLeft: "0px",
              borderRadius: "6px",
              border:
                props.projectError === true
                  ? "1px solid red"
                  : "1px solid #c4c4c4",
              width: "100%",
              paddingLeft: "10px",
            }}
          >
            <div
              className="inline"
              style={{
                position: "relative",
                overflow: "hidden",
                height: "17px",
              }}
            >
              <PoppinsNormal
                style={{
                  textTransform: "none",
                  fontSize: "14px",
                  color: "#414141",
                  lineHeight: "16px",
                  cursor: "pointer",
                }}
              >
                {props.invoice.projectId
                  ? props.invoice.projectInfo.name
                  : null}
              </PoppinsNormal>
              <KeyboardArrowDown
                style={{
                  color: "#414141",
                  width: "15px",
                  position: "absolute",
                  right: "0px",
                  marginTop: "-2px",
                }}
              />
            </div>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end" style={{ width: "100%" }}>
            {props.projectList.map(project => {
              return (
                <DropdownItem
                  key={project.projectId}
                  tag="b"
                  style={{ lineHeight: "20px", cursor: "pointer" }}
                  onClick={() => {
                    handleSetProject(project)
                  }}
                >
                  {" "}
                  <div
                    style={{
                      alignItems: "center",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      display: "block",
                      textOverflow: "ellipsis",
                    }}
                    className="inline"
                  >
                    <img
                      style={{ marginRight: "9px" }}
                      src={project.projectImage}
                      width={40}
                    />
                    {project.name}
                  </div>
                </DropdownItem>
              )
            })}
            <DropdownItem
              tag="b"
              onClick={() => {
                setOpenProject(true)
              }}
              style={{ lineHeight: "20px", cursor: "pointer" }}
            >
              {" "}
              <div
                style={{
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  display: "block",
                  textOverflow: "ellipsis",
                }}
                className="inline"
              >
                <img
                  style={{ marginRight: "9px" }}
                  src={addProject}
                  width={40}
                />
                Crear Proyecto
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>

      {openProject && (
        <CreateProject4Invoice
          open={openProject}
          setOpen={setOpenProject}
          setProject={handleSetProject}
        />
      )}
    </>
  )
}

DropdownSetProject.propTypes = {
  invoice: PropTypes.any,
  setInvoice: PropTypes.func,
  projectList: PropTypes.array,
  getIdentifierNumber: PropTypes.func,
  projectError: PropTypes.any,
}

export default DropdownSetProject
