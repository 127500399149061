export const Taxes = {
  iva: {
    label: "IVA",
    types: [
      {
        id: "iva.21",
        name: "IVA 21%",
        label: "IVA 21%",
        value: 21,
        type: 1,
      },
      {
        id: "iva.10.5",
        name: "IVA 10%",
        label: "IVA 10%",
        value: 10,
        type: 2,
      },
      {
        id: "iva.4",
        name: "IVA 4%",
        label: "IVA 4%",
        value: 4,
        type: 3,
      },
      {
        id: "iva.0",
        name: "Exenta",
        label: "Exenta",
        value: 0,
        type: 4,
      },
    ],
  },
  retention: {
    label: "Retención",
    types: [
      {
        id: "retention.15",
        name: "Retención 15%",
        label: "Retención 15%",
        value: 15,
        type: 1,
      },
      {
        id: "retention.7",
        name: "Retención 7%",
        label: "Retención 7%",
        value: 7,
        type: 2,
      },
    ],
  },
  recargo: {
    label: "Rec. de eq.",
    types: [
      {
        id: "recargo.2.5",
        name: "REC 5.2%",
        label: "REC 5.2%",
        value: 5.2,
        type: 1,
      },
      {
        id: "recargo.1.75",
        name: "REC 1.75%",
        label: "REC 1.75%",
        value: 1.75,
        type: 2,
      },
      {
        id: "recargo.0.5",
        name: "REC 0.5%",
        label: "REC 0.5%",
        value: 0.5,
        type: 3,
      },
      {
        id: "recargo.1.4",
        name: "REC 1.4%",
        label: "REC 1.4%",
        value: 1.4,
        type: 4,
      },
    ],
  },
}
