import {
  ON_GET_PROPOSAL,
  GET_PROPOSAL_SUCCESS,
  GET_PROPOSAL_ERROR,
  UPDATE_PART,
  GET_GROUP_PROPOSAL_SUCCESS,
  LOADING_PDF,
  GET_PDF_SUCCESS,
} from "../types"

const ProposalReducer = (state, action) => {
  switch (action.type) {
    case ON_GET_PROPOSAL:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        projectInfo: action.payload.projectInfo,
        providerInfo: action.payload.providerInfo,
        userInfo: action.payload.userInfo,
        proposalInfo: action.payload.proposalInfo,
        proposalTree: action.payload.proposalTree,
        proposalTreeWithInfo: action.payload.proposalTreeWithInfo,
      }
    case GET_PROPOSAL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_GROUP_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        proposalsGroup: action.payload.groups,
      }
    case UPDATE_PART:
      state.proposalTree.updatePart(
        action.payload.chapterId,
        action.payload.partId,
        action.payload.res,
      )
      return { ...state }
    case LOADING_PDF:
      return {
        ...state,
        loadingPdf: true,
      }
    case GET_PDF_SUCCESS:
      return {
        ...state,
        loadingPdf: false,
        pdf: action.payload.pdf,
      }

    default:
      return state
  }
}

export default ProposalReducer
