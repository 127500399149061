import {
  SET_SELECTED_ACTA,
  GET_ACTS,
  GET_ACTA,
  ADD_ACT,
  UPDATE_ACT,
  DELETE_ACT,
  UPDATE_PENDING_SUBJECT,
} from "../types"

const actsReducer = (state, action) => {
  switch (action.type) {
    case SET_SELECTED_ACTA:
      const selectedActa = action.payload
      state.selectedActa = selectedActa
      return { ...state }
    case GET_ACTS:
      state.acts = action.payload.sort((a, b) => a.actNum - b.actNum).reverse()
      return { ...state, selectedActa: null }
    case GET_ACTA:
      state.selectedActa = action.payload[0]
      return { ...state }
    case ADD_ACT:
      state.acts.push(action.payload)
      return { ...state }
    case UPDATE_PENDING_SUBJECT:
      let index = state.updatedsPendingSubjects.findIndex(
        x => x.code == action.payload.code,
      )
      index === -1 ? state.updatedsPendingSubjects.push(action.payload) : null
      return { ...state }
    case UPDATE_ACT:
      const result = []

      for (const acta of state.acts) {
        if (action.payload.actId === acta.actId) {
          result.push(action.payload)
        } else {
          result.push(acta)
        }
      }

      state.acts = result

      return { ...state }
    case DELETE_ACT:
      return { ...state }
    case "SENDING_ACT":
      return { ...state, sendingAct: true }
    case "SEND_ACT_COMPLETE":
      return { ...state, sendingAct: false }
    default:
      return state
  }
}

export default actsReducer
