import React, { useContext } from "react"
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import { HelveticaBold } from "common/typographies"
import { BlueButton } from "common/containers"
import PropTypes from "prop-types"
import i18n from "i18n"
import { useHistory } from "react-router-dom"
import AuthContext from "context/auth/authContext"
import { CircularProgress } from "@mui/material"
const InvoicePageHeader = ({ invoiceService, localInvoice }) => {
  const history = useHistory()
  const { freeTrialExpired, openFreeTrialExpire } = useContext(AuthContext)
  return (
    <div style={{ width: "100%", position: "relative", height: "60px" }}>
      <div
        className="inline"
        style={{ position: "absolute", marginLeft: "20px", marginTop: "6px" }}
      >
        <ArrowBackOutlinedIcon
          onClick={() => {
            history.goBack()
          }}
          style={{
            color: "#414141",
            cursor: "pointer",
            fontSize: "18px",
            marginTop: "6px",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        />
        <HelveticaBold
          style={{
            fontSize: "18px",
            color: "#414141",
            fontWeight: "600",
            marginTop: "1px",
          }}
        >
          {`${
            invoiceService.localInvoice === true
              ? "Nueva Factura"
              : `${invoiceService.invoice.invoiceNumber} - ${invoiceService.invoice.projectInfo.name}`
          }`}
        </HelveticaBold>
      </div>
      <div className="inline" style={{ position: "absolute", right: "20px" }}>
        <BlueButton
          data-amplify-analytics-on="click"
          data-amplify-analytics-name="SaveInvoice"
          data-amplify-analytics-attrs={invoiceService.invoice}
          onClick={() => {
            freeTrialExpired
              ? openFreeTrialExpire()
              : invoiceService.handleSaveFactura()
          }}
          className="inline"
          style={{
            width: "180px",
            height: "33px",
            textAlign: "center",
            justifyContent: "center",
            paddingTop: "5px",
          }}
        >
          {invoiceService.savingInvoice === false ? (
            <HelveticaBold
              style={{ color: "white", fontWeight: "600", fontSize: "15px" }}
            >
              {i18n.t("Guardar")}
            </HelveticaBold>
          ) : (
            <CircularProgress size={20} style={{ color: "white" }} />
          )}
        </BlueButton>
      </div>
    </div>
  )
}

InvoicePageHeader.propTypes = {
  invoiceService: PropTypes.any,
  localInvoice: PropTypes.bool,
}

export default InvoicePageHeader
