import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Modal } from "@mui/material"
import i18n from "i18n"
import FileContext from "context/file/fileContext"
import pdfIcon from "assets/images/icons/pdf-big.png"
import { HelveticaBold, PoppinsNormal } from "common/typographies"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "650px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    height: "500px",
    //maxHeight: "calc(100vh - 100px)",
    overflowY: "hidden",
    overflowX: "hidden",
  },
}))
const ModalSelectTribbooFiles = ({
  openModal,
  setOpenAnterior,
  setOpenModal,
  selectedFiles,
  actaValues,
  setActaValues,
  setSelectedFiles,
  index,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { getMyFiles, files, fotos, loading } = useContext(FileContext)

  useEffect(() => {
    getMyFiles()
  }, [])

  const onUpload = async () => {
    let aux = actaValues.subjects
    aux[index].files.push({
      fileId: selectedFiles[0].fileId,
      url: selectedFiles[0].url,
    })
    setActaValues({
      ...actaValues,
      subjects: aux,
    })

    setOpenModal(false)
    setOpenAnterior(false)
  }

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          null
        }}
      >
        <>
          <div style={modalStyle} className={classes.paper}>
            <div className={"py-4"}>
              <p
                className="text-center my-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "23px",
                  color: "#414141",
                }}
              >
                {i18n.t("Documentos de el proyecto")}
              </p>

              <button
                type="button"
                onClick={() => {
                  setSelectedFiles([])
                  setOpenModal(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {loading ? (
              <div
                style={{
                  height: "317px",
                  marginTop: "0px",
                  margin: "33px",
                  justifyContent: "center",
                  border: "2px solid rgba(0, 0, 0, 0.36)",
                  overflowY: "auto",
                }}
              >
                <>
                  <div
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      position: "relative",
                      display: "flex",
                      marginLeft: "-70px",
                      marginTop: "30px",
                    }}
                  >
                    <div className="loader">
                      <div className="ball"></div>
                      <div className="ball"></div>
                      <div className="ball"></div>
                    </div>
                  </div>
                  <div
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      position: "relative",
                      display: "flex",
                      marginLeft: "0px",
                    }}
                  >
                    <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
                  </div>
                  <div
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      position: "relative",
                      display: "flex",
                      marginLeft: "0px",
                      marginTop: "-15px",
                    }}
                  >
                    <PoppinsNormal style={{ fontWeight: "400" }}>
                      {i18n.t("Esta acción puede tardar unos segundos")}
                    </PoppinsNormal>
                  </div>
                </>
              </div>
            ) : (
              <div
                style={{
                  height: "317px",
                  marginTop: "0px",
                  margin: "33px",
                  padding: "8px",
                  border: "2px solid rgba(0, 0, 0, 0.36)",
                  flexWrap: "wrap",
                  overflowY: "auto",
                  display: "flex",
                }}
              >
                {fotos.map((foto, index) => (
                  <div
                    onClick={() => {
                      setSelectedFiles([
                        {
                          ...foto,
                          type: "foto",
                          index: index,
                          name: foto.filename,
                        },
                      ])
                    }}
                    key={index}
                    style={{
                      backgroundColor:
                        selectedFiles[0]?.type == "foto" &&
                        selectedFiles[0]?.index == index &&
                        "#FDEFC6",
                      width: "130px",
                      height: "130px",
                      padding: "8px",
                      marginLeft: "6px",
                      marginRight: "4px",
                      justifyContent: "center",
                      verticalAlign: "middle",
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                      src={foto.url}
                    />
                  </div>
                ))}
                {files.map(
                  (file, index) =>
                    file?.filename.substring(
                      file?.filename.length - 3,
                      file?.filename.length,
                    ) == "pdf" && (
                      <div
                        onClick={() => {
                          setSelectedFiles([
                            {
                              ...file,
                              type: "file",
                              index: index,
                              name: file.filename,
                            },
                          ])
                        }}
                        key={index}
                        style={{
                          backgroundColor:
                            selectedFiles[0]?.type == "file" &&
                            selectedFiles[0]?.index == index &&
                            "#FDEFC6",
                          width: "130px",
                          height: "130px",
                          padding: "8px",
                          marginLeft: "6px",
                          marginRight: "4px",
                          justifyContent: "center",
                          verticalAlign: "middle",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <img src={pdfIcon} />
                          <HelveticaBold
                            style={{
                              color: "#414141",
                              fontWeight: "300",
                              fontSize: "14px",
                              marginTop: "3px",
                              maxWidth: "120px",
                              marginBottom: "0px",
                              maxHeight: "38px",
                              textOverflow: "ellipsis",
                              display: "block",
                              overflow: "hidden",
                            }}
                          >
                            {file?.filename}
                          </HelveticaBold>
                        </div>
                      </div>
                    ),
                )}
                {fotos.length == 0 && files.length == 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <HelveticaBold
                      style={{
                        color: "#414141",
                        fontWeight: "300",
                        fontSize: "20px",
                        padding: "30",
                      }}
                    >
                      {i18n.t("Parece que no tienes añadido ningún documento")}
                    </HelveticaBold>
                  </div>
                )}
              </div>
            )}

            <div id="centerButton" style={{ marginTop: "-5px" }}>
              {selectedFiles.length == 0 ? (
                <button
                  type="button"
                  className="AddProjectDisabled"
                  style={{ marginTop: "-10px", marginBottom: "30px" }}
                >
                  {i18n.t("Subir")}
                </button>
              ) : (
                <button
                  type="button"
                  className="AddContact"
                  onClick={onUpload}
                  style={{ marginTop: "-10px", marginBottom: "30px" }}
                >
                  {i18n.t("Subir")}
                </button>
              )}
            </div>
          </div>
        </>
      </Modal>
    </>
  )
}

ModalSelectTribbooFiles.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  selectedFiles: PropTypes.array.isRequired,
  setSelectedFiles: PropTypes.func.isRequired,
  actaValues: PropTypes.object.isRequired,
  setActaValues: PropTypes.func.isRequired,
  setOpenAnterior: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

export default ModalSelectTribbooFiles
