import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import i18n from "i18n"
import { PoppinsNormal } from "common/typographies"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"

const DropdownSubjectStatus = props => {
  const [menu, setMenu] = useState(false)

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          id="nonHover"
          style={{ width: "100%", height: "0px", marginTop: "18px" }}
        >
          <DropdownToggle
            id="nonHover"
            style={{
              marginTop: "-10px",
              backgroundColor: "white",
              height: "20px",
              width: "100%",
              padding: "0px",
            }}
          >
            <div
              id="nonHover"
              className="inline"
              style={{ position: "relative", width: "100%" }}
            >
              <PoppinsNormal
                style={{
                  fontSize: "14px",
                  color: "#414141",
                  lineHeight: "18px",
                  cursor: "pointer",
                  paddingTop: "0px",
                  paddingLeft: "0px",
                  textTransform: "initial",
                }}
              >
                {props.status}
              </PoppinsNormal>
              <KeyboardArrowDown
                style={{
                  color: "#414141",
                  width: "15px",
                  position: "absolute",
                  right: "10px",
                  marginTop: "-3px",
                }}
              />
            </div>
          </DropdownToggle>

          <DropdownMenu
            className="dropdown-menu-end"
            style={{ width: "109px", minWidth: "109px", marginLeft: "20px" }}
          >
            <DropdownItem
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
              onClick={() => {
                props.handleChangeStatus("Pendiente")
              }}
            >
              {" "}
              <div className="inline">{i18n.t("Pendiente")}</div>
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
              onClick={() => {
                props.handleChangeStatus("En curso")
              }}
            >
              {" "}
              <div className="inline">{i18n.t("En curso")}</div>
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
              onClick={() => {
                props.handleChangeStatus("Finalizado")
              }}
            >
              {" "}
              <div className="inline">{i18n.t("Finalizado")}</div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </>
  )
}

DropdownSubjectStatus.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  status: PropTypes.string,
  handleChangeStatus: PropTypes.string,
}

export default DropdownSubjectStatus
