import React from "react"
import PropTypes from "prop-types"
import { Paper } from "@mui/material"
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid-pro"
import useDashboardRows from "hooks/dashboard/useDashboardRows"
import useDashboardConfigFilters from "hooks/dashboard/useDashboardConfigFilters"
import DataGridLocaleText from "common/DataGridLocaleText"

const CustomToolbar = () => (
  <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
    <GridToolbarDensitySelector />
    <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    <GridToolbarQuickFilter
      quickFilterParser={searchInput =>
        searchInput.split(",").map(value => value.trim())
      }
      quickFilterFormatter={quickFilterValues => quickFilterValues.join(", ")}
    />
  </GridToolbarContainer>
)

const DashboardDataGrid = ({ projects }) => {
  const {
    columns,
    handleColumnOrderChange,
    dashboardConfig,
    handleSortModelChange,
    handleColumnVisibilityModelChange,
  } = useDashboardConfigFilters()

  const { rows } = useDashboardRows(projects)

  return (
    <Paper
      sx={{
        height: "80vh",
        width: "100%",
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        sx={{
          border: 0,
          "& .MuiDataGrid-toolbarContainer>Button": {
            color: "rgba(0, 0, 0, 0.87)",
          },
        }}
        slots={{ toolbar: CustomToolbar }}
        sortModel={dashboardConfig.sort}
        onSortModelChange={handleSortModelChange}
        columnVisibilityModel={dashboardConfig.columnVisibility}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        onColumnOrderChange={handleColumnOrderChange}
        hideFooterPagination
        hideFooter
        localeText={DataGridLocaleText()}
      />
    </Paper>
  )
}

DashboardDataGrid.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      chapters_count: PropTypes.number,
      costeGeneral: PropTypes.number,
      createdAt: PropTypes.string,
      discount: PropTypes.number,
      fase: PropTypes.number,
      finalPrice: PropTypes.number,
      progressPercentage: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      projectId: PropTypes.string,
    }),
  ).isRequired,
}

export default DashboardDataGrid
