import React, { useContext } from "react"
import { PoppinsSemiBold } from "common/typographies"
import i18n from "i18n"
import { ActasTable } from "common/tables"
import PropTypes from "prop-types"
import AsuntosPendientesComponent from "./AsuntosPendientesComponent"

const AsuntosPendientes = ({
  updateSubject,
  actaValues,
  setActaValues,
  actNum,
  setActaRefs,
  actasRefs,
  viewPending,
}) => {
  const addAsunto = () => {
    setActaValues({
      ...actaValues,
      subjects: [
        ...actaValues.subjects,
        {
          code: "",
          subject: "",
          status: "Pendiente",
          responsible: "",
          files: [],
        },
      ],
    })
    setActaRefs({
      ...actasRefs,
      subjectsRefs: [
        ...actasRefs.subjectsRefs,
        {
          subject: React.createRef(),
          status: React.createRef(),
          responsible: React.createRef(),
        },
      ],
    })
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: "#F8F8F8",
          marginTop: "10px",
          marginBottom: "16px",
          marginLeft: "0px",
          borderRadius: "6px",
        }}
      >
        <div
          className="inline"
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
            borderRadius: "6px",
          }}
        >
          <div
            style={{
              backgroundColor: "#F8F8F8",
              width: "100%",
              marginLeft: "20px",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px",
                paddingTop: "8px",
                paddingLeft: "0px",
                paddingBottom: "5px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold style={{ fontSize: "14px", minWidth: "200px" }}>
                {i18n.t("Asuntos pendientes")}
              </PoppinsSemiBold>
            </div>
            <ActasTable
              style={{
                marginBottom: "12px",
                display: viewPending ? "table" : "none",
              }}
            >
              <tr>
                <th style={{ width: "20%" }}>{i18n.t("Codigo")}</th>
                <th style={{ width: "35%" }}>{i18n.t("Asunto")}</th>
                <th style={{ width: "15%" }}>{i18n.t("Estado")}</th>
                <th style={{ width: "15%" }}>{i18n.t("Responsable")}</th>
                <th style={{ width: "15%" }}>{i18n.t("Archivos")}</th>
              </tr>
              <AsuntosPendientesComponent
                updateSubject={updateSubject}
                actasRefs={actasRefs}
                actaValues={actaValues}
                setActaValues={setActaValues}
                index={0}
                actNum={actNum}
              />
              {actaValues.pendingSubjects.length > 1 &&
                actaValues.pendingSubjects.map((asunto, index) => {
                  if (index > 0) {
                    return (
                      <AsuntosPendientesComponent
                        updateSubject={updateSubject}
                        actasRefs={actasRefs}
                        key={index}
                        actaValues={actaValues}
                        setActaValues={setActaValues}
                        index={index}
                        actNum={actNum}
                      />
                    )
                  }
                })}
            </ActasTable>
          </div>
        </div>
      </div>
    </>
  )
}

AsuntosPendientes.propTypes = {
  updateSubject: PropTypes.func.isRequired,
  actaValues: PropTypes.object.isRequired,
  setActaValues: PropTypes.func.isRequired,
  actNum: PropTypes.number.isRequired,
  setActaRefs: PropTypes.func.isRequired,
  actasRefs: PropTypes.array.isRequired,
  viewPending: PropTypes.bool.isRequired,
}

export default AsuntosPendientes
