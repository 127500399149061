import {
  HelveticaBold,
  HelveticaLight,
  PoppinsSemiBold,
  PoppinsNormal,
} from "common/typographies"
import React, { useState, useEffect, useContext } from "react"
import i18n from "i18n"
import { Box, Grid } from "@material-ui/core"
import { BlueButton, CardPricing, EmptyButton } from "common/containers"
import data from "./pricing.json"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { useHistory } from "react-router-dom"
import AuthContext from "context/auth/authContext"
import amex from "assets/images/creditCards/amex.png"
import mastercard from "assets/images/creditCards/mastercard.png"
import visa from "assets/images/creditCards/visa.png"
import dankort from "assets/images/creditCards/dankort.png"
import diners from "assets/images/creditCards/diners.png"
import discover from "assets/images/creditCards/discover.png"
import jcb from "assets/images/creditCards/jcb.png"
import maestro from "assets/images/creditCards/maestro.png"
import unionpay from "assets/images/creditCards/unionpay.png"
import electron from "assets/images/creditCards/electron.png"
import interpayment from "assets/images/creditCards/interpayment.png"
import axios from "axios"
import PropTypes from "prop-types"
import { YellowButton } from "common/buttons"
import { isLocalhost } from "utils/functions"
import { CHARGEBEE_SETTINGS } from "utils/functions"
import isNaN from "lodash/isNaN"

const SubscriptionComponent = ({ anual, setAnual }) => {
  // Verifica si Chargebee ya ha sido inicializado en window
  window.cbInstance =
    window.cbInstance ||
    window.Chargebee.init({
      site: CHARGEBEE_SETTINGS["site"],
    })

  // Asigna cbInstance a la variable local para mantener el nombre
  const cbInstance = window.cbInstance

  const authContext = useContext(AuthContext)
  const {
    user,
    getUser,
    coupon,
    subscription,
    getCustomerInfo,
    updateChargebeeAccount,
    card,
    billingAddress,
    createChargebeePortal,
    chargebeePortal,
  } = authContext
  useEffect(() => {
    getCustomerInfo()
  }, [])

  useEffect(() => {
    if (chargebeePortal === null) {
      createChargebeePortal()
    } else {
      cbInstance.setPortalSession(function () {
        // This function should return a promise, that will resolve a portal session object
        // If the library that you use for making ajax calls, can return a promise, you can directly return that.
        return new Promise(function (resolve, reject) {
          // This sampe response should be fetched as a result of an ajax call
          var sampleResponse = chargebeePortal.portal
          resolve(sampleResponse)
        })
      })
    }
  }, [chargebeePortal])
  const [actualPlan, setActualPlan] = useState("")

  const [showComparativa, setShowComparativa] = useState(false)
  const history = useHistory()
  useEffect(() => {
    if (subscription?.subscription_items) {
      setActualPlan(subscription.subscription_items[0].item_price_id)
    }
  }, [subscription])

  function toDateTime(secs) {
    var t = new Date(1970, 0, 1) // Epoch
    t.setSeconds(secs)
    return t
  }

  const baseUrl = isLocalhost
    ? "https://api.dev.planhopper.com/integration/"
    : "https://api.prod.planhopper.com/integration/"

  const handlePayment = (e, planName) => {
    e.preventDefault()
    cbInstance.openCheckout({
      hostedPage() {
        return axios
          .post(baseUrl + "chargebee/checkout", {
            subscriptionId: subscription.id,
            plan: planName.toUpperCase(),
            billing_period: anual ? "YEARLY" : "MONTHLY",
            coupon: [],
          })
          .then(response => response.data.checkout)
      },
      async success(hostedPageId) {
        //pasar a Arnau user.sub, planName, anual
        updateChargebeeAccount({
          email: user.email,
          plan: planName.toUpperCase(),
          billing_period: anual ? "YEARLY" : "MONTHLY",
        })
        history.push("/dashboard")
      },
      close() {
        console.log("checkout new closed")
      },
      step(step) {
        console.log("checkout", step)
      },
    })
  }
  const [itemSelected, setItemSelected] = useState(false)
  const [editSubscription, setEditSubscription] = useState(false)
  const [cancelSubscription, setCancelSubscription] = useState(false)

  const handleManageSubscription = () => {
    const cbPortalInstance = cbInstance.createChargebeePortal()
    cbPortalInstance.openSection(
      {
        sectionType: Chargebee.getPortalSections().SUBSCRIPTION_DETAILS,
        params: {
          subscriptionId: subscription.id,
        },
      },
      {
        visit(visit) {
          console.log("visit", visit)
        },
        subscriptionChanged(data) {
          console.log("subscription changed", data)
        },
        subscriptionCustomFieldsChanged(data) {
          console.log("subscription changed custom fields changed", data)
        },
        subscriptionCancelled(data) {
          console.log("subscription changed cancelled", data)
        },
        close() {
          location.reload()
          getCustomerInfo()
        },
      },
    )
  }

  const handleManageAddress = () => {
    const cbPortalInstance = cbInstance.createChargebeePortal()
    cbPortalInstance.openSection(
      {
        sectionType: Chargebee.getPortalSections().ADDRESS,
      },
      {
        visit(visit) {
          console.log("portal visit", visit)
        },
        subscriptionChanged(data) {
          console.log("subscription changed", data)
        },
        subscriptionCustomFieldsChanged(data) {
          console.log("subscription changed custom fields changed", data)
        },
        subscriptionCancelled(data) {
          console.log("subscription changed cancelled", data)
        },
        close() {
          getCustomerInfo()
        },
      },
    )
  }
  const handleBillinghistory = () => {
    const cbPortalInstance = cbInstance.createChargebeePortal()
    cbPortalInstance.openSection(
      {
        sectionType: Chargebee.getPortalSections().BILLING_HISTORY,
      },
      {
        visit(visit) {
          console.log("portal visit", visit)
        },
        subscriptionChanged(data) {
          console.log("subscription changed", data)
        },
        subscriptionCustomFieldsChanged(data) {
          console.log("subscription changed custom fields changed", data)
        },
        subscriptionCancelled(data) {
          console.log("subscription changed cancelled", data)
        },
        close() {
          getCustomerInfo()
        },
      },
    )
  }

  const handlePaymentSources = () => {
    const cbPortalInstance = cbInstance.createChargebeePortal()
    cbPortalInstance.openSection(
      {
        sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
      },
      {
        visit(visit) {
          console.log("portal visit", visit)
        },
        subscriptionChanged(data) {
          console.log("subscriptionIII changed", data)
        },
        subscriptionCustomFieldsChanged(data) {
          console.log("subscriptioIIII changed custom fields changed", data)
        },
        subscriptionCancelled(data) {
          console.log("subscriptionIII changed cancelled", data)
        },
        close() {
          getCustomerInfo()
        },
      },
    )
  }

  const buttonCopy = plan => {
    if (user["custom:subscription_plan"] == "FREE_TRIAL") {
      if (plan == "Enterprise") {
        return i18n.t("Contactar")
      } else {
        return i18n.t("Seleccionar")
      }
    }
    let aux = actualPlan.toLowerCase()
    if (plan == "Enterprise") {
      return i18n.t("Contactar")
    }
    if (aux.includes(plan.toLowerCase())) {
      return i18n.t("Gestionar")
    } else {
      return i18n.t("Seleccionar")
    }
  }

  const [url, setUrl] = useState("")
  useEffect(() => {
    setUrl(window.location.href)
  }, [window.location.href])

  const [imInFreeTrial, setImInFreeTrial] = useState(false)

  const getPlanCheckoutUrl = (plan, email, sub) => {
    const cb_checkout_url =
      "https://" +
      CHARGEBEE_SETTINGS["site"] +
      ".chargebee.com/hosted_pages/checkout"
    return (
      cb_checkout_url +
      "?subscription_items[item_price_id][0]=" +
      plan +
      "&subscription_items[quantity][0]=1&customer[email]=" +
      email +
      "&customer[locale]=es&billing_address[email]=" +
      email +
      "&redirect_url=" +
      url +
      "&customer[id]=" +
      sub
    )
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!user) {
      getUser()
    } else {
      if (user["custom:subscription_plan"] == "FREE_TRIAL") {
        setImInFreeTrial(true)
      } else {
        const fetchData = async () => {
          cbInstance.setPortalSession(await createChargebeePortal())
        }
        fetchData()
        setImInFreeTrial(false)
      }
      setLoading(false)
    }
  }, [user])

  return loading ? (
    <>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          marginLeft: "-100px",
          marginTop: "30px",
        }}
      >
        <div className="loader">
          <div className="ball"></div>
          <div className="ball"></div>
          <div className="ball"></div>
        </div>
      </div>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          marginLeft: "-30px",
        }}
      >
        <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
      </div>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          position: "relative",
          display: "flex",
          marginLeft: "-30px",
          marginTop: "-15px",
        }}
      >
        <PoppinsNormal style={{ fontWeight: "400" }}>
          {i18n.t("Esta acción puede tardar unos segundos")}
        </PoppinsNormal>
      </div>
    </>
  ) : imInFreeTrial && user["custom:subscription_status"] != "cancelled" ? (
    <>
      <PoppinsSemiBold style={{ fontSize: "20px", marginLeft: "20px" }}>
        {i18n.t("Elige las herramientas adecuadas para tu negocio")}
      </PoppinsSemiBold>
      <div
        className="inline"
        style={{
          width: "100%",
          justifyContent: "center",
          marginBottom: "30px",
          marginTop: "-20px",
        }}
      >
        <div className="container4buttons">
          {anual == false ? (
            <>
              <BlueButton
                style={{
                  width: "123px",
                  marginLeft: "-4px",
                  height: "26px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "2px",
                  borderRadius: "4px",
                }}
              >
                <HelveticaLight
                  style={{
                    color: "white",
                    paddingTop: "12px",
                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                >
                  {i18n.t("Mensual")}
                </HelveticaLight>
              </BlueButton>
              <EmptyButton
                style={{ marginTop: "4px", paddingLeft: "2px" }}
                onClick={() => {
                  history.push("/subscriptionAnual")
                  history.go(0)
                }}
              >
                <HelveticaLight
                  style={{
                    color: "rgba(65, 65, 65, 0.75)",
                    paddingTop: "12px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t("Anual - Ahorra un 20%")}
                </HelveticaLight>
              </EmptyButton>
            </>
          ) : (
            <>
              <EmptyButton
                style={{ marginTop: "4px", paddingLeft: "2px", with: "123px" }}
                onClick={() => {
                  history.push("/subscription")
                  history.go(0)
                }}
              >
                <HelveticaLight
                  style={{
                    color: "rgba(65, 65, 65, 0.75)",
                    paddingTop: "12px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t("Mensual")}
                </HelveticaLight>
              </EmptyButton>
              <BlueButton
                style={{
                  width: "160px",
                  zIndex: "3",
                  marginLeft: "-4px",
                  height: "26px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "2px",
                  borderRadius: "4px",
                }}
              >
                <HelveticaLight
                  style={{
                    color: "white",
                    paddingTop: "12px",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  {i18n.t("Anual - Ahorra un 20%")}
                </HelveticaLight>
              </BlueButton>
            </>
          )}
        </div>
      </div>

      <div
        className="inline"
        style={{
          justifyContent: "center",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Box position="relative" zIndex={2}>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            style={{ display: "flex-grid" }}
          >
            {data.map((item, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <CardPricing>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <HelveticaBold
                        style={{
                          fontSize: "20px",
                          color: "#414141",
                          marginTop: "20px",
                          textTransform: "uppercase",
                        }}
                      >
                        {item.externalName}
                      </HelveticaBold>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                        marginBottom: "18px",
                      }}
                    ></div>
                    <div style={{ marginLeft: "20px" }}>
                      {anual == false ? (
                        <>
                          {!isNaN(item.monthlyPrice) ? (
                            <div className="inline">
                              <HelveticaBold
                                style={{
                                  fontSize: "42px",
                                  color: "#414141",
                                  marginTop: "-15px",
                                }}
                              >
                                {item.monthlyPrice} €
                              </HelveticaBold>
                              <HelveticaBold
                                style={{
                                  fontSize: "18px",
                                  color: "#414141",
                                  marginTop: "10px",
                                }}
                              >
                                {" "}
                                &nbsp; por usuario/mes
                              </HelveticaBold>
                            </div>
                          ) : (
                            <HelveticaBold
                              style={{
                                fontSize: "42px",
                                color: "#414141",
                                marginTop: "-15px",
                              }}
                            >
                              {i18n.t("Consultar")}
                            </HelveticaBold>
                          )}

                          {item.mostPopular ? (
                            <BlueButton
                              style={{
                                cursor: "default",
                                width: "110px",
                                height: "20px",
                                marginTop: "-100px",
                                marginLeft: "145px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "absolute",
                              }}
                            >
                              <HelveticaLight
                                style={{
                                  color: "white",
                                  paddingTop: "12px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                {i18n.t("Mas popular")}
                              </HelveticaLight>
                            </BlueButton>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {!isNaN(item.monthlyPrice) ? (
                            <HelveticaLight
                              style={{
                                fontSize: "16px",
                                fontWeight: "400",
                                textDecoration: "line-through",
                              }}
                            >
                              {item.monthlyPrice} €
                            </HelveticaLight>
                          ) : (
                            <div style={{ height: "25px" }}></div>
                          )}

                          {!isNaN(item.yearlyPrice) ? (
                            <div className="inline">
                              <HelveticaBold
                                style={{
                                  fontSize: "42px",
                                  color: "#414141",
                                  marginTop: "-15px",
                                }}
                              >
                                {Math.trunc(parseFloat(item.yearlyPrice) / 12)}{" "}
                                €
                              </HelveticaBold>
                              <HelveticaBold
                                style={{
                                  fontSize: "18px",
                                  color: "#414141",
                                  marginTop: "10px",
                                }}
                              >
                                &nbsp; por usuario/mes{" "}
                              </HelveticaBold>
                            </div>
                          ) : (
                            <HelveticaBold
                              style={{
                                fontSize: "42px",
                                color: "#414141",
                                marginTop: "0px",
                              }}
                            >
                              {i18n.t("Consultar")}
                            </HelveticaBold>
                          )}
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                        marginBottom: "30px",
                      }}
                    >
                      {item.planName == "Professional" ? (
                        <BlueButton
                          onClick={() =>
                            window.open(
                              "https://calendly.com/planhopper-xvq/planhopper",
                              "_blank",
                            )
                          }
                          style={{
                            width: "260px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              buttonCopy(item.planName) == "Gestionar" &&
                              "#ffd52b",
                          }}
                        >
                          <HelveticaLight
                            style={{
                              color: "white",
                              paddingTop: "12px",
                              fontSize: "14px",
                              fontWeight: "600",
                              color:
                                buttonCopy(item.planName) == "Gestionar"
                                  ? "black"
                                  : "white",
                            }}
                          >
                            {buttonCopy(item.planName)}
                          </HelveticaLight>
                        </BlueButton>
                      ) : (
                        <BlueButton
                          onClick={e => {
                            handlePayment(e, item.planName)
                          }}
                          style={{
                            width: "260px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              buttonCopy(item.planName) == "Gestionar" &&
                              "#ffd52b",
                          }}
                        >
                          <HelveticaLight
                            style={{
                              color: "white",
                              paddingTop: "12px",
                              fontSize: "14px",
                              fontWeight: "600",
                              color:
                                buttonCopy(item.planName) == "Gestionar"
                                  ? "black"
                                  : "white",
                            }}
                          >
                            {buttonCopy(item.planName)}
                          </HelveticaLight>
                        </BlueButton>
                      )}
                    </div>

                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                        marginBottom: "30px",
                      }}
                    ></div>
                    {item.mainFeatures.map((feature, index) => {
                      return (
                        <div
                          style={{
                            marginLeft: "25px",
                            marginBottom: "15px",
                            marginTop: "-10px",
                          }}
                          className="inline"
                          key={index}
                        >
                          <CheckCircleOutlineIcon
                            style={{
                              color: "#414141",
                              width: "18px",
                              heigth: "18px",
                              marginTop: "-2px",
                              marginRight: "10px",
                            }}
                          />
                          <HelveticaBold
                            style={{
                              color: "#414141",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {feature}
                          </HelveticaBold>
                        </div>
                      )
                    })}
                  </CardPricing>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </div>
    </>
  ) : (
    <>
      <PoppinsSemiBold style={{ fontSize: "20px", marginLeft: "0px" }}>
        {i18n.t("Cuenta")}
      </PoppinsSemiBold>
      <div
        className="inline"
        style={{
          width: "920px",
          position: "relative",
          height: "350px",
          backgroundColor: "white",
          marginBottom: "30px",
          marginTop: "0px",
          marginLeft: "0px",
          borderRadius: "6px",
        }}
      >
        <div style={{ width: "25%" }}>
          <HelveticaBold
            style={{
              fontSize: "20px",
              color: "#414141",
              marginTop: "15px",
              marginLeft: "20px",
            }}
          >
            {i18n.t("Suscripción y facturación")}
          </HelveticaBold>
          <YellowButton
            style={{ marginLeft: "20px", width: "200px", height: "40px" }}
            onClick={handleManageSubscription}
          >
            {i18n.t("Cambiar plan")}
          </YellowButton>
          <HelveticaBold
            style={{
              fontSize: "20px",
              color: "#414141",
              marginTop: "90px",
              marginLeft: "20px",
            }}
          >
            {i18n.t("Detalles del plan contratado")}
          </HelveticaBold>
        </div>
        <div
          style={{
            width: "75%",
            position: "relative",
            marginLeft: "90px",
            marginRight: "30px",
            marginTop: "15px",
          }}
        >
          <div
            className="inline"
            style={{ width: "100%", marginTop: "40px", position: "relative" }}
          >
            <HelveticaBold
              style={{
                fontSize: "14px",
                textTransform: "none",
                color: "#8E8E8E",
                fontWeight: "400",
              }}
            >
              {billingAddress.address}
            </HelveticaBold>
            <HelveticaBold
              onClick={e => {
                handleManageAddress(e)
              }}
              style={{
                fontSize: "14px",
                fontWeight: "400",
                position: "absolute",
                right: "0px",
                top: "0px",
                color: "#4461D7",
                cursor: "pointer",
              }}
            >
              {i18n.t("Gestionar información de facturación")}
            </HelveticaBold>
          </div>
          <div
            className="inline"
            style={{ width: "100%", marginTop: "0px", position: "relative" }}
          >
            <HelveticaBold
              style={{
                fontSize: "14px",
                textTransform: "none",
                color: "#8E8E8E",
                fontWeight: "400",
              }}
            >
              {billingAddress.city}
            </HelveticaBold>
          </div>
          <div
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              width: "100%",
            }}
          ></div>
          <div
            className="inline"
            style={{ width: "100%", marginTop: "20px", position: "relative" }}
          >
            <img
              src={
                card.typeOfCreditCard == "MASTERCARD"
                  ? mastercard
                  : card.typeOfCreditCard == "VISA"
                    ? visa
                    : card.typeOfCreditCard == "ELECTRON"
                      ? electron
                      : card.typeOfCreditCard == "AMEX"
                        ? amex
                        : card.typeOfCreditCard == "DINERS"
                          ? diners
                          : card.typeOfCreditCard == "DISCOVER"
                            ? discover
                            : card.typeOfCreditCard == "JCB"
                              ? jcb
                              : card.typeOfCreditCard == "UNIONPAY"
                                ? unionpay
                                : card.typeOfCreditCard == "MAESTRO"
                                  ? maestro
                                  : card.typeOfCreditCard == "INTERPAYMENT"
                                    ? interpayment
                                    : card.typeOfCreditCard == "DANKORT"
                                      ? dankort
                                      : visa
              }
              style={{ width: "25px", height: "23px", marginRight: "10px" }}
            />
            <HelveticaBold
              style={{
                fontSize: "14px",
                textTransform: "none",
                color: "#8E8E8E",
                fontWeight: "400",
              }}
            >
              {card.typeOfCreditCard}
            </HelveticaBold>
            <HelveticaBold
              style={{
                fontSize: "14px",
                textTransform: "none",
                color: "#8E8E8E",
                fontWeight: "400",
                marginLeft: "3px",
              }}
            >
              {"terminada en " + card.last4}
            </HelveticaBold>
            <HelveticaBold
              style={{
                fontSize: "14px",
                fontWeight: "400",
                position: "absolute",
                right: "0px",
                top: "0px",
                color: "#4461D7",
                cursor: "pointer",
              }}
              onClick={handlePaymentSources}
            >
              {i18n.t("Gestionar información de pago")}
            </HelveticaBold>
          </div>
          <div
            className="inline"
            style={{ width: "100%", marginTop: "0px", position: "relative" }}
          >
            <HelveticaBold
              style={{
                fontSize: "14px",
                textTransform: "none",
                color: "#8E8E8E",
                fontWeight: "400",
              }}
            >
              {i18n.t("Tu próxima facturación es el ")}
            </HelveticaBold>
            <HelveticaBold
              style={{
                fontSize: "14px",
                textTransform: "none",
                color: "#8E8E8E",
                fontWeight: "400",
                marginLeft: "3px",
              }}
            >
              {" " +
                toDateTime(subscription.current_term_end).getDate() +
                "/" +
                (toDateTime(subscription.current_term_end).getMonth() + 1) +
                "/" +
                toDateTime(subscription.current_term_end).getFullYear()}
            </HelveticaBold>
            <HelveticaBold
              style={{
                fontSize: "14px",
                fontWeight: "400",
                position: "absolute",
                right: "0px",
                top: "0px",
                color: "#4461D7",
                cursor: "pointer",
              }}
              onClick={handleBillinghistory}
            >
              {i18n.t("Datos de facturación")}
            </HelveticaBold>
          </div>
          <div
            style={{
              height: "1px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              width: "100%",
            }}
          ></div>
          <div
            className="inline"
            style={{ width: "100%", marginTop: "20px", position: "relative" }}
          >
            <HelveticaBold
              style={{
                fontSize: "20px",
                textTransform: "uppercase",
                color: "#414141",
              }}
            >
              {user
                ? user["custom:subscription_plan"] == "BASIC"
                  ? "ESENCIAL"
                  : "AVANZADO"
                : ""}
            </HelveticaBold>
            <HelveticaBold
              style={{
                fontSize: "14px",
                fontWeight: "400",
                position: "absolute",
                right: "0px",
                top: "0px",
                color: "#4461D7",
                cursor: "pointer",
              }}
              onClick={handleManageSubscription}
            >
              {user && user["custom:subscription_status"] == "cancelled"
                ? i18n.t("Reactivar suscripcion")
                : i18n.t("Cancelar suscripcion")}
            </HelveticaBold>
          </div>
        </div>
      </div>
      <div
        className="inline"
        style={{
          width: "920px",
          justifyContent: "center",
          marginBottom: "30px",
          marginTop: "-20px",
        }}
      >
        <div className="container4buttons">
          {anual == false ? (
            <>
              <BlueButton
                style={{
                  width: "123px",
                  marginLeft: "-4px",
                  height: "26px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "2px",
                  borderRadius: "4px",
                }}
              >
                <HelveticaLight
                  style={{
                    color: "white",
                    paddingTop: "12px",
                    fontSize: "13px",
                    fontWeight: "700",
                  }}
                >
                  {i18n.t("Mensual")}
                </HelveticaLight>
              </BlueButton>
              <EmptyButton
                style={{ marginTop: "4px", paddingLeft: "2px" }}
                onClick={() => {
                  history.push("/subscriptionAnual")
                  history.go(0)
                }}
              >
                <HelveticaLight
                  style={{
                    color: "rgba(65, 65, 65, 0.75)",
                    paddingTop: "12px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t("Anual - Ahorra un 20%")}
                </HelveticaLight>
              </EmptyButton>
            </>
          ) : (
            <>
              <EmptyButton
                style={{ marginTop: "4px", paddingLeft: "2px", with: "123px" }}
                onClick={() => {
                  history.push("/subscription")
                  history.go(0)
                }}
              >
                <HelveticaLight
                  style={{
                    color: "rgba(65, 65, 65, 0.75)",
                    paddingTop: "12px",
                    fontSize: "13px",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t("Mensual")}
                </HelveticaLight>
              </EmptyButton>
              <BlueButton
                style={{
                  width: "160px",
                  zIndex: "3",
                  marginLeft: "-4px",
                  height: "26px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "2px",
                  borderRadius: "4px",
                }}
              >
                <HelveticaLight
                  style={{
                    color: "white",
                    paddingTop: "12px",
                    fontSize: "12px",
                    fontWeight: "700",
                  }}
                >
                  {i18n.t("Anual - Ahorra un 20%")}
                </HelveticaLight>
              </BlueButton>
            </>
          )}
        </div>
      </div>

      <div
        className="inline"
        style={{ justifyContent: "center", width: "900px" }}
      >
        <Box position="relative" zIndex={2}>
          <Grid
            container
            spacing={4}
            justifyContent="center"
            style={{ display: "flex-grid" }}
          >
            {data.map((item, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <CardPricing>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        display: "flex",
                        textAlign: "center",
                        justifyContent: "center",
                      }}
                    >
                      <HelveticaBold
                        style={{
                          fontSize: "20px",
                          color: "#414141",
                          marginTop: "20px",
                          textTransform: "uppercase",
                        }}
                      >
                        {item.externalName}
                      </HelveticaBold>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                        marginBottom: "18px",
                      }}
                    ></div>

                    <div style={{ marginLeft: "20px" }}>
                      {
                        /* */
                        anual == false ? (
                          <>
                            {!isNaN(item.monthlyPrice) ? (
                              <div className="inline">
                                <HelveticaBold
                                  style={{
                                    fontSize: "42px",
                                    color: "#414141",
                                    marginTop: "-15px",
                                  }}
                                >
                                  {item.monthlyPrice} €
                                </HelveticaBold>
                                <HelveticaBold
                                  style={{
                                    fontSize: "18px",
                                    color: "#414141",
                                    marginTop: "10px",
                                  }}
                                >
                                  {" "}
                                  &nbsp; por usuario/mes
                                </HelveticaBold>
                              </div>
                            ) : (
                              <HelveticaBold
                                style={{
                                  fontSize: "42px",
                                  color: "#414141",
                                  marginTop: "-15px",
                                }}
                              >
                                {i18n.t("Consultar")}
                              </HelveticaBold>
                            )}

                            {item.mostPopular ? (
                              <BlueButton
                                style={{
                                  cursor: "default",
                                  width: "110px",
                                  height: "20px",
                                  marginTop: "-100px",
                                  marginLeft: "145px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  position: "absolute",
                                }}
                              >
                                <HelveticaLight
                                  style={{
                                    color: "white",
                                    paddingTop: "12px",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {i18n.t("Mas popular")}
                                </HelveticaLight>
                              </BlueButton>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {!isNaN(item.monthlyPrice) ? (
                              <HelveticaLight
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  textDecoration: "line-through",
                                }}
                              >
                                {item.monthlyPrice} €
                              </HelveticaLight>
                            ) : (
                              <div style={{ height: "25px" }}></div>
                            )}

                            {!isNaN(item.yearlyPrice) ? (
                              <div className="inline">
                                <HelveticaBold
                                  style={{
                                    fontSize: "42px",
                                    color: "#414141",
                                    marginTop: "-15px",
                                  }}
                                >
                                  {Math.trunc(
                                    parseFloat(item.yearlyPrice) / 12,
                                  )}{" "}
                                  €
                                </HelveticaBold>
                                <HelveticaBold
                                  style={{
                                    fontSize: "18px",
                                    color: "#414141",
                                    marginTop: "10px",
                                  }}
                                >
                                  &nbsp; por usuario/mes{" "}
                                </HelveticaBold>
                              </div>
                            ) : (
                              <HelveticaBold
                                style={{
                                  fontSize: "42px",
                                  color: "#414141",
                                  marginTop: "0px",
                                }}
                              >
                                {i18n.t("Consultar")}
                              </HelveticaBold>
                            )}
                          </>
                        )
                        /* */
                      }
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        position: "relative",
                        justifyContent: "center",
                        marginBottom: "30px",
                      }}
                    >
                      {
                        <BlueButton
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="SelectSubscription"
                          data-amplify-analytics-attrs={null}
                          onClick={handleManageSubscription}
                          style={{
                            width: "260px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor:
                              buttonCopy(item.planName) == "Gestionar" &&
                              "#ffd52b",
                          }}
                        >
                          <HelveticaLight
                            style={{
                              color: "white",
                              paddingTop: "12px",
                              fontSize: "14px",
                              fontWeight: "600",
                              color:
                                buttonCopy(item.planName) == "Gestionar"
                                  ? "black"
                                  : "white",
                            }}
                          >
                            {buttonCopy(item.planName)}
                          </HelveticaLight>
                        </BlueButton>
                      }
                    </div>

                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 0.05)",
                        marginBottom: "30px",
                      }}
                    ></div>
                    {item.mainFeatures.map((feature, index) => {
                      return (
                        <div
                          style={{
                            marginLeft: "20px",
                            marginRight: "20px",
                            marginBottom: "15px",
                            marginTop: "-10px",
                          }}
                          className="inline"
                          key={index}
                        >
                          <CheckCircleOutlineIcon
                            style={{
                              color: "#414141",
                              width: "18px",
                              heigth: "18px",
                              marginTop: "-2px",
                              marginRight: "10px",
                            }}
                          />
                          <HelveticaBold
                            style={{
                              color: "#414141",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {feature}
                          </HelveticaBold>
                        </div>
                      )
                    })}
                  </CardPricing>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </div>
      {/*
                showComparativa==false ?
            
            <div className="inline" style={{width:"900px", justifyContent:'center', marginBottom:'0px', marginTop:'20px'}}>
                    <WhiteButton onClick={()=>{setShowComparativa(true)}}>
                        <HelveticaBold style={{color:'#414141', fontSize:'14px', fontWeight:'600', paddingTop:'14px'}}>{i18n.t("Ver toda la comparativa")}</HelveticaBold>
                        <ArrowCircleDownIcon style={{marginLeft:'4px', width:'20px', height:'20px'}}/>
                    </WhiteButton>
            </div>
            :
            <div className="inline" style={{width:"900px", justifyContent:'center', marginBottom:'0px', marginTop:'20px'}}>
            <WhiteButton onClick={()=>{setShowComparativa(false)}}>
                <HelveticaBold style={{color:'#414141', fontSize:'14px', fontWeight:'600', paddingTop:'14px'}}>{i18n.t("Cerrar la comparativa")}</HelveticaBold>
                <ArrowCircleUpIcon style={{marginLeft:'4px', width:'20px', height:'20px'}}/>
            </WhiteButton>
    </div>
            */}
      {/*
                showComparativa ?
                <>
                  <div className="inline" style={{width:"920px", justifyContent:'center', marginBottom:'0px', marginTop:'20px', backgroundColor:'#EFEFEF', height:'166px', marginLeft:'0px'}}>
                  <Box position="relative" zIndex={2} >
                  <Grid container spacing={4} justifyContent="center" style={{display:'flex-grid'}}>
                  <Grid item xs={2}>
                                        <CardPricing style={{backgroundColor:'transparent', border:'none', boxShadow:'none', width:'160px'}}>
                                            </CardPricing>
                                    </Grid>
                    
                                    <Grid item xs={2}>
                                        <CardPricing style={{backgroundColor:'transparent', border:'none', boxShadow:'none', width:'160px'}}>
                                            </CardPricing>
                                    </Grid>
                  {
                            data.map((item, index) => {
                                return (
                                    <Grid item xs={2.4} key={index}>
                                        <CardPricing style={{backgroundColor:'transparent', border:'none', boxShadow:'none', width:'160px'}}>
                                            <div style={{width:'100%', position:'relative', display:'flex', textAlign:'center', justifyContent:'center'}}>
                                                <HelveticaBold style={{fontSize:'20px', color:'#414141', marginTop:'15px', textTransform:'uppercase'}}>{item.externalName}</HelveticaBold>
                                            </div>
                                           
                                            <div style={{marginLeft:'10px'}}>
                                                {
                                                    anual==false ? (
                                                        <>
                                                        {item.monthlyPrice!=null ?
                                                        <div className="inline">
                                                        <HelveticaBold style={{fontSize:'32px', color:'#414141', marginTop:'-25px', marginLeft:'20px'}}>{item.monthlyPrice} €</HelveticaBold>
                                                        <HelveticaBold style={{fontSize:'18px', color:'#414141', marginTop:'-10px', marginLeft:'0px'}}>{anual ? '/año' : '/mes' }</HelveticaBold>
                                                        </div>
                                                        : <HelveticaBold style={{fontSize:'32px', color:'#414141', marginTop:'-25px'}}>{i18n.t('Consultar')}</HelveticaBold>
                                                        }
                                                       
                                                        </>)
                                                        :
                                                        (
                                                            <>
                                                        {item.yearlyPrice!=null ? 
                                                            <HelveticaLight style={{fontSize:'16px', fontWeight:'400', textDecoration:'line-through'}}>{item.monthlyPrice} €</HelveticaLight>
                                                            : <div style={{height:'25px'}}></div>}
                                                            {item.normalRealPrice!=null ?
                                                            <div className="inline">
                                                            <HelveticaBold style={{fontSize:'32px', color:'#414141', marginTop:'-15px'}}>{Math.trunc(parseFloat(item.yearlyPrice)/12)} €</HelveticaBold>
                                                            <HelveticaBold style={{fontSize:'18px', color:'#C4C4C4', marginTop:'0px'}}> &nbsp; /mes </HelveticaBold>
                                                            </div>
                                                            : <HelveticaBold style={{fontSize:'32px', color:'#414141', marginTop:'0px'}}>{i18n.t('Consultar')}</HelveticaBold>
                                                            }
                                                        
                                                            </>)

                                                }
                                                <div style={{width:'100%', position:'relative', display:'flex', textAlign:'center', justifyContent:'center', marginTop:'-10px'}}>
                                                <BlueButton onClick={(e)=>{item.planName!='Enterprise' ?  handlePayment(e, item.planName) : window.open('https://calendly.com/nicolas-tribboo/tribboo-demo-preguntas', '_blank')}} 
                                                    style={{width:'113px', marginLeft:'-4px', height:'26px', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'2px', borderRadius:'4px', backgroundColor: buttonCopy(item.planName)=='Gestionar' && '#ffd52b'}}
                                                ><HelveticaLight style={{color:'white', paddingTop:'12px', fontSize:'13px', fontWeight:'700', backgroundColor: buttonCopy(item.planName)=='Gestionar' && '#ffd52b', color: buttonCopy(item.planName)=='Gestionar' ? 'black' : 'white'}}> {buttonCopy(item.planName)}</HelveticaLight></BlueButton> 
                                                </div>
                                               
                                            </div>
                                           
                                          </CardPricing>
                                    </Grid>
                                )
                            })
                       }
                       </Grid>
                       </Box>
                  </div>
                  <div className="inline" style={{width:"900px", justifyContent:'center', marginBottom:'0px', marginTop:'10px', marginLeft:'20px'}}>
                    <ComparativeTable/>
                </div>
                </>
                :null
             */}
    </>
  )
}

SubscriptionComponent.propTypes = {
  anual: PropTypes.bool,
  setAnual: PropTypes.func,
}

export default SubscriptionComponent
