import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { CircularProgress, Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import i18n from "i18n"
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "444px",
    height: "450px",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,

    overflowY: "hidden",
    overflowX: "hidden",
  },
}))

const ModalDestacarPalabras = ({
  open,
  setOpen,
  setPartidaState,
  partidaState,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  const [loading, setLoading] = useState(false)
  const referenciaComment = useRef(null)
  console.log(partidaState.comment, "eeeeee")

  useEffect(() => {
    if (referenciaComment.current) {
      if (partidaState.comment) {
        referenciaComment.current.innerText = partidaState.comment
      }
    }
  }, [open, referenciaComment])

  const [palabrasDestacadas, setPalabrasDestacadas] = useState("")

  const onChangePalabrasDestacadas = e => {
    setPalabrasDestacadas(e.target.value)
  }

  const savePalabrasDestacadas = () => {
    setLoading(true)
    setPartidaState({ ...partidaState, comment: palabrasDestacadas })
    setOpen(false)
    setLoading(false)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div id="totalDiv">
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setOpen(false)
            }}
            style={{
              marginTop: "0px",
              paddingLeft: "6px",
              marginRight: "-10px",
            }}
          ></CloseIcon>
          <div
            id="DivTitleEliminar"
            style={{ marginTop: "24px", justifyContent: "center" }}
          >
            <p
              id="centradoBudget"
              style={{
                fontSize: "20px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              {i18n.t("Define las palabras destacadas")}{" "}
            </p>
          </div>
          <div
            id="DivTitleEliminar"
            style={{ marginTop: "12px", justifyContent: "center" }}
          >
            <p
              id="centradoBudget"
              style={{
                fontSize: "12px",
                marginTop: "0px",
                marginBottom: "20px",
                fontWeight: "300",
              }}
            >
              {" "}
              Las palabras descritas a continuación apareceran tras la
              descripción de la partida en el presupuesto marcadas en color
              rojo.
            </p>
          </div>

          <textarea
            ref={referenciaComment}
            suppressContentEditableWarning={true}
            onChange={e => {
              onChangePalabrasDestacadas(e)
            }}
            id="descripcionInput"
            onBlur={e => {
              onChangePalabrasDestacadas(e)
            }}
            name="comment"
            placeholder="Escribe aquí las palabras destacadas"
            style={{
              width: "100%",
              height: "50%",
              position: "relative",
              paddingTop: "10px",
              paddingLeft: "10px",
              resize: "none",
            }}
          >
            {partidaState.comment}
          </textarea>

          {loading ? (
            <CircularProgress
              size={24}
              style={{ marginTop: "30px", marginLeft: "170px" }}
            />
          ) : (
            <div
              id="centerButton"
              style={{ marginTop: "30px", marginBottom: "6px" }}
            >
              <button
                style={{ height: "38px", width: "103px" }}
                type="button"
                className="AddContact"
                onClick={savePalabrasDestacadas}
              >
                {i18n.t("Guardar")}
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

ModalDestacarPalabras.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  partidaInfo: PropTypes.object.isRequired,
  setPartidaState: PropTypes.func.isRequired,
  partidaState: PropTypes.object.isRequired,
}

export default ModalDestacarPalabras
