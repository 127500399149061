import React, { useContext, useEffect, useState } from "react"
import i18n from "i18n"
import UploadBankPrice from "./modals/uploadBankPrice"
import WaitingModalBanco from "./modals/WaitingModalBanco"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import AuthContext from "context/auth/authContext"
import { PoppinsNormal } from "common/typographies"
import excel from "assets/customIcons/excel.svg"
import bc3 from "assets/customIcons/bc3.svg"
import tribboo from "assets/customIcons/logos/startPlanhopper.svg"
import higherThan from "assets/customIcons/ArrowHigherThan.svg"
import ImportDatabaseSuccess from "./modals/ImportDatabaseSuccess"
import UploadExcelModal from "./modals/uploadExcel"
import ModalWaitingExcel from "pages/Dashboard/ModalWaitingExcel"
const SelectBDType = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openBc3, setOpenBc3] = useState(false)
  const [waitModal, setWaitModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const {
    createInitialStructure,
    getMyBankPrices,
    myPricesBank,
    addBankChapter,
  } = useContext(BankPricesContext)
  const { user, freeTrialExpired, openFreeTrialExpire } =
    useContext(AuthContext)
  const [openModalForExcel, setOpenModalForExcel] = useState(false)
  const [openWaitModalForExcel, setOpenWaitModalForExcel] = useState(false)
  const handleCreateStructure = async () => {
    setLoading(true)
    await createInitialStructure(user.sub)
    setLoading(false)
    await getMyBankPrices(user.sub)
  }

  const handleStart = async () => {
    setLoading(true)
    await addBankChapter("root", null, 0, user.sub, "true")
    setLoading(false)
  }
  useEffect(() => {
    if (freeTrialExpired) {
      openFreeTrialExpire()
    }
  }, [freeTrialExpired])
  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-50px",
              marginTop: "90px",
            }}
          >
            <div className="loader">
              <div className="ball"></div>
              <div className="ball"></div>
              <div className="ball"></div>
            </div>
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "10px",
            }}
          >
            <PoppinsNormal>
              {i18n.t("Estamos generando tu banco de precios...")}
            </PoppinsNormal>
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "10px",
              marginTop: "-15px",
            }}
          >
            <PoppinsNormal style={{ fontWeight: "400" }}>
              {i18n.t("Esta acción puede tardar unos segundos")}
            </PoppinsNormal>
          </div>
        </>
      ) : user ? (
        freeTrialExpired || user["custom:role"] === "WORKER" ? (
          <PoppinsNormal>
            {i18n.t("Para crear un banco de precios, debes actualizar tu plan")}
          </PoppinsNormal>
        ) : (
          <>
            <div
              className="CardProjects"
              onClick={() => {
                setOpenModalForExcel(true)
              }}
              style={{
                marginTop: "50px",
                marginLeft: "40px",
                cursor: "pointer",
                width: "85%",
                height: "110px",
                border: "1PX SOLID #C4C4C4",
              }}
            >
              <div id="CardContent">
                <div
                  className="ImgProyect"
                  style={{
                    marginLeft: "0px",
                    marginRight: "20px",
                    marginTop: "-3px",
                  }}
                >
                  <span>
                    <img src={excel} height="75px" width="150px" />
                  </span>
                </div>
                <div
                  id="CenterContentProyectCard"
                  style={{
                    marginLeft: "105px",
                    marginRight: "20px",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  <div
                    className="flex-grow-1 overflow-hidden"
                    style={{ marginRight: "-10px" }}
                  >
                    <div
                      id="AddProyecto"
                      style={{ fontSize: "18px", marginTop: "-5px" }}
                    >
                      {i18n.t("Cargar Documento excel")}
                    </div>
                    <p className="DescriptionText" style={{ fontSize: "14px" }}>
                      Crea tu propio banco desde un documento Excel.
                    </p>
                  </div>
                  <img
                    src={higherThan}
                    style={{ width: "24px", height: "15px", marginTop: "22px" }}
                  />
                </div>
              </div>
            </div>
            <div
              className="CardProjects"
              onClick={() => {
                setOpenBc3(true)
              }}
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                cursor: "pointer",
                width: "85%",
                height: "110px",
                border: "1PX SOLID #C4C4C4",
              }}
            >
              <div id="CardContent">
                <div
                  className="ImgProyect"
                  style={{
                    marginLeft: "0px",
                    marginRight: "20px",
                    marginTop: "-3px",
                  }}
                >
                  <span>
                    <img src={bc3} height="75px" width="150px" />
                  </span>
                </div>
                <div
                  id="CenterContentProyectCard"
                  style={{
                    marginLeft: "105px",
                    marginRight: "20px",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  <div
                    className="flex-grow-1 overflow-hidden"
                    style={{ marginRight: "-10px" }}
                  >
                    <div
                      id="AddProyecto"
                      style={{ fontSize: "18px", marginTop: "-5px" }}
                    >
                      {i18n.t("Cargar Fichero bc3")}
                    </div>
                    <p className="DescriptionText" style={{ fontSize: "14px" }}>
                      Crea tu banco desde un fichero bc3.
                    </p>
                  </div>
                  <img
                    src={higherThan}
                    style={{ width: "24px", height: "15px", marginTop: "22px" }}
                  />
                </div>
              </div>
            </div>
            <div
              onClick={handleStart}
              className="CardProjects"
              style={{
                marginTop: "30px",
                marginLeft: "40px",
                cursor: "pointer",
                width: "85%",
                height: "110px",
                border: "1px solid #C4C4C4",
              }}
            >
              <div id="CardContent">
                <div
                  className="ImgProyect"
                  style={{
                    marginLeft: "0px",
                    marginRight: "20px",
                    marginTop: "-3px",
                  }}
                >
                  <span>
                    <img src={tribboo} height="75px" width="150px" />
                  </span>
                </div>
                <div
                  id="CenterContentProyectCard"
                  style={{
                    marginLeft: "105px",
                    marginRight: "20px",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                >
                  <div
                    className="flex-grow-1 overflow-hidden"
                    style={{ marginRight: "-10px" }}
                  >
                    <div
                      id="AddProyecto"
                      style={{ fontSize: "18px", marginTop: "-5px" }}
                    >
                      {i18n.t("Comienza desde cero")}
                    </div>
                    <p
                      className="DescriptionText"
                      style={{ fontSize: "14px", marginRight: "0px" }}
                    >
                      {i18n.t(
                        "Crea tu propio banco de precios de forma manual",
                      )}{" "}
                    </p>
                  </div>
                  <img
                    src={higherThan}
                    style={{ width: "24px", height: "15px", marginTop: "22px" }}
                  />
                </div>
              </div>
            </div>
            {/* <div onClick={handleCreateStructure} className="CardProjects" style={{marginTop:'30px', marginLeft:'40px', cursor:'pointer', width:'85%', height:'110px', }} >
            <div id="CardContent">
                <div className="ImgProyect" style={{marginLeft:'0px', marginRight:'20px', marginTop:"-3px"}}>
                    <span>
                        <img src={tribboo}   height="75px" width='150px' />
                    </span>
                    </div>
                    <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'20px', textAlign:'left', fontSize:'14px'}}>
                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                        <div id="AddProyecto" style={{fontSize:'18px', marginTop:'-5px'}}>
                            {i18n.t("Comienza con Planhopper")}
                        </div>
                        <p className="DescriptionText" style={{fontSize:'14px', marginRight:'0px'}}>{i18n.t("Te facilitamos una estructura para que agregues tus precios de referencia")} </p>
                    </div>
                                        <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'22px' }}/>
                    </div>
            </div>
            </div> */}
          </>
        )
      ) : null}

      {openBc3 === true ? (
        <UploadBankPrice
          openModal={openBc3}
          setOpenModal={setOpenBc3}
          filesAccepted={[".bc3", ".BC3"]}
          setWaitModal={setWaitModal}
        />
      ) : null}
      {waitModal === true ? (
        <WaitingModalBanco
          openModal={waitModal}
          setOpenModal={setWaitModal}
          accountId={user.sub}
          setSuccessModal={setSuccessModal}
        />
      ) : null}
      {successModal === true ? (
        <ImportDatabaseSuccess open={successModal} setOpen={setSuccessModal} />
      ) : null}

      {openModalForExcel ? (
        <UploadExcelModal
          openModal={openModalForExcel}
          setOpenModal={setOpenModalForExcel}
          setWaitModal={setOpenWaitModalForExcel}
          bankPrice={myPricesBank}
          user={user?.sub}
        />
      ) : null}
      {openWaitModalForExcel && (
        <ModalWaitingExcel
          bankprice={myPricesBank}
          openModal={openWaitModalForExcel}
          setOpenModal={setOpenWaitModalForExcel}
          setSuccessModal={setSuccessModal}
          imComingFrom={"bankprices"}
          user={user?.sub}
        />
      )}
    </>
  )
}

export default SelectBDType
