import i18n from "i18n"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useContext } from "react"
import { useHistory, Link } from "react-router-dom"
import PropTypes from "prop-types"
import ProjectContext from "context/projects/projectContext"
function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "500px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const SuccessModal = ({
  setSuccessModal,
  imTemplate,
  successModal,
  project,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const history = useHistory()
  const projectContext = useContext(ProjectContext)
  const { clearUploadProcess } = projectContext

  const goToBudgeting = () => {
    setSuccessModal(false)
    clearUploadProcess()
    if (imTemplate) {
      history.push("/bancodeprecios/templateV2/" + res.projectId)
    } else {
      history.push("/budgeting/" + project.projectId)
    }
  }
  return (
    <>
      <Modal
        open={successModal}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <div id="centerPaco">
            <img
              src={"https://media.tribboo.co/images/paquitoListo.png"}
              style={{ width: "175px", height: "175px" }}
            ></img>
          </div>

          <div className="modal-body">
            <div>
              <div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    textAlign: "center",
                    fontFamily: "Roboto",
                  }}
                >
                  {i18n.t("Está listo!")}
                </p>
                <p
                  style={{
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "14px",
                    lineHeight: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                  }}
                >
                  {i18n.t("Explora nuestras funcionalidades disponibles")}
                  <br /> {i18n.t("con los datos de tu nuevo proyecto")}
                </p>
                <div
                  id=""
                  style={{
                    width: "100%",
                    marginBottom: "35px",
                    marginTop: "30px",
                  }}
                >
                  <div id="muypronto2">{i18n.t("muy pronto")}</div>
                  <button
                    className="AddProjectDisabled"
                    style={{
                      marginRight: "10%",
                      marginLeft: "30px",
                      paddingRight: "15px",
                      width: "180px",
                      position: "absolute",
                    }}
                  >
                    {i18n.t("Planning")}
                  </button>
                  <button
                    className="AddProject"
                    style={{
                      paddingRight: "15px",
                      marginLeft: "220px",
                      cursor: "pointer",
                      position: "relative",
                      bottom: "0px",
                      width: "180px",
                      height: "50px",
                    }}
                    onClick={goToBudgeting}
                  >
                    {i18n.t("Presupuesto")}
                  </button>
                </div>
                <p className="text-center">
                  <Link
                    to="/dashboard"
                    onClick={() => {
                      location.reload()
                    }}
                    style={{
                      color: "black",
                      fontSize: "14px",
                      lineHeight: "16px",
                      fontFamily: "Roboto",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        lineHeight: "16px",
                        fontFamily: "Roboto",
                        cursor: "pointer",
                      }}
                    >
                      {i18n.t("Ir al panel principal")}
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
SuccessModal.propTypes = {
  setSuccessModal: PropTypes.func.isRequired,
  successModal: PropTypes.bool.isRequired,
  project: PropTypes.object,
  imTemplate: PropTypes.bool,
}
export default SuccessModal
