import React, { useContext, useEffect } from "react"
import { Modal, CircularProgress } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { useState } from "react"
import i18n from "i18n"
import CloseIcon from "@mui/icons-material/Close"
import { VersionsTable } from "common/tables"
import { PoppinsNormal } from "common/typographies"
import CapituloModal from "../chapters/CapituloModal"
import ModalConfirmPaste from "./modalConfirmPaste"
import AuthContext from "context/auth/authContext"
import ModalSuccesPaste from "./modalSuccesPaste"
import FavoritesBPContext from "context/favoritesBP/favoritesBPContext"
import Budget2023Context from "context/budget2023/Budget2023Context"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "850px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "550px",
    height: "550px",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalPasteSelection = ({
  open,
  setOpen,
  project,
  setOpenPrincipal,
  tipo,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const { getBudgetTree, budgetTree, loadingTree } =
    useContext(Budget2023Context)
  const {
    selectedCategories2,
    importChaptersFromDatabase,
    categoriesChecked,
    partsChecked,
    createPartsOnBudget,
  } = useContext(FavoritesBPContext)
  useEffect(() => {
    if (project) {
      getBudgetTree(project.projectId)
    }
  }, [project])
  const [capituloSelected, setCapituloSelected] = useState({})
  const [openFinal, setOpenFinal] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const { user } = useContext(AuthContext)
  const handleConfirm = async () => {
    if (capituloSelected.capituloInfo) {
      setLoading(true)
      if (categoriesChecked.length > 0) {
        await importChaptersFromDatabase(
          selectedCategories2,
          project.projectId,
          user.sub,
          capituloSelected.capituloInfo.chapterId,
          tipo,
        )
      } else {
        await createPartsOnBudget(
          user.sub,
          partsChecked,
          project.projectId,
          capituloSelected.capituloInfo.chapterId,
          capituloSelected,
        )
      }
      setLoading(false)
      setOpenFinal(true)
    } else {
      setLoading(true)
      setOpenModalConfirm(true)
      setLoading(false)
    }
  }

  return (
    <>
      <Modal open={open} onClose={() => null}>
        <div style={modalStyle} className={classes.paper}>
          <div
            id="principalOptions"
            style={{
              marginTop: "20px",
              marginBottom: "15px",
              textAlign: "left",
            }}
          >
            <CloseIcon
              id="cerrar"
              style={{ marginTop: "-15px", right: "-10px" }}
              onClick={() => {
                setOpen(false)
              }}
            ></CloseIcon>
            <p
              id="titles"
              style={{
                textAlign: "CENTER",
                fontSize: "20px",
                marginBottom: "35px",
              }}
            >
              {i18n.t(
                "Selecciona el capitulo dónde quieres pegar tu seleccion",
              )}
            </p>

            <VersionsTable>
              <tr>
                <th style={{ width: "430px" }} colSpan="2">
                  {i18n.t("Nombre")}
                </th>
                <th style={{ width: "330px" }}>
                  {i18n.t("Dirección de obra")}
                </th>
                <th style={{ width: "140px" }}>{i18n.t("Etiqueta")}</th>
              </tr>
              {project.name ? (
                <tr style={{ cursor: "pointer", background: "#FFE992" }}>
                  <td style={{ width: "80px" }}>
                    <img
                      src={project.projectImage}
                      style={{
                        width: "45px",
                        height: "45px",
                        marginRight: "0px",
                      }}
                    />
                  </td>
                  <td
                    style={{
                      fontWeight: "500",
                      color: "#414141",
                      paddingLeft: "0PX",
                    }}
                  >
                    {project.name}
                  </td>
                  <td style={{ color: "#414141" }}> {project.orgAddress}</td>
                  <td>
                    {project.tags ? (
                      project.tags.length > 0 ? (
                        <div
                          style={{ width: "fit-content", paddingRight: "10px" }}
                          id="color1"
                          className="tag"
                        >
                          {project.tags[0]}
                        </div>
                      ) : null
                    ) : null}
                  </td>
                </tr>
              ) : null}
            </VersionsTable>
            <div
              style={{
                height: "290px",
                overflow: "auto",
                paddingBottom: "10px",
              }}
            >
              {loadingTree ? (
                <>
                  <div
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      position: "relative",
                      display: "flex",
                      marginLeft: "-100px",
                      marginTop: "0px",
                    }}
                  >
                    <div className="loader">
                      <div className="ball"></div>
                      <div className="ball"></div>
                      <div className="ball"></div>
                    </div>
                  </div>
                  <div
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      position: "relative",
                      display: "flex",
                      marginLeft: "-30px",
                    }}
                  >
                    <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
                  </div>
                  <div
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      textAlign: "center",
                      position: "relative",
                      display: "flex",
                      marginLeft: "-30px",
                      marginTop: "5px",
                    }}
                  >
                    <PoppinsNormal style={{ fontWeight: "400" }}>
                      {i18n.t("Esta acción puede tardar unos segundos")}
                    </PoppinsNormal>
                  </div>
                </>
              ) : budgetTree.subcapitulos.length > 0 ? (
                budgetTree.subcapitulos.map((subcapitulo, index) => {
                  return (
                    <CapituloModal
                      margin={0}
                      key={index}
                      capitulo={subcapitulo}
                      capituloSelected={capituloSelected}
                      setCapituloSelected={setCapituloSelected}
                    />
                  )
                })
              ) : (
                <PoppinsNormal>
                  Todavia no tienes capitulos creados
                </PoppinsNormal>
              )}
            </div>
          </div>
          {loading == false ? (
            <button
              className="AddProject"
              style={{ left: "40%" }}
              onClick={() => {
                handleConfirm()
              }}
            >
              {i18n.t("Confirmar")}
            </button>
          ) : (
            <div>
              <CircularProgress size={24} style={{ left: "40%" }} />
            </div>
          )}
        </div>
      </Modal>
      <ModalConfirmPaste
        successModal={openModalConfirm}
        setSuccessModal={setOpenModalConfirm}
        setOpenPrincipal={setOpenPrincipal}
        project={project}
        setOpenSegundo={setOpen}
        tipo={tipo}
      />
      <ModalSuccesPaste
        successModal={openFinal}
        setSuccessModal={setOpenFinal}
        setOpenPrincipal={setOpenPrincipal}
        project={project}
        setOpenSegundo={setOpen}
      />
    </>
  )
}

ModalPasteSelection.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  setOpenPrincipal: PropTypes.func.isRequired,
  tipo: PropTypes.string.isRequired,
}
export default ModalPasteSelection
