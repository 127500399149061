import React, { useContext, useEffect } from "react"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { useState } from "react"
import i18n from "i18n"
import CloseIcon from "@mui/icons-material/Close"
import { VersionsTable } from "common/tables"
import ProjectContext from "context/projects/projectContext"
import ModalPasteSelection from "./modalPasteSelection"
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "850px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "550px",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalSelectProject = ({ open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { projects, getProjects } = useContext(ProjectContext)

  const [projectSelected, setProjectSelected] = useState({})
  const [open2, setOpen2] = useState(false)
  const { myPricesBank, getSelectedChapters } = useContext(
    BankPricesReferenceContext,
  )

  useEffect(() => {
    if (open) {
      getSelectedChapters([], [], myPricesBank.subcapitulos, true)
    }
  }, [open])
  useEffect(() => {
    getProjects()
  }, [])

  const handleProject = (project, index) => {
    setProjectSelected(project)
    setOpen2(true)
  }

  return (
    <>
      <Modal open={open} onClose={() => null}>
        <div style={modalStyle} className={classes.paper}>
          <div
            id="principalOptions"
            style={{
              marginTop: "20px",
              marginBottom: "15px",
              textAlign: "left",
            }}
          >
            <CloseIcon
              id="cerrar"
              style={{ marginTop: "-15px", right: "-10px" }}
              onClick={() => {
                setOpen(false)
              }}
            ></CloseIcon>
            <p
              id="titles"
              style={{
                textAlign: "CENTER",
                fontSize: "20px",
                marginBottom: "35px",
              }}
            >
              {i18n.t("Selecciona el proyecto dónde quieres pegar el capítulo")}
            </p>

            <VersionsTable>
              <tr>
                <th style={{ width: "430px" }} colSpan="2">
                  {i18n.t("Nombre")}
                </th>
                <th style={{ width: "330px" }}>
                  {i18n.t("Dirección de obra")}
                </th>
                <th style={{ width: "140px" }}>{i18n.t("Etiqueta")}</th>
              </tr>
              {projects.length > 0
                ? projects.map((project, index) =>
                    project.name ? (
                      <tr
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleProject(project, index)
                        }}
                      >
                        <td style={{ width: "80px" }}>
                          <img
                            src={project.projectImage}
                            style={{
                              width: "45px",
                              height: "45px",
                              marginRight: "0px",
                            }}
                          />
                        </td>
                        <td
                          style={{
                            fontWeight: "500",
                            color: "#414141",
                            paddingLeft: "0PX",
                          }}
                        >
                          {project.name}
                        </td>
                        <td style={{ color: "#414141" }}>
                          {" "}
                          {project.orgAddress}
                        </td>
                        <td>
                          {project.tags ? (
                            project.tags.length > 0 ? (
                              <div
                                style={{
                                  width: "fit-content",
                                  paddingRight: "10px",
                                }}
                                id="color1"
                                className="tag"
                              >
                                {project.tags[0]}
                              </div>
                            ) : null
                          ) : null}
                        </td>
                      </tr>
                    ) : null,
                  )
                : null}
            </VersionsTable>
          </div>
        </div>
      </Modal>
      <ModalPasteSelection
        open={open2}
        setOpen={setOpen2}
        project={projectSelected}
        setOpenPrincipal={setOpen}
      />
    </>
  )
}

ModalSelectProject.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}
export default ModalSelectProject
