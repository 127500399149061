import React, { useState } from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import UploadRoundedIcon from "@mui/icons-material/UploadRounded"
import i18n from "i18n"
import { useUtils } from "hooks/useUtils"
import { CircularProgress } from "@mui/material"
import { HelveticaBold } from "common/typographies"
import { Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { makeStyles } from "@material-ui/core/styles"
import defa from "assets/images/icons/00_DEFAULT.png"
import { Link } from "react-router-dom"
import { nanoid } from "nanoid"
import { Card, Row, Col } from "reactstrap"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "500px",
    maxHeight: "calc(100vh - 100px)",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))
const FileDropzone = ({ partidaState, setPartidaState, open, setOpen }) => {
  const { generateFileUrl } = useUtils()
  const [loading, setLoading] = React.useState(false)
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles({ defa })
  const [selectedFiles, setSelectedFiles] = useState([])

  async function onChange(files) {
    setLoading(true)
    let aux = files.map(file => {
      return Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setSelectedFiles([...selectedFiles, ...aux])
    setLoading(false)
  }

  const removeFile = f => {
    let filteredArray = selectedFiles.filter(item => item !== f)
    setSelectedFiles(filteredArray)
  }

  const onUpload = async e => {
    setLoading(true)
    e.preventDefault()
    let auxImages = partidaState.files ? partidaState.files : []
    for (let i = 0; i < selectedFiles.length; i++) {
      await generateFileUrl(selectedFiles[i], "protected").then(res => {
        auxImages.push({
          name: selectedFiles[i].name,
          url: res,
          fileId: nanoid(),
        })
      })
    }
    setPartidaState({ ...partidaState, files: auxImages })
    setLoading(false)
    setOpen(false)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div style={modalStyle} className={classes.paper}>
        <CloseIcon
          style={{ marginTop: "-5px", marginRight: "-15px" }}
          id="cerrar"
          onClick={() => setOpen(false)}
        ></CloseIcon>
        {loading ? (
          <>
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "grid",
                marginTop: "30px",
                marginBottom: "-10px",
              }}
            >
              <CircularProgress
                style={{
                  width: 100,
                  height: 100,
                  marginLeft: "15px",
                  color: "#414141",
                  marginBottom: "20px",
                }}
              />
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "24px",
                  marginLeft: "10px",
                  marginTop: "0px",
                }}
              >
                {i18n.t("Cargando...")}
              </HelveticaBold>
            </div>
          </>
        ) : (
          <>
            <Dropzone
              className="dropzone"
              accept={[".jpeg", ".png", ".jpg", ".pdf"]}
              onDrop={acceptedFiles => {
                onChange(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone2" style={{ marginTop: "25px" }}>
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                      }}
                    >
                      {i18n.t("Arrastra archivos o explora tu ordenador")}
                    </p>
                    <div className="mb-3">
                      <UploadRoundedIcon
                        style={{ height: "60px", width: "60px" }}
                      />
                    </div>
                    <p
                      style={{
                        fontWeight: "300",
                        fontSize: "14px",
                        lineHeight: "16px",
                        textAlign: "center",
                      }}
                    >
                      {i18n.t(
                        "El formato en los que deberás cargar los archivos debe ser .jpeg, .png, .jpg, .pdf",
                      )}
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <i className="bx bx-file" />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                        <CloseIcon
                          style={{
                            marginRight: "1px",
                            width: "45px",
                            marginTop: "-20px",
                          }}
                          onClick={() => {
                            removeFile(f)
                          }}
                        />
                      </Row>
                    </div>
                  </Card>
                )
              })}
            </div>
            <div id="centerButton">
              {loading ? (
                <CircularProgress
                  size={24}
                  style={{ marginTop: "0px", marginBottom: "5px" }}
                />
              ) : selectedFiles.length == 0 ? (
                <button
                  type="button"
                  className="AddProjectDisabled"
                  style={{ marginTop: "0px", marginBottom: "5px" }}
                >
                  {i18n.t("Subir")}
                </button>
              ) : (
                <button
                  type="button"
                  className="AddContact"
                  onClick={e => {
                    onUpload(e)
                  }}
                  style={{ marginTop: "0px", marginBottom: "5px" }}
                >
                  {i18n.t("Subir")}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
FileDropzone.propTypes = {
  partidaState: PropTypes.object,
  setPartidaState: PropTypes.func,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default FileDropzone
