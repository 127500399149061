import { isNil } from "lodash"
import ChapterCertHistory from "./ChapterCertHistory"

class Certification {
  constructor(data) {
    this.id = data.id
    this.status = data.status
    this.revisionDate = data.revision_date
    this.certificationUserId = data.certification_user_id
    this.certItems = isNil(data.cert_items) ? {} : data.cert_items
    this.chapters = isNil(data.chapters)
      ? []
      : data.chapters.map(chapter => new ChapterCertHistory(chapter))
    this.totalCertPrice = 0
  }

  calculateTotalCertPrice = () => {
    const result = this.chapters.reduce(
      (total, chapter) => total + chapter.updateTotalChapterPrice(),
      0,
    )
    this.totalCertPrice = parseFloat(result.toFixed(2))
  }
}

export default Certification
