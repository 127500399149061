import React, { useState, useRef } from "react"
import { Grid } from "@material-ui/core"
import { HelveticaBold } from "common/typographies"
import cover from "assets/customIcons/pdfs/cover.png"
import contraPortada from "assets/customIcons/pdfs/contraPortada.png"
import content from "assets/customIcons/pdfs/content.png"
import { Document, Page, pdfjs } from "react-pdf"
import CoverManagement from "./coverManagement"
import ContentManagement from "./contentManagement"
import coverTemplatePDF from "./coverTemplate.pdf"
import ContraPortadaManagement from "./contraPortadaManagement"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const typeView = {
  selectPage: 0,
  detailedView: 1,
}

const pageSelected = {
  cover: 0,
  content: 1,
  contraPortada: 2,
}

const PrincipalView = () => {
  const [viewSelected, setViewSelected] = React.useState(typeView.detailedView)
  const [selectedPage, setSelectedPage] = React.useState(pageSelected.cover)

  const handleSelectPage = page => {
    if (viewSelected === typeView.selectPage) {
      setViewSelected(typeView.detailedView)
    }
    setSelectedPage(page)
  }

  const [usingMyCover, setUsingMyCover] = useState(false)

  const [selectedFiles, setselectedFiles] = useState([])
  const [coverPdf, setCoverPdf] = useState(null)
  const [numPages, setNumPages] = useState(1)
  const componentRef = useRef()
  const [waterMark, setWaterMark] = useState(null)
  const [selectedFiles2, setselectedFiles2] = useState([]) //to not lose the files when changing the page
  const [selectedFiles3, setselectedFiles3] = useState([]) //to not lose the files when changing the page
  const [contraPortadaPDF, setContraPortadaPDF] = useState(null)
  const [usingMyContraPortada, setUsingMyContraPortada] = useState(false)
  const [useLogoInContraPortada, setUseLogoInContraportada] = useState(false)

  return (
    <>
      <div>
        {viewSelected === typeView.selectPage ? (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Grid container spacing={2} columns={{ md: 12 }} style={{}}>
              <Grid
                item
                md={4}
                style={{
                  backgroundColor: "transparent",
                  marginBottom: "0px",
                  height: "83vh",
                  minHeight: "550px",
                }}
              >
                <div
                  className="selectCard"
                  onClick={() => {
                    handleSelectPage(pageSelected.cover)
                  }}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #C4C4C4",
                    borderRadius: "12px",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <img src={cover} style={{ width: "90%" }} />
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      fontSize: "20px",
                      marginTop: "20PX",
                    }}
                  >
                    PORTADA
                  </HelveticaBold>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                style={{
                  backgroundColor: "transparent",
                  marginBottom: "0px",
                  height: "83vh",
                  minHeight: "550px",
                }}
              >
                <div
                  className="selectCard"
                  onClick={() => {
                    handleSelectPage(pageSelected.content)
                  }}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #C4C4C4",
                    borderRadius: "12px",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <img src={content} style={{ width: "90%" }} />
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      fontSize: "20px",
                      marginTop: "20PX",
                    }}
                  >
                    CONTENIDO
                  </HelveticaBold>
                </div>
              </Grid>
              <Grid
                item
                md={4}
                style={{
                  backgroundColor: "transparent",
                  marginBottom: "0px",
                  height: "83vh",
                  minHeight: "550px",
                }}
              >
                <div
                  className="selectCard"
                  onClick={() => {
                    handleSelectPage(pageSelected.contraPortada)
                  }}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    border: "1px solid #C4C4C4",
                    borderRadius: "12px",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <img src={contraPortada} style={{ width: "90%" }} />
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      fontSize: "20px",
                      marginTop: "20PX",
                    }}
                  >
                    CONTRAPORTADA
                  </HelveticaBold>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Grid
            container
            spacing={0}
            columns={{ md: 12 }}
            style={{
              marginLeft: "30px",
              marginRight: "30px",
              marginTop: "0px",
            }}
          >
            <Grid
              item
              md={2}
              style={{
                display: "flex",
                paddingTop: "15px",
                padding: "10PX",
                marginLeft: "-30px",
                borderRight: "1px solid #C4C4C4",
                flexDirection: "column",
                backgroundColor: "transparent",
                height: "92vh",
                minHeight: "550px",
              }}
            >
              <div
                onClick={() => {
                  handleSelectPage(pageSelected.cover)
                }}
                className="selectCard"
                style={{
                  display: "flex",
                  height: "35px",
                  cursor: "pointer",
                  position: "relative",
                  marginBottom: "15px",
                  width: "100%",
                }}
              >
                <img
                  src={cover}
                  style={{
                    height: "70%",
                    marginTop: "5px",
                    marginLeft: "5px",
                    opacity: selectedPage == pageSelected.cover ? "1" : "0.5",
                  }}
                />
                <HelveticaBold
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    marginTop: "6PX",
                    marginLeft: "5PX",
                    opacity: selectedPage == pageSelected.cover ? "1" : "0.5",
                  }}
                >
                  Portada
                </HelveticaBold>
              </div>
              <div
                onClick={() => {
                  handleSelectPage(pageSelected.content)
                }}
                className="selectCard"
                style={{
                  display: "flex",
                  height: "35px",
                  cursor: "pointer",
                  position: "relative",
                  marginBottom: "15px",
                  width: "100%",
                }}
              >
                <img
                  src={content}
                  style={{
                    height: "70%",
                    marginTop: "5px",
                    marginLeft: "5px",
                    opacity: selectedPage == pageSelected.content ? "1" : "0.5",
                  }}
                />
                <HelveticaBold
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    marginTop: "6PX",
                    marginLeft: "5PX",
                    opacity: selectedPage == pageSelected.content ? "1" : "0.5",
                  }}
                >
                  Contenido
                </HelveticaBold>
              </div>
              <div
                onClick={() => {
                  handleSelectPage(pageSelected.contraPortada)
                }}
                className="selectCard"
                style={{
                  display: "flex",
                  height: "35px",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={contraPortada}
                  style={{
                    height: "70%",
                    marginTop: "5px",
                    marginLeft: "5px",
                    opacity:
                      selectedPage == pageSelected.contraPortada ? "1" : "0.5",
                  }}
                />
                <HelveticaBold
                  style={{
                    color: "#414141",
                    fontSize: "14px",
                    marginTop: "6PX",
                    marginLeft: "5PX",
                    opacity:
                      selectedPage == pageSelected.contraPortada ? "1" : "0.5",
                  }}
                >
                  Contraportada
                </HelveticaBold>
              </div>
            </Grid>
            <Grid
              item
              md={4}
              style={{
                marginTop: "0px",
                backgroundColor: "transparent",
                marginBottom: "0px",
                borderRight: "1px solid #C4C4C4",
                height: "92vh",
                minHeight: "550px",
              }}
            >
              {selectedPage === pageSelected.cover ? (
                <CoverManagement
                  coverPdf={coverPdf}
                  usingMyCover={usingMyCover}
                  setUsingMyCover={setUsingMyCover}
                  selectedFiles={selectedFiles}
                  setselectedFiles={setselectedFiles}
                  setCoverPdf={setCoverPdf}
                />
              ) : selectedPage === pageSelected.content ? (
                <ContentManagement
                  selectedFiles={selectedFiles2}
                  setselectedFiles={setselectedFiles2}
                  waterMark={waterMark}
                  setWaterMark={setWaterMark}
                />
              ) : (
                <ContraPortadaManagement
                  selectedFiles={selectedFiles3}
                  setselectedFiles={setselectedFiles3}
                  contraPortadaPDF={contraPortadaPDF}
                  setContraPortadaPDF={setContraPortadaPDF}
                  usingMyContraPortada={usingMyContraPortada}
                  setUsingMyContraPortada={setUsingMyContraPortada}
                  useLogoInContraPortada={useLogoInContraPortada}
                  setUseLogoInContraportada={setUseLogoInContraportada}
                />
              )}
            </Grid>
            <Grid
              item
              md={6}
              style={{
                marginTop: "0px",
                backgroundColor: "transparent",
                marginBottom: "0px",
                height: "92vh",
                minHeight: "550px",
              }}
            >
              {selectedPage === pageSelected.cover ? (
                <div
                  style={{
                    height: "100%",
                    marginLeft: "0px",
                    marginRight: "20px",
                    display: "flex",
                    justifyContent: "center",
                    overflowY: "auto",
                  }}
                >
                  {usingMyCover ? (
                    coverPdf != null && (
                      <Document
                        ref={componentRef}
                        file={coverPdf}
                        onLoadError={console.error}
                        style={{ overflowY: "auto" }}
                      >
                        {[...Array(numPages)].map((elementInArray, index) => (
                          <Page
                            scale={0.75}
                            key={index}
                            pageNumber={index + 1}
                          />
                        ))}
                      </Document>
                    )
                  ) : (
                    <Document
                      ref={componentRef}
                      file={coverTemplatePDF}
                      onLoadError={console.error}
                      style={{ overflowY: "auto" }}
                    >
                      {[...Array(numPages)].map((elementInArray, index) => (
                        <Page scale={0.75} key={index} pageNumber={index + 1} />
                      ))}
                    </Document>
                  )}
                </div>
              ) : selectedPage === pageSelected.content ? (
                <div
                  style={{
                    height: "100%",
                    marginLeft: "0px",
                    marginRight: "20px",
                    display: "flex",
                    justifyContent: "center",
                    overflowY: "auto",
                  }}
                ></div>
              ) : (
                <div
                  style={{
                    height: "100%",
                    marginLeft: "0px",
                    marginRight: "20px",
                    display: "flex",
                    justifyContent: "center",
                    overflowY: "auto",
                  }}
                >
                  {contraPortadaPDF != null && (
                    <Document
                      ref={componentRef}
                      file={contraPortadaPDF}
                      onLoadError={console.error}
                      style={{ overflowY: "auto" }}
                    >
                      {[...Array(numPages)].map((elementInArray, index) => (
                        <Page scale={0.75} key={index} pageNumber={index + 1} />
                      ))}
                    </Document>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    </>
  )
}

export default PrincipalView
