import React, { useReducer } from "react"
import TeamsContext from "./teamsContext"
import { API, Auth } from "aws-amplify"
import PropTypes from "prop-types"
import TeamsReducer from "./teamsReducer"

const TeamsState = props => {
  const initialState = {
    workers: [],
  }
  const [state, dispatch] = useReducer(TeamsReducer, initialState)
  const APINAME = "dev-PLANHOPPER-API"

  const createWorker = async (accountId, name, mail, rol, projects) => {
    try {
      //projects ==> [{name:'nombre', 'projectId':'e49as3enrf3ednowfn'}]
      //rol ==> ["Admin", "color"]
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/team/createWorker"
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          accountId: accountId,
          fullName: name,
          mail: mail,
          projects: projects,
          rol: rol,
        },
      }
      const res = await API.post(apiName, path, payload)
      dispatch({
        type: "WORKER_CREATED",
        payload: res,
      })
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const getWorkersByProject = async (accountId, projectId) => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path =
        "/api/team/getByProject?projectId=" +
        projectId +
        "&accountId=" +
        accountId
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const res = await API.get(apiName, path, payload)
      dispatch({
        type: "GET_WORKERS_BY_PROJECT",
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateWorkerCampos = async (accountId, worker, campos, tagsFinal) => {
    try {
      //projects ==> [{name:'nombre', 'projectId':'e49as3enrf3ednowfn'}]
      //rol ==> ["Admin", "color"]
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/team/updateWorker"
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          accountId: accountId,
          workerId: worker.workerId,
          mail: worker.mail,
          projects: worker.projects,
          fullName: worker.fullName,
          rol: worker.rol,
          organization: campos.empresa,
          mobile: campos.movil,
          nif: campos.nif,
          tags: tagsFinal,
          address: campos.direccion,
        },
      }
      const res = await API.put(apiName, path, payload)
      dispatch({
        type: "WORKER_UPDATED",
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const sendInvitation = async (projectId, accountId, workerId) => {
    try {
      const apiName = "service-projects"
      const path = "/inviteMemberToProject"
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          accountId: accountId,
          projectId: projectId,
          memberId: workerId,
        },
      }
      const res = await API.post(apiName, path, payload)
    } catch (error) {
      console.log(error)
    }
  }

  const deleteWorker = async (accountId, workerId) => {
    try {
      const apiName = "dev-PLANHOPPER-API"
      const path = "/api/team/deleteWorker"
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          accountId: accountId,
          workerId: workerId,
        },
      }
      const res = await API.del(apiName, path, payload)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <TeamsContext.Provider
      value={{
        workers: state.workers,
        createWorker,
        getWorkersByProject,
        updateWorkerCampos,
        sendInvitation,
        deleteWorker,
      }}
    >
      {props.children}
    </TeamsContext.Provider>
  )
}

TeamsState.propTypes = {
  children: PropTypes.element.isRequired,
}

export default TeamsState
