import React, { useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress, Modal } from "@mui/material"
import PropTypes from "prop-types"
import i18n from "i18n"
import { HelveticaBold } from "common/typographies"
import { YellowButton } from "common/buttons"
import cloudDownload from "assets/customIcons/somethingIsBad.svg"
import notifyToSlackChannel from "react-slack-notification"
import AuthContext from "context/auth/authContext"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "420px",
    height: "300px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],

    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalCallError = ({ open, setOpen, error, ubicacion, showError }) => {
  const { user } = useContext(AuthContext)
  const url =
    "https://hooks.slack.com/services/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"
  notifyToSlackChannel(
    url,
    "Ha ocurrido un error en " +
      ubicacion +
      " \nMensaje de error: " +
      error?.message +
      " \nFunción: " +
      error?.function +
      " \nServicio: " +
      error?.service +
      " \nEntorno: " +
      window.location.hostname +
      " \nUsuario: " +
      user?.sub +
      " " +
      user?.email +
      " \nFecha: " +
      new Date().toLocaleString(),
  )

  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          window.location.reload()
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <div
            style={{
              justifyContent: "center",
              position: "relative",
              textAlign: "center",
              display: "block",
              marginTop: "30px",
              margin: "30px",
            }}
          >
            <img
              src={cloudDownload}
              style={{ width: "100px", height: "70px" }}
            />
            <HelveticaBold
              style={{ fontSize: "16px", marginTop: "20px", color: "#414141" }}
            >
              {i18n.t("Parece que ha ocurrido un error")}
            </HelveticaBold>
            {showError ? (
              <HelveticaBold
                style={{
                  fontSize: "12px",
                  marginTop: "10px",
                  color: "#414141",
                }}
              >
                {i18n.t(error)}
              </HelveticaBold>
            ) : (
              <>
                <HelveticaBold
                  style={{
                    fontSize: "12px",
                    lineHeight: "13px",
                    marginTop: "10px",
                    color: "#414141",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t("Nuestro equipo esta trabajando en ello,")}
                  <HelveticaBold
                    style={{
                      fontSize: "12px",
                      marginTop: "10px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("Pruebe de nuevo mas tarde")}
                  </HelveticaBold>
                </HelveticaBold>
              </>
            )}
            <YellowButton
              onClick={() => {
                showError ? setOpen(false) : window.location.reload()
              }}
              style={{
                marginTop: "5px",
                width: "160px",
                height: "40px",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {i18n.t("Aceptar")}
            </YellowButton>
          </div>
        </div>
      </Modal>
    </>
  )
}
ModalCallError.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  error: PropTypes.any,
  ubicacion: PropTypes.string,
  showError: PropTypes.bool,
}

export default ModalCallError
