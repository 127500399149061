import React, { useState, useContext, useEffect } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { Modal } from "@mui/material"
import ProjectContext from "context/projects/projectContext"
import ProjectDropzone from "components/Dropzone/ProjectDropzone"
import { useHistory } from "react-router-dom"
import ModalBC3 from "./ModalBC3"
import i18n from "i18n"
import Budget2023Context from "context/budget2023/Budget2023Context"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))
const UploadModal = ({ openModal, setOpenModal, proyecto, setWaitModal }) => {
  const history = useHistory()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [uploading_project, setUploading_project] = useState(false)
  const projectContext = useContext(ProjectContext)
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [notBC3, setNotBC3] = useState(false)
  const { UploadProject } = projectContext
  const [open, setOpen] = useState(false)
  const { getBudgetTree } = useContext(Budget2023Context)

  const onUpload = async () => {
    setUploading_project(true)
    for (let i = 0; i < selectedFiles.length; i++) {
      await UploadProject({ file: selectedFiles[i], infoProject: proyecto })
    }
    setUploading_project(false)
    //getBudgetTree(proyecto.projectId, false)
    setWaitModal(true)
    setOpenModal(false)
  }

  const onUpload2 = e => {
    e.preventDefault()
    setOpenModal(false)
    setOpen(false)
    history.push(`/budgeting/${proyecto.projectId}`)
  }

  useEffect(() => {
    if (notBC3 == true) {
      setOpen(true)
    }
  }, [notBC3])

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          null
        }}
      >
        <>
          <div style={modalStyle} className={classes.paper}>
            <div className={"py-4"}>
              <p
                className="text-center my-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "23px",
                  color: "#414141",
                }}
              >
                {i18n.t("Sube tus mediciones para obtener tu presupuesto")}
              </p>
              <button
                type="button"
                onClick={() => {
                  setOpenModal(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "0 5rem" }}>
              <ProjectDropzone
                selectedFiles={selectedFiles}
                setselectedFiles={setSelectedFiles}
                setNotBC3={setNotBC3}
                proyecto={proyecto}
              />
            </div>

            <div
              id="centerButton"
              style={{ marginTop: "15px", marginBottom: "20px" }}
            >
              {selectedFiles.length == 0 ? (
                <button type="button" className="AddProjectDisabled">
                  {i18n.t("Subir")}
                </button>
              ) : uploading_project ? (
                <CircularProgress size={24} />
              ) : (
                <button type="button" className="AddContact" onClick={onUpload}>
                  {i18n.t("Subir")}
                </button>
              )}
            </div>
            {/* 
            <Box
              sx={{
                display: "flex",
                justifyContent: "spaceAround",
                backgroundColor: "rgba(68, 97, 215, 0.1)",
                padding: "2rem",
                cursor: "pointer",
              }}
              onClick={(e)=>{onUpload2(e)}}
            >
              <img src={Intro_Create} height="30%" width="30%" />

              <Box sx={{ paddingLeft: "1rem" }}>
                <Box>
                  <Box
                    sx={{
                      fontFamily: "Helvetica",
                      fontWeight: "600",
                      fontSize: "18px",
                      marginBottom: "1rem",
                    }}
                  >
                    {i18n.t("Comienza con Planhopper")}
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "Helvetica",
                      lineHeight: "21px",
                      fontWeight: "300",
                    }}
                  >
                    <b style={{ fontFamily: "Helvetica", fontWeight: "500" }}>
                      {i18n.t("Crearás tu presupuesto manualmente")}{" "}
                    </b>
                    {i18n.t("en relación a los precios de referencia de nuestra base de datos.")}{" "}
                  </Typography>
                </Box>
              </Box>
            </Box>
            */}
          </div>
        </>
      </Modal>
      <ModalBC3 open={open} setOpen={setOpen} setNotBC3={setNotBC3} />
    </>
  )
}

UploadModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  proyecto: PropTypes.object,
  setWaitModal: PropTypes.func.isRequired,
}

export default UploadModal
