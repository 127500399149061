import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { CircularProgress, Modal } from "@mui/material"
import { useParams } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import ProjectContext from "context/projects/projectContext"
import CloseBud from "assets/images/budgeting/Send.png"
import i18n from "i18n"
import Budget2023Context from "context/budget2023/Budget2023Context"
import SendModal from "components/Feed/sendBudgetRefatctor/sendModal"
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "430px",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
    height: "440px",
  },
}))

const ModalNewSend = ({
  projectInfo,
  openSend,
  setOpenSend,
  open3,
  setOpen3,
  contactos,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const { projectId } = useParams()
  const classes = useStyles()
  const projectContext = useContext(ProjectContext)
  const { getProject } = projectContext
  const { closeEntireBudget } = useContext(Budget2023Context)
  const [loading, setLoading] = useState(false)

  const guardarPres = async e => {
    setLoading(true)
    await closeEntireBudget({
      projectId: projectInfo.projectId,
      accountId: projectInfo.createdBy,
    })
    await getProject(projectId)
    setOpen3(true)
    setLoading(false)
    setOpenSend(false)
  }

  const guardarPres2 = async e => {
    setLoading(true)
    await closeEntireBudget({
      projectId: projectInfo.projectId,
      accountId: projectInfo.createdBy,
    })
    await getProject(projectId)
    setLoading(false)
    setOpenSend(false)
  }

  return (
    <>
      <Modal
        open={openSend}
        onClose={() => {
          setOpenSend(false)
        }}
      >
        <div id="totalDiv">
          <div style={modalStyle} className={classes.paper}>
            <CloseIcon
              id="cerrar"
              onClick={() => {
                setOpenSend(false)
              }}
              style={{
                marginTop: "0px",
                paddingLeft: "6px",
                marginRight: "-10px",
              }}
            ></CloseIcon>
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "50px",
              }}
            >
              <img src={CloseBud} style={{ width: "296px", height: "108px" }} />
            </div>

            <div
              id="DivTitleEliminar"
              style={{
                marginTop: "25px",
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p id="centradoBudget">{i18n.t("¿Deseas enviar presupuesto?")}</p>
            </div>
            <p style={{ marginTop: "10px" }} className="lightPoppins">
              {i18n.t(
                "Podrás enviar este presupuesto ahora o en otro momento, haciendo click en",
              )}
            </p>
            {loading == false ? (
              <div id="centerButton">
                <div
                  id="DivTitleEliminar2"
                  style={{ marginLeft: "40px", marginBottom: "10px" }}
                >
                  <button
                    type="button"
                    style={{
                      width: "182px",
                      marginRight: "15px",
                      borderRadius: "6px",
                    }}
                    className="CancelDel"
                    onClick={() => {
                      guardarPres2()
                    }}
                  >
                    {i18n.t("Cerrar Presupuesto")}
                  </button>
                  <button
                    type="button"
                    style={{ width: "170px" }}
                    className="AddContact"
                    onClick={guardarPres}
                  >
                    {i18n.t("Cerrar y Enviar")}
                  </button>
                </div>
              </div>
            ) : (
              <div id="centerButton">
                <CircularProgress size={24} />
              </div>
            )}
          </div>
        </div>
      </Modal>
      <SendModal open={open3} setOpen={setOpen3} contactos={contactos} />
    </>
  )
}

ModalNewSend.propTypes = {
  projectInfo: PropTypes.object.isRequired,
  openSend: PropTypes.bool.isRequired,
  setOpenSend: PropTypes.func.isRequired,
  open3: PropTypes.bool.isRequired,
  setOpen3: PropTypes.func.isRequired,
  contactos: PropTypes.any.isRequired,
}

export default ModalNewSend
