import React, { useContext, useEffect } from "react"
import CertHistoryContext from "context/certHistory/CertHistoryContext"
import { MetaTags } from "react-meta-tags"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { Container } from "reactstrap"
import Loading from "common/Loading"
import CertHistoryRow from "components/CertHistory/CertHistoryRow"
import DownloadBudget from "components/Feed/DownloadBudget"
import DownloadBudgetEnum from "common/DownloadBudgetEnum"
import { SquareContent } from "common/containers"
import { HelveticaBold } from "common/typographies"
import emptyFilesFolder from "assets/customIcons/empty_files_folder.png"
import ConfirmationModal from "common/ConfirmationModal"
import i18n from "i18n"

const CertificationsHistoryIndex = () => {
  const { projectId } = useParams()
  const {
    setCertHistory,
    certHistoryTree,
    loading,
    exportSideBar,
    openConfirmDelete,
    setOpenConfirmDelete,
    setCetToDelete,
    deleteCert,
    certToDelete,
  } = useContext(CertHistoryContext)

  useEffect(() => {
    setCertHistory(projectId)
  }, [])

  if (loading) {
    return <Loading />
  }

  const handleClose = () => {
    setCetToDelete(null)
    setOpenConfirmDelete(false)
  }

  const handleDeleteCert = () => {
    deleteCert(projectId, certToDelete)
  }

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper | Proyecto </title>
        </MetaTags>
        <Container fluid>
          {certHistoryTree.certs && certHistoryTree.certs.length > 0 ? (
            certHistoryTree.certs.map((c, index) => (
              <CertHistoryRow
                key={c.id}
                data={c}
                title={`CERTIFICACIÓN ${index + 1}`}
                showDeleteIcon={index === certHistoryTree.certs.length - 1}
              />
            ))
          ) : (
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                boxShadow: "none",
                display: "flex",
                textAlign: "center",
                height: "60vh",
              }}
            >
              <SquareContent style={{ width: "370px", height: "200px" }}>
                <img
                  src={emptyFilesFolder}
                  style={{
                    width: "90px",
                    height: "75px",
                    marginBottom: "20px",
                    marginTop: "30px",
                  }}
                />
                <HelveticaBold
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    padding: "0px 30px 30px 30px",
                    color: "#414141",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <b>No hay certificaciones</b>
                </HelveticaBold>
              </SquareContent>
            </div>
          )}
        </Container>
        <DownloadBudget
          open={exportSideBar.isOpen}
          setOpen={exportSideBar.setOpen}
          name="Certification"
          fromTab={DownloadBudgetEnum.FROM_HISTORY_CERTIFICATION}
        />

        <ConfirmationModal
          open={openConfirmDelete}
          onClose={handleClose}
          onConfirm={handleDeleteCert}
          title={i18n.t("certHistory.confirmationModal.title")}
          message={i18n.t("certHistory.confirmationModal.message")}
          confirmButtonText={i18n.t("buttons.confirm")}
          cancelButtonText={i18n.t("buttons.cancel")}
        />
      </div>
    </>
  )
}

export default CertificationsHistoryIndex
