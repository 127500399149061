import i18n from "i18n"
import React, { useContext, useState, useEffect } from "react"
import BookOutlinedIcon from "@mui/icons-material/BookOutlined"
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import AuthContext from "context/auth/authContext"
import { DownloadingOutlined, EditOutlined } from "@mui/icons-material"
import { HelveticaBold } from "common/typographies"
import ModalEliminar from "./ModalEliminar"
import PropTypes from "prop-types"
import UploadDocument from "./UploadDocument"
import ModalEdit from "./modalEdit"

const TopQuickActionsBar = ({ selectedDocument, setSelectedDocument }) => {
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const { user, freeTrialExpired } = useContext(AuthContext)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleDelete = async () => {
    setOpenDeleteModal(true)
  }

  const handleDownload = async () => {
    selectedDocument.forEach(file => {
      window.open(file.url, "_blank")
    })
  }

  const handleEdit = () => {
    setOpenEdit(true)
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "47px",
          backgroundColor: "#FFD52B",
          zIndex: "999",
          paddingTop: "15px",
        }}
      >
        <div className="inline">
          <div style={{ marginLeft: "10%" }} className="inline">
            <div className="tdcheckColumn">
              <input
                className="form-check-input"
                type="checkbox"
                checked={true}
                id="flexCheckDefault"
                style={{
                  width: "13px",
                  height: "13px",
                  cursor: "pointer",
                  zIndex: "5",
                  position: "relative",
                }}
              ></input>
            </div>
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "14px",
                marginTop: "-0.5px",
              }}
            >
              {selectedDocument.length > 1
                ? `${selectedDocument.length} ${i18n.t(
                    "Documento seleccionados",
                  )}`
                : `${selectedDocument.length} ${i18n.t(
                    "Documento seleccionado",
                  )}`}
            </HelveticaBold>
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "absolute",
              display: "flex",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <EditOutlined
              onClick={() => {
                selectedDocument.length == 1 ? handleEdit() : null
              }}
              className="segundoIconoShow"
              style={{
                cursor: "pointer",
                color: selectedDocument.length == 1 ? "#414141" : "#c4c4c4",
                width: "24px",
                marginRight: "3px",
              }}
            />
            <div
              className="segundoIcono"
              style={{
                position: "absolute",
                marginLeft: "-55px",
                marginTop: "35px",
              }}
            >
              <div className="recuadro">
                <div className="recuadroPico"></div>
                {i18n.t("Editar")}
              </div>
            </div>

            <DownloadingOutlined
              onClick={() => {
                user
                  ? user["custom:role"] === "WORKER" || freeTrialExpired
                    ? null
                    : handleDownload()
                  : null
              }}
              className="segundoIconoShow"
              style={{
                cursor: "pointer",
                color: user
                  ? user["custom:role"] === "WORKER" || freeTrialExpired
                    ? "#c4c4c4"
                    : "#414141"
                  : "#c4c4c4",
                width: "24px",
                marginRight: "3px",
              }}
            />
            {user ? (
              user["custom:role"] === "WORKER" || freeTrialExpired ? null : (
                <div
                  className="segundoIcono"
                  style={{
                    position: "absolute",
                    marginLeft: "0px",
                    marginTop: "35px",
                  }}
                >
                  <div className="recuadro">
                    <div className="recuadroPico"></div>
                    {i18n.t("Descargar")}
                  </div>
                </div>
              )
            ) : null}

            <DeleteOutlinedIcon
              onClick={() => {
                user
                  ? user["custom:role"] === "WORKER" || freeTrialExpired
                    ? null
                    : handleDelete()
                  : null
              }}
              className="cuartoIconoShow"
              style={{
                color: user
                  ? user["custom:role"] === "WORKER" || freeTrialExpired
                    ? "#C4C4C4"
                    : "#414141"
                  : "#C4C4C4",
                width: "24px",
                marginRight: "3px",
                cursor: "pointer",
              }}
            />
            {user ? (
              user["custom:role"] === "WORKER" || freeTrialExpired ? null : (
                <div
                  className="cuartoIcono"
                  style={{
                    position: "absolute",
                    marginLeft: "50px",
                    marginTop: "35px",
                  }}
                >
                  <div className="recuadro">
                    <div className="recuadroPico"></div>
                    {i18n.t("Eliminar")}
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>

      {openDeleteModal && (
        <ModalEliminar
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          openEliminate={openDeleteModal}
          setOpenEliminate={setOpenDeleteModal}
        />
      )}
      {openEdit && (
        <ModalEdit
          open={openEdit}
          setOpen={setOpenEdit}
          file={selectedDocument[0]}
          setSelectedFiles={setSelectedDocument}
        />
      )}
    </>
  )
}

TopQuickActionsBar.propTypes = {
  selectedDocument: PropTypes.array,
  setSelectedDocument: PropTypes.func,
}

export default TopQuickActionsBar
