import useLocalStorage from "hooks/useLocalStorage"

export const DOWNLOAD_TYPES = Object.freeze({
  EXCEL: "excel",
  PDF: "pdf",
})

export const INPUTS = Object.freeze({
  CLIENT_PRICE: "clientPrice",
  COST_PRICE: "costPrice",
  PART_DECOMPOSITION: "decomposition",
  MEASURES: "measures",
  COMMENTS: "comment",
  PDF_PRICE_OPTION: "pdfPriceValue",
  COMPANY_AND_CLIENT_INFO: "companyAndClientInfo",
  GENERAL_CONDITIONS: "generalConditions",
  BRIEFING_POSITION: "briefingPosition",
  BRIEFING_POSITION: "briefingPosition",
  ADD_COVER: "addCover",
  ADD_SIGNATURE: "addSignature",
  ADD_DATE: "addDate",
  NEW_FORMAT: "newFormat",
})

export const DOWNLOAD_BUDGET_CONFIG = "downloadBudgetConfig"

/**
 * Custom hook for managing the download configuration of budget data in PDF or Excel formats.
 *
 * This hook provides a way to persistently store and update download settings using `useLocalStorage`.
 * It organizes configurations into two sections: PDF and Excel, each with their own set of inputs and options.
 *
 * @returns {[Object, Function]} An array containing the current download configuration and an updater function.
 * @returns {Object} downloadBudgetConfig - The current configuration for PDF and Excel downloads.
 * @returns {Function} updateConfig - Updates a specific configuration value within a given section.
 *
 * @example
 * const [downloadBudgetConfig, updateConfig] = useDownloadBudgetConfig();
 *
 * // Access PDF configuration
 * const pdfConfig = downloadBudgetConfig[DOWNLOAD_TYPES.PDF];
 *
 * // Update the PDF price option
 * updateConfig(DOWNLOAD_TYPES.PDF, INPUTS.PDF_PRICE_OPTION, "cost");
 *
 * @description
 * - `downloadBudgetConfig`: Stores the current settings for PDF and Excel downloads.
 * - `updateConfig`: Updates a specific key-value pair in the configuration for a given section (PDF or Excel).
 *
 * ### Default Configuration
 * #### PDF
 * - `pdfPriceValue`: "client"
 * - `companyAndClientInfo`: `true`
 * - `generalConditions`: `true`
 * - `decomposition`: `true`
 * - `briefingPosition`: `false`
 * - `addCover`: `true`
 * - `addSignature`: `false`
 * - `addDate`: `false`
 * - `newFormat`: `true`
 *
 * #### Excel
 * - `clientPrice`: `true`
 * - `costPrice`: `false`
 * - `decomposition`: `true`
 * - `measures`: `false`
 * - `comment`: `false`
 */

const useDownloadBudgetConfig = () => {
  const [downloadBudgetConfig, setDownloadBudgetConfig] = useLocalStorage(
    DOWNLOAD_BUDGET_CONFIG,
    {
      [DOWNLOAD_TYPES.PDF]: {
        [INPUTS.PDF_PRICE_OPTION]: "client",
        [INPUTS.COMPANY_AND_CLIENT_INFO]: true,
        [INPUTS.GENERAL_CONDITIONS]: true,
        [INPUTS.PART_DECOMPOSITION]: true,
        [INPUTS.BRIEFING_POSITION]: false,
        [INPUTS.ADD_COVER]: true,
        [INPUTS.ADD_SIGNATURE]: false,
        [INPUTS.ADD_DATE]: false,
        [INPUTS.NEW_FORMAT]: true,
      },
      [DOWNLOAD_TYPES.EXCEL]: {
        [INPUTS.CLIENT_PRICE]: true,
        [INPUTS.COST_PRICE]: false,
        [INPUTS.PART_DECOMPOSITION]: true,
        [INPUTS.MEASURES]: false,
        [INPUTS.COMMENTS]: false,
      },
    },
  )

  const updateConfig = (section, key, value) => {
    setDownloadBudgetConfig(prevConfig => ({
      ...prevConfig,
      [section]: {
        ...prevConfig[section],
        [key]: value,
      },
    }))
  }

  return [downloadBudgetConfig, updateConfig]
}

export default useDownloadBudgetConfig
