import { HelveticaBold } from "common/typographies"
import FileContext from "context/file/fileContext"
import ProjectContext from "context/projects/projectContext"
import i18n from "i18n"
import { useHistory } from "react-router-dom"
import React, { useContext } from "react"
import RecentFiles from "./recentFiles"
import propTypes from "prop-types"
import tribbooLogo from "assets/customIcons/logos/planhopper_logo_mini.svg"

const IndexProjectDocuments = ({ selectedDocument, setSelectedDocument }) => {
  const { projects, getProjects } = useContext(ProjectContext)
  const { filteredFilesByProject } = useContext(FileContext)
  const history = useHistory()
  return (
    <>
      <div
        className="inline"
        style={{
          width: "100%",
          position: "relative",
          marginBottom: "10px",
          marginTop: "30px",
        }}
      >
        <div className="inline" style={{ width: "25%", position: "relative" }}>
          <HelveticaBold
            id="capitalize"
            style={{ color: "#414141", fontSize: "14px", marginBottom: "0px" }}
          >
            {i18n.t("Proyecto")}
          </HelveticaBold>
        </div>
        <div className="inline" style={{ width: "25%", position: "relative" }}>
          <HelveticaBold
            id="capitalize"
            style={{
              color: "#414141",
              fontSize: "14px",
              marginBottom: "0px",
              marginLeft: "25px",
            }}
          >
            {i18n.t("Dirección")}
          </HelveticaBold>
        </div>
        <div className="inline" style={{ width: "15%", position: "relative" }}>
          <HelveticaBold
            id="capitalize"
            style={{
              color: "#414141",
              fontSize: "14px",
              marginBottom: "0px",
              marginLeft: "25px",
            }}
          >
            {i18n.t("Nº Documentos")}
          </HelveticaBold>
        </div>
        <div className="inline" style={{ width: "15%", position: "relative" }}>
          <HelveticaBold
            id="capitalize"
            style={{
              color: "#414141",
              fontSize: "14px",
              marginBottom: "0px",
              marginLeft: "25px",
            }}
          >
            {i18n.t("Última modificación")}
          </HelveticaBold>
        </div>
      </div>

      <div
        className="projectRow"
        onClick={() => {
          history.push("/documents/sin_clasificar")
        }}
      >
        <div
          className="inline"
          style={{
            width: "25%",
            position: "relative",
            padding: "10px 10px 10px 15px",
          }}
        >
          <img
            style={{
              width: "35px",
              height: "35px",
              marginLeft: "3px",
              marginTop: "2px",
            }}
            src={tribbooLogo}
          ></img>
          <HelveticaBold
            id="capitalize"
            style={{
              color: "#414141",
              fontSize: "18px",
              marginLeft: "10px",
              marginTop: "7px",
            }}
          >
            SIN CLASIFICAR
          </HelveticaBold>
        </div>

        <div
          className="inline"
          style={{
            width: "25%",
            position: "relative",
            padding: "10px 10px 10px 15px",
          }}
        >
          <HelveticaBold
            id="capitalize"
            style={{
              color: "#414141",
              fontSize: "14px",
              fontWeight: "300",
              marginLeft: "10px",
              marginTop: "11px",
            }}
          ></HelveticaBold>
        </div>

        <div
          className="inline"
          style={{
            width: "15%",
            position: "relative",
            padding: "10px 10px 10px 15px",
          }}
        >
          <HelveticaBold
            id="capitalize"
            style={{
              color: "#414141",
              fontSize: "14px",
              fontWeight: "300",
              marginLeft: "10px",
              marginTop: "11px",
            }}
          >
            {filteredFilesByProject["sin_clasificar"]
              ? filteredFilesByProject["sin_clasificar"].length
              : 0}
          </HelveticaBold>
        </div>

        <div
          className="inline"
          style={{
            width: "15%",
            position: "relative",
            padding: "10px 10px 10px 15px",
          }}
        >
          <HelveticaBold
            id="capitalize"
            style={{
              color: "#414141",
              fontSize: "14px",
              fontWeight: "300",
              marginLeft: "10px",
              marginTop: "11px",
            }}
          >
            {new Date().toLocaleDateString()}
          </HelveticaBold>
        </div>

        <div
          className="inline"
          style={{
            width: "20%",
            position: "relative",
            padding: "10px 10px 10px 15px",
          }}
        >
          {
            //AQUI VA EL DROPDOWN
          }
        </div>
      </div>

      {projects.map((project, index) => {
        return (
          <div
            className="projectRow"
            onClick={() => {
              history.push("/documents/" + project.projectId)
            }}
            key={index}
          >
            <div
              className="inline"
              style={{
                width: "25%",
                position: "relative",
                padding: "10px 10px 10px 15px",
              }}
            >
              <img
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "100px",
                  border: "0.5px solid #D9D9D9",
                }}
                src={project.projectImage}
              ></img>
              <HelveticaBold
                id="capitalize"
                style={{
                  color: "#414141",
                  fontSize: "18px",
                  marginLeft: "10px",
                  marginTop: "7px",
                }}
              >
                {project.name}
              </HelveticaBold>
            </div>

            <div
              className="inline"
              style={{
                width: "25%",
                position: "relative",
                padding: "10px 10px 10px 15px",
              }}
            >
              <HelveticaBold
                id="capitalize"
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginLeft: "10px",
                  marginTop: "11px",
                }}
              >
                {project.orgAddress}
              </HelveticaBold>
            </div>

            <div
              className="inline"
              style={{
                width: "15%",
                position: "relative",
                padding: "10px 10px 10px 15px",
              }}
            >
              <HelveticaBold
                id="capitalize"
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginLeft: "10px",
                  marginTop: "11px",
                }}
              >
                {filteredFilesByProject[project.projectId]
                  ? filteredFilesByProject[project.projectId].length
                  : 0}
              </HelveticaBold>
            </div>

            <div
              className="inline"
              style={{
                width: "15%",
                position: "relative",
                padding: "10px 10px 10px 15px",
              }}
            >
              <HelveticaBold
                id="capitalize"
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  fontWeight: "300",
                  marginLeft: "10px",
                  marginTop: "11px",
                }}
              >
                {new Date(project.updatedAt).toLocaleDateString()}
              </HelveticaBold>
            </div>

            <div
              className="inline"
              style={{
                width: "20%",
                position: "relative",
                padding: "10px 10px 10px 15px",
              }}
            >
              {
                //AQUI VA EL DROPDOWN
              }
            </div>
          </div>
        )
      })}
      <RecentFiles
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
      />
    </>
  )
}

IndexProjectDocuments.propTypes = {
  selectedDocument: propTypes.array.isRequired,
  setSelectedDocument: propTypes.func.isRequired,
}

export default IndexProjectDocuments
