import React, { useState, useContext, useEffect, useCallback } from "react"
import { Auth, API } from "aws-amplify"
import PropTypes from "prop-types"
import { useLocation } from "react-router-dom/cjs/react-router-dom"
import AuthContext from "../auth/authContext"
import SubscriptionContext from "./subscriptionContext"
import ModalExpireFreeTrial from "components/UserView/ModalExpireFreeTrial"
import * as amplitude from "@amplitude/analytics-browser"

const SubscriptionProvider = ({ children }) => {
  const { user } = useContext(AuthContext)
  const [subscriptionState, setSubscriptionState] = useState(null)
  const [isTrial, setIsTrial] = useState(false)
  const [noSubscription, setNoSubscription] = useState(false)
  const [daysLeft, setDaysLeft] = useState(30)
  const [showTopAdviceBar, setShowTopAdviceBar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [hideSidebar, setHideSidebar] = useState(false)

  const location = useLocation()
  const isSubscriptionPath = location.pathname === "/subscription"
  const responseToAvoidError = {
    customer: {
      id: "a9224bdd-dc70-41e7-8f5e-2caf75a54a7a",
      email: "soporte+trial@planhopper.com",
      auto_collection: "on",
      net_term_days: 0,
      allow_direct_debit: false,
      created_at: 1734103749,
      taxability: "taxable",
      resource_version: 1734103749545,
      updated_at: 1734103749,
      locale: "es-ES",
      pii_cleared: "active",
      channel: "web",
      preferred_currency_code: "EUR",
      promotional_credits: 0,
      unbilled_charges: 0,
      refundable_credits: 0,
      excess_payments: 0,
      deleted: false,
    },
    subscriptions: [
      {
        id: "199SxkUWqobwL1x2Z",
        customer_id: "a9224bdd-dc70-41e7-8f5e-2caf75a54a7a",
        status: "in_trial",
        trial_start: 1734103749,
        trial_end: 1734103749,
        next_billing_at: 1735313349,
        due_invoices_count: 0,
        subscription_items: [
          {
            item_price_id: "Business-EUR-Monthly",
            item_type: "plan",
          },
        ],
      },
    ],
    subscription_entitlements: [],
  }

  const calculateDaysLeft = endTime =>
    Math.floor((endTime - Math.floor(Date.now() / 1000)) / 86400)

  const getAuthHeaders = useCallback(async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    return {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    }
  }, [])

  const updateTrialInfo = useCallback(() => {
    if (subscriptionState?.subscriptions?.length === 0) {
      setShowModal(true)
    }

    const subscription = subscriptionState?.subscriptions?.[0]
    if (subscription) {
      const { status, trial_end } = subscription

      if (status === "in_trial" && trial_end) {
        setDaysLeft(calculateDaysLeft(trial_end))
      }
    }
  }, [subscriptionState, isTrial])

  useEffect(() => {
    setShowTopAdviceBar(daysLeft < 30)
  }, [daysLeft])

  useEffect(() => {
    if (subscriptionState?.subscriptions?.length > 0) {
      const [subscription] = subscriptionState.subscriptions
      setIsTrial(subscription.status === "in_trial")
      setNoSubscription(false)
    } else {
      setIsTrial(false)
      setNoSubscription(true)
    }
    setLoading(false)
  }, [subscriptionState])

  const fetchSubscriptionData = useCallback(async () => {
    setLoading(true)
    const config = { headers: await getAuthHeaders() }
    const path = "/chargebee/user"
    const body = { user_id: user["custom:organizationId"] }

    try {
      const response = await API.post("CORE", `${path}`, { ...config, body })
      if (response === null) {
        setSubscriptionState(responseToAvoidError)
      } else {
        setSubscriptionState(response)
      }
    } catch (error) {
      console.error(`Error fetching subscription data: ${error}`)
      setSubscriptionState(responseToAvoidError)
      amplitude.track({
        event_type: "[subscription] fetch failed",
        event_properties: {
          user_id: user.sub,
        },
      })
      setLoading(false)
    }
  }, [user])

  useEffect(() => {
    if (user && !loading) {
      fetchSubscriptionData()
    }
  }, [user, fetchSubscriptionData])

  useEffect(() => {
    updateTrialInfo()
  }, [updateTrialInfo])

  useEffect(() => {
    setShowModal(!isSubscriptionPath && isTrial && daysLeft <= 0)
    setHideSidebar(isSubscriptionPath && isTrial && daysLeft <= 0)
  }, [isSubscriptionPath, isTrial, daysLeft])

  return (
    <SubscriptionContext.Provider
      value={{
        subscriptionState,
        isTrial,
        noSubscription,
        daysLeft,
        showTopAdviceBar,
        setShowTopAdviceBar,
        modalExpireFreeTrial: {
          open: showModal,
          setOpen: setShowModal,
        },
        isSubscriptionPath,
        hideSidebar,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}

SubscriptionProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SubscriptionProvider
