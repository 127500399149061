import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { useHistory } from "react-router-dom"
import i18n from "i18n"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"

import { ListBank } from "pages/BancoPrecios/ListBank"
import { HelveticaBold } from "common/typographies"

const DropdownSelectBank = props => {
  const [menu, setMenu] = useState(false)
  const history = useHistory()
  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{ height: "0px" }}
        >
          <DropdownToggle
            id="settingb"
            style={{
              marginTop: "10px",
              color: "#414141",
              marginLeft: "10px",
              padding: "0px",
              borderRadius: "0px",
              paddingBottom: "12px",
              borderBottom: "0px",
              cursor: "pointer",
            }}
          >
            <div className="inline" style={{ width: "200px" }}>
              <HelveticaBold
                style={{ color: "#414141", marginTop: "3px", fontSize: "12px" }}
              >
                {i18n.t("Banco de precios")}
              </HelveticaBold>
              {props.bankSelected === "cype" ? (
                <>
                  <img
                    style={{
                      marginTop: "0px",
                      marginLeft: "5px",
                      marginRight: "-10px",
                      marginBottom: "-10px",
                      zIndex: "9",
                    }}
                    src={ListBank.cype.logo}
                    width={20}
                    height={20}
                  />
                  <KeyboardArrowDown
                    style={{
                      color: "#414141",
                      width: "15px",
                      marginLeft: "15px",
                    }}
                  />
                </>
              ) : props.bankSelected === "bedec" ? (
                <>
                  <img
                    style={{
                      marginTop: "0px",
                      marginLeft: "5px",
                      marginRight: "-10px",
                      marginBottom: "-10px",
                      zIndex: "9",
                    }}
                    src={ListBank.bedec.logo}
                    width={20}
                    height={20}
                  />
                  <KeyboardArrowDown
                    style={{
                      color: "#414141",
                      width: "15px",
                      marginLeft: "15px",
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    style={{
                      marginTop: "0px",
                      marginLeft: "5px",
                      marginRight: "-10px",
                      marginBottom: "-10px",
                      zIndex: "9",
                    }}
                    src={ListBank.preoc.logo}
                    width={20}
                    height={20}
                  />
                  <KeyboardArrowDown
                    style={{
                      color: "#414141",
                      width: "15px",
                      marginLeft: "15px",
                    }}
                  />
                </>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {Object.keys(ListBank).map((item, index) => {
              let bank = ListBank[item]
              return (
                <DropdownItem
                  key={index}
                  tag="b"
                  style={{ lineHeight: "20px", cursor: "pointer" }}
                  onClick={() => {
                    props.setBankSelected(bank.uri)
                  }}
                >
                  {" "}
                  <div className="inline">
                    {i18n.t("Banco de precios")}
                    <img
                      style={{
                        marginTop: "0px",
                        marginLeft: "5px",
                        marginRight: "-10px",
                        marginBottom: "-10px",
                        zIndex: "9",
                      }}
                      src={bank.logo}
                      width={20}
                      height={20}
                    />
                  </div>
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </>
  )
}

DropdownSelectBank.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  bankSelected: PropTypes.string,
  setBankSelected: PropTypes.func,
}

export default DropdownSelectBank
