import React, { useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Modal,
  Typography,
} from "@mui/material"
import i18n from "i18n"
import { YellowButton } from "common/buttons"
import cardImage2 from "assets/images/freeTrial/modalExpire_image2.svg"
import cardImage3 from "assets/images/freeTrial/whatsapp.svg"
import cardImage1 from "assets/images/freeTrial/image_card1.svg"
import AuthContext from "context/auth/authContext"
import SubscriptionContext from "context/subscription/subscriptionContext"
import { WHATSAPP_NUMBER } from "components/VerticalLayout/routes"

const ModalExpireFreeTrial = () => {
  const history = useHistory()
  const { user, getUser } = useContext(AuthContext)
  const { modalExpireFreeTrial } = useContext(SubscriptionContext)

  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [user])

  const cardsData = [
    {
      id: crypto.randomUUID(),
      image: cardImage1,
      buttonText: i18n.t("buttons.subscribe"),
      description: i18n.t("modalExpireFreeTrial.card.subscribeDescription"),
      action: () => {
        history.push("/subscription")
        history.go(0)
      },
    },
    {
      id: crypto.randomUUID(),
      image: cardImage2,
      buttonText: i18n.t("buttons.consultation"),
      description: i18n.t("modalExpireFreeTrial.card.consultationDescription"),
      action: () => {
        window.open(
          "https://meetings-eu1.hubspot.com/meetings/tizianopierini/trial-demo-",
          "_blank",
        )
      },
    },
    {
      id: crypto.randomUUID(),
      image: cardImage3,
      buttonText: i18n.t("buttons.whatsapp"),
      description: i18n.t("modalExpireFreeTrial.card.whatsappDescription"),
      action: () => {
        window.open(
          `https://api.whatsapp.com/send/?phone=${WHATSAPP_NUMBER}&text=Hola%2C+%C2%BFen+que+puedo+ayudarte%3F&type=phone_number&app_absent=0`,
          "_blank",
        )
      },
    },
  ]

  return (
    <Modal open={modalExpireFreeTrial.open} onClose={() => {}}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "70%", md: "50%" },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Typography
          id="modal-title"
          variant="h6"
          align="center"
          sx={{ mb: 2, fontWeight: 600 }}
        >
          {user
            ? user["custom:subscription_status"] === "cancelled"
              ? i18n.t("Tu suscripción ha finalizado.")
              : i18n.t("Tu periodo de prueba ha finalizado.")
            : i18n.t("Tu periodo de prueba ha finalizado.")}
        </Typography>

        <Typography
          variant="body1"
          align="center"
          sx={{ mb: 4, fontWeight: 400, fontSize: "1.2rem" }}
        >
          {i18n.t("modalExpireFreeTrial.subTitle")}
        </Typography>

        <Grid container spacing={2}>
          {cardsData.map(card => (
            <Grid item xs={12} sm={6} md={4} key={card.id}>
              <Card
                sx={{
                  textAlign: "center",
                  p: 2,
                  boxShadow: "none",
                  border: "none",
                  borderColor: "divider",
                  borderRadius: 2,
                }}
              >
                <CardContent>
                  <Avatar
                    src={card.image}
                    alt={`Opción ${card.id}`}
                    sx={{
                      width: { xs: 100, sm: 120, md: 150 },
                      height: { xs: 100, sm: 120, md: 150 },
                      mx: "auto",
                      mb: 2,
                    }}
                  />
                  <YellowButton
                    variant="contained"
                    color="primary"
                    onClick={card.action}
                    sx={{
                      width: "100%",
                      textTransform: "none",
                      mb: 2,
                    }}
                  >
                    {card.buttonText}
                  </YellowButton>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "0.9rem", lineHeight: 1.5 }}
                  >
                    <br />
                    {card.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Modal>
  )
}

export default ModalExpireFreeTrial
