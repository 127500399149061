import React, { useState, useContext, useEffect, useRef } from "react"
import propTypes from "prop-types"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import {
  CapituloContainer,
  SubChapterContainer,
  ShowChildDiv,
  ShowChildDivWithBorder,
} from "common/containers"
import { HelveticaBold, PoppinsNormal } from "common/typographies"
import i18n from "i18n"
import ContactContext from "context/contact/contactContext"
import PartTable from "components/BancoPrecios/parts/index"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import AuthContext from "context/auth/authContext"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import ModalDeleteCap from "../modals/modalDeleteCap"
import NewModalPartida from "pages/ModalPartidas/NewModalPartida"
import { CircularProgress } from "@material-ui/core"
import ArrowDownIcon from "assets/customIcons/ArrowDownIcon.svg"
import ArrowUpIcon from "assets/customIcons/ArrowUpIcon.svg"

const Capitulo = props => {
  const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
  const [subCapsLoaded, setSubCapsLoaded] = useState(false)
  const {
    getSubCaps,
    loadingCaps,
    addBankChapter,
    updateChapterBank,
    checkCapitulos,
    updateChaptersList,
    checkMultipleChapters,
    selectedCategories,
    getSelectedChapters,
    selectedCategories2,
    categoriesChecked,
    moveChaptersWithArrows,
  } = useContext(BankPricesContext)
  const { user, freeTrialExpired } = useContext(AuthContext)
  const [openEliminate, setOpenEliminate] = useState(false)

  const [moreTags, setMoreTags] = useState([])
  const refExtraTag = useRef(null)
  const [loadingContent, setLoadingContent] = useState(false)
  const handleOpen = async () => {
    setOpenNotSendCapitulo(prev => !prev)
    if (subCapsLoaded === false) {
      setLoadingContent(true)
      await getSubCaps(props.capitulo.chapterId, user.sub)
      setLoadingContent(false)
      setSubCapsLoaded(true)
    }
  }

  const getPixels = word => {
    let sumPixels = 0
    for (let i = 0; i < word.length; i++) {
      if (word.charAt(i) == word.charAt(i).toUpperCase()) {
        sumPixels += 12.4
      } else {
        sumPixels += 9.6
      }
    }
    if (sumPixels > 650) {
      return sumPixels * 1.35
    } else if (sumPixels > 500) {
      return sumPixels * 1.2
    } else if (sumPixels > 300) {
      return sumPixels * 1.1
    } else {
      return sumPixels
    }
  }

  const contactContext = useContext(ContactContext)
  const [openPart, setOpenPart] = useState(false)
  const addSubChapter = () => {
    const data = {
      name: null,
      description: null,
      parentId: props.capitulo.capituloInfo.chapterBankId,
      price: null,
      tags: null,
      accountId: user.sub,
      code: null,
      root: "false",
    }
    addBankChapter(
      data.parentId,
      data.projectId,
      props.capitulo.subcapitulos.length,
      data.accountId,
      data.root,
    )
  }
  const addSubChapter2 = () => {
    const data = {
      name: null,
      description: null,
      parentId: props.capitulo.capituloInfo.parentId,
      price: null,
      tags: null,
      accountId: user.sub,
      code: null,
      root: "false",
    }
    addBankChapter(
      data.parentId,
      data.projectId,
      props.lengthSubCap,
      data.accountId,
      data.root,
    )
  }

  const handleUpdateChapter = async () => {
    const data = {
      name: name,
      description: "",
      chapterId: props.capitulo.capituloInfo.chapterBankId,
      tags: [],
      accountId: user.sub,
      code: null,
    }
    updateChapterBank(data)
  }
  const [name, setName] = useState(props.capitulo.capituloInfo.name)
  useEffect(() => {
    setName(props.capitulo.capituloInfo.name)
  }, [props.capitulo])

  const handleChangeName = e => {
    setName(e.target.value)
  }
  const changeCheck = async e => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    if (e.nativeEvent.shiftKey) {
      if (props.capitulo.checked === true) {
        await checkCapitulos(props.capitulo.chapterId, false)
        await updateChaptersList(props.capitulo.capituloInfo)
      } else {
        await checkMultipleChapters(props.capitulo.chapterId)
      }
    } else {
      if (props.capitulo.checked === true) {
        await checkCapitulos(props.capitulo.chapterId, false)
        await updateChaptersList(props.capitulo.capituloInfo)
      } else {
        await checkCapitulos(props.capitulo.chapterId, true)
        await updateChaptersList(props.capitulo.capituloInfo)
      }
    }
  }

  const [tags, setTags] = useState([])
  const [color, setColor] = useState(0)
  const listaColores = [
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
    "color7",
    "color8",
  ]

  useEffect(() => {
    if (props.capitulo.capituloInfo.tags !== null) {
      setTags(props.capitulo.capituloInfo.tags)
    } else {
      setTags([])
    }
  }, [])
  useEffect(() => {
    let nuevo = []
    if (tags.length > 2) {
      for (let i = 2; i < tags.length; i++) {
        nuevo.push(tags[i])
      }
    } else {
      if (refExtraTag.current) {
        refExtraTag.current.className = "NOseeMoretags"
      }
    }
    setMoreTags(nuevo)
  }, [tags])

  const createPart = () => {
    setOpenPart(true)
  }
  return (
    <div
      style={{
        marginBottom:
          props.capitulo.capituloInfo.name == "PARTIDAS SIN CLASIFICAR"
            ? "40px"
            : "0px",
      }}
    >
      {props.capitulo.capituloInfo.name == "PARTIDAS SIN CLASIFICAR" ? (
        <PoppinsNormal style={{ fontWeight: "700" }}>
          {i18n.t("Partidas sin clasificar")}:
        </PoppinsNormal>
      ) : null}
      {props.capitulo.parentId == "root" ||
      props.capitulo.capituloInfo.parentId === "root" ? (
        <div>
          <CapituloContainer
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              handleOpen()
            }}
            className="hoverCapitulo"
            style={{ background: "white" }}
          >
            <div
              className="hoverYellow"
              style={{
                height: "44px",
                background: !openNotSendCapitulo ? "white" : "#FCD56B",
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                borderRadius: "6px",
                paddingTop: "12px",
              }}
            >
              {loadingContent ? (
                <CircularProgress
                  style={{
                    width: "18px",
                    height: "20px",
                    color: "#414141",
                    marginLeft: "3px",
                    marginRight: "4px",
                    marginTop: "-1.5px",
                  }}
                />
              ) : openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginTop: "-1.5px",
                    }}
                    className="dropdownArrowB"
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: "3px",
                    marginTop: "-1.5px",
                  }}
                  className="dropdownArrowB"
                />
              )}
              <div
                style={{ marginTop: "0.5px" }}
                className={props.capitulo.checked ? " " : "detallesCap"}
              >
                <div className="tdcheckColumn">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={props.capitulo.checked}
                    id="flexCheckDefault"
                    onClick={e => {
                      if (!e) var e = window.event
                      e.cancelBubble = true
                      if (e.stopPropagation) e.stopPropagation()
                    }}
                    onChange={changeCheck}
                    style={{
                      width: "13px",
                      height: "13px",
                      cursor: "pointer",
                      zIndex: "5",
                    }}
                  ></input>
                </div>
              </div>
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  marginTop: "-0.5px",
                  marginRight: "5px",
                }}
              >
                {props.referencia + "."}
              </HelveticaBold>
              <input
                onChange={handleChangeName}
                onClick={e => {
                  if (!e) var e = window.event
                  e.cancelBubble = true
                  if (e.stopPropagation) e.stopPropagation()
                }}
                onBlur={handleUpdateChapter}
                className="NombreAlign"
                style={{
                  marginTop: "-9.5px",
                  fontWeight: "700",
                  width: getPixels(name) + "px",
                  minWidth: name.length == 0 ? "270px" : "120px",
                }}
                name={"name"}
                value={name}
                placeholder={i18n.t("Introduce el nombre de la categoria")}
                autoComplete="off"
                disabled={
                  props.capitulo.capituloInfo.name == "PARTIDAS SIN CLASIFICAR"
                    ? true
                    : user
                      ? freeTrialExpired || user["custom:role"] === "WORKER"
                        ? true
                        : false
                      : false
                }
              ></input>

              <div className="tdTotalCap" style={{ paddingLeft: "7px" }}>
                <div
                  className="inline"
                  style={{
                    marginLeft: "5px",
                    position: "absolute",
                    right: "1%",
                    marginTop: "0px",
                  }}
                >
                  <div
                    className="detallesCap"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "65px",
                    }}
                  >
                    {user ? (
                      freeTrialExpired ||
                      user["custom:role"] === "WORKER" ? null : (
                        <DeleteOutlineIcon
                          style={{ width: "19px", cursor: "pointer" }}
                          onClick={e => {
                            e.stopPropagation()
                            setOpenEliminate(true)
                          }}
                        />
                      )
                    ) : null}
                    {props.referencia < props.lengthSubCap && (
                      <img
                        src={ArrowDownIcon}
                        onClick={() => {
                          moveChaptersWithArrows(props.capitulo, "down")
                        }}
                        style={{
                          width: "15px",
                          height: "15px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                    {props.indice !== 0 && (
                      <img
                        src={ArrowUpIcon}
                        onClick={() => {
                          moveChaptersWithArrows(props.capitulo, "up")
                        }}
                        style={{
                          width: "13px",
                          height: "13px",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CapituloContainer>
          {openNotSendCapitulo ? (
            <ShowChildDiv
              style={{
                width: "99%",
                marginTop: props.capitulo.partidas?.length ? "-8px" : "-14px",
                background: "#FDEFC6",
              }}
            >
              {props.capitulo.partidas?.length ? (
                <PartTable
                  referencia={props.referencia}
                  parts={props.capitulo.partidas}
                  total={props.capitulo.total}
                  capitulo={props.capitulo}
                />
              ) : props.capitulo.subcapitulos?.length ? (
                props.capitulo.subcapitulos.map((capitulo, i) => (
                  <Capitulo
                    key={capitulo.chapterId}
                    i={i}
                    capitulo={capitulo}
                    referencia={props.referencia + "." + (i + 1)}
                    imParent={(props.capitulo.parentId = "root" ? true : false)}
                    imLast={
                      props.capitulo.subcapitulos.length - 1 === i
                        ? true
                        : false
                    }
                    indice={i}
                    lengthSubCap={props.capitulo.subcapitulos.length}
                  />
                ))
              ) : user ? (
                freeTrialExpired || user["custom:role"] === "WORKER" ? null : (
                  <div style={{ display: "grid" }}>
                    <div className="NombreAlign">
                      <button
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="AddNewCategoryBP"
                        data-amplify-analytics-attrs={props.capitulo}
                        onClick={() => {
                          addSubChapter()
                        }}
                        className="nuevoBotonCrear4"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        + {i18n.t("Nueva Subcategoría")}
                      </button>
                    </div>
                    <div className="NombreAlign">
                      <button
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="AddNewPartBudgetting"
                        data-amplify-analytics-attrs={props.capitulo}
                        onClick={() => {
                          createPart()
                        }}
                        className="nuevoBotonCrear4"
                        style={{
                          marginTop: "10px",
                          marginBottom: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        + {i18n.t("Buscar o crear nueva partida")}
                      </button>
                    </div>
                  </div>
                )
              ) : null}
            </ShowChildDiv>
          ) : null}
        </div>
      ) : (
        //im a subchapter  <SubChapterContainer> --> 'className='NewSubCapi' in budgeting.css
        <div>
          <SubChapterContainer
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              handleOpen()
            }}
            className="hoverCapitulo"
            style={{
              borderLeft: props.imParent ? "6px solid #F1CA2C" : "0px",
              background: "#FCD56B",
            }}
          >
            <div
              className="hoverYellow"
              style={{
                height: "44px",
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                paddingTop: "11px",
                background: "#FCD56B",
                borderRadius: "6px",
              }}
            >
              {loadingContent ? (
                <CircularProgress
                  style={{
                    width: "18px",
                    height: "20px",
                    color: "#414141",
                    marginLeft: "3px",
                    marginRight: "4px",
                    marginTop: "-1.5px",
                    display: "block",
                  }}
                />
              ) : openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{
                      cursor: "pointer",
                      marginLeft: "3px",
                      marginTop: "-1.5px",
                    }}
                    className="dropdownArrowB"
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{
                    cursor: "pointer",
                    marginLeft: "3px",
                    marginTop: "-1.5px",
                  }}
                  className="dropdownArrowB"
                />
              )}
              <div
                style={{ marginTop: "0.5px" }}
                className={props.capitulo.checked ? " " : "detallesCap"}
              >
                <div className="tdcheckColumn" style={{ height: "0px" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={props.capitulo.checked}
                    id="flexCheckDefault"
                    onClick={e => {
                      if (!e) var e = window.event
                      e.cancelBubble = true
                      if (e.stopPropagation) e.stopPropagation()
                    }}
                    onChange={changeCheck}
                    style={{
                      width: "13px",
                      height: "13px",
                      cursor: "pointer",
                      zIndex: "5",
                    }}
                  ></input>
                </div>
              </div>
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  marginTop: "-0.5px",
                  marginRight: "5px",
                }}
              >
                {props.referencia + "."}
              </HelveticaBold>
              <input
                onChange={handleChangeName}
                onClick={e => {
                  if (!e) var e = window.event
                  e.cancelBubble = true
                  if (e.stopPropagation) e.stopPropagation()
                }}
                onBlur={handleUpdateChapter}
                className="NombreAlign"
                style={{
                  marginTop: "-9.5px",
                  fontWeight: "700",
                  width: getPixels(name) + "px",
                  minWidth: name.length == 0 ? "270px" : "120px",
                }}
                name={"name"}
                value={name}
                placeholder={i18n.t("Introduce el nombre de la categoria")}
                autoComplete="off"
                disabled={
                  user
                    ? freeTrialExpired || user["custom:role"] === "WORKER"
                      ? true
                      : false
                    : false
                }
              ></input>

              <div
                className="inline"
                style={{
                  marginLeft: "5px",
                  position: "absolute",
                  right: "1%",
                  marginTop: "0px",
                }}
              >
                <div
                  className="detallesCap"
                  style={{
                    backgroundColor: "transparent",
                    marginTop: "-2px",
                    width: "55px",
                    height: "0px",
                    justifyContent: "center",
                    marginLeft: "120px",
                  }}
                >
                  {user ? (
                    freeTrialExpired ||
                    user["custom:role"] === "WORKER" ? null : (
                      <DeleteOutlineIcon
                        style={{ width: "19px", cursor: "pointer" }}
                        onClick={e => {
                          e.stopPropagation()
                          setOpenEliminate(true)
                        }}
                      />
                    )
                  ) : null}
                  {props.indice + 1 < props.lengthSubCap && (
                    <img
                      src={ArrowDownIcon}
                      onClick={() => {
                        moveChaptersWithArrows(props.capitulo, "down")
                      }}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginTop: "5px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                  {props.indice !== 0 && (
                    <img
                      src={ArrowUpIcon}
                      onClick={() => {
                        moveChaptersWithArrows(props.capitulo, "up")
                      }}
                      style={{
                        width: "13px",
                        height: "13px",
                        marginTop: "5px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </SubChapterContainer>

          {openNotSendCapitulo ? (
            props.imParent ? (
              <ShowChildDivWithBorder
                style={{
                  paddingLeft: props.capitulo.partidas?.length ? "0px" : "8px",
                  borderLeft: "7px solid #F1CA2C",
                }}
              >
                {props.capitulo.partidas?.length ? (
                  <PartTable
                    referencia={props.referencia}
                    parts={props.capitulo.partidas}
                    imParent={props.imParent ? props.imParent : false}
                    total={props.capitulo.total}
                    capitulo={props.capitulo}
                  />
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <Capitulo
                      referencia={props.referencia + "." + (i + 1)}
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                      imLast={
                        props.capitulo.subcapitulos.length - 1 === i
                          ? true
                          : false
                      }
                      indice={i}
                    />
                  ))
                ) : user ? (
                  freeTrialExpired ||
                  user["custom:role"] === "WORKER" ? null : (
                    <div style={{ display: "grid" }}>
                      <div className="NombreAlign">
                        <button
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="AddNewCategoryBP"
                          data-amplify-analytics-attrs={props.capitulo}
                          onClick={() => {
                            addSubChapter()
                          }}
                          className="nuevoBotonCrear4"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          + {i18n.t("Nueva Subcategoría")}
                        </button>
                      </div>
                      <div className="NombreAlign">
                        <button
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="AddNewPartBudgetting"
                          data-amplify-analytics-attrs={props.capitulo}
                          onClick={() => {
                            createPart()
                          }}
                          className="nuevoBotonCrear4"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          + {i18n.t("Buscar o crear nueva partida")}
                        </button>
                      </div>
                    </div>
                  )
                ) : null}
              </ShowChildDivWithBorder>
            ) : (
              <ShowChildDiv style={{ backgroundColor: "#FDEFC6" }}>
                {props.capitulo.partidas?.length ? (
                  <PartTable
                    referencia={props.referencia}
                    parts={props.capitulo.partidas}
                    total={props.capitulo.total}
                    capitulo={props.capitulo}
                  />
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <Capitulo
                      referencia={props.referencia + "." + (i + 1)}
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                      imLast={
                        props.capitulo.subcapitulos.length - 1 === i
                          ? true
                          : false
                      }
                      indice={i}
                    />
                  ))
                ) : user ? (
                  freeTrialExpired ||
                  user["custom:role"] === "WORKER" ? null : (
                    <div style={{ display: "grid" }}>
                      <div className="NombreAlign">
                        <button
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="AddNewCategoryBP"
                          data-amplify-analytics-attrs={props.capitulo}
                          onClick={() => {
                            addSubChapter()
                          }}
                          className="nuevoBotonCrear4"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          + {i18n.t("Nueva Subcategoría")}
                        </button>
                      </div>
                      <div className="NombreAlign">
                        <button
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="AddNewPartBudgetting"
                          data-amplify-analytics-attrs={props.capitulo}
                          onClick={() => {
                            createPart()
                          }}
                          className="nuevoBotonCrear4"
                          style={{
                            marginTop: "10px",
                            marginBottom: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          + {i18n.t("Buscar o crear nueva partida")}
                        </button>
                      </div>
                    </div>
                  )
                ) : null}
              </ShowChildDiv>
            )
          ) : null}
          {user ? (
            freeTrialExpired ||
            user["custom:role"] === "WORKER" ? null : props.imLast ? (
              props.imParent ? (
                <div className="NombreAlign">
                  <button
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="AddNewCategoryBP"
                    data-amplify-analytics-attrs={props.capitulo}
                    onClick={() => {
                      addSubChapter2()
                    }}
                    className="nuevoBotonCrear4"
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    + {i18n.t("Nueva Subcategoría")}
                  </button>
                </div>
              ) : (
                <div className="NombreAlign">
                  <button
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="AddNewCategoryBP"
                    data-amplify-analytics-attrs={props.capitulo}
                    onClick={() => {
                      addSubChapter2()
                    }}
                    className="nuevoBotonCrear2"
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    + {i18n.t("Nueva Subcategoría")}
                  </button>
                </div>
              )
            ) : null
          ) : null}
        </div>
      )}

      <ModalDeleteCap
        openEliminate={openEliminate}
        setOpenEliminate={setOpenEliminate}
        infoCap={props.capitulo}
      />
      <NewModalPartida
        open={openPart}
        setOpen={setOpenPart}
        chapterId={props.capitulo.capituloInfo.chapterBankId}
        bancoDePrecios={"MI BANCO DE PRECIOS"}
      />
    </div>
  )
}

Capitulo.propTypes = {
  key: propTypes.string.isRequired,
  i: propTypes.number.isRequired,
  capitulo: propTypes.object.isRequired,
  imParent: propTypes.bool,
  imLast: propTypes.bool,
  referencia: propTypes.string,
  indice: propTypes.number,
  lengthSubCap: propTypes.number,
}

export default Capitulo
