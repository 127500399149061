import React from "react"
import PropTypes from "prop-types"
import TableStructureAuto from "./tableStructureAuto"
import PartAuto from "./partAuto"

const PartTableAuto = ({
  parts,
  capitulo,
  width,
  ml,
  bankSelected,
  tipo,
  disabled,
  setDisabled,
  loadingButton,
  setLoadingButton,
  doTheAction,
  setDoTheAction,
  chapterId,
  projectInfo,
  setOpen,
}) => {
  return (
    <TableStructureAuto
      capitulo={capitulo}
      width={width}
      ml={ml}
      bankSelected={bankSelected}
      tipo={tipo}
      disabled={disabled}
      setDisabled={setDisabled}
      loadingButton={loadingButton}
      setLoadingButton={setLoadingButton}
      doTheAction={doTheAction}
      setDoTheAction={setDoTheAction}
      chapterId={chapterId}
      projectInfo={projectInfo}
      setOpen={setOpen}
      parts={parts}
    >
      {parts.map(part => (
        <PartAuto
          key={part.partidaId}
          part={part}
          bankSelected={bankSelected}
        />
      ))}
    </TableStructureAuto>
  )
}
PartTableAuto.propTypes = {
  parts: PropTypes.array.isRequired,
  capitulo: PropTypes.object,
  width: PropTypes.number,
  ml: PropTypes.number,
  bankSelected: PropTypes.string,
  tipo: PropTypes.string,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  loadingButton: PropTypes.bool,
  setLoadingButton: PropTypes.func,
  doTheAction: PropTypes.bool,
  setDoTheAction: PropTypes.func,
  chapterId: PropTypes.string,
  projectInfo: PropTypes.object,
  setOpen: PropTypes.func,
}
export default PartTableAuto
