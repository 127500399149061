import { isNil } from "lodash"
import PartCertHistory from "./PartCertHistory"

class ChapterCertHistory {
  constructor(data) {
    this.accountId = data.accountId
    this.chapterId = data.chapterId
    this.chaptersCount = data.chapters_count || 0
    this.clientPrice = data.clientPrice
    this.clientPriceWithoutDiscount = data.clientPriceWithoutDiscount
    this.createdAt = data.createdAt
    this.name = data.name
    this.organizationId = data.organizationId
    this.parentId = data.parentId
    this.partsCount = data.partidas_count || 0
    this.position = data.position || 0
    this.price = data.price
    this.projectId = data.projectId
    this.selfManagement = data.selfManagement
    this.sended = data.sended
    this.updatedAt = data.updatedAt
    this.chapters = isNil(data.chapters)
      ? []
      : data.chapters.map(chapter => new ChapterCertHistory(chapter))
    this.parts = isNil(data.partidas)
      ? []
      : data.partidas.map(part => new PartCertHistory(part))
    this.totalChapterPrice = 0
  }

  updateTotalChapterPrice = () => {
    if (this.chapters.length > 0) {
      this.totalChapterPrice = this.chapters.reduce(
        (total, chapter) => total + chapter.updateTotalChapterPrice(),
        0,
      )
    } else if (this.parts.length > 0) {
      this.totalChapterPrice = this.parts.reduce(
        (total, part) => total + part.totalPrice,
        0,
      )
    } else {
      this.totalChapterPrice = 0
    }
    return this.totalChapterPrice
  }
}

export default ChapterCertHistory
