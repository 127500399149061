import React from "react"
import PropTypes from "prop-types"
import {
  DataGrid,
  GridEditModes,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid"
import { Box } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles"

import DataGridLocaleText from "common/DataGridLocaleText"
import ConfirmDeleteModal from "./ConfirmDeleteModal"
import CanNotDeleteModal from "./CanNotDeleteModal"
import { useDynamicElements } from "hooks/DynamicElements/useDynamicElements"
import { useColumnWidths } from "hooks/DynamicElements/useColumnWidths"
import { useColumns } from "hooks/DynamicElements/useColumns"
import { useDeleteElement } from "hooks/DynamicElements/useDeleteElement"
import { isNil } from "lodash"

const theme = createTheme({
  palette: {
    primary: { main: "#555555" },
    secondary: { main: "#FFFFFF" },
  },
})

const DynamicElementList = ({
  organizationId,
  children,
  apiRef,
  showActions = true,
}) => {
  const { loading, dynamicElements } = useDynamicElements(organizationId)

  const { columnWidths, handleCellEditStart, handleCellEditStop } =
    useColumnWidths()

  const columns = useColumns(apiRef, columnWidths)

  const {
    handleCancelDeleteModal,
    handleConfirm,
    deleteElement,
    handleCloseCanNotDeleteModal,
  } = useDeleteElement(organizationId)

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  )

  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <Box>{children}</Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ height: 450 }}>
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              {...(apiRef
                ? { apiRef, checkboxSelection: dynamicElements.length > 0 }
                : {})}
              loading={loading}
              rows={dynamicElements.map(e => ({ ...e, organizationId }))}
              columns={columns}
              columnVisibilityModel={{ itemId: false, actions: showActions }}
              editMode={apiRef ? GridEditModes.None : GridEditModes.Cell}
              hideFooterPagination
              hideFooter
              sx={{ overflow: "clip" }}
              getRowId={row => row.itemId}
              onCellEditStart={!apiRef ? handleCellEditStart : undefined}
              onCellEditStop={!apiRef ? handleCellEditStop : undefined}
              localeText={DataGridLocaleText()}
            />
          </Box>
        </Box>

        <ConfirmDeleteModal
          open={deleteElement.openConfirmModal}
          onClose={handleCancelDeleteModal}
          onConfirm={handleConfirm}
          elementToDelete={deleteElement.elementToDelete}
        />

        <CanNotDeleteModal
          open={!isNil(deleteElement.partsRelated)}
          onClose={handleCloseCanNotDeleteModal}
          elementToDelete={deleteElement.elementToDelete}
          partsRelated={deleteElement.partsRelated}
        />
      </ThemeProvider>
    </div>
  )
}

DynamicElementList.propTypes = {
  organizationId: PropTypes.string.isRequired,
  children: PropTypes.node,
  apiRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  showActions: PropTypes.bool,
}

export default DynamicElementList
