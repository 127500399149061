import {
  GET_URL_POST,
  GET_URL_POST_SUCCESS,
  GET_URL_POST_ERROR,
  UPLOAD_FILE_SUCCESS,
  GET_MY_FILES_SUCCESS,
  UPLOADING_FILE,
} from "../types"
const FileReducer = (state, action) => {
  switch (action.type) {
    case UPLOADING_FILE:
      return {
        ...state,
        loadingFile: true,
      }
    case "GET_FILES":
      return {
        ...state,
        loading: true,
      }
    case GET_URL_POST:
      return {
        ...state,
      }
    case GET_URL_POST_SUCCESS:
      return {
        ...state,
        uploadProcess: {
          ...state.uploadProcess,
          urlPost: action.payload,
          isUploading: true,
          fileInfo: action.payload,
        },
      }
    case GET_URL_POST_ERROR:
      return {
        ...state,
      }
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loadingFile: false,
        uploadProcess: {
          ...state.uploadProcess,
          isUploading: false,
          uploaded: true,
          progress: 1,
        },
      }
    case GET_MY_FILES_SUCCESS:
      return {
        ...state,
        files: action.payload.archivos,
        fotos: action.payload.pictures,
        loading: false,
        filteredFilesByProject: action.payload.filteredFilesByProject,
      }
    default:
      return state
  }
}
export default FileReducer
