import React, { Fragment, useContext, useState } from "react"
import i18n from "i18n"
import Table from "@mui/material/Table"
import ProjectContext from "context/projects/projectContext"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import PropTypes from "prop-types"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { HelveticaBold, PoppinsNormal } from "common/typographies"
import { YellowButton } from "common/buttons"
import { useHistory, useParams } from "react-router-dom"
import ActRow from "./actRow"

const COLUMNS = [
  {
    id: "createdAt",
    label: i18n.t("Fecha"),
    show: true,
    type: "date",
  },
  {
    id: "actNum",
    label: i18n.t("No. Acta"),
    show: true,
    type: "string",
  },
  {
    id: "projectName",
    label: i18n.t("Proyecto"),
    show: true,
    type: "string",
  },
  {
    id: "projectId.client.completeName",
    label: i18n.t("Cliente Asociado"),
    show: true,
    type: "string",
  },
  ,
  {
    id: "attendees",
    label: i18n.t("Asistentes"),
    show: true,
    type: "attendees",
  },
  {
    id: "status",
    label: i18n.t("Estado"),
    show: true,
    type: "status",
  },
]

const actStatus = status => {
  let firmas = 0
  for (let i = 0; i < status.attendees.length; i++) {
    if (status.attendees[i].hasSigned) {
      firmas += 1
    }
  }
  let bgColor = ""
  let color = "white"
  let text = ""

  if (firmas === status.attendees.length) {
    bgColor = "#0C8527"
    text = "FIRMADA " + firmas + "/" + status.attendees.length
  } else if (firmas > 0) {
    bgColor = "#EE950F"
    text = "FIRMADA " + firmas + "/" + status.attendees.length
  } else {
    bgColor = "#BF2929"
    text = "SIN FIRMAR " + firmas + "/" + status.attendees.length
  }
  return (
    <div
      className="inline"
      style={{
        marginTop: "0px",
        position: "relative",
        backgroundColor: bgColor,
        height: "22px",
        borderRadius: "6px",
        width: "110px",
        padding: "0px",
      }}
    >
      <PoppinsNormal
        style={{
          fontSize: "9px",
          color: color,
          lineHeight: "20px",
          cursor: "pointer",
          paddingTop: "1px",
          paddingLeft: "3px",
          marginLeft: "10px",
        }}
      >
        {text}
      </PoppinsNormal>
      {firmas == 0 ? (
        <HighlightOffIcon
          style={{
            color: color,
            fontSize: "16PX",
            position: "absolute",
            right: "5px",
            marginTop: "3px",
          }}
        />
      ) : (
        <CheckCircleOutlineIcon
          style={{
            color: color,
            fontSize: "16PX",
            position: "absolute",
            right: "5px",
            marginTop: "3px",
          }}
        />
      )}
    </div>
  )
}

const ActasTableCustom = ({ actas }) => {
  const [columns, setColumns] = useState(COLUMNS)
  const history = useHistory()
  const goToEdit = (e, act) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const resolveValue = (act, column) => {
    switch (column.type) {
      case "date":
        try {
          const dt = new Date(act[column.id]).toLocaleDateString()
          if (dt === "Invalid Date") return null
          return dt
        } catch (e) {
          return ""
        }
      case "string":
        try {
          let ids = column.id.split(".")
          if (ids.length > 1) {
            return act[ids[0]][ids[1]]
          }
          return act[column.id]
        } catch (e) {
          return (
            <YellowButton
              style={{
                width: "70px",
                height: "30px",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={e => {
                goToEdit(e, act)
              }}
            >
              Editar
            </YellowButton>
          )
        }
      case "attendees":
        try {
          let firstAttendee = act.attendees[0].name
          return firstAttendee
        } catch (e) {
          return ""
        }
      case "status":
        return actStatus(act)
    }
  }
  const [selectedact, setSelectedact] = useState(null)

  const handlePreviewPDF = actId => {
    setSelectedact(actId)
    setOpenPDF(true)
  }

  const { projectId } = useParams()
  const { projectInfo } = useContext(ProjectContext)
  return (
    <Fragment>
      <div
        style={{ width: "100%", position: "relative", height: "60px" }}
        className={"inline"}
      >
        {projectId && projectInfo ? (
          <HelveticaBold
            style={{
              color: "#414141",
              fontSize: "20px",
              left: "30px",
              position: "absolute",
            }}
          >
            {i18n.t("Actas") + ` de ${projectInfo.name}`}
          </HelveticaBold>
        ) : (
          <HelveticaBold
            style={{
              color: "#414141",
              fontSize: "20px",
              left: "30px",
              position: "absolute",
            }}
          >
            {i18n.t("Actas")}
          </HelveticaBold>
        )}
        <div
          className="inline"
          style={{ position: "absolute", right: "20px", marginTop: "-15px" }}
        >
          <YellowButton
            onClick={() => history.push("/createAct/" + projectId)}
            style={{
              color: "white",
              height: "40px",
              width: "128px",
              marginRight: "0px",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "6px",
              paddingTop: "8px",
            }}
          >
            <HelveticaBold
              style={{
                fontSize: "14px",
                color: "#414141",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {i18n.t("Nueva Acta")}
            </HelveticaBold>
          </YellowButton>
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginRight: "20px",
          marginLeft: "30px",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            maxWidth: "95vw",
            overflow: "hidden",
            height: "auto",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map(
                  (column, index) =>
                    column.show && (
                      <TableCell key={index} id="helveticaTitle">
                        {column.label}
                      </TableCell>
                    ),
                )}
                <TableCell>
                  <div style={{ height: "20px" }}> </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {actas.map(act => (
                <ActRow
                  act={act}
                  key={act}
                  columns={columns}
                  projectId={projectId}
                  resolveValue={resolveValue}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Fragment>
  )
}

ActasTableCustom.propTypes = {
  actas: PropTypes.array.isRequired,
}

export default ActasTableCustom
