import React from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { PoppinsNormal, DisabledPoppins } from "common/typographies"

const ProvidersTimeLine = ({ proposal }) => {
  function formatDate(fec) {
    const date = new Date(fec)
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    })

    return formattedDate
  }

  return (
    <div className="row" style={{ paddingTop: "15px" }}>
      {proposal.proposalStatus == "PENDING" ? (
        <div className="col">
          <div
            className="timeline-steps aos-init aos-animate"
            data-aos="fade-up"
          >
            <div className="timeline-step">
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
              >
                <div
                  className="inner-circle"
                  style={{ color: "white", backgroundColor: "white" }}
                ></div>
                <PoppinsNormal style={{ marginTop: "0px", fontSize: "10px" }}>
                  {i18n.t("Solicitud enviada el día")}{" "}
                  {formatDate(proposal.createdAt)}{" "}
                </PoppinsNormal>
              </div>
            </div>
            <div className="timeline-step">
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
              >
                <div className="inner-circle"></div>
                <DisabledPoppins style={{ marginTop: "0px" }}>
                  {i18n.t("Pendiente de aprobación")}
                </DisabledPoppins>
              </div>
            </div>
            <div className="timeline-step">
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
              >
                <div className="inner-circle"></div>
                <DisabledPoppins style={{ marginTop: "0px" }}>
                  {i18n.t("¡El proveedor ha enviado su propuesta!")}
                </DisabledPoppins>
              </div>
            </div>
          </div>
        </div>
      ) : proposal.proposalStatus == "OPEN" ? (
        <div className="col">
          <div
            className="timeline-steps aos-init aos-animate"
            data-aos="fade-up"
          >
            <div className="timeline-step">
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
              >
                <div className="inner-circle"></div>
                <DisabledPoppins style={{ marginTop: "0px" }}>
                  {i18n.t("Solicitud enviada el día")}{" "}
                  {formatDate(proposal.createdAt)}
                </DisabledPoppins>
              </div>
            </div>
            <div className="timeline-step">
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
              >
                <div
                  className="inner-circle"
                  style={{ color: "white", backgroundColor: "white" }}
                ></div>
                <PoppinsNormal style={{ marginTop: "0px", fontSize: "10px" }}>
                  {i18n.t("El proovedor ha abierto tu propuesta")}{" "}
                </PoppinsNormal>
              </div>
            </div>
            <div className="timeline-step">
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
              >
                <div className="inner-circle"></div>
                <DisabledPoppins style={{ marginTop: "0px" }}>
                  {i18n.t("¡El proveedor ha enviado su propuesta!")}
                </DisabledPoppins>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col">
          <div
            className="timeline-steps aos-init aos-animate"
            data-aos="fade-up"
          >
            <div className="timeline-step">
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
              >
                <div className="inner-circle"></div>
                <DisabledPoppins style={{ marginTop: "0px" }}>
                  {i18n.t("Solicitud enviada el día")}{" "}
                  {formatDate(proposal.createdAt)}
                </DisabledPoppins>
              </div>
            </div>
            <div className="timeline-step">
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
              >
                <div className="inner-circle"></div>
                <DisabledPoppins style={{ marginTop: "0px" }}>
                  {i18n.t("Pendiente de aprobación")}
                </DisabledPoppins>
              </div>
            </div>
            <div className="timeline-step">
              <div
                className="timeline-content"
                data-toggle="popover"
                data-trigger="hover"
                data-placement="top"
              >
                <div
                  className="inner-circle"
                  style={{ color: "white", backgroundColor: "white" }}
                ></div>
                <PoppinsNormal style={{ marginTop: "0px", fontSize: "10px" }}>
                  {" "}
                  {i18n.t("¡El proveedor ha enviado su propuesta!")}{" "}
                </PoppinsNormal>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

ProvidersTimeLine.propTypes = {
  proposal: PropTypes.object,
}

export default ProvidersTimeLine
