import React, { useContext, useEffect } from "react"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { useState } from "react"
import i18n from "i18n"
import CloseIcon from "@mui/icons-material/Close"
import Budget2023Context from "context/budget2023/Budget2023Context"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "555px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalFilterByTag = ({ open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { getTagsByProject, tags, budgetTree, saveTagsToFilter, tagsFiltered } =
    useContext(Budget2023Context)
  useEffect(() => {
    if (budgetTree) {
      getTagsByProject()
    }
  }, [budgetTree.subcapitulos.length, open])

  const [tagsToFilter, setTagsToFilter] = useState([])
  useEffect(() => {
    setTagsToFilter(tags)
    if (tagsFiltered.length > 0 && tags.length > 0) {
      let aux = []
      for (let i = 0; i < tags.length; i++) {
        let trobat = false
        for (let j = 0; j < tagsFiltered.length; j++) {
          if (tags[i] === tagsFiltered[j]) {
            trobat = true
          }
        }
        if (!trobat) {
          aux.push(tags[i])
        }
      }
      setTagsToFilter(aux)
    }
  }, [tags, tagsFiltered])
  const [selectedTags, setSelectedTags] = useState([])
  const handleTags = tag => {
    setSelectedTags([...selectedTags, tag])
    tagsToFilter.splice(tagsToFilter.indexOf(tag), 1)
  }
  const deleteTag = (e, tag) => {
    e.preventDefault()
    setTagsToFilter([...tagsToFilter, tag])
    selectedTags.splice(selectedTags.indexOf(tag), 1)
  }
  const saveTagsFilter = async () => {
    await saveTagsToFilter(selectedTags)
    setOpen(false)
  }
  useEffect(() => {
    if (tagsFiltered.length > 0) {
      setSelectedTags(tagsFiltered)
    }
  }, [tagsFiltered])

  return (
    <Modal open={open} onClose={() => null}>
      <div style={modalStyle} className={classes.paper}>
        <div
          id="principalOptions"
          style={{ marginTop: "20px", marginBottom: "15px", textAlign: "left" }}
        >
          <CloseIcon
            id="cerrar"
            style={{ marginTop: "-15px", right: "-10px" }}
            onClick={() => {
              setOpen(false)
            }}
          ></CloseIcon>
          <p id="titles" style={{ textAlign: "left" }}>
            {i18n.t("Filtrar por etiquetas")}
          </p>
          <p style={{ fontWeight: "300", textAlign: "left" }}>
            {i18n.t(
              "Selecciona las etiquetas por las que quieres filtrar tus capitulos",
            )}
          </p>
          <div
            id="displayInline"
            style={{
              marginBottom: "5px",
              flexWrap: "wrap",
              marginLeft: "-5px",
              minWidth: "495px",
            }}
          >
            {tagsToFilter.map((tag, index) => {
              return (
                <div
                  onClick={() => {
                    handleTags(tag)
                  }}
                  key={index}
                  id="tagColor"
                  className="tag"
                  style={{
                    width: "fit-content",
                    marginRight: "5px",
                    paddingRight: "10px",
                    backgroundColor: tag[1],
                    fontSize: "14px",
                    height: "25px",
                    cursor: "pointer",
                  }}
                >
                  {tag[0]}
                </div>
              )
            })}
          </div>
          <p style={{ fontWeight: "300", textAlign: "left" }}>
            {i18n.t("Tags seleccionados")}:{" "}
          </p>
          <div
            id="displayInline"
            style={{
              marginBottom: "5px",
              flexWrap: "wrap",
              marginLeft: "-5px",
              minWidth: "495px",
            }}
          >
            {selectedTags.map((tag, index) => {
              return (
                <div
                  key={index}
                  id="tagColor"
                  className="tag"
                  style={{
                    width: "fit-content",
                    marginRight: "5px",
                    paddingRight: "5px",
                    backgroundColor: tag[1],
                    fontSize: "14px",
                    height: "25px",
                    cursor: "pointer",
                  }}
                >
                  {tag[0]}
                  <button
                    style={{
                      color: "white",
                      paddingLeft: "8px",
                      marginTop: "-2px",
                    }}
                    onClick={e => deleteTag(e, tag)}
                  >
                    x
                  </button>
                </div>
              )
            })}
          </div>
          <div
            id="downloadButton"
            onClick={() => {
              saveTagsFilter()
            }}
            style={{
              justifyContent: "center",
              cursor: "pointer",
              display: "flex",
              position: "relative",
              textAlign: "center",
              width: "164px",
              height: "48px",
              paddingTop: "12px",
              fontSize: "16px",
              fontWeight: "500",
              marginTop: "7px",
            }}
          >
            {i18n.t("Guardar")}
          </div>
        </div>
      </div>
    </Modal>
  )
}

ModalFilterByTag.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}
export default ModalFilterByTag
