import React, { useState } from "react"
import { Modal } from "@mui/material"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import paquitoerror from "../../assets/images/paco/paquitoError.png"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "430px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalExcel = ({ open, setOpen, setNotExcel, frase }) => {
  // Configuración del modal para añadir contactos
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  const onClose = () => {
    setNotExcel(false)
    setOpen(false)
  }
  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle} className={classes.paper}>
        <div id="centerPaco">
          <img
            src={paquitoerror}
            style={{ width: "182px", height: "286px", marginTop: "-100px" }}
          ></img>
        </div>
        <p
          style={{
            fontSize: "16px",
            fontWeight: "600",
            textAlign: "center",
            fontFamily: "Helvetica",
            marginTop: "30px",
          }}
        >
          Algo no va bien...
        </p>

        <p
          style={{
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "12px",
            lineHeight: "16px",
            textAlign: "center",
            fontFamily: "Helvetica",
          }}
        >
          Algun documento que estás intentando importar no se ha
          <br /> podido procesar. Recuerda que por ahora solo puedes <br />
          importar archivos en {frase ? " excel" : " .bc3"}, los otros formatos
          no seran procesados por el momento.
        </p>
        <div id="centerButton">
          <button className="AddContact" onClick={onClose}>
            De acuerdo
          </button>
        </div>
      </div>
    </Modal>
  )
}

ModalExcel.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setNotExcel: PropTypes.func.isRequired,
  frase: PropTypes.bool,
}
export default withRouter(ModalExcel)
