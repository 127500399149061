import React, { useContext } from "react"
import { Modal, Box, Grid, IconButton, CircularProgress } from "@mui/material"
import { ActionModal } from "./StylesAndUtils/CustomStyles"
import PropTypes from "prop-types"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import deletePng from "assets/images/budgeting/deleteDoc.png"
import i18n from "i18n"
import FileContext from "context/file/fileContext"
const ModalEliminar = ({
  openEliminate,
  setOpenEliminate,
  setSelectedDocument,
  selectedDocument,
}) => {
  const { deleteFile } = useContext(FileContext)
  const [loading, setLoading] = React.useState(false)

  const handleEliminar = async () => {
    setLoading(true)
    // for the selectedDocumentsPopUp
    selectedDocument.forEach(async file => {
      if (file.id) {
        await deleteFile(file.id)
      } else if (file.fileId) {
        await deleteFile(file.fileId)
      }
    })
    setSelectedDocument([])
    setLoading(false)
    setOpenEliminate(false)
    return
  }

  return (
    <Modal open={openEliminate} onClose={() => null}>
      <Box
        component="form"
        sx={ActionModal}
        style={{ width: "630px", height: "430px" }}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
        >
          <Grid
            item
            sx={{
              alignSelf: "flex-end",
            }}
          >
            <IconButton onClick={() => setOpenEliminate(false)}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Box>
              <img
                style={{ marginLeft: "15px" }}
                src={deletePng}
                alt="PacoSend"
                width="90%"
                height="auto"
              />
            </Box>
          </Grid>
          <Grid item>
            <p
              style={{
                fontFamily: "Helvetica",
                fontWeight: "600",
                fontSize: "16px",
                color: "#414141",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              {selectedDocument.length > 1
                ? i18n.t(
                    "¿Está seguro que desea eliminar los archivos seleccionados?",
                  )
                : "¿Está seguro que desea eliminar el archivo " +
                  selectedDocument[0].filename +
                  "?"}
            </p>
          </Grid>
          <Grid item>
            <p
              style={{
                fontFamily: "Helvetica",
                fontWeight: "300",
                fontSize: "16px",
                color: "#414141",
                textAlign: "center",
                marginTop: "-10px",
              }}
            >
              {i18n.t("Esta acción no se puede deshacer")}
            </p>
          </Grid>
          <Grid item sx={{ marginY: "0rem", marginBottom: "1rem" }}>
            {!loading ? (
              <button
                onClick={handleEliminar}
                style={{ color: "white", backgroundColor: "#BF2929" }}
                className="AddContact"
              >
                {i18n.t("Eliminar")}
              </button>
            ) : (
              <CircularProgress size={24} />
            )}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}

ModalEliminar.propTypes = {
  openEliminate: PropTypes.bool.isRequired,
  setOpenEliminate: PropTypes.func.isRequired,
  selectedDocument: PropTypes.array.isRequired,
  setSelectedDocument: PropTypes.func.isRequired,
}

export default ModalEliminar
