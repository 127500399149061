import React, { useContext, useEffect } from "react"
import { PoppinsSemiBold, HelveticaBold } from "common/typographies"
import AuthContext from "context/auth/authContext"
import { HelveticaInput2 } from "common/inputs"
import { useTagsInput } from "hooks/useTagsInput"
import i18n from "i18n"
import PropTypes from "prop-types"

const AuthorInfo = ({ setActaValues, actaValues }) => {
  const { user } = useContext(AuthContext)
  const {
    onKeyDown,
    onKeyUp,
    deleteTag,
    onChange,
    input,
    tags,
    onBlur,
    setInitialTags,
  } = useTagsInput({})

  useEffect(() => {
    setActaValues(prevState => ({
      ...prevState,
      themes: tags,
    }))
  }, [tags.length])

  useEffect(() => {
    if (actaValues.themes.length > 0) {
      setInitialTags(actaValues.themes)
    }
  }, [actaValues.themes])

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: "#F8F8F8",
          marginTop: "10px",
          marginBottom: "16px",
          marginLeft: "0px",
          borderRadius: "6px",
        }}
      >
        <div
          className="inline"
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
            paddingBottom: "5px",
          }}
        >
          <div
            style={{
              backgroundColor: "#F8F8F8",
              width: "40%",
              minWidth: "180px",
              maxHeight: "75px",
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px",
                paddingTop: "8px",
                paddingBottom: "5px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  minWidth: "200px",
                }}
              >
                {i18n.t("Autor")}
              </PoppinsSemiBold>
            </div>
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                marginLeft: "20px",
                color: "#414141",
              }}
            >
              {actaValues.author
                ? actaValues.author
                : user
                  ? user["custom:nombre_apellido"]
                    ? user["custom:nombre_apellido"]
                    : "-"
                  : "-"}
            </HelveticaBold>
          </div>

          <div
            style={{
              backgroundColor: "#F8F8F8",
              width: "50%",
              minWidth: "180px",
              minHeight: "75px",
              borderTopRightRadius: "6px",
              borderBottomRightRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px",
                paddingTop: "8px",
                paddingBottom: "0px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  minWidth: "200px",
                  marginBottom: "14px",
                }}
              >
                {i18n.t("Tematicas")}
              </PoppinsSemiBold>
            </div>
            <div
              className="container2"
              style={{
                padding: "0px",
                background: "white",
                minHeight: "35px",
                marginLeft: "20px",
              }}
            >
              {tags.length > 0 &&
                tags.map((tag, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <div
                    id={tag[1]}
                    className="tag"
                    style={{
                      marginTop: "5.5px",
                      marginBottom: "5.5px",
                      marginLeft: "5px",
                      marginRight: "0px",
                    }}
                  >
                    {tag[0]}
                    <button onClick={e => deleteTag(e, index)}>x</button>
                  </div>
                ))}
              <HelveticaInput2
                className="importantMargin"
                style={{
                  height: "35px",
                  width: "345px",
                  marginLeft: "5px",
                  paddingLeft: "5px",
                  padding: "0px",
                  margin: "0px",
                  border: "none",
                  outline: "none",
                  background: "none",
                  fontSize: "14px",
                  color: "#414141",
                }}
                name="etiqueta"
                value={input}
                onBlur={onBlur}
                placeholder={i18n.t(
                  "Introduce la temática; Inicio de la obra, Visita de obra ordinaria...",
                )}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

AuthorInfo.propTypes = {
  setActaValues: PropTypes.func.isRequired,
  actaValues: PropTypes.object.isRequired,
}

export default AuthorInfo
