import cypeLogo from "assets/customIcons/cype_logo.svg"
import itecLogo from "assets/customIcons/itec_logo.svg"
import preocLogo from "assets/customIcons/preocVert.svg"
export const ListBank = {
  cype: {
    uri: "cype",
    name: "Cype",
    logo: cypeLogo,
    categorias: [
      {
        uri: "rehabilitacion",
        name: "Rehabilitación",
        description:
          "Banco de precios de referencia para la rehabilitación de edificios",
      },
      {
        uri: "obra-nueva",
        name: "Obra nueva",
      },
      {
        uri: "espacios-urbanos",
        name: "Espacios urbanos",
      },
    ],
  },
  bedec: {
    uri: "bedec",
    logo: itecLogo,
    name: "Bedec",
    categorias: [
      {
        uri: "partidas-obra-conjuntos",
        name: "PARTIDAS DE OBRA Y CONJUNTOS",
        width: "260px",
      },
      {
        uri: "maquinaria",
        name: "MAQUINARIA",
      },
      {
        uri: "materiales-compuestos",
        name: "MATERIALES Y COMPUESTOS",
        width: "220px",
      },
      {
        uri: "mano-de-obra",
        name: "MANO DE OBRA",
      },
    ],
  },
  preoc: {
    uri: "preoc",
    logo: preocLogo,
    name: "Preoc",
    categorias: [
      {
        uri: "precios-unitarios",
        name: "PRECIOS UNITARIOS",
      },
      {
        uri: "precios-auxiliares",
        name: "PRECIOS AUXILIARES",
      },
      {
        uri: "precios-descompuestos",
        name: "PRECIOS DESCOMPUESTOS",
        width: "220px",
      },
    ],
  },
}
