import React, { useState } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import i18n from "i18n"
import { HelveticaBold, HelveticaLight } from "common/typographies"
import { YellowButton } from "common/buttons"
import ThumbUpIcon from "@mui/icons-material/ThumbUp"
import ThumbDownIcon from "@mui/icons-material/ThumbDown"
import RefreshIcon from "@mui/icons-material/Refresh"
import { WhiteButton } from "common/containers"
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "495px",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalRegenerate = ({ open, setOpen, handleGenerateIAPart, openai }) => {
  const [modalStyle] = useState(getModalStyle)
  const [model, setModel] = useState("CURIE")
  const classes = useStyles()
  const [isThumbedUp, setIsThumbedUp] = useState(false)
  const [isThumbedDown, setIsThumbedDown] = useState(false)
  const handleVote = async vote => {
    if (vote === "up") {
      if (!isThumbedUp) {
        await votePart("up")
        setIsThumbedUp(true)
        setIsThumbedDown(false)
      }
    } else {
      if (!isThumbedDown) {
        await votePart("down")
        setIsThumbedDown(true)
        setIsThumbedUp(false)
      }
    }
  }
  const [loadingVote, setLoadingVote] = useState(false)
  const votePart = async vote => {
    try {
      setLoadingVote(true)
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }

      const response = await API.post(
        SERVICE_INTEGRATION,
        `/openai/feedback-part`,
        {
          headers: headers,
          body: {
            part: openai.currentPart,
            model: model,
            feedback: vote == "up" ? "ACCEPTED" : "DECLINED",
          },
        },
      )
      setLoadingVote(false)
    } catch (error) {
      setLoadingVote(false)
    }
  }
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div id="totalDiv">
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setOpen(false)
            }}
            style={{
              marginTop: "0px",
              paddingLeft: "6px",
              marginRight: "-10px",
            }}
          ></CloseIcon>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              paddingLeft: "60px",
              paddingRight: "60px",
            }}
          >
            <HelveticaBold
              style={{ fontSize: "24px", color: "#414141", marginTop: "20PX" }}
            >
              {i18n.t("Descripción automática")}
            </HelveticaBold>
            <div
              className="inline"
              style={{ marginTop: "-20px", marginBottom: "10px" }}
            >
              <RefreshIcon
                onClick={() => {
                  handleGenerateIAPart()
                  setOpen(false)
                }}
                className="refresh"
                style={{ marginTop: "13px", cursor: "pointer", width: "23px" }}
              />
              <ThumbUpIcon
                onClick={() => {
                  handleVote("up")
                }}
                className="thumbUp"
                style={{
                  marginTop: "13px",
                  marginLeft: "8px",
                  cursor: isThumbedUp ? "default" : "pointer",
                  width: "20px",
                  opacity: isThumbedUp && "1",
                  color: isThumbedUp && "#00CC00",
                }}
              />
              <ThumbDownIcon
                onClick={() => {
                  handleVote("down")
                }}
                className="thumbDown"
                style={{
                  marginTop: "13px",
                  marginLeft: "8px",
                  cursor: isThumbedDown ? "default" : "pointer",
                  width: "20px",
                  opacity: isThumbedDown && "1",
                  color: isThumbedDown && "#CC0000",
                }}
              />
            </div>
            <HelveticaLight
              style={{ color: "#B7B7B7", fontSize: "14PX", fontWeight: "400" }}
            >
              {i18n.t(
                " Comunicanos mediante los iconos superiores si has obtenido una descripción a tu medida, si no regenerala.",
              )}
            </HelveticaLight>
            <div className="inline" style={{ marginBottom: "0PX" }}>
              <WhiteButton
                onClick={() => {
                  setOpen(false)
                }}
                style={{
                  width: "100PX",
                  height: "34px",
                  borderRadius: "6px",
                  border: "1px solid #FFD52B",
                  boxShadow: "none",
                  marginRight: "10px",
                }}
              >
                {i18n.t("Cancelar")}
              </WhiteButton>
              <YellowButton
                onClick={() => {
                  setOpen(false)
                }}
                style={{ width: "100PX", height: "34PX" }}
              >
                {i18n.t("Aceptar")}
              </YellowButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ModalRegenerate.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleGenerateIAPart: PropTypes.func.isRequired,
  openai: PropTypes.any.isRequired,
}

export default ModalRegenerate
