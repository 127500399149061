import React from "react"
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  CardContent,
} from "@mui/material"
import PropTypes from "prop-types"
import { ListaColores } from "components/Documents/StylesAndUtils/Colors"
import i18n from "i18n"
function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2")
}

function BasicTable({ rows }) {
  const tiposPartidas = {
    0: "Sin clasificar",
    1: "Mano de obra",
    2: "Maquinaria y medios auxiliares",
    3: "Materiales",
    4: "Componentes adicionales de residuo",
    5: "Clasificación de residuo",
    6: "Otros",
    7: "Otros",
  }
  const computedTotal = row => {
    const quantity =
      row.quantityComputed && row.quantityComputed > 0
        ? row.quantityComputed
        : row.quantity
    const price = row.price ?? 0

    return roundToTwo(quantity * price)
  }

  return (
    <TableContainer
      sx={{
        padding: "1rem",
        overflowY: "hidden",
        backgroundColor: "#FBFBFE",
      }}
    >
      <Table
        sx={{ minWidth: 650, marginBottom: "-16px", marginTop: "-16px" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow sx={{ border: "none" }}>
            <TableCell
              align="center"
              sx={{ border: "none", width: "130px" }}
              style={{
                fontWeight: "600",
                fontFamily: "Helvetica",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              {i18n.t("Tipo")}
            </TableCell>
            <TableCell
              align="center"
              sx={{ border: "none", width: "150px" }}
              style={{
                fontWeight: "600",
                fontFamily: "Helvetica",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              {i18n.t("Código")}
            </TableCell>
            <TableCell
              align="center"
              sx={{ border: "none" }}
              style={{
                fontWeight: "600",
                fontFamily: "Helvetica",
                fontSize: "14px",
                width: "500px",
                textAlign: "left",
              }}
            >
              {i18n.t("Descripción")}
            </TableCell>
            <TableCell
              align="center"
              sx={{ border: "none" }}
              style={{
                fontWeight: "600",
                fontFamily: "Helvetica",
                fontSize: "14px",
                width: "100px",
                textAlign: "left",
              }}
            >
              {i18n.t("Precio")}
            </TableCell>
            <TableCell
              align="center"
              sx={{ border: "none" }}
              style={{
                fontWeight: "600",
                fontFamily: "Helvetica",
                fontSize: "14px",
                width: "100px",
                textAlign: "left",
              }}
            >
              {i18n.t("Cantidad")}
            </TableCell>
            <TableCell
              align="center"
              sx={{ border: "none" }}
              style={{
                fontWeight: "600",
                fontFamily: "Helvetica",
                fontSize: "14px",
                width: "100px",
                textAlign: "left",
              }}
            >
              {i18n.t("Importe")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ border: "none" }}>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{
                "&:last-child td, &:last-child th": { border: "none" },
                border: "none",
              }}
            >
              <TableCell align="left" sx={{ border: "none" }}>
                <Chip
                  style={{ minWidth: "120px", fontSize: "12px" }}
                  label={tiposPartidas[row.type]}
                  variant="outlined"
                  sx={{
                    margin: "0px",
                    borderRadius: "4px",
                    backgroundColor: `${
                      ListaColores[row.type > 7 ? 6 : row.type].bg
                    }`,
                    color: `${ListaColores[row.type > 7 ? 6 : row.type].color}`,
                    border:
                      "1px solid " +
                      `${ListaColores[row.type > 7 ? 6 : row.type].color}`,
                  }}
                />
              </TableCell>
              <TableCell
                align="left"
                sx={{ border: "none" }}
                style={{ fontWeight: "500", textTransform: "uppercase" }}
              >{`${row.type}-${index}`}</TableCell>
              <TableCell
                align="left"
                sx={{ border: "none" }}
                style={{ fontWeight: "300" }}
              >
                {row.name}
              </TableCell>
              <TableCell
                align="left"
                sx={{ border: "none" }}
                style={{ fontWeight: "300" }}
              >
                {roundToTwo(row.price)}
                <span>&#8364;</span>/{row.unity}
              </TableCell>
              <TableCell
                align="left"
                sx={{ border: "none" }}
                style={{ fontWeight: "300" }}
              >
                {roundToTwo(
                  row.quantityComputed && row.quantityComputed > 0
                    ? row.quantityComputed
                    : row.quantity,
                )}{" "}
                {row.unidad}
              </TableCell>
              <TableCell
                align="left"
                sx={{ border: "none" }}
                style={{ fontWeight: "300" }}
              >
                {computedTotal(row)} <span>&#8364;</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

BasicTable.propTypes = {
  rows: PropTypes.array.isRequired,
}

const DecompositionTable = ({ part }) => {
  return (
    <CardContent sx={{ padding: "0px", marginTop: "-16PX" }}>
      <div style={{ width: "100%" }}>
        <BasicTable rows={part.decomposition ?? []} />
      </div>
    </CardContent>
  )
}

DecompositionTable.propTypes = {
  part: PropTypes.array.isRequired,
}

export default DecompositionTable
