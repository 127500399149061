import MetaTags from "react-meta-tags"
import React from "react"
import { Container } from "reactstrap"
import PartGeneratorContainer from "components/demo/partGenerator"

const PartGenerator = () => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>Generador de partidas</title>
      </MetaTags>
      <Container fluid>
        <PartGeneratorContainer />
      </Container>
    </div>
  )
}

export default PartGenerator
