import ExportSideBar from "./ExportSideBar"

class CertHistoryContextState {
  constructor(
    setCertHistory = () => {},
    certHistoryTree = {},
    loading = false,
    exportSideBar = new ExportSideBar(),
    updating = false,
    openConfirmDelete = false,
    setOpenConfirmDelete = () => {},
    certToDelete = null,
    setCetToDelete = () => {},
    deleteCert = () => {},
  ) {
    this.setCertHistory = setCertHistory
    this.certHistoryTree = certHistoryTree
    this.loading = loading
    this.exportSideBar = exportSideBar
    this.updating = updating
    this.openConfirmDelete = openConfirmDelete
    this.setOpenConfirmDelete = setOpenConfirmDelete
    this.certToDelete = certToDelete
    this.setCetToDelete = setCetToDelete
    this.deleteCert = deleteCert
  }
}

export default CertHistoryContextState
