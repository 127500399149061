import { LOADING_BANKPRICES, GET_BANK_PRICES, LOADING_SUBCAPS } from "../types"
import {
  ON_UPLOAD_FILE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_SUCCESS,
  CLEAN_IMPORT_PROGRESS,
  ADD_PARTIDA_TO_MOVE,
  ADD_CAPITULO_MOVE,
  DEL_CAPITULO,
} from "./types"
const BankPricesReducer = (state, action) => {
  switch (action.type) {
    case LOADING_BANKPRICES:
      return {
        ...state,
        loading: true,
        myPricesBank: action.payload.myPricesBank,
      }
    case "UPDATE_FILE_UPLOADED":
      return {
        ...state,
        fileUploaded: action.payload,
      }
    case CLEAN_IMPORT_PROGRESS:
      return {
        ...state,
        importProgress: {
          uploading: false,
          uploadSuccess: null,
          uploadError: null,
        },
      }
    case ON_UPLOAD_FILE:
      return {
        ...state,
        importProgress: {
          uploading: true,
          uploadSuccess: null,
          uploadError: null,
        },
      }
    case UPLOAD_FILE_ERROR:
      return {
        ...state,
        importProgress: {
          uploading: false,
          uploadSuccess: false,
          uploadError: true,
        },
      }
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        importProgress: {
          uploading: false,
          uploadSuccess: true,
          uploadError: false,
        },
      }

    case GET_BANK_PRICES:
      state.myPricesBank.subcapitulos = action.payload
    case "GET_BANK_PRICES_SEARCH":
      state.myPricesBank.subcapitulos = []
      for (let capitulo of action.payload) {
        state.myPricesBank.subcapitulos.push(capitulo)
      }
      return {
        ...state,
        loading: false,
      }
      return {
        ...state,
        loading: false,
      }
    case "LOADING_BANKPRICES_2":
      return {
        ...state,
        loading: true,
        myNonSpecifiedPricesBank: action.payload.myPricesBank,
      }
    case "GET_BANK_PRICES_2":
      for (let capitulo of action.payload) {
        state.myNonSpecifiedPricesBank.subcapitulos.push(capitulo)
      }
      return {
        ...state,
        loading: false,
      }

    case LOADING_SUBCAPS:
      return {
        ...state,
        loadingCaps: true,
      }
    case "LOADING_SUBCAPS_SUCCESS":
      return {
        ...state,
        loadingCaps: false,
      }
    case "ADD_CAPITULO":
      state.myPricesBank.addCapitulo(
        action.payload.parentId,
        action.payload.res,
        action.payload.indexCapitulo,
      )
      return {
        ...state,
      }
    case ADD_CAPITULO_MOVE:
      state.myPricesBank.addCapituloMove(
        action.payload.parentId,
        action.payload.res,
        action.payload.indexCapitulo,
      )
      return {
        ...state,
      }
    case "UPDATE_CAPITULO":
      state.myPricesBank.updateCapitulo(
        action.payload.newData,
        action.payload.capituloId,
      )
      return {
        ...state,
        updating: false,
      }
    case "SELECCIONAR_AGRUPAR":
      return {
        ...state,
        selectedCategories: action.payload.selectedBudget,
      }
    case DEL_CAPITULO:
      state.myPricesBank.remove(action.payload)
      return {
        ...state,
      }
    case "CHECK_CAPITULOS":
      state.myPricesBank.checkCapitulo(
        action.payload.capituloId,
        action.payload.value,
      )
      return {
        ...state,
      }
    case "UNCHECK_ALL":
      state.myPricesBank.checkCapitulo("root", false)
      return {
        ...state,
        categoriesChecked: [],
        partsChecked: [],
      }
    case "UPDATE_CHAPTERS_LIST":
      return {
        ...state,
        categoriesChecked: action.payload.list,
      }
    case "ADD_PARTIDA":
      state.myPricesBank.addPartida(
        action.payload.chapterId,
        action.payload.partidaNueva,
        action.payload.index,
      )
      return {
        ...state,
      }
    case ADD_PARTIDA_TO_MOVE:
      state.myPricesBank.addPartidaToMove(
        action.payload.chapterId,
        action.payload.partidaNueva,
        action.payload.index,
      )
      return {
        ...state,
      }
    case "ADD_MULTIPLE_PARTS":
      state.myPricesBank.addMultipleParts(
        action.payload.chapterId,
        action.payload.partidas,
      )
      return {
        ...state,
      }
    case "ADD_MULTIPLE_CHAPTERS":
      state.myPricesBank.addMultipleChapters(
        action.payload.chapterId,
        action.payload.capitulos,
      )
      return {
        ...state,
      }
    case "DEL_PARTIDA":
      state.myPricesBank.removePartida(
        action.payload.capituloId,
        action.payload.partidaId,
      )
      return {
        ...state,
      }
    case "UPDATE_PARTS_LIST":
      return {
        ...state,
        partsChecked: action.payload.list,
      }
    case "CHECK_PARTIDA":
      state.myPricesBank.checkPartida(
        action.payload.capituloId,
        action.payload.partidaId,
      )
      return {
        ...state,
      }
    case "ERROR_CAPITULO":
      return {
        ...state,
        selectedPartGroup: [],
        errorAgrupar: true,
      }
    case "SET_GROUP":
      return {
        ...state,
        selectedPartGroup: action.payload.selectedParts,
        errorAgrupar: false,
      }
    case "UPDATE_PARTIDA":
      state.myPricesBank.updatePartida(
        action.payload.newData,
        action.payload.capituloId,
        action.payload.final,
      )
      return {
        ...state,
      }
    case "SET_SELECTED":
      return {
        ...state,
        selectedCategories2: action.payload.selectedBudget,
      }
    case "ORDER_PARTS":
      if (action.payload.loadingOrder == false) {
        state.myPricesBank.updateCapitulo(
          action.payload.capitulo,
          action.payload.capitulo.chapterId,
        )
      }
      return {
        ...state,
        loadingOrder: action.payload.loadingOrder,
      }
    default:
      return state
  }
}

export default BankPricesReducer
