import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { CircularProgress, Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import i18n from "i18n"
import ProposalContext from "context/proposal/proposalContext"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "580px",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
    height: "420px",
  },
}))

const ModalAcceptProposal = ({
  open,
  setOpen,
  proposalToAccept,
  setProposalToAccept,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { acceptProposal } = useContext(ProposalContext)
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState([])
  const guardarPres = async e => {
    e.preventDefault()
    setLoading(true)
    let res = await acceptProposal(
      proposalToAccept[0].proposalInfo.proposalId,
      proposalToAccept[0].groupId,
      proposalToAccept[0].proposalInfo.accountId,
      proposalToAccept[0].providerInfo.email,
      proposalToAccept[0].proposalInfo.projectId,
    )
    setResponse(res)
    setLoading(false)
  }
  useEffect(() => {
    setTimeout(() => {
      if (response.length > 0) {
        setOpen(false)
        setResponse([])
      }
    }, 2000)
  }, [response])

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <div id="totalDiv">
          <div style={modalStyle} className={classes.paper}>
            <CloseIcon
              id="cerrar"
              onClick={() => {
                setOpen(false)
              }}
              style={{
                marginTop: "0px",
                paddingLeft: "6px",
                marginRight: "-10px",
              }}
            ></CloseIcon>

            <div
              id="DivTitleEliminar"
              style={{
                marginTop: "85px",
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p id="centradoBudget">
                {proposalToAccept.length > 0
                  ? i18n.t(
                      "¡Estás apunto de aceptar el presupuesto de " +
                        proposalToAccept[0].providerInfo.orgName +
                        "!",
                    )
                  : i18n.t("¡Estás apunto de aceptar el presupuesto de " + "!")}
              </p>
            </div>
            <p style={{ marginTop: "10px" }} className="lightPoppins">
              {i18n.t("Al aceptar el presupuesto se enviará ")}
              {i18n.t(
                "un email automatico aceptando la oferta a tu contacto personal",
              )}
            </p>
            {loading ? (
              <div style={{ marginTop: "60px" }} id="centerButton">
                <CircularProgress size={24} />
              </div>
            ) : response.length == 0 ? (
              <div style={{ marginTop: "60px" }} id="centerButton">
                <button
                  style={{ width: "180px" }}
                  type="button"
                  className="AddContact"
                  onClick={guardarPres}
                >
                  {i18n.t("Aceptar Propuesta")}
                </button>
              </div>
            ) : (
              <div style={{ marginTop: "60px" }} id="centerButton">
                <CheckCircleOutlineIcon
                  size={24}
                  style={{ color: "#00a650" }}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
ModalAcceptProposal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  proposalToAccept: PropTypes.array.isRequired,
  setProposalToAccept: PropTypes.func.isRequired,
}

export default ModalAcceptProposal
