import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { CircularProgress, Grid } from "@material-ui/core"
import i18n from "i18n"
import {
  ProvidersTitle,
  ProvidersSubTitle,
  PoppinsWhite,
} from "common/typographies"
import ProvidersTimeLine from "./ProvidersTimeline"
import { BlueButton } from "common/containers"
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined"
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined"
import ProposalContext from "context/proposal/proposalContext"
import ModalFichaProveedor from "../modals/ModalFichaProveedor"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"

const GroupStatuses = ({ proposal }) => {
  const { sendReminder } = useContext(ProposalContext)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = event => {
    setOpen(false)
  }

  const sendRecordatorio = async e => {
    e.preventDefault()
    setLoading(true)
    await sendReminder(
      proposal.proposalId,
      proposal.accountId,
      proposal.providerInfo.email,
      proposal.projectId,
    )
    setLoading(false)
    handleClick()
  }
  const [openFicha, setOpenFicha] = useState(false)

  return (
    <Grid
      container
      style={{
        backgroundColor: "white",
        height: "100px",
        width: "98%",
        marginTop: "-20px",
      }}
    >
      <Grid
        item
        xs={2}
        style={{ paddingLeft: "12px", cursor: "pointer" }}
        onClick={() => {
          setOpenFicha(true)
        }}
      >
        <ProvidersTitle style={{ marginBottom: "0px", paddingTop: "25px" }}>
          {proposal.providerInfo.orgName}
        </ProvidersTitle>
        <ProvidersSubTitle>
          {proposal.providerInfo.completeName}
        </ProvidersSubTitle>
      </Grid>
      <Grid
        item
        xs={7}
        onClick={() => {
          setOpenFicha(true)
        }}
        style={{ cursor: "pointer" }}
      >
        <ProvidersTimeLine proposal={proposal} />
      </Grid>
      <Grid item xs={3}>
        <div
          className="inline"
          style={{
            marginTop: "40px",
            position: "relative",
            justifyContent: "right",
            right: "3.5%",
          }}
        >
          <BlueButton className="inline" style={{ marginRight: "7px" }}>
            <UploadFileOutlinedIcon
              style={{
                color: "white",
                width: "16px",
                marginTop: "-2px",
                marginLeft: "7px",
                marginRight: "5px",
              }}
            />
            <PoppinsWhite>{i18n.t("Importar Excel")}</PoppinsWhite>
          </BlueButton>
          {proposal.proposalStatus != "ACCEPTED" ? (
            <BlueButton
              className="inline"
              style={{
                marginRight: "7px",
                cursor: loading ? "default" : "pointer",
              }}
              onClick={e => {
                loading === false ? sendRecordatorio(e) : null
              }}
            >
              {loading ? (
                <div
                  style={{
                    paddingTop: "3px",
                    marginLeft: "7PX",
                    marginRight: "7PX",
                  }}
                >
                  <CircularProgress style={{ color: "white" }} size={15} />
                </div>
              ) : (
                <NotificationsActiveOutlinedIcon
                  style={{
                    color: "white",
                    width: "16px",
                    marginTop: "-2px",
                    marginLeft: "7px",
                    marginRight: "7px",
                  }}
                />
              )}
              <PoppinsWhite>{i18n.t("Recordatorio")}</PoppinsWhite>
            </BlueButton>
          ) : (
            <BlueButton
              className="inline"
              style={{ marginRight: "7px", cursor: "default", opacity: "0.5" }}
            >
              <NotificationsActiveOutlinedIcon
                style={{
                  color: "white",
                  width: "16px",
                  marginTop: "-2px",
                  marginLeft: "7px",
                  marginRight: "7px",
                }}
              />
              <PoppinsWhite>{i18n.t("Recordatorio")}</PoppinsWhite>
            </BlueButton>
          )}
        </div>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            style={{ marginTop: "-12px", marginLeft: "14px" }}
            sx={{ width: "350px" }}
          >
            Recordatorio enviado correctamente
          </Alert>
        </Snackbar>
      </Grid>
      <ModalFichaProveedor
        open={openFicha}
        setOpen={setOpenFicha}
        providerInfo={proposal.providerInfo}
      />
    </Grid>
  )
}

GroupStatuses.propTypes = {
  proposal: PropTypes.object,
}

export default GroupStatuses
