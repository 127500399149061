import React, { useContext, useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { Document, Page, pdfjs } from "react-pdf"
import FacturasContext from "context/facturas/facturasContext"
import { PoppinsNormal } from "common/typographies"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFPreview = ({ invoiceId, invoiceService }) => {
  const getPDFURL = invoiceId => {
    const download = invoiceService.invoicesPdf.find(
      invoicePdf => invoicePdf.invoiceId === invoiceId,
    )
    return download ? download.url : null
  }
  const [numPages, setNumPages] = useState(null)
  const [notRender, setNotRender] = useState(true)
  const componentRef = useRef()
  const [invoicePdf, setInvoicePdf] = useState(getPDFURL(invoiceId))
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  useEffect(() => {
    setInvoicePdf(getPDFURL(invoiceId))
  }, [])
  useEffect(() => {
    if (invoicePdf) {
      setNotRender(false)
    }
  }, [invoicePdf])
  useEffect(() => {
    setInvoicePdf(getPDFURL(invoiceId))
  }, [invoiceService.invoicesPdf])
  return (
    <>
      {invoiceService.loadingPDF === true || notRender ? (
        <>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-100px",
              marginTop: "90px",
            }}
          >
            <div className="loader">
              <div className="ball"></div>
              <div className="ball"></div>
              <div className="ball"></div>
            </div>
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-30px",
            }}
          >
            <PoppinsNormal>{i18n.t("Cargando PDF...")}</PoppinsNormal>
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-30px",
              marginTop: "-15px",
            }}
          >
            <PoppinsNormal style={{ fontWeight: "400" }}>
              {i18n.t("Esta acción puede tardar unos segundos")}
            </PoppinsNormal>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              height: "95.7%",
              minHeight: "85vh",
              maxHeight: "90vh",
              overflowY: "auto",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#D9D9D9",
              borderBottom: "1px solid #EBEBEB",
            }}
          >
            <Document
              ref={componentRef}
              file={invoicePdf}
              onLoadError={console.error}
              style={{ overflowY: "auto" }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {[...Array(numPages)].map((elementInArray, index) => (
                <Page key={index} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </>
      )}
    </>
  )
}
PDFPreview.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  invoiceService: PropTypes.any,
}

export default PDFPreview
