/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const createPartGenerated = /* GraphQL */ `
  mutation CreatePartGenerated(
    $input: CreatePartGeneratedInput!
    $condition: ModelPartGeneratedConditionInput
  ) {
    createPartGenerated(input: $input, condition: $condition) {
      id
      name
      description
      price
      type
      unity
      decomposition {
        name
        quantity
        unity
        price
        total
        type
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const updatePartGenerated = /* GraphQL */ `
  mutation UpdatePartGenerated(
    $input: UpdatePartGeneratedInput!
    $condition: ModelPartGeneratedConditionInput
  ) {
    updatePartGenerated(input: $input, condition: $condition) {
      id
      name
      description
      price
      type
      unity
      decomposition {
        name
        quantity
        unity
        price
        total
        type
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const deletePartGenerated = /* GraphQL */ `
  mutation DeletePartGenerated(
    $input: DeletePartGeneratedInput!
    $condition: ModelPartGeneratedConditionInput
  ) {
    deletePartGenerated(input: $input, condition: $condition) {
      id
      name
      description
      price
      type
      unity
      decomposition {
        name
        quantity
        unity
        price
        total
        type
      }
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const createImportedProjects = /* GraphQL */ `
  mutation CreateImportedProjects(
    $input: CreateImportedProjectsInput!
    $condition: ModelImportedProjectsConditionInput
  ) {
    createImportedProjects(input: $input, condition: $condition) {
      id
      projectId
      accountId
      parts
      chapters
      importStatus
      organizationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const updateImportedProjects = /* GraphQL */ `
  mutation UpdateImportedProjects(
    $input: UpdateImportedProjectsInput!
    $condition: ModelImportedProjectsConditionInput
  ) {
    updateImportedProjects(input: $input, condition: $condition) {
      id
      projectId
      accountId
      parts
      chapters
      importStatus
      organizationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const deleteImportedProjects = /* GraphQL */ `
  mutation DeleteImportedProjects(
    $input: DeleteImportedProjectsInput!
    $condition: ModelImportedProjectsConditionInput
  ) {
    deleteImportedProjects(input: $input, condition: $condition) {
      id
      projectId
      accountId
      parts
      chapters
      importStatus
      organizationId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const createImportProcess = /* GraphQL */ `
  mutation CreateImportProcess(
    $input: CreateImportProcessInput!
    $condition: ModelImportProcessConditionInput
  ) {
    createImportProcess(input: $input, condition: $condition) {
      id
      fileId
      orgId
      status
      error
      errorMessage
      createdAt
      updatedAt
      validation
      chapters
      parts
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const updateImportProcess = /* GraphQL */ `
  mutation UpdateImportProcess(
    $input: UpdateImportProcessInput!
    $condition: ModelImportProcessConditionInput
  ) {
    updateImportProcess(input: $input, condition: $condition) {
      id
      fileId
      orgId
      status
      error
      errorMessage
      createdAt
      updatedAt
      validation
      chapters
      parts
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const deleteImportProcess = /* GraphQL */ `
  mutation DeleteImportProcess(
    $input: DeleteImportProcessInput!
    $condition: ModelImportProcessConditionInput
  ) {
    deleteImportProcess(input: $input, condition: $condition) {
      id
      fileId
      orgId
      status
      error
      errorMessage
      createdAt
      updatedAt
      validation
      chapters
      parts
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const createTribbooAppComments = /* GraphQL */ `
  mutation CreateTribbooAppComments(
    $input: CreateTribbooAppCommentsInput!
    $condition: ModelTribbooAppCommentsConditionInput
  ) {
    createTribbooAppComments(input: $input, condition: $condition) {
      id
      commentId
      conversationId
      author {
        accountId
        fullName
        profileImg
      }
      createdAt
      updatedAt
      content
      commentResponse {
        id
        commentId
        conversationId
        author {
          accountId
          fullName
          profileImg
        }
        createdAt
        updatedAt
        content
        commentResponse {
          id
          commentId
          conversationId
          createdAt
          updatedAt
          content
          edited
          _version
          _deleted
          _lastChangedAt
        }
        edited
        _version
        _deleted
        _lastChangedAt
      }
      edited
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const updateTribbooAppComments = /* GraphQL */ `
  mutation UpdateTribbooAppComments(
    $input: UpdateTribbooAppCommentsInput!
    $condition: ModelTribbooAppCommentsConditionInput
  ) {
    updateTribbooAppComments(input: $input, condition: $condition) {
      id
      commentId
      conversationId
      author {
        accountId
        fullName
        profileImg
      }
      createdAt
      updatedAt
      content
      commentResponse {
        id
        commentId
        conversationId
        author {
          accountId
          fullName
          profileImg
        }
        createdAt
        updatedAt
        content
        commentResponse {
          id
          commentId
          conversationId
          createdAt
          updatedAt
          content
          edited
          _version
          _deleted
          _lastChangedAt
        }
        edited
        _version
        _deleted
        _lastChangedAt
      }
      edited
      _version
      _deleted
      _lastChangedAt
    }
  }
`
export const deleteTribbooAppComments = /* GraphQL */ `
  mutation DeleteTribbooAppComments(
    $input: DeleteTribbooAppCommentsInput!
    $condition: ModelTribbooAppCommentsConditionInput
  ) {
    deleteTribbooAppComments(input: $input, condition: $condition) {
      id
      commentId
      conversationId
      author {
        accountId
        fullName
        profileImg
      }
      createdAt
      updatedAt
      content
      commentResponse {
        id
        commentId
        conversationId
        author {
          accountId
          fullName
          profileImg
        }
        createdAt
        updatedAt
        content
        commentResponse {
          id
          commentId
          conversationId
          createdAt
          updatedAt
          content
          edited
          _version
          _deleted
          _lastChangedAt
        }
        edited
        _version
        _deleted
        _lastChangedAt
      }
      edited
      _version
      _deleted
      _lastChangedAt
    }
  }
`
