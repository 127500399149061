import React, { useState, useContext } from "react"

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import i18n from "i18n"
import UploadBankPrice from "../modals/uploadBankPrice"
import WaitingModal from "../modals/WaitingModalBanco"
import AuthContext from "context/auth/authContext"
import ImportDatabaseSuccess from "../modals/ImportDatabaseSuccess"
import UploadExcelModal from "../modals/uploadExcel"
import ModalWaitingExcel from "pages/Dashboard/ModalWaitingExcel"
import BankPricesContext from "context/bankPrices/bankPricesContext"

const UserDatabaseOptions = () => {
  const [show, setShow] = useState(false)
  const [openBc3, setOpenBc3] = useState(false)
  const [openExcel, setOpenExcel] = useState(false)
  const [waitModal, setWaitModal] = useState(false)
  const [openModalForExcel, setOpenModalForExcel] = useState(false)
  const [openWaitModalForExcel, setOpenWaitModalForExcel] = useState(false)
  const [openModalImportSuccess, setOpenModalImportSuccess] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const { myPricesBank } = useContext(BankPricesContext)
  const { user } = useContext(AuthContext)
  return (
    <>
      <Dropdown
        isOpen={show}
        toggle={() => setShow(!show)}
        className="d-inline-block"
      >
        <DropdownToggle
          id="settingb"
          style={{
            marginTop: "-10px",
            color: "black",
            margin: "0px",
            padding: "0px",
          }}
        >
          <MoreHorizIcon id="settingsBud" style={{ color: "#414141" }} />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            tag="c"
            style={{ cursor: "pointer", display: "flex" }}
            onClick={() => {
              setOpenModalForExcel(true)
            }}
          >
            {" "}
            <i
              style={{ marginTop: "-2px", paddingTop: "4px" }}
              className="bx bx-plus font-size-16 align-middle me-1"
            />
            {i18n.t("Importar Excel")}
          </DropdownItem>
          <DropdownItem
            tag="c"
            style={{ cursor: "pointer", display: "flex" }}
            onClick={() => {
              setOpenBc3(true)
            }}
          >
            {" "}
            <i
              style={{ marginTop: "-2px", paddingTop: "4px" }}
              className="bx bx-plus font-size-16 align-middle me-1"
            />
            {i18n.t("Importar BC3")}
          </DropdownItem>
          <DropdownItem
            tag="c"
            style={{ cursor: "pointer", display: "flex" }}
            onClick={() => {
              setOpenExcel(true)
            }}
          >
            {" "}
            <i
              style={{ marginTop: "-2px", paddingTop: "4px" }}
              className="bx bx-plus font-size-16 align-middle me-1"
            />
            {i18n.t("Importar csv")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {openBc3 === true ? (
        <UploadBankPrice
          openModal={openBc3}
          setOpenModal={setOpenBc3}
          setWaitModal={setWaitModal}
          filesAccepted={[".bc3", ".BC3"]}
        />
      ) : null}
      {openModalForExcel ? (
        <UploadExcelModal
          openModal={openModalForExcel}
          setOpenModal={setOpenModalForExcel}
          setWaitModal={setOpenWaitModalForExcel}
          bankPrice={myPricesBank}
          user={user?.sub}
        />
      ) : null}
      {openWaitModalForExcel && (
        <ModalWaitingExcel
          bankprice={myPricesBank}
          openModal={openWaitModalForExcel}
          setOpenModal={setOpenWaitModalForExcel}
          setSuccessModal={setOpenModalImportSuccess}
          imComingFrom={"bankprices"}
          user={user?.sub}
        />
      )}
      {openExcel === true ? (
        <UploadBankPrice
          openModal={openExcel}
          setOpenModal={setOpenExcel}
          setWaitModal={setWaitModal}
          filesAccepted={[".xls", ".xlsx", ".xlsm"]}
        />
      ) : null}
      {waitModal === true ? (
        <WaitingModal
          openModal={waitModal}
          setOpenModal={setWaitModal}
          setSuccessModal={setSuccessModal}
          accountId={user.sub}
        />
      ) : null}
      {successModal === true ? (
        <ImportDatabaseSuccess open={successModal} setOpen={setSuccessModal} />
      ) : null}
    </>
  )
}

export default UserDatabaseOptions
