import SubscriptionComponent from "components/GestionEmpresa/subscriptionComponent"
import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import { RecordPageView } from "App"
import * as amplitude from "@amplitude/analytics-browser"

const SubscriptionPage = () => {
  RecordPageView()
  const [anual, setAnual] = React.useState(false)

  useEffect(() => {
    amplitude.track("Subscription Visited")
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper | Subscripción</title>
        </MetaTags>
        <Container fluid style={{ marginLeft: "30px" }}>
          <SubscriptionComponent anual={anual} setAnual={setAnual} />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SubscriptionPage
