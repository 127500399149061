import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress, Modal } from "@mui/material"
import i18n from "i18n"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import AuthContext from "context/auth/authContext"
import ImportBankPrice from "../dropzones/importBankPrice"
import CheckIcon from "@mui/icons-material/Check"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))
const UploadBankPrice = ({
  openModal,
  setOpenModal,
  setWaitModal,
  filesAccepted,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { importFile, importProgress, cleanImportProgress } =
    useContext(BankPricesContext)
  const { user } = useContext(AuthContext)

  const onUpload = async e => {
    e.preventDefault()
    importFile({ file: selectedFiles[0], accountId: user.sub })
  }
  useEffect(() => {
    if (importProgress.uploadSuccess === true) {
      setOpenModal(false)
      setWaitModal(true)
      cleanImportProgress()
    }
  }, [importProgress])
  useEffect(() => {
    cleanImportProgress()
  }, [])
  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          null
        }}
      >
        <>
          <div style={modalStyle} className={classes.paper}>
            <div className={"py-4"}>
              <p
                className="text-center my-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "23px",
                  color: "#414141",
                }}
              >
                {i18n.t("Sube tus mediciones para crear tu base de datos")}
              </p>
              <button
                type="button"
                onClick={() => {
                  setOpenModal(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "0 5rem" }}>
              <ImportBankPrice
                selectedFiles={selectedFiles}
                setselectedFiles={setSelectedFiles}
                filesAccepted={filesAccepted}
              />
            </div>

            <div id="centerButton">
              {selectedFiles.length == 0 ? (
                <button
                  type="button"
                  className="AddProjectDisabled"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  {i18n.t("Subir")}
                </button>
              ) : (
                <button
                  type="button"
                  className="AddContact"
                  onClick={e => {
                    onUpload(e)
                  }}
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="UploadBc3ToBudget"
                  data-amplify-analytics-attrs={selectedFiles}
                >
                  {importProgress.uploading === true ? (
                    <CircularProgress size={20} />
                  ) : importProgress.uploadSuccess === true ? (
                    <CheckIcon />
                  ) : (
                    i18n.t("Subir")
                  )}
                </button>
              )}
            </div>
          </div>
        </>
      </Modal>
    </>
  )
}

UploadBankPrice.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  setWaitModal: PropTypes.func.isRequired,
  filesAccepted: PropTypes.array.isRequired,
}

export default UploadBankPrice
