import React, { useEffect, useContext, useState } from "react"
import { HelveticaBold, PoppinsNormal } from "common/typographies"
import CapituloModal from "./CapituloModal"
import PropTypes from "prop-types"
import i18n from "i18n"
import { getChapterById } from "utils/budgetUtils"
import Budget2023Context from "context/budget2023/Budget2023Context"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import BankPricesReferenceContext from "context/referenceBankPrices/BankPricesReferenceContext"
import AuthContext from "context/auth/authContext"
import { CircularProgress } from "@mui/material"

const PrincipalChapters = ({
  chaptersToShow,
  loadingSearch,
  estoyEnBusqueda,
  setChaptersToShow,
  loadingTree,
  bankSelected,
  tipo,
  disabled,
  setDisabled,
  loadingButton,
  setLoadingButton,
  doTheAction,
  setDoTheAction,
  chapterId,
  projectInfo,
  setOpen,
}) => {
  const { partsChecked, cleanParts } = useContext(
    bankSelected == "User database"
      ? BankPricesContext
      : BankPricesReferenceContext,
  )
  const { budgetTree, createPartsOnBudget } = useContext(Budget2023Context)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    if (doTheAction && !disabled) {
      setLoadingButton(true)
      const createParts = async () => {
        let capituloEntero = await getChapterById(
          chapterId,
          budgetTree.subcapitulos,
          false,
          0,
          {},
        )
        await createPartsOnBudget(
          user.sub,
          partsChecked,
          projectInfo,
          chapterId,
          capituloEntero,
          bankSelected == "User database"
            ? "user-database"
            : bankSelected == "Cype database"
              ? "cype"
              : bankSelected == "Bedec database"
                ? "bedec"
                : bankSelected == "Preoc database"
                  ? "preoc"
                  : "tribboo",
        )
        //await deletePartidav2 (partida.chapterId, partida.partidaId, user.sub)
        cleanParts()
        setDisabled(true)
        setDoTheAction(false)
        setLoadingButton(false)
        setOpen(false)
      }
      createParts()
    }
  }, [doTheAction])

  useEffect(() => {
    if (partsChecked.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [partsChecked.length])
  return (
    <div
      style={{
        height: "75vh",
        paddingBottom: "10px",
        overflowY: "auto",
        minHeight: "290px",
        position: "relative",
        justifyContent: "center",
      }}
    >
      {loadingTree || loadingSearch ? (
        <>
          <div
            style={{
              width: "100%",
              position: "absolute",
              transform: "translateY(-50%, -50%)",
              top: "20%",
              left: "45%",
            }}
          >
            <CircularProgress
              style={{
                width: 150,
                height: 150,
                marginLeft: "0px",
                color: "#414141",
                marginBottom: "20px",
              }}
            />
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "24px",
                marginLeft: "10px",
                marginTop: "0px",
              }}
            >
              {i18n.t("Cargando...")}
            </HelveticaBold>
          </div>
        </>
      ) : chaptersToShow.length > 0 ? (
        chaptersToShow[chaptersToShow.length - 1].subcapitulos.length > 0 ? (
          chaptersToShow[chaptersToShow.length - 1].subcapitulos.map(
            (subcapitulo, index) => {
              return (
                <CapituloModal
                  estoyEnBusqueda={estoyEnBusqueda}
                  key={index}
                  capitulo={subcapitulo}
                  setChaptersToShow={setChaptersToShow}
                  chaptersToShow={chaptersToShow}
                  width={96}
                  ml={20}
                  numeroArray={0}
                  bankSelected={bankSelected}
                  tipo={tipo}
                  disabled={disabled}
                  setDisabled={setDisabled}
                  loadingButton={loadingButton}
                  setLoadingButton={setLoadingButton}
                  doTheAction={doTheAction}
                  setDoTheAction={setDoTheAction}
                  chapterId={chapterId}
                  projectInfo={projectInfo}
                  setOpen={setOpen}
                />
              )
            },
          )
        ) : (
          <PoppinsNormal style={{ marginLeft: "360px", marginTop: "30px" }}>
            {i18n.t("No hay capítulos disponibles")}
          </PoppinsNormal>
        )
      ) : (
        <PoppinsNormal style={{ marginLeft: "360px", marginTop: "30px" }}>
          {i18n.t("No hay capítulos disponibles")}
        </PoppinsNormal>
      )}
    </div>
  )
}

PrincipalChapters.propTypes = {
  chaptersToShow: PropTypes.array.isRequired,
  setChaptersToShow: PropTypes.func.isRequired,
  loadingTree: PropTypes.bool.isRequired,
  bankSelected: PropTypes.string,
  tipo: PropTypes.string,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  loadingButton: PropTypes.bool,
  setLoadingButton: PropTypes.func,
  doTheAction: PropTypes.bool,
  setDoTheAction: PropTypes.func,
  chapterId: PropTypes.string,
  projectInfo: PropTypes.object,
  setOpen: PropTypes.func,
  estoyEnBusqueda: PropTypes.bool,
  loadingSearch: PropTypes.bool,
}

export default PrincipalChapters
