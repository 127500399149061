import { useReducer } from "react"
import ProposalReducer from "./proposalReducer"
import ProposalContext from "./proposalContext"
import { API, Auth } from "aws-amplify"
import React from "react"
import PropTypes from "prop-types"

import {
  ON_GET_PROPOSAL,
  GET_PROPOSAL_SUCCESS,
  GET_PROPOSAL_ERROR,
  GET_GROUP_PROPOSAL_SUCCESS,
  LOADING_PDF,
  GET_PDF_SUCCESS,
} from "../types"

class PartProposal {
  constructor(part) {
    Object.assign(this, part)
  }
  update(part) {
    Object.assign(this, part)
  }
}
class ChapterProposal {
  constructor(chapter, isRoot) {
    if (isRoot === true) {
      this.chapterProposalId = "root"
      this.subchapters = chapter.map(ch => new ChapterProposal(ch, false))
    } else {
      let { parts, subchapters, ...info } = chapter
      Object.assign(this, info)
      this.subchapters = subchapters.map(
        subchapter => new ChapterProposal(subchapter, false),
      )
      this.parts = parts.map(part => new PartProposal(part))
    }
  }

  *preOrder() {
    yield this
    if (this.subchapters.length > 0) {
      for (let subchapter of this.subchapters) {
        yield* subchapter.preOrder()
      }
    }
  }
}
const ProposalState = props => {
  const ENDPOINT = "dev-PLANHOPPER-API"
  const ENDPOINT2 = "dev-PROVIDERS-API"
  const initialState = {
    loading: true,
    error: null,
    projectInfo: null,
    providerInfo: null,
    userInfo: null,
    proposalInfo: null,
    proposalTree: null,
    proposalTreeWithInfo: null,
    proposalsGroup: [],
    loadingPdf: false,
    pdf: "",
  }
  const [state, dispatch] = useReducer(ProposalReducer, initialState)
  const getProposalInfo = async projectId => {
    try {
      let proposalTree = []
      dispatch({
        type: ON_GET_PROPOSAL,
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const res = await API.get(ENDPOINT, `/api/proposalsClient/` + projectId, {
        headers: headers,
      })

      let aux = []
      if (res) {
        for (let i = 0; i < res.proposals.length; i++) {
          proposalTree.push(
            new ChapterProposal(res.proposals[i].proposalTree, true),
          )
          aux.push({
            chapterProposal: new ChapterProposal(
              res.proposals[i].proposalTree,
              true,
            ),
            proposalInfo: res.proposals[i].proposalInfo,
            providerInfo: res.proposals[i].providerInfo,
            userInfo: res.proposals[i].userInfo,
            projectInfo: res.proposals[i].projectInfo,
          })
        }
      }

      dispatch({
        type: GET_PROPOSAL_SUCCESS,
        payload: {
          proposalTree: proposalTree,
          proposalInfo: res.proposalInfo,
          projectInfo: res.projectInfo,
          providerInfo: res.providerInfo,
          userInfo: res.userInfo,
          proposalTreeWithInfo: aux,
        },
      })
    } catch (err) {
      console.log(err)
      dispatch({
        type: GET_PROPOSAL_ERROR,
      })
    }
  }

  const getGroupInfo = async projectId => {
    try {
      dispatch({
        type: ON_GET_PROPOSAL,
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      let res = await API.get(ENDPOINT2, "/getGroupProposals/" + projectId, {
        headers: headers,
      })
      if (res.length > 0) {
        for (let index = 0; index < res.length; index++) {
          const newProposals = await API.get(
            ENDPOINT2,
            "/getProposalsFromGroup?projectId=" +
              projectId +
              "&groupId=" +
              res[index].groupId,
            { headers: headers },
          )
          res[index].proposals = newProposals
        }
      }
      dispatch({
        type: GET_GROUP_PROPOSAL_SUCCESS,
        payload: {
          groups: res,
        },
      })
    } catch (err) {
      console.log(err)
      dispatch({
        type: GET_PROPOSAL_ERROR,
      })
    }
  }

  const addProposalToGroup = async (groupId, projectId, proposalId) => {
    try {
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const body = {
        groupId: groupId,
        projectId: projectId,
        proposalId: proposalId,
      }
      const res1 = await API.put(ENDPOINT2, `/addProposalToGroup/`, {
        headers: headers,
        body: body,
      })
      const newProposals = await API.get(
        ENDPOINT2,
        "/getProposalsFromGroup?projectId=" + projectId + "&groupId=" + groupId,
        { headers: headers },
      )
      let auxTree = state.proposalsGroup
      let trobat = false
      let i = 0
      while (i < auxTree.length && !trobat) {
        if (auxTree[i].groupId == groupId) {
          auxTree[i].proposals = newProposals
          trobat = true
        }
        i++
      }
      dispatch({
        type: GET_GROUP_PROPOSAL_SUCCESS,
        payload: {
          groups: auxTree,
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const createGroup = async (groupName, accountId, projectId, proposalId) => {
    try {
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const body = {
        projectId: projectId,
        proposalId: proposalId,
        groupName: groupName,
        accountId: accountId,
      }
      const res = await API.post(ENDPOINT2, `/createGroup/`, {
        headers: headers,
        body: body,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const sendReminder = async (proposalId, accountId, email, projectId) => {
    try {
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const body = {
        proposalId: proposalId,
        email: email,
        accountId: accountId,
        projectId: projectId,
      }
      const res = await API.post(ENDPOINT2, `/sendReminder/`, {
        headers: headers,
        body: body,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const getPdf = async (proposalId, accountId) => {
    try {
      dispatch({
        type: LOADING_PDF,
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const res = await API.get(
        ENDPOINT2,
        `/getPDF?proposalId=` + proposalId + "&accountId=" + accountId,
        { headers: headers },
      )
      dispatch({
        type: GET_PDF_SUCCESS,
        payload: {
          pdf: res,
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const acceptProposal = async (
    proposalId,
    groupId,
    accountId,
    email,
    projectId,
  ) => {
    try {
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const body = {
        proposalId: proposalId,
        groupId: groupId,
        email: email,
        accountId: accountId,
        projectId: projectId,
      }
      const res = await API.put(ENDPOINT2, `/acceptProposalUser`, {
        headers: headers,
        body: body,
      })
      return res
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <ProposalContext.Provider
      value={{
        loading: state.loading,
        proposalInfo: state.proposalInfo,
        error: state.error,
        getProposalInfo,
        proposalTree: state.proposalTree,
        proposalTreeWithInfo: state.proposalTreeWithInfo,
        projectInfo: state.projectInfo,
        providerInfo: state.providerInfo,
        userInfo: state.userInfo,
        proposalsGroup: state.proposalsGroup,
        getGroupInfo,
        addProposalToGroup,
        createGroup,
        sendReminder,
        getPdf,
        loadingPdf: state.loadingPdf,
        pdf: state.pdf,
        acceptProposal,
      }}
    >
      {props.children}
    </ProposalContext.Provider>
  )
}
ProposalState.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ProposalState
