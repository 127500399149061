import React, { useState } from "react"
import { Chip, Stack } from "@mui/material"
import { Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "auto",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalShowExtraTags = ({ open, setOpen, tags }) => {
  // Configuración del modal para añadir contactos
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon id="cerrar" onClick={onClose}></CloseIcon>
          <div style={{ marginTop: "40px" }}></div>
          {tags.length > 0 ? (
            <div>
              {tags.map((tag, index) => (
                <Stack
                  key={index}
                  style={{
                    marginRight: "0.5rem",
                    marginBottom: "10px",
                  }}
                >
                  <Chip
                    id={tag.color}
                    className="etiquetaContacto"
                    size="small"
                    label={tag.role}
                    style={{
                      borderRadius: "4px",
                      marginTop: "1px",
                      textTransform: "uppercase",
                    }}
                  />
                </Stack>
              ))}
            </div>
          ) : null}
        </div>
      </Modal>
    </>
  )
}

ModalShowExtraTags.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
}
export default withRouter(ModalShowExtraTags)
