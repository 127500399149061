import {
  CHANGE_SEND_BUDGET,
  SENDING_MESSAGE,
  SEND_COMPLETE,
  RESET_SEND,
  SET_FOOTER_VALUES,
  DEL_CAPITULO_SET_POSITION,
  SET_BC3_LINK_DATA,
  TOGGLE_MODAL_IMPORT_BC3_LINK,
  IS_IMPORTING_PART_FROM_EDIT_MODAL,
  SET_MODAL_BUDGETING_ERROR,
  REMOVE_ALL_PARTS_FROM_CHAPTER,
  SET_CHAPTER_ID_SELECTED_FROM_BC3LINK,
  IS_CONTRADICTING_PART,
  LOADING_EXTRA_AND_CONTRADITIONS,
  SET_EXTRA_AND_CONTRADITIONS,
  SET_FOOTER_VALUES_FOR_EXTRA_AND_CONTRADITIONS,
  SET_LOADING_ICON,
  IS_BUDGET_CLOSED,
  MOVE_PARTS_BY_CHAPTERS_ID,
  SET_DYNAMIC_ELEMENT_SELECTED,
  SET_DYNAMIC_ELEMENT_MODAL_IS_OPEN,
  SET_DYNAMIC_ELEMENT_PROJECT_ID,
} from "../types"

const Budget2023Reducer = (state, action) => {
  switch (action.type) {
    case SET_FOOTER_VALUES:
      state.budgetTree.calculateFooterValues()
      return {
        ...state,
        loading: false,
      }
    case SET_FOOTER_VALUES_FOR_EXTRA_AND_CONTRADITIONS:
      state.extraAndContradictions.calculateFooterValues()
      return {
        ...state,
        loading: false,
      }
    case "SET_BUDGET_TREE":
      state.budgetTree.subcapitulos = action.payload
      return {
        ...state,
        loading: false,
      }
    case SET_EXTRA_AND_CONTRADITIONS:
      state.extraAndContradictions.subcapitulos = action.payload
      return {
        ...state,
        loading: false,
      }
    case "DEL_CAPITULO":
      state.budgetTree.remove(action.payload.capituloId)
      if (
        action.payload.totalDiscount != undefined ||
        action.payload.totalDiscount != null
      ) {
        state.budgetTree.updateDiscount(action.payload.totalDiscount, "root")
      }
      return {
        ...state,
        updating: false,
      }
    case "DEL_PARTIDA":
      state.budgetTree.removePartida(
        action.payload.capituloId,
        action.payload.partidaId,
      )
      if (
        action.payload.totalDiscount != undefined ||
        action.payload.totalDiscount != null
      ) {
        state.budgetTree.updateDiscount(action.payload.totalDiscount, "root")
      }
      return {
        ...state,
        updating: false,
      }
    case "DEL_PARTIDA_MOVE":
      state.budgetTree.removePartidaMove(
        action.payload.capituloId,
        action.payload.partidaId,
      )
      if (
        action.payload.totalDiscount != undefined ||
        action.payload.totalDiscount != null
      ) {
        state.budgetTree.updateDiscount(action.payload.totalDiscount, "root")
      }
      return {
        ...state,
        updating: false,
      }
    case "CLEAN_PARTS":
      return {
        ...state,
        partsChecked: [],
      }
    case "UNCHECK_ALL":
      state.budgetTree.checkCapitulo("root", false)
      return {
        ...state,
        chaptersChecked: [],
        partsChecked: [],
      }
    case "ADD_PARTIDA":
      state.budgetTree.addPartida(
        action.payload.chapterId,
        action.payload.partidaNueva,
        action.payload.tmpIndex - 1,
      )
      return {
        ...state,
        updating: false,
      }
    case "ADD_PARTIDA_MOVE":
      state.budgetTree.addPartida(
        action.payload.chapterId,
        action.payload.partidaNueva,
        action.payload.tmpIndex,
      )
      return {
        ...state,
        updating: false,
      }
    case SET_BC3_LINK_DATA:
      return {
        ...state,
        bc3LinkData: action.payload,
      }
    case "ADD_CAPITULO":
      state.budgetTree.addCapitulo(
        action.payload.parentId,
        action.payload.res,
        action.payload.indexCapitulo,
      )
      return {
        ...state,
        updating: false,
      }
    case "FILL_CHAPTER_CONTENT":
      state.budgetTree.fillChapterContent(
        action.payload.chapterId,
        action.payload.content,
      )
      return {
        ...state,
        updating: false,
      }
    case "FILL_CHAPTER_CONTENT_2":
      state.budgetTree.fillChapterContent2(
        action.payload.chapterId,
        action.payload.content,
      )
      return {
        ...state,
        updating: false,
      }
    case "LOADING_BUDGET":
      return {
        ...state,
        loading: action.payload.loading,
        budgetTree: action.payload.budgetTree,
      }
    case LOADING_EXTRA_AND_CONTRADITIONS:
      return {
        ...state,
        loading: action.payload.loading,
        extraAndContradictions: action.payload.extraAndContradictions,
      }
    case "ERR_BUDGET_TREE":
      return {
        ...state,
        loading: action.payload.loading,
      }
    case "UPDATE_IS_EXPANDED":
      state.budgetTree.updateIsExpanded(action.payload)
      return {
        ...state,
      }
    case "CHECK_CAPITULOS":
      state.budgetTree.checkCapitulo(
        action.payload.capituloId,
        action.payload.value,
      )
      return {
        ...state,
      }
    case "CHECK_PARTIDA":
      state.budgetTree.checkPartida(
        action.payload.capituloId,
        action.payload.partidaId,
      )
      return {
        ...state,
      }
    case "UPDATE_PARTIDA":
      state.budgetTree.updatePartida(
        action.payload.newData,
        action.payload.capituloId,
        action.payload.final,
      )
      return {
        ...state,
        updating: false,
      }
    case "UPDATE_FINALPRICE":
      return {
        ...state,
        budgetTree: { ...state.budgetTree, clientPrice: action.payload },
      }
    case "ADD_IMAGE":
      state.budgetTree.updatePartida(
        action.payload.newData,
        action.payload.capituloId,
        action.payload.final,
      )
      return {
        ...state,
        updating: false,
      }
    case "UPDATE_CAPITULO":
      state.budgetTree.updateCapitulo(
        action.payload.newData,
        action.payload.capituloId,
      )
      return {
        ...state,
        updating: false,
      }
    case "SET_SELECTED":
      return {
        ...state,
        selectedBudget2: action.payload.selectedBudget,
      }
    case "ON_UPDATE":
      return {
        ...state,
        updating: true,
      }
    case "ON_UPDATE_RECALC":
      return {
        ...state,
        loadingRecalc: true,
      }
    case "RECALC_DONE":
      return {
        ...state,
        loadingRecalc: false,
      }
    case "CLOSE_BUDGET":
      state.budgetTree.checkEmpty()
      return {
        ...state,
        readyToClose: !state.budgetTree.warning,
      }
    case "CHECK_UPDATES":
      state.budgetTree.checkUpdates()
      return {
        ...state,
      }

    case "DOWNLOADING_BUDGET":
      return {
        ...state,
        urlDownload: "pending",
      }
    case "DOWNLOAD_BUDGET":
      return {
        ...state,
        urlDownload: "done",
      }
    case "SET_GROUP":
      return {
        ...state,
        selectedPartGroup: action.payload.selectedParts,
        errorAgrupar: false,
      }
    case "SELECCIONAR_AGRUPAR":
      return {
        ...state,
        selectedChaptersGroup: action.payload.selectedBudget,
      }
    case "ERROR_CAPITULO":
      return {
        ...state,
        selectedPartGroup: [],
        errorAgrupar: true,
      }
    case "CHANGE_DIMENSIONS":
      return {
        ...state,
        changeSidebar: action.payload.num + 1,
      }
    case "UPDATE_PARTS_LIST":
      return {
        ...state,
        partsChecked: action.payload.list,
      }
    case "UPDATE_CHAPTERS_LIST":
      return {
        ...state,
        chaptersChecked: action.payload.list,
      }
    case "CHANGE_MOVING":
      return { ...state, imChecked: action.payload.res }
    case "CHANGE_MOVING_2":
      return { ...state, imChecked2: action.payload.res }
    case "CLEAN_STATE_FOR_MOVING_PARTS":
      return { ...state, imChecked: action.payload.objeto, partsChecked: [] }
    case "LEAN_STATE_FOR_MOVING_CHAPTERS":
      return {
        ...state,
        imChecked2: action.payload.objeto,
        chaptersChecked: [],
      }
    case "POST_GROUP_PARTS":
      state.budgetTree.postGroupParts(
        action.payload.chapter,
        action.payload.parts,
        action.payload.oldParts,
      )
      return {
        ...state,
        loadingAgruparParts: false,
      }
    case "POST_GROUP_CHAPTERS":
      state.budgetTree.postGroupChapters(
        action.payload.chapterCreated,
        action.payload.newChapters,
        action.payload.oldChapters,
      )
      return {
        ...state,
        loadingAgruparChapters: false,
      }
    case "UPDATE_TAGS_LIST":
      return {
        ...state,
        tags: action.payload.tagsList,
      }
    case "SAVE_TAGS_LIST":
      return {
        ...state,
        tagsFiltered: action.payload.tagsList,
      }
    case "CHANGE_VERSION":
      return {
        ...state,
        versionToDisplay: action.payload.version,
      }
    case "LOADING_BUDGET_PDF":
      return {
        ...state,
        loadingBudgetPDF: true,
      }
    case "SET_BUDGET_PDF":
      return {
        ...state,
        loadingBudgetPDF: false,
        budgetPDF: action.payload,
      }
    case "GROUPING_PARTS":
      return {
        ...state,
        loadingAgruparParts: true,
      }
    case "GROUPING_CHAPTERS":
      return {
        ...state,
        loadingAgruparChapters: true,
      }
    case "SET_PREFERENCIAS":
      return {
        ...state,
        preferencias: action.payload.preferencias,
      }
    case CHANGE_SEND_BUDGET:
      return {
        ...state,
        sendChapters: action.payload,
      }
    case SENDING_MESSAGE:
      return {
        ...state,
        loadingSend: true,
        sendOk: false,
      }
    case SEND_COMPLETE:
      return {
        ...state,
        loadingSend: false,
        sendOk: true,
      }
    case RESET_SEND:
      return {
        ...state,
        loadingSend: false,
        sendOk: false,
      }
    case "COPY_DATA":
      return {
        ...state,
        copiedData: action.payload,
      }
    case "ADD_LAST_ACTION":
      console.log("aaaaa", action.payload)
      return {
        ...state,
        lastActions: [...state.lastActions, action.payload],
      }
    case "REMOVE_LAST_UNDO_ACTION":
      let newLastActions = state.lastActions.splice(-1)
      return {
        ...state,
        lastActions: newLastActions,
      }
    case TOGGLE_MODAL_IMPORT_BC3_LINK:
      return {
        ...state,
        openModalImportPartFromBc3Link: action.payload,
      }
    case SET_CHAPTER_ID_SELECTED_FROM_BC3LINK:
      return {
        ...state,
        chapterIdSelectedFromBc3Link: action.payload.chapterId,
      }
    case IS_IMPORTING_PART_FROM_EDIT_MODAL:
      return {
        ...state,
        isImportingPartFromEditModal: action.payload,
      }
    case IS_CONTRADICTING_PART:
      return {
        ...state,
        isContradictingPart: action.payload,
      }
    case IS_BUDGET_CLOSED:
      return {
        ...state,
        isBudgetClosed: action.payload,
        updating: false,
      }
    case SET_MODAL_BUDGETING_ERROR:
      return {
        ...state,
        modalBudgetingError: action.payload,
      }
    case DEL_CAPITULO_SET_POSITION:
      state.budgetTree.removeAndSetPosition(action.payload.capituloId)
      if (
        action.payload.totalDiscount != undefined ||
        action.payload.totalDiscount != null
      ) {
        state.budgetTree.updateDiscount(action.payload.totalDiscount, "root")
      }
      return {
        ...state,
        updating: false,
      }
    case REMOVE_ALL_PARTS_FROM_CHAPTER:
      state.budgetTree.removeAllPartsByChapterId(action.payload.chapterId)
      return {
        ...state,
        updating: false,
      }

    case MOVE_PARTS_BY_CHAPTERS_ID: {
      state.budgetTree.movePartsByChapterIds(
        action.payload.parentId,
        action.payload.chapterId,
      )
      return {
        ...state,
        updating: false,
      }
    }
    case SET_LOADING_ICON: {
      return {
        ...state,
        loadingIcon: action.payload,
      }
    }

    case SET_DYNAMIC_ELEMENT_MODAL_IS_OPEN: {
      return {
        ...state,
        dynamicElement: {
          ...state.dynamicElement,
          modalIsOpen: action.payload,
        },
      }
    }

    case SET_DYNAMIC_ELEMENT_SELECTED: {
      return {
        ...state,
        dynamicElement: {
          ...state.dynamicElement,
          selected: action.payload,
        },
      }
    }

    case SET_DYNAMIC_ELEMENT_PROJECT_ID: {
      return {
        ...state,
        dynamicElement: {
          ...state.dynamicElement,
          projectId: action.payload,
        },
      }
    }

    default:
      return state
  }
}
export default Budget2023Reducer
