import { GET_CONTACT_SUCCESS } from "../types"

const ContactReducer = (state, action) => {
  switch (action.type) {
    case GET_CONTACT_SUCCESS:
      return {
        ...state,
        Contacts: action.payload,
        loadingContacts: false,
      }
    case "GET_CONTACTS":
      return {
        ...state,
        loadingContacts: true,
      }

    default:
      return state
  }
}

export default ContactReducer
