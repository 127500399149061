import { useMemo } from "react"

/**
 * Custom hook for transforming project data into rows for the dashboard.
 *
 * This hook takes in an array of projects and transforms them into a format suitable for rendering in a dashboard table.
 * It uses `useMemo` to memoize the rows, ensuring that they are recalculated only when the `projects` array changes.
 *
 * @param {Array} projects - An array of project objects to transform into rows.
 * @param {string} projects.projectId - The unique identifier for the project.
 * @param {string} projects.projectImage - The image URL for the project.
 * @param {string} projects.name - The name of the project.
 * @param {string} projects.description - A brief description of the project.
 * @param {string} projects.fase - The current phase of the project.
 * @param {number} projects.progressPercentage - The progress percentage of the project.
 * @param {number} projects.finalPrice - The final price of the project.
 *
 * @returns {Object} The transformed rows.
 * @returns {Array} rows - An array of row objects, each representing a project with the following properties:
 *   - `id`: The unique ID of the project (either `projectId` or the array index).
 *   - `projectImage`: The image URL for the project.
 *   - `name`: The name of the project.
 *   - `description`: A brief description of the project.
 *   - `fase`: The current phase of the project.
 *   - `progressPercentage`: The progress percentage of the project.
 *   - `finalPrice`: The final price of the project.
 */
const useDashboardRows = projects => {
  const rows = useMemo(
    () =>
      projects?.map((project, index) => ({
        ...project,
        id: project.projectId || index,
        projectImage: project.projectImage,
        name: project.name,
        description: project.description,
        fase: project.fase,
        progressPercentage: project.progressPercentage,
        finalPrice: project.finalPrice,
        identifierNumber: project.identifierNumber,
        updatedAt: project.updatedAt,
        price: project.price,
        industrialProfit: project.industrialProfit,
        realMargin: project.finalPrice - project.price, //! check this
      })) || [],
    [projects],
  )

  return { rows }
}

export default useDashboardRows
