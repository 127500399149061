import React, { useState, useMemo, useEffect, useContext, useRef } from "react"
import { Grid, CircularProgress } from "@material-ui/core"
import { CardPricing, FakeInput } from "common/containers"
import {
  HelveticaBold,
  HelveticaLight,
  PoppinsSemiBold,
} from "common/typographies"
import i18n from "i18n"
import { HelveticaInput } from "common/inputs"
import { YellowButton } from "common/buttons"
import pagoRecurrenteList from "./pagoRecurrente.json"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import AuthContext from "context/auth/authContext"
import DropdownPagoAlContado from "./DropdownPagoAlContado"
import { CloseOutlined } from "@mui/icons-material"
import { SketchPicker } from "react-color"
import ImageDropZone from "components/BudgetingTemplate/imageDropZone"
import { useUtils } from "hooks/useUtils"
import * as amplitude from "@amplitude/analytics-browser"
import ModalInstructions from "./ModalInstructions"

const GeneralComponent = () => {
  const [userInfo, setUserInfo] = useState({
    name: "",
    mobile: "",
    cif: "",
    web: "",
    direccion: "",
    logo: "",
  })
  const pagoRecurrenteOptions = useMemo(() => pagoRecurrenteList, [])

  const [languageOptions, setLanguajeOptions] = useState({
    iban: "",
    titular: "",
    pagoRecurrente: {},
  })

  const { user, updateUser, organizationFields, updateOrganization } =
    useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [userInfoSaved, setUserInfoSaved] = useState(false)
  const [orgIdentitySaved, setOrgIdentitySaved] = useState(false)
  const [languageOptionsSaved, setLanguajeOptionsSaved] = useState(false)
  const [showAccountInputs, setShowAccountInputs] = useState(false)

  const [logo, setLogo] = useState(null)
  const [primaryColor, setPrimaryColor] = useState("")
  const [secondaryColor, setSecondaryColor] = useState("")
  const { generateFileUrl } = useUtils()

  useEffect(() => {
    amplitude.track({
      event_type: "General Profile Visited",
      event_properties: { accountEmail: user?.email },
    })
  }, [])

  useEffect(() => {
    if (user && organizationFields) {
      if (
        !organizationFields["name"] ||
        organizationFields["name"] === "" ||
        !organizationFields["phone"] ||
        organizationFields["phone"] === "" ||
        !organizationFields["cif_number"] ||
        organizationFields["cif_number"] === "" ||
        !organizationFields["website"] ||
        organizationFields["website"] === "" ||
        !organizationFields["address"] ||
        organizationFields["address"] === ""
      ) {
        setShowOrgInputs(true)
      } else {
        setShowOrgInputs(false)
      }
      setUserInfo({
        name: organizationFields["name"] ? organizationFields["name"] : "",
        mobile: organizationFields["phone"] ? organizationFields["phone"] : "",
        cif: organizationFields["cif_number"]
          ? organizationFields["cif_number"]
          : "",
        web: organizationFields["website"] ? organizationFields["website"] : "",
        direccion: organizationFields["address"]
          ? organizationFields["address"]
          : "",
        logo: organizationFields["logo"] ? organizationFields["logo"] : "",
      })
      let pago = "No"
      if (user["custom:pago_recurrente"]) {
        let trobat = false
        let i = 0
        while (!trobat && i < pagoRecurrenteOptions.length) {
          if (
            pagoRecurrenteOptions[i].label === user["custom:pago_recurrente"]
          ) {
            trobat = true
            setPago(pagoRecurrenteOptions[i])
            pago = pagoRecurrenteOptions[i]
          } else {
            i++
          }
        }
      } else {
        setPago({ value: "No", label: "NO" })
      }
      if (
        !user["custom:iban"] ||
        user["custom:iban"] === "" ||
        !user["custom:titular_cuenta"] ||
        user["custom:titular_cuenta"] === ""
      ) {
        setShowAccountInputs(true)
      } else {
        setShowAccountInputs(false)
      }
      setLanguajeOptions({
        iban: user["custom:iban"] ? user["custom:iban"] : "",
        titular: user["custom:titular_cuenta"]
          ? user["custom:titular_cuenta"]
          : "",
        pagoRecurrente: pago,
      })
      if (organizationFields["logo"]) {
        setLogo(organizationFields["logo"])
        setShowDropZone(true)
      }
      if (organizationFields["color_primary"]) {
        setPrimaryColor(organizationFields["color_primary"])
        setShowDropZone(true)
      }
      if (organizationFields["color_secondary"]) {
        setSecondaryColor(organizationFields["color_secondary"])
        setShowDropZone(true)
      }
    }
    setUserInfoSaved(true)
    setLanguajeOptionsSaved(true)
  }, [])

  const [refs4UserInfo, setRefs4UserInfo] = useState([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ])
  const [refs4Address, setRefs4Address] = useState([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ])

  const handleChange = e => {
    setUserInfoSaved(false)
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    })
  }

  const handleChange2 = e => {
    setLanguajeOptionsSaved(false)
    setLanguajeOptions({
      ...languageOptions,
      [e.target.name]: e.target.value,
    })
  }
  const [value, setValue] = useState("")
  const [pagoRecurrenteValue, setPago] = useState("")
  const [idiomaValue, setIdiomaValue] = useState("")
  const [showOrgInputs, setShowOrgInputs] = useState(true)
  const [showIdentityInputs, setShowIdentityInputs] = useState(true)

  const changeHandler2 = value => {
    setLanguajeOptionsSaved(false)
    setPago(value)
  }

  const [displayPicker, setDisplayPicker] = useState(false)

  const refpicker = useRef(null)
  const handleClick1 = () => {
    setDisplayPicker(true)
  }

  const handleChangeComplete = color => {
    setPrimaryColor(color.hex)
  }

  useEffect(() => {
    if (displayPicker) {
      if (refpicker.current) {
        refpicker.current.focus()
      }
    }
  }, [displayPicker])

  const [displayPicker2, setDisplayPicker2] = useState(false)

  const refpicker2 = useRef(null)
  const handleClick2 = () => {
    setDisplayPicker2(true)
  }

  const handleChangeComplete2 = color => {
    setSecondaryColor(color.hex)
  }

  const [openInstructions, setOpenIntructions] = useState(false)

  useEffect(() => {
    if (displayPicker2) {
      if (refpicker2.current) {
        refpicker2.current.focus()
      }
    }
  }, [displayPicker2])

  const [selectedFiles, setselectedFiles] = useState([])

  const [showDropZone, setShowDropZone] = useState(false)

  const handleSubmit1 = async e => {
    e.preventDefault()
    let valida = true
    {
      /*
      if(userInfo.name.length==0){
        valida=false
        refs4UserInfo[0].current.style.border="1px solid red"
      }
      else{
          refs4UserInfo[0].current.style.border="1px solid #e0e0e0"
      }
      if(userInfo.mobile==""){
        valida=false
        refs4UserInfo[1].current.style.border="1px solid red"
      }else{
        refs4UserInfo[1].current.style.border="1px solid #e0e0e0"
      }
      if(userInfo.cif.length==0){
        valida=false
        refs4UserInfo[2].current.style.border="1px solid red"
      }else{
        refs4UserInfo[2].current.style.border="1px solid #e0e0e0"
      }
      if(userInfo.web.length==0){
        valida=false
        refs4UserInfo[3].current.style.border="1px solid red"
      }else{
        refs4UserInfo[3].current.style.border="1px solid #e0e0e0"
      }
      if(userInfo.direccion.length==0){
        valida=false
        refs4UserInfo[4].current.style.border="1px solid red"
      }else{
        refs4UserInfo[4].current.style.border="1px solid #e0e0e0"
      }
      */
    }
    if (valida) {
      setLoading(true)
      let userInfoParsed = {
        "custom:nombre_empresa": userInfo.name,
        "custom:mobile": userInfo.mobile,
        "custom:nif_empresa": userInfo.cif,
        "custom:web_page": userInfo.web,
        "custom:direccion_empresa": userInfo.direccion,
        "custom:logo": userInfo.logo,
      }
      await updateUser(userInfoParsed)
      await updateOrganization(user["custom:organizationId"], {
        logo: userInfo.logo,
        color_primary: primaryColor,
        color_secondary: secondaryColor,
        name: userInfo.name,
        phone: userInfo.mobile,
        cif_number: userInfo.cif,
        website: userInfo.web,
        address: userInfo.direccion,
      })
      setLoading(false)
      setShowOrgInputs(false)
      setUserInfoSaved(true)
    }
  }
  const handleSubmit2 = async e => {
    e.preventDefault()
    let valida = true

    if (pagoRecurrenteValue.length == 0) {
      valida = false
    } else {
      setLanguajeOptions({
        ...languageOptions,
        pagoRecurrente: pagoRecurrenteValue,
      })
    }
    if (languageOptions.iban.length == 0) {
      valida = false
      refs4Address[2].current.style.border = "1px solid red"
    } else {
      refs4Address[2].current.style.border = "1px solid #e0e0e0"
    }
    if (languageOptions.titular.length == 0) {
      valida = false
      refs4Address[0].current.style.border = "1px solid red"
    } else {
      refs4Address[0].current.style.border = "1px solid #e0e0e0"
    }

    if (valida) {
      setLoading2(true)
      let userInfoParsed = {
        "custom:iban": languageOptions.iban,
        "custom:pago_recurrente": pagoRecurrenteValue.label,
        "custom:titular_cuenta": languageOptions.titular,
      }
      await updateUser(userInfoParsed)
      setLoading2(false)
      setShowAccountInputs(false)
      setLanguajeOptionsSaved(true)
    }
  }

  const referenciasIdentity = [
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]
  const handleSubmit3 = async e => {
    e.preventDefault()
    let valida = true
    {
      /*
      if(logo==null){
        valida=false
        referenciasIdentity[0].current.style.color="red"
      }else{
        referenciasIdentity[0].current.style.color="#414141"
      }
      if(primaryColor.length==0){
        valida=false
        referenciasIdentity[1].current.style.border="1px solid red"
      }else{
        referenciasIdentity[1].current.style.border="1px solid #e0e0e0"
      }
      if(secondaryColor.length==0){
        valida=false
        referenciasIdentity[2].current.style.border="1px solid red"
      }else{
        referenciasIdentity[2].current.style.border="1px solid #e0e0e0"
      }
*/
    }
    if (valida) {
      setLoading(true)
      let logoParsed = await generateFileUrl(selectedFiles[0], "protected")
      let userInfoParsed
      if (logoParsed) {
        userInfoParsed = {
          "custom:orgLogo": logoParsed,
          "custom:primaryColor": primaryColor,
          "custom:secondaryColor": secondaryColor,
        }
      } else {
        userInfoParsed = {
          "custom:orgLogo": logoParsed,
          "custom:primaryColor": primaryColor,
          "custom:secondaryColor": secondaryColor,
        }
      }
      await updateUser(userInfoParsed)
      await updateOrganization(user["custom:organizationId"], {
        logo: logoParsed,
        color_primary: primaryColor,
        color_secondary: secondaryColor,
        name: userInfo.name,
        phone: userInfo.mobile,
        cif_number: userInfo.cif,
        website: userInfo.web,
        address: userInfo.direccion,
      })
      setLoading(false)
      setShowIdentityInputs(false)
      setOrgIdentitySaved(true)
    }
  }
  return (
    <div>
      <PoppinsSemiBold
        style={{ fontSize: "20px", marginLeft: "30px", marginBottom: "20px" }}
      >
        {i18n.t("Configuración")}
      </PoppinsSemiBold>
      <div className="inline">
        <Grid
          container
          style={{
            backgroundColor: "transparent",
            height: "auto",
            marginLeft: "20px",
            marginRight: "20px",
            marginBottom: "50px",
          }}
          direction="row"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "12px",
              cursor: "pointer",
              width: "100%",
              position: "relative",
            }}
          >
            <CardPricing
              style={{
                width: "100%",
                height: "295px",
                maxWidth: "908px",
                position: "relative",
                minWidth: "520px",
                paddingLeft: "16px",
                paddingTop: "9px",
                cursor: "default",
              }}
            >
              <HelveticaBold style={{ color: "#414141", fontSize: "20px" }}>
                {i18n.t("Información de Empresa")}
              </HelveticaBold>

              <div className="inline" style={{ width: "85%" }}>
                <div style={{ width: "45%", marginRight: "20px" }}>
                  <HelveticaBold
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("Nombre")}
                  </HelveticaBold>
                  {!userInfoSaved || showOrgInputs ? (
                    <HelveticaInput
                      style={{ width: "100%" }}
                      placeholder={"Introduce el nombre de tu empresa"}
                      name="name"
                      value={userInfo.name}
                      onChange={handleChange}
                      ref={refs4UserInfo[0]}
                    ></HelveticaInput>
                  ) : (
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#414141",
                        fontWeight: "400",
                        paddingTop: "7PX",
                        paddingBottom: "7px",
                      }}
                    >
                      {userInfo.name}
                    </HelveticaBold>
                  )}
                </div>
                <div style={{ width: "45%" }}>
                  <HelveticaBold
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("Telefono")}
                  </HelveticaBold>
                  {!userInfoSaved || showOrgInputs ? (
                    <HelveticaInput
                      style={{ width: "100%" }}
                      placeholder={"+00 000 000 000"}
                      name="mobile"
                      value={userInfo.mobile}
                      onChange={handleChange}
                      ref={refs4UserInfo[1]}
                    ></HelveticaInput>
                  ) : (
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#414141",
                        fontWeight: "400",
                        paddingTop: "7PX",
                        paddingBottom: "7px",
                      }}
                    >
                      {userInfo.mobile}
                    </HelveticaBold>
                  )}
                </div>
              </div>
              <div className="inline" style={{ width: "85%" }}>
                <div style={{ width: "45%", marginRight: "20px" }}>
                  <HelveticaBold
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("CIF")}
                  </HelveticaBold>
                  {!userInfoSaved || showOrgInputs ? (
                    <HelveticaInput
                      style={{ width: "100%" }}
                      placeholder={"B-XXXXXXXX"}
                      name="cif"
                      value={userInfo.cif}
                      onChange={handleChange}
                      ref={refs4UserInfo[2]}
                    ></HelveticaInput>
                  ) : (
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#414141",
                        fontWeight: "400",
                        paddingTop: "7PX",
                        paddingBottom: "7px",
                      }}
                    >
                      {userInfo.cif}
                    </HelveticaBold>
                  )}
                </div>
                <div style={{ width: "45%" }}>
                  <HelveticaBold
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("Página Web")}
                  </HelveticaBold>
                  {!userInfoSaved || showOrgInputs ? (
                    <HelveticaInput
                      style={{ width: "100%" }}
                      placeholder={"Introduce la página web de tu empresa"}
                      name="web"
                      value={userInfo.web}
                      onChange={handleChange}
                      ref={refs4UserInfo[3]}
                    ></HelveticaInput>
                  ) : (
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#414141",
                        fontWeight: "400",
                        paddingTop: "7PX",
                        paddingBottom: "7px",
                      }}
                    >
                      {userInfo.web}
                    </HelveticaBold>
                  )}
                </div>
              </div>
              <div className="inline" style={{ width: "85%" }}>
                <div style={{ width: "45%" }}>
                  <HelveticaBold
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("Dirección")}
                  </HelveticaBold>
                  {!userInfoSaved || showOrgInputs ? (
                    <HelveticaInput
                      style={{ width: "100%" }}
                      placeholder={"Introduce la dirección de tu empresa"}
                      name="direccion"
                      type="mail"
                      value={userInfo.direccion}
                      onChange={handleChange}
                      ref={refs4UserInfo[4]}
                    ></HelveticaInput>
                  ) : (
                    <HelveticaBold
                      style={{
                        fontSize: "14px",
                        marginBottom: "5px",
                        color: "#414141",
                        fontWeight: "400",
                        paddingTop: "7PX",
                        paddingBottom: "7px",
                      }}
                    >
                      {userInfo.direccion}
                    </HelveticaBold>
                  )}
                </div>
              </div>
              {loading == true ? (
                <CircularProgress
                  style={{
                    position: "absolute",
                    right: "30px",
                    bottom: "25px",
                  }}
                  size={24}
                />
              ) : !userInfoSaved || showOrgInputs ? (
                <YellowButton
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="SaveCompanyIdentity"
                  data-amplify-analytics-attrs=""
                  style={{
                    position: "absolute",
                    right: "30px",
                    width: "130px",
                    bottom: "25px",
                    height: "35px",
                    cursor: userInfoSaved && "default",
                    backgroundColor: userInfoSaved && "#E4E4E4",
                  }}
                  onClick={e => {
                    userInfoSaved ? null : handleSubmit1(e)
                  }}
                >
                  {i18n.t("Guardar")}
                </YellowButton>
              ) : (
                <EditOutlinedIcon
                  style={{
                    position: "absolute",
                    right: "30px",
                    bottom: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUserInfoSaved(false)
                  }}
                />
              )}
            </CardPricing>

            {
              //SEGUNDO COMPONENTE
            }
          </Grid>
          {/*
              <Grid item xs={12} spacing={3} style={{paddingLeft:'12px', cursor:'pointer', height:'370px', marginTop:'20px', position:'relative'}}>
              <CardPricing style={{width:'auto', height:'220px', minWidth:'520px', maxWidth:'908px',paddingLeft:'16px', paddingTop:'9px', position:'relative', cursor:'default'}}>
               
              <HelveticaBold style={{color:'#414141', fontSize:'20px'}}>{i18n.t("Método de pago")}</HelveticaBold>
                
                  <div className="inline" style={{width:'85%'}}>
                    <div style={{width:'45%', marginRight:'20px'}}>
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("Titular de la cuenta")}</HelveticaBold>
                    {!languageOptionsSaved || showAccountInputs?
                    <HelveticaInput style={{width:'100%'}}
                    placeholder={"Introduce el nombre del titular de la cuenta"}
                    name='titular'
                    value={languageOptions.titular}
                    onChange={(e)=>handleChange2(e)}
                    ref={refs4Address[0]}
                    ></HelveticaInput>
                    :
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{languageOptions.titular}</HelveticaBold>
                    }
                    </div>
                    <div style={{width:'45%'}}>
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("Pago al contado")}</HelveticaBold>
                    {!languageOptionsSaved || showAccountInputs?
                   <DropdownPagoAlContado ref={refs4Address[1]}  value={pagoRecurrenteValue} onChange={changeHandler2} name='pagoRecurrente'/>
                    :
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{pagoRecurrenteValue.label}</HelveticaBold>
                    }
                    </div>
                  </div>
        
                  <div className="inline" style={{width:'85%'}}>
                    <div style={{width:'45%', marginRight:'20px'}}>
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141'}}>{i18n.t("IBAN")}</HelveticaBold>
                    {!languageOptionsSaved || showAccountInputs?
                    <HelveticaInput style={{width:'100%'}}
                    placeholder={"ES00 0000 0000 0000 0000 0000"}
                    name='iban'
                    value={languageOptions.iban}
                    onChange={(e)=>handleChange2(e)}
                    ref={refs4Address[2]}
                    ></HelveticaInput>
                    :
                    <HelveticaBold style={{fontSize:'14px', marginBottom:'5px', color:'#414141', fontWeight:'400', paddingTop:'7PX', paddingBottom:'7px'}}>{languageOptions.iban}</HelveticaBold>
                    }
                    </div>
                  
                  </div>
                  {loading2 == true ?
                  <CircularProgress style={{position:'absolute', right:'20px', bottom:'20px'}} size={24}/> :
                  !languageOptionsSaved || showAccountInputs?
                <YellowButton 
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="SaveCompanyIdentity"
                data-amplify-analytics-attrs=""
                style={{position:'absolute', right:'20px', width:'130px', bottom:'20px', height:'35px', cursor:languageOptionsSaved && 'default', backgroundColor: languageOptionsSaved && '#E4E4E4'}}
                onClick={(e) => {
                    languageOptionsSaved ? null : handleSubmit2(e)
                }}
                >
                  {i18n.t("Guardar")}
                </YellowButton>
                :
                <EditOutlinedIcon style={{position:'absolute', right:'20px', bottom:'20px', cursor:'pointer'}} onClick={()=>{setLanguajeOptionsSaved(false)}}/>
                }
              
              </CardPricing>
              
               </Grid>
               */}
        </Grid>

        <Grid
          container
          style={{
            backgroundColor: "transparent",
            height: "auto",
            marginLeft: "20px",
            marginRight: "20px",
            marginBottom: "50px",
          }}
          direction="row"
          spacing={1}
        >
          <Grid
            item
            xs={12}
            style={{
              paddingLeft: "12px",
              cursor: "pointer",
              width: "100%",
              position: "relative",
            }}
          >
            <CardPricing
              style={{
                width: "100%",
                height: !showDropZone ? "295px" : "543px",
                maxWidth: "908px",
                position: "relative",
                minWidth: "520px",
                paddingLeft: "16px",
                paddingTop: "9px",
                cursor: "default",
              }}
            >
              <HelveticaBold style={{ color: "#414141", fontSize: "20px" }}>
                {i18n.t("Personalización de PDF")}
              </HelveticaBold>

              <div className="inline" style={{ width: "85%" }}>
                <div style={{ width: "90%", marginRight: "20px" }}>
                  <HelveticaBold
                    style={{
                      fontSize: "12px",
                      marginBottom: "10px",
                      marginRight: "-80PX",
                      color: "#414141",
                      fontWeight: "400",
                      marginTop: "-10px",
                      cursor: "DEFAULT",
                    }}
                  >
                    {i18n.t(
                      "Esta información permanece activa para todos los proyectos, en caso de querer personalizarla para un caso concreto puedes hacerlo desde dentro del mismo presupuesto",
                    )}
                  </HelveticaBold>
                  <HelveticaBold
                    ref={referenciasIdentity[0]}
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("Logotipo")}
                  </HelveticaBold>
                  <HelveticaBold
                    onClick={() => {
                      setShowDropZone(true)
                    }}
                    style={{
                      fontSize: "12px",
                      marginBottom: "21px",
                      color: "#C4C4C4",
                      fontWeight: "400",
                      marginTop: "10px",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    {i18n.t(
                      "Subir en formato archivo PNG o JPG, tamaño máx. 1200x600 px.",
                    )}
                  </HelveticaBold>

                  {showDropZone ? (
                    logo === null ? (
                      <div style={{ marginBottom: "40px" }}>
                        <ImageDropZone
                          selectedFiles={selectedFiles}
                          setselectedFiles={setselectedFiles}
                          setImage={setLogo}
                        />
                      </div>
                    ) : (
                      <>
                        <HelveticaBold
                          onClick={() => {
                            setOpenIntructions(true)
                          }}
                          style={{
                            fontWeight: "600",
                            textDecoration: "underline",
                            margin: "0px",
                            color: "#41414190",
                            cursor: "pointer",
                            fontSize: "12px",
                          }}
                        >
                          Si el logotipo se ve borroso haz click aquí
                        </HelveticaBold>
                        <img
                          style={{
                            maxWidth: "320px",
                            maxHeight: "160px",
                            marginBottom: "50px",
                            marginTop: "20px",
                          }}
                          src={logo}
                        />
                        <CloseOutlined
                          onClick={() => {
                            setselectedFiles([])
                            setLogo(null)
                            setOrgIdentitySaved(false)
                          }}
                          style={{
                            width: "15px",
                            marginTop: "-2px",
                            cursor: "pointer",
                          }}
                        ></CloseOutlined>
                      </>
                    )
                  ) : null}
                </div>
              </div>

              <div className="inline" style={{ width: "85%" }}>
                <div style={{ width: "60%", marginRight: "20px" }}>
                  <HelveticaBold
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("Color de Fondo de Capítulos y Resumen")}
                  </HelveticaBold>
                  <div className="inline" style={{ marginBottom: "10px" }}>
                    <div
                      style={{
                        backgroundColor:
                          primaryColor == "" ? "#C4C4C466" : primaryColor,
                        minWidth: "35px",
                        borderRadius: "6px",
                        height: "35px",
                        marginRight: "10px",
                      }}
                    ></div>
                    <FakeInput
                      ref={referenciasIdentity[1]}
                      onClick={handleClick1}
                      style={{ height: "35px" }}
                    >
                      <HelveticaLight
                        style={{
                          marginTop: "8px",
                          marginLeft: "12px",
                          textTransform: "uppercase",
                          color:
                            primaryColor.toString() == ""
                              ? "#c4c4c4"
                              : "#414141",
                        }}
                      >
                        {primaryColor.toString() == ""
                          ? "#XXXXXX"
                          : primaryColor.toString()}
                      </HelveticaLight>
                    </FakeInput>
                    {displayPicker ? (
                      <>
                        <div
                          style={{
                            width: "100%",
                            height: "20px",
                            backgroundColor: "white",
                            borderRadius: "0px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        ></div>
                        <div
                          ref={refpicker}
                          tabIndex="0"
                          onBlur={e => {
                            if (e.currentTarget.contains(e.relatedTarget)) {
                              null
                            } else {
                              setDisplayPicker(false)
                            }
                          }}
                          style={{ position: "absolute", width: "50%" }}
                        >
                          <SketchPicker
                            width="auto"
                            height="20px"
                            position="relative!important"
                            color={primaryColor}
                            onChangeComplete={handleChangeComplete}
                            disableAlpha={true}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="inline" style={{ width: "85%" }}>
                <div style={{ width: "60%", marginRight: "20px" }}>
                  <HelveticaBold
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("Color de la fuente")}
                  </HelveticaBold>
                  <div className="inline">
                    <div
                      style={{
                        backgroundColor:
                          secondaryColor == "" ? "#C4C4C466" : secondaryColor,
                        minWidth: "35px",
                        borderRadius: "6px",
                        height: "35px",
                        marginRight: "10px",
                      }}
                    ></div>
                    <FakeInput
                      ref={referenciasIdentity[2]}
                      onClick={handleClick2}
                      style={{ height: "35px" }}
                    >
                      <HelveticaLight
                        style={{
                          marginTop: "8px",
                          marginLeft: "12px",
                          textTransform: "uppercase",
                          color:
                            secondaryColor.toString() == ""
                              ? "#c4c4c4"
                              : "#414141",
                        }}
                      >
                        {secondaryColor.toString() == ""
                          ? "#XXXXXX"
                          : secondaryColor.toString()}
                      </HelveticaLight>
                    </FakeInput>
                    {displayPicker2 ? (
                      <>
                        <div
                          style={{
                            width: "100%",
                            height: "20px",
                            backgroundColor: "white",
                            borderRadius: "0px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        ></div>
                        <div
                          ref={refpicker2}
                          tabIndex="0"
                          onBlur={e => {
                            if (e.currentTarget.contains(e.relatedTarget)) {
                              null
                            } else {
                              setDisplayPicker2(false)
                            }
                          }}
                          style={{ position: "absolute", width: "50%" }}
                        >
                          <SketchPicker
                            width="auto"
                            height="20px"
                            position="relative!important"
                            color={secondaryColor}
                            onChangeComplete={handleChangeComplete2}
                            disableAlpha={true}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              {loading == true ? (
                <CircularProgress
                  style={{
                    position: "absolute",
                    right: "30px",
                    bottom: "25px",
                  }}
                  size={24}
                />
              ) : !orgIdentitySaved || showIdentityInputs ? (
                <YellowButton
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="SaveCompanyIdentity"
                  data-amplify-analytics-attrs=""
                  style={{
                    position: "absolute",
                    right: "30px",
                    width: "130px",
                    bottom: "25px",
                    height: "35px",
                    cursor: orgIdentitySaved && "default",
                    backgroundColor: orgIdentitySaved && "#E4E4E4",
                  }}
                  onClick={e => {
                    orgIdentitySaved ? null : handleSubmit3(e)
                  }}
                >
                  {i18n.t("Guardar")}
                </YellowButton>
              ) : (
                <EditOutlinedIcon
                  style={{
                    position: "absolute",
                    right: "30px",
                    bottom: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOrgIdentitySaved(false)
                  }}
                />
              )}
            </CardPricing>
          </Grid>
        </Grid>
      </div>

      {openInstructions && (
        <ModalInstructions
          open={openInstructions}
          setOpen={setOpenIntructions}
        />
      )}
    </div>
  )
}

export default GeneralComponent
