import React, { useState } from "react"
import propTypes from "prop-types"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import {
  CapituloContainer,
  SubChapterContainer,
  ShowChildDiv,
  ShowChildDivWithBorder,
} from "common/containers"
import { PoppinsNormal } from "common/typographies"
import { currencyFormatDE } from "lib/transformers"
import PartTableAuto from "../../part/selfManagement/indexAuto"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext"

const CapituloAutoGestion = props => {
  const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
  const handleOpen = () => {
    setOpenNotSendCapitulo(prev => !prev)
  }

  const { user } = useContext(AuthContext)
  return (
    <div>
      {props.capitulo.parentId == "root" ||
      props.capitulo.capituloInfo.parentId === "root" ? (
        <div>
          <CapituloContainer>
            <div
              style={{
                height: "44px",
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                borderRadius: "6px",
                paddingTop: "12px",
                background: "#FFE088",
              }}
            >
              {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
                    onClick={handleOpen}
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
                  onClick={handleOpen}
                />
              )}
              <PoppinsNormal>{props.capitulo.capituloInfo.name}</PoppinsNormal>
              <div
                style={{ display: "flex", position: "absolute", right: "0.7%" }}
              >
                <div id="precioTotal" style={{ marginTop: "-3px" }}>
                  {currencyFormatDE(
                    props.capitulo.total,
                    getCurrency(user["custom:currency"]),
                  )}
                </div>
              </div>
            </div>
          </CapituloContainer>
          {openNotSendCapitulo ? (
            <ShowChildDiv
              style={{
                width: "98%",
                marginTop: props.capitulo.partidas?.length ? "-8px" : "-14px",
                background: "#FFF3D0",
              }}
            >
              {props.capitulo.partidas?.length ? (
                <PartTableAuto
                  parts={props.capitulo.partidas}
                  total={props.capitulo.total}
                  capitulo={props.capitulo}
                />
              ) : props.capitulo.subcapitulos?.length ? (
                props.capitulo.subcapitulos.map((capitulo, i) => (
                  <CapituloAutoGestion
                    key={capitulo.chapterId}
                    i={i}
                    capitulo={capitulo}
                    imParent={(props.capitulo.parentId = "root" ? true : false)}
                  />
                ))
              ) : null}
            </ShowChildDiv>
          ) : null}
        </div>
      ) : (
        //im a subchapter  <SubChapterContainer> --> 'className='NewSubCapi' in budgeting.css
        <div>
          <SubChapterContainer
            style={{
              borderLeft: props.imParent ? "6px solid #D8AA28" : "0px",
              background: "#FFE088",
            }}
          >
            <div
              style={{
                minWidth: "37px",
                paddingLeft: "3px",
                display: "flex",
                position: "relative",
                paddingTop: "11px",
              }}
            >
              {openNotSendCapitulo ? (
                <div>
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    className="dropdownArrowB"
                    onClick={handleOpen}
                  />
                </div>
              ) : (
                <KeyboardArrowRightIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
                  onClick={handleOpen}
                />
              )}
              <PoppinsNormal>{props.capitulo.capituloInfo.name}</PoppinsNormal>
              <div
                style={{ display: "flex", position: "absolute", right: "0.7%" }}
              >
                <div id="precioTotal" style={{ marginTop: "-3px" }}>
                  {currencyFormatDE(
                    props.capitulo.total,
                    getCurrency(user["custom:currency"]),
                  )}
                </div>
              </div>
            </div>
          </SubChapterContainer>

          {openNotSendCapitulo ? (
            props.imParent ? (
              <ShowChildDivWithBorder
                style={{
                  paddingLeft: props.capitulo.partidas?.length ? "0px" : "8px",
                  borderColor:
                    "rgb(245, 245, 245) rgb(245, 245, 245) rgb(245, 245, 245) #D8AA28",
                }}
              >
                {props.capitulo.partidas?.length ? (
                  <PartTableAuto
                    parts={props.capitulo.partidas}
                    imParent={props.imParent ? props.imParent : false}
                    total={props.capitulo.total}
                    capitulo={props.capitulo}
                  />
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <CapituloAutoGestion
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                    />
                  ))
                ) : null}
              </ShowChildDivWithBorder>
            ) : (
              <ShowChildDiv style={{ backgroundColor: "white" }}>
                {props.capitulo.partidas?.length ? (
                  <PartTableAuto
                    parts={props.capitulo.partidas}
                    total={props.capitulo.total}
                    capitulo={props.capitulo}
                  />
                ) : props.capitulo.subcapitulos?.length ? (
                  props.capitulo.subcapitulos.map((capitulo, i) => (
                    <CapituloAutoGestion
                      key={capitulo.chapterId}
                      i={i}
                      capitulo={capitulo}
                    />
                  ))
                ) : null}
              </ShowChildDiv>
            )
          ) : null}
        </div>
      )}
    </div>
  )
}

CapituloAutoGestion.propTypes = {
  key: propTypes.string.isRequired,
  i: propTypes.number.isRequired,
  capitulo: propTypes.object.isRequired,
  imParent: propTypes.bool,
}

export default CapituloAutoGestion
