import React, { useState, useEffect, useContext } from "react"
import { ChapterContainer } from "common/containers"
import { ChapterTypo, PoppinsNormal } from "common/typographies"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import i18n from "i18n"
import { ShowParentDiv } from "common/containers"
import ProposalContext from "context/proposal/proposalContext"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { useParams } from "react-router-dom"
import AcceptedProposals from "./chapters/acceptedProposal"

const QuotedBudget = () => {
  const { projectId } = useParams()
  const [openNotSendBudget, setOpenNotSendBudget] = useState(false)
  const handleOpen = () => {
    setOpenNotSendBudget(prev => !prev)
  }
  const {
    getTablaComparativa,
    loadingTablaComparativa,
    tablaComparativa,
    getGroupInfo,
    proposalsGroup,
    loading,
  } = useContext(ProposalContext)
  useEffect(() => {
    getGroupInfo(projectId)
  }, [])
  return (
    <>
      <ChapterContainer
        style={{
          backgroundColor: "#147DB9",
          color: "white",
          borderLeft: "7px solid #147DB9",
        }}
      >
        <tr style={{ marginTop: "0px" }}>
          <td style={{ minWidth: "37px", paddingLeft: "3px" }}>
            {openNotSendBudget ? (
              <div>
                <KeyboardArrowDownIcon
                  style={{ cursor: "pointer", marginLeft: "3px" }}
                  className="dropdownArrowB"
                  onClick={handleOpen}
                />
              </div>
            ) : (
              <KeyboardArrowRightIcon
                style={{ cursor: "pointer", marginLeft: "3px" }}
                className="dropdownArrowB"
                onClick={handleOpen}
              />
            )}
          </td>
          <td style={{ minWidth: "300px" }}>
            <ChapterTypo style={{ marginLeft: "0px", color: "white" }}>
              {i18n.t("PRESUPUESTOS ACEPTADOS")}
            </ChapterTypo>
          </td>
        </tr>
      </ChapterContainer>
      {openNotSendBudget ? (
        loading ? (
          <>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-100px",
                marginTop: "70px",
              }}
            >
              <div className="loader">
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-30px",
              }}
            >
              <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-30px",
                marginTop: "-15px",
              }}
            >
              <PoppinsNormal style={{ fontWeight: "400" }}>
                {i18n.t("Esta acción puede tardar unos segundos")}
              </PoppinsNormal>
            </div>
          </>
        ) : proposalsGroup.length > 0 ? (
          <ShowParentDiv
            style={{
              backgroundColor: "rgba(88, 136, 163, 0.1)",
              borderLeft: "7px solid #147DB9",
              marginTop: "-19.5px",
            }}
          >
            {proposalsGroup.map((item, index) => {
              return <AcceptedProposals key={index} group={item} />
            })}
          </ShowParentDiv>
        ) : (
          <div
            style={{
              backgroundColor: "#ECFCF9",
              paddingLeft: "35px",
              height: "33px",
              width: "100%",
              borderRadius: "6px",
              marginTop: "-16px",
              paddingTop: "6px",
              fontFamily: "Roboto",
              fontWeight: "300",
              fontSize: "12px",
            }}
          >
            {" "}
            {i18n.t("Todavía no hay propuestas aceptadas")}{" "}
          </div>
        )
      ) : null}
    </>
  )
}

export default QuotedBudget
