import React, { useState, useContext, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Grid, Alert, Snackbar } from "@mui/material"
import Budget2023Context from "context/budget2023/Budget2023Context"
import Board, {
  addCard,
  addColumn,
  moveCard,
  removeCard,
  removeColumn,
} from "@asseinfo/react-kanban"
import "@asseinfo/react-kanban/dist/styles.css"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import PlanningContext from "context/planning/planningContext"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditIcon from "@mui/icons-material/Edit"
import UpdateKanbanModal from "./modals/updateKanbanModal"
import ModalPlanningTaskDetails from "./modals/modalPlanningTaskDetails"
import { PoppinsNormal } from "common/typographies"
import i18n from "i18n"

const PlanningKanban = ({ projectInfo }) => {
  const { getBudgetTree, budgetTree, loadingTree, closeBudget, readyToClose } =
    useContext(Budget2023Context)
  const {
    getTasksByProject,
    tasks,
    addPlanningColumn,
    delPlanningColumn,
    updatePlanningTask,
    filtro,
  } = useContext(PlanningContext)
  const colors = [
    "#15B3D6",
    "#8758E9",
    "#E82D2D",
    "#E2E800",
    "#E95EC2",
    "#54C16C",
    "#FFC727",
  ]
  const [open, setOpen] = useState(false)
  const [openToEdit, setOpenToEdit] = useState(false)
  const [openDetails, setOpenDetails] = useState(false)

  const [capitulosFiltrados, setCapitulosFiltrados] = useState([])

  const initialBoard = {
    columns: [
      {
        id: 1,
        title: "Backlog",
        color: colors[0],
        cards: [
          {
            id: 1,
            title: "Add card",
            description: "Add capability to add a card in a column",
          },
        ],
      },
    ],
  }

  useEffect(() => {
    getBudgetTree(projectInfo.projectId)
    getTasksByProject(projectInfo.projectId)
  }, [])

  const handleClose = () => {
    setOpen(false)
  }
  const [infoCol, setInfoCol] = useState({ title: "", color: "#15B3D6" })
  const [infoCard, setInfoCard] = useState({ title: "", description: "" })

  const [dragState, setDragState] = useState({})

  useEffect(() => {
    if (openToEdit == false) {
      setInfoCol({ title: "", color: "#15B3D6" })
    }
  }, [openToEdit])

  useEffect(() => {
    if (openDetails == false) {
      setInfoCard({ title: "", description: "" })
    }
  }, [openDetails])

  const [noAct, setNoAct] = useState(false)

  function ControlledBoard() {
    const [controlledBoard, setBoard] = useState(initialBoard)
    const [lastId, setLastId] = useState(0)

    useEffect(() => {
      if (filtro.length == 0) {
        var aux = { columns: [] }
        var id = 1
        for (let i = 0; i < tasks.length; i++) {
          var cardsAux = []
          for (let j = 0; j < tasks[i].cards.length; j++) {
            cardsAux.push(tasks[i].cards[j])
            cardsAux[j] = { ...cardsAux[j], id: id, colId: i, position: j }
            id = id + 1
          }
          aux.columns.push({
            cards: cardsAux,
            bgColor: tasks[i].bgColor,
            color: tasks[i].color,
            idBackEnd: tasks[i].id,
            id: i + 1,
            title: tasks[i].title,
          })
        }
        setLastId(id)
        setBoard(aux)
        setCols(controlledBoard.columns.length)
      } else {
        var aux = { columns: [] }
        var id = 1
        var filtroFast = filtro
        for (let i = 0; i < tasks.length; i++) {
          var cardsAux = []
          let pos = 0
          for (let j = 0; j < tasks[i].cards.length; j++) {
            let trobat = false
            let z = 0
            while (!trobat && z < filtroFast.length) {
              if (filtroFast[z] == tasks[i].cards[j].taskId) {
                cardsAux.push({
                  ...tasks[i].cards[j],
                  id: id,
                  colId: i,
                  position: pos,
                })
                pos = pos + 1
                id = id + 1
                //filtroFast.splice(z, 1)
                trobat = true
              } else {
                z++
              }
            }
          }
          aux.columns.push({
            cards: cardsAux,
            bgColor: tasks[i].bgColor,
            color: tasks[i].color,
            idBackEnd: tasks[i].id,
            id: i + 1,
            title: tasks[i].title,
          })
        }
        for (let a = 0; a < aux.columns.length; a++) {
          for (let b = 0; b < aux.columns[a].cards.length; b++) {
            if (aux.columns[a].cards[b]) {
              if (!aux.columns[a].cards[b].partInfo) {
                aux.columns[a].cards.splice(b, 1)
              }
            }
          }
        }
        setLastId(id)
        setBoard(aux)
        setCols(controlledBoard.columns.length)
      }
    }, [tasks])

    const [numberofCols, setCols] = useState(controlledBoard.columns.length)
    useEffect(() => {
      setCols(controlledBoard.columns.length)
    }, [controlledBoard])
    const [col, setCol] = useState(false)

    const addColumna = e => {
      let aux = numberofCols
      while (aux > 7) {
        aux = aux - 7
      }
      e.preventDefault()
      const newColumn = {
        id: numberofCols + 1,
        title: tituloCol,
        color: aux % 7 == 0 ? colors[0] : colors[aux],
        cards: [],
        bgColor: aux % 7 == 0 ? colors[0] : colors[aux],
      }

      setLastId(lastId + 1)
      setCols(numberofCols + 2)
      setBoard(addColumn(controlledBoard, newColumn))
      setCol(false)
      setTituloCol("")
      addPlanningColumn(newColumn, projectInfo.projectId)
    }
    const [contadorNuevo, setContadorNuevo] = useState(0)
    const [titulo, setTitulo] = useState("")
    const referenciaTitulo = useRef(null)
    useEffect(() => {
      if (referenciaDescripcion.current != null && contadorNuevo != 0) {
        referenciaDescripcion.current.focus()
      }
    }, [contadorNuevo])
    const [descripcion, setDescripcion] = useState("")
    const referenciaDescripcion = useRef(null)

    const [renderCreate, setRenderCreate] = useState(false)
    const [tituloCol, setTituloCol] = useState("")
    const handleTituloCol = e => {
      setTituloCol(e.target.value)
    }
    const addCarta = columna => {
      setRenderCreate(true)
      let trobat = false
      let i = 0
      while (i < controlledBoard.columns.length && !trobat) {
        if (controlledBoard.columns[i].id == columna) {
          trobat = true
        }
        i = i + 1
      }

      const handleChangeTitulo = () => {
        setTitulo(referenciaTitulo.current.innerHTML)
      }
      const handleChangeDescripcion = () => {
        setDescripcion(referenciaDescripcion.current.innerHTML)
      }
      const idCarta = lastId

      const newCard = {
        id: idCarta,
        title: "",
        description: (
          <p
            style={{
              display: "block",
              textAlign: "left",
              marginBottom: "0px",
              height: "100%",
            }}
          >
            {" "}
            <span
              className="textareaExpand3"
              role="textbox"
              style={{ padding: "0", content: "h" }}
              autoComplete="off"
              name="descripcion"
              ref={referenciaDescripcion}
              onKeyDown={(event, id, col) =>
                handleEnterDescription(
                  event,
                  idCarta,
                  controlledBoard.columns[i - 1],
                )
              }
              onInput={handleChangeDescripcion}
              onBlur={(e, id, col) => {
                isCreated(e, idCarta, controlledBoard.columns[i - 1])
              }}
              contentEditable="true"
            >
              {descripcion}
            </span>
          </p>
        ),
      }
      setBoard(
        addCard(controlledBoard, controlledBoard.columns[i - 1], newCard),
      )
      setContadorNuevo(contadorNuevo + 1)
      setLastId(lastId + 1)
    }
    const isCreated = (e, id, col) => {
      if (referenciaDescripcion.current.innerHTML == "") {
        setBoard(removeCard(controlledBoard, col, id))
        setLastId(lastId - 1)
        setRenderCreate(false)
      } else {
        const newCard = {
          id: id,
          title: "",
          description: referenciaDescripcion.current.innerHTML,
        }
        setBoard(removeCard(controlledBoard, col, id))
        setBoard(addCard(controlledBoard, col, newCard))
        setRenderCreate(false)
      }
      setDescripcion("")
    }

    const handleDelete = id => {
      if (controlledBoard.columns[id - 1].cards.length > 0) {
        setOpen(true)
      } else {
        delPlanningColumn(
          projectInfo.projectId,
          controlledBoard.columns[id - 1].idBackEnd,
        )
      }
    }

    function handleCardMove(_card, source, destination) {
      setNoAct(true)
      const updatedBoard = moveCard(controlledBoard, source, destination)
      let aux = updatedBoard
      let auxCard =
        aux.columns[destination.toColumnId - 1].cards[destination.toPosition]
      aux.columns[destination.toColumnId - 1].cards[destination.toPosition] = {
        ...auxCard,
        status: destination.toColumnId,
        colId: destination.toColumnId - 1,
      }
      updatePlanningTask(
        aux.columns[destination.toColumnId - 1].cards[destination.toPosition],
        projectInfo.projectId,
        source,
        destination,
      )
    }
    const handleEnter = async event => {
      if (event.key.toLowerCase() === "enter") {
        event.preventDefault()
        addColumna(event)
      }
    }
    const handleColumna = async e => {
      e.preventDefault()

      if (tituloCol == "") {
        setCol(false)
      } else {
        addColumna(e)
      }
    }
    const handleEnterDescription = async (event, id, col) => {
      if (event.key.toLowerCase() === "enter") {
        event.preventDefault()
        isCreated(event, id, col)
      }
    }

    return (
      <>
        <Board
          onCardDragEnd={handleCardMove}
          disableColumnDrag
          allowAddColum
          allowAddCard
          allowRemoveColumn
          renderColumnHeader={({ title, color, id }) => (
            <div
              className="react-kanban-column-header"
              style={{ borderTop: "10px solid" + color }}
            >
              {title}
              {renderCreate == false ? (
                <>
                  <EditIcon
                    style={{
                      float: "right",
                      marginRight: "5px",
                      width: "16px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setInfoCol(controlledBoard.columns[id - 1])
                      setOpenToEdit(true)
                    }}
                  />
                  <DeleteOutlineIcon
                    style={{
                      float: "right",
                      marginRight: "5px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(id)}
                  />
                  {/* 
            <AddIcon type='button' style={{float:'right', marginRight:'5px', width:'20px'}} onClick={()=>addCarta(id)}/>
           */}
                </>
              ) : null}
            </div>
          )}
          renderCard={(
            { title, description, colId, idCard, id, position },
            { removeCard, dragging },
          ) => (
            <div
              onClick={() => {
                setInfoCard(controlledBoard.columns[colId].cards[position])
                setOpenDetails(true)
              }}
              className="react-kanban-card"
              style={{ maxWidth: "260px" }}
            >
              {title == "" ? (
                <p></p>
              ) : (
                <p
                  style={{
                    display: "block",
                    textAlign: "left",
                    marginBottom: "0px",
                    height: "100%",
                  }}
                >
                  {" "}
                  <span
                    className="textareaExpand3"
                    role="textbox"
                    style={{
                      padding: "0",
                      content: "h",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#414141",
                      marginBottom: "10px",
                    }}
                    autoComplete="off"
                    name="title2"
                    contentEditable="false"
                  >
                    {title}
                  </span>
                </p>
              )}

              <p
                style={{
                  display: "block",
                  textAlign: "left",
                  marginBottom: "0px",
                  height: "100%",
                }}
              >
                {" "}
                <span
                  className="textareaExpand3"
                  role="textbox"
                  style={{ padding: "10", content: "h", cursor: "pointer" }}
                  autoComplete="off"
                  name="description2"
                  contentEditable="false"
                >
                  {description}
                </span>
              </p>
            </div>
          )}
        >
          {controlledBoard}
        </Board>
        {col == false ? (
          <div
            onClick={() => setCol(true)}
            style={{
              marginTop: "25px",
              marginLeft: "15px",
              opacity: "0.7",
              color: "#B7B7B7",
              cursor: "pointer",
              minWidth: "260px",
              marginRight: "25px",
            }}
            className="react-kanban-column-header"
          >
            + {i18n.t("AÑADIR COLUMNA")}
          </div>
        ) : (
          <div
            style={{
              marginTop: "25px",
              marginLeft: "15px",
              opacity: "1",
              color: "#B7B7B7",
            }}
            className="react-kanban-column-header"
          >
            <input
              autoFocus
              onKeyDown={handleEnter}
              onChange={e => {
                handleTituloCol(e)
              }}
              style={{
                marginTop: "-15px",
                height: "32px",
                width: "260px",
                marginLeft: "-20px",
                border: "1px solid #FFD52B",
                paddingRight: "50px",
                fontFamily: "Helvetica",
                fontWeight: "300",
                fontSize: "14px",
                color: "#414141",
              }}
              type="text"
              placeholder="Puedes introducir aqui el nombre"
            ></input>
            <CheckIcon
              style={{
                position: "absolute",
                color: "black",
                marginLeft: "-45px",
                width: "20px",
                cursor: "pointer",
              }}
              onClick={e => {
                handleColumna(e)
              }}
            />
            <CloseIcon
              style={{
                position: "absolute",
                color: "black",
                marginLeft: "-25px",
                width: "20px",
                cursor: "pointer",
              }}
              onClick={() => {
                setCol(false)
              }}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {loadingTree ? (
        <>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-100px",
              marginTop: "70px",
            }}
          >
            <div className="loader">
              <div className="ball"></div>
              <div className="ball"></div>
              <div className="ball"></div>
            </div>
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-30px",
            }}
          >
            <PoppinsNormal>{i18n.t("Cargando...")}</PoppinsNormal>
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "flex",
              marginLeft: "-30px",
              marginTop: "-15px",
            }}
          >
            <PoppinsNormal style={{ fontWeight: "400" }}>
              {i18n.t("Esta acción puede tardar unos segundos")}
            </PoppinsNormal>
          </div>
        </>
      ) : (
        <>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              {i18n.t(
                "No puedes borrar esta columna ya que tiene tareas asociadas",
              )}
            </Alert>
          </Snackbar>
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ display: "flex" }}>
              {tasks ? <ControlledBoard /> : "Cargando..."}
            </Grid>
          </Grid>
          <UpdateKanbanModal
            open={openToEdit}
            setOpen={setOpenToEdit}
            infoCol={infoCol}
          />
          <ModalPlanningTaskDetails
            open={openDetails}
            setOpen={setOpenDetails}
            infoCard={infoCard}
          />
        </>
      )}
    </>
  )
}

PlanningKanban.propTypes = {
  projectInfo: PropTypes.object.isRequired,
}

export default PlanningKanban
