import React, { useContext, useRef, useEffect, useState } from "react"
import propTypes from "prop-types"
import i18n from "i18n"
import {
  HelveticaBold,
  HelveticaLight,
  PoppinsSemiBold,
} from "common/typographies"
import { FakeInput } from "common/containers"
import circlePlus from "assets/images/icons/circlePlus.svg"
import ModalSelectContact from "./modalSelectContact"
import AuthContext from "context/auth/authContext"
import { HelveticaInput } from "common/inputs"
import { BlackHoverButton4CreateDynamically } from "common/containers"
import AddIcon from "@mui/icons-material/Add"
import ModalSelectFiles from "./modalSelectFiles"
import { DeleteOutline } from "@mui/icons-material"
import excel from "assets/images/icons/excel.png"
import pdf from "assets/images/icons/pdf.png"
import jpg from "assets/images/icons/jpg.png"

const ThirdStep = ({ message, files, setMessage, setFiles }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <HelveticaBold
        style={{
          fontWeight: "400",
          fontSize: "14px",
          color: "#414141",
          marginTop: "40px",
        }}
      >
        {i18n.t(
          "Añade un mensaje y archivos adjuntos adicionales a tu factura",
        )}
      </HelveticaBold>
      <div>
        <textarea
          contentEditable="true"
          value={message}
          onChange={e => {
            setMessage(e.target.value)
          }}
          id="descripcionInput"
          name="mensaje"
          placeholder={i18n.t("Escribe un mensaje")}
          style={{
            marginLeft: "0px",
            marginRight: "30px",
            marginTop: "10px",
            width: "100%",
            minHeight: "250px",
            height: "75%",
            position: "relative",
            paddingTop: "10px",
            paddingLeft: "10px",
            resize: "none",
          }}
        ></textarea>

        {files.length > 0 ? (
          <div style={{ marginTop: "30px" }}>
            {files.map((file, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-20px",
                    padding: "0px",
                  }}
                >
                  {file.path.split(".").pop() === "pdf" ||
                  file.path.split(".").pop() === "PDF" ? (
                    <img
                      src={pdf}
                      style={{
                        width: "12px",
                        height: "16px",
                        marginRight: "7px",
                      }}
                    />
                  ) : file.path.split(".").pop() === "xlx" ||
                    file.path.split(".").pop() === "XLX" ||
                    file.path.split(".").pop() === "xlsx" ||
                    file.path.split(".").pop() === "XLSX" ? (
                    <img
                      src={excel}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "7px",
                      }}
                    />
                  ) : (
                    <img
                      src={jpg}
                      style={{
                        width: "13px",
                        height: "17px",
                        marginRight: "7px",
                      }}
                    />
                  )}

                  <HelveticaLight
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#414141",
                      marginRight: "10px",
                      paddingTop: "17px",
                    }}
                  >
                    {file.name}
                  </HelveticaLight>
                  <DeleteOutline
                    style={{
                      width: "17px",
                      height: "17px",
                      cursor: "pointer",
                      color: "BF2929",
                    }}
                    onClick={() => {
                      setFiles(
                        files.filter((file, i) => {
                          return i != index
                        }),
                      )
                    }}
                  />
                </div>
              )
            })}
          </div>
        ) : null}
        <BlackHoverButton4CreateDynamically
          onClick={() => {
            setOpen(true)
          }}
          style={{ marginTop: "15px", marginBottom: "10px" }}
        >
          <AddIcon
            style={{
              fontSize: "18px",
              marginRight: "5px",
              color: "#414141",
              marginLeft: "-5px",
              paddingTop: "1px",
            }}
          />
          <HelveticaBold
            style={{
              color: "#414141",
              fontWeight: "600",
              paddingTop: "1px",
              fontSize: "12px",
            }}
          >
            {i18n.t("Adjuntar archivo")}
          </HelveticaBold>
        </BlackHoverButton4CreateDynamically>
      </div>
      {open && (
        <ModalSelectFiles
          open={open}
          setOpen={setOpen}
          selectedFiles={files}
          setSelectedFiles={setFiles}
        />
      )}
    </>
  )
}

ThirdStep.propTypes = {
  message: propTypes.array.isRequired,
  files: propTypes.object.isRequired,
  setMessage: propTypes.func.isRequired,
  setFiles: propTypes.func.isRequired,
}

export default ThirdStep
