import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import InvoiceLoader from "./invoiceLoader"
import InvoiceSummary from "./invoiceSummary"
import InvoicePageHeader from "./invoicePageHeader"
import InvoicePageGeneralInfo from "./invoicePageGeneralInfo"
import ModalSearchParts from "components/Facturas/modals/modalSearchPart"
import InvoiceConceptHeader from "./invoiceConceptHeader"
import InvoiceConcept from "./invoiceConcept"
import { BlackHoverButton4CreateDynamically } from "common/containers"
import { HelveticaBold } from "common/typographies"
import AddIcon from "@mui/icons-material/Add"
import i18n from "i18n"
import { formatValue } from "react-currency-input-field"
import InvoicePageAdditionalData from "./invoicePageAdditionalData"

const InvoicePageContainer = ({ invoiceService }) => {
  const [selectedParts, setSelectedParts] = useState([])
  const [openModalSearchParts, setOpenModalSearchParts] = useState(false)
  const handleAddFromParts = index => {
    let oldTotal = invoiceService.invoice.total
    let oldSubtotal = invoiceService.invoice.subtotal
    let oldIva = invoiceService.invoice.iva
    let oldRecargo = invoiceService.invoice.recargo
    let oldRetention = invoiceService.invoice.retention
    let newConcepts = [...invoiceService.invoice.concepts]
    let tmpNewConcepts = []
    for (let part of selectedParts) {
      const newConcept = invoiceService.buildEmptyInvoiceConcept({
        invoice: invoiceService.invoice,
      })
      newConcept.description = part.name
      newConcept.quantity = {
        value: part.quantity.toFixed(2),
        float: part.quantity,
        formatted: formatValue({
          value: part.quantity.toFixed(2),
          locale: "es-ES",
          decimalSeparator: ",",
          groupSeparator: ".",
        }),
      }
      newConcept.price = {
        value: part.clientPrice.toFixed(2),
        float: part.clientPrice,
        formatted: formatValue({
          value: part.clientPrice.toFixed(2),
          locale: "es-ES",
          decimalSeparator: ",",
          groupSeparator: ".",
        }),
      }
      const { total, subtotal, iva, recargo, retention } =
        invoiceService.computeTaxes({
          concept: newConcept,
        })
      newConcept.total = total
      newConcept.subtotal = subtotal
      oldSubtotal += subtotal
      oldIva += iva
      oldRecargo += recargo
      oldRetention += retention
      oldTotal += total
      tmpNewConcepts.push(newConcept)
    }
    if (index === undefined) {
      newConcepts = [...newConcepts, ...tmpNewConcepts]
    } else {
      newConcepts = [
        ...newConcepts.slice(0, index),
        ...tmpNewConcepts,
        ...newConcepts.slice(index),
      ]
    }
    invoiceService.setInvoice({
      ...invoiceService.invoice,
      total: oldTotal,
      subtotal: oldSubtotal,
      iva: oldIva,
      recargo: oldRecargo,
      retention: oldRetention,
      concepts: newConcepts,
    })

    setOpenModalSearchParts(false)
  }
  const addEmptyConcept = () => {
    const newConcept = invoiceService.buildEmptyInvoiceConcept({
      invoice: invoiceService.invoice,
    })
    invoiceService.createConcept({
      concept: newConcept,
    })
  }

  return (
    <Fragment>
      {invoiceService.loading === true ? (
        <InvoiceLoader />
      ) : (
        <Fragment>
          <InvoicePageHeader
            invoiceService={invoiceService}
            localInvoice={invoiceService.localInvoice}
          />
          <InvoicePageGeneralInfo
            invoice={invoiceService.invoice}
            setInvoice={invoiceService.setInvoice}
            setOpenModalSearchParts={setOpenModalSearchParts}
            localInvoice={invoiceService.localInvoice}
            getIdentifierNumber={invoiceService.getIdentifierNumber}
            contactError={invoiceService.contactError}
            projectError={invoiceService.projectError}
          />
          <div
            style={{
              width: "96.4%",
              paddingBottom: "5px",
              height: "auto",
              backgroundColor: "#F8F8F8",
              marginTop: "10px",
              marginBottom: "10px",
              marginLeft: "30px",
              borderRadius: "6px",
            }}
          >
            <InvoiceConceptHeader />
            {invoiceService.invoice.concepts.map((concept, index) => {
              return (
                <InvoiceConcept
                  key={index}
                  concept={concept}
                  invoice={invoiceService.invoice}
                  setInvoice={invoiceService.setInvoice}
                  index={index}
                  invoiceService={invoiceService}
                  selectedParts={selectedParts}
                  setSelectedParts={setSelectedParts}
                  handleAddFromParts={handleAddFromParts}
                />
              )
            })}
            <BlackHoverButton4CreateDynamically
              onClick={() => {
                addEmptyConcept()
              }}
              style={{
                marginTop: "15px",
                marginBottom: "10px",
                marginLeft: "20px",
              }}
            >
              <AddIcon
                style={{
                  fontSize: "18px",
                  marginRight: "5px",
                  color: "#414141",
                  marginLeft: "-5px",
                  paddingTop: "1px",
                }}
              />
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontWeight: "600",
                  paddingTop: "1px",
                  fontSize: "12px",
                }}
              >
                {i18n.t("Nuevo Concepto")}
              </HelveticaBold>
            </BlackHoverButton4CreateDynamically>
          </div>
          <InvoicePageAdditionalData invoiceService={invoiceService} />
          <InvoiceSummary invoice={invoiceService.invoice} />
        </Fragment>
      )}
      {openModalSearchParts === true ? (
        <ModalSearchParts
          open={openModalSearchParts}
          setOpen={setOpenModalSearchParts}
          projectInfo={invoiceService.invoice.projectInfo}
          selectedParts={selectedParts}
          setSelectedParts={setSelectedParts}
          handleAddFromParts={handleAddFromParts}
        />
      ) : null}
    </Fragment>
  )
}

InvoicePageContainer.propTypes = {
  invoiceService: PropTypes.any,
}

export default InvoicePageContainer
