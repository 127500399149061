import React, { useContext, useState } from "react"
import propTypes from "prop-types"
import i18n from "i18n"
import {
  HelveticaBold,
  HelveticaLight,
  PoppinsSemiBold,
} from "common/typographies"
import { HelveticaInput } from "common/inputs"
import { BlackHoverButton4CreateDynamically } from "common/containers"
import AddIcon from "@mui/icons-material/Add"
import ModalSelectFiles from "./modalSelectFiles"
import { DeleteOutline } from "@mui/icons-material"
import excel from "assets/images/icons/excel.png"
import pdf from "assets/images/icons/pdf.png"
import jpg from "assets/images/icons/jpg.png"

const SecondStep = ({
  asunto,
  setAsunto,
  message,
  files,
  setMessage,
  setFiles,
  title,
  setTitle,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            padding: "10px",
            paddingLeft: "0px",
            marginBottom: "-10px",
            marginTop: "5px",
          }}
        >
          <PoppinsSemiBold style={{ fontSize: "14px" }}>
            {i18n.t("Asunto del email")}:
          </PoppinsSemiBold>
        </div>

        <HelveticaInput
          style={{
            marginTop: "-20px",
            width: "100%",
            paddingLeft: "10px",
            minWidth: "250px",
          }}
          placeholder={i18n.t("Solicitud de presupuesto Material")}
          value={asunto}
          onChange={e => {
            setAsunto(e.target.value)
          }}
        />

        <div
          style={{
            display: "flex",
            padding: "10px",
            paddingLeft: "0px",
            marginBottom: "-10px",
          }}
        >
          <PoppinsSemiBold style={{ fontSize: "14px" }}>
            {i18n.t("Titulo del archivo")}
          </PoppinsSemiBold>
        </div>

        <HelveticaInput
          style={{
            marginTop: "-20px",
            width: "100%",
            paddingLeft: "10px",
            minWidth: "250px",
          }}
          placeholder={i18n.t("Solicitud precio Planhopper")}
          value={title}
          onChange={e => {
            setTitle(e.target.value)
          }}
        />

        <HelveticaBold
          style={{
            fontWeight: "700",
            fontSize: "14px",
            color: "#414141",
            marginTop: "5px",
          }}
        >
          {i18n.t("Añade un mensaje y archivos adjuntos a tu prespuesto")}
        </HelveticaBold>

        <textarea
          contentEditable="true"
          value={message}
          onChange={e => {
            setMessage(e.target.value)
          }}
          id="descripcionInput"
          name="mensaje"
          placeholder={i18n.t("Escribe un mensaje")}
          style={{
            marginLeft: "0px",
            marginRight: "30px",
            marginTop: "0px",
            width: "100%",
            minHeight: "150px",
            height: "55%",
            position: "relative",
            paddingTop: "10px",
            paddingLeft: "10px",
            resize: "none",
          }}
        ></textarea>

        {files.length > 0 ? (
          <div style={{ marginTop: "30px" }}>
            {files.map((file, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-20px",
                    padding: "0px",
                  }}
                >
                  {file.path.split(".").pop() === "pdf" ||
                  file.path.split(".").pop() === "PDF" ? (
                    <img
                      src={pdf}
                      style={{
                        width: "12px",
                        height: "16px",
                        marginRight: "7px",
                      }}
                    />
                  ) : file.path.split(".").pop() === "xlx" ||
                    file.path.split(".").pop() === "XLX" ||
                    file.path.split(".").pop() === "xlsx" ||
                    file.path.split(".").pop() === "XLSX" ? (
                    <img
                      src={excel}
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "7px",
                      }}
                    />
                  ) : (
                    <img
                      src={jpg}
                      style={{
                        width: "13px",
                        height: "17px",
                        marginRight: "7px",
                      }}
                    />
                  )}

                  <HelveticaLight
                    style={{
                      fontSize: "13px",
                      fontWeight: "400",
                      color: "#414141",
                      marginRight: "10px",
                      paddingTop: "17px",
                    }}
                  >
                    {file.name}
                  </HelveticaLight>
                  <DeleteOutline
                    style={{
                      width: "17px",
                      height: "17px",
                      cursor: "pointer",
                      color: "BF2929",
                    }}
                    onClick={() => {
                      setFiles(
                        files.filter((file, i) => {
                          return i != index
                        }),
                      )
                    }}
                  />
                </div>
              )
            })}
          </div>
        ) : null}
        <BlackHoverButton4CreateDynamically
          onClick={() => {
            setOpen(true)
          }}
          style={{ marginTop: "5px", marginBottom: "10px" }}
        >
          <AddIcon
            style={{
              fontSize: "18px",
              marginRight: "5px",
              color: "#414141",
              marginLeft: "-5px",
              paddingTop: "1px",
            }}
          />
          <HelveticaBold
            style={{
              color: "#414141",
              fontWeight: "600",
              paddingTop: "1px",
              fontSize: "12px",
            }}
          >
            {i18n.t("Adjuntar archivo")}
          </HelveticaBold>
        </BlackHoverButton4CreateDynamically>
      </div>

      {open && (
        <ModalSelectFiles
          open={open}
          setOpen={setOpen}
          selectedFiles={files}
          setSelectedFiles={setFiles}
        />
      )}
    </>
  )
}

SecondStep.propTypes = {
  asunto: propTypes.array.isRequired,
  setAsunto: propTypes.func.isRequired,
  message: propTypes.object.isRequired,
  files: propTypes.object.isRequired,
  setMessage: propTypes.func.isRequired,
  setFiles: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
  setTitle: propTypes.func.isRequired,
}

export default SecondStep
