import React, { useContext, useRef, useEffect, useState } from "react"
import propTypes from "prop-types"
import i18n from "i18n"
import { HelveticaBold, PoppinsSemiBold } from "common/typographies"
import { FakeInput } from "common/containers"
import circlePlus from "assets/images/icons/circlePlus.svg"
import ModalSelectContact from "./modalSelectContact"
import AuthContext from "context/auth/authContext"
import { HelveticaInput } from "common/inputs"
import ProjectContext from "context/projects/projectContext"

const SecondStep = ({ asunto, title, setAsunto, setTitle, factura }) => {
  const { projectInfo } = useContext(ProjectContext)

  return (
    <>
      <HelveticaBold
        style={{
          fontWeight: "400",
          fontSize: "14px",
          color: "#414141",
          marginTop: "40px",
        }}
      >
        {i18n.t("Indica el asunto de tu mensaje")}
      </HelveticaBold>
      <div>
        <div
          style={{
            display: "flex",
            padding: "10px",
            paddingLeft: "0px",
            marginBottom: "-10px",
          }}
        >
          <PoppinsSemiBold style={{ fontSize: "14px" }}>
            {i18n.t("Asunto")}
          </PoppinsSemiBold>
        </div>

        <HelveticaInput
          style={{
            marginTop: "-20px",
            width: "100%",
            paddingLeft: "10px",
            minWidth: "250px",
          }}
          placeholder={factura ? factura.invoiceNumber : ""}
          value={asunto}
          onChange={e => {
            setAsunto(e.target.value)
          }}
        />
        <HelveticaBold
          style={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#414141",
            marginTop: "30px",
          }}
        >
          {i18n.t("Indica el nombre que le quieres poner a la factura")}
        </HelveticaBold>
        <div
          style={{
            display: "flex",
            padding: "10px",
            paddingLeft: "0px",
            marginBottom: "-10px",
          }}
        >
          <PoppinsSemiBold style={{ fontSize: "14px" }}>
            {i18n.t("Titulo del archivo")}
          </PoppinsSemiBold>
        </div>

        <HelveticaInput
          style={{
            marginTop: "-20px",
            width: "100%",
            paddingLeft: "10px",
            minWidth: "250px",
          }}
          placeholder={projectInfo ? projectInfo.name : ""}
          value={title}
          onChange={e => {
            setTitle(e.target.value)
          }}
        />
      </div>
    </>
  )
}

SecondStep.propTypes = {
  asunto: propTypes.array.isRequired,
  title: propTypes.object.isRequired,
  setAsunto: propTypes.func.isRequired,
  setTitle: propTypes.func.isRequired,
  factura: propTypes.object.isRequired,
}

export default SecondStep
