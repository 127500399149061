import React, { useContext, useEffect, useRef, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import ProjectContext from "context/projects/projectContext"
import CloseIcon from "@mui/icons-material/Close"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import authContext from "../../context/auth/authContext"
import { makeStyles } from "@material-ui/core/styles"
import { Autocomplete, Modal, TextField } from "@mui/material"
import PropTypes from "prop-types"
import AddContact from "../../components/Contacts/ContactTable/addContact"
import ModalImageProject from "./ModalImageProject"
import ContactContext from "context/contact/contactContext"
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp"
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp"
import i18n from "i18n"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "500px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const PersonalModal = ({
  modal_standard,
  imTemplate,
  setmodal_standard,
  setModalUpload,
  contactos,
  setSeleccionado,
  modal_first,
  setmodal_first,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const contactContext = useContext(ContactContext)
  const { Contacts, getContacts } = contactContext
  /*Funciones para las etiquetas*/
  const [input, setInput] = useState("")
  const [tags, setTags] = useState([])
  const [tagsFinal, setTagsFinal] = useState([])
  const [isKeyReleased, setIsKeyReleased] = useState(false)
  const [color, setColor] = useState(0)
  const listaColores = [
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
    "color7",
    "color8",
  ]
  const [openAdd, setOpenAdd] = useState(false)
  const [contacts, setContacts] = useState(contactos)
  const [documentInfo, setDocumentInfo] = useState({
    filename: "",
    description: "",
    tagsFinal: [],
    members: [],
  })
  const handleOpenAdd = e => {
    e.preventDefault()
    setOpenAdd(true)
  }

  useEffect(() => {
    setContacts(contactos)
  }, [contactos])

  const addMember = option => {
    setDocumentInfo({
      ...documentInfo,
      members: [...documentInfo.members, option],
    })
  }

  const onKeyDown = e => {
    const { key } = e
    const trimmedInput = input.trim()

    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
      setTagsFinal(prevState => [...prevState, trimmedInput])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags]
      const poppedTag = tagsCopy.pop()
      e.preventDefault()
      setTags(tagsCopy)
      setInput(poppedTag[0])
    }

    setIsKeyReleased(false)
  }

  const AddTagOnClick = e => {
    const trimmedInput = input.trim()

    if (trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault()
      setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
      setTagsFinal(prevState => [...prevState, trimmedInput])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }

    setIsKeyReleased(false)
  }

  const onKeyUp = () => {
    setIsKeyReleased(true)
  }
  const deleteTag = (e, index) => {
    e.preventDefault()
    setTags(prevState => prevState.filter((tag, i) => i !== index))
    setTagsFinal(prevState => prevState.filter((tag, i) => i !== index))
  }

  const selectTag = e => {
    var etiqueta = e.target.innerHTML
    e.target.className = "hide"
    const trimmedInput = etiqueta.trim()
    e.preventDefault()
    setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
    setTagsFinal(prevState => [...prevState, trimmedInput])
    setInput("")
    if (color != 7) {
      setColor(color + 1)
    } else {
      setColor(0)
    }
  }
  const AuthContext = useContext(authContext)
  const { user, getUser } = AuthContext
  //    useEffect(()=>{getUser()},[])

  /*Referencias para cambio de estilos en validacion de formulario*/
  const refNombre = useRef(null)
  const refMovil = useRef(null)
  const refEmail = useRef(null)
  const refDni = useRef(null)
  const refEmpresa = useRef(null)
  const refTags = useRef(null)
  const refDir = useRef(null)

  /*Referencias para iconos confirm and error*/
  const refNombreConfirm = useRef(null)
  const refMovilConfirm = useRef(null)
  const refEmailConfirm = useRef(null)
  const refDniConfirm = useRef(null)
  const refEmpresaConfirm = useRef(null)
  const refTagsConfirm = useRef(null)
  const refDirConfirm = useRef(null)

  const refNombreError = useRef(null)
  const refMovilError = useRef(null)
  const refEmailError = useRef(null)
  const refDniError = useRef(null)
  const refEmpresaError = useRef(null)
  const refTagsError = useRef(null)
  const refDirError = useRef(null)

  let valida = false
  let validaNombre = true
  let validaMail = true
  let validaMovil = true
  let validaNif = true
  let validaEmpresa = true
  let validaDir = true

  const [selectedContact, setSelectedContact] = useState(null)

  const [focused, setFocused] = React.useState(false)
  const onFocus = e => {
    setFocused(true)
    if (e.target.name == "name") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } else if (e.target.name == "client") {
      refDni.current["id"] = "focus"
      refDniConfirm.current.id = "confirmNotVisible"
      refDniError.current.id = "errorNotVisible"
    } else if (e.target.name == "description") {
      refEmpresa.current["id"] = "focus"
      refEmpresaConfirm.current.id = "confirmNotVisible"
      refEmpresaError.current.id = "errorNotVisible"
    } else if (e.target.name == "orgAddress") {
      refDir.current["id"] = "focus"
      refDirConfirm.current.id = "confirmNotVisible"
      refDirError.current.id = "errorNotVisible"
    } else if (e.target.name == "identifier_number") {
      refMovil.current["id"] = "focus"
      refMovilConfirm.current.id = "confirmNotVisible"
      refMovilError.current.id = "errorNotVisible"
    } else if (e.target.name == "tags") {
      refTags.current["id"] = "focus"
    }
  }
  const onBlur = e => {
    setFocused(false)
    if (e.target.name == "name") {
      if (campos.name.length > 0) {
        validaNombre = true
        refNombre.current.id = " "
        refNombreConfirm.current.id = "confirmVisibleNombre"
        refNombreError.current.id = "errorNotVisible"
      } else {
        refNombre.current.id = "errorValidation"
        validaNombre = false
        refNombreConfirm.current.id = "confirmNotVisible"
        refNombreError.current.id = "errorVisibleNombre"
      }
    } else if (e.target.name == "identifier_number") {
      /*
        else if(e.target.name=="description"){
            if(campos.description.length>0){
                validaEmpresa=true;
                refEmpresa.current.id=" ";
                refEmpresaError.current.id="errorNotVisible"
                refEmpresaConfirm.current.id="confirmVisible"
            }else{
                refEmpresa.current.id="errorValidation"
                validaEmpresa=false;
                refEmpresaError.current.id="errorVisible"
                refEmpresaConfirm.current.id="confirmNotVisible"
            }
            
        } 
        
        else if(e.target.name=="orgAddress"){
            if(campos.orgAddress.length>0){
                validaDir=true;
                refDir.current.id=" ";
                refDirError.current.id="errorNotVisible"
                refDirConfirm.current.id="confirmVisible"
            }else{
                refDir.current.id="errorValidation"
                validaDir=false;
                refDirError.current.id="errorVisible"
                refDirConfirm.current.id="confirmNotVisible"
            }
        }

        */
      if (
        //isNumeric(campos.identifier_number) &&
        campos.identifier_number.length > 0
      ) {
        validaMovil = true
        refMovil.current.id = " "
        refMovilError.current.id = "errorNotVisible"
        refMovilConfirm.current.id = "confirmVisibleId"
      } else {
        refMovil.current.id = "errorValidation"
        validaMovil = false
        refMovilError.current.id = "errorVisibleId"
        refMovilConfirm.current.id = "confirmNotVisible"
      }
    }
  }

  const handleOnClick = e => {
    if (e.target.name == "name") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } else if (e.target.name == "client") {
      refDni.current["id"] = "focus"
      refDniConfirm.current.id = "confirmNotVisible"
      refDniError.current.id = "errorNotVisible"
    } else if (e.target.name == "description") {
      refEmpresa.current["id"] = "focus"
      refEmpresaConfirm.current.id = "confirmNotVisible"
      refEmpresaError.current.id = "errorNotVisible"
    } else if (e.target.name == "orgAddress") {
      refDir.current["id"] = "focus"
      refDirConfirm.current.id = "confirmNotVisible"
      refDirError.current.id = "errorNotVisible"
    } else if (e.target.name == "identifier_number") {
      refMovil.current["id"] = "focus"
      refMovilConfirm.current.id = "confirmNotVisible"
      refMovilError.current.id = "errorNotVisible"
    } else if (e.target.name == "tags") {
      refTags.current["id"] = "focus"
    }
  }

  function nameIsValid(name) {
    var matches = name.match(/\d+/g)
    if (matches != null) {
      return false
    } else {
      return true
    }
  }
  function isNumeric(num) {
    return !isNaN(num)
  }

  const onCreateProject = () => {
    setmodal_standard(!modal_standard)
    setModalUpload(true)
  }
  const projectContext = useContext(ProjectContext)
  const { createProject, selectedProject, createProcess } = projectContext
  const { projects, getProjects } = projectContext

  const [values, setValues] = useState({
    name: "",
    description: "",
    identifier_number: "",
    orgAddress: "",
    client: "",
    tags: [],
  })
  const { name, description, identifier_number, userEmail } = values

  const [campos, guardarCampos] = useState({
    name: "",
    description: "",
    identifier_number: "",
    orgAddress: "",
    client: "",
    tags: [],
    imagen: "",
    is_template: imTemplate ? true : false,
  })
  const getLastProject = () => {
    let ultimaFecha = ""
    let iteracion = 0
    for (let i = 0; i < projects.length; i++) {
      if (i == 0) {
        ultimaFecha = projects[i].updatedAt
        iteracion = i
      } else {
        if (projects[i].createdAt > ultimaFecha) {
          ultimaFecha = projects[i].createdAt
          iteracion = i
        }
      }
    }
    return projects[iteracion]
  }

  const obtenerCampos = e => {
    guardarCampos({
      ...campos,
      [e.target.name]: e.target.value,
    })
  }
  const onEtiqueta = e => {
    const { value } = e.target
    setInput(value)
  }

  const onSubmit = e => {
    //e.preventDefault();
    if (campos.name.length > 0) {
      validaNombre = true
      refNombre.current.id = " "
      refNombreConfirm.current.id = "confirmVisibleNombre"
      refNombreError.current.id = "errorNotVisible"
    } else {
      refNombre.current.id = "errorValidation"
      validaNombre = false
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorVisibleNombre"
    }
    // if(
    //    // isNumeric(campos.identifier_number) &&
    //     campos.identifier_number.length>0){
    //     validaMovil=true;
    //     refMovil.current.id=" ";
    //     refMovilError.current.id="errorNotVisible"
    //     refMovilConfirm.current.id="confirmVisibleId"
    // }else{
    //     refMovil.current.id="errorValidation"
    //     validaMovil=false;
    //     refMovilError.current.id="errorVisibleId"
    //     refMovilConfirm.current.id="confirmNotVisible"
    // }
    if (
      validaNombre //&& validaMovil  && validaEmpresa && validaDir
    ) {
      valida = true
    }
    if (valida == true) {
      campos.tags = tagsFinal
      campos.imagen = image
      if (selectedContact == null) {
        campos.client = " "
      } else {
        campos.client = selectedContact.id
      }
      createProject(campos)

      // setTimeout(()=>{
      //     getProjects();
      // },3000)
      // setSeleccionado(getLastProject());
      // setmodal_standard(false)
      // setModalUpload(true)
    }
  }
  useEffect(() => {
    if (selectedProject) {
      setSeleccionado(selectedProject)
      if (modal_standard) {
        setmodal_standard(false)
        setModalUpload(true)
      } else {
        setmodal_first(false)
      }
    }
  }, [selectedProject])
  const handleEnter = event => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }

  const [image, setImage] = useState(
    "https://d1t181n48swd5d.cloudfront.net/imgProject/00_DEFAULT.png",
  ) //useState(user['custom:avatar']);
  const [openImage, setOpenImage] = useState(false)
  const handleOpenImage = e => {
    e.preventDefault()
    setOpenImage(true)
  }
  return (
    <>
      <Modal
        open={modal_standard}
        onClose={() => {
          setmodal_standard(false)
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setmodal_standard(false)
            }}
          ></CloseIcon>
          <div id="UserImage">
            <img
              id="ProfileImage"
              onClick={handleOpenImage}
              className="rounded-circle header-profile-user"
              src={image}
              alt="Header Avatar"
            />
            <div id="foto">
              <EditOutlinedIcon
                onClick={handleOpenImage}
                id="fotoSize"
              ></EditOutlinedIcon>
            </div>
          </div>
          <form id="Form">
            <div id="inlineDiv">
              <div id="NombreProyecto">
                <p className="Titulos">{i18n.t("Nombre del Proyecto")}</p>
                <input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={handleEnter}
                  ref={refNombre}
                  onClick={handleOnClick}
                  placeholder={i18n.t("Nombre del proyecto")}
                  name="name"
                  onChange={obtenerCampos}
                ></input>
                <CheckCircleOutlineSharpIcon
                  style={{ width: "16px", color: "#3BA452" }}
                  ref={refNombreConfirm}
                  id="confirmNotVisible"
                ></CheckCircleOutlineSharpIcon>
                <ErrorOutlineSharpIcon
                  style={{ width: "16px", color: "#BF2929" }}
                  ref={refNombreError}
                  id="errorNotVisible"
                ></ErrorOutlineSharpIcon>
              </div>
              {/*
                        <div id="NumeroId">
                            <p className="Titulos">{i18n.t("Nº. de id.")}</p>
                            <input
                            onFocus={onFocus} onBlur={onBlur} onKeyDown={handleEnter}
                                ref={refMovil}
                                onClick={handleOnClick}
                                placeholder="Número "
                                name="identifier_number"
                                onChange={obtenerCampos}></input>
                            <CheckCircleOutlineSharpIcon style={{width:'16px', color: '#3BA452'}} ref={refMovilConfirm} id="confirmNotVisible" ></CheckCircleOutlineSharpIcon>
                         <ErrorOutlineSharpIcon  style={{width:'16px', color: '#BF2929'}} ref={refMovilError} id="errorNotVisible" ></ErrorOutlineSharpIcon>
                </div>*/}
            </div>
            <div id="OtroConten">
              {/*
                        <p className="Titulos">{i18n.t("Dirección de Obra")}</p>
                        <div>
                            <input
                            onFocus={onFocus} onBlur={onBlur} onKeyDown={handleEnter}
                                ref={refDir}
                                onClick={handleOnClick}
                                placeholder={i18n.t("Dirección de Obra")}
                                name="orgAddress"
                                onChange={obtenerCampos}></input>
                            <CheckCircleOutlineSharpIcon style={{width:'16px', color: '#3BA452', marginTop: "-10%", zIndex:'1',
                  marginLeft: "80%"}} ref={refDirConfirm} id="confirmNotVisible" ></CheckCircleOutlineSharpIcon>
                             <ErrorOutlineSharpIcon  style={{width:'16px', color: '#BF2929',  marginTop: "-8%"}}ref={refDirError} id="errorNotVisible" ></ErrorOutlineSharpIcon>
                        </div>
                        <p className="Titulos">{i18n.t("Cliente")}</p>
                        <div>
                        <Autocomplete className="FieldAuto"
                            options={contactos}
                            onKeyDown={handleEnter}
                            renderInput={params => (
                                <TextField {...params} label="" variant="outlined" />
                            )}
                                      getOptionLabel={(option) => option.completeName}

                            value={selectedContact}
                            onChange={(_event, Seleccionado) => {
                                setSelectedContact(Seleccionado);
                            }}
                        />
                            <AddCircleOutlineIcon id="addContact" onClick={handleOpenAdd}></AddCircleOutlineIcon>
                        </div>
                        <p className="Titulos">Descripción</p>
                        <div>
                            <textarea
                            onFocus={onFocus} onBlur={onBlur} onKeyDown={handleEnter}
                                contentEditable="true"
                                id="descripcionInput"
                                ref={refEmpresa}
                                onClick={handleOnClick}
                                name="description"
                                placeholder={i18n.t("Describe aquí tu proyecto")}
                                onChange={obtenerCampos}></textarea>
                            <CheckCircleOutlineSharpIcon style={{width:'16px', color: '#3BA452'}} ref={refEmpresaConfirm} id="confirmNotVisible" ></CheckCircleOutlineSharpIcon>
                            <ErrorOutlineSharpIcon  style={{width:'16px', color: '#BF2929'}} ref={refEmpresaError} id="errorNotVisible" ></ErrorOutlineSharpIcon>
                         </div>
                        <p className="Titulos">{i18n.t("Etiqueta")}</p>
                        <div
                            ref={refTags}
                            onClick={handleOnClick}
                            className="container2" style={{padding:"0px"}}>
                            <LocalOfferOutlinedIcon id="iconTag"></LocalOfferOutlinedIcon>
                            {tags.map((tag, index) => (
                                // eslint-disable-next-line react/jsx-key
                                <div id={tag[1]} className="tag">
                                    {tag[0]}
                                    <button onClick={(e) => deleteTag(e, index)}>x</button>
                                </div>
                            ))}
                            <input
                            onFocus={onFocus}
                            onBlur={onBlur}
                                name="tags"
                                value={input}
                                placeholder={i18n.t("Añadir Etiqueta")}
                                onKeyDown={onKeyDown}
                                onKeyUp={onKeyUp}
                                onChange={onEtiqueta}
                            />
                            <AddCircleOutlineIcon id="addTag" onClick={AddTagOnClick}></AddCircleOutlineIcon>
                        </div>
                        <div id="Default">
                        <span id="color1" className="defaultTag" onClick={
                            selectTag
                        }>
                            VIVIENDA
                        </span>
                            <span id="color2" className="defaultTag" onClick={
                                selectTag
                            }>
                            RESIDENCIAL
                        </span >
                            <span id="color3" className="defaultTag" onClick={
                                selectTag
                            }>
                            OBRA NUEVA
                        </span>
                            <span id="color5" className="defaultTag" onClick={
                                selectTag
                            }>
                            REFORMA
                        </span>
                            <span id="color4" className="defaultTag" onClick={
                                selectTag
                            }>
                            REHABILITACIÓN
                        </span>
                            <span id="color6" className="defaultTag" onClick={
                                selectTag
                            }>
                            RETAIL
                        </span>
                            <span id="color7" className="defaultTag" onClick={
                                selectTag
                            }>
                            PÚBLICO
                        </span>
                            <span id="color8" className="defaultTag" onClick={
                                selectTag
                            }>
                            PRIVADO
                        </span>

                        </div>
                        */}
              <div id="centerButton" style={{ paddingTop: "20px" }}>
                {createProcess.creating_project ? (
                  <CircularProgress size={24} />
                ) : (
                  <button
                    type="button"
                    onClick={onSubmit}
                    className="AddContact"
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="ProjectCreated"
                    data-amplify-analytics-attrs={campos}
                  >
                    {i18n.t("Crear Proyecto")}
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <AddContact openAdd={openAdd} setOpenAdd={setOpenAdd} />
      <ModalImageProject
        openImage={openImage}
        setOpenImage={setOpenImage}
        image={image}
        first={true}
        setImage={setImage}
      ></ModalImageProject>
    </>
  )
}
PersonalModal.propTypes = {
  modal_standard: PropTypes.bool.isRequired,
  setmodal_standard: PropTypes.func.isRequired,
  setModalUpload: PropTypes.func.isRequired,
  contactos: PropTypes.array,
  setSeleccionado: PropTypes.func.isRequired,
  modal_first: PropTypes.bool.isRequired,
  setmodal_first: PropTypes.func.isRequired,
  imTemplate: PropTypes.bool,
}

export default PersonalModal
