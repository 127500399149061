import React, { useContext } from "react"
import PropTypes from "prop-types"
import Document from "components/Documents/Document"
import i18n from "i18n"
import loadingAnimation from "assets/customIcons/loading.json"
import Lottie from "react-lottie-player"
import { HelveticaBold } from "common/typographies.js"
import FileContext from "context/file/fileContext"
import ContactContext from "context/contact/contactContext"
import TopQuickActionsBar from "./TopQuickActionsBar"

const CardFiles = ({ files, selectedDocument, setSelectedDocument }) => {
  const { loading } = useContext(FileContext)
  const { Contacts } = useContext(ContactContext)

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "relative",
              display: "grid",
              marginTop: "10px",
            }}
          >
            <Lottie
              loop
              animationData={loadingAnimation}
              play
              style={{ width: 100, height: 100, marginLeft: "0px" }}
            />
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "24px",
                marginLeft: "10px",
                marginTop: "-30px",
              }}
            >
              {i18n.t("Cargando...")}
            </HelveticaBold>
          </div>
        </>
      ) : (
        <Document
          documents={files}
          contacts={Contacts}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
        />
      )}
      {selectedDocument.length > 0 && (
        <TopQuickActionsBar
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
        />
      )}
    </>
  )
}

CardFiles.propTypes = {
  files: PropTypes.array,
  selectedDocument: PropTypes.array,
  setSelectedDocument: PropTypes.func,
}

export default CardFiles
