import React from "react"
import { HelveticaBold } from "common/typographies.js"
import i18n from "i18n"
import PropTypes from "prop-types"
import { ReactComponent as EditIcon } from "assets/customIcons/editIcon.svg"

const DetailsHeader = ({
  editMode,
  setEditMode,
  imCreating,
  bancoDePrecios,
}) => {
  return (
    <div className="inline" style={{ width: "100%" }}>
      <div style={{ width: "8%", textAlign: "start" }}>
        <HelveticaBold
          style={{ color: "#414141", fontSize: "12px", paddingRight: "10px" }}
        >
          {i18n.t("Tipo")}
        </HelveticaBold>
      </div>
      <div style={{ width: "50%", textAlign: "start", paddingRight: "10px" }}>
        <HelveticaBold
          style={{ color: "#414141", fontSize: "12px", paddingRight: "10px" }}
        >
          {i18n.t("Descripción")}
        </HelveticaBold>
      </div>
      {(editMode || imCreating) && (
        <div style={{ width: "13%", textAlign: "center" }}>
          <HelveticaBold
            style={{ color: "#414141", fontSize: "12px", paddingRight: "10px" }}
          >
            {i18n.t("Unidad")}
          </HelveticaBold>
        </div>
      )}
      <div style={{ width: "13%", textAlign: "center" }}>
        <HelveticaBold
          style={{ color: "#414141", fontSize: "12px", paddingRight: "10px" }}
        >
          {i18n.t("Cantidad")}
        </HelveticaBold>
      </div>
      <div style={{ width: "13%", textAlign: "center" }}>
        <HelveticaBold
          style={{ color: "#414141", fontSize: "12px", paddingRight: "10px" }}
        >
          {i18n.t("Precio")}
        </HelveticaBold>
      </div>
      <div style={{ width: "13%", textAlign: "center" }}>
        <HelveticaBold
          style={{ color: "#414141", fontSize: "12px", paddingRight: "10px" }}
        >
          {i18n.t("Importe")}
        </HelveticaBold>
      </div>
      <div style={{ width: "6%", textAlign: "center" }}>
        {bancoDePrecios === "BANCO DE PRECIOS REFERENCIA" ? null : (
          <EditIcon
            onClick={() => {
              setEditMode(!editMode)
            }}
            style={{ marginBottom: "5px", cursor: "pointer" }}
          ></EditIcon>
        )}
      </div>
    </div>
  )
}

DetailsHeader.propTypes = {
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  imCreating: PropTypes.bool.isRequired,
  bancoDePrecios: PropTypes.string,
}

export default DetailsHeader
