import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import DynamicElementsContext from "context/dynamicElements/DynamicElementsContext"
import { GridActionsCellItem } from "@mui/x-data-grid"
import ActionIcon from "./ActionIcon"
import { Save } from "@mui/icons-material"

const DynamicElementEditAction = ({ params }) => {
  const { dispatches } = useContext(DynamicElementsContext)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    const isSuccess = await dispatches.editElement(
      params.row,
      params.row.organizationId,
    )

    if (isSuccess) {
      setSuccess(true)
      setTimeout(() => setSuccess(false), 3000)
    }
    setLoading(false)
  }

  return (
    <GridActionsCellItem
      label=""
      icon={
        <ActionIcon
          success={success}
          loading={loading}
          handleSubmit={handleSubmit}
          ActionComponent={Save}
          tooltipTitle={i18n.t("buttons.save")}
        />
      }
    />
  )
}

DynamicElementEditAction.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    field: PropTypes.string,
    row: PropTypes.object.isRequired,
    value: PropTypes.any,
    colDef: PropTypes.object,
    api: PropTypes.object,
    getValue: PropTypes.func,
  }).isRequired,
}

export default DynamicElementEditAction
