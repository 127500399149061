import { HelveticaBold } from "common/typographies"
import React, { useEffect, useContext, useState } from "react"
import i18n from "i18n"
import ActaStaticInfo from "./ActaStaticInfo"
import AuthorInfo from "./AuthorInfo"
import AsistentesInfo from "./AsistentesInfo"
import AsuntosTratados from "./AsuntosTratados"
import AsuntosPendientes from "./AsuntosPendientes"
import { YellowButton } from "common/buttons"
import ContactContext from "context/contact/contactContext"
import AuthContext from "context/auth/authContext"
import { useActas } from "hooks/useActas"
import { CircularProgress } from "@mui/material"
import DropdownActOptions from "./DropdownActOptions"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"

const EditActComponent = () => {
  const { getContacts } = useContext(ContactContext)
  const { user, getUser } = useContext(AuthContext)
  const {
    actaValues,
    setActaValues,
    actNum,
    actasRefs,
    setActaRefs,
    handleEditAct,
    loading,
    loadingPdf,
    actPdf,
    actId,
    updateSubject,
  } = useActas()
  const [viewPending, setViewPending] = useState(true)
  const history = useHistory()

  useEffect(() => {
    getContacts()
    if (!user) {
      getUser()
    }
  }, [])

  return (
    <>
      <div style={{ marginLeft: "30px", marginRight: "18px" }}>
        <div className="inline" style={{ marginLeft: "10px" }}>
          <ArrowBackOutlinedIcon
            onClick={() => {
              history.goBack()
            }}
            style={{
              color: "#414141",
              cursor: "pointer",
              fontSize: "18px",
              marginTop: "5px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          />
          <HelveticaBold style={{ color: "#414141", fontSize: "18px" }}>
            {i18n.t("Actas")}
          </HelveticaBold>
          <div style={{ marginTop: "-7px" }}>
            <DropdownActOptions
              actId={actId}
              pdf={actPdf}
              loadingPdf={loadingPdf}
              actNum={actNum}
              asistentes={actaValues?.attendees}
            />
          </div>
        </div>

        <ActaStaticInfo actaValues={actaValues} setActaValues={setActaValues} />

        <AuthorInfo setActaValues={setActaValues} actaValues={actaValues} />

        <AsistentesInfo
          setActaRefs={setActaRefs}
          actasRefs={actasRefs}
          actaValues={actaValues}
          setActaValues={setActaValues}
        />

        <AsuntosTratados
          setActaRefs={setActaRefs}
          actasRefs={actasRefs}
          actaValues={actaValues}
          setActaValues={setActaValues}
          actNum={actNum}
        />

        {actaValues.pendingSubjects.length > 0 ? (
          <div style={{ display: "flex" }}>
            {viewPending ? (
              <VisibilityOutlinedIcon
                onClick={() => {
                  setViewPending(!viewPending)
                }}
                style={{
                  marginTop: "16px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              />
            ) : (
              <VisibilityOffOutlinedIcon
                onClick={() => {
                  setViewPending(!viewPending)
                }}
                style={{
                  marginTop: "16px",
                  marginRight: "10px",
                  cursor: "pointer",
                }}
              />
            )}
            <AsuntosPendientes
              updateSubject={updateSubject}
              viewPending={viewPending}
              setActaRefs={setActaRefs}
              actasRefs={actasRefs}
              actaValues={actaValues}
              setActaValues={setActaValues}
              actNum={actNum}
            />
          </div>
        ) : null}

        <div
          style={{
            width: "100%",
            position: "relative",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <YellowButton
              onClick={e => {
                handleEditAct(e)
              }}
            >
              {i18n.t("Validar y Firmar")}
            </YellowButton>
          )}
        </div>
      </div>
    </>
  )
}

export default EditActComponent
