import styled from "styled-components"

export const DangerAlert = styled.div`
  background-color: #f5d2d2;
  color: #bf2929;
  border-radius: 6px;
  font-weight: 700;
  font-size: 16px;
  font-family: "Helvetica", sans-serif;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`
