import React, { Fragment, useContext } from "react"
import PropTypes from "prop-types"
import { Divider, Box, Typography, Chip } from "@mui/material"
import { ListaColores } from "components/Documents/StylesAndUtils/Colors"
import i18n from "i18n"
import { formatValue } from "react-currency-input-field"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext"

function roundToTwo(num) {
  return +(Math.round(num + "e+2") + "e-2")
}
function computeMaterialCost(rows) {
  let cost = 0
  for (let row of rows) {
    if (row.type === "3") {
      let quantity =
        row.quantityComputed && row.quantityComputed > 0
          ? row.quantityComputed
          : row.quantity
      let price = row.price ?? 0
      cost += roundToTwo(price * quantity)
    }
  }
  return roundToTwo(cost)
}
function computeWorkersCost(rows) {
  let cost = 0
  for (let row of rows) {
    if (row.type === "1") {
      let quantity =
        row.quantityComputed && row.quantityComputed > 0
          ? row.quantityComputed
          : row.quantity
      let price = row.price ?? 0
      cost += roundToTwo(quantity * price)
    }
  }
  return roundToTwo(cost)
}
const PartTotal = ({ part }) => {
  const computeTotal = () => {
    if (!part.decomposition) return 0
    let total = 0
    for (let row of part.decomposition) {
      let quantity =
        row.quantityComputed && row.quantityComputed > 0
          ? row.quantityComputed
          : row.quantity
      let price = row.price ?? 0
      total += roundToTwo(quantity * price)
    }
    let stringFormat = formatValue({
      value: total.toFixed(2),
      groupSeparator: ".",
      decimalSeparator: ",",
    })
    return stringFormat
  }
  const { user } = useContext(AuthContext)

  return (
    <Fragment>
      <Divider
        sx={{
          margin: "0px",
          color: "#FFD52B",
          width: "100%",
          borderBottomWidth: 1,
        }}
      />
      <Box
        display={"flex"}
        sx={{
          justifyContent: "space-around",
        }}
        style={{ backgroundColor: "#FBFBFE" }}
      >
        <Box display={"flex"} justifyContent={"space-evenly"}>
          <Box p={1} m={1}>
            <Typography
              style={{
                marginLeft: "40px",
                fontWeight: "600",
                fontFamily: "Helvetica",
                fontSize: "18px",
              }}
            >
              {i18n.t("Total")}
            </Typography>
          </Box>
          <Box display={"flex"} alignItems="center" p={1} m={1}>
            <Chip
              style={{
                marginLeft: "80px",
                marginTop: "-2px",
                minWidth: "120px",
                fontSize: "12px",
                fontFamily: "Helvetica",
                fontWeight: "400",
              }}
              label={
                "Mano de obra: " +
                computeWorkersCost(part.decomposition ?? []) +
                getCurrency(user["custom:currency"])
              }
              variant="outlined"
              sx={{
                margin: "0px",
                borderRadius: "4px",

                backgroundColor: "transparent",
                color: ListaColores[2].color,
                borderColor: ListaColores[2].color,
              }}
            />
          </Box>
          <Box display={"flex"} alignItems="center" p={1} m={1}>
            <Chip
              style={{
                marginLeft: "10px",
                marginTop: "-2px",
                minWidth: "120px",
                fontSize: "12px",
                fontFamily: "Helvetica",
                fontWeight: "400",
              }}
              label={
                "Costo material: " +
                computeMaterialCost(part.decomposition ?? []) +
                getCurrency(user["custom:currency"])
              }
              variant="outlined"
              sx={{
                margin: "0px",
                borderRadius: "4px",

                backgroundColor: "transparent",
                color: ListaColores[4].color,
                borderColor: ListaColores[4].color,
              }}
            />
          </Box>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-around"}
          alignItems={"center"}
          m={1}
          p={1}
        >
          <Box mx={3} ml={"-40"}>
            <Typography
              style={{
                fontWeight: "600",
                fontFamily: "Helvetica",
                fontSize: "18px",
              }}
            >
              {i18n.t("Coste directo")}
            </Typography>
          </Box>
          <Box mx={2}>
            <Typography
              style={{
                fontWeight: "600",
                fontFamily: "Helvetica",
                fontSize: "18px",
              }}
            >
              {computeTotal() + getCurrency(user["custom:currency"])}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  )
}
PartTotal.propTypes = {
  part: PropTypes.object.isRequired,
}

export default PartTotal
