import React, { useContext, useState, useEffect } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { HelveticaBold, PoppinsNormal } from "common/typographies"
import { styled } from "@mui/material/styles"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Check from "@mui/icons-material/Check"
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import PersonIcon from "@mui/icons-material/Person"
import MailIcon from "@mui/icons-material/Mail"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import SendIcon from "@mui/icons-material/Send"
import FirstStep from "./FirstStep"
import SecondStep from "./SecondStep"
import ThirdStep from "./ThirdStep"
import { CircularProgress } from "@mui/material"
import FourthStep from "./FourthStep"
import FacturasContext from "context/facturas/facturasContext"

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}))

function QontoStepIcon(props) {
  const { active, completed, className } = props
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  )
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
}
//Conectores
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 11,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 95deg,#5076FF 0%, #5076FF 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 95deg,#5076FF 0%, #5076FF 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    width: "80%",
    marginLeft: "10%",
    backgroundColor: "#414141",
    borderRadius: 1,
  },
}))
//Circulos
const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 25,
  height: 25,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "linear-gradient( 136deg, #5076FF 0%, #5076FF 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage: "linear-gradient( 136deg, #5076FF 0%, #5076FF 100%)",
  }),
}))
//Iconos
function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: <PersonIcon style={{ width: "16px" }} />,
    2: <MailIcon style={{ width: "16px" }} />,
    3: (
      <BorderColorIcon
        style={{ width: "16px", marginLeft: "-1px", marginTop: "-1px" }}
      />
    ),
    4: (
      <SendIcon
        style={{
          width: "16px",
          transform: "rotate(-40deg)",
          marginTop: "-2px",
        }}
      />
    ),
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}
//Pasos
const steps = [
  i18n.t("Añade el destinatario"),
  i18n.t("Indica el asunto"),
  i18n.t("Añade un mensaje"),
  i18n.t("Factura enviada"),
]

const SendSteps = ({ facturas, setOpen, invoiceService }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [selectedContacts, setSelectedContacts] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([
    {
      name: "Proveedor",
      value: "PROVIDER",
      checked: false,
      key: Math.random(),
    },
    {
      name: "Cliente",
      value: "CLIENT",
      checked: false,
      key: Math.random(),
    },
    {
      name: "Otro",
      value: "OTHER",
      checked: false,
      key: Math.random(),
    },
  ])
  const [asunto, setAsunto] = useState("")
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [files, setFiles] = useState([])
  const { loadingSendInvoice, sendInvoice } = useContext(FacturasContext)
  const onSend = async () => {
    const sendData = {
      selectedContacts: selectedContacts,
      roles: selectedRoles.filter(role => role.checked).map(role => role.name),
      asunto: asunto,
      mensaje: message,
      title: title + ".pdf",
      projectId: facturas[0].projectInfo.projectId,
      orgId: facturas[0].accountId,
      selectedFiles: files,
    }
    invoiceService.handleSend({
      sendData: sendData,
      invoices: facturas,
    })
    setActiveStep(activeStep + 1)
    setTimeout(() => {
      setOpen(false)
    }, 1000)
  }

  const [cont, setCont] = useState(0)
  useEffect(() => {
    if (selectedContacts.length !== 0) {
      setSelectedContacts(selectedContacts)
    }
  }, [cont])

  return (
    <>
      <div
        style={{
          maxHeight: "95.7%",
          height: "95.7%",
          justifyContent: "left",
          backgroundColor: "white",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingTop: "30px",
          borderBottom: "0px solid #EBEBEB",
        }}
      >
        <HelveticaBold
          style={{ fontWeight: "700", color: "#414141", fontSize: "18px" }}
        >
          {facturas.length > 1
            ? i18n.t("Estas a punto de enviar las facturas por email")
            : i18n.t("Estas a punto de enviar la factura por email")}
        </HelveticaBold>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          style={{ marginLeft: "-5%", marginTop: "30px" }}
          connector={<ColorlibConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div
          style={{
            marginTop: "30px",
            height: "1px",
            backgroundColor: "#EBEBEB",
            marginLeft: "-10%",
            width: "120%",
          }}
        ></div>
        {activeStep == 0 ? (
          <FirstStep
            setCont={setCont}
            selectedContacts={selectedContacts}
            setSelectedContacts={setSelectedContacts}
            selectedRoles={selectedRoles}
            setSelectedRoles={setSelectedRoles}
          />
        ) : activeStep == 1 ? (
          <SecondStep
            asunto={asunto}
            setAsunto={setAsunto}
            title={title}
            setTitle={setTitle}
            factura={facturas[0]}
          />
        ) : activeStep == 2 ? (
          <ThirdStep
            message={message}
            setMessage={setMessage}
            files={files}
            setFiles={setFiles}
          />
        ) : (
          <FourthStep />
        )}
      </div>
      {loadingSendInvoice ? (
        <div
          className="inline"
          style={{
            justifyContent: "left",
            backgroundColor: "white",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <CircularProgress size={24} style={{ marginTop: "7px" }} />
        </div>
      ) : (
        <div
          className="inline"
          style={{
            justifyContent: "left",
            backgroundColor: "white",
            paddingLeft: "10%",
            paddingRight: "10%",
          }}
        >
          <button
            onClick={() => {
              activeStep == 3
                ? setOpen(false)
                : activeStep != 0
                  ? setActiveStep(activeStep - 1)
                  : setOpen(false)
            }}
            className="AddProject"
            style={{
              marginRight: "0%",
              marginLeft: "20px",
              paddingRight: "0px",
              width: "180px",
              border: "2px solid #FFD52B",
              background: "white",
              bottom: "12px",
            }}
          >
            {activeStep == 0
              ? i18n.t("Cancelar")
              : activeStep == 3
                ? "Cerrar"
                : "Atrás"}
          </button>
          {activeStep == 0 &&
          (selectedContacts.length == 0 ||
            (selectedRoles.provider == false &&
              selectedRoles.another == false &&
              selectedRoles.client == false)) ? (
            <button
              disabled
              className="AddProject"
              style={{
                paddingRight: "10px",
                cursor: "default",
                marginLeft: "220px",
                width: "180px",
                border: "2px solid #e4e4e4",
                background: "#e4e4e4",
                bottom: "12px",
              }}
            >
              {activeStep != 2 ? i18n.t("Siguiente") : i18n.t("Enviar")}
            </button>
          ) : activeStep == 1 && (asunto == "" || title == "") ? (
            <button
              disabled
              className="AddProject"
              style={{
                paddingRight: "10px",
                cursor: "default",
                marginLeft: "220px",
                width: "180px",
                border: "2px solid #e4e4e4",
                background: "#e4e4e4",
                bottom: "12px",
              }}
            >
              {activeStep != 2 ? i18n.t("Siguiente") : i18n.t("Enviar")}
            </button>
          ) : activeStep == 2 ? (
            <button
              onClick={() => {
                onSend()
              }}
              className="AddProject"
              style={{
                paddingRight: "10px",
                cursor: "pointer",
                marginLeft: "220px",
                width: "180px",
                border: "2px solid #FFD52B",
                background: "#FFD52B",
                bottom: "12px",
              }}
            >
              {activeStep != 2 ? i18n.t("Siguiente") : i18n.t("Enviar")}
            </button>
          ) : activeStep == 3 ? null : (
            <button
              onClick={() => {
                setActiveStep(activeStep + 1)
              }}
              className="AddProject"
              style={{
                paddingRight: "10px",
                cursor: "pointer",
                marginLeft: "220px",
                width: "180px",
                border: "2px solid #FFD52B",
                background: "#FFD52B",
                bottom: "12px",
              }}
            >
              {activeStep != 2 ? i18n.t("Siguiente") : i18n.t("Enviar")}
            </button>
          )}
        </div>
      )}
    </>
  )
}
SendSteps.propTypes = {
  facturas: PropTypes.array.isRequired,
  setOpen: PropTypes.func.isRequired,
  invoiceService: PropTypes.any,
}

export default SendSteps
