import React, { useContext, useEffect, useState, useRef } from "react"
import { MetaTags } from "react-meta-tags"
import { Box } from "@mui/material"
import ContactTable from "components/Contacts/ContactTable"
import ContactContext from "../../context/contact/contactContext"
import GridViewIcon from "@mui/icons-material/GridView"
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined"
import AuthContext from "context/auth/authContext"
import AddContact from "components/Contacts/ContactTable/addContact"
import i18n from "i18next"
import ContactGridView from "components/Contacts/ContactTable/contactGridView"
import * as amplitude from "@amplitude/analytics-browser"
import { RecordPageView } from "App"
const Contacts = () => {
  RecordPageView()
  const { Contacts, getContacts } = useContext(ContactContext)

  useEffect(() => {
    amplitude.track("Contactos Visited")
    getContacts()
  }, [])

  const [openAdd, setOpenAdd] = useState(false)
  const { user, getUser, freeTrialExpired } = useContext(AuthContext)
  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])

  const [vista, setVista] = useState(0)
  const refCuad = useRef(null)
  const refList = useRef(null)
  const handleVista0 = e => {
    refCuad.current["id"] = "vistaCuad"
    refList.current["id"] = "vistaList"
    setVista(0)
  }
  const handleVista1 = e => {
    refCuad.current["id"] = "vistaCuad2"
    refList.current["id"] = "vistaList2"
    setVista(1)
  }

  const handleOpenAdd = e => {
    e.preventDefault()
    setOpenAdd(true)
  }

  return (
    <Box>
      <Box className="page-content">
        <MetaTags>
          <title>Planhopper | Contactos</title>
        </MetaTags>

        <Box
          sx={{
            margin: "0 30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          <Box>
            <p id="Configuration">Contactos</p>
            <div
              id="SwitchVista"
              style={{
                width: "100px",
                marginRight: "220px",
                marginTop: "-60px",
                marginBottom: "60px",
                height: "40px",
              }}
            >
              <div
                ref={refCuad}
                id="vistaCuad"
                className="Cuad"
                style={{
                  display: "flex",
                  width: "50px",
                  borderRadius: "30px",
                  marginLeft: "0px",
                  height: "40px",
                  paddingTop: "7px",
                }}
                onClick={handleVista0}
              >
                <GridViewIcon
                  style={{
                    width: "25px",
                    height: "25px",
                    marginTop: "1PX",
                    marginRight: "2px",
                  }}
                ></GridViewIcon>
              </div>
              <div
                ref={refList}
                id="vistaList"
                className="ListV"
                style={{
                  display: "flex",
                  width: "50px",
                  borderRadius: "30px",
                  marginLeft: "0px",
                  height: "40px",
                  paddingTop: "7px",
                }}
                onClick={handleVista1}
              >
                <FormatListBulletedOutlinedIcon
                  style={{
                    width: "25px",
                    height: "25px",
                    marginTop: "1PX",
                    marginRight: "2px",
                  }}
                ></FormatListBulletedOutlinedIcon>
              </div>
            </div>
            {user ? (
              user.freeTrialExpired ||
              user["custom:role"] === "WORKER" ? null : (
                <div
                  style={{
                    position: "absolute",
                    right: "30px",
                    marginTop: "-60px",
                  }}
                >
                  <button
                    className="AddContact"
                    style={{
                      height: "40px",
                      width: "200px",
                      marginRight: "0px",
                    }}
                    onClick={handleOpenAdd}
                  >
                    {i18n.t("Añadir Contacto")}
                  </button>
                </div>
              )
            ) : null}
          </Box>
          {vista === 0 ? (
            <ContactGridView user={user} contacts={Contacts} />
          ) : (
            <ContactTable contacts={Contacts} />
          )}
        </Box>
      </Box>

      <AddContact openAdd={openAdd} setOpenAdd={setOpenAdd} />
    </Box>
  )
}

export default Contacts
