import { useState } from "react"

/**
 * Custom hook to manage state with localStorage persistence.
 * @param {string} key - The key to store and retrieve the value in localStorage.
 * @param {T} initialValue - The initial value to use if no value is found in localStorage.
 * @returns {[T, (value: T | ((val: T) => T)) => void]} - An array containing the current state and a function to update it.
 *
 * @example
 * const [value, setValue] = useLocalStorage("theme", "light");
 * setValue("dark"); // Updates the localStorage value for "theme" to "dark".
 */
const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error(`Error reading localStorage key "${key}":`, error)
      return initialValue
    }
  })

  const setValue = value => {
    try {
      const valueToStore =
        typeof value === "function" ? value(storedValue) : value
      setStoredValue(valueToStore)
      localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      console.error(`Error setting localStorage key "${key}":`, error)
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage
