import React, { useContext, useState } from "react"
import { HelveticaBold } from "common/typographies.js"
import i18n from "i18n"
import DetailsHeader from "./detailsHeader"
import Detail from "./detail"
import DetailsFooter from "./detailsFooter"
import CreateDetailViewMode from "./createDetailViewMode"
import PropTypes from "prop-types"
import Budget2023Context from "context/budget2023/Budget2023Context"
import { useParams } from "react-router-dom/cjs/react-router-dom"

const PartDetails = ({
  details,
  setDetails,
  creatingDetailInViewMode,
  setCreatingDetailInViewMode,
  partidaState,
  bancoDePrecios,
  showDynamicElementButton = false,
}) => {
  const { dynamicElement } = useContext(Budget2023Context)
  const { projectId } = useParams()

  const [editMode, setEditMode] = useState(false)
  const addEmptyDetail = () => {
    const newDetail = {
      type: "",
      description: "",
      price: 0,
      quantity: 0,
      total: 0,
      unity: "pa",
    }
    setDetails(prevArray => [...prevArray, newDetail])
  }

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 350px)",
          height: "calc(100% - 114px )",
          padding: "15px",
        }}
      >
        <DetailsHeader
          editMode={editMode}
          setEditMode={setEditMode}
          imCreating={creatingDetailInViewMode}
          bancoDePrecios={bancoDePrecios}
        />
        {details.map((detail, index) => (
          <Detail
            key={index}
            index={index}
            details={details}
            setDetails={setDetails}
            detail={detail}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        ))}
        {creatingDetailInViewMode && !editMode ? (
          <CreateDetailViewMode
            bancoDePrecios={bancoDePrecios}
            setCreatingDetailInViewMode={setCreatingDetailInViewMode}
            details={details}
            detail={{
              type: "",
              description: "",
              price: 0,
              quantity: 0,
              total: 0,
              unity: "pa",
            }}
            setDetails={setDetails}
          />
        ) : null}
        {bancoDePrecios == "BANCO DE PRECIOS REFERENCIA"
          ? null
          : !creatingDetailInViewMode && (
              <button
                onClick={() => {
                  if (editMode) {
                    addEmptyDetail()
                  } else {
                    setCreatingDetailInViewMode(true)
                  }
                }}
                className="nuevoBotonCrear4"
                style={{ marginTop: "10px", width: "fit-content" }}
              >
                {i18n.t("+ Nuevo elemento")}
              </button>
            )}
        {bancoDePrecios && showDynamicElementButton && (
          <button
            onClick={() => {
              dynamicElement.setModalIsOpen(true)
              dynamicElement.setProjectId(projectId || null)
            }}
            className="nuevoBotonCrear4"
            style={{ marginTop: "10px", width: "fit-content" }}
          >
            {i18n.t("+ Elemento dinámico")}
          </button>
        )}
      </div>

      <DetailsFooter partidaState={partidaState} />
    </div>
  )
}

PartDetails.propTypes = {
  details: PropTypes.array.isRequired,
  setDetails: PropTypes.func.isRequired,
  partidaState: PropTypes.object.isRequired,
  creatingDetailInViewMode: PropTypes.bool.isRequired,
  setCreatingDetailInViewMode: PropTypes.func.isRequired,
  bancoDePrecios: PropTypes.string,
  showDynamicElementButton: PropTypes.bool,
}

export default PartDetails
