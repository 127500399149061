import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Container, Row } from "reactstrap"
import classNames from "classnames"
import i18n from "i18n"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import ExtrasAndContradictionsContext from "context/extrasAndContradictions/ExtrasAndContradictionsContext"
import ProjectContext from "context/projects/projectContext"
import ActionsButtons from "components/ActionsButtons/ActionsButtons"
import { DangerAlert } from "common/alerts"

const LayoutExtrasAndContradictions = ({ children }) => {
  const { projectId } = useParams()
  const { dispatchs, btnLoading, tree } = useContext(
    ExtrasAndContradictionsContext,
  )
  const { projectInfo } = useContext(ProjectContext)
  const history = useHistory()

  const closeExtras = async () => {
    await dispatchs.closeExtras(projectId)
    history.push(`/budgeting/${projectId}`)
  }

  const hasParts = () => tree?.getTotalPartCount() > 0

  return (
    <>
      <Row
        className={classNames(
          "d-flex justify-content-between mb-3 ms-2 align-items-center",
        )}
      >
        <div className="col">
          <span className="projectName">{projectInfo?.name}</span>
        </div>
        <div className="col">
          {hasParts() && <DangerAlert>{i18n.t("extras.alert")}</DangerAlert>}
        </div>
        <ActionsButtons className="col d-flex justify-content-end">
          <ActionsButtons.YellowButton
            title={i18n.t("extras.closeExtras")}
            loading={btnLoading}
            onClick={closeExtras}
          />
        </ActionsButtons>
      </Row>
      <Row>
        <Container fluid>{children}</Container>
      </Row>
    </>
  )
}

LayoutExtrasAndContradictions.propTypes = {
  children: PropTypes.node.isRequired,
}
export default LayoutExtrasAndContradictions
