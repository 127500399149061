import { useState } from "react"

export const useTagsInput = ({}) => {
  const [isKeyReleased, setIsKeyReleased] = useState(true)
  const [tags, setTags] = useState([])
  const [color, setColor] = useState(0)
  const [input, setInput] = useState("")
  const listaColores = [
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
    "color7",
    "color8",
  ]

  const onChange = e => {
    const { value } = e.target
    setInput(value)
  }
  const onKeyDown = e => {
    const { key } = e
    const trimmedInput = input.trim()
    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags]
      const poppedTag = tagsCopy.pop()
      e.preventDefault()
      setTags(tagsCopy)
      setInput(poppedTag[0])
    }

    setIsKeyReleased(false)
  }

  const onBlur = e => {
    if (input != "") {
      const trimmedInput = input.trim()
      e.preventDefault()
      setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }
  }

  const onKeyUp = () => {
    setIsKeyReleased(true)
  }
  const deleteTag = (e, index) => {
    e.preventDefault()
    setTags(prevState => prevState.filter((tag, i) => i !== index))
  }

  const setInitialTags = tagsList => {
    setTags(tagsList)
  }

  return {
    onKeyDown,
    onKeyUp,
    deleteTag,
    onChange,
    input,
    tags,
    setTags,
    onBlur,
    setInitialTags,
  }
}
