import React, { useContext, useEffect, useState } from "react"
import { PoppinsSemiBold, HelveticaBold } from "common/typographies"
import AuthContext from "context/auth/authContext"
import actsContext from "context/acts/actsContext"
import i18n from "i18n"
import ProjectContext from "context/projects/projectContext"
import PropTypes from "prop-types"
import ContactContext from "context/contact/contactContext"

const ActaStaticInfo = ({ setActaValues, actaValues }) => {
  const todayDate =
    new Date().getDate() +
    "/" +
    (new Date().getMonth() + 1) +
    "/" +
    new Date().getFullYear()
  const { user } = useContext(AuthContext)
  const { acts } = useContext(actsContext)
  const { projectInfo } = useContext(ProjectContext)
  const { Contacts } = useContext(ContactContext)
  const [client, setClient] = useState("-")

  useEffect(() => {
    if (projectInfo) {
      if (projectInfo.client) {
        searchClient(projectInfo.client)
      }
    }
  }, [projectInfo, Contacts])

  const searchClient = id => {
    if (Contacts) {
      let i = 0
      let found = false
      while (i < Contacts.length && !found) {
        if (Contacts[i].id === id) {
          setClient(Contacts[i].completeName)
          found = true
        }
        i++
      }
    }
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: "#F8F8F8",
          marginTop: "10px",
          marginBottom: "16px",
          marginLeft: "0px",
          borderRadius: "6px",
        }}
      >
        <div
          className="inline"
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              backgroundColor: "#F8F8F8",
              width: "20%",
              minWidth: "180px",
              maxHeight: "75px",
              borderTopLeftRadius: "6px",
              borderBottomLeftRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px",
                paddingTop: "8px",
                paddingBottom: "5px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  minWidth: "200px",
                }}
              >
                {i18n.t("Fecha")}
              </PoppinsSemiBold>
            </div>
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                marginLeft: "20px",
                color: "#414141",
              }}
            >
              {actaValues.date
                ? new Date(actaValues.date).getDate() +
                  "/" +
                  (new Date(actaValues.date).getMonth() + 1) +
                  "/" +
                  new Date(actaValues.date).getFullYear()
                : todayDate}
            </HelveticaBold>
          </div>

          <div
            style={{
              backgroundColor: "#F8F8F8",
              width: "20%",
              minWidth: "180px",
              maxHeight: "75px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px",
                paddingTop: "8px",
                paddingBottom: "5px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  minWidth: "200px",
                }}
              >
                {i18n.t("Numero de acta")}
              </PoppinsSemiBold>
            </div>
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                marginLeft: "20px",
                color: "#414141",
              }}
            >
              {actaValues.actNum
                ? actaValues.actNum
                : (acts?.length + 1).toString()}
            </HelveticaBold>
          </div>

          <div
            style={{
              backgroundColor: "#F8F8F8",
              width: "20%",
              minWidth: "180px",
              maxHeight: "75px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px",
                paddingTop: "8px",
                paddingBottom: "5px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  minWidth: "200px",
                }}
              >
                {i18n.t("Proyecto")}
              </PoppinsSemiBold>
            </div>
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                marginLeft: "20px",
                color: "#414141",
                textOverflow: "ellipsis",
                height: "23px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "block",
              }}
            >
              {projectInfo?.name}
            </HelveticaBold>
          </div>

          <div
            style={{
              backgroundColor: "#F8F8F8",
              width: "20%",
              minWidth: "180px",
              maxHeight: "75px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px",
                paddingTop: "8px",
                paddingBottom: "5px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  minWidth: "200px",
                }}
              >
                {i18n.t("Cliente")}
              </PoppinsSemiBold>
            </div>
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                marginLeft: "20px",
                color: "#414141",
              }}
            >
              {client}
            </HelveticaBold>
          </div>

          <div
            style={{
              backgroundColor: "#F8F8F8",
              width: "20%",
              minWidth: "180px",
              maxHeight: "75px",
              borderTopRightRadius: "6px",
              borderBottomLeftRadius: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "10px",
                paddingTop: "8px",
                paddingBottom: "5px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  minWidth: "200px",
                }}
              >
                {i18n.t("Director de obra")}
              </PoppinsSemiBold>
            </div>
            <HelveticaBold
              style={{
                fontWeight: "400",
                fontSize: "14px",
                marginLeft: "20px",
                color: "#414141",
              }}
            >
              {actaValues.director
                ? actaValues.director
                : user
                  ? user["custom:nombre_apellido"]
                    ? user["custom:nombre_apellido"]
                    : "-"
                  : "-"}
            </HelveticaBold>
          </div>
        </div>
      </div>
    </>
  )
}

ActaStaticInfo.propTypes = {
  setActaValues: PropTypes.func.isRequired,
  actaValues: PropTypes.object.isRequired,
}

export default ActaStaticInfo
