import React from "react"
import LayoutMiBancoDePrecios from "./layoutMiBancoDePrecios"
import { HelveticaBold } from "common/typographies.js"
import { SquareContent } from "common/containers"
import i18n from "i18n"
import SelectBDType from "./selectBDType"
import { useContext, useState, useEffect } from "react"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import Capitulo from "./chapters/Capitulo"
import AuthContext from "context/auth/authContext"
import TopQuickMenu from "components/topQuickMenu"
import { CircularProgress } from "@mui/material"
import ModalImportPartFromBc3Link from "pages/ModalPartidas/ModalImportPartFromBc3Link"
import Budget2023Context from "context/budget2023/Budget2023Context"

const BancoPreciosContent = () => {
  const {
    getMyBankPrices,
    myPricesBank,
    loading,
    addBankChapter,
    categoriesChecked,
    partsChecked,
    getMyBankPricesWithoutClassify,
    myNonSpecifiedPricesBank,
    cleanChapters,
    cleanParts,
  } = useContext(BankPricesContext)
  const { user, getUser, freeTrialExpired, openFreeTrialExpire } =
    useContext(AuthContext)
  const { toggleModalImportPartFromBc3Link, openModalImportPartFromBc3Link } =
    useContext(Budget2023Context)

  const [cont, setCont] = useState(0)

  useEffect(() => {
    if (freeTrialExpired) {
      openFreeTrialExpire()
    }
  }, [freeTrialExpired])

  useEffect(() => {
    if (user) {
      if (cont === 0) {
        cleanChapters()
        cleanParts()
        getMyBankPrices(user.sub)
        setTimeout(function () {
          getMyBankPricesWithoutClassify(user.sub)
        }, 500)

        setCont(1)
      }
    } else {
      getUser()
    }
  }, [user])

  const addChapter = () => {
    const data = {
      name: null,
      description: null,
      parentId: "root",
      price: null,
      tags: null,
      accountId: user.sub,
      code: null,
      root: "true",
    }
    addBankChapter(
      data.parentId,
      data.projectId,
      myPricesBank.subcapitulos.length,
      data.accountId,
      data.root,
    )
  }

  return (
    <>
      <LayoutMiBancoDePrecios />
      {loading ? (
        <>
          <div
            style={{
              width: "100%",
              position: "absolute",
              transform: "translateY(-50%, -50%)",
              top: "40%",
              left: "45%",
            }}
          >
            <CircularProgress
              style={{
                width: 150,
                height: 150,
                marginLeft: "0px",
                color: "#414141",
                marginBottom: "20px",
              }}
            />
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "24px",
                marginLeft: "10px",
                marginTop: "0px",
              }}
            >
              {i18n.t("Cargando...")}
            </HelveticaBold>
          </div>
        </>
      ) : myPricesBank.subcapitulos.length > 0 ? (
        <>
          <div style={{ marginLeft: "30px" }}>
            {myPricesBank.subcapitulos.map((capitulo, i) => (
              <Capitulo
                key={capitulo.chapterId}
                i={i}
                capitulo={capitulo}
                indice={i}
                referencia={i + 1}
                lengthSubCap={myPricesBank.subcapitulos.length}
              />
            ))}
          </div>
          {user ? (
            freeTrialExpired || user["custom:role"] === "WORKER" ? null : (
              <div className="NombreAlign">
                <button
                  onClick={() => {
                    addChapter()
                  }}
                  className="nuevoBotonCrear4"
                  style={{
                    marginTop: "10px",
                    width: "fit-content",
                    marginBottom: "10px",
                    marginLeft: "30px",
                  }}
                >
                  + {i18n.t("Nueva Categoría")}
                </button>
              </div>
            )
          ) : null}
        </>
      ) : (
        <div
          style={{
            justifyContent: "center",
            width: "100%",
            textAlign: "center",
            position: "relative",
            display: "flex",
            marginLeft: "-30px",
            height: "80vh",
          }}
        >
          <SquareContent style={{ top: "48%", boxShadow: "none" }}>
            <SelectBDType />
          </SquareContent>
        </div>
      )}
      {categoriesChecked.length > 0 ? (
        <TopQuickMenu />
      ) : partsChecked.length > 0 ? (
        <TopQuickMenu />
      ) : null}

      {openModalImportPartFromBc3Link && (
        <ModalImportPartFromBc3Link
          openModal={openModalImportPartFromBc3Link}
          setOpenModal={toggleModalImportPartFromBc3Link}
          callback={() => {
            // setOpenModalCreatePartida(true)
          }}
        />
      )}
    </>
  )
}

export default BancoPreciosContent
