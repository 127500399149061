import React, { Fragment, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import BudgetingVacio from "assets/images/budgeting/NoCaps.png"
import DownloadBudgetModal from "components/Feed/DownloadBudget"
import ContactContext from "context/contact/contactContext"
import LayoutBudget from "./LayoutBudget"
import NewCapitulo from "./NewCapitulo"
import { useParams } from "react-router-dom"
import Budget2023Context from "context/budget2023/Budget2023Context"
import { HelveticaBold } from "common/typographies"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import ProjectContext from "context/projects/projectContext"
import SendModal from "components/Feed/sendBudgetRefatctor/sendModal"
import TopQuickBudgetMenu from "./options/TopQuickBudgetMenu"
import { CircularProgress } from "@mui/material"
import ModalEditMargins from "./options/ModalEditMargins"
import * as amplitude from "@amplitude/analytics-browser"
import { getCurrency } from "utils/functions"

const BudgetStructure = ({
  soyFinal,
  version,
  index,
  imTemplate,
  certificaciones,
}) => {
  const {
    addCapituloV2,
    getBudgetTree,
    budgetTree,
    preferencias,
    setPreferencias,
    loadingTree,
    closeBudget,
    readyToClose,
    tagsFiltered,
    getVersionTree,
    chaptersChecked,
    partsChecked,
  } = useContext(Budget2023Context)
  const contactContext = useContext(ContactContext)
  const { Contacts, getContacts } = contactContext
  const { getProjectVersions, projectInfo } = useContext(ProjectContext)
  const [modificado, setModificado] = useState(0)
  const [contador, setCont] = useState(0)
  const { user, openFreeTrialExpire, freeTrialExpired } =
    useContext(AuthContext)
  const { projectId } = useParams()
  const [ocultoFinal, setOcultoFinal] = useState(false)

  useEffect(() => {
    amplitude.track({
      event_type: certificaciones
        ? "Certificaciones Visited"
        : "Budgeting Visited",
      event_properties: {
        projectId: projectId,
        projectName: projectInfo.name,
        accountEmail: user.email,
      },
    })
    if (version) {
      getVersionTree(version, projectId, soyFinal)
      getContacts()
      getProjectVersions(projectId, projectInfo.createdBy)
    } else {
      getBudgetTree(projectId, soyFinal)
      getContacts()
      getProjectVersions(projectId, projectInfo.createdBy)
    }
  }, [])
  const [dif, setDif] = useState(0)

  useEffect(() => {
    if (budgetTree) {
      if (
        budgetTree.clientPrice != 0 ||
        budgetTree.clientPriceWithoutDiscount != 0
      ) {
        if (preferencias.descuento == true) {
          setDif(
            currencyFormatDE(
              budgetTree.clientPrice - budgetTree.price,
              getCurrency(user["custom:currency"]),
            ),
          )
        } else {
          setDif(
            currencyFormatDE(
              budgetTree.clientPriceWithoutDiscount - budgetTree.price,
              getCurrency(user["custom:currency"]),
            ),
          )
        }
      } else {
        setDif(
          currencyFormatDE(parseFloat(0), getCurrency(user["custom:currency"])),
        )
      }
    }
  }, [
    budgetTree.price,
    budgetTree.clientPrice,
    budgetTree.clientPriceWithoutDiscount,
    preferencias.descuento,
  ])
  const [margins, setMargins] = useState(0)
  useEffect(() => {
    const margin =
      projectInfo.costeGeneral +
      projectInfo.industrialProfit +
      projectInfo.costeIndirecto
    const clientPrice =
      budgetTree.price * (1 + margin / 100) - budgetTree.totalDiscount
    setMargins(margin)
  }, [budgetTree.price])

  const [showAct, setshowAct] = useState(false)
  const [openSendBudgetModal, setOpenSendBudgetModal] = useState(false)
  const handleSendBudgetModal = () => {
    setOpenSendBudgetModal(true)
  }
  const [openDownloadBudgetModal, setOpenDownloadBudgetModal] = useState(false)
  const handleDownloadBudgetModal = async () => {
    setOpenDownloadBudgetModal(true)
  }
  useEffect(() => {
    if (contador != 0) {
      if (readyToClose == true) {
        setOpenDownloadBudgetModal(true)
      }
    }
  }, [readyToClose])

  const addCapitulo = () => {
    addCapituloV2(
      {
        name: "",
        price: 0,
        clientPrice: 0,
        tags: [],
        subcapitulos: [],
        partidas: [],
        parentId: "root",
        projectId: projectInfo.projectId,
        accountId: user.sub,
        position: budgetTree.subcapitulos.length,
        status: "OPEN",
        customCode: null,
        root: "true",
        sended: "false",
        selfManagement: "false",
      },
      budgetTree.subcapitulos.length,
    )
  }

  let numeroClases = 0
  function currencyFormatDE(num, simbol) {
    return (
      num
        .toFixed(2) // always two decimal digits
        .replace(".", ",") // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") +
      " " +
      simbol
    ) // use . as a separator
  }
  const [close, setClose] = useState(0)
  useEffect(() => {
    if (close == 1) {
      closeBudget()
      setClose(0)
    }
  }, [close])

  const [modificarMargen, setModificarMargen] = useState(false)
  const [isChecked, setIsChecked] = useState({
    isChecked: false,
    partida: null,
  })
  const [imDragging, setImDragging] = useState(false)

  const [isChecked2, setIsChecked2] = useState({
    isChecked2: false,
    capitulo: null,
  })
  const [imDragging2, setImDragging2] = useState(false)

  const [filterChapters, setFilterChapters] = useState([])
  const cargarCapitulosFiltered = () => {
    let aux = []
    for (let i = 0; i < budgetTree.subcapitulos.length; i++) {
      for (let j = 0; j < tagsFiltered.length; j++) {
        for (
          let y = 0;
          y < budgetTree.subcapitulos[i].capituloInfo.tags.length;
          y++
        ) {
          if (
            budgetTree.subcapitulos[i].capituloInfo.tags[y] === tagsFiltered[j]
          ) {
            let trobat2 = false
            for (let z = 0; z < aux.length; z++) {
              if (
                aux[z].capituloInfo.chapterId ==
                budgetTree.subcapitulos[i].capituloInfo.chapterId
              ) {
                trobat2 = true
              }
            }
            if (trobat2 == false) {
              aux.push(budgetTree.subcapitulos[i])
            }
          }
        }
      }
    }
    setFilterChapters(aux)
  }
  useEffect(() => {
    if (
      !loadingTree &&
      budgetTree.subcapitulos.length > 0 &&
      tagsFiltered.length > 0
    ) {
      cargarCapitulosFiltered()
    } else {
      if (tagsFiltered.length == 0) {
        setFilterChapters([])
      }
    }
  }, [tagsFiltered.length, loadingTree])

  const [open2, setOpen2] = useState(false)
  return (
    <>
      <LayoutBudget
        handleSendBudgetModal={handleSendBudgetModal}
        handleDownloadBudgetModal={handleDownloadBudgetModal}
        projectInfo={projectInfo}
        addCapitulo={addCapitulo}
        showAct={showAct}
        setshowAct={setshowAct}
        preferencias={preferencias}
        setPreferencias={setPreferencias}
        open3={openSendBudgetModal}
        setOpen3={setOpenSendBudgetModal}
        contactos={Contacts}
        soyFinal={soyFinal}
        setModificado={setModificado}
        version={version}
        index={index}
        certificaciones={certificaciones}
      >
        {loadingTree ? (
          <>
            <div
              style={{
                width: "100%",
                position: "absolute",
                transform: "translateY(-50%, -50%)",
                top: "40%",
                left: "45%",
              }}
            >
              <CircularProgress
                style={{
                  width: 150,
                  height: 150,
                  marginLeft: "0px",
                  color: "#414141",
                  marginBottom: "20px",
                }}
              />
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "24px",
                  marginLeft: "10px",
                  marginTop: "0px",
                }}
              >
                {i18n.t("Cargando...")}
              </HelveticaBold>
            </div>
          </>
        ) : filterChapters.length == 0 ? (
          budgetTree.subcapitulos.length > 0 ? (
            <div
              style={{
                overflowX: "hidden",
                marginLeft: "30px",
                marginRight: "12px",
              }}
            >
              {budgetTree.subcapitulos.map((subcapitulo, index) => (
                <NewCapitulo
                  imTemplate={imTemplate}
                  key={index}
                  indice={index}
                  capitulo={subcapitulo}
                  desplazamiento={24}
                  preferencias={preferencias}
                  setPreferencias={setPreferencias}
                  numeroClases={numeroClases}
                  numeroIndex={index + 1}
                  lengthSubCap={budgetTree.subcapitulos.length}
                  soyFinal={soyFinal}
                  projectInfo={projectInfo}
                  setModificado={setModificado}
                  modificado={modificado}
                  setClose={setClose}
                  modificarMargen={modificarMargen}
                  setModificarMargen={setModificarMargen}
                  ocultoFinal={ocultoFinal}
                  setOcultoFinal={setOcultoFinal}
                  isChecked={isChecked}
                  setIsChecked={setIsChecked}
                  isChecked2={isChecked2}
                  setIsChecked2={setIsChecked2}
                  priceWidth={3}
                  version={version}
                  certificaciones={certificaciones}
                />
              ))}
              {projectInfo.status === "CLOSED" ||
              version ||
              certificaciones ? null : (
                <div
                  className="NombreAlign"
                  onClick={freeTrialExpired ? openFreeTrialExpire : addCapitulo}
                >
                  <button
                    className="nuevoBotonCrear4"
                    style={{
                      marginTop: "10px",
                      marginBottom: "30px",
                      width: "fit-content",
                    }}
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="ChapterCreated"
                    data-amplify-analytics-attrs=""
                  >
                    + {i18n.t("Nuevo capítulo")}
                  </button>
                </div>
              )}
              {
                //Esta es la barrade presupuesto de coste
              }
              {certificaciones ? (
                <div id="desglosePresShow">
                  <div id="PresCoste">
                    <div style={{ marginRight: "15px" }}>
                      {/*<div
                        className="cuadMargins"
                        onClick={() => {
                          //freeTrialExpired
                          //  ? openFreeTrialExpire()
                          //  : setOpen2(true)
                        }}
                      >
                        <HelveticaBold
                          style={{
                            color: "white",
                            cursor: "pointer",
                            fontWeight: "700",
                            fontSize: "30px",
                            marginTop: "-2px",
                            position: "relative",
                          }}
                        >
//                          {{parseFloat(
//                            ((budgetTree.totalCertificateProject/budgetTree.tota) /
//                              budgetTree.price) *
//                              100
//                          ).toFixed(0)}}
                        </HelveticaBold>

                        <HelveticaBold
                          style={{
                            color: "white",
                            fontWeight: "700",
                            fontSize: "20px",
                            paddingTop: "8px",
                            marginLeft: "2px",
                          }}
                        >
                          %
                        </HelveticaBold>
                      </div>*/}
                      <p className="totalE">
                        {currencyFormatDE(
                          budgetTree.totalCertificateProject || 0,
                          getCurrency(user["custom:currency"]),
                        )}
                      </p>
                      <p className="subTitE">Total Certificado</p>
                    </div>
                  </div>
                  <div id="PresCoste">
                    <div style={{ marginRight: "15px" }}>
                      <p className="totalE">
                        {currencyFormatDE(
                          budgetTree.totalPreviousCertificateProject || 0,
                          getCurrency(user["custom:currency"]),
                        )}
                      </p>
                      <p className="subTitE" style={{ fontWeight: "700" }}>
                        Total Cert. Previa
                      </p>
                    </div>
                  </div>
                  <div id="PresCoste" style={{ marginRight: "40px" }}>
                    <div style={{ marginRight: "40px" }}>
                      <p className="totalE">
                        {currencyFormatDE(
                          budgetTree.totalCurrentCertificateProject || 0,
                          getCurrency(user["custom:currency"]),
                        )}
                      </p>
                      <p className="subTitE">Total Cert. Actual</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div id="desglosePresShow">
                  <div id="PresCoste">
                    <div style={{ marginRight: "15px" }}>
                      <div
                        className="cuadMargins"
                        onClick={() => {
                          freeTrialExpired
                            ? openFreeTrialExpire()
                            : setOpen2(true)
                        }}
                      >
                        <HelveticaBold
                          style={{
                            color: "white",
                            cursor: "pointer",
                            fontWeight: "700",
                            fontSize: "30px",
                            marginTop: "-2px",
                            position: "relative",
                          }}
                        >
                          {
                            //margins
                            preferencias.descuento == true
                              ? parseFloat(
                                  ((budgetTree.clientPrice - budgetTree.price) /
                                    budgetTree.price) *
                                    100,
                                ).toFixed(0)
                              : parseFloat(
                                  100 *
                                    (1 -
                                      budgetTree.price /
                                        budgetTree.clientPriceWithoutDiscount),
                                ).toFixed(0)
                          }
                        </HelveticaBold>
                        <HelveticaBold
                          style={{
                            color: "white",
                            fontWeight: "700",
                            fontSize: "20px",
                            paddingTop: "8px",
                            marginLeft: "2px",
                          }}
                        >
                          %
                        </HelveticaBold>
                      </div>
                      <p className="totalE">{dif}</p>
                      <p className="subTitE">{i18n.t("Mi márgen obj.")}</p>
                    </div>
                  </div>
                  <div id="PresCoste">
                    <div style={{ marginRight: "15px" }}>
                      <p className="totalE">
                        {currencyFormatDE(
                          soyFinal ? budgetTree.costPrice : budgetTree.price,
                          getCurrency(user["custom:currency"]),
                        )}
                      </p>
                      <p className="subTitE" style={{ fontWeight: "700" }}>
                        {i18n.t("Coste Obj. Total")}
                      </p>
                    </div>
                  </div>
                  {preferencias.costReal && false && (
                    // Where to force budgetTree.costReal to be calculated on load?
                    <div id="PresCoste">
                      <div style={{ marginRight: "15px" }}>
                        <p className="totalE">
                          {currencyFormatDE(
                            soyFinal ? budgetTree.costReal : 0,
                            getCurrency(user["custom:currency"]),
                          )}
                        </p>
                        <p className="subTitE" style={{ fontWeight: "700" }}>
                          {i18n.t("Coste Real Total")}
                        </p>
                      </div>
                    </div>
                  )}
                  <div id="PresCoste" style={{ marginRight: "40px" }}>
                    <div style={{ marginRight: "40px" }}>
                      <p className="totalE">
                        {preferencias.descuento == true
                          ? currencyFormatDE(
                              budgetTree.clientPrice
                                ? budgetTree.clientPrice
                                : 0,
                              getCurrency(user["custom:currency"]),
                            )
                          : currencyFormatDE(
                              budgetTree.clientPriceWithoutDiscount
                                ? budgetTree.clientPriceWithoutDiscount
                                : 0,
                              getCurrency(user["custom:currency"]),
                            )}
                      </p>
                      <p className="subTitE">
                        {i18n.t("Total Presupuesto Cliente")}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Fragment>
              <div id="centerPaco">
                <img
                  src={BudgetingVacio}
                  style={{ width: "343px", height: "321px", marginTop: "5%" }}
                ></img>
              </div>
            </Fragment>
          )
        ) : (
          <div
            style={{
              overflowX: "hidden",
              marginLeft: "30px",
              marginRight: "12px",
            }}
          >
            {filterChapters.map((subcapitulo, index) => (
              <NewCapitulo
                imTemplate={imTemplate}
                key={index}
                indice={index}
                capitulo={subcapitulo}
                desplazamiento={24}
                preferencias={preferencias}
                setPreferencias={setPreferencias}
                numeroClases={numeroClases}
                numeroIndex={index + 1}
                lengthSubCap={budgetTree.subcapitulos.length}
                soyFinal={soyFinal}
                projectInfo={projectInfo}
                setModificado={setModificado}
                modificado={modificado}
                setClose={setClose}
                modificarMargen={modificarMargen}
                setModificarMargen={setModificarMargen}
                ocultoFinal={ocultoFinal}
                setOcultoFinal={setOcultoFinal}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
                certificaciones={certificaciones}
                isChecked2={isChecked2}
                setIsChecked2={setIsChecked2}
                priceWidth={3}
                version={version}
              />
            ))}
            {projectInfo.status === "CLOSED" ||
            version ||
            certificaciones ? null : (
              <div
                className="NombreAlign"
                onClick={freeTrialExpired ? openFreeTrialExpire : addCapitulo}
              >
                <button
                  className="nuevoBotonCrear4"
                  style={{
                    marginTop: "10px",
                    marginBottom: "30px",
                    width: "fit-content",
                  }}
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="ChapterCreated"
                  data-amplify-analytics-attrs=""
                >
                  + {i18n.t("Nuevo capítulo")}
                </button>
              </div>
            )}
          </div>
        )}
      </LayoutBudget>
      {open2 && (
        <ModalEditMargins
          projectInfo={projectInfo}
          open={open2}
          setOpen={setOpen2}
        />
      )}
      {openSendBudgetModal && (
        <SendModal
          open={openSendBudgetModal}
          setOpen={setOpenSendBudgetModal}
          contactos={Contacts}
          soyFinal={soyFinal}
        />
      )}
      {openDownloadBudgetModal && (
        <DownloadBudgetModal
          open={openDownloadBudgetModal}
          setOpen={setOpenDownloadBudgetModal}
          name={projectInfo.name}
          soyFinal={soyFinal}
        />
      )}
      {(chaptersChecked.length > 0 || partsChecked.length > 0) && (
        <TopQuickBudgetMenu soyFinal={soyFinal} />
      )}
    </>
  )
}

BudgetStructure.propTypes = {
  soyFinal: PropTypes.bool,
  version: PropTypes.object,
  index: PropTypes.number,
  imTemplate: PropTypes.bool,
  certificaciones: PropTypes.bool,
}

export default BudgetStructure
