import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import ModalSelectPlantilla from "pages/Dashboard/modalSelectPlantilla"
import ModalCreateFromExcel from "pages/Dashboard/ModalCreateFromExcel"
import ContactContext from "context/contact/contactContext"
import excel from "assets/customIcons/excel.svg"
import bc3 from "assets/customIcons/bc3.svg"
import startPlanhopper from "assets/customIcons/logos/startPlanhopper.svg"
import plantilla from "assets/customIcons/plantilla.svg"
import higherThan from "assets/customIcons/ArrowHigherThan.svg"

const NoProjects = ({
  setUploadDB,
  setModal_standard,
  modal_first,
  setModal_first,
}) => {
  const handleModalStandard = () => {
    setModal_standard(true)
  }
  const handleCargar = () => {
    setModal_first(true)
  }

  const [openModalPlantilla, setOpenModalPlantilla] = useState(false)
  const [openModalExcel, setOpenModalExcel] = useState(false)
  const { Contacts } = useContext(ContactContext)

  return (
    <div
      id="projectsContainer"
      className="centered-container"
      style={{
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        border: "0px",
        backgroundColor: "#ffffff",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        paddingTop: "20px",
        flexDirection: "column",
        height: "620px",
        //height:'520px',
        width: "450px",
      }}
    >
      <div
        className="CardProjects"
        style={{
          marginTop: "0px",
          marginLeft: "0px",
          cursor: "pointer",
          border: "1px solid #c4c4c4",
        }}
        onClick={() => handleModalStandard(true)}
      >
        <div id="CardContent">
          <div className="ImgProyect" style={{ marginLeft: "0px" }}>
            <span>
              <img src={bc3} height="75px" width="150px" />
            </span>
          </div>
          <div
            id="CenterContentProyectCard"
            style={{ marginLeft: "105px", marginRight: "0px" }}
          >
            <div
              className="flex-grow-1 overflow-hidden"
              style={{ marginRight: "-10px" }}
            >
              <div
                id="AddProyecto"
                style={{ fontSize: "16px", fontWeight: "700" }}
              >
                {i18n.t("Cargar Mediciones")}
              </div>
              <p className="DescriptionText" style={{ fontSize: "13px" }}>
                Sube tu archivo bc3 y empieza a trabajar en tu presupuesto
              </p>
            </div>
            <img
              src={higherThan}
              style={{ width: "24px", height: "15px", marginTop: "30px" }}
            />
          </div>
        </div>
      </div>

      <div
        className="CardProjects"
        style={{
          marginTop: "0px",
          marginLeft: "0px",
          cursor: "pointer",
          border: "1px solid #c4c4c4",
        }}
        onClick={handleCargar}
      >
        <div id="CardContent">
          <div className="ImgProyect" style={{ marginLeft: "0px" }}>
            <span>
              <img src={startPlanhopper} height="75px" width="150px" />
            </span>
          </div>
          <div id="CenterContentProyectCard" style={{ marginLeft: "105px" }}>
            <div
              className="flex-grow-1 overflow-hidden"
              style={{ marginRight: "-10px" }}
            >
              <div
                id="AddProyecto"
                style={{ fontSize: "16px", fontWeight: "700" }}
              >
                {i18n.t("Comienza con Planhopper")}
              </div>
              <p
                className="DescriptionText"
                style={{ fontSize: "13px", marginRight: "0px" }}
              >
                Construye tu presupuesto utilizando las partidas de las bases de
                datos{" "}
              </p>
            </div>
            <img
              src={higherThan}
              style={{ width: "24px", height: "15px", marginTop: "30px" }}
            />
          </div>
        </div>
      </div>
      <div
        className="CardProjects"
        style={{
          marginTop: "0px",
          marginLeft: "0px",
          border: "1px solid #c4c4c4",
        }}
      >
        <div
          id="CardContent"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpenModalPlantilla(true)
          }}
        >
          <div
            className="ImgProyect"
            style={{ marginLeft: "0px", marginBottom: "60px" }}
          >
            <span>
              <img src={plantilla} height="75px" width="150px" />
            </span>
          </div>
          <div
            id="CenterContentProyectCard"
            style={{ marginLeft: "105px", marginRight: "0px" }}
          >
            <div
              className="flex-grow-1 overflow-hidden"
              style={{ marginRight: "-10px" }}
            >
              <div
                id="AddProyecto"
                style={{ fontSize: "16px", fontWeight: "700" }}
              >
                {i18n.t("Plantilla de referencia")}
              </div>
              <p className="DescriptionText" style={{ fontSize: "13px" }}>
                Selecciona una plantilla y empieza a trabajar en tu presupuesto
              </p>
            </div>
            <img
              src={higherThan}
              style={{ width: "24px", height: "15px", marginTop: "30px" }}
            />
          </div>
        </div>
      </div>
      {
        <div
          className="CardProjects"
          style={{
            marginTop: "0px",
            marginLeft: "0px",
            border: "1px solid #c4c4c4",
          }}
        >
          <div
            id="CardContent"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenModalExcel(true)
            }}
          >
            <div
              className="ImgProyect"
              style={{ marginLeft: "0px", marginBottom: "60px" }}
            >
              <span>
                <img src={excel} height="75px" width="150px" />
              </span>
            </div>
            <div
              id="CenterContentProyectCard"
              style={{ marginLeft: "105px", marginRight: "0px" }}
            >
              <div
                className="flex-grow-1 overflow-hidden"
                style={{ marginRight: "-10px" }}
              >
                <div
                  id="AddProyecto"
                  style={{ fontSize: "16px", fontWeight: "700" }}
                >
                  {i18n.t("Cargar Excel")}
                </div>
                <p className="DescriptionText" style={{ fontSize: "13px" }}>
                  Sube tu archivo excel y empieza a trabajar en tu presupuesto
                </p>
              </div>
              <img
                src={higherThan}
                style={{ width: "24px", height: "15px", marginTop: "30px" }}
              />
            </div>
          </div>
        </div>
      }
      {openModalPlantilla && (
        <ModalSelectPlantilla
          open={openModalPlantilla}
          setOpen={setOpenModalPlantilla}
        />
      )}
      <ModalCreateFromExcel
        modal_first={openModalExcel}
        setmodal_first={setOpenModalExcel}
        contactos={Contacts}
        imComingFromNoProjects={true}
      />
    </div>
  )
}

NoProjects.propTypes = {
  setUploadDB: PropTypes.func,
  setModal_standard: PropTypes.func,
  modal_first: PropTypes.bool,
  setModal_first: PropTypes.func,
}

export default NoProjects
