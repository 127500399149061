import { PoppinsNormal } from "common/typographies"
import i18n from "i18n"
import React, { useContext, useState } from "react"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import AuthContext from "context/auth/authContext"
import PropTypes from "prop-types"
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { CircularProgress } from "@mui/material"
import { SendOutlined } from "@mui/icons-material"
import ModalSendMultiple from "./modalSendMultiple"
import ProjectContext from "context/projects/projectContext"

const TopQuickInvoiceActions = ({
  invoices,
  setAllChecked,
  invoiceService,
  invoicesChecked,
  setInvoicesChecked,
  setInvoicesToMakeActions,
}) => {
  const [loading, setLoading] = useState(false)
  const { projectId } = useParams()
  const { user, freeTrialExpired } = useContext(AuthContext)
  const { getProject } = useContext(ProjectContext)
  const handleDelete = async () => {
    setLoading(true)
    if (invoices.length > 0) {
      for (let index = 0; index < invoices.length; index++) {
        await invoiceService.deleteInvoice(invoices[index].invoiceId)
      }
    }
    setLoading(false)
    if (projectId) {
      invoiceService.getByProject(projectId)
      getProject(projectId)
    } else {
      invoiceService.getByOrganization()
    }
  }

  const handleDownload = async () => {
    setLoading(true)
    if (invoices.length > 0) {
      for (let index = 0; index < invoices.length; index++) {
        await invoiceService.handleDownload({
          invoiceId: invoices[index].invoiceId,
        })
      }
    }
    setLoading(false)
    if (projectId) {
      invoiceService.getByProject(projectId)
      getProject(projectId)
    } else {
      invoiceService.getByOrganization()
    }
  }

  const handleDesmarcar = () => {
    setInvoicesChecked(invoicesChecked.map(invoice => false))
    setInvoicesToMakeActions([])
    setAllChecked(false)
  }
  const [openChapter, setOpenChapter] = useState(false)
  const [enviar, setEnviar] = useState(false)
  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "47px",
          backgroundColor: "#FFD52B",
          zIndex: "999",
          paddingTop: "15px",
        }}
      >
        <div className="inline">
          <div style={{ marginLeft: "10%" }} className="inline">
            <div className="tdcheckColumn">
              <input
                className="form-check-input"
                type="checkbox"
                checked={true}
                onChange={() => {
                  handleDesmarcar()
                }}
                id="flexCheckDefault"
                style={{
                  width: "13px",
                  height: "13px",
                  cursor: "pointer",
                  zIndex: "5",
                  position: "relative",
                }}
              ></input>
            </div>
            {invoices.length > 0 ? (
              invoices.length == 1 ? (
                <PoppinsNormal>
                  {invoices.length} {i18n.t("Factura seleccionado")}{" "}
                </PoppinsNormal>
              ) : (
                <PoppinsNormal>
                  {invoices.length} {i18n.t("facturas seleccionados")}{" "}
                </PoppinsNormal>
              )
            ) : null}
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "absolute",
              display: "flex",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <DeleteOutlinedIcon
                  onClick={() => {
                    user
                      ? user["custom:role"] === "WORKER" || freeTrialExpired
                        ? null
                        : handleDelete()
                      : null
                  }}
                  className="cuartoIconoShow"
                  style={{
                    color: user
                      ? user["custom:role"] === "WORKER" || freeTrialExpired
                        ? "#C4C4C4"
                        : "#414141"
                      : "#C4C4C4",
                    width: "24px",
                    marginRight: "3px",
                    cursor: "pointer",
                  }}
                />
                {user ? (
                  user["custom:role"] === "WORKER" ||
                  freeTrialExpired ? null : (
                    <div
                      className="cuartoIcono"
                      style={{
                        position: "absolute",
                        marginLeft: "-52px",
                        marginTop: "35px",
                      }}
                    >
                      <div className="recuadro">
                        <div className="recuadroPico"></div>
                        {i18n.t("Eliminar")}
                      </div>
                    </div>
                  )
                ) : null}

                <ArrowCircleDownIcon
                  onClick={() => {
                    user
                      ? user["custom:role"] === "WORKER" || freeTrialExpired
                        ? null
                        : handleDownload()
                      : null
                  }}
                  className="cuartoIconoShow"
                  style={{
                    color: user
                      ? user["custom:role"] === "WORKER" || freeTrialExpired
                        ? "#C4C4C4"
                        : "#414141"
                      : "#C4C4C4",
                    width: "22px",
                    marginTop: "1px",
                    marginRight: "3px",
                    cursor: "pointer",
                  }}
                />
                {user ? (
                  user["custom:role"] === "WORKER" ||
                  freeTrialExpired ? null : (
                    <div
                      className="cuartoIcono"
                      style={{
                        position: "absolute",
                        marginLeft: "-2px",
                        marginTop: "35px",
                      }}
                    >
                      <div className="recuadro">
                        <div className="recuadroPico"></div>
                        {i18n.t("Descargar")}
                      </div>
                    </div>
                  )
                ) : null}

                <SendOutlined
                  onClick={() => {
                    user
                      ? user["custom:role"] === "WORKER" || freeTrialExpired
                        ? null
                        : setEnviar(true)
                      : null
                  }}
                  className="cuartoIconoShow"
                  style={{
                    color: user
                      ? user["custom:role"] === "WORKER" || freeTrialExpired
                        ? "#C4C4C4"
                        : "#414141"
                      : "#C4C4C4",
                    width: "22px",
                    marginRight: "3px",
                    marginLeft: "2px",
                    marginTop: "1PX",
                    cursor: "pointer",
                  }}
                />
                {user ? (
                  user["custom:role"] === "WORKER" ||
                  freeTrialExpired ? null : (
                    <div
                      className="cuartoIcono"
                      style={{
                        position: "absolute",
                        marginLeft: "45px",
                        marginTop: "35px",
                      }}
                    >
                      <div className="recuadro">
                        <div className="recuadroPico"></div>
                        {i18n.t("Enviar")}
                      </div>
                    </div>
                  )
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      {enviar && (
        <ModalSendMultiple
          open={enviar}
          setOpen={setEnviar}
          invoices={invoices}
          invoiceService={invoiceService}
        />
      )}
    </>
  )
}

TopQuickInvoiceActions.propTypes = {
  invoices: PropTypes.array.isRequired,
  invoiceService: PropTypes.any.isRequired,
  invoicesChecked: PropTypes.array.isRequired,
  setInvoicesChecked: PropTypes.func.isRequired,
  setInvoicesToMakeActions: PropTypes.func.isRequired,
  setAllChecked: PropTypes.func.isRequired,
}

export default TopQuickInvoiceActions
