import SelectDynamicElementsModal from "components/DynamicElements/SelectDynamicElementsModal"
import ModalExpireFreeTrial from "components/UserView/ModalExpireFreeTrial"
import Budget2023Context from "context/budget2023/Budget2023Context"
import React, { useContext } from "react"

const WrapperModals = () => {
  const { dynamicElement } = useContext(Budget2023Context)
  return (
    <div aria-describedby="modals">
      <SelectDynamicElementsModal
        isOpen={dynamicElement.modalIsOpen}
        onClose={isOpen => {
          dynamicElement.setModalIsOpen(isOpen)
          dynamicElement.setProjectId(null)
        }}
        setSelectedElements={data => {
          dynamicElement.setSelected(data)
          dynamicElement.setModalIsOpen(false)
          dynamicElement.setProjectId(null)
        }}
        projectId={dynamicElement.projectId}
      />
      <ModalExpireFreeTrial />
    </div>
  )
}

export default WrapperModals
