import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter, useHistory } from "react-router-dom"
import i18n from "i18n"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ModalPreviewPDF from "../modals/ModalPreviewPDF"
import ModalSelectAsistentesToSend from "../modals/ModalSelectAsistentesToSend"
import { useReactToPrint } from "react-to-print"
import { Document, Page, pdfjs } from "react-pdf"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const DropdownActOptions = props => {
  const [menu, setMenu] = useState(false)
  const [openModalPreview, setOpenModalPreview] = useState(false)
  const [openSendModal, setOpenSendModal] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const refToPrint = React.useRef()
  const history = useHistory()
  const { projectId } = useParams()

  const handlePrint = useReactToPrint({
    content: () => refToPrint.current,
  })
  const [imprimir, setImprimir] = useState(false)

  const handleImprimir = () => {
    setImprimir(true)
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  useEffect(() => {
    if (imprimir) {
      handlePrint()
      setImprimir(false)
    }
  }, [imprimir])
  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{
            position: props.imInTable && "absolute",
            top: props.imInTable && "-20px",
          }}
        >
          <DropdownToggle
            id="settingb"
            style={{
              marginTop: "0px",
              color: "black",
              marginTop: "2.5px",
              padding: "0px",
              marginLeft: "10px",
            }}
          >
            <MoreVertIcon
              id="settingsBud"
              style={{ color: "#414141", marginTop: "6px" }}
            />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-end"
            id={props.imInTable ? "" : "positionActDropdown"}
          >
            <DropdownItem
              tag="d"
              style={{ cursor: "pointer" }}
              onClick={() => window.open(props.pdf)}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", marginRight: "4px" }}
                className="bx bx-down-arrow-circle font-size-16 align-middle "
              />
              {i18n.t("Descargar")}
            </DropdownItem>
            <DropdownItem
              tag="b"
              onClick={() => handleImprimir()}
              style={{ lineHeight: "20px", cursor: "pointer" }}
            >
              {" "}
              <div className="inline">
                <i
                  style={{ marginTop: "2px", marginRight: "5px" }}
                  className="bx bx-printer font-size-16 align-middle "
                />
                {i18n.t("Imprimir")}
              </div>
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpenSendModal(true)
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", marginRight: "5px" }}
                className="bx bx-send font-size-16 align-middle "
              />
              {i18n.t("Enviar")}
            </DropdownItem>
            {props.imInTable ? (
              <DropdownItem
                tag="b"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/editAct/" + projectId + "/" + props.actId)
                }}
              >
                {" "}
                <i
                  style={{ marginTop: "-3px" }}
                  className="bx bx-pencil font-size-16 align-middle me-1"
                />
                {i18n.t("Editar Acta")}
              </DropdownItem>
            ) : (
              <DropdownItem
                tag="b"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenModalPreview(true)
                }}
              >
                {" "}
                <i
                  style={{ marginTop: "-3px" }}
                  className="bx bx-file-blank font-size-16 align-middle me-1"
                />
                {i18n.t("Previsualizar PDF")}
              </DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>

      {openModalPreview && (
        <ModalPreviewPDF
          open={openModalPreview}
          setOpen={setOpenModalPreview}
          pdf={props.pdf}
          loadingPdf={props.loadingPdf}
          actNum={props.actNum}
        />
      )}
      {openSendModal && (
        <ModalSelectAsistentesToSend
          open={openSendModal}
          setOpen={setOpenSendModal}
          asistentes={props.asistentes}
          idActa={props.actId}
        />
      )}
      <div style={{ display: "none" }}>
        <Document
          scale={2}
          ref={refToPrint}
          file={props.pdf}
          onLoadError={console.error}
          style={{ overflowY: "auto" }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {[...Array(numPages)].map((elementInArray, index) => (
            <Page key={index} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </>
  )
}

DropdownActOptions.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  pdf: PropTypes.string,
  loadingPdf: PropTypes.bool,
  actNum: PropTypes.string,
  asistentes: PropTypes.array,
  imInTable: PropTypes.bool,
  actId: PropTypes.string,
}

export default withTranslation()(withRouter(DropdownActOptions))
