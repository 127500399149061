import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import { ReactComponent as MaterialIcon } from "assets/customIcons/materiales.svg"
import { ReactComponent as ManodeobraIcon } from "assets/customIcons/manodeobra.svg"
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"
import RecyclingIcon from "@mui/icons-material/Recycling"
import AutoDeleteOutlinedIcon from "@mui/icons-material/AutoDeleteOutlined"
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined"
import PropTypes from "prop-types"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"

const TypeDropdown = props => {
  const [menu, setMenu] = useState(false)

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block dropdown"
          style={{
            marginTop: "0px",
            width: "100%",
            border: "1px solid #C4C4C4",
            borderRadius: props.mode === "view" ? "6px" : "0px",
            display: "flex",
            alignItems: "center",
            marginBottom: "-12.5px",
            padding: props.mode === "view" ? "0px" : "3px",
            marginTop: props.mode === "edit" ? "-4px" : "-1px",
            height: `${props.size.height + 14}px`,
            borderTopLeftRadius:
              props.index == 0 ? "6px" : props.mode == "view" ? "6px" : "0px",
            borderBottomLeftRadius:
              props.details && props.details.length - 1 === props.index
                ? "6px"
                : props.mode == "view"
                  ? "6px"
                  : "0px",
          }}
        >
          <DropdownToggle
            className="inline"
            id="settingb"
            style={{
              color: "black",
              margin: "0px",
              padding: "0px",
              width: "100%",
              fontWeight: "400",
              height: "100%",
            }}
          >
            {props.type == "coste" ? (
              <MonetizationOnOutlinedIcon
                style={{
                  width: "20px",
                  color: "#c4c4c4",
                  height: "20px",
                  marginLeft: "1px",
                }}
              />
            ) : props.type == "material" ? (
              <MaterialIcon />
            ) : props.type == "manodeobra" ? (
              <ManodeobraIcon />
            ) : props.type == "maquinaria" ? (
              <LocalShippingOutlinedIcon
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "1px",
                  color: "#c4c4c4",
                }}
              />
            ) : props.type == "residuoAdicional" ? (
              <RecyclingIcon
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "1px",
                  color: "#c4c4c4",
                }}
              />
            ) : props.type == "residuoClasificacion" ? (
              <AutoDeleteOutlinedIcon
                style={{
                  width: "20px",
                  color: "#c4c4c4",
                  height: "20px",
                  marginLeft: "1px",
                }}
              />
            ) : (
              <ManodeobraIcon />
            )}
            <KeyboardArrowDown
              style={{
                color: "#414141",
                fontWeight: "400",
                width: "15px",
                marginTop: "-2px",
                fontSize: "14px",
              }}
            />
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-end2"
            style={{ margin: "0px 0px" }}
          >
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setType("coste")
              }}
            >
              <MonetizationOnOutlinedIcon
                style={{ width: "20px", height: "20px", color: "#c4c4c4" }}
              />{" "}
              Costes
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setType("material")
              }}
            >
              <MaterialIcon /> Material
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setType("manodeobra")
              }}
            >
              <ManodeobraIcon /> Mano de obra
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setType("maquinaria")
              }}
            >
              <LocalShippingOutlinedIcon
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "-1px",
                  color: "#c4c4c4",
                }}
              />{" "}
              Maquinaria y medios auxiliares
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setType("residuoAdicional")
              }}
            >
              <RecyclingIcon
                style={{ width: "21px", height: "21px", color: "#c4c4c4" }}
              />{" "}
              Componentes adicionales de residuo
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setType("residuoClasificacion")
              }}
            >
              <AutoDeleteOutlinedIcon
                style={{ width: "20px", height: "20px", color: "#c4c4c4" }}
              />{" "}
              Clasificación de residuo
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </>
  )
}

TypeDropdown.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  setType: PropTypes.func,
  type: PropTypes.string,
  size: PropTypes.any,
  mode: PropTypes.string,
  index: PropTypes.any,
  details: PropTypes.any,
}

export default withTranslation()(withRouter(TypeDropdown))
