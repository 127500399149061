import React, { useContext, useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress, Modal } from "@mui/material"
import PropTypes from "prop-types"
import i18n from "i18n"
import { HelveticaBold } from "common/typographies"
import { useProgressPdf } from "hooks/useProgressPdf"
import AuthContext from "context/auth/authContext"
import axios from "axios"
import ReactPolling from "react-polling"
import { API, Auth } from "aws-amplify"
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { isLocalhost, API_URL } from "utils/functions"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "420px",
    height: "400px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],

    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1, ml: 2 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
}

const ModalOpenDownload = ({
  open,
  setOpen,
  projectId,
  setOpenError,
  urlId,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [header, setHeader] = useState(null)

  useEffect(() => {
    getHeader()
  }, [])

  const getHeader = async () => {
    setHeader((await Auth.currentSession()).getIdToken().getJwtToken())
  }

  const [progress, setProgress] = useState(0)

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <div>
            <div>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  textAlign: "center",
                  fontFamily: "Helvetica",
                  marginTop: "30px",
                }}
              >
                {i18n.t("Estamos generando tu presupuesto")}
              </p>
              <p
                style={{
                  fontStyle: "normal",
                  fontWeight: "300",
                  fontSize: "14px",
                  lineHeight: "16px",
                  textAlign: "center",
                  fontFamily: "Helvetica",
                }}
              >
                Esto puede tomar un minuto. Por favor no te
                <br /> vayas, ni cierres esta pestaña
                <div style={{ marginBottom: "10px" }} className="loader">
                  <div className="ball"></div>
                  <div className="ball"></div>
                  <div className="ball"></div>
                </div>
                {header != null && (
                  <ReactPolling
                    url={API_URL + "/budget/project/pdf/query/" + urlId}
                    headers={{
                      Authorization: `Bearer ${header}`,
                      "Access-Control-Allow-Origin": "*",
                    }}
                    method={"get"}
                    interval={3000} // in milliseconds(ms)
                    retryCount={3} // this is optional
                    onSuccess={resp => {
                      setProgress(
                        parseInt(
                          (resp.process.parts_done * 100) /
                            resp.process.parts_total,
                        ),
                      )
                      if (resp.process.error) {
                        setOpenError(true)
                        setOpen(false)
                        return true
                      }

                      if (resp.process.url) {
                        setTimeout(() => {
                          axios({
                            url: resp.process.url,
                            method: "GET",
                            responseType: "blob",
                          }).then(response => {
                            const url = window.URL.createObjectURL(
                              new Blob([response.data]),
                            )
                            const link = document.createElement("a")
                            link.href = url
                            link.setAttribute("download", "presupuesto.pdf") //or any other extension
                            document.body.appendChild(link)
                            link.click()
                          })
                        }, 1000)
                        setOpen(false)
                        return false
                      }
                      console.log(resp)
                      return true
                    }}
                    onFailure={() => console.log("handle failure")} // this is optional
                    render={({ startPolling, stopPolling, isPolling }) => {
                      if (isPolling) {
                        return (
                          <div>
                            <LinearProgressWithLabel value={progress} />
                          </div>
                        )
                      } else {
                        return <></>
                      }
                    }}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

ModalOpenDownload.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  projectId: PropTypes.string,
  urlId: PropTypes.string,
  setOpenError: PropTypes.func,
}

export default ModalOpenDownload
