import React, { useReducer } from "react"
import PlanningContext from "./planningContext"
import { API, Auth } from "aws-amplify"
import PropTypes from "prop-types"
import PlanningReducer from "./planningReducer"

const PlanningState = props => {
  const initialState = {
    tasks: [],
    gantt: [],
    tree: [],
    filtro: [],
    dependencias: [],
  }
  const [state, dispatch] = useReducer(PlanningReducer, initialState)

  const PlanningService = "service-planning"

  const getTasksByProject = async projectId => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const res = await API.get(
        PlanningService,
        `/kanban/${projectId}`,
        payload,
      )
      dispatch({
        type: "GET_TASKS_BY_PROJECT",
        payload: res,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const addPlanningColumn = async (data, projectId) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          id: data.id,
          title: data.title,
          color: data.color,
          bgColor: data.bgColor,
        },
      }
      const res = await API.post(
        PlanningService,
        `/kanban/addList/${projectId}`,
        payload,
      )
      dispatch({
        type: "ADD_COLUMN",
        payload: res,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const delPlanningColumn = async (projectId, columnId) => {
    let payload = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    const res = await API.del(
      PlanningService,
      `/kanban/removeList/${projectId}/${columnId}`,
      payload,
    )
    dispatch({
      type: "DELETE_COLUMN",
      payload: columnId,
    })
  }

  const updatePlanningColumn = async (data, projectId) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          id: data.id,
          title: data.title,
          color: data.color,
          bgColor: data.bgColor,
        },
      }
      const res = await API.put(
        PlanningService,
        `/kanban/updateList/${projectId}`,
        payload,
      )
      dispatch({
        type: "UPDATE_COLUMN",
        payload: data,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const updatePlanningTask = async (data, projectId, source, destination) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          ...data,
          projectId: projectId,
        },
      }
      const res = await API.put(PlanningService, `/task/update`, payload)
      dispatch({
        type: "UPDATE_TASK",
        payload: {
          res: res,
          source: source,
          destination: destination,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const getGanttByProject = async projectId => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
      }
      const res = await API.get(PlanningService, `/gantt/${projectId}`, payload)
      dispatch({
        type: "CREATE_GANTT",
        payload: res,
      })
    } catch (e) {
      console.log(e)
    }
  }

  const updateFiltro = lista => {
    dispatch({
      type: "UPDATE_FILTER",
      payload: lista,
    })
  }

  return (
    <PlanningContext.Provider
      value={{
        filtro: state.filtro,
        tasks: state.tasks,
        gantt: state.gantt,
        tree: state.tree,
        dependencias: state.dependencias,
        getTasksByProject,
        addPlanningColumn,
        delPlanningColumn,
        updatePlanningColumn,
        updatePlanningTask,
        getGanttByProject,
        updateFiltro,
      }}
    >
      {props.children}
    </PlanningContext.Provider>
  )
}

PlanningState.propTypes = {
  children: PropTypes.element.isRequired,
}

export default PlanningState
