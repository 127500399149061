import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import DropdownSetStatus from "components/Facturas/dropdowns/dropdownSetStatus"
import { PoppinsSemiBold } from "common/typographies"
import DropdownPaymentSelect from "components/BillingTemplate/dropdownPaymentSelect"
import { HelveticaInput } from "common/inputs"
import i18n from "i18n"
const InvoicePageAdditionalData = ({ invoiceService }) => {
  const [paymentType, setPaymentType] = useState(
    invoiceService.invoice.invoicePaymentMethod,
  )
  const [status, setStatus] = useState(invoiceService.invoice.invoiceStatus)

  useEffect(() => {
    console.log(status)
    status === "PAID"
      ? invoiceService.setInvoice({
          ...invoiceService.invoice,
          invoiceStatus: "PAID",
        })
      : invoiceService.setInvoice({
          ...invoiceService.invoice,
          invoiceStatus: "UNPAID",
        })
  }, [status])

  useEffect(() => {
    invoiceService.setInvoice({
      ...invoiceService.invoice,
      invoicePaymentMethod: paymentType,
    })
  }, [paymentType])

  const handleChange = e => {
    invoiceService.setInvoice({
      ...invoiceService.invoice,
      [e.target.name]: e.target.value,
    })
  }
  return (
    <>
      <div
        className="inline"
        style={{
          width: "96.4%",
          height: "auto",
          marginTop: "10px",
          marginBottom: "10px",
          marginLeft: "30px",
          borderRadius: "6px",
        }}
      >
        <div
          className="inline"
          style={{
            backgroundColor: "#F8F8F8",
            width: "50%",
            height: "200px",
            paddingTop: "10px",
            paddingBottom: "8px",
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
            marginRight: "30px",
            borderRadius: "6px",
          }}
        >
          <div style={{ width: "100%", minWidth: "250px" }}>
            <div
              style={{
                display: "flex",
                padding: "10px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  minWidth: "200px",
                }}
              >
                {i18n.t("Mensaje visible en la factura")}
              </PoppinsSemiBold>
            </div>
            <textarea
              suppressContentEditableWarning={true}
              contentEditable="true"
              value={invoiceService.invoice.invoiceMessage}
              id="descripcionInput"
              name="invoiceMessage"
              onChange={handleChange}
              placeholder={i18n.t("Escribe un mensaje")}
              style={{
                marginLeft: "20px",
                marginRight: "30px",
                width: "95%",
                height: "70%",
                position: "relative",
                paddingTop: "10px",
                paddingLeft: "10px",
                resize: "none",
              }}
            />
          </div>
        </div>
        <div
          className="inline"
          style={{
            backgroundColor: "#F8F8F8",
            width: "50%",
            height: "100%",
            paddingTop: "10px",
            paddingBottom: "8px",
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
            borderRadius: "6px",
          }}
        >
          <div style={{ width: "100%", minWidth: "250px" }}>
            <div
              style={{
                display: "flex",
                padding: "10px",
                marginBottom: "-10px",
              }}
            >
              <PoppinsSemiBold
                style={{
                  fontSize: "14px",
                  marginLeft: "10px",
                  marginBottom: "26px",
                  minWidth: "200px",
                }}
              >
                {i18n.t("Seleccionar metodo de pago")}
              </PoppinsSemiBold>
            </div>
            <DropdownPaymentSelect
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              imInInvoice={true}
            />
            <div
              className="inline"
              style={{ width: "100%", marginTop: "12px" }}
            >
              <div
                style={{ display: "block", marginRight: "30px", width: "55%" }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    marginBottom: "-10px",
                  }}
                >
                  <PoppinsSemiBold
                    style={{
                      fontSize: "14px",
                      marginLeft: "10px",
                      minWidth: "200px",
                    }}
                  >
                    {i18n.t("IBAN")}
                  </PoppinsSemiBold>
                </div>
                <HelveticaInput
                  value={invoiceService.invoice.iban}
                  style={{
                    marginTop: "-25px",
                    marginLeft: "20px",
                    width: "100%",
                    paddingLeft: "10px",
                    height: "40px",
                  }}
                  name="iban"
                  onChange={handleChange}
                />
              </div>
              <div
                style={{ display: "block", width: "34%", marginRight: "30px" }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "10px",
                    marginBottom: "-10px",
                  }}
                >
                  <PoppinsSemiBold
                    style={{
                      fontSize: "14px",
                      marginLeft: "10px",
                      minWidth: "140px",
                    }}
                  >
                    {i18n.t("Estado de factura")}
                  </PoppinsSemiBold>
                </div>
                <DropdownSetStatus status={status} setStatus={setStatus} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
InvoicePageAdditionalData.propTypes = {
  invoiceService: PropTypes.any,
}
export default InvoicePageAdditionalData
