import TemplateCategories from "components/Templates/templateCategories"
import React, { useContext, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { useParams } from "react-router-dom"
import { Container } from "reactstrap"
import TemplatesContext from "context/templates/templatesContext"
import AuthContext from "context/auth/authContext"

const TemplatePage = () => {
  const { templateId } = useParams()
  const { getTemplate } = useContext(TemplatesContext)
  const { user, getUser } = useContext(AuthContext)
  useEffect(() => {
    getTemplate(templateId)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper | Plantillas </title>
        </MetaTags>
        <Container fluid>
          <TemplateCategories />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TemplatePage
