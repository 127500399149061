import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import DynamicElementsContext from "context/dynamicElements/DynamicElementsContext"
import { GridActionsCellItem } from "@mui/x-data-grid"
import ActionIcon from "./ActionIcon"
import { Delete } from "@mui/icons-material"
import i18n from "i18n"

const DynamicElementDeleteAction = ({ params }) => {
  const { dispatches, deleteElement } = useContext(DynamicElementsContext)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async () => {
    dispatches.setConfirmDeleteModal(true)
    dispatches.setElementToDelete(params.row)
  }

  useEffect(() => {
    const shouldSetLoading =
      params.row.itemId === deleteElement.elementToDelete?.itemId

    if (isLoading !== shouldSetLoading) {
      setIsLoading(shouldSetLoading)
    }
  }, [deleteElement.elementToDelete, isLoading, params.row.itemId])

  return (
    <GridActionsCellItem
      onClick={handleSubmit}
      label=""
      icon={
        <ActionIcon
          success={false}
          loading={isLoading}
          handleSubmit={() => {}}
          ActionComponent={Delete}
          isActionComponentPrimary={false}
          tooltipTitle={i18n.t("buttons.delete")}
        />
      }
    />
  )
}

DynamicElementDeleteAction.propTypes = {
  params: PropTypes.shape({
    row: PropTypes.object.isRequired,
  }).isRequired,
}

export default DynamicElementDeleteAction
