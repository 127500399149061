import React, { useRef, useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { CircularProgress, Modal } from "@mui/material"
import { HelveticaBold } from "common/typographies"
import { makeStyles } from "@material-ui/core/styles"
import ImageDropZone from "components/BudgetingTemplate/imageDropZone"
import cover from "assets/customIcons/pdfs/cover.png"
import contraPortada from "assets/customIcons/pdfs/contraPortada.png"
import {
  CheckCircleRounded,
  CloseOutlined,
  DeleteOutline,
} from "@mui/icons-material"
import { BlackHoverButton4CreateDynamically } from "common/containers"
import { Document, Page, pdfjs } from "react-pdf"
import i18n from "i18n"
import ProjectContext from "context/projects/projectContext"
import { useUtils } from "hooks/useUtils"
import { CheckedInput, CustomCheckedInput } from "common/inputs"
import AuthContext from "context/auth/authContext"
import { PoppinsNormal, HelveticaLight } from "common/typographies"
import {
  DeleteOutlineOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material"
import { HelveticaInput2 } from "common/inputs"
import AddIcon from "@mui/icons-material/Add"

const PdfConfig = () => {
  const [loading, setLoading] = useState(false)

  const [pageSelected, setPageSelected] = useState(0)
  const [coverPdf, setCoverPdf] = useState(null)
  const [selectedFiles, setselectedFiles] = useState([])
  const [selectedFiles2, setselectedFiles2] = useState([])
  const [logo, setLogo] = useState(null)
  const {
    user,
    getOrganization,
    updateOrganization,
    getOrganizationNew,
    organizationFields,
  } = useContext(AuthContext)

  const [numPages, setNumPages] = useState(1)
  const componentRef = useRef()

  const changePage = page => {
    setPageSelected(page)
  }
  const { updateCoverAndConditions, getProject } = useContext(ProjectContext)

  const referenciaComment = useRef(null)

  useEffect(() => {
    console.log("eeeaaa", organizationFields)
    if (referenciaComment.current) {
      if (organizationFields.conditions) {
        referenciaComment.current.innerText = organizationFields.conditions
      }
    }
    if (organizationFields.conditions) {
      setConditions(organizationFields.conditions)
    }
    if (organizationFields.cover) {
      console.log("eeee")
      setCoverPdf(organizationFields.cover)
    }
    if (organizationFields.conditions) {
      setConditions(organizationFields.conditions)
    }
    if (organizationFields.signature) {
      setLogo(organizationFields.signature)
    }
  }, [referenciaComment, organizationFields])

  const [conditions, setConditions] = useState("")

  const onChangeConditions = e => {
    setConditions(e.target.value)
  }

  const { uploading, generateFileUrl } = useUtils()
  console.log(user)

  const saveProjectPdf = async () => {
    setLoading(true)
    let url = ""
    let firma = ""
    if (selectedFiles.length > 0) {
      url = await generateFileUrl(selectedFiles[0], "protected")
    }
    if (selectedFiles2.length > 0) {
      firma = await generateFileUrl(selectedFiles2[0], "protected")
    }

    let campos = {
      cover: url != "" ? url : coverPdf != null ? coverPdf : "",
      conditions: conditions != "" ? conditions : organizationFields.conditions,
      signature: firma != "" ? firma : logo != null ? logo : "",
    }

    await updateOrganization(user["custom:organizationId"], campos)
    setLoading(false)
  }

  return (
    <div
      style={{ width: "100%", position: "relative", justifyContent: "center" }}
    >
      <div>
        <div className="inline" style={{ marginTop: "10px" }}>
          <div
            onClick={() => {
              changePage(0)
            }}
            className="selectCard"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "35px",
              cursor: "pointer",
              position: "relative",
              marginBottom: "15px",
              width: "100%",
            }}
          >
            <img
              src={cover}
              style={{
                height: "70%",
                marginTop: "5px",
                opacity: pageSelected == 0 ? "1" : "0.5",
              }}
            />
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "14px",
                marginTop: "6PX",
                marginLeft: "5PX",
                opacity: pageSelected == 0 ? "1" : "0.5",
              }}
            >
              Portada
            </HelveticaBold>
          </div>

          <div
            onClick={() => {
              changePage(1)
            }}
            className="selectCard"
            style={{
              display: "flex",
              height: "35px",
              justifyContent: "center",
              cursor: "pointer",
              position: "relative",
              width: "100%",
            }}
          >
            <img
              src={contraPortada}
              style={{
                height: "70%",
                marginTop: "5px",
                opacity: pageSelected == 1 ? "1" : "0.5",
              }}
            />
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "14px",
                marginTop: "6PX",
                marginLeft: "5PX",
                opacity: pageSelected == 1 ? "1" : "0.5",
              }}
            >
              Contraportada
            </HelveticaBold>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {pageSelected == 0 ? (
            <>
              {!coverPdf ? (
                <ImageDropZone
                  selectedFiles={selectedFiles}
                  setselectedFiles={setselectedFiles}
                  setImage={setCoverPdf}
                  message={"Debe subir un archivo pdf de una sola pagina"}
                  accept={[".PDF", ".pdf"]}
                />
              ) : (
                <>
                  <div className="inline">
                    <CheckCircleRounded
                      style={{
                        color: "#FFD52B",
                        marginRight: "5px",
                        marginLeft: "2px",
                        fontSize: "16px",
                      }}
                    />
                    <HelveticaBold
                      style={{
                        color: "#414141",
                        marginTop: "-3px",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {selectedFiles[0]?.path}
                    </HelveticaBold>
                  </div>
                  <BlackHoverButton4CreateDynamically
                    onClick={() => {
                      setCoverPdf(null)
                      setselectedFiles([])
                    }}
                    style={{
                      marginTop: "0px",
                      marginBottom: "10px",
                      marginLeft: "2px",
                      width: "fit-content",
                      paddingRight: "7px",
                    }}
                  >
                    <DeleteOutline
                      style={{
                        fontSize: "18px",
                        marginRight: "5px",
                        color: "#414141",
                        marginLeft: "-5px",
                        paddingTop: "1px",
                      }}
                    />
                    <HelveticaBold
                      style={{
                        color: "#414141",
                        fontWeight: "600",
                        paddingTop: "1px",
                        fontSize: "12px",
                      }}
                    >
                      {i18n.t("Eliminar")}
                    </HelveticaBold>
                  </BlackHoverButton4CreateDynamically>
                </>
              )}
              {coverPdf != null && (
                <div
                  style={{
                    height: "222px",
                    marginBottom: "0px",
                    marginLeft: "0px",
                    marginRight: "20px",
                    display: "flex",
                    justifyContent: "center",
                    overflowY: "auto",
                  }}
                >
                  {coverPdf != null && (
                    <Document
                      ref={componentRef}
                      file={coverPdf}
                      onLoadError={console.error}
                      style={{ overflowY: "auto" }}
                    >
                      {[...Array(numPages)].map((elementInArray, index) => (
                        <Page scale={1} key={index} pageNumber={index + 1} />
                      ))}
                    </Document>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="inline">
              <div style={{ marginRight: "60px" }}>
                <div
                  id="DivTitleEliminar"
                  style={{ marginTop: "12px", justifyContent: "center" }}
                >
                  <p
                    id="centradoBudget"
                    style={{
                      fontSize: "12px",
                      marginTop: "0px",
                      marginBottom: "20px",
                      fontWeight: "300",
                    }}
                  >
                    {" "}
                    Escribe aqui las condiciones generales de tu proyecto
                  </p>
                </div>

                <textarea
                  ref={referenciaComment}
                  suppressContentEditableWarning={true}
                  onChange={e => {
                    onChangeConditions(e)
                  }}
                  id="descripcionInput"
                  onBlur={e => {
                    onChangeConditions(e)
                  }}
                  name="comment"
                  placeholder="Escribe aquí las condiciones"
                  style={{
                    width: "600px",
                    height: "230px",
                    position: "relative",
                    paddingTop: "10px",
                    paddingLeft: "10px",
                    resize: "none",
                  }}
                >
                  {organizationFields.conditions}
                </textarea>
              </div>
              {logo === null ? (
                <div
                  style={{
                    marginBottom: "40px",
                    width: "400px",
                    marginTop: "0px",
                  }}
                >
                  <div
                    id="DivTitleEliminar"
                    style={{ marginTop: "12px", justifyContent: "center" }}
                  >
                    <p
                      id="centradoBudget"
                      style={{
                        fontSize: "12px",
                        marginTop: "0px",
                        marginBottom: "20px",
                        fontWeight: "300",
                      }}
                    >
                      {" "}
                      Sube aqui tu firma
                    </p>
                  </div>
                  <ImageDropZone
                    selectedFiles={selectedFiles2}
                    setselectedFiles={setselectedFiles2}
                    setImage={setLogo}
                  />
                </div>
              ) : (
                <>
                  <img
                    style={{
                      maxWidth: "320px",
                      maxHeight: "160px",
                      marginBottom: "50px",
                      marginTop: "50px",
                    }}
                    src={logo}
                  />
                  <CloseOutlined
                    onClick={() => {
                      setselectedFiles2([])
                      setLogo(null)
                    }}
                    style={{
                      width: "15px",
                      marginTop: "28px",
                      cursor: "pointer",
                    }}
                  ></CloseOutlined>
                </>
              )}
            </div>
          )}
        </div>

        {loading ? (
          <CircularProgress
            size={24}
            style={{ marginTop: "30px", marginLeft: "170px" }}
          />
        ) : (
          <div id="centerButton" style={{ marginBottom: "6px" }}>
            <button
              style={{ height: "38px", width: "103px" }}
              type="button"
              className="AddContact"
              onClick={saveProjectPdf}
            >
              {i18n.t("Guardar")}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default PdfConfig
