import i18n from "i18n"
import React, { useState, useContext, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { Modal, Typography } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import { useUtils } from "hooks/useUtils"
import UploadRoundedIcon from "@mui/icons-material/UploadRounded"
import Dropzone from "react-dropzone"
import DropdownSetProject from "components/Facturas/dropdowns/dropdownSetProject"
import ProjectContext from "context/projects/projectContext"
import { Link } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"
import { CircularProgress } from "@mui/material"
import { YellowButton } from "common/buttons"
import { Card, Row, Col } from "reactstrap"
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}
const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "50%",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "500px",
    overflowY: "hidden",
    overflowX: "hidden",
    paddingLeft: "1rem",
    paddingRight: "-1rem",
    minHeight: "200px",
  },
}))
const ModalScanInvoices = ({ open, setOpen, invoiceService }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { uploading, generateFileUrl } = useUtils()
  const [selectedFiles, setSelectedFiles] = useState([])
  const [urlFile, setUrlFile] = useState(null)
  const { projects, getProjects } = useContext(ProjectContext)
  const handleAcceptedFiles = async files => {
    setSelectedFiles(files)
    const url = await generateFileUrl(files[0], "protected")
    setUrlFile(url)
  }

  useEffect(() => {
    getProjects()
  }, [])
  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <div style={modalStyle} className={classes.paper}>
          <Typography
            variant="p"
            style={{
              fontWeight: "bold",
              marginBottom: "2rem",
              marginLeft: "20px",
            }}
          >
            {i18n.t("Escoje tu proyecto")}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: "40px",
            }}
          >
            <DropdownSetProject
              invoice={invoiceService.invoice}
              setInvoice={invoiceService.setInvoice}
              projectList={projects}
              getIdentifierNumber={invoiceService.getIdentifierNumber}
              projectError={invoiceService.projectError}
            />
          </div>

          <Fragment>
            <Dropzone
              className="dropzone"
              onDrop={acceptedFiles => {
                handleAcceptedFiles(acceptedFiles)
              }}
              accept={["image/*", ".pdf", ".png", ".jpg", ".jpeg"]}
              maxFiles={1}
              disabled={selectedFiles.length > 0}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  className="dropzone2"
                  style={{
                    marginLeft: "20px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <p
                      style={{
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "19px",
                      }}
                    >
                      {i18n.t("Arrastra tu factura o explora en tu ordenador")}
                    </p>
                    <div className="mb-3">
                      <UploadRoundedIcon
                        style={{ height: "60px", width: "60px" }}
                      />
                    </div>
                    <p
                      style={{
                        fontWeight: "300",
                        fontSize: "14px",
                        lineHeight: "16px",
                        textAlign: "center",
                      }}
                    >
                      {i18n.t(
                        "El formato en los que deberás cargar los archivos debe ser PDF, JPG o PNG",
                      )}
                    </p>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <i className="bx bx-file" />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                        {uploading ? (
                          <CircularProgress
                            style={{
                              marginRight: "1px",
                              cursor: "pointer",
                              width: "45px",
                            }}
                          />
                        ) : (
                          <CloseIcon
                            style={{
                              marginRight: "1px",
                              cursor: "pointer",
                              width: "45px",
                            }}
                            onClick={() => {
                              setSelectedFiles([])
                            }}
                          />
                        )}
                      </Row>
                    </div>
                  </Card>
                )
              })}
            </div>
          </Fragment>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <YellowButton
              onClick={() => {
                invoiceService.analyzeExpense({
                  url: urlFile,
                })
              }}
              disabled={selectedFiles.length === 0}
            >
              {invoiceService.processing === true ? (
                <CircularProgress />
              ) : (
                i18n.t("Subir factura")
              )}
            </YellowButton>
          </div>
        </div>
      </Modal>
    </>
  )
}
ModalScanInvoices.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  invoiceService: PropTypes.any,
}
export default ModalScanInvoices
