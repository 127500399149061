import React from "react"
import PropTypes from "prop-types"
import TableStructureComparison from "./tableStructureComparison"
import PartComparison from "./partComparison"

const PartTableComparison = ({
  table,
  open,
  setOpen,
  proposalToAccept,
  setProposalToAccept,
}) => {
  return (
    <TableStructureComparison
      tabla={table}
      setOpen={setOpen}
      proposalToAccept={proposalToAccept}
      setProposalToAccept={setProposalToAccept}
    >
      {table.map((item, index) => {
        return index > 1 && index != table.length - 1 ? (
          <PartComparison key={index} part={item} tabla={table} />
        ) : null
      })}
    </TableStructureComparison>
  )
}
PartTableComparison.propTypes = {
  table: PropTypes.array.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  proposalToAccept: PropTypes.array,
  setProposalToAccept: PropTypes.func,
}
export default PartTableComparison
