import React from "react"
import { YellowButton } from "common/buttons"
import { HelveticaBold } from "common/typographies"
import { HelveticaInput } from "common/inputs"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { CircularProgress } from "@mui/material"
import PropTypes from "prop-types"
import i18n from "i18n"

const InputMail = ({
  mailError,
  loading,
  errorMessage,
  handleChange,
  handleConfirmEmail,
}) => {
  return (
    <div className="centered-container" style={{ height: "390px" }}>
      <HelveticaBold
        className="tituloGrandeResponsive"
        style={{
          color: "#414141",
          fontSize: "24px",
          paddingTop: "20px",
          fontWeight: "700",
          marginBottom: "5px",
        }}
      >
        {i18n.t("Recupera tu contraseña")}
      </HelveticaBold>
      <HelveticaBold
        style={{
          color: "#41414199",
          fontSize: "14px",
          fontWeight: "400",
          marginBottom: "30px",
        }}
      >
        {i18n.t("Ingresa tu email y sigue los pasos para acceder a Planhopper")}
      </HelveticaBold>

      <HelveticaBold
        style={{ fontSize: "14px", marginBottom: "5px", color: "#414141" }}
      >
        {i18n.t("Email")}
      </HelveticaBold>
      <HelveticaInput
        style={{
          width: "100%",
          marginBottom: !mailError && "30px",
          border: mailError && "1px solid #BF2929",
        }}
        placeholder={i18n.t("Introduce tu correo electrónico")}
        name="email"
        onChange={e => handleChange(e)}
      ></HelveticaInput>
      {mailError && (
        <div style={{ position: "relative" }}>
          <HelveticaBold
            className="errorMessageHelvetica"
            style={{ marginBottom: "6px" }}
          >
            {errorMessage}
          </HelveticaBold>
          <HighlightOffIcon className="errorPasswordIcon" />
        </div>
      )}

      {loading ? (
        <div
          style={{
            width: "100%",
            position: "relative",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress size={24} style={{ marginBottom: "30px" }} />
        </div>
      ) : (
        <YellowButton
          style={{
            width: "100%",
            marginBottom: "30px",
            height: "35px",
            rotate: "-0.5deg",
          }}
          onClick={e => {
            handleConfirmEmail(e)
          }}
        >
          {i18n.t("Confirmar")}
        </YellowButton>
      )}

      <div
        style={{
          marginTop: "-10px",
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          position: "relative",
        }}
      >
        <HelveticaBold
          className="littlePresponsive"
          style={{ color: "#414141", fontSize: "12px", fontWeight: "400" }}
        >
          {i18n.t("Al continuar estás aceptando")}
        </HelveticaBold>
        <a
          href="https://www.planhopper.com/terms-of-services"
          rel="noreferrer"
          target="_blank"
        >
          <HelveticaBold
            className="littlePresponsive"
            style={{
              color: "#4461D7",
              fontSize: "12px",
              fontWeight: "400",
              marginLeft: "5px",
            }}
          >
            {i18n.t("nuestros terminos y condiciones")}
          </HelveticaBold>
        </a>
      </div>
    </div>
  )
}

InputMail.propTypes = {
  mailError: PropTypes.bool,
  loading: PropTypes.bool,
  errorMessage: PropTypes.string,
  handleChange: PropTypes.func,
  handleConfirmEmail: PropTypes.func,
}

export default InputMail
