import React, { useEffect, useContext } from "react"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { useState } from "react"
import CloseBud from "assets/images/budgeting/CloseBud.png"
import i18n from "i18n"
import { PoppinsNormal } from "common/typographies"
import { CloseOutlined } from "@mui/icons-material"
import { useParams } from "react-router-dom"
import Budget2023Context from "context/budget2023/Budget2023Context"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "630px",
    height: "470px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalCargandoRecalc = ({ open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle)
  const { loadingRecalc, recalcBudget } = useContext(Budget2023Context)
  const { projectId } = useParams()
  const classes = useStyles()

  useEffect(() => {
    if (open) {
      recalcBudget(projectId)
    }
  }, [open])

  return (
    <Modal open={open} onClose={() => null}>
      <div style={modalStyle} className={classes.paper}>
        {loadingRecalc ? (
          <>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "-70px",
                marginTop: "30px",
              }}
            >
              <div className="loader">
                <div className="ball"></div>
                <div className="ball"></div>
                <div className="ball"></div>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "0px",
              }}
            >
              <PoppinsNormal>
                {i18n.t("Estamos recalculando tu presupuesto...")}
              </PoppinsNormal>
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "0px",
                marginTop: "10px",
              }}
            >
              <PoppinsNormal style={{ fontWeight: "400" }}>
                {i18n.t("Esta acción puede tardar unos segundos")}
              </PoppinsNormal>
            </div>
            <div
              style={{
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                position: "relative",
                display: "flex",
                marginLeft: "0px",
                marginTop: "10px",
              }}
            >
              <PoppinsNormal style={{ fontWeight: "400" }}>
                {i18n.t("Por favor no cierres esta pestaña")}
              </PoppinsNormal>
            </div>
          </>
        ) : (
          <>
            <CloseOutlined
              style={{
                color: "#414141",
                position: "absolute",
                right: "10px",
                display: "flex",
                fontSize: "24px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.reload()
              }}
            />
            <div
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "65px",
              }}
            >
              <img src={CloseBud} style={{ width: "117px", height: "117px" }} />
            </div>

            <div
              id="DivTitleEliminar"
              style={{
                marginTop: "15px",
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <p id="centradoBudget">
                {i18n.t("El presupuesto se ha recalculado correctamente")}
              </p>
            </div>

            <div id="centerButton" style={{ marginTop: "15px" }}>
              <button
                type="button"
                className="AddContact"
                onClick={() => {
                  window.location.reload()
                }}
              >
                {i18n.t("Volver al presupuesto")}
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

ModalCargandoRecalc.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}
export default ModalCargandoRecalc
