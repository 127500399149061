import React from "react"
import PropTypes from "prop-types"
import Dropzone from "react-dropzone"
import UploadRoundedIcon from "@mui/icons-material/UploadRounded"
import i18n from "i18n"
import { useUtils } from "hooks/useUtils"
import { CircularProgress } from "@mui/material"
import { HelveticaBold } from "common/typographies"

const FromComputerTab = ({ setImage }) => {
  const { generateFileUrl } = useUtils()
  const [loading, setLoading] = React.useState(false)

  async function onChange(files) {
    const file = files[0]
    setLoading(true)
    setImage(await generateFileUrl(file, "protected"))
    setLoading(false)
  }

  return loading ? (
    <>
      <div
        style={{
          width: "100%",
          justifyContent: "center",
          display: "grid",
          marginTop: "30px",
          marginBottom: "-10px",
        }}
      >
        <CircularProgress
          style={{
            width: 100,
            height: 100,
            marginLeft: "15px",
            color: "#414141",
            marginBottom: "20px",
          }}
        />
        <HelveticaBold
          style={{
            color: "#414141",
            fontSize: "24px",
            marginLeft: "10px",
            marginTop: "0px",
          }}
        >
          {i18n.t("Cargando...")}
        </HelveticaBold>
      </div>
    </>
  ) : (
    <Dropzone
      className="dropzone"
      accept={[".jpeg", ".png", ".jpg"]}
      maxFiles={1}
      onDrop={acceptedFiles => {
        onChange(acceptedFiles)
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <div className="dropzone2">
          <div className="dz-message needsclick mt-2" {...getRootProps()}>
            <input {...getInputProps()} />
            <p
              style={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "19px",
              }}
            >
              {i18n.t("Arrastra archivos o explora tu ordenador")}
            </p>
            <div className="mb-3">
              <UploadRoundedIcon style={{ height: "60px", width: "60px" }} />
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  )
}
FromComputerTab.propTypes = {
  setImage: PropTypes.func.isRequired,
}

export default FromComputerTab
