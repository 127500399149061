import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Switch, useLocation, BrowserRouter as Router } from "react-router-dom"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"
// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import "./assets/css/generalCss.css"
import AuthState from "./context/auth/authState"
import ContactState from "context/contact/contactState"
import ProjectState from "./context/projects/projectState"
import FileState from "context/file/fileState"
import ProposalState from "context/proposal/proposalState"
import TeamsState from "context/teams/teamsState"
import PlanningState from "context/planning/planningState"
import ActsState from "context/acts/actsState"
import BankPricesState from "context/bankPrices/bankPricesState"
import BankPricesReferenceState from "context/referenceBankPrices/bankPricesReferenceState"
import TemplatesState from "context/templates/templatesState"
import FavoritesBPState from "context/favoritesBP/favoritesBPState"
import FacturasState from "context/facturas/facturasState"
import { SnackbarProvider } from "notistack"
import { AwsRum } from "aws-rum-web"
import Budget2023State from "context/budget2023/Budget2023State"
import * as amplitude from "@amplitude/analytics-browser"
import CertHistoryState from "context/certHistory/CertHistoryState"

import SubscriptionProvider from "context/subscription/subscriptionProvider"
import DynamicElementsState from "context/dynamicElements/DynamicElementsState"
import WrapperModals from "components/AppModals/WrapperModals"

let awsRum = null
try {
  const config = {
    sessionSampleRate: 1,
    guestRoleArn:
      "arn:aws:iam::711693965565:role/RUM-Monitor-eu-west-1-711693965565-0236142957761-Unauth",
    identityPoolId: "eu-west-1:f868544e-3202-4600-ae51-f72d461e8a8d",
    endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
    telemetries: ["performance", "errors", "http"],
    allowCookies: true,
    enableXRay: false,
  }

  const APPLICATION_ID = "42deea63-a063-42fe-b28d-6955ac1f9a6f"
  const APPLICATION_VERSION = "1.0.0"
  const APPLICATION_REGION = "eu-west-1"

  awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config,
  )
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

export function RecordPageView() {
  let location = useLocation()
  React.useEffect(() => {
    awsRum.recordPageView(location.pathname)
  }, [location])
}

export function RecordPageViewWithoutUserId() {
  let location = useLocation()
  let baseLocation = location.pathname.split("/")
  baseLocation.pop()
  const baseLocationPath = baseLocation.join("/")
  React.useEffect(() => {
    awsRum.recordPageView(baseLocationPath)
  }, [baseLocationPath])
}
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    awsRum.recordError(error)
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.children
    }
    return this.props.children
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

import { LicenseInfo } from "@mui/x-license"

const App = props => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)

  const Layout = VerticalLayout

  useEffect(() => {
    amplitude.init("6d2700dd6f6190fde14ea1bebe819657", null, {
      defaultTracking: {
        pageViews: false,
        sessions: true,
        formInteractions: true,
        fileDownloads: true,
      },
    })
  }, [])

  return (
    <React.Fragment>
      <SnackbarProvider maxSnack={3}>
        <AuthState>
          <SubscriptionProvider>
            <ProjectState>
              <ProposalState>
                <FileState>
                  <ContactState>
                    <Budget2023State>
                      <CertHistoryState>
                        <TeamsState>
                          <PlanningState>
                            <ActsState>
                              <BankPricesState>
                                <BankPricesReferenceState>
                                  <TemplatesState>
                                    <FavoritesBPState>
                                      <FacturasState>
                                        <DynamicElementsState>
                                          <Router>
                                            <ErrorBoundary>
                                              <Switch>
                                                {publicRoutes.map(
                                                  (route, idx) => (
                                                    <Authmiddleware
                                                      path={route.path}
                                                      layout={NonAuthLayout}
                                                      component={
                                                        route.component
                                                      }
                                                      key={idx}
                                                      isAuthProtected={false}
                                                      exact
                                                    />
                                                  ),
                                                )}

                                                {authProtectedRoutes.map(
                                                  (route, idx) => (
                                                    <Authmiddleware
                                                      path={route.path}
                                                      layout={Layout}
                                                      component={
                                                        route.component
                                                      }
                                                      navContent={
                                                        route.NavContent
                                                      }
                                                      key={idx}
                                                      isAuthProtected={true}
                                                      exact
                                                    />
                                                  ),
                                                )}
                                              </Switch>
                                            </ErrorBoundary>
                                          </Router>
                                          <WrapperModals />
                                        </DynamicElementsState>
                                      </FacturasState>
                                    </FavoritesBPState>
                                  </TemplatesState>
                                </BankPricesReferenceState>
                              </BankPricesState>
                            </ActsState>
                          </PlanningState>
                        </TeamsState>
                      </CertHistoryState>
                    </Budget2023State>
                  </ContactState>
                </FileState>
              </ProposalState>
            </ProjectState>
          </SubscriptionProvider>
        </AuthState>
      </SnackbarProvider>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
