import React from "react"
import { Box } from "@mui/material"
import { HelveticaBold } from "common/typographies"
import PropTypes from "prop-types"
const PartHeader = ({ part }) => {
  return (
    <div
      style={{
        backgroundColor: "#FFD52B",
        alignItems: "center",
        marginBottom: "0px",
        height: "fit-content",
      }}
    >
      <HelveticaBold
        style={{
          fontSize: "14px",
          color: "#414141",
          textAlign: "justify",
          paddingRight: "20px",
          marginLeft: "1rem",
          paddingTop: "20px",
          paddingLeft: "10px",
        }}
      >
        {part.name ?? "Sin nombre"} {`(${part.unity ?? "Ud"})`}
      </HelveticaBold>
      <Box
        sx={{
          display: "flex",
          padding: "0rem",
          alignItems: "center",
          height: "auto",
          marginBottom: "20px",
        }}
      >
        <HelveticaBold
          style={{
            marginLeft: "1rem",
            paddingLeft: "10px",
            fontWeight: "400",
            fontFamily: "Helvetica",
            fontSize: "14px",
            color: "#414141",
            textAlign: "justify",
            paddingRight: "20px",
          }}
        >
          {part.description ?? "Sin descripción"}
        </HelveticaBold>
      </Box>
    </div>
  )
}
PartHeader.propTypes = {
  part: PropTypes.object.isRequired,
}
export default PartHeader
