import React, { useContext, useEffect, useState } from "react"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import { Titulos } from "../../../common/containers"
import i18n from "i18n"
import ProjectContext from "../../../context/projects/projectContext"
import StarIcon from "@mui/icons-material/Star"
import AddContact from "../../../components/Contacts/ContactTable/addContact"
import AuthContext from "context/auth/authContext"
import ModalEditFields from "../../../pages/Dashboard/ModalEditFields"
import { Modal } from "@mui/material"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "450px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    height: "calc(100vh - 100px)",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalEditProyecto = ({
  projectInfo,
  contactos,
  open,
  setOpen,
  imFromDropdown,
}) => {
  const classes = useStyles()
  const { updateProject } = useContext(ProjectContext)
  const { user, getUser, freeTrialExpired } = useContext(AuthContext)
  const [modalStyle] = useState(getModalStyle)
  const [tagsFinal, setTagsFinal] = useState([])
  const [openAdd, setOpenAdd] = useState(false)
  const [contacts, setContacts] = useState(contactos)
  const [selectedContact, setSelectedContact] = useState()
  const [campos, guardarCampos] = useState({
    name: "",
    description: "",
    identifier_number: "",
    orgAddress: "",
    client: "",
    iva: 0,
    descuento: 0,
    igic: 0,
    tags: [],
    imagen: "",
    projectId: "",
    costeIndirecto: "",
    costeGeneral: "",
    industrialProfit: "",
    costeTotal: "",
    conditions: "",
    nota: "",
    cover: "",
  })
  const [isEdit, setIsEdit] = useState(true)
  const [image, setImage] = useState(projectInfo.projectImage)
  const [contacto, setContacto] = useState({ completeName: " " })

  useEffect(() => {
    if (imFromDropdown && !isEdit) {
      setOpen(false)
    }
  }, [isEdit])

  const encontrarCliente = id => {
    let trobat = false
    let i = 0
    while (trobat == false && i < contacts.length) {
      if (contacts[i].id == id) {
        trobat = true
      } else {
        i = i + 1
      }
    }
    if (trobat) {
      setContacto(contacts[i])
    }
  }

  const handleEditFields = e => {
    e.preventDefault()
    setIsEdit(true)
  }
  const [favourite, setFavourite] = useState(false)

  useEffect(() => {
    setContacts(contactos)
    if (projectInfo.client != " ") {
      encontrarCliente(projectInfo.client)
    }
  }, [contactos])

  useEffect(() => {
    console.log(projectInfo, "iiii")

    if (projectInfo.client != " ") {
      encontrarCliente(projectInfo.client)
    }
    guardarCampos({
      name: projectInfo.name,
      description: projectInfo.description,
      orgAddress: projectInfo.orgAddress,
      client: projectInfo.client ? projectInfo.client : "",
      tags: projectInfo.tags,
      iva: projectInfo.iva ? projectInfo.iva : 0,
      descuento: projectInfo.discount
        ? parseFloat(projectInfo.discount / 100)
        : 0,
      igic: projectInfo.igic ? parseFloat(projectInfo.igic / 100) : 0,
      imagen: projectInfo.projectImage,
      identifier_number: projectInfo.identifierNumber,
      projectId: projectInfo.projectId,
      nota: projectInfo.discount_notes ? projectInfo.discount_notes : "",
      costeIndirecto: projectInfo.costeIndirecto
        ? projectInfo.costeIndirecto
        : null,
      costeGeneral: projectInfo.costeGeneral ? projectInfo.costeGeneral : null,
      industrialProfit: projectInfo.industrialProfit
        ? projectInfo.industrialProfit
        : null,
      costeTotal: projectInfo.costeTotal ? projectInfo.costeTotal : null,
      conditions: projectInfo.conditions ? projectInfo.conditions : "",
      cover: projectInfo.cover ? projectInfo.cover : "",
    })

    setFavourite(projectInfo.favourite ? projectInfo.favourite : false)
  }, [projectInfo])

  useEffect(() => {
    const selectedContact = contacts.filter(
      contact => contact.id === projectInfo.client,
    )

    if (selectedContact.length) {
      setSelectedContact(selectedContact[0])
    }
  }, [contacts])

  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])

  return (
    <>
      <Modal open={open} onClose={null}>
        <div style={modalStyle} className={classes.paper}>
          <Box style={modalStyle} className={classes.paper}>
            {!isEdit ? (
              <>
                <Box
                  sx={{
                    color: "#414141",
                    backgroundColor: "#CCD8FC",
                    borderRadius: "6px",
                    cursor: "pointer",
                    width: "30px",
                    height: "30px",
                    marginLeft: "auto",
                  }}
                >
                  {user ? (
                    user.freeTrialExpired ||
                    user["custom:role"] === "WORKER" ? null : (
                      <EditOutlinedIcon
                        style={{ width: "1.3em", marginTop: "10%" }}
                        onClick={handleEditFields}
                      ></EditOutlinedIcon>
                    )
                  ) : null}
                </Box>
                <Box
                  sx={{
                    width: " 100%",
                    overflow: "inherit",
                    borderBottom: "1px solid #E2E8F0",
                    paddingBottom: "1rem",
                  }}
                >
                  <Box>
                    <img
                      src={image}
                      className="rounded-circle"
                      width="100px"
                      height="100px"
                      alt="Header Avatar"
                      style={{
                        filter: "drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.08))",
                      }}
                    />
                    {favourite ? (
                      <div
                        id="Estrella"
                        style={{ marginTop: "-30px", marginLeft: "60px" }}
                      >
                        <StarIcon
                          sx={{ color: "#FFD52B", fontSize: 30, zIndex: "9" }}
                        ></StarIcon>
                      </div>
                    ) : null}
                  </Box>
                </Box>

                <Box
                  sx={{
                    borderBottom: "1px solid #E2E8F0",
                    paddingTop: "0.5rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <Titulos className={`${classes.title}`}>
                    {i18n.t("Nombre")}
                  </Titulos>
                  <span className={classes.content}>{projectInfo.name}</span>
                </Box>

                <Box
                  sx={{
                    borderBottom: "1px solid #E2E8F0",
                    paddingTop: "0.5rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <Titulos className={`${classes.title}`}>
                    {i18n.t("Número de identificación")}
                  </Titulos>
                  <span className={classes.content}>
                    {projectInfo.identifierNumber}
                  </span>
                </Box>

                <Box
                  sx={{
                    borderBottom: "1px solid #E2E8F0",
                    paddingTop: "0.5rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <Titulos className={`${classes.title}`}>
                    {i18n.t("Cliente Asociado")}
                  </Titulos>
                  {contacto["completeName"] !== " " ? (
                    <span className={classes.content}>
                      {contacto.completeName}
                    </span>
                  ) : (
                    (encontrarCliente(projectInfo.client),
                    (<div style={{ height: "1.5rem" }}></div>))
                  )}
                </Box>

                <Box
                  sx={{
                    borderBottom: "1px solid #E2E8F0",
                    paddingTop: "0.5rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <Titulos className={`${classes.title}`}>
                    {i18n.t("Descripción")}
                  </Titulos>
                  {projectInfo.description ? (
                    <span className={classes.content}>
                      {projectInfo.description}
                    </span>
                  ) : (
                    <div style={{ height: "2rem" }}></div>
                  )}
                </Box>

                <Box
                  sx={{
                    borderBottom: "1px solid #E2E8F0",
                    paddingTop: "0.5rem",
                    paddingBottom: "1rem",
                  }}
                >
                  <Titulos className={`${classes.title}`}>
                    {i18n.t("Dirección")}
                  </Titulos>
                  {projectInfo.description ? (
                    <span className={classes.content}>
                      {projectInfo.orgAddress}
                    </span>
                  ) : (
                    <div style={{ height: "2rem" }}></div>
                  )}
                </Box>

                <Box sx={{ paddingTop: "1rem" }}>
                  <Titulos className={`${classes.title}`}>
                    {i18n.t("Etiqueta")}
                  </Titulos>

                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    {projectInfo.tags.length ? (
                      projectInfo.tags.map((tag, i) => (
                        // eslint-disable-next-line react/jsx-key
                        <Box id="tagColor" className="tag">
                          {tag}
                        </Box>
                      ))
                    ) : (
                      <div style={{ height: "2rem" }}></div>
                    )}
                  </Box>
                </Box>

                <AddContact openAdd={openAdd} setOpenAdd={setOpenAdd} />
              </>
            ) : (
              <ModalEditFields
                campos={campos}
                contactos={contactos}
                guardarCampos={guardarCampos}
                projectInfo={projectInfo}
                setIsEdit={setIsEdit}
                image={image}
                setImage={setImage}
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
                imFromDropdown={imFromDropdown}
              ></ModalEditFields>
            )}
          </Box>
        </div>
      </Modal>
    </>
  )
}

ModalEditProyecto.propTypes = {
  projectInfo: PropTypes.object.isRequired,
  contactos: PropTypes.array,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  imFromDropdown: PropTypes.bool,
}

export default ModalEditProyecto
