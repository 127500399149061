import { Auth, API } from "aws-amplify"
import { useState } from "react"

const useComparison = () => {
  const [tablaComparativa, setTablaComparativa] = useState([])
  const [loading, setLoading] = useState(false)
  const ENDPOINT2 = "dev-PROVIDERS-API"
  const getTablaComparativa = async (projectId, groupId) => {
    try {
      setLoading(true)
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const res = await API.get(
        ENDPOINT2,
        `/providers-table/` + projectId + "?groupId=" + groupId,
        { headers: headers },
      )
      setTablaComparativa(res)
      setLoading(false)
      return res
    } catch (err) {
      console.log(err)
    }
  }

  return {
    tablaComparativa,
    loading,
    getTablaComparativa,
  }
}
export default useComparison
