import React, { useState } from "react"
import { Modal } from "@mui/material"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import somethingIsBad from "assets/customIcons/somethingIsBad.svg"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "460px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 2, 0),
    borderRadius: 6,
    height: "300px",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalBC3 = ({ open, setOpen, setNotBC3, frase }) => {
  // Configuración del modal para añadir contactos
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  const onClose = () => {
    setNotBC3(false)
    setOpen(false)
  }
  return (
    <Modal open={open} onClose={onClose}>
      <div style={modalStyle} className={classes.paper}>
        <div id="centerPaco">
          <img
            src={somethingIsBad}
            style={{ width: "70px", height: "70px", marginTop: "-15px" }}
          ></img>
        </div>
        <p
          style={{
            fontSize: "24px",
            fontWeight: "700",
            textAlign: "center",
            fontFamily: "Helvetica",
            marginTop: "30px",
          }}
        >
          Algo no va bien...
        </p>

        <p
          style={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "18px",
            textAlign: "center",
            fontFamily: "Helvetica",
            color: "rgba(65, 65, 65, 0.6)",
          }}
        >
          Algun documento no se ha podido procesar. Recuerda que por ahora solo
          puedes importar archivos en {frase ? " excel" : " .bc3"}, disculpa las
          molestias.
        </p>
        <div id="centerButton">
          <button className="AddContact" onClick={onClose}>
            De acuerdo
          </button>
        </div>
      </div>
    </Modal>
  )
}

ModalBC3.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setNotBC3: PropTypes.func.isRequired,
  frase: PropTypes.bool,
}
export default withRouter(ModalBC3)
