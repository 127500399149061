import React from "react"
import PropTypes from "prop-types"

import { HelveticaBold, HelveticaLight } from "common/typographies.js"
import { PriceInput } from "common/inputs"

class PartInput extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    symbol: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    name: "name",
    onBlur: (name, value) => {},
    onFocus: e => {
      e.target.select()
    },
    symbol: "€",
    title: "Title",
    value: () => {},
  }

  constructor(props) {
    super(props)
    this.onEdit = false
    this.state = {
      value: parseFloat(this.props.value).toFixed(2),
    }
  }

  handleChange = event => {
    this.onEdit = true
    this.setState({ value: event.target.value })
  }

  handleBlur = event => {
    this.props.onBlur(this.props.name, event.target.value)
    this.onEdit = false
  }

  handleFocus = event => {
    this.props.onFocus(event)
  }

  render() {
    const { title, name, symbol } = this.props

    return (
      <div
        style={{
          borderRight: "1px solid #C4C4C4",
          justifyContent: "center",
          display: "flex",
          backgroundColor: "#EEEEEE",
          flexDirection: "column",
          width: "25%",
          textAlign: "center",
        }}
      >
        <HelveticaBold
          style={{
            color: "#414141",
            position: "relative",
            fontSize: "14px",
            marginTop: "-1px",
            marginBottom: "15px",
          }}
        >
          {title}
        </HelveticaBold>
        <div
          className="inline"
          style={{
            width: "100%",
            justifyContent: "center",
            position: "relative",
            textAlign: "center",
          }}
        >
          <PriceInput
            type="number"
            name={name}
            style={{
              width: "70%",
              position: "relative",
              fontSize: "14px",
              marginTop: "-11px",
              textAlign: "center",
              backgroundColor: "#EEEEEE",
              color: "#414141",
            }}
            disabled={this.props.disabled}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            value={
              this.onEdit
                ? this.state.value
                : parseFloat(this.props.value()).toFixed(2)
            }
          />
          <HelveticaBold
            style={{
              color: "#414141",
              fontSize: "14px",
              marginTop: "-1px",
              marginBottom: "15px",
              fontWeight: "400",
              marginLeft: "5px",
            }}
          >
            {symbol}
          </HelveticaBold>
        </div>
      </div>
    )
  }
}

export { PartInput }
