import React, { Fragment, useContext, useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { v4 as uuidv4 } from "uuid"
import { useSearch } from "hooks/useSearch"
import { useParams } from "react-router-dom"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import Budget2023Context from "context/budget2023/Budget2023Context"
import ListPartida from "./ListPartida"
import i18n from "i18n"
import manualPart from "assets/images/budgeting/manualPart.png"
import cypeLogo from "assets/images/logos/cypeSelected.png"
import preocLogo from "assets/customIcons/preocVert.svg"
import bedecLogo from "assets/images/logos/bedecSelected.png"
import dbLogo from "assets/customIcons/selectedUB.svg"
import tribbooLogo from "assets/images/logos/tribbooSelected.png"
import AuthContext from "context/auth/authContext"
import ModalEditarPartida from "./ModalEditarPartida"
import ArrowDownIcon from "assets/customIcons/ArrowDownIcon.svg"
import ArrowUpIcon from "assets/customIcons/ArrowUpIcon.svg"
import ModalDeletePart from "./ModalDeletePart"
import NewModalPartida from "pages/ModalPartidas/NewModalPartida"
import {
  formatTableValue,
  getCurrency,
  checkIfMeasuresAreEmpty,
} from "utils/functions"
import {
  costRealCalc,
  costRealTotalCalc,
  marginRealPercCalc,
  calcMarginBetween,
  getMargin,
  calcMarginFactor,
  underObjective,
  overObjective,
  overPrice,
  calcApplyDiscount,
} from "utils/partUtils"
import ModalDecomposition from "./ModalDecomposition"
import { isNil } from "lodash"
import { isEmpty } from "lodash"
import { PriceInput } from "common/inputs"
import StrikethroughRow from "./StrikethroughRow"
import { HelveticaBold, HelveticaLight } from "common/typographies.js"
import { PartTableInput } from "components/Part/PartTableInput"

const Partida = ({
  certificaciones,
  partidaInfo,
  indice,
  preferencias,
  soyFinal,
  projectInfo,
  modificado,
  numeroIndex,
  setModificado,
  contadorActualizaciones,
  setContadorActualizaciones,
  ocultoFinal,
  isChecked,
  setIsChecked,
  imDragging,
  setImDragging,
  version,
  whoIAm,
  openModalCreatePartida,
  setOpenModalCreatePartida,
  currentPercentageChapter,
  setCurrentPercentageChapter,
  currentPercentagePartida,
  setParentUpdate,
  parentUpdate,
  isFirstTime,
}) => {
  const { projectId } = useParams()
  const { itemsMemo, setItems, queryPartida } = useSearch()

  const {
    deletePartidav2,
    deleteMultipleParts,
    updatePartidav2,
    createPartidaV2,
    checkPartida,
    closeBudget,
    isUpdates,
    budgetTree,
    updatePartsList,
    partsChecked,
    imChecked,
    checkMultipleParts,
    movePartsWithArrows,
    deletePartDecomposition,
    updateCurrentPercentageChapterCertification,
    isBudgetClosed,
  } = useContext(Budget2023Context)
  const [partida, setPartida] = useState({
    unity: "",
    quantity: 0,
    resumen: "",
    price: 0, // Price should be a number to use fixed decimals
    precioRef: "",
    total: "",
  })
  const [viewState, setViewState] = useState({})
  const [openEliminate, setOpenEliminate] = useState(false)
  const [open, setOpen] = useState(false)
  const [custom, setCustom] = useState(false)
  const [contador2, setContador2] = useState(0)
  const [contador3, setContador3] = useState(0)
  const [contadorManual, setContadorManual] = useState(0)
  const [cert_previous_percentage, setCert_previous_percentage] = useState(0)
  const [cert_current_percentage, setCert_current_percentage] = useState(0)
  const [cert_total_percentage, setCert_total_percentage] = useState(0)
  const [cert_total, setCert_total] = useState(0)
  const [capitulo_cert_total, setCapitulo_cert_total] = useState(0)
  const [capitulo_cert_total_percentage, setCapitulo_cert_total_percentage] =
    useState(0)
  const [capitulo_cert_previous, setCapitulo_cert_previous] = useState(0)
  const [capitulo_cert_current, setCapitulo_cert_current] = useState(0)
  const [
    capitulo_cert_previous_percentage,
    setCapitulo_cert_previous_percentage,
  ] = useState(0)
  const [
    capitulo_cert_current_percentage,
    setCapitulo_cert_current_percentage,
  ] = useState(0)
  const inputRef = useRef(null)

  useEffect(() => {
    if (
      currentPercentageChapter < cert_current_percentage ||
      isNil(cert_current_percentage) ||
      isNaN(cert_current_percentage) ||
      isEmpty(cert_current_percentage)
    ) {
      setCert_current_percentage(currentPercentageChapter)
    }
  }, [currentPercentageChapter])

  const calculateCurrentPercentage = (current, previous) => {
    const isMoreThanOneHundred = parseFloat(current) + parseFloat(previous)
    let finalCertPreviusValue
    if (isMoreThanOneHundred < 100) {
      finalCertPreviusValue = current
    } else {
      finalCertPreviusValue = 100 - parseFloat(previous)
    }

    return finalCertPreviusValue
  }

  //const currencySymbol = user && user.hasOwnProperty("custom:currency") ? getCurrency(user["custom:currency"]) : ""

  useEffect(() => {
    console.log(`parentUpdate: ${JSON.stringify(parentUpdate)}`)

    for (const [key, value] of Object.entries(parentUpdate)) {
      console.log(`parentUpdate: ${key} => ${value}`)

      switch (key) {
        // Note: Set to currentPercentageUpdate(value, false) to avoid pushing the update (calling the backend)
        // however, right now, we need it to trigger the sum upwards
        case "cert_current_perc":
          currentPercentageUpdate(value, true)
          break
      }
    }
  }, [parentUpdate])
  // TODO:
  //
  // useEffect(() => {
  //   const aux1 = parseFloat(
  //     partidaInfo.cert_previous +
  //       Math.abs((partida.cert_current * partida.quantity) / 100)
  //   )
  //   setCert_total(aux1)
  //   const aux2 = parseFloat(
  //     parseFloat(
  //       ((partidaInfo.cert_previous + partidaInfo.cert_current) /
  //         partidaInfo.quantity) *
  //         100
  //     )
  //   ).toFixed(2)
  //   setCert_total_percentage(aux2)
  //   const aux3 = parseFloat(
  //     (partidaInfo.cert_previous / partidaInfo.quantity) * 100
  //   ).toFixed(2)
  //   setCert_previous_percentage(aux3)
  // }, [partida])

  const onClickStopPropagation = e => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
  }

  useEffect(() => {
    if (partidaInfo) {
      setPartida({
        ...partidaInfo,
        name:
          partidaInfo.name == "" ? partidaInfo.description : partidaInfo.name,
        resumen: partidaInfo.description,
        code: partidaInfo.code ? partidaInfo.code : null,
        projectId: projectId,
        total: partidaInfo.price * partidaInfo.quantity,
        discount: partidaInfo.discount ? partidaInfo.discount : 0,
        cert_previous: partidaInfo.cert_previous
          ? partidaInfo.cert_previous
          : 0,
        cert_current: partidaInfo.cert_current ? partidaInfo.cert_current : 0,
        totalWithDiscount: partidaInfo.discount
          ? partidaInfo.discount > 0
            ? (partidaInfo.price -
                (partidaInfo.discount / 100) * partidaInfo.price) *
              partidaInfo.quantity
            : partidaInfo.price * partidaInfo.quantity
          : partidaInfo.price * partidaInfo.quantity,
      })
      const certPreviousPercentageValue = parseFloat(
        (partidaInfo.cert_previous / partidaInfo.quantity) * 100,
      ).toFixed(2)
      setCert_previous_percentage(certPreviousPercentageValue)
      const certCurrentPercentageValue = parseFloat(
        (partidaInfo.cert_current / partidaInfo.quantity) * 100,
      ).toFixed(2)
      setCert_current_percentage(certCurrentPercentageValue)
      const certTotalValue = parseFloat(
        partidaInfo.cert_previous + partidaInfo.cert_current,
      )
      setCert_total(certTotalValue)
      const certTotalPercentageValue = parseFloat(
        parseFloat(
          ((partidaInfo.cert_previous + partidaInfo.cert_current) /
            partidaInfo.quantity) *
            100,
        ),
      ).toFixed(2)
      setCert_total_percentage(certTotalPercentageValue)
    }
  }, [
    partidaInfo,
    contador2,
    contador3,
    partidaInfo.name,
    partidaInfo.quantity,
    partidaInfo.description,
    partidaInfo.price,
    partidaInfo.unity,
    partidaInfo.discount,
    partidaInfo.clientPrice, // Why do we need to specify each property?
    partidaInfo.clientPriceWithoutDiscount, // doesn't partidaInfo suffice?
    partidaInfo.costReal,
  ])

  useEffect(() => {
    if (
      partidaInfo.elementInfo == null &&
      partidaInfo.name != "" &&
      partidaInfo.description != ""
    ) {
      //significa que la he creado manual
      styleUpdated.current.id = ""
    } else {
      //NO SOY FINAL
      styleUpdated.current.id = ""
    }
  }, [partida, contadorManual, budgetTree.warning])

  useEffect(() => {
    if (partidaInfo) {
      console.log("reset view state")
      setViewState({})
    }
  }, [partidaInfo])

  const { quantity } = partida

  const [sumMargins, setSumMargins] = useState(0)

  useEffect(() => {
    if (projectInfo) {
      if (projectInfo.industrialProfit) {
        setSumMargins(
          projectInfo.industrialProfit +
            projectInfo.costeGeneral +
            projectInfo.costeIndirecto,
        )
      } else {
        setSumMargins(projectInfo.costeGeneral)
      }
    }
  }, [projectInfo])
  const handleChangePartida = e => {
    setPartida({
      ...partida,
      [e.target.name]: e.target.value,
    })

    if (
      e.target.name == "name" &&
      e.target.value != "" &&
      e.target.value.length >= 3 &&
      !custom
    ) {
      setItems(queryPartida(e.target.value))
    }
    if (e.target.name == "quantity" && e.target.value != "") {
      if (e.target.value < 0) {
        setPartida({
          ...partida,
          quantity: e.target.value * -1,
          total: Math.floor(partida.price * e.target.value * -100) / 100,
        })
      } else {
        setPartida({
          ...partida,
          quantity: e.target.value,
          total: Math.floor(partida.price * e.target.value * 100) / 100,
        })
      }
    }
  }

  const { user, getUser, freeTrialExpired, openFreeTrialExpire } =
    useContext(AuthContext)
  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])
  const [estadoDescuento, setEstadoDescuento] = useState(partidaInfo.discount)
  const [costeTotalFinal, setCosteTotalFinal] = useState(
    partidaInfo.finalClientPrice,
  )
  const handleChangeDescuento = e => {
    setEstadoDescuento(e.target.value)
    setPartida({
      ...partida,
      discount: parseInt(e.target.value),
    })
    let valor =
      parseFloat(partidaInfo.price) * parseFloat(partidaInfo.quantity) -
      parseFloat(partidaInfo.price) *
        parseFloat(partidaInfo.quantity) *
        parseFloat(parseInt(e.target.value) / 100)
    setCosteTotalFinal(valor)
  }
  const handleChangeCertificacion = e => {
    let isValid = true
    for (let i = 0; i < e.target.value.length; i++) {
      if (e.target.value[i] == "-") {
        isValid = false
      }
    }
    if (isValid) {
      if (e.target.name == "cert_current_percentage") {
        let auxCertPrev = parseFloat(cert_previous_percentage)
        let auxTarget = parseFloat(e.target.value)
        let sum = parseFloat(auxCertPrev + auxTarget)
        if ((sum <= 100 && e.target.value >= 0) || e.target.value == "") {
          setCert_current_percentage(e.target.value)
          setPartida({
            ...partida,
            cert_current: parseFloat(
              parseFloat((partidaInfo.quantity * e.target.value) / 100).toFixed(
                2,
              ),
            ),
          })
          setCert_total(
            parseFloat(
              partida.cert_previous +
                parseFloat(
                  parseFloat(
                    (partidaInfo.quantity * e.target.value) / 100,
                  ).toFixed(2),
                ),
            ),
          )
          setCert_total_percentage(
            parseFloat(
              parseFloat(e.target.value) + parseFloat(cert_previous_percentage),
            ).toFixed(2),
          )
        }
      } else if (
        e.target.name == "cert_current_number" ||
        e.target.value == ""
      ) {
        if (
          e.target.value <= partidaInfo.quantity - partida.cert_previous &&
          e.target.value >= 0
        ) {
          setCert_current_percentage(
            parseFloat(
              (parseFloat(e.target.value) / partidaInfo.quantity) * 100,
            ).toFixed(2),
          )
          setPartida({
            ...partida,
            cert_current: parseFloat(e.target.value),
          })
          setCert_total(
            parseFloat(partida.cert_previous + parseFloat(e.target.value)),
          )
          setCert_total_percentage(
            parseFloat(
              parseFloat(
                (parseFloat(e.target.value) / partidaInfo.quantity) * 100,
              ) + parseFloat(cert_previous_percentage),
            ).toFixed(2),
          )
        }
      } else if (
        e.target.name == "cert_total_percentage" ||
        e.target.value == ""
      ) {
        if (e.target.value <= 100 && e.target.value >= 0) {
          setCert_total_percentage(e.target.value)
          setCert_total(
            parseFloat(
              parseFloat(partidaInfo.quantity * (e.target.value / 100)).toFixed(
                2,
              ),
            ),
          )
          setCert_current_percentage(
            parseFloat(
              parseFloat(e.target.value) - parseFloat(cert_previous_percentage),
            ).toFixed(2),
          )
          // TODO:
          // setPartida({
          //   ...partida,
          //   cert_current: parseFloat(
          //     parseFloat(partidaInfo.quantity * (parseFloat(e.target.value) - parseFloat(cert_previous_percentage) / 100)).toFixed(
          //       2
          //     )
          //   ),
          // })
        }
      } else if (e.target.name == "cert_total" || e.target.value == "") {
        let auxValue =
          parseFloat(partida.quantity) - parseFloat(partida.cert_previous)
        let actualValue = parseFloat(e.target.value)
        console.log(auxValue, actualValue, "auxValue, actualValue")
        if (actualValue <= auxValue && actualValue >= 0) {
          console.log("entra aux")
          setCert_total(e.target.value)
          setCert_total_percentage(
            parseFloat(
              parseFloat((e.target.value / partidaInfo.quantity) * 100) +
                parseFloat(cert_previous_percentage),
            ).toFixed(2),
          )
          setCert_current_percentage(
            parseFloat(
              parseFloat(
                ((e.target.value - partida.cert_previous) /
                  partidaInfo.quantity) *
                  100,
              ).toFixed(2),
            ),
          )
          setPartida({
            ...partida,
            cert_current: parseFloat(
              parseFloat(e.target.value) - parseFloat(partida.cert_previous),
            ),
          })
        }
      }
    }
  }

  Number.prototype.countDecimals = function () {
    if (Math.floor(this.valueOf()) === this.valueOf()) return 0
    return this.toString().split(".")[1].length || 0
  }

  const [openAdd, setOpenAdd] = useState(false)

  const addPartida = item => {
    let wasEmpty = partidaInfo.empty
    setPartida({
      ...partida,
      code: item.id,
      unity: item.unity,
      resumen: item.resumen,
      name: item.name,
      price: Math.floor(item.price * 100) / 100,
    })

    updatePartidav2(
      {
        ...partida,
        name: item.name,
        code: item.id,
        unity: item.unity,
        resumen: item.resumen,
        price: Math.floor(item.price * 100) / 100,
      },
      partidaInfo.chapterId,
      user.sub,
    )
    setItems([])
    setTimeout(() => {
      if (wasEmpty == true && modificado == 1) {
        closeBudget()
      }
    }, 1500)
  }
  const handleCheckBox = e => {
    if (e.nativeEvent.shiftKey) {
      if (partidaInfo.checked) {
        checkPartida(partidaInfo.chapterId, partidaInfo.partidaId)
        updatePartsList(partidaInfo)
      } else {
        checkMultipleParts(partidaInfo.chapterId, partidaInfo)
      }
    } else {
      checkPartida(partidaInfo.chapterId, partidaInfo.partidaId)
      updatePartsList(partidaInfo)
    }
  }

  const clamp = (value, min, max) => {
    return Math.min(Math.max(value, min), max)
  }

  const totalCertUpdate = value => {
    console.log(`totalCertUpdate: ${value}`)
    const clamped = clamp(value, partida.cert_previous, partida.quantity)
    console.log(`totalCertUpdate: ${clamped}`)

    const previous_value = parseFloat(
      partida.cert_current + partida.cert_previous,
    )
    if (
      value.toFixed(2) == clamped.toFixed(2) &&
      clamped.toFixed(2) == previous_value.toFixed(2)
    ) {
      return
    } // Avoid updating

    updateViewState({
      cert_totalView: clamped,
      cert_totalPercView: parseFloat(
        (100 * clamped) / partida.quantity,
      ).toFixed(2),
      cert_currentView: parseFloat(clamped - partida.cert_previous).toFixed(2),
      cert_currentPercView: parseFloat(
        (100 * (clamped - partida.cert_previous)) / partida.quantity,
      ).toFixed(2),
      cert_currentPriceView: parseFloat(
        partida.clientPrice * (clamped - partida.cert_previous),
      ).toFixed(2),
    })
    updatePartidaState({
      cert_current: clamped - partida.cert_previous,
    })
  }

  const totalPercentageUpdate = value => {
    console.log(`totalPercentageUpdate: ${value}`)
    console.log(
      `partida.cert_previousPerc: ${partida.cert_previous / partida.quantity}`,
    )
    const clamped = clamp(
      value,
      (100 * partida.cert_previous) / partida.quantity,
      100,
    )
    console.log(`totalPercentageUpdate: ${clamped}`)

    const previous_value = parseFloat(
      (100 * (partida.cert_current + partida.cert_previous)) / partida.quantity,
    )
    if (
      value.toFixed(2) == clamped.toFixed(2) &&
      clamped.toFixed(2) == previous_value.toFixed(2)
    ) {
      return
    } // Avoid updating

    const total = parseFloat((partida.quantity * clamped) / 100)
    const current = total - partida.cert_previous

    updateViewState({
      cert_totalView: total,
      cert_totalPercView: clamped,
      cert_currentView: current.toFixed(2),
      cert_currentPercView: parseFloat(
        (100 * current) / partida.quantity,
      ).toFixed(2),
      cert_currentPriceView: parseFloat(partida.clientPrice * current).toFixed(
        2,
      ),
    })
    updatePartidaState({
      cert_current: current,
    })
  }

  const currentCertUpdate = value => {
    console.log(`currentCertUpdate: ${value}`)
    const clamped = clamp(value, 0, partida.quantity - partida.cert_previous)
    console.log(`currentCertUpdate: ${clamped}`)

    const previous_value = parseFloat(partida.cert_current)
    if (
      value.toFixed(2) == clamped.toFixed(2) &&
      clamped.toFixed(2) == previous_value.toFixed(2)
    ) {
      return
    } // Avoid updating

    updateViewState({
      cert_totalView: parseFloat(partida.cert_previous + clamped).toFixed(2),
      cert_totalPercView: parseFloat(
        (parseFloat(partida.cert_previous + clamped) / partida.quantity) * 100,
      ).toFixed(2),
      cert_currentView: clamped.toFixed(2),
      cert_currentPercView: parseFloat(
        (100 * clamped) / partida.quantity,
      ).toFixed(2),
      cert_currentPriceView: parseFloat(clamped * partida.clientPrice).toFixed(
        2,
      ),
    })
    updatePartidaState({
      cert_current: clamped,
    })
  }

  const currentPercentageUpdate = (value, push = true) => {
    console.log(`currentPercentageUpdate: ${value}`)
    value = parseFloat(value)
    var clamped = clamp(
      value,
      0,
      100 - (100 * partida.cert_previous) / partida.quantity,
    )
    console.log(`currentPercentageUpdate: ${clamped}`)

    const previous_value = parseFloat(
      (100 * partida.cert_current) / partida.quantity,
    )
    console.log(`previous_value: ${previous_value.toFixed(2)}`)
    console.log(`value: ${value.toFixed(2)}`)
    if (
      value.toFixed(2) == clamped.toFixed(2) &&
      clamped.toFixed(2) == previous_value.toFixed(2)
    ) {
      return
    } // Avoid updating

    console.log(`currentPercentageUpdate: updating ${clamped}`)

    const current = parseFloat((partida.quantity * clamped) / 100)
    updateViewState({
      cert_totalView: parseFloat(partida.cert_previous + current).toFixed(2),
      cert_totalPercView: parseFloat(
        (parseFloat(partida.cert_previous + current) / partida.quantity) * 100,
      ).toFixed(2),
      cert_currentView: current.toFixed(2),
      cert_currentPercView: clamped.toFixed(2),
      cert_currentPriceView: parseFloat(partida.clientPrice * current).toFixed(
        2,
      ),
    })

    if (push) {
      updatePartidaState({
        cert_current: current,
      })
    }
  }

  const handleViewBlur = (name, value) => {
    console.log(`handleViewBlur ${name} => ${value}`)

    value = parseFloat(value)

    if (isNaN(value) || value === null || value === undefined) {
      setViewState({})
      return
    }

    switch (name) {
      case "cert_totalView":
        totalCertUpdate(value)
        break
      case "cert_totalPercView":
        totalPercentageUpdate(value)
        break
      case "cert_currentView":
        currentCertUpdate(value)
        break
      case "cert_currentPercView":
        currentPercentageUpdate(value)
        break
      case "price":
        priceUpdate(value)
        break
      case "quantity":
        quantityUpdate(value)
        break
      default:
        updateViewState({ [name]: value.toFixed(2) })
    }
  }

  const handleViewChange = e => {
    const { name, value } = e.target
    //console.log(`handleViewChange: ${name} => ${value}`)
    updateViewState({ [name]: value })
  }

  const currencySymbol = getCurrency(user["custom:currency"])

  const currencyFmt = value => {
    return (
      parseFloat(value).toFixed(2) + " " + getCurrency(user["custom:currency"])
    )
  }

  const quantityUpdate = value => {
    console.log(`quantityUpdate: ${value}`)
    value = parseFloat(value)

    updatePartidaState({ quantity: value })
    setViewState({})
  }

  const priceUpdate = value => {
    console.log(`priceUpdate: ${value}`)
    value = parseFloat(value)

    const margin = parseFloat(partidaInfo.costeGeneral) / 100
    const clientPriceWithoutDiscount = parseFloat(value / (1 - margin))
    const clientPrice = calcApplyDiscount(
      clientPriceWithoutDiscount,
      partidaInfo.discount,
    )
    console.log(`priceUpdate: ${value} => ${clientPriceWithoutDiscount}`)

    /*updateViewState({
      price: value.toFixed(2),
      clientPriceWithoutDiscount: clientPriceWithoutDiscount.toFixed(2),
    })*/

    updatePartidaState({
      price: value,
      clientPriceWithoutDiscount: clientPriceWithoutDiscount,
      clientPrice: clientPrice,
    })

    setViewState({})
  }

  const currentPercentageChapterOnChange = e => {
    var { name, value } = e.target
    const chapterId = name.replace("currentPercentageChapter", "")
    console.log(`currentPercentageChapterOnChange: ${chapterId} => ${value}`)
    updateViewState({ [chapterId]: { cert_current_perc: value } })
  }

  const currentPercentageChapterOnBlur = e => {
    var { name, value } = e.target
    const chapterId = name.replace("currentPercentageChapter", "")
    console.log(`currentPercentageChapterOnBlur: ${chapterId} => ${value}`)
    value = parseFloat(value)
    const clamped = clamp(value, 0, 100)

    updateViewState({ [chapterId]: { cert_current_perc: clamped.toFixed(2) } })

    // this triggers the parentUpdate useEffect
    setParentUpdate({ cert_current_perc: clamped.toFixed(2) })

    updateCurrentPercentageChapterCertification({
      cert_current_perc: clamped,
      chapterId: chapterId,
      projectId: projectId,
    })

    // Falta actualizar la vista SIN POST...
  }

  const updateViewState = updateObject => {
    //for (const [key, value] of Object.entries(updateObject)) {
    //  console.log(`updateViewState: ${key} => ${value}`)
    //}
    setViewState({
      ...viewState,
      ...updateObject,
    })
  }

  const updatePartidaState = partidaObject => {
    //console.log(`updatePartidaState: ${JSON.stringify(partidaObject)}`)

    var shouldUpdate = false
    var changedKeys = {}
    for (const [key, value] of Object.entries(partidaObject)) {
      if (partida[key] != value) {
        shouldUpdate = true
        console.log(`updatePartidaState: ${key} => ${value} != ${partida[key]}`)
        changedKeys[key] = value
      }
    }

    if (shouldUpdate) {
      setPartida({
        ...partida,
        ...partidaObject,
      })

      updatePartidav2(
        {
          ...partida,
          ...partidaObject,
        },
        partidaInfo.chapterId,
        partidaInfo.accountId,
      )

      for (const key in changedKeys) {
        console.log(`updatePartidaState: updated ${key} => ${changedKeys[key]}`)
      }
    }
  }

  const handleOnBlur = e => {
    updatePartidav2(
      {
        ...partida,
        price: parseFloat(partida.price),
        discount: parseInt(partida.discount),
        cert_current: parseFloat(partida.cert_current),
        cert_previous: parseFloat(partida.cert_previous),
      },
      partidaInfo.chapterId,
      partidaInfo.accountId,
    )
  }

  function handleCustom(data) {
    setCustom(true)
    setPartida({
      ...partida,
      unidades: data.unidades ? parseInt(data.unidades) : 0,
      quantity: data.unidades ? parseInt(data.unidades) : 0,
      code: data.code ? data.code : uuidv4(),
      unity: data.unity,
      resumen: data.resumen.length > 0 ? data.resumen : "_",
      name: data.name,
      price: parseFloat(data.price),
      precioRef: parseFloat(
        data.refPrice ? data.refPrice : data.precioRef ? data.precioRef : 0,
      ),
      price: parseFloat(data.price),
      total: parseFloat(data.price * data.unidades),
      position: partida.position,
      comeFrom: data.comeFrom,
    })

    setItems([])
    styleUpdated.current.id = ""
    setContadorManual(contadorManual + 1)
    setTimeout(() => {
      updatePartidav2(
        {
          ...data,
          unidades: data.unidades ? parseInt(data.unidades) : 0,
          price: parseFloat(data.price),
        },
        partidaInfo.chapterId,
        partidaInfo.accountId,
        1,
      )
      styleUpdated.current.id = ""
      setContadorManual(contadorManual + 1)
    }, 2000)
  }
  const styleUpdated = useRef(null)
  const [isUpdated2, setIsUpdated] = useState(0)

  useEffect(() => {
    if (isUpdated2 == 0) {
      if (partidaInfo.updates == null) {
        styleUpdated.current.id = ""
      }
    }
  }, [isUpdated2])

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      const inputs = Array.from(
        document.querySelectorAll("input#inputUnidades"),
      )
      const currentIndex = inputs.findIndex(input => input === event.target)
      const nextInput = inputs[currentIndex + 2]
      if (nextInput) {
        nextInput.focus()
      }
    }
  }

  const handleUpdates = action => {
    updatePartidav2(
      {
        ...partida,
        price: parseFloat(partida.price),
      },
      partidaInfo.chapterId,
      user.sub,
      partidaInfo.accountId,
    )
    setTimeout(() => {
      setContador3(contador3 + 1)
      if (styleUpdated.current) {
        styleUpdated.current.id = ""
      }
      isUpdates()
      setContadorActualizaciones(contadorActualizaciones + 1)
      setIsUpdated(0)
    }, 1000)
  }

  useEffect(() => {
    if (isUpdates == 0) {
      styleUpdated.current.id = ""
    }
  }, [isUpdates])

  const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean)
    if (!filteredRefs.length) return null
    if (filteredRefs.length === 0) return filteredRefs[0]
    return inst => {
      for (const ref of filteredRefs) {
        if (typeof ref === "function") {
          ref(inst)
        } else if (ref) {
          ref.current = inst
        }
      }
    }
  }

  useEffect(() => {
    setIsChecked(imChecked)
  }, [imChecked])

  const [openDecompositionModal, setOpenDecompositionModal] = useState(false)

  const manageDecomposition = async partidaInfo => {
    if (partidaInfo.decomposition.length > 0) {
      await deletePartDecomposition(partidaInfo, false)
      updatePartidaState({ ...partida, decomposition: [] })
    } else {
      await deletePartDecomposition(partidaInfo, true)
      updatePartidaState({
        ...partida,
        _decomposition: [],
        decomposition: partida._decomposition,
      })
    }
  }

  return (
    <>
      <tbody className="position-relative">
        {
          //fila capitulo certificado
          certificaciones && indice == 0 && (
            <tr
              ref={
                soyFinal || !soyFinal
                  ? styleUpdated
                  : mergeRefs(styleUpdated, drag, drop)
              }
              className="partidasInfo"
              style={{
                display: "",
                visibility: "inherit",
                borderBottom: "0px",
                border: partidaInfo.checked ? "1.5px solid #4461D7" : "0px",
              }}
            >
              {
                //CHECKBOX Y UNIDADES
              }
              <td style={{ borderLeft: "none", width: "79px" }}>
                <div
                  className="inline"
                  style={{
                    height: "35px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                ></div>
              </td>

              <td style={{ borderLeft: "none" }}>
                <div
                  id="estiloBuscador"
                  style={{ display: "flex", position: "relative" }}
                >
                  <p
                    style={{
                      display: "block",
                      textAlign: "left",
                      marginBottom: "0px",
                      marginRight: "20px",
                      marginLeft: "23px",
                    }}
                  >
                    <span
                      style={{ maxWidth: "100% !important", fontWeight: "600" }}
                      className="partidaNameAutoExpandible"
                      role="textbox"
                      autoComplete="off"
                      name="resumen"
                    >
                      {" "}
                      Certificación del capítulo
                    </span>
                  </p>
                </div>
              </td>

              {
                <td
                  style={{
                    maxWidth: "86px",
                    borderLeft: "none",
                  }}
                ></td>
              }

              <td
                style={{
                  minWidth: "48px",
                  maxWidth: "64px",
                  borderLeft: "none",
                }}
              >
                <div style={{ height: "auto" }}></div>
              </td>

              {
                //AQUI ESTA EL PRECIO OBJETIVO Y SU TOTAL
                preferencias.precioCoste && !certificaciones && (
                  <>
                    <td
                      style={{
                        minWidth: "127px",
                        color: "#414141",
                        background:
                          partidaInfo.decomposition.length > 0 && "#E4E4E4",
                        borderLeft: "none",
                      }}
                    >
                      <div style={{ marginTop: "", height: "auto" }}></div>
                    </td>
                    <td
                      style={{
                        minWidth: "126px",
                        color: "#414141",
                        position: "relative",
                        borderLeft: "none",
                      }}
                    >
                      <div style={{ marginTop: "", height: "auto" }}></div>
                    </td>
                  </>
                )
              }
              {/*
                preferencias.clientPrice && !soyFinal && (<>
                  <td style={{ minWidth: "64px", background:"FF0000"}}>
                    <div style={{ marginTop: "", height: "auto" }}></div>
                  </td>
                  <td style={{minWidth: "64px", borderLeft: "1px solid #F3F1F1", paddingTop: "1px"}}>
                    <div style={{ marginTop: "", height: "auto" }}></div>
                  </td>
                </>)
		*/}
              {certificaciones && (
                <>
                  <td style={{ borderLeft: "0px" }}></td>
                  <td style={{ borderLeft: "0px" }}></td>
                  <td style={{ borderLeft: "0px" }}></td>
                  <td style={{ borderLeft: "0px" }}></td>
                  <td style={{ borderLeft: "0px" }}></td>
                  <td style={{ borderLeft: "0px" }}></td>
                  <td style={{ borderLeft: "0px" }}></td>
                  <td
                    style={{
                      borderLeft: "1px solid rgb(243, 241, 241)",
                      minWidth: "64px",
                      background: "#F3D66338",
                    }}
                  >
                    <div style={{ height: "auto", width: "80px" }}>
                      <PriceInput
                        ref={inputRef}
                        id={`currentPercentageChapter${partida.chapterId}`}
                        name={`currentPercentageChapter${partida.chapterId}`}
                        value={
                          viewState.hasOwnProperty(partida.chapterId) &&
                          viewState[partida.chapterId].hasOwnProperty(
                            "cert_current_perc",
                          )
                            ? viewState[partida.chapterId]["cert_current_perc"]
                            : parseFloat(0).toFixed(2)
                        }
                        onClick={e => {
                          if (!e) var e = window.event
                          e.cancelBubble = true
                          if (e.stopPropagation) e.stopPropagation()
                          inputRef.current && inputRef.current.select()
                        }}
                        min="0"
                        type="number"
                        onChange={currentPercentageChapterOnChange}
                        onBlur={currentPercentageChapterOnBlur}
                        style={{
                          color: "#414141",
                          fontWeight: "400",
                          backgroundColor: "transparent",
                          WebkitTextFillColor: "#414141",
                          height: "35px",
                          fontFamily: "Helvetica",
                          width: "80%",
                          justifyContent: "center",
                          textAlign: "center",
                          border: "none",
                        }}
                      />
                    </div>
                  </td>
                </>
              )}
              {
                //COLUMNAS DESCUENTO
              }
              {preferencias.descuento && !certificaciones && (
                <td
                  style={{
                    borderLeft: "1px solid #F3F1F1",
                    background: "#F3D66338",
                  }}
                ></td>
              )}
              {
                //AQUI ESTAN LAS OPCIONES ON HOVER
              }
              {!certificaciones && (
                <td
                  style={{
                    borderLeft: "none",
                    minWidth: "40px",
                    height: "35px",
                    background:
                      preferencias.descuento == true ? "#F3D66338" : null,
                  }}
                >
                  {!certificaciones && (
                    <div
                      onClick={e => {
                        if (!e) var e = window.event
                        e.cancelBubble = true
                        if (e.stopPropagation) e.stopPropagation()
                      }}
                      className="detallesPartiShow"
                      style={{
                        backgroundColor: "transparent",
                        width: "40px",
                        zIndex: "10",
                        position: "relative",
                        minWidth: "40px",
                        paddingTop: "5px",
                        height: "35px",
                        justifyContent: "center",
                        marginLeft: "-10px",
                      }}
                    >
                      {partidaInfo.checked ? (
                        <DeleteOutlineIcon
                          style={{ color: "#414141", width: "18px" }}
                          onClick={e => {
                            freeTrialExpired
                              ? openFreeTrialExpire()
                              : setOpenEliminate(true)
                          }}
                        />
                      ) : partsChecked.length > 0 ? (
                        <div></div>
                      ) : (
                        <div className="inline">
                          <DeleteOutlineIcon
                            style={{
                              color: "#414141",
                              width: "16px",
                              marginRight: "-3px",
                            }}
                            onClick={e => {
                              freeTrialExpired
                                ? openFreeTrialExpire()
                                : setOpenEliminate(true)
                            }}
                          />

                          {whoIAm != "last" && (
                            <img
                              src={ArrowDownIcon}
                              style={{
                                color: "#414141",
                                width: "13px",
                                height: "13px",
                                marginTop: "6px",
                                marginLeft: "3px",
                                marginRight: "-3px",
                              }}
                              onClick={e => {
                                movePartsWithArrows(
                                  projectId,
                                  partidaInfo,
                                  "down",
                                )
                              }}
                            />
                          )}
                          {whoIAm != "first" && (
                            <img
                              src={ArrowUpIcon}
                              style={{
                                color: "#414141",
                                width: "12px",
                                height: "12px",
                                marginTop: "6px",
                                marginLeft: "3px",
                              }}
                              onClick={e => {
                                movePartsWithArrows(
                                  projectId,
                                  partidaInfo,
                                  "up",
                                )
                              }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </td>
              )}
            </tr>
          )
        }
        <tr
          onClick={e => {
            if (!e) var e = window.event
            e.cancelBubble = true
            if (e.stopPropagation) e.stopPropagation()
            !certificaciones && setOpenAdd(true)
          }}
          ref={
            soyFinal || !soyFinal
              ? styleUpdated
              : mergeRefs(styleUpdated, drag, drop)
          }
          className="partidasInfo"
          style={{
            cursor: "pointer",
            display: "",
            visibility: "inherit",
            borderBottom: "0px",
            border: partidaInfo.checked ? "1.5px solid #4461D7" : "0px",
          }}
        >
          {
            //CHECKBOX Y UNIDADES
          }
          <td
            style={{
              borderLeft: "none",
              width: "79px",
              backgroundColor: partidaInfo.comment && "#F5D2D2",
            }}
          >
            <div
              className="inline"
              style={{
                height: "35px",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <input
                type={"checkbox"}
                onClick={e => {
                  if (!e) var e = window.event
                  e.cancelBubble = true
                  if (e.stopPropagation) e.stopPropagation()
                }}
                onChange={e => {
                  if (!e) var e = window.event
                  e.cancelBubble = true
                  if (e.stopPropagation) e.stopPropagation()
                  handleCheckBox(e)
                }}
                checked={partidaInfo.checked}
                style={{
                  width: "12px",
                  marginRight: "5px",
                  cursor: "pointer",
                  marginTop: "-2px",
                }}
              ></input>

              <p
                className="segundoIconoShow"
                style={{
                  paddingTop: "8px",
                  color: partidaInfo.comment ? "#BF2929" : "#414141",
                  fontSize: "14px",
                }}
              >
                {numeroIndex + "." + (partidaInfo.position + 1)}
              </p>
              {partidaInfo.comment && (
                <div
                  className="segundoIcono"
                  style={{
                    position: "absolute",
                    marginLeft: "17px",
                    marginTop: "30px",
                    zIndex: "99",
                  }}
                >
                  <div className="recuadro">
                    <div className="recuadroPico"></div>
                    {i18n.t("Contiene notas")}
                  </div>
                </div>
              )}
            </div>
          </td>
          {
            //COLUMNAS DESCRIPCION
          }
          <td style={{ borderLeft: "1px solid #E9ECEF" }}>
            <div
              id="estiloBuscador"
              style={{ display: "flex", position: "relative" }}
              onClick={e => {
                if (!e) var e = window.event
                e.cancelBubble = true
                if (e.stopPropagation) e.stopPropagation()
                !certificaciones && setOpenAdd(true)
              }}
            >
              <SearchOutlinedIcon
                id="lupaBud"
                style={{
                  visibility: "hidden",
                }}
              />
              {/* {partida.code != null ||
              (partida.name == null && partida.description == null) ||
              partida.comeFrom == user?.sub ? (
                <SearchOutlinedIcon
                  id="lupaBud"
                  style={{
                    visibility: "hidden",
                  }}
                />
              ) : (
                <SearchOutlinedIcon
                  id="lupaBud"
                  onClick={e => {
                    if (!e) var e = window.event
                    e.cancelBubble = true
                    if (e.stopPropagation) e.stopPropagation()
                    setOpenModalCreatePartida(true)
                  }}
                  style={{ marginTop: "8px" }}
                />
              )} */}

              <ListPartida
                items={itemsMemo}
                addPartida={addPartida}
                handleChangePartida={handleChangePartida}
                partida={partida}
                handleCustom={handleCustom}
                estoyEnFinal={soyFinal}
                creadaEnFinal={partidaInfo.creadaEnFinal}
                projectInfo={projectInfo}
                version={version}
                openModalCreatePartida={openModalCreatePartida}
                setOpenModalCreatePartida={setOpenModalCreatePartida}
                setOpenAdd={setOpenAdd}
                setPartida={setPartida}
              />

              {preferencias.procedencia &&
                (partidaInfo.comeFrom == user.sub && !soyFinal ? (
                  <div
                    className="editarPartidaDiv"
                    style={{
                      position: "absolute",
                      width: "50px",
                      right: "0px",
                      top: "55%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <img
                      style={{
                        width: "32px",
                        height: "32px",
                        cursor: "pointer",
                      }}
                      className="segundoIconoShow"
                      onClick={e => {
                        //e.preventDefault(); e.stopPropagation();
                        //setOpen(true)
                      }}
                      src={manualPart}
                    />
                    <div
                      className="segundoIcono"
                      style={{
                        position: "absolute",
                        marginLeft: "-20px",
                        marginTop: "-2px",
                        zIndex: "99",
                      }}
                    >
                      <div className="recuadro">
                        <div className="recuadroPico"></div>
                        {i18n.t("Editar partida")}
                      </div>
                    </div>
                  </div>
                ) : partidaInfo.comeFrom == "cype" ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "50px",
                      right: "0px",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      top: "47%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <img
                      style={{
                        width: "22px",
                        height: "22px",
                        marginLeft: "2px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                      }}
                      src={cypeLogo}
                    />
                  </div>
                ) : partidaInfo.comeFrom == "bedec" ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "50px",
                      right: "0px",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      top: "47%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <img
                      style={{
                        width: "22px",
                        height: "22px",
                        marginLeft: "2px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                      }}
                      src={bedecLogo}
                    />
                  </div>
                ) : partidaInfo.comeFrom == "tribboo" ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "50px",
                      right: "0px",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      top: "47%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <img
                      style={{
                        width: "22px",
                        height: "22px",
                        marginLeft: "2px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                      }}
                      src={tribbooLogo}
                    />
                  </div>
                ) : partidaInfo.comeFrom == "preoc" ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "50px",
                      right: "0px",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      top: "47%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <img
                      style={{
                        width: "22px",
                        height: "22px",
                        marginLeft: "2px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                      }}
                      src={preocLogo}
                    />
                  </div>
                ) : partidaInfo.comeFrom == "user-database" ? (
                  <div
                    style={{
                      position: "absolute",
                      width: "50px",
                      right: "0px",
                      height: "100%",
                      justifyContent: "center",
                      display: "flex",
                      top: "47%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <img
                      style={{
                        width: "19px",
                        height: "19px",
                        marginLeft: "2px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                      }}
                      src={dbLogo}
                    />
                  </div>
                ) : null)}
            </div>
          </td>
          {
            //COLUMNAS CANTIDAD
            quantity == "Unidades" ? (
              <td style={{ minWidth: "86px", borderLeft: "1px solid #E9ECEF" }}>
                <div style={{ height: "auto" }}>{quantity}</div>
              </td>
            ) : (
              <td
                style={{
                  background:
                    !checkIfMeasuresAreEmpty(partidaInfo.measures) &&
                    new Date(partidaInfo.createdAt).getTime() !=
                      new Date(partidaInfo.updatedAt).getTime() &&
                    "#E4E4E4",
                  minWidth: "86px",
                  borderLeft: "1px solid #E9ECEF",
                }}
              >
                <div style={{ height: "auto" }}>
                  <input
                    style={{
                      color: "#414141",
                      fontWeight: "400",
                      backgroundColor: "transparent",
                      WebkitTextFillColor: "#414141",
                      height: "35px",
                    }}
                    id="inputUnidades"
                    type="number"
                    min="0"
                    onKeyDown={handleKeyDown}
                    onChange={handleViewChange}
                    onClick={onClickStopPropagation}
                    onFocus={e => {
                      e.target.select()
                    }}
                    onBlur={e => handleViewBlur(e.target.name, e.target.value)}
                    name="quantity"
                    value={
                      viewState.hasOwnProperty("quantity")
                        ? viewState.quantity
                        : parseFloat(partida.quantity).toFixed(2)
                    }
                    disabled={
                      isBudgetClosed ||
                      projectInfo.status === "CLOSED" ||
                      version ||
                      certificaciones
                        ? true
                        : user
                          ? freeTrialExpired || user["custom:role"] === "WORKER"
                            ? true
                            : !checkIfMeasuresAreEmpty(partidaInfo.measures)
                              ? true
                              : false
                          : false
                    }
                  />
                </div>
              </td>
            )
          }
          {
            //COLUMNA UNIDAD
            <td
              style={{
                maxWidth: "86px",
                borderLeft: "1px solid #E9ECEF",
              }}
            >
              <div style={{ marginTop: "", height: "auto" }}>
                {partidaInfo.unity}
              </div>
            </td>
          }
          {
            //COLUMNAS REF BD
          }
          {preferencias.basededatos == true && soyFinal != true ? (
            <>
              <td
                colSpan={"2"}
                style={{
                  maxWidth: "86px",

                  borderLeft: "1px solid #E9ECEF",
                }}
              >
                <div style={{ marginTop: "", height: "auto" }}>
                  {Math.round(
                    parseFloat(partidaInfo.costeGeneral) +
                      parseFloat(partidaInfo.costeIndirecto) +
                      parseFloat(partidaInfo.industrialProfit),
                    2,
                  )}
                  %
                </div>
              </td>
            </>
          ) : null}
          {
            // Costo Objetivo
            preferencias.precioCoste && !certificaciones && (
              <>
                <PartTableInput
                  //disabled={partida.decomposition?.length > 0 || partida.costReal}
                  disabled={isBudgetClosed || partida.decomposition?.length > 0}
                  onClick={onClickStopPropagation}
                  name="price"
                  value={partida.price}
                  partida={partida}
                  symbol={currencySymbol}
                  onBlur={(name, value) => {
                    console.log(`onBlur: ${name} => ${value}`)

                    const clientPriceWithoutDiscount =
                      parseFloat(value) / calcMarginFactor(partida)
                    const clientPrice = calcApplyDiscount(
                      clientPriceWithoutDiscount,
                      partida.discount,
                    )

                    updatePartidaState({
                      ...partida,
                      [name]: parseFloat(value),
                      clientPriceWithoutDiscount: clientPriceWithoutDiscount,
                      clientPrice: clientPrice,
                    })
                    //updatePartidav2({...partida, [name]: parseFloat(value)}, partidaInfo.chapterId, partidaInfo.accountId)
                  }}
                />

                <PartTableInput
                  //disabled={partida.decomposition?.length > 0 || partida.costReal}
                  disabled={isBudgetClosed || partida.decomposition?.length > 0}
                  onClick={onClickStopPropagation}
                  name="priceTotal"
                  value={partida.price * partida.quantity}
                  partida={partida}
                  symbol={currencySymbol}
                  onBlur={(name, value) => {
                    const price =
                      parseFloat(value) / parseFloat(partida.quantity)
                    const clientPriceWithoutDiscount =
                      price / calcMarginFactor(partida)
                    const clientPrice = calcApplyDiscount(
                      clientPriceWithoutDiscount,
                      partida.discount,
                    )
                    updatePartidaState({
                      ...partida,
                      [name]: price,
                      clientPriceWithoutDiscount: clientPriceWithoutDiscount,
                      clientPrice: clientPrice,
                    })
                  }}
                  isOutlined={partidaInfo?.decomposition.length > 0}
                  outlined={e => {
                    e.stopPropagation()
                    setOpenDecompositionModal(true)
                  }}
                />
              </>
            )
          }
          {
            // Coste Real
            preferencias.costReal && !certificaciones && (
              <>
                {
                  // Margen Real
                  preferencias.basededatos && (
                    <>
                      <PartTableInput
                        name="marginReal"
                        symbol={"%"}
                        disabled={true}
                        green={partida.quantity && underObjective(partida)}
                        yellow={partida.quantity && overObjective(partida)}
                        red={partida.quantity && overPrice(partida)}
                        onClick={onClickStopPropagation}
                        value={marginRealPercCalc(partida)}
                        partida={partida}
                      />
                    </>
                  )
                }

                <PartTableInput
                  onClick={onClickStopPropagation}
                  name="costReal"
                  value={costRealCalc(partida) || partida.price}
                  symbol={currencySymbol}
                  partida={partida}
                  onBlur={(name, value) => {
                    console.log(`onBlur: ${name} => ${value}`)
                    updatePartidaState({
                      ...partida,
                      [name]: parseFloat(value),
                    })
                    //updatePartidav2({...partida, [name]: parseFloat(value)}, partidaInfo.chapterId, partidaInfo.accountId)
                  }}
                />
                <PartTableInput
                  onClick={onClickStopPropagation}
                  name="costRealTotal"
                  symbol={currencySymbol}
                  value={
                    (costRealCalc(partida) || partida.price) * partida.quantity
                  }
                  partida={partida}
                  onBlur={(name, value) => {
                    const costReal =
                      parseFloat(value) / parseFloat(partida.quantity)
                    updatePartidaState({ ...partida, costReal: costReal })
                  }}
                />
              </>
            )
          }
          {
            // Precio Cliente
            ((preferencias.clientPrice && !soyFinal) || certificaciones) && (
              <>
                <PartTableInput
                  name="clientPriceWithoutDiscount"
                  partida={partida}
                  disabled={isBudgetClosed || certificaciones} // add "|| partida.costReal" or "project.is_closed" later
                  onClick={onClickStopPropagation}
                  symbol={currencySymbol}
                  value={
                    partida.hasOwnProperty("clientPriceWithoutDiscount")
                      ? partida.clientPriceWithoutDiscount
                      : null
                  } /* partida parece no tener esa propiedad, si partidaInfo */
                  onBlur={(name, value) => {
                    updatePartidaState({
                      ...partida,
                      [name]: parseFloat(value),
                      clientPrice: calcApplyDiscount(
                        parseFloat(value),
                        partida.discount,
                      ),
                      margin: calcMarginBetween(partida.price, value),
                    })
                  }}
                />

                <PartTableInput
                  name="clientPriceWithoutDiscountTotal"
                  partida={partida}
                  symbol={currencySymbol}
                  disabled={isBudgetClosed || certificaciones} // add "|| partida.costReal" or "project.is_closed" later
                  onClick={onClickStopPropagation}
                  value={
                    partida.hasOwnProperty("clientPriceWithoutDiscount")
                      ? partida.clientPriceWithoutDiscount * partida.quantity
                      : null
                  }
                  onBlur={(name, value) => {
                    const clientPriceWithoutDiscount =
                      parseFloat(value) / parseFloat(partida.quantity)
                    updatePartidaState({
                      ...partida,
                      clientPriceWithoutDiscount: clientPriceWithoutDiscount,
                      clientPrice: calcApplyDiscount(
                        parseFloat(value),
                        partida.discount,
                      ),
                      margin: calcMarginBetween(
                        partida.price,
                        clientPriceWithoutDiscount,
                      ),
                    })
                  }}
                />
              </>
            )
          }
          {
            // Precio Cliente con Descuento
            preferencias.descuento && (
              <>
                <PartTableInput
                  name="clientPrice"
                  partida={partida}
                  symbol={currencySymbol}
                  disabled={true}
                  onClick={onClickStopPropagation}
                  value={
                    partida.hasOwnProperty("clientPrice")
                      ? partida.clientPrice
                      : null
                  } /* partida parece no tener esa propiedad, si partidaInfo */
                  /* onBlur={} */
                />

                <PartTableInput
                  name="clientPrice"
                  partida={partida}
                  symbol={currencySymbol}
                  disabled={true}
                  onClick={onClickStopPropagation}
                  value={
                    partida.hasOwnProperty("clientPrice")
                      ? partida.clientPrice * partida.quantity
                      : null
                  }
                  /* onBlur={} */
                />
              </>
            )
          }
          {
            // Certificaciones
            certificaciones && (
              <>
                {/* Certificación Total */}
                <PartTableInput
                  name="cert_totalView"
                  partida={viewState}
                  onClick={onClickStopPropagation}
                  symbol={i18n.t("Ud.")}
                  value={
                    viewState.hasOwnProperty("cert_totalView")
                      ? viewState.cert_totalView
                      : parseFloat(
                          partida.cert_current + partida.cert_previous,
                        ).toFixed(2)
                  }
                  onBlur={handleViewBlur}
                />
                <PartTableInput
                  name="cert_totalPercView"
                  partida={viewState}
                  onClick={onClickStopPropagation}
                  symbol={"%"}
                  value={
                    viewState.hasOwnProperty("cert_totalPercView")
                      ? viewState.cert_totalPercView
                      : clamp(
                          parseFloat(
                            ((partida.cert_previous + partida.cert_current) /
                              partida.quantity) *
                              100 || 0,
                          ),
                          0,
                          100,
                        ).toFixed(2)
                  }
                  onBlur={handleViewBlur}
                />
                {/* Certificación previa */}
                <PartTableInput
                  name="cert_previous"
                  partida={partida}
                  symbol={i18n.t("Ud.")}
                  disabled={true}
                  value={partida.cert_previous}
                />
                <PartTableInput
                  name="cert-previousPerc"
                  partida={partida}
                  symbol={"%"}
                  disabled={true}
                  value={(partida.cert_previous / partida.quantity) * 100}
                />
                {/* Certificación actual */}
                <PartTableInput
                  name="cert_currentView"
                  partida={viewState}
                  onClick={onClickStopPropagation}
                  symbol={i18n.t("Ud.")}
                  value={
                    viewState.hasOwnProperty("cert_currentView")
                      ? viewState.cert_currentView
                      : parseFloat(partida.cert_current || 0).toFixed(2)
                  }
                  onChange={handleViewChange}
                  onBlur={handleViewBlur}
                />
                <PartTableInput
                  name="cert_currentPercView"
                  partida={viewState}
                  onClick={onClickStopPropagation}
                  symbol={"%"}
                  value={
                    viewState.hasOwnProperty("cert_currentPercView")
                      ? viewState.cert_currentPercView
                      : clamp(
                          parseFloat(
                            (100 * partida.cert_current) / partida.quantity ||
                              0,
                          ),
                          0,
                          100,
                        ).toFixed(2)
                  }
                  onChange={handleViewChange}
                  onBlur={handleViewBlur}
                />
                <PartTableInput
                  name="cert_currentPriceView"
                  partida={viewState}
                  symbol={currencySymbol}
                  disabled={true}
                  value={currencyFmt(
                    viewState.hasOwnProperty("cert_currentPriceView")
                      ? viewState.cert_currentPriceView
                      : partida.cert_current * partida.clientPrice,
                  )}
                />
              </>
            )
          }
          {
            //AQUI ESTAN LAS OPCIONES ON HOVER
            !certificaciones && (
              <td
                style={{
                  borderLeft: "none",
                  minWidth: "40px",
                  height: "35px",
                  background:
                    preferencias.descuento == true ? "#F3D66338" : null,
                }}
              >
                <div
                  onClick={onClickStopPropagation}
                  className="detallesPartiShow"
                  style={{
                    backgroundColor: "transparent",
                    width: "40px",
                    zIndex: "10",
                    position: "relative",
                    minWidth: "40px",
                    paddingTop: "5px",
                    height: "35px",
                    justifyContent: "center",
                  }}
                >
                  {partidaInfo.checked ? (
                    <DeleteOutlineIcon
                      style={{
                        color: "#414141",
                        width: "18px",
                        marginLeft: "2px",
                      }}
                      onClick={e => {
                        freeTrialExpired
                          ? openFreeTrialExpire()
                          : setOpenEliminate(true)
                      }}
                    />
                  ) : partsChecked.length > 0 ? (
                    <div></div>
                  ) : (
                    <div className="inline">
                      {!isBudgetClosed && (
                        <DeleteOutlineIcon
                          style={{
                            color: "#414141",
                            width: "16px",
                            marginLeft: "2px",
                          }}
                          onClick={e => {
                            freeTrialExpired
                              ? openFreeTrialExpire()
                              : setOpenEliminate(true)
                          }}
                        />
                      )}

                      {!isBudgetClosed && whoIAm != "last" && (
                        <img
                          src={ArrowDownIcon}
                          style={{
                            color: "#414141",
                            width: "13px",
                            height: "13px",
                            marginTop: "6px",
                            marginLeft: "3px",
                            marginLeft: "4px",
                          }}
                          onClick={e => {
                            movePartsWithArrows(projectId, partidaInfo, "down")
                          }}
                        />
                      )}

                      {!isBudgetClosed && whoIAm != "first" && (
                        <img
                          src={ArrowUpIcon}
                          style={{
                            color: "#414141",
                            width: "12px",
                            height: "12px",
                            marginTop: "6px",
                            marginLeft: "4px",
                          }}
                          onClick={e => {
                            movePartsWithArrows(projectId, partidaInfo, "up")
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              </td>
            )
          }
        </tr>
        {partidaInfo.contradicted_by_id && <StrikethroughRow />}
      </tbody>
      {openAdd && (
        <NewModalPartida
          open={openAdd}
          setOpen={setOpenAdd}
          partidaInfo={{
            ...partidaInfo,
            ref: `${numeroIndex}.${partidaInfo.position + 1}`,
          }}
          chapterId={partidaInfo.chapterId}
        />
      )}

      <ModalEditarPartida open={open} setOpen={setOpen} partida={partidaInfo} />
      {openEliminate && (
        <ModalDeletePart
          openEliminate={openEliminate}
          setOpenEliminate={setOpenEliminate}
          partida={partida}
        />
      )}
      {openDecompositionModal && (
        <ModalDecomposition
          open={openDecompositionModal}
          setOpen={setOpenDecompositionModal}
          partida={partidaInfo}
          functionToDo={manageDecomposition}
        />
      )}
    </>
  )
}

Partida.propTypes = {
  partidaInfo: PropTypes.object.isRequired,
  preferencias: PropTypes.object.isRequired,
  indice: PropTypes.number.isRequired,
  soyFinal: PropTypes.bool,
  projectInfo: PropTypes.object.isRequired,
  modificado: PropTypes.number,
  setModificado: PropTypes.func,
  contadorActualizaciones: PropTypes.number,
  setContadorActualizaciones: PropTypes.func,
  ocultoFinal: PropTypes.bool,
  isChecked: PropTypes.bool,
  setIsChecked: PropTypes.func,
  imDragging: PropTypes.bool,
  setImDragging: PropTypes.func,
  version: PropTypes.object,
  numeroIndex: PropTypes.string,
  openModalCreatePartida: PropTypes.bool,
  setOpenModalCreatePartida: PropTypes.func,
  whoIAm: PropTypes.string,
  certificaciones: PropTypes.bool,
  currentPercentageChapter: PropTypes.number,
  setCurrentPercentageChapter: PropTypes.func,
  currentPercentagePartida: PropTypes.number,
  setParentUpdate: PropTypes.func,
  parentUpdate: PropTypes.object,
  isFirstTime: PropTypes.bool,
}

export default React.memo(Partida)
