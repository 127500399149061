import React, { useEffect, useRef, useState, useContext } from "react"
import { Modal, CircularProgress } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { HelveticaLight, PoppinsSemiBold } from "common/typographies"
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp"
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp"
import TeamsContext from "context/teams/teamsContext"
import AuthContext from "context/auth/authContext"
import { useParams } from "react-router-dom"
import ProjectContext from "context/projects/projectContext"
import i18n from "i18n"
import { FakeInput } from "common/containers"
import { KeyboardArrowDownOutlined } from "@mui/icons-material"
import { HelveticaBold } from "common/typographies"
import { YellowButton } from "common/buttons"
import cloudDownload from "assets/customIcons/somethingIsBad.svg"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "fit-content",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    width: "430px",
    height: "472px",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const InviteUserModal = ({ open, setOpen }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [focused, setFocused] = useState(false)
  const refNombre = useRef(null)
  const refEmail = useRef(null)
  const [campos, guardarCampos] = useState({
    nombre: "",
    email: "",
  })
  const { projectId } = useParams()
  const [selectedRol, setSelectedRol] = useState("")
  const { createWorker } = useContext(TeamsContext)
  const { user, sendInvitation, internalUser } = useContext(AuthContext)
  const { getProject, projectInfo } = useContext(ProjectContext)

  const [selectingRol, setSelectingRol] = useState(false)
  useEffect(() => {
    getProject(projectId)
  }, [])

  const obtenerCampos = e => {
    guardarCampos({
      ...campos,
      [e.target.name]: e.target.value,
    })
  }

  /*Referencias para iconos confirm and error*/
  const refNombreConfirm = useRef(null)
  const refEmailConfirm = useRef(null)

  const refNombreError = useRef(null)
  const refEmailError = useRef(null)

  const [validaNombre, setValidaNombre] = useState(false)
  const [validaMail, setValidaMail] = useState(false)

  const handleOnClick = e => {
    if (e.target.name == "nombre") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } else if (e.target.name == "email") {
      refEmail.current["id"] = "focus"
      refEmailConfirm.current.id = "confirmNotVisible"
      refEmailError.current.id = "errorNotVisible"
    }
    setSelectingRol(false)
  }
  const handleEnter = event => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }
  const onFocus = e => {
    setFocused(true)
    if (e.target.name == "nombre") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } else if (e.target.name == "email") {
      refEmail.current["id"] = "focus"
      refEmailConfirm.current.id = "confirmNotVisible"
      refEmailError.current.id = "errorNotVisible"
    }
  }
  function nameIsValid(name) {
    var matches = name.match(/\d+/g)
    if (matches != null) {
      return false
    } else {
      return true
    }
  }
  const [loading, setLoading] = useState(false)
  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }
  const onBlur = e => {
    setFocused(false)
    if (e.target.name == "nombre") {
      if (nameIsValid(campos.nombre) && campos.nombre.length > 0) {
        setValidaNombre(true)
        refNombre.current.id = " "
        refNombreConfirm.current.id = "confirmVisible"
        refNombreError.current.id = "errorNotVisible"
      } else {
        refNombre.current.id = "errorValidation"
        setValidaNombre(false)
        refNombreConfirm.current.id = "confirmNotVisible"
        refNombreError.current.id = "errorVisible"
      }
    } else if (e.target.name == "email") {
      if (emailIsValid(campos.email)) {
        setValidaMail(true)
        refEmail.current.id = " "
        refEmailConfirm.current.id = "confirmVisible"
        refEmailError.current.id = "errorNotVisible"
      } else {
        refEmail.current.id = "errorValidation"
        setValidaMail(false)
        refEmailError.current.id = "errorVisible"
        refEmailConfirm.current.id = "confirmNotVisible"
      }
    }
  }

  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const handleInvite = async e => {
    e.preventDefault()
    if (validaMail && validaNombre) {
      let customRole = {}
      if (selectedRol.length > 0) {
        customRole = {
          color: "black",
          value: selectedRol[0],
          bgColor: selectedRol[1],
        }
      }
      setLoading(true)
      const result = await sendInvitation({
        email: campos.email,
        name: campos.nombre,
        customRole: customRole,
        organizationId: user["custom:organizationId"],
      })
      console.log(result)
      setLoading(false)
      if (!result?.member) {
        setErrorMessage(result)
        setError(true)
      } else {
        setOpen(false)
      }
    }
    if (!validaNombre) {
      refNombre.current.id = "errorValidation"
      setValidaNombre(false)
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorVisible"
    }
    if (!validaMail) {
      refEmail.current.id = "errorValidation"
      setValidaMail(false)
      refEmailError.current.id = "errorVisible"
      refEmailConfirm.current.id = "confirmNotVisible"
    }
  }
  return (
    <Modal open={open} onClose={() => null}>
      <div style={modalStyle} className={classes.paper}>
        <CloseIcon
          id="cerrar"
          style={{ marginRight: "13px" }}
          onClick={() => {
            setOpen(false)
          }}
        ></CloseIcon>
        {error ? (
          <>
            <div
              style={{
                justifyContent: "center",
                position: "relative",
                textAlign: "center",
                display: "block",
                margin: "30px",
                marginTop: "100px",
              }}
            >
              <img
                src={cloudDownload}
                style={{ width: "100px", height: "70px" }}
              />
              <HelveticaBold
                style={{
                  fontSize: "16px",
                  marginTop: "20px",
                  color: "#414141",
                }}
              >
                {i18n.t("Parece que ha ocurrido un error")}
              </HelveticaBold>
              {errorMessage == "user already exists in the organization" ? (
                <HelveticaBold
                  style={{
                    fontSize: "12px",
                    lineHeight: "13px",
                    marginTop: "10px",
                    color: "#414141",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t(
                    "El usuario al que intentas invitar ya pertenece a esta organización",
                  )}
                </HelveticaBold>
              ) : errorMessage ==
                "user already belongs to another organization" ? (
                <HelveticaBold
                  style={{
                    fontSize: "12px",
                    lineHeight: "13px",
                    marginTop: "10px",
                    color: "#414141",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t(
                    "El usuario al que intentas invitar ya tiene una cuenta creada",
                  )}
                </HelveticaBold>
              ) : errorMessage == "user already invited" ? (
                <HelveticaBold
                  style={{
                    fontSize: "12px",
                    lineHeight: "13px",
                    marginTop: "10px",
                    color: "#414141",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t(
                    "El usuario al que intentas invitar ya ha sido invitado",
                  )}
                </HelveticaBold>
              ) : errorMessage == "user already has an organization" ? (
                <HelveticaBold
                  style={{
                    fontSize: "12px",
                    lineHeight: "13px",
                    marginTop: "10px",
                    color: "#414141",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t(
                    "El usuario al que intentas invitar ya pertenece a otro equipo",
                  )}
                </HelveticaBold>
              ) : (
                <HelveticaBold
                  style={{
                    fontSize: "12px",
                    lineHeight: "13px",
                    marginTop: "10px",
                    color: "#414141",
                    fontWeight: "400",
                  }}
                >
                  {i18n.t("Nuestro equipo esta trabajando en ello,")}
                  <HelveticaBold
                    style={{
                      fontSize: "12px",
                      marginTop: "10px",
                      color: "#414141",
                    }}
                  >
                    {i18n.t("Pruebe de nuevo mas tarde")}
                  </HelveticaBold>
                </HelveticaBold>
              )}

              <YellowButton
                onClick={() => {
                  setOpen(false)
                }}
                style={{
                  marginTop: "5px",
                  width: "160px",
                  height: "40px",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
              >
                {i18n.t("Aceptar")}
              </YellowButton>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                marginTop: "60px",
              }}
            >
              <PoppinsSemiBold style={{ fontSize: "18px" }}>
                {i18n.t("Invitar Usuario")}
              </PoppinsSemiBold>
            </div>
            <form id="Form">
              <p className="Titulos" style={{ marginLeft: "44px" }}>
                {i18n.t("Nombre y Apellido")}
              </p>
              <div style={{ marginLeft: "25px", width: "90%" }}>
                <input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={handleEnter}
                  ref={refNombre}
                  onClick={handleOnClick}
                  placeholder="Nombre Apellido"
                  name="nombre"
                  value={campos.nombre}
                  onChange={obtenerCampos}
                ></input>
                <CheckCircleOutlineSharpIcon
                  style={{ width: "16px", color: "#3BA452" }}
                  ref={refNombreConfirm}
                  id="confirmNotVisible"
                ></CheckCircleOutlineSharpIcon>
                <ErrorOutlineSharpIcon
                  style={{ width: "16px", color: "#BF2929" }}
                  ref={refNombreError}
                  id="errorNotVisible"
                ></ErrorOutlineSharpIcon>
              </div>
              <p className="Titulos" style={{ marginLeft: "44px" }}>
                {i18n.t("Email")}
              </p>
              <div style={{ marginLeft: "25px", width: "90%" }}>
                <input
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyDown={handleEnter}
                  ref={refEmail}
                  onClick={handleOnClick}
                  placeholder="Email"
                  name="email"
                  value={campos.email}
                  onChange={obtenerCampos}
                ></input>
                <CheckCircleOutlineSharpIcon
                  style={{ width: "16px", color: "#3BA452" }}
                  ref={refEmailConfirm}
                  id="confirmNotVisible"
                ></CheckCircleOutlineSharpIcon>
                <ErrorOutlineSharpIcon
                  style={{ width: "16px", color: "#BF2929" }}
                  ref={refEmailError}
                  id="errorNotVisible"
                ></ErrorOutlineSharpIcon>
              </div>
              <p className="Titulos" style={{ marginLeft: "44px" }}>
                {i18n.t("Rol de usuario")}
              </p>
              <div style={{ marginLeft: "25px", width: "90%" }}>
                <FakeInput
                  style={{ backgroundColor: "transparent" }}
                  onClick={() => {
                    setSelectingRol(true)
                  }}
                >
                  {selectedRol == "" ? (
                    <div
                      className="inline"
                      style={{ position: "relative", width: "100%" }}
                    >
                      <HelveticaLight
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "#414141",
                          width: "100%",
                          paddingTop: "8px",
                          paddingLeft: "20PX",
                        }}
                      >
                        {i18n.t("Selecciona rol")}
                      </HelveticaLight>
                      <KeyboardArrowDownOutlined
                        style={{
                          position: "absolute",
                          right: "20px",
                          marginTop: "8px",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="inline"
                      style={{ position: "relative", width: "100%" }}
                    >
                      <div
                        id="tagColor"
                        className="tag"
                        style={{
                          cursor: "pointer",
                          marginLeft: "20px",
                          width: "80px",
                          background: selectedRol[1],
                          textAlign: "center",
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                          color: "black",
                          marginTop: "8px",
                        }}
                      >
                        {selectedRol[0]}
                      </div>
                      <KeyboardArrowDownOutlined
                        style={{
                          position: "absolute",
                          right: "20px",
                          marginTop: "8px",
                        }}
                      />
                    </div>
                  )}
                </FakeInput>
                {selectingRol ? (
                  <div
                    className="inline"
                    style={{
                      width: "100%",
                      height: "62px",
                      borderRadius: "6px",
                      background: "#FFFFFF",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div
                      onClick={() => {
                        setSelectedRol(["ADMIN.", "#78DD8E"])
                        setSelectingRol(false)
                      }}
                      id="tagColor"
                      className="tag"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        width: "80px",
                        background: "#78DD8E",
                        textAlign: "center",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      {i18n.t("ADMIN.")}
                    </div>
                    <div
                      onClick={() => {
                        setSelectedRol(["DIR.OBRA", "#FF9DE4"])
                        setSelectingRol(false)
                      }}
                      id="tagColor"
                      className="tag"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        width: "80px",
                        background: "#FF9DE4",
                        textAlign: "center",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      {i18n.t("DIR.OBRA")}
                    </div>
                    <div
                      onClick={() => {
                        setSelectedRol(["DIR.PROYECTO", "#ECAD80"])
                        setSelectingRol(false)
                      }}
                      id="tagColor"
                      className="tag"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        width: "80px",
                        background: "#ECAD80",
                        textAlign: "center",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      {i18n.t("DIR.PROYECTO")}
                    </div>
                    <div
                      onClick={() => {
                        setSelectedRol(["ARQUITECTO", "#758FFB"])
                        setSelectingRol(false)
                      }}
                      id="tagColor"
                      className="tag"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        width: "80px",
                        background: "#758FFB",
                        textAlign: "center",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        color: "black",
                        marginTop: "20px",
                      }}
                    >
                      {i18n.t("ARQUITECTO")}
                    </div>
                  </div>
                ) : null}
              </div>
              <div id="centerPaco">
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  <button
                    onClick={e => {
                      handleInvite(e)
                    }}
                    className="AddContact"
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="UserInvited"
                    data-amplify-analytics-attrs={campos}
                  >
                    {i18n.t("Invitar")}
                  </button>
                )}
              </div>
            </form>
          </>
        )}
      </div>
    </Modal>
  )
}

InviteUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}
export default InviteUserModal
