import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import { CircularProgress } from "@mui/material"
import {
  PoppinsNormal,
  HelveticaBold,
  HelveticaLight,
} from "common/typographies"
import { YellowButton } from "common/buttons"
import AddIcon from "@mui/icons-material/Add"
import {
  BlackHoverButton4CreateDynamically,
  FakeInput,
} from "common/containers"
import { currencyFormatToConcept } from "lib/transformers"
import { HelveticaInput2 } from "common/inputs"
import ModalSelectTaxInModal from "../dropdowns/modalSelectTaxInModal"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext"

const DefinirImpuestosComponent = ({
  createFacturas,
  total,
  impuestos,
  setImpuestos,
  invoiceService,
  projectName,
}) => {
  const [openImpuestos, setOpenImpuestos] = useState(false)
  const [totalFinal, setTotalFinal] = useState(total)
  const { user } = useContext(AuthContext)

  useEffect(() => {
    let totalFinalAux = total
    if (impuestos.iva?.id) {
      totalFinalAux =
        totalFinalAux + (totalFinalAux * impuestos.iva.value) / 100
    }
    if (impuestos.recargo?.id) {
      totalFinalAux =
        totalFinalAux + (totalFinalAux * impuestos.recargo.value) / 100
    }
    if (impuestos.retencion?.id) {
      totalFinalAux =
        totalFinalAux - (totalFinalAux * impuestos.retencion.value) / 100
    }
    setTotalFinal(totalFinalAux)
  }, [impuestos])
  console.log("impuestos", impuestos)

  return (
    <>
      <>
        <div
          id="centerPaco"
          style={{
            marginLeft: "50px",
            marginRight: "50px",
            textAlign: "center",
            marginTop: "20px",
            display: "grid",
          }}
        >
          <PoppinsNormal style={{ fontSize: "24px", fontWeight: "600" }}>
            {i18n.t("Últimos detalles")}
          </PoppinsNormal>
          <HelveticaLight
            style={{
              fontSize: "14px",
              fontWeight: "300",
              marginTop: "-15px",
              marginBottom: "0px",
            }}
          >
            {i18n.t("Define los impuestos y retenciones del presupuesto.")}
          </HelveticaLight>
        </div>

        <div
          style={{
            marginTop: "40px",
            paddingLeft: "60px",
            paddingRight: "60px",
            position: "relative",
          }}
        >
          <HelveticaBold
            style={{
              color: "#414141",
              fontSize: "14px",
              marginLeft: "0px",
              marginBottom: "10px",
            }}
          >
            {projectName}
          </HelveticaBold>
          <div
            className="inline"
            style={{
              position: "relative",
              width: "100%",
              marginBottom: "-5px",
            }}
          >
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "14px",
                fontWeight: "400",
                marginLeft: "0px",
              }}
            >
              {i18n.t("Base Imponible")}
            </HelveticaBold>
            <HelveticaBold
              style={{
                color: "#414141",
                position: "absolute",
                right: "0px",
                fontSize: "14px",
                fontWeight: "400",
                marginLeft: "0px",
              }}
            >
              {currencyFormatToConcept(total)}{" "}
              {getCurrency(user["custom:currency"])}
            </HelveticaBold>
          </div>
          {impuestos.iva?.id && (
            <div
              className="inline"
              style={{
                position: "relative",
                width: "100%",
                marginBottom: "-5px",
              }}
            >
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginLeft: "0px",
                }}
              >
                {i18n.t("Impuestos")}
              </HelveticaBold>
              <HelveticaBold
                style={{
                  color: "#414141",
                  position: "absolute",
                  right: "0px",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginLeft: "0px",
                }}
              >
                {currencyFormatToConcept((total * impuestos.iva.value) / 100)}{" "}
                {getCurrency(user["custom:currency"])}
              </HelveticaBold>
            </div>
          )}
          {impuestos.recargo?.id && (
            <div
              className="inline"
              style={{
                position: "relative",
                width: "100%",
                marginBottom: "-5px",
              }}
            >
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginLeft: "0px",
                }}
              >
                {i18n.t("Recargos")}
              </HelveticaBold>
              <HelveticaBold
                style={{
                  color: "#414141",
                  position: "absolute",
                  right: "0px",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginLeft: "0px",
                }}
              >
                {currencyFormatToConcept(
                  (total * impuestos.recargo.value) / 100,
                )}{" "}
                {getCurrency(user["custom:currency"])}
              </HelveticaBold>
            </div>
          )}
          {impuestos.retention?.id && (
            <div
              className="inline"
              style={{
                position: "relative",
                width: "100%",
                marginBottom: "-5px",
              }}
            >
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginLeft: "0px",
                }}
              >
                {i18n.t("Retenciones")}
              </HelveticaBold>
              <HelveticaBold
                style={{
                  color: "#414141",
                  position: "absolute",
                  right: "0px",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginLeft: "0px",
                }}
              >
                {currencyFormatToConcept(
                  (total * impuestos.retention.value) / 100,
                )}{" "}
                {getCurrency(user["custom:currency"])}
              </HelveticaBold>
            </div>
          )}
          <FakeInput
            onClick={() => {
              setOpenImpuestos(true)
            }}
            style={{
              width: "100%",
              cursor: "pointer",
              height: "30px",
              border: "1px solid #C4C4C4",
              paddingTop: "4px",
              paddingLeft: "5px",
            }}
          >
            {!impuestos.retention?.id &&
            !impuestos.iva?.id &&
            !impuestos.recargo?.id ? (
              <HelveticaLight>
                {i18n.t("Realiza la selección...")}
              </HelveticaLight>
            ) : (
              <>
                {impuestos.iva?.id && (
                  <div
                    className="hoverYellow"
                    style={{
                      height: "25px",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      marginRight: "7px",
                      marginLeft: "7px",
                      marginTop: "-2.5PX",
                      width: "fit-content",
                      borderRadius: "6px",
                      border: "1px solid #FFD52B",
                    }}
                  >
                    <HelveticaLight
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        marginBottom: "0px",
                      }}
                    >
                      {impuestos.iva.name}
                    </HelveticaLight>
                  </div>
                )}
                {impuestos.recargo?.id && (
                  <div
                    className="hoverYellow"
                    style={{
                      height: "25px",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      marginRight: "7px",
                      marginLeft: "7px",
                      marginTop: "-2.5PX",
                      width: "fit-content",
                      borderRadius: "6px",
                      border: "1px solid #FFD52B",
                    }}
                  >
                    <HelveticaLight
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        marginBottom: "0px",
                      }}
                    >
                      {impuestos.recargo.name}
                    </HelveticaLight>
                  </div>
                )}
                {impuestos.retention?.id && (
                  <div
                    className="hoverYellow"
                    style={{
                      height: "25px",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      marginRight: "7px",
                      marginLeft: "7px",
                      marginTop: "-2.5PX",
                      width: "fit-content",
                      borderRadius: "6px",
                      border: "1px solid #FFD52B",
                    }}
                  >
                    <HelveticaLight
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        marginBottom: "0px",
                      }}
                    >
                      {impuestos.retention.name}
                    </HelveticaLight>
                  </div>
                )}
              </>
            )}
          </FakeInput>

          <div
            className="greySeparator"
            style={{ width: "100%", marginTop: "20px" }}
          />
          <div
            className="inline"
            style={{
              position: "relative",
              width: "100%",
              marginBottom: "-5px",
            }}
          >
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "14px",
                fontWeight: "700",
                marginLeft: "0px",
              }}
            >
              {i18n.t("Total presupuesto")}
            </HelveticaBold>
            <HelveticaBold
              style={{
                color: "#414141",
                position: "absolute",
                right: "0px",
                fontSize: "14px",
                fontWeight: "700",
                marginLeft: "0px",
              }}
            >
              {currencyFormatToConcept(totalFinal)}{" "}
              {getCurrency(user["custom:currency"])}
            </HelveticaBold>
          </div>
        </div>

        <div
          id="centerPaco"
          style={{ textAlign: "center", marginTop: "40px", display: "grid" }}
        >
          {invoiceService.batchAction === false ? (
            <YellowButton
              onClick={() => {
                createFacturas()
              }}
              style={{ marginTop: "10px", marginBottom: "25px" }}
            >
              {i18n.t("Definir")}
            </YellowButton>
          ) : (
            <YellowButton
              disabled
              style={{
                marginTop: "10px",
                marginBottom: "25px",
                backgroundColor: "#E4E4E4",
                cursor: "default",
              }}
            >
              <CircularProgress
                size={24}
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  marginBottom: "25px",
                }}
              />
            </YellowButton>
          )}
        </div>
      </>
      {openImpuestos && (
        <ModalSelectTaxInModal
          open={openImpuestos}
          setOpen={setOpenImpuestos}
          impuestos={impuestos}
          setImpuestos={setImpuestos}
        />
      )}
    </>
  )
}

DefinirImpuestosComponent.propTypes = {
  createFacturas: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  impuestos: PropTypes.object.isRequired,
  setImpuestos: PropTypes.func.isRequired,
  invoiceService: PropTypes.any,
  projectName: PropTypes.string.isRequired,
}

export default DefinirImpuestosComponent
