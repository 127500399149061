import React, { useReducer } from "react"
import FileContext from "./fileContext"
import { API, Auth } from "aws-amplify"
import PropTypes from "prop-types"
import {
  GET_URL_POST_SUCCESS,
  UPLOAD_FILE_SUCCESS,
  GET_MY_FILES_SUCCESS,
  UPLOADING_FILE,
} from "../types"
import axios from "axios"
import FileReducer from "./fileReducer"
const FileState = props => {
  const initialState = {
    files: [],
    fotos: [],
    uploadProcess: {
      isUploading: false,
      progress: 0,
      fileInfo: null,
      urlPost: null,
      uploaded: false,
    },
    loadingFile: false,
    loading: false,
    filteredFilesByProject: {},
  }
  const APINAME = "dev-PLANHOPPER-API"

  const [state, dispatch] = useReducer(FileReducer, initialState)
  const getPostUrl = async (data, moreInfo) => {
    try {
      dispatch({
        type: UPLOADING_FILE,
      })

      let payload = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          filename: data.name,
          extension: data.name.split(".").slice(-1)[0],
          size: data.size,
          projectId: data.projectId,
          contentType: data.type,
          ...moreInfo,
        },
      }
      const res = await API.post(APINAME, "/api/file/upload", payload)
      dispatch({
        type: GET_URL_POST_SUCCESS,
        payload: res.file,
      })
      uploadFile({ file: data, presignedPostData: res.uploadInfo })
      getMyFiles()
      return res
    } catch (error) {
      console.log(error)
    }
  }
  const deleteFile = async id => {
    try {
      let payload = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: {
          fileId: id,
        },
      }
      const res = await API.del(APINAME, "/api/file/delete", payload)
      getMyFiles()
    } catch (error) {
      console.log(error)
    }
  }
  const uploadFile = async ({ file, presignedPostData }) => {
    try {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key])
      })
      formData.append("file", file)
      const res = await axios.post(presignedPostData.url, formData)
      if (res.status === 204) {
        dispatch({
          type: UPLOAD_FILE_SUCCESS,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getMyFiles = async () => {
    try {
      dispatch({
        type: "GET_FILES",
      })
      let payload = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
      const res = await API.get(APINAME, "/api/file/myfiles", payload)
      let auxProjects = {}
      for (let i = 0; i < res.length; i++) {
        if (res[i].projectId) {
          if (res[i].projectId in auxProjects) {
            auxProjects[res[i].projectId].push(res[i])
          } else {
            auxProjects = { ...auxProjects, [res[i].projectId]: [res[i]] }
          }
        } else {
          if ("sin_clasificar" in auxProjects) {
            auxProjects["sin_clasificar"].push(res[i])
          } else {
            auxProjects = { ...auxProjects, sin_clasificar: [res[i]] }
          }
        }
      }
      dispatch({
        type: GET_MY_FILES_SUCCESS,
        payload: {
          archivos: res,
          pictures: [],
          filteredFilesByProject: auxProjects,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  const updateFile = async file => {
    try {
      const payload = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
        body: file,
      }
      const res = await API.put(APINAME, "/api/file/update", payload)
      await getMyFiles()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <FileContext.Provider
      value={{
        files: state.files,
        fotos: state.fotos,
        loadingFile: state.loadingFile,
        uploadProcess: state.uploadProcess,
        getPostUrl,
        getMyFiles,
        updateFile,
        deleteFile,
        updateFile,
        loading: state.loading,
        filteredFilesByProject: state.filteredFilesByProject,
      }}
    >
      {props.children}
    </FileContext.Provider>
  )
}

FileState.propTypes = {
  children: PropTypes.any,
}

export default FileState
