import { isNil, isNaN } from "lodash"

export const getMargin = partida => {
  if (partida.hasOwnProperty("margin") && !isNil(partida.margin)) {
    return parseFloat(partida.margin)
  } else {
    return (
      parseFloat(partida.costeGeneral) +
      parseFloat(partida.costeIndirecto) +
      parseFloat(partida.industrialProfit)
    )
  }
}

export const calcMarginFactor = partida => {
  return 1 - getMargin(partida) / 100
}

export const calcMarginBetween = (cost, price) => {
  //console.log(`calcMarginBetween: cost => ${cost}, price => ${price}`)
  if (cost === price) {
    return 0
  }
  var margin = 100 * (1 - cost / price)
  //console.log(`calcMarginBetween: margin => ${margin}`)
  return margin
}

export const marginCalc = partida => {
  //console.log(`marginCalc: price => ${partida.price}, clientPrice => ${partida.clientPrice}`)
  if (
    !isNaN(partida.price) &&
    !isNaN(partida.clientPrice) &&
    partida.clientPrice !== 0
  ) {
    return calcMarginBetween(partida.price, partida.clientPrice)
  } else {
    return 0
  }
}

export const calcCostTotal = partida => {
  var costTotal = parseFloat(partida.price) * parseFloat(partida.quantity)
  //console.log(`calcCostTotal: costTotal => ${costTotal}`)
  return costTotal
}

export const calcCostRealTotal = partida => {
  var costRealTotal =
    parseFloat(
      partida.hasOwnProperty("costReal") ? partida.costReal : partida.price,
    ) * parseFloat(partida.quantity)
  //console.log(`calcCostRealTotal: costRealTotal => ${costRealTotal}`)
  return costRealTotal
}

export const calcApplyDiscount = (price, discount) => {
  return price * (1 - discount / 100)
}

export const calcClientPriceWithoutDiscount = partida => {
  if (isNaN(partida.price) || partida.price === 0) {
    return 0
  } else {
    const margin = calcMarginFactor(partida)
    return margin
      ? parseFloat(partida.price) / calcMarginFactor(partida)
      : partida.price
  }
}

export const calcClientPrice = partida => {
  if (isNil(partida.discount) || partida.discount === 0) {
    return calcClientPriceWithoutDiscount(partida)
  } else {
    return (
      calcClientPriceWithoutDiscount(partida) *
      (1 - parseFloat(partida.discount) / 100)
    )
  }
}

export const calcPriceTotal = partida => {
  var priceTotal = partida.clientPrice * partida.quantity
  //console.log(`calcPriceTotal: priceTotal => ${priceTotal}`)
  return priceTotal
}

export const costRealCalc = partida => {
  //console.log(`partida: ${JSON.stringify(partida)}`)
  return parseFloat(partida.costReal) || parseFloat(partida.price)
}

export const costRealTotalCalc = partida => {
  //console.log('costRealTotalCalc:', costRealCalc(partida), partida.quantity)
  return costRealCalc(partida) * parseFloat(partida.quantity)
}

export const marginRealCalc = partida => {
  return (
    calcPriceTotal(partida) - calcCostRealTotal(partida) ||
    calcPriceTotal(partida) - calcCostTotal(partida)
  )
}

export const marginRealPercCalc = partida => {
  return partida.price || partida.costReal
    ? calcMarginBetween(partida.price || partida.costReal, partida.clientPrice)
    : 0
}

export const underObjective = partida => {
  return partida.costReal && partida.costReal < partida.price
}

export const overObjective = partida => {
  return partida.costReal && partida.costReal > partida.price
}

export const overPrice = partida => {
  return partida.costReal && partida.costReal > partida.clientPrice
}

export const calcPartida = (partida, updatedField, updatedValue) => {
  console.log(
    `calcPartida: updatedField => ${updatedField}, updatedValue => ${updatedValue}`,
  )

  partida[updatedField] = updatedValue

  switch (updatedField) {
    case "quantity":
    case "price":
    case "costReal":
    case "discount":
    case "margin":
      partida.clientPriceWithoutDiscount =
        calcClientPriceWithoutDiscount(partida)
      partida.clientPrice = calcClientPrice(partida)
    case "clientPrice":
    case "clientPriceWithoutDiscount":
      partida.margin = marginCalc(partida)
    //partida.marginReal = marginRealCalc(partida)
    //partida.marginRealPerc = marginRealPercCalc(partida)
    default:
      break
  }

  return partida
}
