import PrincipalView from "components/PdfTest/principalView"
import React, { useContext, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import PdfConfig from "components/PdfTest/pdfConfig"
import AuthContext from "context/auth/authContext"

const NewPlantillaPdf = () => {
  const { getOrganizationNew, organizationFields, user } =
    useContext(AuthContext)

  useEffect(() => {
    if (user) {
      getOrganizationNew(user["custom:organizationId"])
    }
  }, [user])

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ paddingBottom: "0px", paddingTop: "60px" }}
      >
        <MetaTags>
          <title>Planhopper - Plantilla</title>
        </MetaTags>
        <Container fluid style={{ overflowY: "hidden" }}>
          {
            //<PrincipalView/>
            organizationFields && <PdfConfig />
          }
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewPlantillaPdf
