import React from "react"
import PropTypes from "prop-types"
import i18n from "i18n"

const CertHistoryRowContent = ({ data }) => {
  return (
    <div className="mt-2 mb-2 backgroundWhite">
      <table className="tablaPartidas">
        <thead>
          <tr className="tableBorder">
            <th colSpan="7" className="tableBorderRight"></th>
            <th colSpan="2" className="tableBorderRight">
              {i18n.t("certHistory.clientPrice")}
            </th>
            <th colSpan="6">{i18n.t("certHistory.cert")}</th>
          </tr>
          <tr className="tableBorder">
            <th colSpan="1" className="tableBorderRight2 w-4-percent">
              {i18n.t("certHistory.ref")}
            </th>
            <th colSpan="4" className="tableBorderRight2 w-38-percent">
              {i18n.t("certHistory.description")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.measures")}
            </th>
            <th colSpan="1" className="tableBorderRight w-4-percent">
              {i18n.t("certHistory.unit")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.pricePerUnit")}
            </th>
            <th colSpan="1" className="tableBorderRight w-6-percent">
              {i18n.t("certHistory.clientTotalPrice")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.unitDelta")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.percentageDelta")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.priceDelta")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.totalPercentage")}
            </th>
            <th colSpan="1" className="tableBorderRight2 w-6-percent">
              {i18n.t("certHistory.totalUnit")}
            </th>
            <th colSpan="1" className="w-6-percent">
              {i18n.t("certHistory.totalPrice")}
            </th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0
            ? data.map((part, index) => {
                return (
                  <tr key={part.partidaId}>
                    <td>{index + 1}</td>
                    <td
                      colSpan="4"
                      style={{ textAlign: "left", paddingLeft: "10px" }}
                    >
                      {part.name}
                    </td>
                    <td>{part.quantity}</td>
                    <td>{part.unity}</td>
                    <td>{part.clientPrice.toFixed(2)} €</td>
                    <td>{part.clientTotalPrice.toFixed(2)} €</td>
                    <td>
                      {part.unitDelta.toFixed(2)} {part.unity}
                    </td>
                    <td>{part.percentageDelta.toFixed(2)} %</td>
                    <td>{part.priceDelta.toFixed(2)} €</td>
                    <td>{part.totalPercentage.toFixed(2)} %</td>
                    <td>
                      {part.totalUnit.toFixed(2)} {part.unity}
                    </td>
                    <td>{part.totalPrice.toFixed(2)} €</td>
                  </tr>
                )
              })
            : null}
        </tbody>
      </table>
    </div>
  )
}

CertHistoryRowContent.propTypes = { data: PropTypes.arrayOf(PropTypes.any) }

export default CertHistoryRowContent
