import LayoutTemplate from "components/TemplatesManagementComponent/layoutTemplate"
import AuthContext from "context/auth/authContext"
import React, { useContext, useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Container } from "reactstrap"

const TemplateManagement = () => {
  const { user, getUser, getActualUser, internalUser } = useContext(AuthContext)
  const [onlyOneTime, setOnlyOneTime] = useState(false)
  useEffect(() => {
    if (user) {
      if (!onlyOneTime) {
        getActualUser()
        setOnlyOneTime(true)
      }
    } else {
      getUser()
    }
  }, [user])

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ paddingBottom: "0px", paddingRight: "0px" }}
      >
        <MetaTags>
          <title>Planhopper | Plantillas</title>
        </MetaTags>
        <Container fluid style={{ paddingBottom: "0px", paddingRight: "0px" }}>
          <LayoutTemplate />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TemplateManagement
