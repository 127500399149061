import React, { Fragment, useContext, useState } from "react"
import i18n from "i18n"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import PropTypes from "prop-types"
import DropdownSettings from "components/Project/Overview/DropdownSettings"
import StarIcon from "@mui/icons-material/Star"
import { useHistory } from "react-router-dom"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext"
import ProjectContext from "context/projects/projectContext"

const COLUMNS = [
  {
    id: "avatar",
    label: "",
    show: true,
    type: "avatar",
  },
  {
    id: "name",
    label: i18n.t("Nombre"),
    show: true,
    type: "name",
  },
  {
    id: "orgAddress",
    label: i18n.t("Dirección"),
    show: true,
    type: "string",
  },
  {
    id: "client",
    label: i18n.t("Cliente"),
    show: true,
    type: "client",
  },
  {
    id: "date",
    label: i18n.t("Fecha"),
    show: true,
    type: "date",
  },
]

const ProjectTable = ({ projects, contactos, palabrasFiltered }) => {
  const { user } = useContext(AuthContext)
  const [columns, setColumns] = useState(COLUMNS)
  const { sortProjectsBy } = useContext(ProjectContext)
  const history = useHistory()

  function encontrarCliente(id) {
    let trobat = false
    let i = 0
    while (trobat == false && i < contactos.length) {
      if (contactos[i].id == id) {
        trobat = true
        return contactos[i].completeName
      } else {
        i = i + 1
      }
    }
  }
  function avatarCell(project) {
    return (
      <>
        {project.favourite ? (
          <div id="Estrella" style={{ marginTop: "20px", marginLeft: "20px" }}>
            <StarIcon
              sx={{ color: "#FFD52B", fontSize: 20, zIndex: "9" }}
            ></StarIcon>
          </div>
        ) : null}
        <img
          className="avatar-title rounded-circle bg-light text-danger font-size-16"
          style={{
            width: "40px",
            height: "40px",
            marginRight: "10px",
            backgroundColor: "#FACBCB",
            color: "#D78D8D",
          }}
          src={project.projectImage}
        />
      </>
    )
  }

  const resolveValue = (project, column) => {
    switch (column.type) {
      case "avatar":
        return avatarCell(project)
      case "date":
        try {
          const dt = new Date(
            project.createdAt.substring(0, 19),
          ).toLocaleDateString()
          if (dt === "Invalid Date") return "-"
          return dt
        } catch (e) {
          console.log(e, "eeeee")
          return "-"
        }

      case "name":
        try {
          return project[column.id]
        } catch (e) {
          return "0.00" + getCurrency(user["custom:currency"])
        }

      case "client":
        try {
          return encontrarCliente(project[column.id])
        } catch (e) {
          return "-"
        }
      case "string":
        try {
          console.log(column.id, "column.id")

          return project[column.id]
        } catch (e) {
          return "-"
        }
    }
  }

  return (
    <Fragment>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: "95vw",
          overflow: "hidden",
          height: "auto",
        }}
        style={{ overflow: "inherit" }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              onClick={e => {
                e.stopPropagation()
              }}
              id="rowHover"
              style={{ height: "49px" }}
            >
              {columns.map(
                (column, index) =>
                  column.show && (
                    <TableCell
                      onClick={e => {
                        e.stopPropagation()
                        sortProjectsBy(column.label, encontrarCliente)
                      }}
                      style={{
                        width: column.type == "avatar" && "80px",
                        cursor: "pointer",
                      }}
                      key={index}
                      id="helveticaTitle"
                    >
                      {column.label}
                    </TableCell>
                  ),
              )}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map(
              (project, i) =>
                (project.name
                  .toLowerCase()
                  .includes(palabrasFiltered.toLowerCase()) ||
                  project.orgAddress
                    .toLowerCase()
                    .includes(palabrasFiltered.toLowerCase())) && (
                  <>
                    <div
                      id="rowHoverSpecial"
                      onClick={e => {
                        history.push(`/budgeting/${project.projectId}`)
                      }}
                      style={{
                        position: "absolute",
                        height: "49px",
                        marginLeft: "-20px",
                        width: "90%",
                        cursor: "pointer",
                      }}
                    ></div>
                    <TableRow
                      id="rowHover"
                      key={project}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                      style={{ opacity: "1" }}
                    >
                      {columns.map((column, index) => {
                        return (
                          column.show && (
                            <TableCell
                              style={{
                                borderBottom: "0px",
                                width: column.type == "avatar" && "80px",
                              }}
                              key={index}
                              id="bigCellWithOverflow"
                              onClick={() => {}}
                            >
                              {resolveValue(project, column)}
                            </TableCell>
                          )
                        )
                      })}

                      <TableCell
                        id="bigCellWithOverflow"
                        style={{ overflow: "inherit", borderBottom: "none" }}
                      >
                        <div
                          style={{ position: "absolute", marginTop: "-10PX" }}
                        >
                          <DropdownSettings
                            imCard={true}
                            project={project}
                            imList={true}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  )
}
ProjectTable.propTypes = {
  projects: PropTypes.any,
  contactos: PropTypes.any,
  palabrasFiltered: PropTypes.string,
}
export default ProjectTable
