import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { banks } from "./banks.js"

const SideBarContent = ({
  bankSelected,
  setBankSelected,
  setReferencia,
  setTipo,
  bancoDePrecios,
}) => {
  useEffect(() => {
    if (bankSelected == "Cype database") {
      setReferencia("cype")
      setTipo("Rehabilitación")
    } else if (bankSelected == "Bedec database") {
      setReferencia("bedec")
      setTipo("PARTIDAS DE OBRA Y CONJUNTOS")
    } else if (bankSelected == "Preoc database") {
      setReferencia("preoc")
      setTipo("PRECIOS UNITARIOS")
    } else if (bankSelected == "User database") {
      setReferencia("")
      setTipo("")
    }
  }, [bankSelected])

  return (
    <div
      style={{
        minHeight: "551px",
        height: "100%",
        width: "95px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "36px",
      }}
    >
      {banks.map(
        (bank, index) =>
          bank.type !== "Tribboo database" && (
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "65px",
                backgroundColor:
                  bankSelected == bank.type ? "#FFD52B" : "white",
              }}
              key={index}
            >
              {bankSelected == bank.type ? (
                <img src={bank.logoEnabled} />
              ) : (
                <img
                  src={bank.logo}
                  style={{
                    cursor: "pointer",
                    filter: bank.filter && bank.filter,
                  }}
                  onClick={() => {
                    setBankSelected(bank.type)
                  }}
                />
              )}
            </div>
          ),
      )}
    </div>
  )
}

SideBarContent.propTypes = {
  bankSelected: PropTypes.string.isRequired,
  setBankSelected: PropTypes.func.isRequired,
  setReferencia: PropTypes.func.isRequired,
  setTipo: PropTypes.func.isRequired,
  bancoDePrecios: PropTypes.string,
}

export default SideBarContent
