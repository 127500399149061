import styled from "styled-components"

export const ChapterContainer = styled.div`
  width: 100% !important;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  height: 44px;
  padding-top: 0px;
  margin-bottom: 16px;
  margin-top: 10px;
  background-color: #ccd8fc;
  border-radius: 6px;
  min-width: 1020px;
  z-index: 4;
  position: relative;
`

export const ChapterContainerInside = styled.div`
  width: 98% !important;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  height: 44px;
  border-radius: 6px;
  padding-top: 0px;
  margin-bottom: 16px;
  margin-top: 10px;
  background-color: #ccd8fc;
`

export const CapituloContainer = styled.div`
  width: 99%;
  
  border-radius: 6px;
  height: 44px;
  padding-top: 0px;
  margin-bottom: 12px;
  margin-top: 0px;
  background-color: white;
  b
`
export const SubChapterContainer = styled.div`
   {
    width: 98.8% !important;
    
    border-radius: 6px;
    height: 44px;
    padding-top: 0px;
    margin-bottom: 9px;
    margin-top: 8px;
    background-color: #F7DDC8;
    border-radius: 6px;
    min-width: 920px;
    b
  }
`

export const ShowChildDivWithBorder = styled.div`
   {
    margin-left: 0px;
    width: 98.8%;
    background-color: white;
    border-width: 1px 1px 1px 6px;
    border-style: solid;
    border-color: rgb(245, 245, 245) rgb(245, 245, 245) rgb(245, 245, 245)
      #ecae7d;
    border-image: initial;

    display: block;
    margin-bottom: 8px;
    margin-top: -10px;
  }
`

export const ShowChildDiv = styled.div`
   {
    margin-left: 0px;
    width: 98.8%;
    background-color: rgba(252, 168, 100, 0.21);
    padding-top: 10px;

    display: block;
    margin-bottom: 8px;
    padding-bottom: 8px;
    margin-top: -8px;
    padding-left: 8px;
  }
`

export const ShowParentDiv = styled.div`
   {
    width: 100%;
    background-color: white;

    padding-top: 20px;
    padding-bottom: 12px;
    padding-left: 24px;
    display: block;
    margin-left: 0px;
    margin-bottom: 8px;
    margin-top: -19.5px;
    min-width: 1020px;
  }
`

export const CardContainer = styled.div`
  width: 189px;
  height: 189px;
  background: #ffffff;

  border-radius: 6px;
  justify-content: center;
  text-align: center;
  padding-top: 17px;
`

export const LittleTag = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  color: #414141;
  width: 66.66px;
  height: 18.33px;
  background: #ffd52b;
  border-radius: 4px;
  text-align: center;
  padding-top: 2.5px;
`

export const Titulos = styled.p`
  margin-bottom: 0;
  font-family: "Helvetica";
  font-size: 14px;
  font-weight: 500;
  color: #414141;
`

export const PlanningChapters = styled.div`
  border-top: 1px solid #ccd8fc;
  border-right: 1px solid #ccd8fc;
  height: 100vh;
  margin-left: -15px;
  background-color: white;
`

export const PlanningTitles = styled.div`
  width: 100%;
  background-color: white;
  position: relative;
  display: flex;
  border-bottom: 1px solid #ccd8fc;
  heigth: 52px;
  padding-top: 27.5px;
  p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #b7b7b7;
    margin-bottom: 2px;
    padding-top: 8px;
  }
  p:first-child {
    margin-left: 30px;
  }
`

export const PlanningChapterDiv = styled.div`
  width:97%;
  
  height: 25px;
  border: 1px solid #CCD8FC;
  filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.08));
  border-radius: 6px;
  position:relative;
  display:flex;
  padding-left:0px;
  padding-right16px;
  margin-top: 0px;
  margin-left: 4px;

  p{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #414141;
  }
`

export const PlanningTaskDiv = styled.div`
  width:97%;
  
  height: 25px;
  border-bottom: 1px solid #CCD8FC;
  position:relative;
  display:flex;
  padding-left:0px;
  padding-right16px;
  margin-top: 0px;
  margin-left: 4px;

  p{
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    color: #414141;
    
  }
`

export const BlueButton = styled.div`
  background-color: #4461d7;
  border-radius: 6px;
  cursor: pointer;
  width: 140px;
  height: 24px;
  padding-top: 2px;

  :hover {
    background-color: #7398ff !important;
  }
  :active {
    background-color: #5984fc !important;
  }
`

export const SquareContent = styled.div`
  width: 552px;
  height: 483px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`

export const CapituloMini = styled.div`
  width: 100%;
  height: 36px;
  background: #fdfdfd;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  margin-left: 0px;
  margin-right: 20px;
  display: flex;
  position: relative;
  cursor: pointer;
  margin-top: 9px;
  p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    color: #414141;
    margin-left: 20px;
    margin-top: 8px;
  }

  :hover {
    background: #ffd52b33 !important;
    .checkboxChapter {
      display: block !important;
    }
  }
`

export const CardPricing = styled.div`
  width: 300px;
  height: 100%;
  background-color: #ffffff;

  border-radius: 4px;
`

export const EmptyButton = styled.div`
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  width: 135px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
`

export const WhiteButton = styled.div`
  background-color: white;
  border-radius: 6px;
  width: 200px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  :hover {
    background-color: #ffd52b !important;
  }
`

export const PagoSeguro = styled.div`
  background-color: white;
  border: 2px solid #008653;
  border-radius: 6px;
  width: 119px;
  height: 29px;
  padding-top: 2px;
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 14px;
  color: #008653;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
`

export const FakeInput = styled.div`
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: left;
  cursor: pointer;
`

export const BlackHoverButton4CreateDynamically = styled.div`
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: left;
  text-align: left;
  padding-left: 8px;
  position: relative;
  width: 130px;
  height: 20px;
  border-radius: 6px;

  :hover svg {
    color: white !important;
  }

  :hover p {
    color: white !important;
  }

  :hover {
    background-color: #414141 !important;
  }
`

export const PruebaBoton1 = styled.div`
  background-color: #4461d7;
  border-radius: 6px;
  cursor: pointer;
  width: 140px;
  height: 24px;
  padding-top: 2px;
  transition: transform .2s;
  :hover {
    background-color: #7398FF !important;
    transform: scale(1.2);
  }
  :active {
    background-color: #5984FC !important;
  }
	}

`

export const PruebaBoton2 = styled.div`
  background-color: #4461d7;
  border-radius: 6px;
  cursor: pointer;
  width: 140px;
  height: 24px;
  padding-top: 2px;
  transition: transform .2s; /* Animation */
  :hover {
    background-color: #7398FF !important;
    box-shadow: 0px 0px 16px rgba(0, 0, 0,0.25);
  }
  :active {
    background-color: #5984FC !important;
  }
	}

`

export const PruebaBoton3 = styled.div`
  background-color: #7398FF;
  border-radius: 6px;
  cursor: pointer;
  width: 140px;
  height: 24px;
  padding-top: 2px;
  box-shadow: 0 0 20px 20px #4461d7 inset, 0 0 0 0 #4461d7;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  :hover {
    box-shadow: 0 0 10px 0px #4461d7 inset, 0 0 10px 4px #4461d7;
  }
  :active {
    background-color: #5984FC !important;
  }
	}

`

export const PruebaBoton4 = styled.div`
  background-color: #4461d7;
  border-radius: 6px;
  cursor: pointer;
  width: 140px;
  height: 24px;
  padding-top: 2px;

    -webkit-transition: box-shadow 600ms ease-in-out, color 600ms ease-in-out;
    transition: box-shadow 600ms ease-in-out, color 600ms ease-in-out;
  :hover {
    box-shadow: 0 0 40px 40px #7398FF inset;
  }
  :active {
    background-color: #5984FC !important;
  }
	}

`

export const PruebaBoton5 = styled.div`
  background-color: #4461d7;
  border-radius: 6px;
  cursor: pointer;
  width: 140px;
  height: 24px;
  padding-top: 2px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 0 rgba(0, 0, 0, 0.2);
  transition: all .5s ease;
  :hover {
    background-color: #7398FF !important;
  }
  :active {
    background-color: #5984FC !important;
  }
	}
 
   
`

export const InlineEmptyButton = styled.div`
  background-color: white;

  border-radius: 4px;
  cursor: pointer;
  width: 120px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  border: 1px solid #6b728080;
`
