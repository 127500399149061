import React, { useState, useContext, useEffect } from "react"
import AuthContext from "context/auth/authContext"
import { useHistory, useParams } from "react-router-dom"
import actsContext from "context/acts/actsContext"
import ProjectContext from "context/projects/projectContext"

export const useActas = () => {
  const {
    acts,
    getActs,
    createAct,
    getActa,
    selectedActa,
    updateAct,
    getPdf,
    updatePendingSubject,
    updatedsPendingSubjects,
  } = useContext(actsContext)
  const { projectInfo } = useContext(ProjectContext)
  const { user, getUser } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { projectId } = useParams()

  const { actId } = useParams()
  const [actPdf, setActPdf] = useState(null)
  const [contadorLlamadas, setContadorLlamadas] = useState(0)
  const [loadingPdf, setLoadingPdf] = useState(false)

  useEffect(() => {
    if (actId) {
      getActa(actId)
    }
    if (user) {
      if (acts.length == 0) {
        getActs(projectId, user)
      }
    } else {
      getUser()
    }
  }, [actId, user])

  useEffect(() => {
    if (selectedActa != null) {
      let auxAttendees = []
      let auxSubjects = []
      let auxAttendeesRefs = []
      let auxSubjectsRefs = []
      let auxPendingSubjects = []
      let auxPendingSubjectsRefs = []
      for (let i = 0; i < selectedActa.attendees.length; i++) {
        auxAttendees.push({
          title:
            selectedActa.attendees[i].contact.role.length > 0
              ? selectedActa.attendees[i].contact.role[0]
              : "SIN ROL ASIGNADO",
          name: selectedActa.attendees[i].contact.completeName,
          signature: "",
          contact: {
            ...selectedActa.attendees[i].contact,
            id: selectedActa.attendees[i].contact.contactid,
          },
        })
        auxAttendeesRefs.push({ name: React.createRef() })
      }
      for (let i = 0; i < selectedActa.extras.length; i++) {
        auxSubjects.push({
          code: selectedActa.extras[i].code,
          subject: selectedActa.extras[i].subject,
          status: selectedActa.extras[i].status,
          responsible: selectedActa.extras[i].responsible,
          responsible2: {
            ...selectedActa.extras[i].contact,
            id: selectedActa.extras[i].contact.contactid,
          },
          files: selectedActa.extras[i].files,
        })
        auxSubjectsRefs.push({
          subject: React.createRef(),
          status: React.createRef(),
          responsible: React.createRef(),
        })
      }
      acts.map(acta => {
        acta.extras.map(subject => {
          if (
            subject.status == "Pendiente" &&
            acta.actId != selectedActa.actId
          ) {
            subject.responsible2 = subject.contact
            auxPendingSubjects.push(subject)
            auxPendingSubjectsRefs.push({
              subject: React.createRef(),
              status: React.createRef(),
              responsible: React.createRef(),
            })
          }
        })
      })

      setActaValues({
        themes: selectedActa.themes,
        actId: selectedActa.actId,
        actNum: selectedActa.actNum,
        author: selectedActa.author,
        director: selectedActa.director,
        date: selectedActa.createdAt,
        attendees: auxAttendees,
        subjects: auxSubjects,
        pendingSubjects: auxPendingSubjects,
        comments: [],
      })
      setActaRefs({
        attendeesRefs: auxAttendeesRefs,
        subjectsRefs: auxSubjectsRefs,
        pendingSubjectsRefs: auxPendingSubjectsRefs,
      })
    } else {
      let auxPendingSubjects = []
      let auxPendingSubjectsRefs = []
      acts.map(acta => {
        acta.extras.map(subject => {
          if (subject.status == "Pendiente") {
            subject.actInfo = acta
            subject.responsible2 = subject.contact
            auxPendingSubjects.push(subject)
            auxPendingSubjectsRefs.push({
              subject: React.createRef(),
              status: React.createRef(),
              responsible: React.createRef(),
            })
          }
        })
      })
      setActaValues({
        ...actaValues,
        author: user
          ? user["custom:nombre_apellido"]
            ? user["custom:nombre_apellido"]
            : "-"
          : "-",
        director: user
          ? user["custom:nombre_apellido"]
            ? user["custom:nombre_apellido"]
            : "-"
          : "-",
        pendingSubjects: auxPendingSubjects,
      })
      setActaRefs({
        ...actasRefs,
        pendingSubjectsRefs: auxPendingSubjectsRefs,
      })
    }

    if (user && actId && contadorLlamadas == 0) {
      setLoadingPdf(true)
      getPdf(actId, user.sub).then(res => {
        setActPdf(res)
        setLoadingPdf(false)
      })
      setContadorLlamadas(contadorLlamadas + 1)
    }
  }, [selectedActa, acts])

  const [actaValues, setActaValues] = useState({
    author: user
      ? user["custom:nombre_apellido"]
        ? user["custom:nombre_apellido"]
        : "-"
      : "-",
    director: user
      ? user["custom:nombre_apellido"]
        ? user["custom:nombre_apellido"]
        : "-"
      : "-",
    themes: [],
    attendees: [{ title: "", name: "", signature: "" }],
    subjects: [
      {
        code: "",
        subject: "",
        status: "Pendiente",
        responsible: "",
        files: [],
      },
    ],
    pendingSubjects: [],
    comments: [],
  })

  const [actasRefs, setActaRefs] = useState({
    attendeesRefs: [{ name: React.createRef() }],
    subjectsRefs: [
      {
        subject: React.createRef(),
        status: React.createRef(),
        responsible: React.createRef(),
      },
    ],
    pendingSubjectsRefs: [
      {
        subject: React.createRef(),
        status: React.createRef(),
        responsible: React.createRef(),
      },
    ],
  })

  const actNum = (acts?.length + 1).toString()

  const updateSubject = subject => {
    updatePendingSubject(subject)
  }

  const handleSaveAct = async e => {
    e.preventDefault()
    const valid = validateFields()
    if (valid) {
      setLoading(true)
      updatePendingSubjects()
      await createAct(actaValues, projectInfo, user)
      setLoading(false)
      history.push("/actas/" + projectInfo.projectId)
    }
  }

  const handleEditAct = async e => {
    e.preventDefault()
    const valid = validateFields()
    if (valid) {
      setLoading(true)
      updatePendingSubjects()
      await updateAct(actaValues, projectInfo, user)
      setLoading(false)
      history.push("/actas/" + projectInfo.projectId)
    }
  }

  const updatePendingSubjects = async () => {
    for (let i = 0; i < updatedsPendingSubjects.length; i++) {
      const auxActSubjects = updatedsPendingSubjects[i].actInfo.extras.map(
        obj => ({ ...obj }),
      )

      auxActSubjects.map(sub => {
        if (sub.code === updatedsPendingSubjects[i].code) {
          let auxSub
          auxSub = Object.assign({}, updatedsPendingSubjects[i])
          delete auxSub.actInfo
          sub = auxSub
        }
        sub.contact.id = sub.contact.contactid
        sub.responsible = sub.contact
      })

      auxActSubjects.map(s => {
        delete s.actInfo
      })

      const auxAct = Object.assign({}, updatedsPendingSubjects[i].actInfo, {
        comments: [],
        subjects: auxActSubjects,
      })

      let auxAtt = []

      auxAct.attendees.map(att => {
        att.contact.id = att.contact.contactid
        auxAtt.push(att.contact)
      })

      auxAct.attendees = auxAtt
      await updateAct(auxAct, projectInfo, user)
    }
  }

  const validateFields = () => {
    let valid = true
    actaValues.attendees.forEach((attendee, index) => {
      if (Object.keys(attendee).length == 0) {
        valid = false
        actasRefs.attendeesRefs[index].name.current.style.border =
          "1px solid #BF2929"
      } else {
        actasRefs.attendeesRefs[index].name.current.style.border = "none"
        actasRefs.attendeesRefs[index].name.current.style.borderLeft =
          "1px solid #c4c4c4"
      }
    })
    actaValues.subjects.forEach((subject, index) => {
      if (subject.subject === "") {
        valid = false
        actasRefs.subjectsRefs[index].subject.current.style.border =
          "1px solid #BF2929"
      } else {
        actasRefs.subjectsRefs[index].subject.current.style.border = "none"
        actasRefs.subjectsRefs[index].subject.current.style.borderLeft =
          "1px solic #c4c4c4"
      }
      if (Object.keys(subject.responsible).length == 0) {
        valid = false
        actasRefs.subjectsRefs[index].responsible.current.style.border =
          "1px solid #BF2929"
      } else {
        actasRefs.subjectsRefs[index].responsible.current.style.border = "none"
        actasRefs.subjectsRefs[index].responsible.current.style.borderLeft =
          "1px solid #c4c4c4"
      }
    })
    actaValues.pendingSubjects.forEach((subject, index) => {
      if (subject.subject === "") {
        valid = false
        actasRefs.pendingSubjectsRefs[index].subject.current.style.border =
          "1px solid #BF2929"
      } else {
        actasRefs.pendingSubjectsRefs[index].subject.current.style.border =
          "none"
        actasRefs.pendingSubjectsRefs[index].subject.current.style.borderLeft =
          "1px solic #c4c4c4"
      }
      if (Object.keys(subject.responsible).length == 0) {
        valid = false
        actasRefs.pendingSubjectsRefs[index].responsible.current.style.border =
          "1px solid #BF2929"
      } else {
        actasRefs.pendingSubjectsRefs[index].responsible.current.style.border =
          "none"
        actasRefs.pendingSubjectsRefs[
          index
        ].responsible.current.style.borderLeft = "1px solid #c4c4c4"
      }
    })
    return valid
  }

  return {
    updatedsPendingSubjects,
    updateSubject,
    actaValues,
    setActaValues,
    actNum,
    actasRefs,
    actId,
    actPdf,
    loadingPdf,
    setActaRefs,
    handleSaveAct,
    handleEditAct,
    loading,
  }
}
