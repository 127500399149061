import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import download from "assets/customIcons/download.svg"
import CertHistoryContext from "context/certHistory/CertHistoryContext"
import { Tooltip } from "@mui/material"
import i18n from "i18n"
import { grey } from "@mui/material/colors"

const CertHistoryRowHeader = ({
  title,
  showDownloadIcon,
  totalCertPrice,
  certId,
  showDeleteIcon = false,
}) => {
  const [showIcon, setShowIcon] = useState(false)
  const { setOpen, setSelectedCertificationId } =
    useContext(CertHistoryContext).exportSideBar
  const { setCetToDelete, setOpenConfirmDelete } =
    useContext(CertHistoryContext)

  const handleDeleteClick = e => {
    if (showDeleteIcon) {
      e.stopPropagation()
      setCetToDelete(certId)
      setOpenConfirmDelete(true)
    }
  }

  return (
    <Row xs={12} className="rowHeader" onClick={() => setShowIcon(!showIcon)}>
      <Col xs={1} className="d-flex align-items-center maxWidth35">
        {showIcon ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </Col>
      <Col xs={showDownloadIcon ? 7 : 11} className="d-flex align-items-center">
        {title}
      </Col>
      {showDownloadIcon && (
        <>
          <Col
            xs={1}
            className="col-1 col-lg d-flex justify-content-end gap-4 align-items-center"
          >
            <Tooltip
              title={showDeleteIcon ? "" : i18n.t("certHistory.tooltipMsg")}
              disableHoverListener={showDeleteIcon}
            >
              <span>
                <DeleteOutlineIcon
                  style={{
                    width: "20px",
                    cursor: showDeleteIcon ? "pointer" : "not-allowed",
                    color: showDeleteIcon ? "inherit" : grey[400],
                  }}
                  onClick={handleDeleteClick}
                />
              </span>
            </Tooltip>

            <img
              src={download}
              style={{ maxWidth: "16px", cursor: "pointer" }}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setOpen(true)
                setSelectedCertificationId(certId)
              }}
            />
          </Col>
          <Col xs={3} className="col-1 col-lg d-flex justify-content-end">
            <div
              id="precioTotal"
              style={{
                fontWeight: "700",
                minWidth: "170px",
                backgroundColor: "white",
                color: "#414141",
                border: "1px solid #414141",
                fontFamily: "Helvetica",
                width: "fit-content",
                paddingLeft: "5px",
                paddingRight: "5px",
                height: "30px",
                marginTop: "5px",
              }}
            >
              Total cert. € {totalCertPrice}
            </div>
          </Col>
        </>
      )}
    </Row>
  )
}

CertHistoryRowHeader.propTypes = {
  title: PropTypes.string,
  showDownloadIcon: PropTypes.bool,
  setOpenSideBar: PropTypes.func,
  totalCertPrice: PropTypes.number,
  certId: PropTypes.string,
  showDeleteIcon: PropTypes.bool,
}

export default CertHistoryRowHeader
