import i18n from "i18n"
import React, { useContext, useEffect, useState } from "react"
import { CloseOutlined } from "@mui/icons-material"
import { useHistory } from "react-router-dom"

import AuthContext from "context/auth/authContext"
import SubscriptionContext from "context/subscription/subscriptionContext"
import ModalExpireFreeTrial from "./ModalExpireFreeTrial"
import { HelveticaLight } from "common/typographies"

const TopAdviceNavBar = () => {
  const {
    user,
    closeSnackbar,
    setExpireFreeTrial,
    form,
    getUser,
    freeTrialExpired,
    showSnackbar,
  } = useContext(AuthContext)
  const {
    subscriptionState,
    isTrial,
    noSubscription,
    daysLeft,
    showTopAdviceBar,
    setShowTopAdviceBar,
  } = useContext(SubscriptionContext)

  const history = useHistory()
  const [imInSubscription, setImInSubscription] = useState(false)
  const [imInSurvey, setImInSurvey] = useState(false)

  const daysLeftMessage = () => {
    if (noSubscription) {
      return i18n.t("No tienes suscripcion activa")
    }

    if (isTrial && daysLeft < 30) {
      return (
        i18n.t("Te quedan ") + daysLeft + i18n.t(" dias de prueba gratuita")
      )
    }
  }

  useEffect(() => {
    if (!user || !subscriptionState) {
      return
    }

    if (isTrial || noSubscription) {
      if (user["custom:onboarding"] !== "true") {
        if (form == false) {
          setImInSurvey(true)
        }
      }
    }
  }, [user, subscriptionState])

  useEffect(() => {
    if (
      window.location.pathname == "/subscription" ||
      window.location.pathname == "/subscriptionAnual"
    ) {
      setImInSubscription(true)
    } else {
      setImInSubscription(false)
    }
  }, [window.location.pathname])

  return (
    <div
      style={{
        position: "fixed",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "47px",
        backgroundColor: "#5984FC",
        zIndex: "999",
        paddingTop: "15px",
      }}
    >
      <div
        className="inline"
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <HelveticaLight
          style={{ color: "white", fontWeight: "300", fontSize: "14px" }}
        >
          {daysLeftMessage()}
        </HelveticaLight>
        <HelveticaLight
          onClick={() => {
            history.push("/subscription")
            history.go(0)
          }}
          style={{
            color: "white",
            marginLeft: "5px",
            fontWeight: "600",
            fontSize: "14px",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {" "}
          {i18n.t(" Actualiza tu plan y consigue acceso completo.")}
        </HelveticaLight>
        <div
          style={{
            position: "absolute",
            right: "10px",
            marginTop: "-3px",
            color: "white",
            cursor: "pointer",
          }}
        >
          <CloseOutlined
            onClick={() => {
              setShowTopAdviceBar(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default TopAdviceNavBar
