import React, { useState, useContext } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { MoreHorizOutlined } from "@mui/icons-material"
import bpIcon from "assets/customIcons/bpIcon.svg"
import basuraCustom from "assets/customIcons/basuraCustom.svg"
import descuentoCustom from "assets/customIcons/descuentoCustom.svg"
import ModalEditDiscount from "./ModalEditDiscount"
import ModalExportBankPrice from "components/Budgeting2023/options/ModalExportBankPrice"
import Budget2023Context from "context/budget2023/Budget2023Context"
import ModalDeletePart from "components/Budgeting2023/TablaPartida/ModalDeletePart"

const OptionsPartDropdown = props => {
  const [menu, setMenu] = useState(false)

  const [openDiscount, setOpenDiscount] = useState(false)
  const [openEliminate, setOpenEliminate] = useState(false)
  const [openExportBankPrice, setOpenExportBankPrice] = useState(false)
  const { checkPartida, updatePartsList } = useContext(Budget2023Context)

  const exporFirstStep = async () => {
    await checkPartida(props.partidaInfo.chapterId, props.partidaInfo.partidaId)
    await updatePartsList(props.partidaInfo)
    setOpenExportBankPrice(true)
  }

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{ marginTop: "0px", width: "100%" }}
        >
          <DropdownToggle
            id="settingb"
            style={{
              marginTop: "-10px",
              color: "black",
              margin: "0px",
              padding: "0px",
            }}
          >
            <MoreHorizOutlined id="settingsBud" style={{ color: "#414141" }} />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {!props.bancoDePrecios && (
              <DropdownItem
                tag="b"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenDiscount(true)
                }}
              >
                {" "}
                <img
                  src={descuentoCustom}
                  style={{
                    marginTop: "-2px",
                    width: "32px",
                    marginLeft: "-6px",
                    marginRight: "-4px",
                    paddingRight: "5px",
                  }}
                />
                Añadir descuento
              </DropdownItem>
            )}
            {props.imInUpdate && (
              <>
                <DropdownItem
                  tag="b"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    exporFirstStep()
                  }}
                >
                  {" "}
                  <img
                    src={bpIcon}
                    style={{ marginTop: "-2px", paddingRight: "5px" }}
                  />
                  Guardar en base de datos
                </DropdownItem>
                <DropdownItem
                  tag="b"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenEliminate(true)
                  }}
                >
                  {" "}
                  <img
                    src={basuraCustom}
                    style={{
                      marginTop: "-2px",
                      paddingRight: "5px",
                      marginLeft: "-2px",
                    }}
                  />
                  Eliminar
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>

      {openDiscount && (
        <ModalEditDiscount
          open={openDiscount}
          setOpen={setOpenDiscount}
          partidaInfo={props.partidaInfo}
          setPartidaState={props.setPartidaState}
          partidaState={props.partidaState}
        />
      )}
      {openExportBankPrice && (
        <ModalExportBankPrice
          open={openExportBankPrice}
          setOpen={setOpenExportBankPrice}
        />
      )}
      {openEliminate && (
        <ModalDeletePart
          openEliminate={openEliminate}
          setOpenEliminate={setOpenEliminate}
          partida={props.partidaInfo}
          setOpenModalGeneral={props.setOpenModalGeneral}
        />
      )}
    </>
  )
}

OptionsPartDropdown.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  imInUpdate: PropTypes.any,
  partidaInfo: PropTypes.any,
  partidaState: PropTypes.object,
  setPartidaState: PropTypes.func,
  setOpenModalGeneral: PropTypes.func,
  bancoDePrecios: PropTypes.any,
}

export default withTranslation()(withRouter(OptionsPartDropdown))
