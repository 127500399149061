import React, { useReducer } from "react"
import * as amplitude from "@amplitude/analytics-browser"
import PropTypes from "prop-types"
import CertHistoryContext from "./CertHistoryContext"
import CertHistoryReducer from "./CertHistoryReducer"
import {
  CALCULATE_ALL_TOTAL_CERT_PRICES,
  ON_UPDATE,
  SET_CERTIFICATION_HISTORY_TREE,
  SET_LOADING,
  SET_DOWNLOAD_BUDGET_LOADING,
  SET_OPEN_EXPORT_SIDEBAR,
  SET_SELECTED_CERTIFICATION_ID,
  SET_OPEN_CONFIRM_DELETE_MODAL,
  SET_CERT_TO_DELETE,
  DELETE_CERTIFICATION_BY_ID,
} from "context/types"
import { API, Auth } from "aws-amplify"
import CertificationRoot from "./CertificationRoot"
import ExportSideBar from "./ExportSideBar"
import CertHistoryContextState from "./CertHistoryContextState"

const SERVICE_CERT_CORE = "service-cert-core"
const SERVICE_CORE_BASE = "service-core-base"
const initialState = new CertHistoryContextState()

const CertHistoryState = props => {
  const [state, dispatch] = useReducer(CertHistoryReducer, initialState)

  const getCommonConfig = async () => {
    const generalConfig = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    }
    return generalConfig
  }

  const setCertHistory = async projectId => {
    try {
      dispatch({
        type: ON_UPDATE,
        payload: {},
      })

      dispatch({
        type: SET_LOADING,
        payload: true,
      })

      const configurations = await getCommonConfig()
      const path = `/${projectId}/full`
      const resp = await API.get(SERVICE_CERT_CORE, path, configurations)
      const certHistoryTree = new CertificationRoot(resp)
      dispatch({
        type: SET_CERTIFICATION_HISTORY_TREE,
        payload: {
          data: certHistoryTree,
        },
      })
      dispatch({
        type: CALCULATE_ALL_TOTAL_CERT_PRICES,
        payload: {},
      })
    } catch (error) {
      dispatch({
        type: SET_LOADING,
        payload: false,
      })
    }
  }

  const openExportSideBar = state => {
    dispatch({
      type: SET_OPEN_EXPORT_SIDEBAR,
      payload: state,
    })
  }

  const downloadHistoryCertification = async (
    projectId,
    name,
    certificationId,
  ) => {
    try {
      let payload = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
          "Access-Control-Allow-Origin": "*",
        },
        body: {
          cost_price: false,
          cost_real: false,
          client_price: true,
          decomposition: true,
          measures: true,
          comment: false,
          certifications: true,
          certification_id: certificationId,
          debug: false,
        },
      }

      dispatch({
        type: SET_DOWNLOAD_BUDGET_LOADING,
        payload: true,
      })

      const response = await API.post(
        SERVICE_CORE_BASE,
        "/excel/s3/" + projectId,
        payload,
      )
      const link = document.createElement("a")
      link.href = response.url
      link.download = `${name.replace(" ", "_")}.xlsx`
      document.body.appendChild(link)
      link.click()
      amplitude.track({
        event_type: "Certification History Excel Downloaded",
        event_properties: {
          projectId: projectId,
          projectName: name,
          certificationId: certificationId,
        },
      })

      dispatch({
        type: SET_DOWNLOAD_BUDGET_LOADING,
        payload: false,
      })
    } catch (error) {
      return {
        error: true,
        message: error?.message,
        function: "/project/excel/cert_download/" + projectId,
        service: "service-budget",
      }
    }
  }

  const setSelectedCertificationId = certId => {
    dispatch({
      type: SET_SELECTED_CERTIFICATION_ID,
      payload: {
        certId: certId,
      },
    })
  }

  const setOpenConfirmDelete = open => {
    dispatch({
      type: SET_OPEN_CONFIRM_DELETE_MODAL,
      payload: {
        open: open,
      },
    })
  }

  const setCetToDelete = cert => {
    dispatch({
      type: SET_CERT_TO_DELETE,
      payload: {
        certToDelete: cert,
      },
    })
  }

  const deleteCert = async (projectId, certId) => {
    try {
      const configurations = await getCommonConfig()
      const path = `/${projectId}/${certId}`

      const resp = await API.del(SERVICE_CERT_CORE, path, configurations)

      setCetToDelete(null)
      setOpenConfirmDelete(false)

      if (resp.status === "OK") {
        dispatch({
          type: DELETE_CERTIFICATION_BY_ID,
          payload: {
            certId: certId,
          },
        })
      }
    } catch (error) {
      console.log("🚀 ~ deleteCert ~ error:", error)
    }
  }

  return (
    <CertHistoryContext.Provider
      value={
        new CertHistoryContextState(
          setCertHistory,
          state.certHistoryTree,
          state.loading,
          new ExportSideBar(
            state.exportSideBar.isOpen,
            openExportSideBar,
            downloadHistoryCertification,
            setSelectedCertificationId,
            state.exportSideBar.selectedCertificationId,
            state.exportSideBar.isLoading,
          ),
          state.updating,
          state.openConfirmDelete,
          setOpenConfirmDelete,
          state.certToDelete,
          setCetToDelete,
          deleteCert,
        )
      }
    >
      {props.children}
    </CertHistoryContext.Provider>
  )
}

CertHistoryState.propTypes = {
  children: PropTypes.any.isRequired,
}

export default CertHistoryState
