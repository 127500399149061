import React, { useContext, useRef, useState } from "react"
import authContext from "context/auth/authContext"
import { API, Auth } from "aws-amplify"
import { Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import ContactContext from "context/contact/contactContext"
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp"
import ErrorOutlineSharpIcon from "@mui/icons-material/ErrorOutlineSharp"
import TeamsContext from "context/teams/teamsContext"
import { CircularProgress } from "@mui/material"
import i18n from "i18n"
import ModalImageProject from "pages/Dashboard/ModalImageProject"

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "500px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    height: "775px",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const AddContact = ({
  openAdd,
  setOpenAdd,
  worker,
  setCambiado,
  setSelectedContact,
}) => {
  // Configuración del modal para añadir contactos
  const contactContext = useContext(ContactContext)
  const { Contacts, getContacts } = contactContext
  //useEffect(()=>{getContacts()},[])
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  const onClose = () => {
    setOpenAdd(false)
  }

  const [image, setImage] = useState(
    "https://d1t181n48swd5d.cloudfront.net/imgProject/00_DEFAULT.png",
  )
  const [openImage, setOpenImage] = useState(false)

  // Funciones para leer y guardar contenidos de funcionario
  const [campos, guardarCampos] = useState({
    nombre: "",
    email: "",
    movil: "",
    nif: "",
    empresa: "",
    direccion: "",
  })

  const obtenerCampos = e => {
    guardarCampos({
      ...campos,
      [e.target.name]: e.target.value,
    })
  }

  /*Funciones para las etiquetas*/
  const [input, setInput] = useState("")
  const [tags, setTags] = useState([])
  const [tagsFinal, setTagsFinal] = useState([])
  const [isKeyReleased, setIsKeyReleased] = useState(false)
  const [color, setColor] = useState(0)
  const listaColores = [
    "color1",
    "color2",
    "color3",
    "color4",
    "color5",
    "color6",
    "color7",
    "color8",
  ]
  const { updateWorkerCampos } = useContext(TeamsContext)

  const onChange = e => {
    const { value } = e.target
    setInput(value)
  }

  const onKeyDown = e => {
    const { key } = e
    const trimmedInput = input.trim()

    if (
      key === "Enter" &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault()
      setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
      setTagsFinal(prevState => [...prevState, trimmedInput])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags]
      const poppedTag = tagsCopy.pop()
      e.preventDefault()
      setTags(tagsCopy)
      setInput(poppedTag[0])
    }

    setIsKeyReleased(false)
  }

  const AddTagOnClick = e => {
    const trimmedInput = input.trim()

    if (trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault()
      setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
      setTagsFinal(prevState => [...prevState, trimmedInput])
      setInput("")
      if (color != 7) {
        setColor(color + 1)
      } else {
        setColor(0)
      }
    }

    setIsKeyReleased(false)
  }

  const onKeyUp = () => {
    setIsKeyReleased(true)
  }
  const deleteTag = (e, index) => {
    e.preventDefault()
    setTags(prevState => prevState.filter((tag, i) => i !== index))
    setTagsFinal(prevState => prevState.filter((tag, i) => i !== index))
  }

  const selectTag = e => {
    var etiqueta = e.target.innerHTML
    e.target.className = "hide"
    const trimmedInput = etiqueta.trim()
    e.preventDefault()
    setTags(prevState => [...prevState, [trimmedInput, listaColores[color]]])
    setTagsFinal(prevState => [...prevState, trimmedInput])
    setInput("")
    if (color != 7) {
      setColor(color + 1)
    } else {
      setColor(0)
    }
  }
  const AuthContext = useContext(authContext)
  const { user, getUser } = AuthContext
  //useEffect(()=>{getUser()},[])

  /*Referencias para cambio de estilos en validacion de formulario*/
  const refNombre = useRef(null)
  const refMovil = useRef(null)
  const refEmail = useRef(null)
  const refDni = useRef(null)
  const refEmpresa = useRef(null)
  const refTags = useRef(null)

  /*Referencias para iconos confirm and error*/
  const refNombreConfirm = useRef(null)
  const refMovilConfirm = useRef(null)
  const refEmailConfirm = useRef(null)
  const refDniConfirm = useRef(null)
  const refEmpresaConfirm = useRef(null)

  const refNombreError = useRef(null)
  const refMovilError = useRef(null)
  const refEmailError = useRef(null)
  const refDniError = useRef(null)
  const refEmpresaError = useRef(null)

  let valida = false
  let validaNombre = true
  let validaMail = true

  const handleOnClick = e => {
    if (e.target.name == "nombre") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } else if (e.target.name == "nif") {
      refDni.current["id"] = "focus"
      refDniConfirm.current.id = "confirmNotVisible"
      refDniError.current.id = "errorNotVisible"
    } else if (e.target.name == "empresa") {
      refEmpresa.current["id"] = "focus"
      refEmpresaConfirm.current.id = "confirmNotVisible"
      refEmpresaError.current.id = "errorNotVisible"
    } else if (e.target.name == "movil") {
      refMovil.current["id"] = "focus"
      refMovilConfirm.current.id = "confirmNotVisible"
      refMovilError.current.id = "errorNotVisible"
    } else if (e.target.name == "email") {
      refEmail.current["id"] = "focus"
      refEmailConfirm.current.id = "confirmNotVisible"
      refEmailError.current.id = "errorNotVisible"
    } else if (e.target.name == "etiqueta") {
      refTags.current["id"] = "focus"
    }
  }

  function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const [loading, setLoading] = useState(false)

  async function createContact() {
    if (campos.nombre.length > 0) {
      validaNombre = true
      refNombre.current.id = " "
      refNombreConfirm.current.id = "confirmVisible"
      refNombreError.current.id = "errorNotVisible"
    } else {
      refNombre.current.id = "errorValidation"
      validaNombre = false
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorVisible"
    }

    if (worker) {
      if (worker.fullName?.length > 0) {
        validaNombre = true
        refNombre.current.id = " "
        refNombreConfirm.current.id = "confirmVisible"
        refNombreError.current.id = "errorNotVisible"
      } else {
        refNombre.current.id = "errorValidation"
        validaNombre = false
        refNombreConfirm.current.id = "confirmNotVisible"
        refNombreError.current.id = "errorVisible"
      }
    }

    // if (emailIsValid(campos.email)) {
    //   validaMail = true
    //   refEmail.current.id = " "
    //   refEmailConfirm.current.id = "confirmVisible"
    //   refEmailError.current.id = "errorNotVisible"
    // } else {
    //     refEmail.current.id = "errorValidation"
    //     validaMail = false
    //     refEmailError.current.id = "errorVisible"
    //     refEmailConfirm.current.id = "confirmNotVisible"

    // }

    if (
      validaNombre // && validaMail
    ) {
      setLoading(true)
      try {
        const apiName = "dev-PLANHOPPER-API"
        const path = "/api/contact/create"
        let myInit

        myInit = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST",
            "Access-Control-Allow-Credentials": "true",
          },
          body: {
            //createdAt:datetime.datetime.now(),
            //updatedAt:datetime.datetime.now(),
            username: worker ? worker.fullName : campos.nombre,
            nif: campos.nif,
            orgName: campos.empresa.length > 0 ? campos.empresa : "_",
            mobile: campos.movil,
            email: campos.email,
            role: tagsFinal,
            address: campos.direccion,
          },
        }

        const res = await API.post(apiName, path, myInit)

        if (setSelectedContact) {
          setSelectedContact(res)
        }

        await getContacts()

        if (worker) {
          await updateWorkerCampos(user.sub, worker, campos, tagsFinal)
          setCambiado(true)
        }
        guardarCampos({
          nombre: "",
          email: "",
          movil: "",
          nif: "",
          empresa: "",
          direccion: "",
        })
        setOpenAdd(false)
        setLoading(false)

        //location.reload()
      } catch (error) {
        console.log(error)
      }
    }
  }
  const handleEnter = event => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }
  const [focused, setFocused] = React.useState(false)
  const onFocus = e => {
    setFocused(true)
    if (e.target.name == "nombre") {
      refNombre.current["id"] = "focus"
      refNombreConfirm.current.id = "confirmNotVisible"
      refNombreError.current.id = "errorNotVisible"
    } else if (e.target.name == "nif") {
      refDni.current["id"] = "focus"
      refDniConfirm.current.id = "confirmNotVisible"
      refDniError.current.id = "errorNotVisible"
    } else if (e.target.name == "empresa") {
      refEmpresa.current["id"] = "focus"
      refEmpresaConfirm.current.id = "confirmNotVisible"
      refEmpresaError.current.id = "errorNotVisible"
    } else if (e.target.name == "movil") {
      refMovil.current["id"] = "focus"
      refMovilConfirm.current.id = "confirmNotVisible"
      refMovilError.current.id = "errorNotVisible"
    } else if (e.target.name == "email") {
      refEmail.current["id"] = "focus"
      refEmailConfirm.current.id = "confirmNotVisible"
      refEmailError.current.id = "errorNotVisible"
    } else if (e.target.name == "etiqueta") {
      refTags.current["id"] = "focus"
    }
  }
  const onBlur = e => {
    setFocused(false)
    if (e.target.name == "nombre") {
      if (campos.nombre.length > 0) {
        validaNombre = true
        refNombre.current.id = " "
        refNombreConfirm.current.id = "confirmVisible"
        refNombreError.current.id = "errorNotVisible"
      } else {
        refNombre.current.id = "errorValidation"
        validaNombre = false
        refNombreConfirm.current.id = "confirmNotVisible"
        refNombreError.current.id = "errorVisible"
      }
    } else if (e.target.name == "email") {
      if (emailIsValid(campos.email) || campos.email == "") {
        validaMail = true
        refEmail.current.id = " "
        refEmailConfirm.current.id = "confirmVisible"
        refEmailError.current.id = "errorNotVisible"
      } else {
        refEmail.current.id = "errorValidation"
        validaMail = false
        refEmailError.current.id = "errorVisible"
        refEmailConfirm.current.id = "confirmNotVisible"
      }
    }
  }

  return (
    <>
      <Modal open={openAdd} onClose={onClose}>
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon id="cerrar" onClick={onClose}></CloseIcon>
          <div id="UserImage">
            <img
              id="ProfileImage"
              onClick={() => {
                setOpenImage(true)
              }}
              className="rounded-circle"
              src={image}
              alt="Header Avatar"
              style={{
                cursor: "pointer",
                filter: "drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.08))",
              }}
            />

            <div
              id="foto"
              style={{
                marginLeft: "25%",
              }}
            >
              <EditOutlinedIcon
                onClick={() => {
                  setOpenImage(true)
                }}
                style={{
                  background: " #FFFFFF",
                  borderRadius: "50%",
                }}
              ></EditOutlinedIcon>
            </div>
          </div>
          {worker ? (
            <div id="UserName">{worker.fullName}</div>
          ) : (
            <div id="UserName">{i18n.t("Nombre y Apellido")}</div>
          )}

          <form id="Form">
            {worker ? (
              <>
                <p className="Titulos">{i18n.t("Nombre y Apellido")}</p>
                <div>
                  <input
                    value={worker.fullName}
                    placeholder={i18n.t("Nombre y Apellido")}
                    name="nombre"
                    contentEditable="false"
                  ></input>
                  <CheckCircleOutlineSharpIcon
                    style={{ width: "16px", color: "#3BA452" }}
                    ref={refNombreConfirm}
                    id="confirmNotVisible"
                  ></CheckCircleOutlineSharpIcon>
                  <ErrorOutlineSharpIcon
                    style={{ width: "16px", color: "#BF2929" }}
                    ref={refNombreError}
                    id="errorNotVisible"
                  ></ErrorOutlineSharpIcon>
                </div>
                <p className="Titulos">{i18n.t("Email")}</p>
                <div>
                  <input
                    value={worker.mail}
                    contentEditable="false"
                    placeholder={i18n.t("Email")}
                    name="email"
                  ></input>
                  <CheckCircleOutlineSharpIcon
                    style={{ width: "16px", color: "#3BA452" }}
                    ref={refEmailConfirm}
                    id="confirmNotVisible"
                  ></CheckCircleOutlineSharpIcon>
                  <ErrorOutlineSharpIcon
                    style={{ width: "16px", color: "#BF2929" }}
                    ref={refEmailError}
                    id="errorNotVisible"
                  ></ErrorOutlineSharpIcon>
                </div>
              </>
            ) : (
              <>
                {" "}
                <p className="Titulos">{i18n.t("Nombre y Apellido")}</p>
                <div>
                  <input
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyDown={handleEnter}
                    ref={refNombre}
                    onClick={handleOnClick}
                    placeholder={i18n.t("Nombre y Apellido")}
                    name="nombre"
                    onChange={obtenerCampos}
                  ></input>
                  <CheckCircleOutlineSharpIcon
                    style={{ width: "16px", color: "#3BA452" }}
                    ref={refNombreConfirm}
                    id="confirmNotVisible"
                  ></CheckCircleOutlineSharpIcon>
                  <ErrorOutlineSharpIcon
                    style={{ width: "16px", color: "#BF2929" }}
                    ref={refNombreError}
                    id="errorNotVisible"
                  ></ErrorOutlineSharpIcon>
                </div>
                <p className="Titulos">{i18n.t("Email")}</p>
                <div>
                  <input
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyDown={handleEnter}
                    ref={refEmail}
                    onClick={handleOnClick}
                    placeholder={i18n.t("Email")}
                    name="email"
                    onChange={obtenerCampos}
                  ></input>
                  <CheckCircleOutlineSharpIcon
                    style={{ width: "16px", color: "#3BA452" }}
                    ref={refEmailConfirm}
                    id="confirmNotVisible"
                  ></CheckCircleOutlineSharpIcon>
                  <ErrorOutlineSharpIcon
                    style={{ width: "16px", color: "#BF2929" }}
                    ref={refEmailError}
                    id="errorNotVisible"
                  ></ErrorOutlineSharpIcon>
                </div>
              </>
            )}

            <p className="Titulos">{i18n.t("Móvil")}</p>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                ref={refMovil}
                onClick={handleOnClick}
                placeholder={i18n.t("Numero movil")}
                name="movil"
                onChange={obtenerCampos}
              ></input>
              <CheckCircleOutlineSharpIcon
                style={{ width: "16px", color: "#3BA452" }}
                ref={refMovilConfirm}
                id="confirmNotVisible"
              ></CheckCircleOutlineSharpIcon>
              <ErrorOutlineSharpIcon
                style={{ width: "16px", color: "#BF2929" }}
                ref={refMovilError}
                id="errorNotVisible"
              ></ErrorOutlineSharpIcon>
            </div>
            <p className="Titulos">{i18n.t("NIF")}</p>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                ref={refDni}
                onClick={handleOnClick}
                placeholder={i18n.t("Documento de identidad")}
                name="nif"
                onChange={obtenerCampos}
              ></input>
              <CheckCircleOutlineSharpIcon
                style={{ width: "16px", color: "#3BA452" }}
                ref={refDniConfirm}
                id="confirmNotVisible"
              ></CheckCircleOutlineSharpIcon>
              <ErrorOutlineSharpIcon
                style={{ width: "16px", color: "#BF2929" }}
                ref={refDniError}
                id="errorNotVisible"
              ></ErrorOutlineSharpIcon>
            </div>
            <p className="Titulos">{i18n.t("Empresa")}</p>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                ref={refEmpresa}
                onClick={handleOnClick}
                name="empresa"
                placeholder={i18n.t("Nombre de la empresa")}
                onChange={obtenerCampos}
              ></input>
              <CheckCircleOutlineSharpIcon
                style={{ width: "16px", color: "#3BA452" }}
                ref={refEmpresaConfirm}
                id="confirmNotVisible"
              ></CheckCircleOutlineSharpIcon>
              <ErrorOutlineSharpIcon
                style={{ width: "16px", color: "#BF2929" }}
                ref={refEmpresaError}
                id="errorNotVisible"
              ></ErrorOutlineSharpIcon>
            </div>
            <p className="Titulos">{i18n.t("Dirección")}</p>
            <div>
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={handleEnter}
                onClick={handleOnClick}
                name="direccion"
                placeholder={i18n.t("Direccion")}
                onChange={obtenerCampos}
              ></input>
            </div>
            <p className="Titulos">{i18n.t("Etiqueta")}</p>

            <div
              ref={refTags}
              onClick={handleOnClick}
              className="container2"
              style={{ padding: "0px" }}
            >
              <LocalOfferOutlinedIcon id="iconTag"></LocalOfferOutlinedIcon>
              {tags.map((tag, index) => (
                // eslint-disable-next-line react/jsx-key
                <div id={tag[1]} className="tag">
                  {tag[0]}
                  <button onClick={e => deleteTag(e, index)}>x</button>
                </div>
              ))}
              <input
                onFocus={onFocus}
                onBlur={onBlur}
                name="etiqueta"
                value={input}
                placeholder={i18n.t("Añadir Etiqueta")}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onChange={onChange}
              />
              <AddCircleOutlineIcon
                id="addTag"
                onClick={AddTagOnClick}
              ></AddCircleOutlineIcon>
            </div>
            <div id="Default">
              <span id="color1" className="defaultTag" onClick={selectTag}>
                {i18n.t("Manager")}
              </span>
              <span id="color2" className="defaultTag" onClick={selectTag}>
                {i18n.t("Peon")}
              </span>
              <span id="color3" className="defaultTag" onClick={selectTag}>
                {i18n.t("OFICIAL")}
              </span>
              <span id="color4" className="defaultTag" onClick={selectTag}>
                {i18n.t("INSTALADOR")}
              </span>
            </div>
            {loading == false ? (
              <div id="centerButton">
                <button
                  type="button"
                  onClick={async () => {
                    await createContact()
                  }}
                  className="AddContact"
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="ContactCreated"
                  data-amplify-analytics-attrs={campos}
                >
                  {i18n.t("Agregar")}
                </button>
              </div>
            ) : (
              <div id="centerButton">
                <CircularProgress size={24} />
              </div>
            )}
          </form>
        </div>
      </Modal>
      {openImage && (
        <ModalImageProject
          openImage={openImage}
          setOpenImage={setOpenImage}
          image={image}
          setImage={setImage}
          imInContacts={true}
        ></ModalImageProject>
      )}
    </>
  )
}

AddContact.propTypes = {
  openAdd: PropTypes.bool.isRequired,
  setOpenAdd: PropTypes.func.isRequired,
  worker: PropTypes.object,
  setCambiado: PropTypes.func,
  setSelectedContact: PropTypes.func,
}

export default withRouter(AddContact)
