import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"

const UnityDropdown = props => {
  const [menu, setMenu] = useState(false)

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{ width: "100%" }}
        >
          <DropdownToggle
            id="settingb"
            style={{
              marginTop: "-10px",
              color: "black",
              margin: "0px",
              padding: "0px",
              width: "100%",
            }}
          >
            <p style={{ color: "#414141" }}>
              {props.unity}{" "}
              <KeyboardArrowDown
                style={{
                  color: "#414141",
                  width: "15px",
                  position: "absolute",
                  right: "0px",
                  marginTop: "-2px",
                }}
              />
            </p>
          </DropdownToggle>
          <DropdownMenu
            id="showUnities"
            className="dropdown-menu-end"
            style={{ margin: "-100px", marginTop: "-40px !important" }}
          >
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("pa")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              pa
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("m")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              m
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("ml")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              ml
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("m2")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              m2
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("m3")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              m3
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("ud")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              ud
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("kg")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              kg
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </>
  )
}

UnityDropdown.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  setUnity: PropTypes.func,
  unity: PropTypes.string,
}

export default withTranslation()(withRouter(UnityDropdown))
