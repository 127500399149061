import React, { useState, useContext, useEffect, useRef } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row } from "reactstrap"
import { RecordPageView } from "App"
import DashboardComponent from "./dashboardComponent"
import AuthContext from "context/auth/authContext"

const NewDashboard = () => {
  RecordPageView()

  const { user } = useContext(AuthContext)

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper | Proyectos</title>
        </MetaTags>
        <Container fluid>{user && <DashboardComponent />}</Container>
      </div>
    </React.Fragment>
  )
}

export default NewDashboard
