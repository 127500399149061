import { useEffect, useContext } from "react"
import DynamicElementsContext from "context/dynamicElements/DynamicElementsContext"

export const useDynamicElements = organizationId => {
  const { dynamicElements, dispatches, loading } = useContext(
    DynamicElementsContext,
  )

  useEffect(() => {
    const fetchData = async () => {
      if (dynamicElements.length === 0 && organizationId) {
        await dispatches.getDynamicElements(organizationId)
      }
    }

    if (organizationId) {
      fetchData()
    }

    return () => {
      dispatches.setDynamicElementsToInitialState()
    }
  }, [organizationId])

  return { loading, dynamicElements }
}
