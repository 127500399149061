import React, { useReducer } from "react"
import PropTypes from "prop-types"
import { API, Auth } from "aws-amplify"
import FacturasContext from "./facturasContext"
import FacturasReducer from "./facturasReducer"
import Invoice from "./invoice"
import moment from "moment"
import axios from "axios"
import { currencyFormatToFloat } from "lib/transformers"

const FacturasState = props => {
  const initialState = {
    facturas: [],
    loading: false,
    loadingPDF: false,
    facturaInfo: {},
    total: 0,
    subtotal: 0,
    invoicePdf: null,
    loadingSendInvoice: false,
  }

  const [state, dispatch] = useReducer(FacturasReducer, initialState)
  const APINAME = "invoiceAPI"

  const getFacturas = async projectId => {
    try {
      dispatch({
        type: "GET_FACTURAS_LOADING",
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const res = await API.get(APINAME, "/invoice/project/" + projectId, {
        headers: headers,
      })
      let aux = []
      for (let i = 0; i < res.invoices.length; i++) {
        aux.push(new Invoice(res.invoices[i]))
      }
      aux.sort((a, b) => {
        return a.createdAt < b.createdAt ? 1 : -1
      })
      dispatch({
        type: "GET_FACTURAS_SUCCESS",
        payload: {
          facturas: aux,
          subtotal: res.subtotal,
          total: res.total,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getAllFacturas = async userId => {
    try {
      dispatch({
        type: "GET_FACTURAS_LOADING",
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const res = await API.get(APINAME, "/account/" + userId, {
        headers: headers,
      })
      let aux = []
      for (let i = 0; i < res.invoices.length; i++) {
        aux.push(new Invoice(res.invoices[i]))
      }
      dispatch({
        type: "GET_FACTURAS_SUCCESS",
        payload: {
          facturas: aux,
          subtotal: res.subtotal,
          total: res.total,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const createFactura = async (
    projectId,
    firstRow,
    secondRow,
    thirdRow,
    subtotal,
    total,
    accountId,
    contact,
    name,
    projectInfo,
  ) => {
    var regEx = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i
    var newInvoiceDate = firstRow.invoiceDate
    var newInvoiceDueDate = firstRow.invoiceDueDate
    if (
      firstRow.invoiceDate.match(regEx) ||
      firstRow.invoiceDueDate.match(regEx)
    ) {
      newInvoiceDate = firstRow.invoiceDate.split("/").reverse().join("-")
      newInvoiceDueDate = firstRow.invoiceDueDate.split("/").reverse().join("-")
    }
    try {
      dispatch({
        type: "CREATING_FACTURA",
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const body = {
        ...firstRow,
        ...thirdRow,
        invoiceStatus:
          thirdRow.invoiceStatus.length > 0
            ? thirdRow.invoiceStatus
            : "NO PAGADA",
        invoiceDate: firstRow.invoiceDate == "" ? undefined : newInvoiceDate,
        invoiceDueDate:
          firstRow.invoiceDueDate == "" ? undefined : newInvoiceDueDate,
        contact: contact,
        subtotal: parseFloat(subtotal),
        total: parseFloat(total),
        accountId: accountId,
        name: name.length > 0 ? name : projectInfo.name,
        projectId: projectId,
        projectInfo: projectInfo,
      }
      const res = await API.post(APINAME, "/invoice", {
        headers: headers,
        body: body,
      })

      for (let i = 0; i < secondRow.length; i++) {
        const res2 = await API.post(APINAME, "/invoice/concept", {
          headers: headers,
          body: {
            ...secondRow[i],
            invoiceId: res.invoiceId,
            percentage: 0,
            subtotal:
              parseFloat(currencyFormatToFloat(secondRow[i].price)) *
              parseFloat(currencyFormatToFloat(secondRow[i].quantity)) *
              (1 -
                parseFloat(currencyFormatToFloat(secondRow[i].discount) / 100)),
            total:
              parseFloat(currencyFormatToFloat(secondRow[i].price)) *
              parseFloat(currencyFormatToFloat(secondRow[i].quantity)) *
              (1 -
                parseFloat(currencyFormatToFloat(secondRow[i].discount)) /
                  100) *
              (1 + parseFloat(secondRow[i].tax) / 100),
            accountId: accountId,
            projectId: projectId,
            price: parseFloat(currencyFormatToFloat(secondRow[i].price)),
            quantity: parseFloat(currencyFormatToFloat(secondRow[i].quantity)),
            discount: parseFloat(currencyFormatToFloat(secondRow[i].discount)),
            tax: parseFloat(currencyFormatToFloat(secondRow[i].tax)),
          },
        })
      }
      const newFactura = new Invoice(res)
      dispatch({
        type: "FACTURA_CREATED",
        payload: newFactura,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateInvoiceStatus = async (invoiceId, invoiceStatus) => {
    try {
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const body = {
        invoiceStatus: invoiceStatus,
        invoiceId: invoiceId,
      }
      const res = await API.put(APINAME, "/invoice/" + invoiceId, {
        headers: headers,
        body: body,
      })
      dispatch({
        type: "INVOICE_STATUS_UPDATED",
        payload: {
          invoiceId: invoiceId,
          invoiceStatus: invoiceStatus,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const deleteFactura = async invoiceId => {
    try {
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const res = await API.del(APINAME, "/invoice/" + invoiceId, {
        headers: headers,
      })
      dispatch({
        type: "FACTURA_DELETED",
        payload: invoiceId,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getFacturaComplete = async invoiceId => {
    try {
      dispatch({
        type: "GET_FACTURAS_LOADING",
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const res = await API.get(APINAME, "/invoice/completeInfo/" + invoiceId, {
        headers: headers,
      })
      let firstRow = {
        invoiceNumber: res.invoiceInfo.invoiceNumber,
        invoiceDate: res.invoiceInfo.invoiceDate
          ? moment(res.invoiceInfo.invoiceDate, "YYYY-MM-DD").format(
              "DD/MM/YYYY",
            )
          : "",
        invoiceDueDate: res.invoiceInfo.invoiceDueDate
          ? moment(res.invoiceInfo.invoiceDueDate, "YYYY-MM-DD").format(
              "DD/MM/YYYY",
            )
          : "",
        contact: res.invoiceInfo.contact,
      }
      let secondRow = res.concepts
      let thirdRow = {
        iban: res.invoiceInfo.iban,
        invoiceMessage: res.invoiceInfo.invoiceMessage,
        invoicePaymentMethod: res.invoiceInfo.invoicePaymentMethod,
        invoiceStatus: res.invoiceInfo.invoiceStatus,
      }
      let extraInfo = {
        subtotal: res.invoiceInfo.subtotal,
        total: res.invoiceInfo.total,
        tax: res.invoiceInfo.tax,
        invoiceId: res.invoiceInfo.invoiceId,
        projectId: res.invoiceInfo.projectId,
        projectInfo: res.invoiceInfo.projectInfo,
        name: res.invoiceInfo.name,
      }
      dispatch({
        type: "GET_FACTURA_COMPLETE_SUCCESS",
        payload: {
          firstRow: firstRow,
          secondRow: secondRow,
          thirdRow: thirdRow,
          extraInfo: extraInfo,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  const updateFactura = async (
    invoiceId,
    projectId,
    firstRow,
    secondRow,
    thirdRow,
    subtotal,
    total,
    accountId,
    contact,
    name,
    projectInfo,
    paymentType,
  ) => {
    var regEx = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i
    var newInvoiceDate = firstRow.invoiceDate
    var newInvoiceDueDate = firstRow.invoiceDueDate
    if (
      firstRow.invoiceDate.match(regEx) ||
      firstRow.invoiceDueDate.match(regEx)
    ) {
      newInvoiceDate = firstRow.invoiceDate.split("/").reverse().join("-")
      newInvoiceDueDate = firstRow.invoiceDueDate.split("/").reverse().join("-")
    }
    try {
      dispatch({
        type: "GET_FACTURAS_LOADING",
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      let conceptosAux = state.facturaInfo.secondRow
      for (let i = 0; i < secondRow.length; i++) {
        if (secondRow[i].invoiceConceptId == undefined) {
          await API.post(APINAME, "/invoice/concept", {
            headers: headers,
            body: {
              ...secondRow[i],
              invoiceId: invoiceId,
              percentage: 0,
              subtotal:
                parseFloat(currencyFormatToFloat(secondRow[i].price)) *
                parseFloat(currencyFormatToFloat(secondRow[i].quantity)) *
                (1 -
                  parseFloat(currencyFormatToFloat(secondRow[i].discount)) /
                    100),
              total:
                parseFloat(currencyFormatToFloat(secondRow[i].price)) *
                parseFloat(currencyFormatToFloat(secondRow[i].quantity)) *
                (1 - parseFloat(secondRow[i].discount) / 100) *
                (1 + parseFloat(currencyFormatToFloat(secondRow[i].tax)) / 100),
              accountId: accountId,
              projectId: projectId,
              price: parseFloat(currencyFormatToFloat(secondRow[i].price)),
              quantity: parseFloat(
                currencyFormatToFloat(secondRow[i].quantity),
              ),
              discount: parseFloat(
                currencyFormatToFloat(secondRow[i].discount),
              ),
              tax: parseFloat(currencyFormatToFloat(secondRow[i].tax)),
            },
          })
        }
      }
      for (let i = 0; i < conceptosAux.length; i++) {
        let j = 0
        let trobat = false
        while (j < secondRow.length && !trobat) {
          if (
            secondRow[j].invoiceConceptId == conceptosAux[i].invoiceConceptId
          ) {
            trobat = true
          } else {
            j++
          }
        }
        if (!trobat) {
          await API.del(
            APINAME,
            "/invoice/concept/" +
              conceptosAux[i].invoiceConceptId +
              "?invoiceId=" +
              invoiceId,
            { headers: headers },
          )
        } else {
          await API.put(
            APINAME,
            "/invoice/concept/" + secondRow[j].invoiceConceptId,
            {
              headers: headers,
              body: {
                invoiceId: invoiceId,
                tax: secondRow[j].tax
                  ? parseFloat(currencyFormatToFloat(secondRow[j].tax))
                  : 0,
                description: secondRow[j].description,
                discount: secondRow[j].discount
                  ? parseFloat(currencyFormatToFloat(secondRow[j].discount))
                  : 0,
                quantity: secondRow[j].quantity
                  ? parseFloat(currencyFormatToFloat(secondRow[j].quantity))
                  : 0,
                price: secondRow[j].price
                  ? parseFloat(currencyFormatToFloat(secondRow[j].price))
                  : 0,
                projectId: projectId,
                percentage: secondRow[j].percentage
                  ? parseFloat(secondRow[j].percentage)
                  : 0,
                accountId: accountId,
                subtotal:
                  parseFloat(secondRow[j].price) *
                  parseFloat(secondRow[j].quantity) *
                  (1 - parseFloat(secondRow[j].discount) / 100).toFixed(2),
                total:
                  parseFloat(secondRow[j].price) *
                  parseFloat(secondRow[j].quantity) *
                  (1 - parseFloat(secondRow[j].discount) / 100) *
                  (1 + parseFloat(secondRow[j].tax) / 100).toFixed(2),
              },
            },
          )
        }
      }

      const body = {
        ...firstRow,
        ...thirdRow,
        invoiceDate: firstRow.invoiceDate == "" ? undefined : newInvoiceDate,
        invoiceDueDate:
          firstRow.invoiceDueDate == "" ? undefined : newInvoiceDueDate,
        contact: contact,
        subtotal: parseFloat(subtotal),
        total: parseFloat(total),
        accountId: accountId,
        projectId: projectId,
        name: name.length > 0 ? name : projectInfo.name,
        projectInfo: projectInfo,
        invoicePaymentMethod: paymentType,
      }
      const res = await API.put(APINAME, "/invoice/" + invoiceId, {
        headers: headers,
        body: body,
      })
      const newFactura = new Invoice(res)
      dispatch({
        type: "FACTURA_UPDATED",
        payload: newFactura,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getInvoicePDF = async invoiceId => {
    try {
      dispatch({
        type: "GET_PDF_LOADING",
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      const res = await API.get(APINAME, "/" + invoiceId + "/pdf", {
        headers: headers,
      })
      dispatch({
        type: "GET_INVOICE_PDF_SUCCESS",
        payload: res,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const uploadFile = async ({ file, presignedPostData }) => {
    try {
      const formData = new FormData()
      Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key])
      })
      formData.append("file", file)
      const res = await axios.post(presignedPostData.url, formData)
      return res
    } catch (error) {
      console.log(error)
    }
  }

  const createFacturasWithPhases = async (projectInfo, accountId, fases) => {
    try {
      let newDate = new Date()
      newDate =
        newDate.getFullYear() +
        "-" +
        (newDate.getMonth() + 1) +
        "-" +
        newDate.getDate()
      dispatch({
        type: "GET_FACTURAS_LOADING",
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      for (let i = 0; i < fases.length; i++) {
        const body = {
          subtotal: parseFloat(fases[i].cantidad),
          total: parseFloat(fases[i].cantidad),
          invoiceDate: newDate,
          accountId: accountId,
          projectId: projectInfo.projectId,
          projectInfo: projectInfo,
          accountId: accountId,
          invoiceNumber: "2022_" + parseInt(state.facturas.length + 1),
          invoiceStatus: "NO PAGADA",
        }
        const res = await API.post(APINAME, "/invoice", {
          headers: headers,
          body: body,
        })
        const res2 = await API.post(APINAME, "/invoice/concept", {
          headers: headers,
          body: {
            invoiceId: res.invoiceId,
            percentage: parseFloat(fases[i].porcentaje),
            subtotal: parseFloat(fases[i].cantidad),
            discount: 0,
            total: parseFloat(fases[i].cantidad),
            accountId: accountId,
            projectId: projectInfo.projectId,
            price: parseFloat(fases[i].cantidad),
            description: fases[i].nombre,
            quantity: 1,
            tax: 0,
          },
        })

        const newFactura = new Invoice({ ...res, projectInfo: projectInfo })
        dispatch({
          type: "FACTURA_CREATED_WITHOUT_LOADING",
          payload: newFactura,
        })
      }
      dispatch({
        type: "RESET",
      })
    } catch (error) {
      console.log(error)
    }
  }

  const sendInvoice = async (invoiceId, selectedContacts, messageInfo) => {
    try {
      dispatch({
        type: "SEND_INVOICE_LOADING",
      })
      const headers = {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        "Access-Control-Allow-Origin": "*",
      }
      let files = []
      if (messageInfo.selectedFiles.length > 0) {
        for (let i = 0; i < messageInfo.selectedFiles.length; i++) {
          let payload = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            },
            body: {
              filename: messageInfo.selectedFiles[i].name,
              extension: messageInfo.selectedFiles[i].name
                .split(".")
                .slice(-1)[0],
              size: messageInfo.selectedFiles[i].size,
              contentType: messageInfo.selectedFiles[i].type,
              customDB: false,
              importedProject: false,
              tags: [],
              description: "",
              orgId: "",
            },
          }
          const res1 = await API.post(
            "dev-PLANHOPPER-API",
            "/api/file/upload",
            payload,
          )
          const res3 = await uploadFile({
            file: messageInfo.selectedFiles[i],
            presignedPostData: res1.uploadInfo,
          })
          files.push({
            bucket: res1.file.bucket ? res1.file.bucket : null,
            key: res1.uploadInfo.fields.key ? res1.uploadInfo.fields.key : null,
            filename: messageInfo.selectedFiles[i].name,
            url: res1.file.url ? res1.file.url : null,
          })
        }
      }
      for (let i = 0; i < selectedContacts.length; i++) {
        const body = {
          invoiceId: invoiceId,
          contact: selectedContacts[i],
          contactProfile: messageInfo.roles,
          subject: messageInfo.asunto,
          message: messageInfo.mensaje,
          invoiceFile: { filename: messageInfo.title },
          projectId: messageInfo.projectId,
          orgId: messageInfo.orgId,
          attachedFiles: files,
        }
        const res = await API.post(APINAME, "/send", {
          headers: headers,
          body: body,
        })
      }

      dispatch({
        type: "SEND_INVOICE_SUCCESS",
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <FacturasContext.Provider
      value={{
        facturas: state.facturas,
        loading: state.loading,
        loadingPDF: state.loadingPDF,
        facturaInfo: state.facturaInfo,
        invoicePdf: state.invoicePdf,
        total: state.total,
        subtotal: state.subtotal,
        loadingSendInvoice: state.loadingSendInvoice,
        getFacturas,
        getAllFacturas,
        createFactura,
        updateInvoiceStatus,
        deleteFactura,
        getFacturaComplete,
        updateFactura,
        getInvoicePDF,
        createFacturasWithPhases,
        sendInvoice,
      }}
    >
      {props.children}
    </FacturasContext.Provider>
  )
}

FacturasState.propTypes = {
  children: PropTypes.element,
}

export default FacturasState
