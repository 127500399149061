import i18n from "i18n"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { HelveticaBold } from "common/typographies"
import CloseOutlined from "@mui/icons-material/CloseOutlined"
import { useParams } from "react-router-dom"
import AuthContext from "context/auth/authContext"
import ProjectContext from "context/projects/projectContext"
import deleteCap from "assets/images/budgeting/deleteCap.png"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "438px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    maxHeight: "287px",
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const ModalConvert2Template = ({ setOpen, open }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  const handleContinuar = async () => {
    //convert 2 template
    //redirect to templates dashboard
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseOutlined
            onClick={() => {
              setOpen(false)
            }}
            style={{
              position: "absolute",
              right: "15px",
              cursor: "pointer",
              width: "15px",
            }}
          />
          <div id="centerPaco">
            <img
              src={deleteCap}
              style={{
                width: "110px",
                height: "110px",
                marginBottom: "15px",
                marginTop: "-30px",
              }}
            ></img>
          </div>
          <div
            style={{
              width: "100%",
              position: "relative",
              justifyContent: "center",
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "30px",
            }}
          >
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "16px",
                marginBottom: "0px",
              }}
            >
              {i18n.t("Atención")}
            </HelveticaBold>
            <HelveticaBold
              style={{
                color: "#414141",
                fontSize: "13px",
                fontWeight: "400",
                lineHeight: "15px",
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              {i18n.t(
                "Vas a convertir este proyecto en una plantilla a la que podrás acceder cuando quieras desde tu base de datos. ¿Estás seguro?",
              )}
            </HelveticaBold>
          </div>

          <div
            className="inline"
            style={{
              width: "100%",
              marginBottom: "35px",
              marginTop: "30px",
              position: "relative",
              marginTop: "10px",
            }}
          >
            <button
              onClick={() => {
                setOpen(false)
              }}
              className="AddProject"
              style={{
                marginRight: "0%",
                marginLeft: "20px",
                paddingRight: "0px",
                width: "190px",
                border: "2px solid #FFD52B",
                background: "white",
              }}
            >
              {i18n.t("Cancelar")}
            </button>
            <button
              onClick={() => {
                handleContinuar()
              }}
              className="AddProject"
              style={{
                paddingRight: "10px",
                cursor: "pointer",
                marginLeft: "230px",
                width: "190px",
                border: "2px solid #FFD52B",
                background: "#FFD52B",
              }}
            >
              {i18n.t("Convertir en plantilla")}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
ModalConvert2Template.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
export default ModalConvert2Template
