import { PoppinsNormal } from "common/typographies"
import i18n from "i18n"
import React, { useContext, useState, useEffect } from "react"
import AuthContext from "context/auth/authContext"
import { CircularProgress } from "@mui/material"
import { useParams } from "react-router-dom"
import Budget2023Context from "context/budget2023/Budget2023Context"
import ProjectContext from "context/projects/projectContext"
import ModalNoSelection from "./ModalNoSelection"
import ModalCapitulos from "./ModalCapitulos"
import ModalChapterSelected from "./ModalSelected"
import DownloadBudgetModal from "components/Feed/DownloadBudget"
import SendModal from "components/Feed/sendBudgetRefatctor/sendModal"
import ContactContext from "context/contact/contactContext"
import PropTypes from "prop-types"
import ModalExportBankPrice from "./ModalExportBankPrice"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import ModalSelectPlantilla from "../modals/modalSelectPlantilla"
import deleteIcon from "assets/customIcons/topQuickBar/deleteIcon.svg"
import bancoPreciosIcon from "assets/customIcons/topQuickBar/bankPrice.svg"
import ModalDeleteCap from "../modals/ModalDeleteCap"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import SyncAltIcon from "@mui/icons-material/SyncAlt"
import NewModalPartida from "pages/ModalPartidas/NewModalPartida"
import wordIcon from "assets/customIcons/topQuickBar/word.svg"
import excelIcon from "assets/customIcons/topQuickBar/excel.svg"
import pdfIcon from "assets/customIcons/topQuickBar/pdf.svg"
import Box from "@mui/material/Box"
import MoveDownIcon from "@mui/icons-material/MoveDown"
import TreeViewModal from "../modals/TreeViewModal"

const TopQuickBudgetMenu = ({ soyFinal }) => {
  const {
    chaptersChecked,
    partsChecked,
    loadingCaps,
    groupParts,
    selectedPartGroup,
    selectedChaptersGroup,
    budgetTree,
    selectPartsGroup,
    getSelectedBudget2,
    errorAgrupar,
    loadingAgruparChapters,
    loadingAgruparParts,
    uncheckAll,
    deleteCapitulo,
    deletePartidav2,
    cleanChapters,
    cleanParts,
    duplicateParts,
    setIsContradictingPart,
    exportToPdf,
    exportToWord,
    exportToExcel,
    loadingIcon,
    setLoadingIcon,
    movePartsToChapter,
    isBudgetClosed,
  } = useContext(Budget2023Context)
  const { user, freeTrialExpired } = useContext(AuthContext)
  const [name, setName] = useState("")
  const [openAgrupar, setOpenAgrupar] = useState(false)
  const [openCaps, setOpenCaps] = useState(false)
  const [estadoLista, setEstadoLista] = useState([])
  const [estadoListaCaps, setEstadoListaCaps] = useState([])
  const [openSelected, setOpenSelected] = useState(false)
  const { projectInfo } = useContext(ProjectContext)
  const { projectId } = useParams()
  const { Contacts, getContacts } = useContext(ContactContext)
  const { myPricesBank } = useContext(BankPricesContext)

  const [openEliminate, setOpenEliminate] = useState(false)
  const [deletingParts, setDeletingParts] = useState(false)
  const [openModalCreatePartida, setOpenModalCreatePartida] = useState(false)

  const handleDelete = async () => {
    if (chaptersChecked.length > 0) {
      setOpenEliminate(true)
    }
    if (partsChecked.length > 0) {
      setDeletingParts(true)
      for (let index = 0; index < partsChecked.length; index++) {
        await deletePartidav2(
          partsChecked[index].chapterId,
          partsChecked[index].partidaId,
          partsChecked[index].accountId,
        )
      }
      setDeletingParts(false)
      await cleanParts()
    }
  }

  useEffect(() => {
    getContacts()
  }, [])
  useEffect(() => {
    if (chaptersChecked.length > 0 || partsChecked.length > 0) {
      selectPartsGroup([], budgetTree.subcapitulos)
      getSelectedBudget2([], budgetTree.subcapitulos)
    }
  }, [chaptersChecked.length, partsChecked.length])

  useEffect(() => {
    setEstadoLista(selectedPartGroup)
  }, [selectedPartGroup])

  useEffect(() => {
    setEstadoListaCaps(selectedChaptersGroup)
  }, [selectedChaptersGroup])

  const agruparPartidas = async () => {
    if (errorAgrupar == false) {
      if (estadoLista.length == 0) {
        setName("las partidas")
        setOpenAgrupar(true)
      } else {
        groupParts(projectInfo.projectId)
      }
    } else {
      setOpenSelected(true)
    }
  }

  const agruparCapitulos = async () => {
    if (estadoListaCaps.length == 0) {
      //modal no has agrupado nada
      setName("los capítulos")
      setOpenAgrupar(true)
    } else {
      //antes de esto mensaje de aviso
      setOpenCaps(true)
    }
  }

  const handleDuplicateParts = async () => {
    if (partsChecked?.length > 0) {
      await duplicateParts(partsChecked)
    }
  }

  const handleContradictPart = partsChecked => {
    setIsContradictingPart(true)
    setOpenModalCreatePartida(true)
  }

  const handleExportPdf = async () => {
    await exportToPdf(partsChecked, chaptersChecked)
  }

  const handleExportWord = async () => {
    // await exportToWord()
  }

  const handleExportExcel = async () => {
    await exportToExcel(partsChecked, chaptersChecked)
  }

  const [isTreeViewVisible, setIsTreeViewVisible] = useState(false)

  const handleMoveParts = () => {
    setIsTreeViewVisible(true)
  }

  const changeCheck = e => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    if (partsChecked.length > 0 || chaptersChecked.length > 0) {
      uncheckAll()
    }
  }

  const [openDownloadBudgetModal, setOpenDownloadBudgetModal] = useState(false)
  const [openSendBudgetModal, setOpenSendBudgetModal] = useState(false)
  const [openExportBankPrice, setOpenExportBankPrice] = useState(false)
  const [openSelectPlantilla, setOpenSelectPlantilla] = useState(false)

  const getIconStyle = disabled => ({
    color: user
      ? user["custom:role"] === "WORKER" || freeTrialExpired
        ? "#C4C4C4"
        : "white"
      : "#C4C4C4",
    width: "19px",
    marginTop: "-2px",
    marginRight: "10px",
    cursor: disabled ? "not-allowed" : "pointer",
  })

  const isWorker = () => {
    return user && user["custom:role"] === "WORKER"
  }

  const canDelete = () => {
    return user && !(isWorker() || freeTrialExpired) && !isBudgetClosed
  }

  const icons = [
    {
      component: <img src={deleteIcon} style={getIconStyle(isBudgetClosed)} />,
      showCondition: true,
      action: () => {
        canDelete() && handleDelete()
      },
      tooltip: !isBudgetClosed
        ? "Eliminar"
        : "Tienes que abrir el presupuesto para poder eliminar partidas",
      showLoading: false,
    },
    {
      component: <SyncAltIcon style={getIconStyle(!isBudgetClosed)} />,
      showCondition: true,
      action: () => {
        isBudgetClosed && handleContradictPart(partsChecked)
      },
      tooltip: isBudgetClosed
        ? "Contradecir partida"
        : "Tienes que cerrar el presupuesto para poder crear un contradictorio",
    },
    {
      component: (
        <ContentCopyIcon
          style={getIconStyle(isBudgetClosed || chaptersChecked.length > 0)}
        />
      ),
      showCondition: true,
      action: () => {
        if (!isBudgetClosed && chaptersChecked.length === 0) {
          handleDuplicateParts()
        }
      },
      tooltip: isBudgetClosed
        ? "Tienes que abrir el presupuesto para poder duplicar partidas"
        : chaptersChecked.length > 0
          ? "No se puede duplicar un capítulo"
          : "Duplicar partida",
      showLoading: chaptersChecked.length > 0 ? false : true,
    },

    {
      component: (
        <img
          src={bancoPreciosIcon}
          style={{
            ...getIconStyle(),
            width: "23px",
            height: "25px",
          }}
        />
      ),
      showCondition: chaptersChecked.length == 0,
      action: () => {
        user &&
          !(isWorker() || freeTrialExpired) &&
          setOpenExportBankPrice(true)
      },
      tooltip: "Importar a banco de precios",
      showLoading: false,
    },
    {
      component: <img src={pdfIcon} style={getIconStyle()} />,
      showCondition:
        (chaptersChecked.length == 0 && partsChecked.length > 0) ||
        (partsChecked.length == 0 && chaptersChecked.length > 0),
      action: () => {
        user && !(isWorker() || freeTrialExpired) && handleExportPdf()
      },
      tooltip: "Exportar a PDF",
      showLoading: true,
    },
    {
      component: <img src={wordIcon} style={getIconStyle()} />,
      showCondition: false,
      action: () => {
        // user && !(isWorker() || freeTrialExpired) && handleExportWord()
      },
      tooltip: "Exportar a Word",
      showLoading: true,
    },
    {
      component: <img src={excelIcon} style={getIconStyle()} />,
      showCondition:
        (chaptersChecked.length == 0 && partsChecked.length > 0) ||
        (partsChecked.length == 0 && chaptersChecked.length > 0),
      action: () => {
        user && !(isWorker() || freeTrialExpired) && handleExportExcel()
      },
      tooltip: "Exportar a Excel",
      showLoading: false,
    },
    {
      component: <MoveDownIcon style={getIconStyle(isBudgetClosed)} />,
      showCondition: partsChecked.length == 1,
      action: () => {
        !isBudgetClosed && handleMoveParts()
      },
      tooltip: !isBudgetClosed
        ? "Mover partida a capítulo"
        : "Tienes que abrir el presupuesto para poder mover partidas",
      showLoading: false,
    },
  ]

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "47px",
          backgroundColor: "#4461D7",
          zIndex: "999",
          paddingTop: "15px",
        }}
      >
        <div className="inline">
          <div style={{ marginLeft: "10%" }} className="inline">
            <div className="tdcheckColumn">
              <input
                className="form-check-input"
                type="checkbox"
                checked={
                  partsChecked.length > 0 || chaptersChecked.length > 0
                    ? true
                    : false
                }
                id="flexCheckDefault"
                style={{
                  width: "13px",
                  height: "13px",
                  cursor: "pointer",
                  zIndex: "5",
                  position: "relative",
                }}
                onClick={e => {
                  changeCheck(e)
                }}
              ></input>
            </div>
            {chaptersChecked.length > 0 ? (
              chaptersChecked.length == 1 ? (
                <PoppinsNormal style={{ color: "white" }}>
                  {chaptersChecked.length} {i18n.t("capitulo seleccionado")}{" "}
                </PoppinsNormal>
              ) : (
                <PoppinsNormal style={{ color: "white" }}>
                  {chaptersChecked.length} {i18n.t("capitulos seleccionados")}{" "}
                </PoppinsNormal>
              )
            ) : partsChecked.length > 0 ? (
              partsChecked.length == 1 ? (
                <PoppinsNormal style={{ color: "white" }}>
                  {partsChecked.length} {i18n.t("partida seleccionada")}{" "}
                </PoppinsNormal>
              ) : (
                <PoppinsNormal style={{ color: "white" }}>
                  {partsChecked.length} {i18n.t("partidas seleccionadas")}{" "}
                </PoppinsNormal>
              )
            ) : null}
          </div>
          <div
            style={{
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              position: "absolute",
              display: "flex",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "-1px",
            }}
          >
            {icons.map(
              (icon, index) =>
                icon.showCondition && (
                  <div key={index} style={{ position: "relative" }}>
                    {loadingIcon.isLoading &&
                    icon.showLoading &&
                    loadingIcon.index === index &&
                    !isTreeViewVisible ? (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: "auto", marginRight: "3px" }}
                      />
                    ) : (
                      React.cloneElement(icon.component, {
                        onClick: () => {
                          setLoadingIcon({
                            isLoading: true,
                            index: index,
                          })
                          icon.action()
                        },
                        className: `iconoShow-${index + 1}`,
                        onMouseEnter: () => {
                          document.querySelector(
                            `.icono-${index + 1}`,
                          ).style.display = "block"
                        },
                        onMouseLeave: () => {
                          document.querySelector(
                            `.icono-${index + 1}`,
                          ).style.display = "none"
                        },
                      })
                    )}
                    <div
                      className={`icono-${index + 1}`}
                      style={{
                        display: "none",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        top: "30px",
                      }}
                    >
                      <div className="recuadro" style={{ width: "125px" }}>
                        <div
                          className="recuadroPico"
                          style={{
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        ></div>
                        {i18n.t(icon.tooltip)}
                      </div>
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      </div>
      <ModalNoSelection
        open={openAgrupar}
        setOpen={setOpenAgrupar}
        nombre={name}
      />
      <ModalChapterSelected open={openSelected} setOpen={setOpenSelected} />
      <ModalCapitulos
        open={openCaps}
        setOpen={setOpenCaps}
        projectId={projectId}
      />
      <DownloadBudgetModal
        soyFinal={soyFinal}
        open={openDownloadBudgetModal}
        setOpen={setOpenDownloadBudgetModal}
        name={projectInfo.name}
      />
      <SendModal
        open={openSendBudgetModal}
        setOpen={setOpenSendBudgetModal}
        contactos={Contacts}
      />
      <ModalExportBankPrice
        open={openExportBankPrice}
        setOpen={setOpenExportBankPrice}
        project={myPricesBank}
      />
      <ModalSelectPlantilla
        open={openSelectPlantilla}
        setOpen={setOpenSelectPlantilla}
      />
      <ModalDeleteCap
        openEliminate={openEliminate}
        setOpenEliminate={setOpenEliminate}
        chapterList={true}
      />
      {openModalCreatePartida ? (
        <NewModalPartida
          open={openModalCreatePartida}
          setOpen={setOpenModalCreatePartida}
          chapterId={partsChecked[0].chapterId}
          partidaInfo={partsChecked[0]}
        />
      ) : null}
      <TreeViewModal
        open={isTreeViewVisible}
        setOpen={setIsTreeViewVisible}
        chapters={budgetTree.subcapitulos}
        callback={selectedChapter => {
          movePartsToChapter(partsChecked, selectedChapter.chapterId, projectId)
          setIsTreeViewVisible(false)
        }}
      />
    </>
  )
}

TopQuickBudgetMenu.propTypes = {
  soyFinal: PropTypes.bool,
}

export default TopQuickBudgetMenu
