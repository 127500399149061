import { BlueButton, CapituloMini } from "common/containers"
import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import AuthContext from "context/auth/authContext"
import Budget2023Context from "context/budget2023/Budget2023Context"

const CapituloModalMove = ({
  capitulo,
  capituloSelected,
  setCapituloSelected,
  margin,
}) => {
  const [openNotSendCapitulo, setOpenNotSendCapitulo] = useState(false)
  const { getSubCapitulosContent } = useContext(Budget2023Context)
  const { getSubCaps, myPricesBank } = useContext(BankPricesContext)
  const [cargado, setCargado] = useState(false)
  const { user } = useContext(AuthContext)
  const handleOpen = async e => {
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
    setOpenNotSendCapitulo(prev => !prev)
    if (cargado == false) {
      getSubCapitulosContent(capitulo.projectId, capitulo.chapterId)
      setCargado(true)
    }
    getSubCaps(capitulo.chapterId, user.sub)
  }
  const handleCheckBox = () => {
    if (capituloSelected.chapterId == capitulo.chapterId) {
      setCapituloSelected({})
    } else {
      setCapituloSelected(capitulo)
    }
  }

  return (
    <>
      <CapituloMini
        onClick={() => {
          setCapituloSelected(capitulo)
        }}
        style={{
          background: capituloSelected.capituloInfo
            ? capituloSelected.capituloInfo.chapterId
              ? capituloSelected.capituloInfo.chapterId ==
                capitulo.capituloInfo.chapterId
                ? "#FFE992"
                : "#FDEFC6"
              : capituloSelected.chapterId == capitulo.chapterId
                ? "#FFE992"
                : "#FDEFC6"
            : "#FDEFC6",
          marginLeft: margin + "px",
        }}
      >
        {openNotSendCapitulo ? (
          <div>
            <KeyboardArrowDownIcon
              style={{
                cursor: "pointer",
                marginLeft: "5px",
                marginTop: "5px",
                visibility:
                  capitulo.subcapitulos.length == 0 && cargado == true
                    ? "hidden"
                    : "visible",
              }}
              className="dropdownArrowB"
              onClick={e => handleOpen(e)}
            />
          </div>
        ) : (
          <KeyboardArrowRightIcon
            style={{
              cursor: "pointer",
              marginLeft: "5px",
              marginTop: "5px",
              visibility:
                capitulo.subcapitulos.length == 0 && cargado == true
                  ? "hidden"
                  : "visible",
            }}
            className="dropdownArrowB"
            onClick={e => handleOpen(e)}
          />
        )}
        {openNotSendCapitulo && capitulo.subcapitulos.length <= 0 ? (
          <input
            className="checkboxChapter"
            type={"checkbox"}
            onChange={() => handleCheckBox()}
            checked={capitulo.chapterId == capituloSelected.chapterId}
            style={{
              display:
                capitulo.chapterId == capituloSelected.chapterId
                  ? "block"
                  : "none",
              width: "13px",
              marginRight: "5px",
              cursor: "pointer",
              marginTop: "-2px",
            }}
          ></input>
        ) : null}
        <p>{capitulo.capituloInfo.name}</p>
      </CapituloMini>
      {openNotSendCapitulo
        ? capitulo.subcapitulos.length > 0
          ? capitulo.subcapitulos.map((subcapitulo, index) => {
              return subcapitulo.capituloInfo.parentId == capitulo.chapterId ? (
                <CapituloModalMove
                  margin={margin + 20}
                  key={index}
                  capitulo={subcapitulo}
                  capituloSelected={capituloSelected}
                  setCapituloSelected={setCapituloSelected}
                />
              ) : null
            })
          : null
        : null}
    </>
  )
}

CapituloModalMove.propTypes = {
  capitulo: PropTypes.object.isRequired,
  capituloSelected: PropTypes.object.isRequired,
  setCapituloSelected: PropTypes.func.isRequired,
  margin: PropTypes.number.isRequired,
}

export default CapituloModalMove
