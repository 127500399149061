import React, { useState } from "react"
import PropTypes from "prop-types"
import CheckCircleOutlineSharpIcon from "@mui/icons-material/CheckCircleOutlineSharp"
import { useHistory } from "react-router-dom"
import ModalEditMargins from "../Overview/ModalEditMargins"

const ProvidersLayout = ({ projectInfo }) => {
  const [open2, setOpen2] = useState(false)
  const history = useHistory()

  return (
    <>
      <div style={{ maxHeight: "750px", height: "750px", display: "contents" }}>
        <div
          style={{
            marginLeft: "24px",
            marginRight: "10px",
            marginBottom: "30px",
          }}
        >
          <div id="SubMenuProjects">
            <div className="inline">
              {" "}
              <p id="Configuration">{projectInfo.name}</p>
              <div
                className="inline"
                style={{ marginLeft: "10px", marginTop: "1.5px" }}
              >
                <CheckCircleOutlineSharpIcon
                  style={{
                    color: "#4461D7",
                    width: "24px",
                    marginTop: "2.5px",
                    marginRight: "5px",
                  }}
                />{" "}
                <p id="autoSave"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalEditMargins
        projectInfo={projectInfo}
        open={open2}
        setOpen={setOpen2}
      />
    </>
  )
}

ProvidersLayout.propTypes = {
  projectInfo: PropTypes.object.isRequired,
}

export default ProvidersLayout
