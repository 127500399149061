import styled from "styled-components"
export const ChapterContainer = styled.div`
  width: ${prop => prop.width};

  height: 36px;
  background: ${prop => (prop.hasChilds === true ? "white" : "#FDEFC6")};
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  margin-left: ${prop => prop.marginLeft};
  margin-right: 20px;
  display: flex;
  position: relative;
  cursor: pointer;
  margin-top: 9px;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  border: ${prop => (prop.hasChilds === true ? "1px solid #FFD52B" : "0px")};
  border-left: ${prop =>
    prop.hasChilds === true ? "5px solid #FFD52B" : "0px"};

  p {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    color: #414141;
    margin-left: 20px;
    margin-top: 8px;
  }

  :hover {
    background: #ffd52b33 !important;
  }
`
