import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"

const UnityDropdown = props => {
  const [menu, setMenu] = useState(false)

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{ marginTop: "0px" }}
        >
          <DropdownToggle
            className="inline"
            id="settingb"
            style={{
              color: "black",
              margin: "0px",
              padding: "0px",
              width: "100%",
              fontWeight: "400",
            }}
          >
            <p
              style={{
                color: "#414141",
                textTransform: "none",
                marginLeft: "15px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {props.unity}{" "}
              <KeyboardArrowDown
                style={{
                  color: "#414141",
                  fontWeight: "400",
                  width: "15px",
                  marginTop: "-2px",
                }}
              />
            </p>
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-end"
            style={{ margin: "0px 0px" }}
          >
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("pa")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              pa
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("m")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              m
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("ml")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              ml
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("m²")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              m²
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("m³")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              m³
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("ud")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              ud
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("kg")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              kg
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.setUnity("t")
              }}
            >
              {" "}
              <i
                style={{ marginTop: "-2px", paddingRight: "5px" }}
                className="bx bx-list-check font-size-16 align-middle me-1"
              />
              t
            </DropdownItem>
            {props.imDesglose && (
              <>
                <DropdownItem
                  tag="b"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.setUnity("l")
                  }}
                >
                  {" "}
                  <i
                    style={{ marginTop: "-2px", paddingRight: "5px" }}
                    className="bx bx-list-check font-size-16 align-middle me-1"
                  />
                  l
                </DropdownItem>
                <DropdownItem
                  tag="b"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.setUnity("h")
                  }}
                >
                  {" "}
                  <i
                    style={{ marginTop: "-2px", paddingRight: "5px" }}
                    className="bx bx-list-check font-size-16 align-middle me-1"
                  />
                  h
                </DropdownItem>
                {
                  //            <DropdownItem tag="b" style={{cursor:'pointer'}} onClick={()=>{props.setUnity('%')}}>
                  //              {" "}
                  //              <i style={{marginTop:'-2px', paddingRight:'5px'}} className="bx bx-list-check font-size-16 align-middle me-1"/>
                  //              %
                  //            </DropdownItem>
                }
              </>
            )}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </>
  )
}

UnityDropdown.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  setUnity: PropTypes.func,
  unity: PropTypes.string,
  imDesglose: PropTypes.bool,
}

export default withTranslation()(withRouter(UnityDropdown))
