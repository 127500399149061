import AuthContext from "context/auth/authContext"
import { useContext } from "react"

export function currencyFormatDE(num, simbol) {
  return (
    num
      .toFixed(2) // always two decimal digits
      .replace(".", ",") // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") +
    " " +
    simbol
  ) // use . as a separator
}

export function currencyFormatWithoutSimbol(num) {
  return parseFloat(num.replace(",", "."))
    .toFixed(2) // always two decimal digits
    .replace(".", ",") // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") // use . as a separator
}

export function currencyFormatToNumber(num) {
  return parseFloat(num.toString().replace(".", "").replace(",", "."))
    .toFixed(2)
    .replace(".", ",")
}

export function currencyFormatToConcept(num) {
  return parseFloat(num)
    .toFixed(2)
    .replace(".", ",")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
}

export function currencyFormatToFloat(num) {
  return parseFloat(
    num.toString().replaceAll(".", "").replaceAll(",", "."),
  ).toFixed(2)
}
