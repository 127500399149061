//Logos no seleccionados
import manual from "assets/images/logos/gris-disabled/manual.svg"
import user from "assets/customIcons/nonSelectedUB.svg"
import tribboo from "assets/images/logos/gris-disabled/tribboo.svg"
import cype from "assets/images/logos/gris-disabled/cype.svg"
import itec from "assets/images/logos/gris-disabled/itec.svg"
import preoc from "assets/images/logos/gris-disabled/preoc-premeti.svg"
//Logos seleccionados
import manualEnabled from "assets/images/logos/color-enabled/manual.svg"
import userEnabled from "assets/customIcons/selectedUB.svg"
import tribbooEnabled from "assets/images/logos/color-enabled/tribboo.svg"
import cypeEnabled from "assets/images/logos/color-enabled/cype.svg"
import itecEnabled from "assets/images/logos/color-enabled/itec.svg"
import preocEnabled from "assets/images/logos/color-enabled/preoc-premeti.svg"

export const banks = [
  {
    type: "Manual part",
    logo: manual,
    logoEnabled: manualEnabled,
  },
  {
    type: "User database",
    logo: user,
    logoEnabled: userEnabled,
    filter: "brightness(0.7)",
  },
  {
    type: "Tribboo database",
    logo: tribboo,
    logoEnabled: tribbooEnabled,
  },
  {
    type: "Cype database",
    logo: cype,
    logoEnabled: cypeEnabled,
  },
  {
    type: "Bedec database",
    logo: itec,
    logoEnabled: itecEnabled,
  },
  {
    type: "Preoc database",
    logo: preoc,
    logoEnabled: preocEnabled,
  },
]
