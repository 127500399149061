import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@mui/material"
import PropTypes from "prop-types"
import { Titulos } from "common/containers"
import i18n from "i18n"
import { Modal } from "@mui/material"
import { CloseOutlined } from "@mui/icons-material"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "389px",
    height: "550px",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 3, 3),
    borderRadius: 6,
  },
  title: {
    color: "#A0AEC0",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "10px",
  },
  content: {
    fontFamily: "Helvetica",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHight: "140%",
  },
}))

const ModalFichaProveedor = ({ providerInfo, open, setOpen }) => {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const [campos, guardarCampos] = useState({
    name: "",
    description: "",
    identifier_number: "",
    orgAddress: "",
    client: "",
    tags: [],
    imagen: "",
    projectId: "",
    costeIndirecto: "",
    costeGeneral: "",
    industrialProfit: "",
    costeTotal: "",
  })
  const [isEdit, setIsEdit] = useState(false)
  const handleEditFields = e => {
    e.preventDefault()
    setIsEdit(true)
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <Box style={modalStyle} className={classes.paper}>
          {!isEdit ? (
            <>
              <Box
                sx={{
                  color: "#414141",
                  backgroundColor: "transparent",
                  borderRadius: "6px",
                  cursor: "pointer",
                  width: "30px",
                  height: "30px",
                  marginLeft: "auto",
                }}
              >
                <CloseOutlined
                  style={{ width: "1.3em", marginTop: "10%" }}
                  onClick={() => setOpen(false)}
                ></CloseOutlined>
              </Box>
              <Box
                sx={{
                  width: " 100%",
                  overflow: "inherit",
                  borderBottom: "1px solid #E2E8F0",
                  paddingBottom: "0rem",
                  paddingTop: "2rem",
                }}
              >
                <Box>
                  <p className={classes.content} style={{ fontSize: "18px" }}>
                    {i18n.t("Ficha del proveedor")}
                  </p>
                </Box>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #E2E8F0",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <Titulos
                  style={{ marginBottom: "5px" }}
                  className={`${classes.title}`}
                >
                  {i18n.t("Nombre Empresa")}
                </Titulos>
                <span className={classes.content}>{providerInfo.orgName}</span>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #E2E8F0",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <Titulos
                  style={{ marginBottom: "5px" }}
                  className={`${classes.title}`}
                >
                  {i18n.t("CIF")}
                </Titulos>
                <span className={classes.content}>
                  {providerInfo.nif ? (
                    <span className={classes.content}>{providerInfo.nif}</span>
                  ) : (
                    <div style={{ height: "2rem" }}></div>
                  )}
                </span>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #E2E8F0",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <Titulos
                  style={{ marginBottom: "5px" }}
                  className={`${classes.title}`}
                >
                  {i18n.t("Nombre Contacto")}
                </Titulos>
                <span className={classes.content}>
                  {providerInfo.completeName}
                </span>
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #E2E8F0",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <Titulos
                  style={{ marginBottom: "5px" }}
                  className={`${classes.title}`}
                >
                  {i18n.t("Telefono")}
                </Titulos>
                {providerInfo.mobile ? (
                  <span className={classes.content}>{providerInfo.mobile}</span>
                ) : (
                  <div style={{ height: "2rem" }}></div>
                )}
              </Box>

              <Box
                sx={{
                  borderBottom: "1px solid #E2E8F0",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <Titulos
                  style={{ marginBottom: "5px" }}
                  className={`${classes.title}`}
                >
                  {i18n.t("Email")}
                </Titulos>
                {providerInfo.email ? (
                  <span className={classes.content}>{providerInfo.email}</span>
                ) : (
                  <div style={{ height: "2rem" }}></div>
                )}
              </Box>
            </>
          ) : null}
        </Box>
      </Modal>
    </>
  )
}

ModalFichaProveedor.propTypes = {
  providerInfo: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default ModalFichaProveedor
