import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
//i18n
import i18n from "i18n"
import { PoppinsNormal } from "common/typographies"
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"

const DropdownPaymentSelect = props => {
  const [menu, setMenu] = useState(false)
  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="d-inline-block"
          style={{
            height: "0px",
            width: props.imInInvoice ? "93%" : "90%",
            minWidth: props.imInInvoice ?? "250px",
            marginLeft: props.imInInvoice ? "20px" : "20px",
          }}
        >
          <DropdownToggle
            ref={props.referencia ? props.referencia : null}
            style={{
              marginTop: "-10px",
              height: "40px",
              marginLeft: props.imInInvoice ? "0px" : "0px",
              borderRadius: "6px",
              border: "1px solid #c4c4c4",
              width: "100%",
              paddingLeft: "10px",
            }}
          >
            <div className="inline" style={{ position: "relative" }}>
              <PoppinsNormal
                style={{
                  fontSize: "14px",
                  color: "#414141",
                  lineHeight: "20px",
                  cursor: "pointer",
                }}
              >
                {props.paymentType}
              </PoppinsNormal>
              <KeyboardArrowDown
                style={{
                  color: "#414141",
                  width: "15px",
                  position: "absolute",
                  right: props.imInInvoice ? "0px" : "5px",
                  marginTop: props.imInInvoice ? "-2px" : "-1px",
                }}
              />
            </div>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end" style={{ width: "100%" }}>
            <DropdownItem
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
              onClick={() => {
                props.setPaymentType("Tarjeta")
              }}
            >
              {" "}
              <div className="inline">{i18n.t("Tarjeta")}</div>
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
              onClick={() => {
                props.setPaymentType("Transferencia Bancaria")
              }}
            >
              {" "}
              <div className="inline">{i18n.t("Transferencia Bancaria")}</div>
            </DropdownItem>
            <DropdownItem
              tag="b"
              style={{ lineHeight: "20px", cursor: "pointer" }}
              onClick={() => {
                props.setPaymentType("Cheque")
              }}
            >
              {" "}
              <div className="inline">{i18n.t("Cheque")}</div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    </>
  )
}

DropdownPaymentSelect.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  paymentType: PropTypes.string,
  setPaymentType: PropTypes.func,
  imInInvoice: PropTypes.bool,
  referencia: PropTypes.any,
}

export default DropdownPaymentSelect
