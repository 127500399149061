const emptyFn = () => {}
class ExportSideBar {
  constructor(
    isOpen = false,
    setOpen = emptyFn,
    downloadHistoryCertification = emptyFn,
    setSelectedCertificationId = emptyFn,
    selectedCertificationId = null,
    isLoading = false,
  ) {
    this.isOpen = isOpen
    this.setOpen = setOpen
    this.downloadHistoryCertification = downloadHistoryCertification
    this.setSelectedCertificationId = setSelectedCertificationId
    this.selectedCertificationId = selectedCertificationId
    this.isLoading = isLoading
  }
}

export default ExportSideBar
