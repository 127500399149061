import { FALSE } from "sass"
import {
  REGISTRO_ERROR,
  REGISTRO_EXITOSO,
  LOGIN_ERROR,
  LOGIN_EXITOSO,
  LOGOUT,
  OBTENER_USUARIO,
  MODIFICAR_USUARIO,
  ERROR_USUARIO,
} from "../types"

import {
  ON_SAVE_CARD,
  SAVE_CARD_SUCCESS,
  SAVE_CARD_ERROR,
  ON_SAVE_BILLING_ADDRESS,
  SAVE_BILLING_ADDRESS_SUCCESS,
  SAVE_BILLING_ADDRESS_ERROR,
  ON_GET_CUSTOMER_INFO,
  GET_CUSTOMER_INFO_SUCCESS,
  ON_VALIDATE_COUPON,
  VALIDATE_COUPON_SUCCESS,
  VALIDATE_COUPON_ERROR,
  ON_DELETE_CARD,
  DELETE_CARD_SUCCESS,
  ON_PAYMENT_INTENT,
  PAYMENT_INTENT_SUCCESS,
  PAYMENT_INTENT_ERROR,
  ON_SEND_INVITATION,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_ERROR,
} from "./types"

const AuthReducer = (state, action) => {
  switch (action.type) {
    case REGISTRO_EXITOSO:
      return {
        ...state,
        statusRegister: true,
        errorRegistro: { estado: false, mensaje: null },
        waiting: false,
        isAuthenticated: true,
        errorType: "",
      }
    case "SET_FORM":
      return {
        ...state,
        form: action.payload,
      }
    case REGISTRO_ERROR:
      return {
        ...state,
        statusRegister: false,
        errorRegistro: { estado: true, mensaje: action.payload },
        waiting: false,
        isAuthenticated: false,
        //errorType: aux,
      }
    case LOGIN_EXITOSO:
      return {
        ...state,
        user: action.payload.attributes,
        errorLogin: { estado: false, mensaje: null },
        errorType: "",
      }
    case LOGIN_ERROR:
      return {
        ...state,
        errorLogin: { estado: true, mensaje: action.payload },
      }
    case LOGOUT:
      return {
        ...state,
        user: null,
      }
    case OBTENER_USUARIO:
      return {
        ...state,
        user: action.payload.attributes,
      }
    case MODIFICAR_USUARIO:
      return {
        ...state,
        user: action.payload,
      }
    case ERROR_USUARIO:
      return {
        ...state,
        statusRegister: false,
        errorRegistro: { estado: false, mensaje: null },
      }
    case "CLOSE_SNACKBAR":
      return {
        ...state,
        showSnackbar: false,
      }
    case "OPEN_SNACKBAR":
      return {
        ...state,
        showSnackbar: true,
      }
    case "EXPIRE_FREE_TRIAL":
      return {
        ...state,
        freeTrialExpired: true,
      }

    case "ADD_BUDGETING_TEMPLATE":
      return {
        ...state,
        budgetingTemplate: action.payload,
      }
    case "ADD_INVOICING_TEMPLATE":
      return {
        ...state,
        billingTemplate: action.payload,
      }
    case "GET_SUBSCRIPTION":
      return {
        ...state,
        cardInfo: action.payload.cardInfo,
        billingInfo: action.payload.billingInfo,
      }
    case GET_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        subscription: action.payload.subscription,
        card: {
          ...action.payload.card,
          loading: false,
          error: false,
        },
        billingAddress: {
          ...action.payload.billingAddress,
          loading: false,
          error: false,
        },
      }
    case ON_SAVE_CARD:
      return {
        ...state,
        card: {
          ...state.card,
          loading: true,
        },
      }
    case SAVE_CARD_SUCCESS:
      return {
        ...state,
        card: {
          ...action.payload.card,
          loading: false,
          error: false,
        },
      }
    case SAVE_CARD_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      }
    case ON_GET_CUSTOMER_INFO:
      return {
        ...state,
        card: {
          ...state.card,
          loading: true,
          error: false,
        },
        billingAddress: {
          ...state.billingAddress,
          loading: true,
          error: false,
        },
      }
    case ON_SAVE_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          loading: true,
          error: false,
        },
      }
    case SAVE_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        billingAddress: {
          ...action.payload,
          loading: false,
          error: false,
        },
      }
    case SAVE_BILLING_ADDRESS_ERROR:
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          loading: false,
          error: true,
        },
      }

    case ON_VALIDATE_COUPON:
      return {
        ...state,
        coupon: {
          loading: true,
          error: false,
        },
      }
    case VALIDATE_COUPON_SUCCESS:
      return {
        ...state,
        coupon: {
          ...action.payload,
          loading: false,
          error: false,
        },
      }
    case VALIDATE_COUPON_ERROR:
      return {
        ...state,
        coupon: {
          loading: false,
          error: true,
        },
      }
    case ON_DELETE_CARD:
      return {
        ...state,
        card: {
          ...state.card,
          loading: true,
          error: false,
        },
      }
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        card: {
          loading: false,
          error: false,
        },
      }
    case ON_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: {
          hostedPage: null,
          loading: true,
          error: false,
        },
      }
    case PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        paymentIntent: {
          loading: false,
          hostedPage: action.payload,
          error: false,
        },
      }
    case PAYMENT_INTENT_ERROR:
      return {
        ...state,
        paymentIntent: {
          loading: false,
          error: true,
          hostedPage: null,
        },
      }
    case "GET_PORTAL_SUCCESS":
      return {
        ...state,
        chargebeePortal: action.payload,
      }
    case "GET_ORGANIZATION_SUCCESS":
      let auxMembers = []
      let admin = {}
      console.log(action.payload)
      if (action.payload.users.length == 0) {
        admin = state.user
      } else {
        action.payload.users.forEach(member => {
          if (member.role == "WORKER") {
            auxMembers.push(member)
          } else {
            admin = member
          }
        })
      }
      return {
        ...state,
        organization: {
          loading: false,
          error: false,
        },
        organizationUsers: auxMembers,
        organizationAdmin: admin,
      }
    case "GET_ORGANIZATION_NEW":
      console.log(action.payload, "eee")
      return {
        ...state,
        organizationFields: action.payload,
        organization: {
          loading: true,
          error: false,
        },
      }
    case "GETING_ORGANIZATION":
      return {
        ...state,
        organization: {
          loading: true,
          error: false,
        },
      }
    case "EMPTY_ORGANIZATION":
      return {
        ...state,
        organization: {
          loading: false,
          error: false,
        },
        organizationUsers: [],
        organizationAdmin: state.user,
      }

    case "GET_ACTUAL_USER_SUCCESS":
      return {
        ...state,
        internalUser: {
          ...action.payload,
        },
      }
    case ON_SEND_INVITATION:
      return {
        ...state,
        invitationLoading: true,
      }
    case SEND_INVITATION_SUCCESS:
      console.log(action.payload, state.organizationUsers)
      return {
        ...state,
        invitationLoading: false,
        organizationUsers: [...state.organizationUsers, action.payload],
      }
    case SEND_INVITATION_ERROR:
      return {
        ...state,
        invitationLoading: false,
      }
    case "GET_PENDING_ACTIONS":
      return {
        ...state,
        pendingActions: action.payload,
      }
    case "EXECUTE_PENDING_ACTIONS":
      return {
        ...state,
        loadingPendingActions: true,
      }
    case "SET_PENDING_ACTIONS":
      return {
        ...state,
        loadingPendingActions: false,
      }
    case "SET_TEMPLATES":
      switch (action.payload.type) {
        case "BUDGET":
          let auxTemplates = []
          action.payload.res.forEach(template => {
            if (template.id != 621616) {
              auxTemplates.push(template)
            }
          })
          return {
            ...state,
            budgetingTemplates: auxTemplates,
          }
        case "INVOICE":
          return {
            ...state,
            facturaTemplates: action.payload.res,
          }
        case "ACT":
          return {
            ...state,
            actTemplates: action.payload.res,
          }
      }

    default:
      return state
  }
}

export default AuthReducer
