import React, { useState, useContext, useEffect } from "react"
import ImportTemplateDropzone from "../dropzones/importTemplateDropzone"
import PropTypes from "prop-types"
import { Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import i18n from "i18n"
import TemplatesContext from "context/templates/templatesContext"
import WaitingTemplateModal from "./waitingTemplateModal"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "550px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 6,
    maxHeight: "calc(100vh - 100px)",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ImportTemplate = ({
  setImportedTemplateId,
  importedTemplateId,
  open,
  setOpen,
  templateInfo,
  setOpenSuccess,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const templateContext = useContext(TemplatesContext)
  const {
    deleteCapitulo,
    cleanChapters,
    cleanParts,
    myPricesBank,
    importTemplateFromFile,
    getMyBankPrices,
    uploadingTemplate,
    uploadError,
    uploadSuccess,
    clearUploadProcess,
  } = templateContext
  const [selectedFiles, setSelectedFiles] = useState([])
  const upload = () => {
    if (templateInfo) {
      importTemplateFromFile({
        file: selectedFiles[0],
        templateId: templateInfo.templateId,
      })
      setOpenModal(true)
    }
  }

  useEffect(() => {
    if (uploadSuccess) {
      clearUploadProcess()
    }
  }, [uploadingTemplate, uploadError, uploadSuccess])

  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <Modal open={open} onClose={() => null}>
        <>
          <div style={modalStyle} className={classes.paper}>
            <div className={"py-4"}>
              <p
                className="text-center my-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "23px",
                  color: "#414141",
                }}
              >
                {i18n.t("Sube tus ficheros para crear tu plantilla")}
              </p>
              <button
                type="button"
                onClick={() => {
                  setOpen(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ padding: "0 5rem" }}>
              <ImportTemplateDropzone
                selectedFiles={selectedFiles}
                setselectedFiles={setSelectedFiles}
              />
            </div>
            <div>
              <p
                className="text-center my-3"
                style={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "15px",
                  color: "#414141",
                  textDecorationLine: "underline",
                  cursor: "pointer",
                }}
              >
                <Link
                  style={{
                    color: "#414141",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  to={`/bancodeprecios/template/${templateInfo.templateId}`}
                >
                  {i18n.t("Omitir por ahora")}
                </Link>
              </p>
            </div>
            <div id="centerButton">
              {uploadingTemplate === true ? (
                <button
                  style={{ marginTop: "15px", marginBottom: "20px" }}
                  className="AddProjectDisabled"
                  type="button"
                >
                  {i18n.t("Subiendo...")}
                </button>
              ) : (
                <button
                  type="button"
                  onClick={upload}
                  className={
                    selectedFiles.length == 0
                      ? "AddProjectDisabled"
                      : "AddContact"
                  }
                  style={{ marginTop: "15px", marginBottom: "20px" }}
                >
                  {i18n.t("Subir")}
                </button>
              )}
            </div>
          </div>
        </>
      </Modal>
      <WaitingTemplateModal
        setImportedTemplateId={setImportedTemplateId}
        importedTemplateId={importedTemplateId}
        openModal={openModal}
        setSuccessModal={setOpenSuccess}
        setOpen={setOpen}
        setOpenModal={setOpenModal}
        template={templateInfo}
      />
    </>
  )
}

ImportTemplate.propTypes = {
  setImportedTemplateId: PropTypes.func,
  importedTemplateId: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  templateInfo: PropTypes.object,
  setOpenSuccess: PropTypes.func,
}

export default ImportTemplate
