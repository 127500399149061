import React, { createElement } from "react"
import PropTypes from "prop-types"
import { Box, CircularProgress, Tooltip } from "@mui/material"
import { green } from "@mui/material/colors"
import { Check } from "@mui/icons-material"

const ActionIcon = ({
  success,
  loading,
  handleSubmit,
  ActionComponent,
  isActionComponentPrimary,
  tooltipTitle,
  disabled,
}) => {
  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      {success ? (
        <Check sx={{ color: green[700] }} />
      ) : (
        <Tooltip title={disabled ? "" : tooltipTitle} placement="top">
          {createElement(ActionComponent, {
            color: disabled
              ? "grey"
              : isActionComponentPrimary
                ? "primary"
                : "",
            onClick: disabled ? undefined : handleSubmit,
            disabled,
          })}
        </Tooltip>
      )}

      {loading && (
        <CircularProgress
          size={40}
          sx={{
            color: green[500],
            position: "absolute",
            top: -6,
            left: -10,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  )
}

ActionIcon.propTypes = {
  success: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  ActionComponent: PropTypes.elementType.isRequired, //? need to be a MUI material Icon  (Save , Delete or other)
  isActionComponentPrimary: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltipTitle: PropTypes.string,
}

ActionIcon.defaultProps = {
  tooltipTitle: "Action",
  isActionComponentPrimary: true,
  disabled: false,
}

export default ActionIcon
