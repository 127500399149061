import styled from "styled-components"

export const BillingInput = styled.input`
  width: 385.13px;
  height: 40px;
  border: ${props =>
    props.valid === true ? "1px solid #e0e0e0" : "1px solid red"};
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  color: #414141;
  margin-bottom: 9px;
  padding-left: 8px;

  &::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #b7b7b7;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #ffd52b !important;
  }

  &:hover {
    &::placeholder {
      color: #414141;
    }
    border: 1px solid #414141;
  }
`
export const HelveticaInput = styled.input`
  width: 385.13px;
  height: 35px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  color: #414141;
  margin-bottom: 9px;
  padding-left: 8px;

  &::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #b7b7b7;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #ffd52b !important;
  }

  &:hover {
    &::placeholder {
      color: #414141;
    }
    border: 1px solid #414141;
  }
`
export const HelveticaInput2 = styled.input`
  width: 385.13px;
  height: 40px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  color: #414141;
  margin-bottom: 9px;
  padding-left: 8px;

  &::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #b7b7b7;
  }

  &:hover {
    &::placeholder {
      color: #414141;
    }
    border: 1px solid #414141;
  }
`

export const HelveticaInputBlue = styled.input`
  width: 385.13px;
  height: 40px;
  border: 1px solid #b7b7b7;
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.08));
  border-radius: 6px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  color: #414141;
  margin-bottom: 9px;
  padding-left: 8px;

  &::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #b7b7b7;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #4461d7;
  }
`

export const PriceInput = styled.input`
  background-color: white;
  border: none;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #414141;

  &::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #b7b7b7;
  }
`

export const Input4Search = styled.input`
  width: 594px;
  height: 43px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  color: #414141;
  margin-bottom: 9px;
  font-size: 16px;
  padding-left: 50px;

  &::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #b7b7b7;
  }

  &:focus {
    outline: none !important;
    border: 1px solid #ffd52b !important;
  }

  &:hover {
    &::placeholder {
      color: #414141;
    }
    border: 1px solid #414141;
  }
`

export const EmptyHelveticaInput = styled.input`
  width: 100%;
  height: 35px;
  border: none;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  color: #414141;
  padding-left: 0px;

  &::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #b7b7b7;
  }

  &:hover {
    &::placeholder {
      color: #414141;
    }
  }
`

export const CustomCheckedInput = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid #c4c4c4;
  border-radius: 100px;
  cursor: pointer;
  background-color: white;
`

export const CheckedInput = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background-color: #4461d7;
  margin-top: 1px;
  margin-left: 1px;
`
