import i18n from "i18n"
import { CircularProgress, Modal } from "@mui/material"
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import {
  PoppinsNormal,
  HelveticaBold,
  HelveticaLight,
} from "common/typographies"
import {
  CloseOutlined,
  DeleteOutlineOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material"
import { HelveticaInput2 } from "common/inputs"
import { YellowButton } from "common/buttons"
import { useParams } from "react-router-dom"
import ProjectContext from "context/projects/projectContext"
import FacturasContext from "context/facturas/facturasContext"
import AuthContext from "context/auth/authContext"
import AddIcon from "@mui/icons-material/Add"
import { BlackHoverButton4CreateDynamically } from "common/containers"
import { currencyFormatToConcept } from "lib/transformers"
import DefinirImpuestosComponent from "./definirImpuestosComponent"
import ContactContext from "context/contact/contactContext"
import { getCurrency } from "utils/functions"
import Budget2023Context from "context/budget2023/Budget2023Context"

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "500px",
    maxHeight: "550px",
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    overflowY: "auto",
    overflowX: "hidden",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}))

const ModalDefinirFases = ({
  open,
  setOpen,
  imGeneral,
  selectedProject,
  invoiceService,
}) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const [error, setError] = useState(false)
  const { projectId } = useParams()
  const { budgetTree, getBudgetTree } = useContext(Budget2023Context)
  useEffect(() => {
    getBudgetTree(projectId)
  }, [])
  useEffect(() => {
    if (budgetTree) {
      setTotalFinal(budgetTree.clientPrice)
    }
  }, [budgetTree])
  const { projectInfo, getProject } = useContext(ProjectContext)
  const [totalFinal, setTotalFinal] = useState(
    projectId ? projectInfo?.clientPrice : selectedProject?.clientPrice,
  )
  const [totalRestante, setTotalRestante] = useState(0)
  const { Contacts, getContacts } = useContext(ContactContext)
  const [projectClient, setProjectClient] = useState({})
  const getContact = () => {
    if (projectInfo) {
      if (Contacts) {
        let aux = Contacts.filter(contact => contact.id == projectInfo.client)
        setProjectClient(aux[0])
      }
    } else {
      if (Contacts) {
        let aux = Contacts.filter(
          contact => contact.id == selectedProject.client,
        )
        setProjectClient(aux[0])
      }
    }
  }

  useEffect(() => {
    if (Contacts.length == 0) {
      getContacts()
    } else {
      getContact()
    }
  }, [Contacts, projectInfo, selectedProject])

  const [fases, setFases] = useState([
    {
      nombre: "",
      porcentaje: 0,
      cantidad: 0,
      isEdited: false,
      key: 1,
    },
  ])
  const [porcentajeRestante, setPorcentajeRestante] = useState(100)
  const changeFase = (e, index, type) => {
    e.preventDefault()
    const newFases = [...fases]
    newFases[index].porcentaje = parseFloat(e.target.value)
    newFases[index].isEdited = true
    newFases[index].cantidad = parseFloat(
      totalFinal?.toFixed(2) * (e.target.value / 100),
    ).toFixed(2)
    newFases[index].key = Math.random()
    caculateSamePorcentaje(newFases)
    setFases(newFases)
  }

  const changeName = (e, index) => {
    let newFases = fases
    newFases[index].nombre = e.target.value
    setFases(newFases)
  }

  const { user, getUser } = useContext(AuthContext)
  const { loading, createFacturasWithPhases } = useContext(FacturasContext)
  useEffect(() => {
    if (imGeneral) {
      if (!user) {
        getUser()
      }
    } else {
      if (!projectInfo) {
        getProject(projectId)
      }
      if (!user) {
        getUser()
      }
    }
  }, [])

  const [total, setTotal] = useState(0)
  const [step, setStep] = useState(0)

  const createFacturas = async () => {
    if (imGeneral) {
      await invoiceService.createWithSteps({
        steps: fases,
        projectInfo: selectedProject,
        extraInfo: { impuestos: impuestos, client: projectClient },
      })
      setStep(0)
      setOpen(false)
    } else {
      await invoiceService.createWithSteps({
        steps: fases,
        projectInfo: projectInfo,
        extraInfo: { impuestos: impuestos, client: projectClient },
      })
      setStep(0)
      setOpen(false)
    }
  }

  const deleteFase = index => {
    setFases(fases.filter((fase, i) => i !== index))
  }

  const caculateSamePorcentaje = newFases => {
    if (fases) {
      if (fases.length == 1) {
        if (fases[0].isEdited == false) {
          setFases([
            {
              nombre: "",
              porcentaje: 0,
              cantidad: totalFinal?.toFixed(2),
              isEdited: false,
              key: 1,
            },
          ])
          setTotalRestante(0)
          setPorcentajeRestante(100)
        }
      } else {
        let numeroFases = 0
        let auxPorcentaje = 0
        for (let i = 0; i < fases.length; i++) {
          if (fases[i].isEdited == false) {
            numeroFases++
          }
        }
        let sumFasesEdited = 0
        fases.map(fase => {
          if (fase.isEdited) {
            sumFasesEdited = parseFloat(fase.porcentaje) + sumFasesEdited
          }
        })
        let porcentaje = (100 - sumFasesEdited) / numeroFases
        if (sumFasesEdited > 100) {
          setError(true)
        } else {
          setError(false)
        }
        newFases.map(fase => {
          if (fase.isEdited == false) {
            fase.porcentaje =
              parseFloat(porcentaje) % 1 == 0
                ? parseFloat(porcentaje)
                : porcentaje.toFixed(2)
            fase.cantidad = Math.abs(
              (totalFinal?.toFixed(2) * (parseFloat(porcentaje) / 100)).toFixed(
                2,
              ),
            )
            fase.key = Math.random()
          }
        })
        let auxSum = 0
        newFases.map(fase => {
          if (fase.cantidad !== "" && fase.cantidad !== undefined) {
            auxSum += parseFloat(fase.cantidad)
          }
        })
        setTotal(auxSum)
        if (
          parseFloat(totalFinal).toFixed(2) - parseFloat(auxSum).toFixed(2) >
            -1 &&
          parseFloat(totalFinal).toFixed(2) - parseFloat(auxSum).toFixed(2) < 1
        ) {
          setTotalRestante(0)
        } else {
          setTotalRestante(
            parseFloat(totalFinal).toFixed(2) - parseFloat(auxSum).toFixed(2),
          )
        }
        setFases(newFases)
        setPorcentajeRestante(0)
      }
    }
  }

  useEffect(() => {
    caculateSamePorcentaje([...fases])
  }, [fases.length])

  useEffect(() => {
    if (projectInfo) {
      setTotalFinal(projectInfo.finalPrice)
      setFases([
        {
          nombre: "",
          porcentaje: 0,
          cantidad:
            projectInfo.finalPrice % 1 == 0
              ? projectInfo.finalPrice
              : parseFloat(projectInfo.finalPrice).toFixed(2),
          isEdited: false,
          key: 1,
        },
      ])
      setTotalRestante(0)
    } else if (selectedProject) {
      setTotalFinal(selectedProject.finalPrice)
      setFases([
        {
          nombre: "",
          porcentaje: 0,
          cantidad:
            selectedProject.finalPrice % 1 == 0
              ? selectedProject.finalPrice
              : parseFloat(selectedProject.finalPrice).toFixed(2),
          isEdited: false,
          key: 1,
        },
      ])
      setTotalRestante(0)
    } else {
      setTotalFinal(0)
      setTotalRestante(0)
    }
  }, [projectInfo, selectedProject])

  const [sumaPorcentaje, setSumaPorcentaje] = useState(0)
  useEffect(() => {
    let aux = 0
    fases.map(fase => {
      aux += parseFloat(fase.porcentaje)
    })
    setSumaPorcentaje(aux)
  }, [fases])

  const [impuestos, setImpuestos] = useState({
    iva: {},
    recargo: {},
    retention: {},
  })

  return (
    <>
      <Modal
        open={open}
        onClose={() => {
          null
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <CloseOutlined
            onClick={() => {
              setOpen(false)
            }}
            style={{
              position: "absolute",
              right: "10px",
              top: "5px",
              width: "16px",
              cursor: "pointer",
            }}
          />
          {step == 0 ? (
            <>
              <div
                id="centerPaco"
                style={{
                  marginLeft: "50px",
                  marginRight: "50px",
                  textAlign: "center",
                  marginTop: "20px",
                  display: "grid",
                }}
              >
                <PoppinsNormal style={{ fontSize: "24px", fontWeight: "600" }}>
                  {i18n.t("Define las fases de pago")}
                </PoppinsNormal>
                <HelveticaLight
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    marginTop: "-15px",
                    marginBottom: "0px",
                  }}
                >
                  {i18n.t(
                    "Al crear el plan las facturas se crearán automáticamente.",
                  )}
                </HelveticaLight>
              </div>

              {error && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "10px",
                    marginLeft: "0px",
                    marginBottom: "-10PX",
                  }}
                >
                  <ErrorOutlineOutlined
                    style={{ color: "#FF0000", width: "22px", height: "22px" }}
                  />
                  <PoppinsNormal
                    style={{
                      fontSize: "13px",
                      fontWeight: "300",
                      marginLeft: "10px",
                      marginTop: "15px",
                      color: "#FF0000",
                    }}
                  >
                    {i18n.t("Atención, el porcentaje sobrepasa el 100%")}
                  </PoppinsNormal>
                </div>
              )}
              <div
                id="centerPaco"
                style={{
                  marginLeft: "100px",
                  marginRight: "100px",
                  textAlign: "center",
                  marginTop: "40px",
                  display: "grid",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "5px",
                      marginLeft: "0px",
                      width: "20px",
                      marginRight: "20px",
                    }}
                  ></div>
                  <HelveticaInput2
                    value={imGeneral ? selectedProject.name : projectInfo?.name}
                    disabled
                    type="text"
                    style={{
                      width: "220px",
                      fontWeight: "700",
                      height: "30px",
                      marginLeft: "0px",
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  />
                  <div
                    className="inline"
                    style={{
                      width: "70px",
                      borderRadius: "6px",
                      backgroundColor: "transparent",
                      marginLeft: "10px",
                      height: "30px",
                      marginTop: "-10px",
                    }}
                  >
                    <HelveticaInput2
                      value={100}
                      disabled
                      type="number"
                      style={{
                        width: "53px",
                        textAlign: "right",
                        height: "30px",
                        fontWeight: "700",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    />
                    <PoppinsNormal
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        marginLeft: "2px",
                        marginTop: "6px",
                        fontWeight: "700",
                      }}
                    >
                      %
                    </PoppinsNormal>
                  </div>
                  <div
                    className="inline"
                    style={{
                      width: "150px",
                      borderRadius: "6px",
                      backgroundColor: "transparent",
                      marginLeft: "10px",
                      height: "30px",
                      marginTop: "-10px",
                      position: "relative",
                      marginRight: "20px",
                    }}
                  >
                    <HelveticaInput2
                      disabled
                      value={currencyFormatToConcept(totalFinal)}
                      style={{
                        width: "114px",
                        textAlign: "right",
                        height: "30px",
                        fontWeight: "700",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    />
                    <PoppinsNormal
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        marginLeft: "2px",
                        fontWeight: "700",
                        marginTop: "6px",
                      }}
                    >
                      {" "}
                      {getCurrency(user["custom:currency"])}
                    </PoppinsNormal>
                  </div>
                </div>
                {fases.map((fase, index) => {
                  return (
                    <div
                      className="principal"
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "0px",
                        marginBottom: "-10px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "5px",
                          marginLeft: "10px",
                          width: "20px",
                        }}
                      ></div>
                      <HelveticaInput2
                        onChange={e => {
                          changeName(e, index)
                        }}
                        type="text"
                        style={{
                          width: "240px",
                          height: "30px",
                          fontWeight: "400",
                          marginLeft: "10px",
                          backgroundColor: "#F5F5F580",
                          borderTopLeftRadius: index == 0 ? "6px" : "0px",
                          borderBottomLeftRadius:
                            index == fases.length - 1 ? "6px" : "0px",
                          border: "1px solid #D9D9D9",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                        placeholder="Define la fase de pago"
                      />
                      <div
                        className="inline"
                        style={{
                          width: "72px",
                          border: "1px solid #d9d9d9",
                          borderLeft: "none",
                          borderRight: "none",
                          marginLeft: "0px",
                          height: "30px",
                          marginTop: "-9px",
                        }}
                      >
                        <HelveticaInput2
                          onChange={e => {
                            changeFase(e, index, "numero")
                          }}
                          value={fase.porcentaje}
                          placeholder={porcentajeRestante}
                          type="number"
                          style={{
                            width: "45px",
                            fontWeight: "400",
                            border: "none",
                            height: "28px",
                            textAlign: "right",
                          }}
                        />
                        <PoppinsNormal
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            marginLeft: "2px",
                            marginTop: "6px",
                          }}
                        >
                          %
                        </PoppinsNormal>
                      </div>
                      <div
                        className="inline"
                        style={{
                          borderTopRightRadius: index == 0 ? "6px" : "0px",
                          borderBottomRightRadius:
                            index == fases.length - 1 ? "6px" : "0px",
                          width: "130px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          marginLeft: "0px",
                          height: "30px",
                          marginTop: "-9px",
                          border: "1px solid #d9d9d9",
                        }}
                      >
                        <HelveticaInput2
                          value={
                            fase.porcentaje == 0
                              ? getCurrency(user["custom:currency"]) +
                                "/sin IVA"
                              : currencyFormatToConcept(fase.cantidad)
                          }
                          placeholder={parseFloat(totalRestante).toFixed(2)}
                          type="string"
                          style={{
                            cursor: "default",
                            fontWeight: "700",
                            width: "110px",
                            textAlign: "right",
                            height: "28px",
                            border: "none",
                            cursor: "default",
                          }}
                        />
                        {fase.porcentaje != 0 && (
                          <PoppinsNormal
                            style={{
                              fontSize: "12px",
                              fontWeight: "700",
                              marginLeft: "2px",
                              marginTop: "6px",
                            }}
                          >
                            {" "}
                            {getCurrency(user["custom:currency"])}
                          </PoppinsNormal>
                        )}
                      </div>
                      {index != 0 && (
                        <div
                          className="secondary"
                          style={{ visibility: "hidden" }}
                        >
                          <DeleteOutlineOutlined
                            onClick={() => {
                              deleteFase(index)
                            }}
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              fontSize: "18px",
                              marginTop: "-10px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )
                })}
                <BlackHoverButton4CreateDynamically
                  onClick={() => {
                    setFases([
                      ...fases,
                      {
                        nombre: "",
                        porcentaje: 0,
                        cantidad: "",
                        isEdited: false,
                        key: fases.length + 3 * fases.length,
                      },
                    ])
                  }}
                  style={{
                    marginTop: "10px",
                    width: "fit-content",
                    paddingRight: "10px",
                    marginBottom: "10px",
                    marginLeft: "40px",
                  }}
                >
                  <AddIcon
                    style={{
                      fontSize: "18px",
                      marginRight: "5px",
                      color: "#414141",
                      marginLeft: "-5px",
                      paddingTop: "1px",
                    }}
                  />
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      fontWeight: "600",
                      paddingTop: "1px",
                      fontSize: "12px",
                    }}
                  >
                    {i18n.t("Nueva fase de pago")}
                  </HelveticaBold>
                </BlackHoverButton4CreateDynamically>

                {invoiceService.batchAction === false ? (
                  <YellowButton
                    disabled={sumaPorcentaje == 100 ? false : true}
                    onClick={() => {
                      setStep(1)
                    }}
                    style={{
                      marginLeft: "165px",
                      marginTop: "10px",
                      marginBottom: "25px",
                      background: sumaPorcentaje != 100 && "#d9d9d9",
                    }}
                  >
                    {i18n.t("Guardar")}
                  </YellowButton>
                ) : (
                  <YellowButton
                    disabled
                    style={{
                      marginLeft: "165px",
                      marginTop: "10px",
                      marginBottom: "25px",
                      backgroundColor: "#E4E4E4",
                      cursor: "default",
                    }}
                  >
                    <CircularProgress
                      size={24}
                      style={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        marginBottom: "25px",
                      }}
                    />
                  </YellowButton>
                )}
              </div>
            </>
          ) : (
            <DefinirImpuestosComponent
              projectName={imGeneral ? selectedProject.name : projectInfo?.name}
              invoiceService={invoiceService}
              createFacturas={createFacturas}
              total={totalFinal}
              impuestos={impuestos}
              setImpuestos={setImpuestos}
            ></DefinirImpuestosComponent>
          )}
        </div>
      </Modal>
    </>
  )
}
ModalDefinirFases.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  imGeneral: PropTypes.bool,
  selectedProject: PropTypes.object,
  invoiceService: PropTypes.any,
}
export default ModalDefinirFases
