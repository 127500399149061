import React, { useContext } from "react"
import PropTypes from "prop-types"
import { PartStyledTable } from "common/tables"
import i18n from "i18n"
import { currencyFormatDE } from "lib/transformers"
import Budget2023Context from "context/budget2023/Budget2023Context"
import { getCurrency } from "utils/functions"
import AuthContext from "context/auth/authContext"

const TableStructure = ({ children, imParent, total, capitulo }) => {
  const { user } = useContext(AuthContext)
  const { addToSelfManagement } = useContext(Budget2023Context)
  return (
    <PartStyledTable
      style={{
        marginLeft: imParent ? "0px" : "-8px",
        width: imParent ? "100%" : "100.5%",
        marginTop: imParent ? "0px" : "-10px",
        marginBottom: imParent ? "0px" : "-10px",
        border: "0px solid #e0e0e0",
      }}
    >
      <tr style={{ background: "rgba(217, 217, 217, 0.2)" }}>
        <th style={{ minWidth: "81px" }}></th>
        <th style={{ minWidth: "300px", width: "90%" }}></th>
        <th style={{ minWidth: "88px" }}></th>
        <th style={{ minWidth: "205", fontFamily: "Helvetica" }} colSpan="2">
          {i18n.t("Precio Objetivo")}
        </th>
      </tr>
      <tr style={{ background: "rgba(217, 217, 217, 0.2)" }}>
        <th style={{ minWidth: "81px", fontFamily: "Helvetica" }}>U</th>
        <th
          style={{
            minWidth: "300px",
            width: "90%",
            textAlign: "left",
            paddingLeft: "33px",
            width: "auto",
            fontFamily: "Helvetica",
          }}
        >
          {i18n.t("Descripción")}
        </th>

        <th style={{ minWidth: "88px", fontFamily: "Helvetica" }}>
          {i18n.t("Medición")}
        </th>
        <th
          style={{
            minWidth: "100px",
            textAlign: "left",
            paddingLeft: "33px",
            fontFamily: "Helvetica",
          }}
        >
          {getCurrency(user["custom:currency"])}/u
        </th>
        <th
          style={{
            minWidth: "105px",
            textAlign: "left",
            paddingLeft: "33px",
            fontFamily: "Helvetica",
          }}
        >
          {i18n.t("Total")}
        </th>
      </tr>
      {children}
      <tr id="lastPartida">
        <td colSpan="5">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0.5rem",
            }}
          >
            <div>{i18n.t("Total capítulo")}</div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div id="precioTotal">
                {currencyFormatDE(total, getCurrency(user["custom:currency"]))}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </PartStyledTable>
  )
}

TableStructure.propTypes = {
  children: PropTypes.node.isRequired,
  imParent: PropTypes.bool,
  total: PropTypes.float,
  capitulo: PropTypes.object,
}

export default TableStructure
