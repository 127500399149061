import UserView from "components/UserView/userView"
import React, { useEffect, useContext } from "react"
import AuthContext from "context/auth/authContext"
import { RecordPageView } from "App"
import MetaTags from "react-meta-tags"
import { HelveticaBold, PoppinsNormal } from "common/typographies"
import i18n from "i18n"
import { Container } from "reactstrap"
import { CircularProgress } from "@material-ui/core"

const Teams = () => {
  RecordPageView()
  const authContext = useContext(AuthContext)
  const {
    user,
    getUser,
    getOrganization,
    getActualUser,
    organization,
    internalUser,
  } = authContext

  useEffect(() => {
    if (!user) {
      getUser()
    } else {
      getOrganization({
        organizationId: user["custom:organizationId"],
      })
    }
  }, [user])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Planhopper - Equipo</title>
        </MetaTags>
        <Container fluid>
          {user && organization.loading == false ? (
            <UserView user={user} />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  transform: "translateY(-50%, -50%)",
                  top: "40%",
                  left: "45%",
                }}
              >
                <CircularProgress
                  style={{
                    width: 150,
                    height: 150,
                    marginLeft: "0px",
                    color: "#414141",
                    marginBottom: "20px",
                  }}
                />
                <HelveticaBold
                  style={{
                    color: "#414141",
                    fontSize: "24px",
                    marginLeft: "10px",
                    marginTop: "0px",
                  }}
                >
                  {i18n.t("Cargando...")}
                </HelveticaBold>
              </div>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}
export default Teams
