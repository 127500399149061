import React, { useRef, useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"
import { CircularProgress, Modal } from "@mui/material"
import { HelveticaBold } from "common/typographies"
import AuthContext from "context/auth/authContext"
import { makeStyles } from "@material-ui/core/styles"
import cover from "assets/customIcons/pdfs/cover.png"
import contraPortada from "assets/customIcons/pdfs/contraPortada.png"
import ImageDropZone from "components/BudgetingTemplate/imageDropZone"
import {
  CheckCircleRounded,
  CloseOutlined,
  DeleteOutline,
} from "@mui/icons-material"
import { BlackHoverButton4CreateDynamically } from "common/containers"
import { Document, Page, pdfjs } from "react-pdf"
import i18n from "i18n"
import {
  DeleteOutlineOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material"
import { HelveticaInput2 } from "common/inputs"
import AddIcon from "@mui/icons-material/Add"
import ProjectContext from "context/projects/projectContext"
import { CheckedInput, CustomCheckedInput } from "common/inputs"
import { useUtils } from "hooks/useUtils"
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "650px",
    height: "500px",
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 7%), 0px 5px 8px 0px rgb(0 0 0 / 7%), 0px 1px 14px 0px rgb(0 0 0 / 7%)",
    padding: theme.spacing(2, 4, 3),
    borderRadius: 6,
    overflowY: "auto",
    overflowX: "hidden",
  },
}))

const ModalPersonalizarPdf = ({ open, setOpen, projectInfo }) => {
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()
  const { organizationFields } = useContext(AuthContext)
  const handleClose = () => {
    setOpen(false)
  }

  const [loading, setLoading] = useState(false)

  const [pageSelected, setPageSelected] = useState(0)
  const [coverPdf, setCoverPdf] = useState(null)
  const [selectedFiles, setselectedFiles] = useState([])
  const [usingMyCover, setUsingMyCover] = useState(
    projectInfo.cover != "" ? true : false,
  )

  const [numPages, setNumPages] = useState(1)
  const componentRef = useRef()

  const changePage = page => {
    setPageSelected(page)
  }
  const { updateCoverAndConditions, getProject } = useContext(ProjectContext)

  const referenciaComment = useRef(null)

  useEffect(() => {
    if (referenciaComment.current) {
      if (projectInfo.conditions) {
        if (projectInfo.conditions != conditions && conditions != "") {
          referenciaComment.current.innerText = conditions
        } else {
          referenciaComment.current.innerText = projectInfo.conditions
        }
      } else {
        if (organizationFields.conditions) {
          referenciaComment.current.innerText = organizationFields.conditions
        } else {
          referenciaComment.current.innerText = conditions
        }
      }
    }
    if (projectInfo.cover) {
      setUsingMyCover(true)
      setCoverPdf(projectInfo.cover)
    } else if (organizationFields.cover) {
      setUsingMyCover(true)
      setCoverPdf(organizationFields.cover)
    }
    if (projectInfo.conditions) {
      setConditions(projectInfo.conditions)
    } else if (organizationFields.conditions) {
      setConditions(organizationFields.conditions)
    }
    if (projectInfo["cover_fields"]) {
      let objectCover = JSON.parse(projectInfo.cover_fields)

      let newArray = []
      for (let [key, value] of Object.entries(objectCover)) {
        let aux = {
          nombre: key,
          valor: value,
          key: 1,
        }
        newArray.push(aux)
      }
      setFases(newArray)
    }
  }, [open, referenciaComment, pageSelected])

  const [conditions, setConditions] = useState("")

  const onChangeConditions = e => {
    setConditions(e.target.value)
  }

  const { uploading, generateFileUrl } = useUtils()

  const saveProjectPdf = async () => {
    setLoading(true)
    // let url = projectInfo.cover
    let url = coverPdf
    if (selectedFiles.length > 0) {
      url = await generateFileUrl(selectedFiles[0], "protected")
    }
    let cover_fields

    if (fases.length == 1 && fases[0].nombre == "") {
      cover_fields = ""
    } else {
      cover_fields = Object.assign(
        {},
        ...fases.map(x => ({ [x.nombre]: x.valor })),
      )
    }
    await updateCoverAndConditions(
      projectInfo,
      url,
      conditions,
      JSON.stringify(cover_fields),
    )
    await getProject(projectInfo.projectId)
    setOpen(false)
    setLoading(false)
  }

  const [fases, setFases] = useState([
    {
      nombre: "",
      valor: "",
      key: 1,
    },
  ])

  const changeFase = (e, index, type) => {
    e.preventDefault()
    const newFases = [...fases]
    newFases[index].valor = e.target.value

    newFases[index].key = Math.random()
    setFases(newFases)
  }
  const changeName = (e, index) => {
    const newFases = [...fases]
    newFases[index].nombre = String(e.target.value)
    newFases[index].key = Math.random()
    setFases(newFases)
  }

  const deleteFase = index => {
    setFases(fases.filter((fase, i) => i !== index))
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div id="totalDiv">
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon
            id="cerrar"
            onClick={() => {
              setOpen(false)
            }}
            style={{
              marginTop: "0px",
              paddingLeft: "6px",
              marginRight: "-10px",
            }}
          ></CloseIcon>
          <div className="inline" style={{ marginTop: "10px" }}>
            <div
              onClick={() => {
                changePage(0)
              }}
              className="selectCard"
              style={{
                display: "flex",
                justifyContent: "center",
                height: "35px",
                cursor: "pointer",
                position: "relative",
                marginBottom: "15px",
                width: "100%",
              }}
            >
              <img
                src={cover}
                style={{
                  height: "70%",
                  marginTop: "5px",
                  opacity: pageSelected == 0 ? "1" : "0.5",
                }}
              />
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  marginTop: "6PX",
                  marginLeft: "5PX",
                  opacity: pageSelected == 0 ? "1" : "0.5",
                }}
              >
                Portada
              </HelveticaBold>
            </div>

            <div
              onClick={() => {
                changePage(1)
              }}
              className="selectCard"
              style={{
                display: "flex",
                height: "35px",
                justifyContent: "center",
                cursor: "pointer",
                position: "relative",
                width: "100%",
              }}
            >
              <img
                src={contraPortada}
                style={{
                  height: "70%",
                  marginTop: "5px",
                  opacity: pageSelected == 1 ? "1" : "0.5",
                }}
              />
              <HelveticaBold
                style={{
                  color: "#414141",
                  fontSize: "14px",
                  marginTop: "6PX",
                  marginLeft: "5PX",
                  opacity: pageSelected == 1 ? "1" : "0.5",
                }}
              >
                Contraportada
              </HelveticaBold>
            </div>
          </div>
          <div>
            {pageSelected == 0 ? (
              <>
                <div
                  className="inline"
                  style={{
                    marginLeft: "0px",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUsingMyCover(true)
                  }}
                >
                  <CustomCheckedInput
                    style={{
                      marginTop: "1.5px",
                      marginLeft: "3px",
                      marginRight: "5px",
                    }}
                  >
                    {usingMyCover && <CheckedInput />}
                  </CustomCheckedInput>
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      marginTop: "-2px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Usar mi propia portada
                  </HelveticaBold>
                </div>
                {usingMyCover && (
                  <>
                    {!coverPdf ? (
                      <ImageDropZone
                        selectedFiles={selectedFiles}
                        setselectedFiles={setselectedFiles}
                        setImage={setCoverPdf}
                        message={"Debe subir un archivo pdf de una sola pagina"}
                        accept={[".PDF", ".pdf"]}
                      />
                    ) : (
                      <>
                        <div className="inline">
                          <CheckCircleRounded
                            style={{
                              color: "#FFD52B",
                              marginRight: "5px",
                              marginLeft: "2px",
                              fontSize: "16px",
                            }}
                          />
                          <HelveticaBold
                            style={{
                              color: "#414141",
                              marginTop: "-3px",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {selectedFiles[0]?.path}
                          </HelveticaBold>
                        </div>
                        <BlackHoverButton4CreateDynamically
                          onClick={() => {
                            setCoverPdf(null)
                            setselectedFiles([])
                          }}
                          style={{
                            marginTop: "0px",
                            marginBottom: "10px",
                            marginLeft: "2px",
                            width: "fit-content",
                            paddingRight: "7px",
                          }}
                        >
                          <DeleteOutline
                            style={{
                              fontSize: "18px",
                              marginRight: "5px",
                              color: "#414141",
                              marginLeft: "-5px",
                              paddingTop: "1px",
                            }}
                          />
                          <HelveticaBold
                            style={{
                              color: "#414141",
                              fontWeight: "600",
                              paddingTop: "1px",
                              fontSize: "12px",
                            }}
                          >
                            {i18n.t("Eliminar")}
                          </HelveticaBold>
                        </BlackHoverButton4CreateDynamically>
                      </>
                    )}
                  </>
                )}
                {usingMyCover && coverPdf != null && (
                  <div
                    style={{
                      height: "222px",
                      marginLeft: "0px",
                      marginRight: "20px",
                      display: "flex",
                      justifyContent: "center",
                      overflowY: "auto",
                    }}
                  >
                    {coverPdf != null && (
                      <Document
                        ref={componentRef}
                        file={coverPdf}
                        onLoadError={console.error}
                        style={{ overflowY: "auto" }}
                      >
                        {[...Array(numPages)].map((elementInArray, index) => (
                          <Page
                            scale={0.5}
                            key={index}
                            pageNumber={index + 1}
                          />
                        ))}
                      </Document>
                    )}
                  </div>
                )}
                <div
                  className="inline"
                  style={{
                    marginLeft: "0px",
                    marginTop: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUsingMyCover(false)
                  }}
                >
                  <CustomCheckedInput
                    style={{
                      marginTop: "1.5px",
                      marginLeft: "3px",
                      marginRight: "5px",
                    }}
                  >
                    {!usingMyCover && <CheckedInput />}
                  </CustomCheckedInput>
                  <HelveticaBold
                    style={{
                      color: "#414141",
                      marginTop: "-2px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    Utilizar nuestra portada por defecto
                  </HelveticaBold>
                </div>

                {!usingMyCover && (
                  <>
                    <HelveticaBold
                      style={{
                        fontWeight: "700",
                        marginLeft: "40px",
                        fontSize: "14px",
                        color: "#414141",
                      }}
                    >
                      Participantes del proyecto
                    </HelveticaBold>
                    {fases.map((fase, index) => {
                      return (
                        <div
                          className="principal"
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: "0px",
                            marginBottom: "-10px",
                          }}
                        >
                          <div
                            style={{
                              marginTop: "5px",
                              marginLeft: "10px",
                              width: "20px",
                            }}
                          ></div>
                          <HelveticaInput2
                            onChange={e => {
                              changeName(e, index)
                            }}
                            value={fase.nombre}
                            type="text"
                            style={{
                              width: "240px",
                              height: "30px",
                              fontWeight: "400",
                              marginLeft: "10px",
                              backgroundColor: "white",
                              borderTopLeftRadius: index == 0 ? "6px" : "0px",
                              borderBottomLeftRadius:
                                index == fases.length - 1 ? "6px" : "0px",
                              border: "1px solid #D9D9D9",
                              borderTopRightRadius: "0px",
                              borderBottomRightRadius: "0px",
                            }}
                            placeholder="Titulo"
                          />
                          <div
                            className="inline"
                            style={{
                              width: "240px",
                              border: "1px solid #d9d9d9",
                              borderLeft: "none",
                              marginLeft: "0px",
                              height: "30px",
                              marginTop: "-9px",
                            }}
                          >
                            <HelveticaInput2
                              placeholder={"Nombre"}
                              onChange={e => {
                                changeFase(e, index, "texto")
                              }}
                              value={fase.valor}
                              style={{
                                width: "240px",
                                fontWeight: "400",
                                border: "none",
                                height: "28px",
                                textAlign: "left",
                              }}
                            />
                          </div>

                          {index != 0 && (
                            <div
                              className="secondary"
                              style={{ visibility: "hidden" }}
                            >
                              <DeleteOutlineOutlined
                                onClick={() => {
                                  deleteFase(index)
                                }}
                                style={{
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                  marginTop: "-10px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                    <BlackHoverButton4CreateDynamically
                      onClick={() => {
                        setFases([
                          ...fases,
                          {
                            nombre: "",
                            valor: "",
                            key: fases.length + 3 * fases.length,
                          },
                        ])
                      }}
                      style={{
                        marginTop: "10px",
                        width: "fit-content",
                        paddingRight: "10px",
                        marginBottom: "10px",
                        marginLeft: "40px",
                      }}
                    >
                      <AddIcon
                        style={{
                          fontSize: "18px",
                          marginRight: "5px",
                          color: "#414141",
                          marginLeft: "-5px",
                          paddingTop: "1px",
                        }}
                      />
                      <HelveticaBold
                        style={{
                          color: "#414141",
                          fontWeight: "600",
                          paddingTop: "1px",
                          fontSize: "12px",
                        }}
                      >
                        {i18n.t("Nuevo campo")}
                      </HelveticaBold>
                    </BlackHoverButton4CreateDynamically>
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  id="DivTitleEliminar"
                  style={{ marginTop: "12px", justifyContent: "center" }}
                >
                  <p
                    id="centradoBudget"
                    style={{
                      fontSize: "12px",
                      marginTop: "0px",
                      marginBottom: "20px",
                      fontWeight: "300",
                    }}
                  >
                    {" "}
                    Escribe aqui las condiciones generales de tu proyecto
                  </p>
                </div>

                <textarea
                  ref={referenciaComment}
                  suppressContentEditableWarning={true}
                  onChange={e => {
                    onChangeConditions(e)
                  }}
                  id="descripcionInput"
                  onBlur={e => {
                    onChangeConditions(e)
                  }}
                  name="comment"
                  placeholder="Escribe aquí las condiciones"
                  style={{
                    width: "100%",
                    height: "230px",
                    position: "relative",
                    paddingTop: "10px",
                    paddingLeft: "10px",
                    resize: "none",
                  }}
                >
                  {projectInfo.conditions
                    ? projectInfo.conditions
                    : organizationFields.conditions
                      ? organizationFields.conditions
                      : ""}
                </textarea>
              </>
            )}
          </div>

          {loading ? (
            <CircularProgress
              size={24}
              style={{ marginTop: "30px", marginLeft: "170px" }}
            />
          ) : (
            <div id="centerButton" style={{ marginBottom: "6px" }}>
              <button
                style={{ height: "38px", width: "103px" }}
                type="button"
                className="AddContact"
                onClick={saveProjectPdf}
              >
                {i18n.t("Guardar")}
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

ModalPersonalizarPdf.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  projectInfo: PropTypes.object.isRequired,
}

export default ModalPersonalizarPdf
