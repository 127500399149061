import { API, graphqlOperation, Auth } from "aws-amplify"
import { useState, useEffect } from "react"
import { nanoid } from "nanoid"
export const useComments = ({ conversationId }) => {
  const [comments, setComments] = useState([])

  useEffect(() => {
    // const fetchComments = async () => {
    //     const result = await API.graphql(graphqlOperation(queries.queryTribbooAppCommentsByConversationIdIndexAndDate, {
    //         conversationId: conversationId, first : 10
    //     }))
    //     setComments(result.data.queryTribbooAppCommentsByConversationIdIndexAndDate.items)
    // }
    // fetchComments();
  }, [])
  useEffect(() => {
    // const subscription = API.graphql(
    //     graphqlOperation(subscriptions.onCreateCommentToConversation, {'conversationId': conversationId})
    // ).subscribe({
    //     next: ({provider,value}) => addComment(value.data.onCreateCommentToConversation),
    // })
    // return () => {
    //     subscription.unsubscribe()
    // }
  }, [])
  const addComment = comment => {
    setComments(prevComment => [comment, ...prevComment])
  }
  const createComment = async comment => {
    const { attributes } = await Auth.currentUserInfo()
    const author = {
      accountId: attributes.sub,
      fullName: attributes["custom:nombre_apellido"]
        ? attributes["custom:nombre_apellido"]
        : "",
      profileImg: attributes["custom:avatar"]
        ? attributes["custom:avatar"]
        : "",
    }
    const commentDetail = {
      commentId: nanoid(),
      conversationId: comment.conversationId,
      author: JSON.stringify(author),
      content: comment.content,
    }
    // const res = await API.graphql(
    //     {
    //         query: mutations.createTribbooAppComments,
    //         variables: {
    //             input: commentDetail
    //         }
    //     }
    // )
  }
  return {
    createComment,
    comments,
  }
}
